<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        {{headingText}}
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col">
                        <div class="card p-3">
                            <div class="row voucherfont">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="payments.add.voucher.generation.name">
                                    Add Voucher Generation
                                </h6>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.voucher.number.name" | translate }}:<br />{{ voucherGenerationDto?.vendorTransactionDto?.voucherNo }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "date.name" | translate }}:<br />{{ date | date:'dd/MM/yyyy'}}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "implementing.agency.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto?.implementingAgencyDto.name }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.villagegst.number.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto?.implementingAgencyDto.gstNo }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.limit.amount.name" | translate }}:<br />{{ this.voucherGenerationDto?.vendorTransactionDto .iaComponentLimitDto?.realizedLimitAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.department.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto?.departmentDto?.name }}
                                    </p>
                                </div>

                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.scheme.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto?.schemeDto?.name }}
                                    </p>
                                </div>

                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.level.type.name" | translate }}:<br />{{ levelMasterDto?.name }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "funds.fin.year.name" | translate }}:<br />{{ finYear?.name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.grant.level.name">Grant Level</label
              >
              <ng-select
                id="scheme"
                [hideSelected]="true"
                [(ngModel)]="grantlevel"
                [items]="grantLevel"
                bindLabel="name"
                appearance="outline"
                [closeOnSelect]="true"
                [clearable]="true"
                (change)="ongrandLevel()"
                [disabled]="readonly"
                class="form-control dropdown-width"
                [ngClass]="{ 'is-invalid': ngSelectInvalid && !grantlevel }"
              >
              </ng-select>
            </div>
            <div class="col-lg-3 col-md-6">
              <label
                class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req"
                for="scheme"
                [ngClass]="fontService.labelClass"
                jhiTranslate="breadcrumb.component.major.component"
                >Major Component</label>
                            <ng-select id="scheme" [(ngModel)]="majaorComponentdto" [items]="majaorComponent" [bindLabel]="dropDownLabel" appearance="outline" [closeOnSelect]="true" [clearable]="true" [disabled]="readonly" (change)="onMajorComponent()" class="form-control dropdown-width"
                                [ngClass]="{
                  'is-invalid': ngSelectInvalid && !majaorComponentdto
                }">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="breadcrumb.component.minor.component">Minor Component</label
              >
              <ng-select
                id="scheme"
                [(ngModel)]="minorComponentDto"
                [items]="minorComponent"
                [bindLabel]="dropDownLabel"
                [disabled]="readonly"
                appearance="outline"
                [closeOnSelect]="true"
                [clearable]="true"
                (change)="onMinorComponent()"
                class="form-control dropdown-width"
                [ngClass]="{
                  'is-invalid': ngSelectInvalid && !minorComponentDto
                }"
              >
              </ng-select>
            </div>
            <div class="col-lg-3 col-md-6">
              <label
                class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2 req"
                for="scheme"
                [ngClass]="fontService.labelClass"
                jhiTranslate="breadcrumb.component.sub.component"
                >Sub Component</label
              >
              <ng-select
                id="scheme"
                [(ngModel)]="subComponentDto"
                [items]="subComponent"
                [bindLabel]="dropDownLabel"
                [disabled]="readonly"
                appearance="outline"
                [closeOnSelect]="true"
                [clearable]="true"
                class="form-control dropdown-width"
                [ngClass]="{
                  'is-invalid': ngSelectInvalid && !subComponentDto
                }"
                (change)="vendorFilter()"
              >
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="subComponentDto" class="row g-0">
      <div class="col-lg-5">
        <div class="card p-3 invoice-card">
          <div class="row">
            <div class="col-md-7">
              <div class="row">
                <div class="col">
                    <p-radioButton name="vendorOption" [disabled]="readonly" value="selectOption" [(ngModel)]="vendorSearchOption" label="Search by Name"></p-radioButton>
                </div>
                <div class="col">
                    <p-radioButton name="vendorOption" [disabled]="readonly" value="accnoSearchOption" [(ngModel)]="vendorSearchOption" label="Search by Account Number"></p-radioButton>
                </div>
                <div class="col">
                    <p-radioButton name="vendorOption" [disabled]="readonly" value="unIdSearchOption" [(ngModel)]="vendorSearchOption" label="Search by Unique Id"></p-radioButton>
                </div>

                <div *ngIf="vendorSearchOption =='unIdSearchOption'">
                    <span class="p-inputgroup">
                <input type="text" class="form-control search_input mt-1"  maxlength="20" [(ngModel)]="uniqueId" name="uniqueId" autocomplete="off"
                    id="uniqueId" [readonly]="readonly" />
                <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-text" [disabled]="readonly" (click)="vendorFilterByAccount('uniqueNo')" ></button>
                </span>
                    <!-- [ngClass]="{ 'is-invalid': !accountNo }" -->
                </div>
                <div *ngIf="vendorSearchOption =='accnoSearchOption'">
                    <span class="p-inputgroup">
                <input type="text" class="form-control search_input mt-1" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="20" [(ngModel)]="accountNo" name="accountNo" autocomplete="off"
                    id="accountNo" [readonly]="readonly"  />
                <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-text" [disabled]="readonly" (click)="vendorFilterByAccount('AccountNo')" ></button>
                </span>
                    <!-- [ngClass]="{ 'is-invalid': !accountNo }" -->
                </div>

            </div>
            <div class="row" *ngIf="vendorSearchOption == 'selectOption'">
              <ng-select
                id="scheme"
                [disabled]="readonly || loadingMore|| !vendorSearchOption"
                [(ngModel)]="selectedVendor"
                [items]="vendorList"
                bindLabel="name"
                appearance="outline"
                [searchable]="true"
                [closeOnSelect]="true"
                [clearable]="false"
                (search)="search($event)"
                (change)="popupValue()"
                class="form-control dropdown-width"
                [ngClass]="{
                  'is-invalid': ngSelectInvalid && !selectedVendor.name
                }"
                (scrollToEnd)="onScrollToEnd()"
              >
              <ng-template ng-header-tmp>
                <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-text" [disabled]="readonly  || !vendorSearchOption" (click)="vendorFilter()"></button>
              </ng-template>
              </ng-select>
            </div>
          </div>
            <div class="col-md-5">
              <label
                class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req"
                for="scheme"
                [ngClass]="fontService.labelClass"
                jhiTranslate="payments.panchayat.resolution.number.name"
                >Panchayat Resolution No</label
              >
              <input
                type="text"
                class="form-control"
                autocomplete="off"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                maxlength="15"
                [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto
                    .panchayatResolutionNumber
                "
                [ngClass]="{
                  'is-invalid':
                    ngSelectInvalid &&
                    !voucherGenerationDto.vendorTransactionDto
                      .panchayatResolutionNumber
                }"
                name="name"
                [readonly]="readonly"
                id="levelType"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <p [ngClass]="fontService.regularBoldClass">
               Name:<br />{{
                  selectedVendor?.companyName
                }}
              </p>
            </div>
            <div class="col-md-3">
              <p [ngClass]="fontService.regularBoldClass">
                {{ "payments.vendor.id" | translate }}:<br />{{
                  selectedVendor?.id
                }}
              </p>
            </div>

            <div class="col-md-3">
              <p [ngClass]="fontService.regularBoldClass">
                {{ "payments.vendor.gst" | translate }}:<br />{{
                  selectedVendor?.gstNo
                }}
              </p>
            </div>
            <div class="col-md-3">
              <p [ngClass]="fontService.regularBoldClass">
                {{ "payments.vendor.pan" | translate }}:<br />{{
                  selectedVendor?.panNo
                }}
              </p>
            </div>
            </div>
          <div class="row mt-3">
            <div class="col-md-4">
              <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" [ngClass]="fontService.labelClass">{{"payments.staff.accno.name"|translate}}:</label>
                            <p [ngClass]="fontService.regularClass" class="mb-0">{{selectedVendor?.accountNumber}}</p>
                        </div>
                        <div class="col-md-4">
                            <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" [ngClass]="fontService.labelClass">{{"payments.staff.bank.name"|translate}}:</label>
                            <p [ngClass]="fontService.regularClass" class="mb-0">{{selectedVendor?.bankName}}</p>
                        </div>
                        <div class="col-md-4">
                            <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" [ngClass]="fontService.labelClass">{{"payments.staff.ifsc.code.name"|translate}}:</label>
                            <p [ngClass]="fontService.regularClass" class="mb-0">{{selectedVendor?.ifscCode}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-md-"></div> -->
                        <div *ngIf="selectedVendor?.name" class="col-md-5">
                            <div class="" [ngClass]="fontService.labelClass" jhiTranslate="payments.vendor.last.transaction.name">
                                last 5 Transaction
                            </div>
                            <button pButton pRipple (click)="getvendorTrans()" class="p-button-success custom-button" styleClass="button">
                <span
                  class="button-label"
                  [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.view"
                ></span>
              </button>
                        </div>
                        <div *ngIf="enableZonalButton" class="col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Zonal User</label
              >
              <ng-select
                id="scheme"
                [disabled]="readonly"
                [(ngModel)]="selectedZonalUser"
                [items]="zonalList"
                bindLabel="userName"
                appearance="outline"
                [searchable]="true"
                [closeOnSelect]="true"
                [clearable]="false"
                class="form-control dropdown-width"
               
              >
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="card p-3 invoice-card">
          <div class="row">
            <div class="col-lg-6">
              <div class="row">
                <div class="col-lg-7">
                  <label
                    class="form-control-label req"
                    for="scheme"
                    [ngClass]="fontService.labelClass"
                    jhiTranslate="payments.invoice.number"
                    >Invoice Number</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    autocomplete="off"
                    oninput="this.value = this.value.replace(/[^A-Za-z0-9-_]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                    onblur="this.value =this.value.trim();"
                    style="text-transform: uppercase"
                    maxlength="30"
                    [(ngModel)]="
                      voucherGenerationDto.vendorTransactionDto.invoiceNo
                    "  [ngClass]="{
                      'is-invalid':
                        ngSelectInvalid &&
                        !voucherGenerationDto.vendorTransactionDto.invoiceNo
                    }"
                    name="name"
                    [readonly]="readonly"
                    id="levelType"
                  />
                </div>
                <div class="col-lg-5">
                  <label
                    class="form-control-label req pt-lg-0 pt-sm-2 pt-xs-2"
                    for="dateOfBirth"
                    [ngClass]="fontService.labelClass"
                    jhiTranslate="payments.invoice.date"
                    >Invoice Date</label
                  ><br />
                  <p-calendar
                    [(ngModel)]="
                      voucherGenerationDto.vendorTransactionDto.invoiceDate
                    "
                    [maxDate]="maxDate"
                    [disabled]="readonly"
                    styleClass="datepicker"
                    dateFormat="dd/mm/yy"
                  ></p-calendar>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <label
                    class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req"
                    for="schemeDescription"
                    [ngClass]="fontService.labelClass"
                    jhiTranslate="payments.name.of.work.name"
                    >Nature Of Work</label
                  ><br />
                  <textarea
                    id="w3review"
                    [readonly]="readonly"
                    name="w3review"
                    rows="3"
                    [(ngModel)]="
                      voucherGenerationDto.vendorTransactionDto.nameOfWork
                    "
                    [ngClass]="{
                      'is-invalid':
                        ngSelectInvalid &&
                        !voucherGenerationDto.vendorTransactionDto.nameOfWork
                    }"
                    cols="100"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row" *ngIf="this.personalFlag !== 'OFP'">
                <label
                class="form-control-label req pt-md-0 pt-sm-2 pt-xs-2"
                for="scheme"
                [ngClass]="fontService.labelClass"
                jhiTranslate="payments.invoice.upload.invoice.name"
                >Upload Invoice</label
              >
                <div>
                  <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file types are jpg, jpeg, png, pdf and
                    Maximum file size should be 1MB</small>
                </div>
                <p-fileUpload *ngIf="!readonly"
                  accept="image/png,image/jpeg,image/jpg,application/pdf"
                  (onUpload)="onUpload($event,'invoice')"
                  [customUpload]="true"
                  [disabled]="isFileUploaded"
                  (uploadHandler)="onUpload($event,'invoice')"
                  auto="true"
                  multiple="false"
                  [showUploadButton]="false"
                  [showCancelButton]="false"
                  chooseLabel="{{'common.upload'|translate}}"
                  maxFileSize="1000000" 
                >
                </p-fileUpload>
                <div class="row mt-2" *ngIf="isFileUploaded">
                  <div class="col">
                    <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)">{{this.voucherGenerationDto.vendorTransactionDto.invoiceImage}}</a>
                    <i  class="pi pi-times add-margin" *ngIf="!readonly" (click)="removeDocument(this.voucherGenerationDto.vendorTransactionDto.invoiceImage,'invoice')"></i>
                    <i  class="pi pi-download add-margin" (click)="downloadDocument(this.voucherGenerationDto.vendorTransactionDto.invoiceImage)"></i>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="this.personalFlag === 'OFP'">
                <label
                class="form-control-label req pt-md-0 pt-sm-2 pt-xs-2"
                for="scheme"
                [ngClass]="fontService.labelClass"
                >AS Document</label
              >
                <div>
                  <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass">Note : Allowed file types are pdf and
                    Maximum file size should be 1MB</small>
                </div>
                <p-fileUpload *ngIf="!readonly"
                  accept="application/pdf"
                  (onUpload)="onUpload($event,'asDocument')"
                  [customUpload]="true"
                  [disabled]="asUpload"
                  (uploadHandler)="onUpload($event,'asDocument')"
                  auto="true"
                  multiple="false"
                  [showUploadButton]="false"
                  [showCancelButton]="false"
                  chooseLabel="{{'common.upload'|translate}}"
                  maxFileSize="1000000" 
                >
                </p-fileUpload>
                <div class="row mt-2" *ngIf="asUpload">
                  <div class="col">
                    <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument(this.voucherGenerationDto.vendorTransactionDto.asDocumentImage)">{{this.voucherGenerationDto.vendorTransactionDto.asDocumentImage}}</a>
                    <i  class="pi pi-times add-margin" *ngIf="!readonly" (click)="removeDocument(this.voucherGenerationDto.vendorTransactionDto.asDocumentImage,'asDocument')"></i>
                    <i  class="pi pi-download add-margin" (click)="downloadDocument(this.voucherGenerationDto.vendorTransactionDto.asDocumentImage)"></i>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="this.personalFlag === 'OFP'">
                <label
                class="form-control-label req pt-md-0 pt-sm-2 pt-xs-2"
                for="scheme"
                [ngClass]="fontService.labelClass"
                >BDO Pass Order</label
              >
                <div>
                  <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass">Note : Allowed file types are pdf and
                    Maximum file size should be 1MB</small>
                </div>
                <p-fileUpload *ngIf="!readonly"
                  accept="application/pdf"
                  (onUpload)="onUpload($event,'bdoPassOrder')"
                  [customUpload]="true"
                  [disabled]="bdoPass"
                  (uploadHandler)="onUpload($event,'bdoPassOrder')"
                  auto="true"
                  multiple="false"
                  [showUploadButton]="false"
                  [showCancelButton]="false"
                  chooseLabel="{{'common.upload'|translate}}"
                  maxFileSize="1000000" 
                >
                </p-fileUpload>
                <div class="row mt-2" *ngIf="bdoPass">
                  <div class="col">
                    <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument(this.voucherGenerationDto.vendorTransactionDto.bdoPassOrderImage)">{{this.voucherGenerationDto.vendorTransactionDto.bdoPassOrderImage}}</a>
                    <i  class="pi pi-times add-margin" *ngIf="!readonly" (click)="removeDocument(this.voucherGenerationDto.vendorTransactionDto.bdoPassOrderImage,'bdoPassOrder')"></i>
                    <i  class="pi pi-download add-margin" (click)="downloadDocument(this.voucherGenerationDto.vendorTransactionDto.bdoPassOrderImage)"></i>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="this.personalFlag === 'OFP'">
                <label
                class="form-control-label req pt-md-0 pt-sm-2 pt-xs-2"
                for="scheme"
                [ngClass]="fontService.labelClass"
                >Panchayat Voucher</label
              >
                <div>
                  <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass">Note : Allowed file types are pdf and
                    Maximum file size should be 1MB</small>
                </div>
                <p-fileUpload *ngIf="!readonly"
                  accept="application/pdf"
                  (onUpload)="onUpload($event,'panchayatVoucher')"
                  [customUpload]="true"
                  [disabled]="panchayatVoucher"
                  (uploadHandler)="onUpload($event,'panchayatVoucher')"
                  auto="true"
                  multiple="false"
                  [showUploadButton]="false"
                  [showCancelButton]="false"
                  chooseLabel="{{'common.upload'|translate}}"
                  maxFileSize="1000000" 
                >
                </p-fileUpload>
                <div class="row mt-2" *ngIf="panchayatVoucher">
                  <div class="col">
                    <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument(this.voucherGenerationDto.vendorTransactionDto.panchayatVoucherImage)">{{this.voucherGenerationDto.vendorTransactionDto.panchayatVoucherImage}}</a>
                    <i  class="pi pi-times add-margin" *ngIf="!readonly" (click)="removeDocument(this.voucherGenerationDto.vendorTransactionDto.panchayatVoucherImage,'panchayatVoucher')"></i>
                    <i  class="pi pi-download add-margin" (click)="downloadDocument(this.voucherGenerationDto.vendorTransactionDto.panchayatVoucherImage)"></i>
                  </div>
                </div>
              </div>
        
         
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="subComponentDto && selectedVendor?.name" class="row">
    <div class="col-md-12">
      <div class="card p-3">
        <div class="row">
          <div class="col-md-12 mt-2" *ngIf="selectedVendor?.name">
            <p-table
              [value]="voucherGenerationDto.voucherBillDescDtoList"
              [resizableColumns]="true"
              responsiveLayout="scroll"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th [ngClass]="fontService.labelClass">
                    {{ "payments.table.sno" | translate }}
                  </th>
                  <th class="req" [ngClass]="fontService.labelClass">
                    {{ "payments.table.title" | translate }}
                  </th>
                  <!-- <th class="req" [ngClass]="fontService.labelClass">
                    {{ "payments.table.hsn" | translate }}
                  </th> -->
                  <th class="req" [ngClass]="fontService.labelClass">
                    {{ "payments.table.quantity" | translate }}
                  </th>
                  <th class="req" [ngClass]="fontService.labelClass">
                    {{ "payments.table.amount" | translate }} <br />
                    <span class="italic"
                      >({{ "payments.table.exclGst" | translate }})</span
                    >
                  </th>
                  <th
                    *ngIf="enableStateGst || enableIGst"
                    [ngClass]="fontService.labelClass"
                  >
                    {{ "payments.table.gst" | translate }}
                  </th>
                  <th
                    *ngIf="enableStateGst"
                    [ngClass]="fontService.labelClass"
                  >
                    {{ "payments.table.sgst" | translate }}
                  </th>
                  <th
                    *ngIf="enableStateGst"
                    [ngClass]="fontService.labelClass"
                  >
                    {{ "payments.table.cgst" | translate }}
                  </th>
                  <th *ngIf="enableIGst" [ngClass]="fontService.labelClass">
                    {{ "payments.table.igst" | translate }}
                  </th>
                  <th [ngClass]="fontService.labelClass">
                    {{ "payments.table.grossTotal" | translate }}
                  </th>
                  <th *ngIf="this.personalFlag !== 'OFP'" [ngClass]="fontService.labelClass">
                    {{ "payments.table.delete" | translate }}
                  </th>
                  <th *ngIf="this.personalFlag !== 'OFP'" style="text-align: right">
                    <button
                      pButton
                      pRipple
                      icon="pi pi-plus"
                      class="p-button-success custom-button"
                      [disabled]="readonly"
                      styleClass="button"
                      (click)="addRow()"
                      [disabled]="readonly"
                    >
                      <span
                        class="button-label"
                        [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.row"
                      ></span>
                    </button>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      autocomplete="off"
                      oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                      onblur="this.value =this.value.trim();"
                      style="text-transform: uppercase"
                      (blur)="addDescritption(list.description)"
                      maxlength="30"
                      placeholder="Habitation-Location/Street"
                      style="min-width:360px"
                      [(ngModel)]="list.description"
                      name="name"
                      id="levelType"
                      [ngClass]="{
                        'is-invalid': ngSelectInvalid && !list.description
                      }"
                      [readonly]="readonly"
                    />
                  </td>
                  <!-- <td>
                    <input
                      type="text"
                      class="form-control"
                      autocomplete="off"
                      onblur="this.value =this.value.trim();"
                      uppercase
                      maxlength="8"
                      [readonly]="readonly"
                      [(ngModel)]="list.hsnsac"
                      (blur)="addHsn(list.hsnsac)"
                      name="name"
                      id="levelType"
                      autocomplete="off"
                      (input)="validateHsn(list.hsnsac)"
                      [ngClass]="{
                        'is-invalid': ngSelectInvalid && !list.hsnsac
                      }"
                    />
                  </td> -->

                  <td>
                    <p-inputNumber
                      type="number"
                      id="field_price"
                      class="small-box"
                      [(ngModel)]="list.quantity"
                      (ngModelChange)="calculatetotal(list)"
                      [ngClass]="{
                        'is-invalid': ngSelectInvalid && !list.quantity
                      }"
                      [readonly]="readonly"
                    ></p-inputNumber>
                  </td>
                  <td>
                    <p-inputNumber
                      id="field_price"
                      inputId="locale-indian" mode="decimal" locale="en-IN"
                      [(ngModel)]="list.amount"
                      (ngModelChange)="calculatetotal(list)"
                      [readonly]="readonly"
                      maxlength="15"
                      [ngClass]="{
                        'is-invalid': ngSelectInvalid && !list.amount
                      }"
                    ></p-inputNumber>
                  </td>
                  <td *ngIf="enableStateGst || enableIGst">
                    <p-inputNumber
                      id="field_price"                        
                      [max]="28"
                      [readonly]="readonly"
                      [(ngModel)]="list.gstPercentage"
                      mode="decimal"
                      [minFractionDigits]="1" 
                      [maxFractionDigits]="2"
                      (ngModelChange)="calculatetotal(list)"
                    ></p-inputNumber>
                  </td>
                  <td *ngIf="enableStateGst">
                    <input
                      type="text"
                      class="form-control small-box"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                      id="field_price"
                      [(ngModel)]="list.sgstPercentage"
                      [readonly]="true"
                    />
                  </td>
                  <td *ngIf="enableStateGst">
                    <input
                      type="text"
                      class="form-control small-box"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                      id="field_price"
                      [(ngModel)]="list.cgstPercentage"
                      mode="decimal"
                      [readonly]="true"
                    />
                  </td>
                  <td *ngIf="enableIGst">
                    <input
                      type="text"
                      class="form-control small-box"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                      id="field_price"
                      [(ngModel)]="list.gstPercentage"
                      [readonly]="true"
                    />
                  </td>
                  <td>
                    <p-inputNumber
                      type="text"
                      inputId="locale-indian" mode="decimal" locale="en-IN"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                      id="field_price"
                      [(ngModel)]="list.grossAmount"
                      [readonly]="true"
                    ></p-inputNumber>
                  </td>
                  <td *ngIf="this.personalFlag !== 'OFP'">
                    <button
                      pButton
                      pRipple
                      type="button"
                      [disabled]="readonly"
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-text p-button-danger"
                      (click)="delete(index)"
                    ></button>
                  </td>
                  <td *ngIf="this.personalFlag !== 'OFP'"></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div *ngIf="selectedVendor?.name" class="row mt-3 tds">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-sm-4">
                <label
                  class="form-control-label"
                  for="schemeDescription"
                  [ngClass]="fontService.labelClass"
                  >{{ "payments.amount.select.tds.name" | translate }}</label
                ><br />
                <p-radioButton
                  [disabled]="readonly"
                  name="option"
                  [value]="true"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.tdsApplicable
                  "
                  label="{{ 'payments.amount.yes' | translate }}"
                ></p-radioButton>
                <p-radioButton
                  [disabled]="readonly"
                  name="option"
                  [value]="false"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.tdsApplicable
                  "
                  label="{{ 'payments.amount.no' | translate }}"
                  (onClick)="
                    voucherGenerationDto.vendorTransactionDto.tdsPercentage = 0;
                    voucherGenerationDto.vendorTransactionDto.tdsAmount = 0;
                    calculateTotalAmount()
                  "
                ></p-radioButton>
              </div>

              <div
                class="col-sm-3"
                *ngIf="
                  voucherGenerationDto?.vendorTransactionDto?.tdsApplicable
                "
              >
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.tds%"
                  >TDS%</label
                ><br />
                <p-inputNumber
                  class="full-width"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  id="field_price"
                  [readonly]="readonly"
                  [min]="0"
                  [max]="28"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.tdsPercentage
                  "
                  mode="decimal"
                  [minFractionDigits]="1" 
                  [maxFractionDigits]="2"
                  (ngModelChange)="calculateTds()"
                ></p-inputNumber>
              </div>
              <div
                class="col-sm-5"
                *ngIf="
                  voucherGenerationDto?.vendorTransactionDto?.tdsApplicable
                "
              >
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.tds.amount.name"
                  >TDS Amount</label><br>
                            <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.tdsAmount
                  " name="name" id="levelType" [readonly]="true"></p-inputNumber>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-4">
                            <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">{{ "payments.amount.select.gst.name" | translate }}</label
                ><br />
                <p-radioButton
                  name="option"
                  [disabled]="readonly"
                  [value]="true"
                  [(ngModel)]="enableGstOnTds"
                  (onClick)="
                    voucherGenerationDto.vendorTransactionDto.gstOnTds = 2;
                    calculateTotalAmount()
                  "
                  label="{{ 'payments.amount.yes' | translate }}"
                ></p-radioButton>
                <p-radioButton
                  name="option"
                  [disabled]="readonly"
                  [value]="false"
                  [(ngModel)]="enableGstOnTds"
                  label="{{ 'payments.amount.no' | translate }}"
                  (onClick)="calculateTotalAmount()"
                ></p-radioButton>
              </div>
              <div class="col-sm-3" *ngIf="enableGstOnTds">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.gstTds%"
                  >GST on TDS %</label
                ><br />
                <p-inputNumber
                  class="full-width"
                  inputId="locale-indian" mode="decimal" locale="en-IN"
                  [min]="0"
                  [max]="29"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.gstOnTds
                  "
                  mode="decimal"
                  [minFractionDigits]="1" 
                  [maxFractionDigits]="2"
                  [readonly]="readonly"
                  (ngModelChange)="calculateTotalAmount()"
                  name="name"
                  id="levelType"
                ></p-inputNumber>
              </div>
              <div class="col-sm-5" *ngIf="enableGstOnTds">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.gst.amount.name"
                  >GST on TDS Amount</label
                ><br>
                <p-inputNumber
                class="full-width"
                  inputId="locale-indian" mode="decimal" locale="en-IN"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount
                  "
                  name="name"
                  id="levelType"
                  [readonly]="true"
                ></p-inputNumber>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-4">
                <label
                  class="form-control-label pt-xs-2"
                  for="schemeDescription"
                  [ngClass]="fontService.labelClass"
                  >{{
                    "payments.amount.select.labour.name" | translate
                  }}</label
                ><br />
                <p-radioButton
                  name="option"
                  [disabled]="readonly"
                  [value]="true"
                  [(ngModel)]="enableLabourChess"
                  (onClick)="
                    voucherGenerationDto.vendorTransactionDto.labourCessPercentage = 1;
                    calculateTotalAmount()
                  "
                  label="{{ 'payments.amount.yes' | translate }}"
                ></p-radioButton>
                <p-radioButton
                  name="option"
                  [disabled]="readonly"
                  [value]="false"
                  [(ngModel)]="enableLabourChess"
                  label="{{ 'payments.amount.no' | translate }}"
                  (onClick)="voucherGenerationDto.vendorTransactionDto.labourCessPercentage = 0;
                  calculateTotalAmount()"
                ></p-radioButton>
              </div>

              <div class="col-sm-3" *ngIf="enableLabourChess">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.labour.percentage.name"
                ></label
                ><br />
                <p-inputNumber
                  class="full-width"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto
                      .labourCessPercentage
                  "
                  mode="decimal"
                  [minFractionDigits]="1" 
                  [maxFractionDigits]="2"
                  name="name"
                  id="levelType"
                  [max]="28"
                  [readonly]="readonly"
                  (ngModelChange)="calculateTotalAmount()"
                ></p-inputNumber>
              </div>
              <div class="col-sm-5" *ngIf="enableLabourChess">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.labour.amount.name"
                ></label><br>
                            <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.labourCess
                  " [readonly]="true" name="name" id="levelType"></p-inputNumber>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-sm-4">
                            <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">{{
                    "payments.amount.select.deposit.name" | translate
                  }}</label
                ><br />
                <p-radioButton
                  name="option"
                  [value]="true"
                  [disabled]="readonly"
                  [(ngModel)]="enableSecurityDeposit"
                  (onClick)="
                    voucherGenerationDto.vendorTransactionDto.securityDeposit = 1;
                    calculateTotalAmount()
                  "
                  label="{{ 'payments.amount.yes' | translate }}"
                ></p-radioButton>
                <p-radioButton
                  name="option"
                  [value]="false"
                  [disabled]="readonly"
                  [(ngModel)]="enableSecurityDeposit"
                  label="{{ 'payments.amount.no' | translate }}"
                  (onClick)="
                    voucherGenerationDto.vendorTransactionDto.securityDeposit = 0;
                    calculateTotalAmount()
                  "
                ></p-radioButton>
              </div>

              <div class="col-sm-3" *ngIf="enableSecurityDeposit">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.security.percentage.name"
                ></label
                ><br />
                <p-inputNumber
                  class="full-width"
                  [max]="28"
                  inputId="locale-indian" mode="decimal" locale="en-IN"
                  [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.securityDeposit
                  "
                  mode="decimal"
                  [minFractionDigits]="1" 
                  [maxFractionDigits]="2"
                  name="name"
                  id="levelType"
                  [readonly]="readonly"
                  (ngModelChange)="calculateTotalAmount()"
                ></p-inputNumber>
              </div>
              <div class="col-sm-5" *ngIf="enableSecurityDeposit">
                <label
                  class="form-control-label pt-xs-2"
                  for="scheme"
                  [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.amount.security.amount.name"
                ></label><br>
                            <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto
                      .securityDepositAmount
                  " name="name" id="levelType" [readonly]="true"></p-inputNumber>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-4">
                            <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">{{
                            "payments.amount.select.flagDay" | translate
                          }}</label
                        ><br />
                        <p-radioButton
                          name="option"
                          [disabled]="readonly"
                          [value]="true"
                          [(ngModel)]="enableFlagDay"
                          (onClick)="
                            calculateTotalAmount()
                          "
                          label="{{ 'payments.amount.yes' | translate }}"
                        ></p-radioButton>
                        <p-radioButton
                          name="option"
                          [disabled]="readonly"
                          [value]="false"
                          [(ngModel)]="enableFlagDay"
                          label="{{ 'payments.amount.no' | translate }}"
                          (onClick)="voucherGenerationDto.vendorTransactionDto.flagDay = 0;
                          calculateTotalAmount()"
                        ></p-radioButton>
                      </div>
        
                     
                      <div class="col-sm-5" *ngIf="enableFlagDay">
                        <label
                          class="form-control-label pt-xs-2"
                          for="scheme"
                          [ngClass]="fontService.labelClass"
                          jhiTranslate="payments.amount.flagDay"
                        ></label><br>
                            <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                            voucherGenerationDto.vendorTransactionDto.flagDay
                          " (ngModelChange)="calculateTotalAmount()" [disabled]="readonly" name="name" id="levelType"></p-inputNumber>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 pt-lg-0 pt-xs-2 pt-sm-2">
                    <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label
            ><br />
            <textarea
              id="w3review"
              name="w3review"
              rows="3"
              [(ngModel)]="voucherGenerationDto.vendorTransactionDto.remark"
              [readonly]="
                enableSignButton ||
                voucherGenerationDto.vendorTransactionDto.signStatus
              "
              cols="100"
              [ngClass]="{
                'is-invalid':
                  ngSelectInvalid &&
                  !voucherGenerationDto.vendorTransactionDto.remark
              }"
            >
            </textarea>
          </div>

          <div class="col-lg-3 pt-lg-0 pt-xs-2 pt-sm-2">
            <table class="snippet">
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.grossAmount !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.net.amount.name" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.grossAmount
                    | currency:'INR':'symbol':'1.2-2':'en-IN' || "Nill"
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.totalCgst !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">CGST</td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.totalCgst
                    | currency:'INR':'symbol':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.totalSgst !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">SGST</td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.totalSgst
                    | currency:'INR':'symbol':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.totalIgst !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">IGST</td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.totalIgst
                    | currency:'INR':'symbol':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.netAmount !== 0 &&
                  (enableStateGst || enableIGst)
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.total.amount.name" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.netAmount
                    | currency:'INR':'symbol':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.tdsAmount !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">TDS</td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.tdsAmount
                    | currency:'INR':'symbol':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount !==
                  0
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.gst.amount.name" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount
                    | currency:'INR':'symbol':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.labourCess !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.labour.cess.name" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.labourCess
                    | currency:'INR':'symbol':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto
                    .securityDepositAmount !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.security.deposit.name" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto
                      .securityDepositAmount
                      | currency:'INR':'symbol':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto.flagDay !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.flagDay" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto.flagDay
                    | currency:'INR':'symbol':'1.2-2':'en-IN' || "Nill"
                  }}
                </td>
              </tr>
              <tr *ngIf="totalDeduction !== 0">
                <td [ngClass]="fontService.labelClass">
                  {{
                    "payments.amount.total.deduction.amount.name" | translate
                  }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{ totalDeduction | currency:'INR':'symbol':'1.2-2':'en-IN' }}
                </td>
              </tr>
              <tr
                *ngIf="
                  voucherGenerationDto.vendorTransactionDto
                    .transactionAmount !== 0
                "
              >
                <td [ngClass]="fontService.labelClass">
                  {{ "payments.amount.transaction.name" | translate }}
                </td>
                <td style="width: 110px; text-align: right">
                  {{
                    voucherGenerationDto.vendorTransactionDto
                      .transactionAmount
                      | currency:'INR':'symbol':'1.2-2':'en-IN'
                  }}
                </td>
              </tr>
              <!--   <div class="row " *ngIf="!readonly">
                                  <div class="col-sm-3 generate mt-3">
                                  
                                  </div>
                                      <button pButton pRipple label="Cancel" class="p-button-danger"></button>
                              </div> -->
            </table>
          </div>
        </div>
        <p-toolbar *ngIf="selectedVendor?.name && !enableRevertButton && !this.enableView">
          <div class="p-toolbar-group-start"></div>
          <div class="p-toolbar-group-end">
            <button *ngIf="!enableSignButton && !readonly" pButton pRipple class="p-button-success custom-button margin"
              (click)="genVocher()" styleClass="button">
              <span class="button-label" [ngClass]="fontService.buttonClass"
                jhiTranslate="common.generatevoucher"></span>
            </button>
            <button *ngIf="enableSignButton && !voucherGenerationDto.vendorTransactionDto.signaturePresident" class="p-button-success custom-button margin" 
          (click)="submitForm()">{{signButtonName}}</button>
          </div>
        </p-toolbar>
        
        <p-toolbar *ngIf="selectedVendor?.name && enableRevertButton && !this.enableView">
          <div class="p-toolbar-group-start"></div>
          <button *ngIf="enableSignButton && !voucherGenerationDto.vendorTransactionDto.signaturePresident" class="p-button-success custom-button margin" 
          (click)="submitForm()">{{signButtonName}}</button>
      
            <div
              *ngIf="
                (!enableSignButton &&(
                !voucherGenerationDto?.vendorTransactionDto?.signStatus || !voucherGenerationDto.vendorTransactionDto.signaturePresident) &&  !voucherGenerationDto.vendorTransactionDto.signaturePresident)
              "
              class="p-toolbar-group-end"
            >
              <button
                pButton
                pRipple
                (click)="revert()"
                class="p-button-warning custom-button"
              >
                <span
                  class="button-label"
                  [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.revertbutton"
                ></span>
              </button>
              <button
                *ngIf="!voucherGenerationDto.vendorTransactionDto.signStatus && !voucherGenerationDto?.vendorTransactionDto?.revertStatus && enableApproved"
                pButton
                pRipple
                (click)="genVocher()"
                class="p-button-success custom-button"
              >
                <span
                  class="button-label"
                  [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.approvebutton"
                ></span>
              </button>
              <button
                pButton
                pRipple
                (click)="reject()"
                class="p-button-danger custom-button"
              >
                <span
                  class="button-label"
                  [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.rejectbutton"
                ></span>
              </button>
            </div>
          
          <!-- <form *ngIf="enableSignButton || voucherGenerationDto.vendorTransactionDto.signStatus" ngNoForm
                          action="https://demosignergateway.emsigner.com/eMsecure/V3_0/Index" method="POST">
                          <input id="Parameter1" name="Parameter1" type="hidden" [value]='param1' />
                          <input id="Parameter2" name="Parameter2" type="hidden" [value]='param2' />
                          <input id="Parameter3" name="Parameter3" type="hidden" [value]='param3' />

                          <div class="container text-center mt-10">
                              <button class="p-button-success custom-button margin" type="submit"
                                  onclick="submit()">{{signButtonName}}</button>
                          </div>
                      </form> -->
        
         
         
        </p-toolbar>
        <p-toolbar *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident">
          <div class="p-toolbar-group-start">
              <p *ngIf="this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus === 'Failed'" style="font-size: 18px;color:red;font-weight:700;">Payment Has Been {{this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus}}</p>
              <p *ngIf="this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus === 'Success'" style="font-size: 18px;color:green;font-weight:700;">Payment Has Been {{this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus}}</p>
          </div>
          <button *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident && roleCode.role.code !== 'DAD'  && voucherGenerationDto?.vendorTransactionDto?.transactionStatus !== 'Failed'" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
      </p-toolbar>
      </div>
    </div>
  </div>
  <p-dialog
    [(visible)]="profileDialog"
    [style]="{ width: '1000px' }"
    [modal]="true"
    [baseZIndex]="1000"
    styleClass="ib-dialog"
  >
    <p-header [ngClass]="fontService.headingClass">
     {{ "payments.lastTransaction" | translate }}
    </p-header>
    
    <p-table *ngIf="!noRecord"
      [value]="searchResult.items"
      [resizableColumns]="true"
      styleClass="dynamic-table p-datatable-gridlines"
      responsiveLayout="scroll"
    >
      <ng-template pTemplate="header">
        <tr>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.voucherNo" | translate }}
          </th>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.vendorName" | translate }}
          </th>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.vendorAccount" | translate }}
          </th>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.transactionDate" | translate }}
          </th>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.rate" | translate }}
          </th>
          <th [ngClass]="fontService.labelClass">
            {{ "payments.viewTable.totTransAmount" | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          <td>{{ data.vendorTransaction.voucherNo }}</td>
          <td>{{ data.vendorTransaction.commercialVendorName }}</td>
          <td>{{ data.vendorTransaction.commercialAccountNo }}</td>
          <td>{{ data.vendorTransaction.transDate | date : "shortDate" }}</td>
          <td>{{ data.vendorTransaction.grossAmount }}</td>
          <td>{{ data.vendorTransaction.transactionAmount }}</td>
        </tr>
      </ng-template>
    </p-table>
    <div *ngIf="noRecord">
      <h5 class="text-center"  [ngClass]="fontService.regularClass" jhiTranslate="error.norecord">No transactions found</h5>
    </div>
  </p-dialog>
  <p-dialog
    [(visible)]="paymentSucessDialog"
    [style]="{ width: '500px', height: '225px' }"
    [modal]="true"
    [baseZIndex]="1000"
    styleClass="ib-dialog"
  >
    <p-header [ngClass]="fontService.headingClass">
      Payment Alert Box
    </p-header>
    <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
    <ng-template pTemplate="footer">
      <button
        pButton
        pRipple
        class="p-button-text custom-button"
        (click)="paymentSucessDialog = false"
      >
        <span
          class="button-label"
          [ngClass]="fontService.buttonClass"
          label="Ok"
          (click)="redirect()"
          >Ok</span
        >
      </button>
    </ng-template>
  </p-dialog>
  
  <p-dialog
    [(visible)]="viewDoc"
    [style]="{ width: '1000px', height: '800px' }"
    [modal]="true"
    [baseZIndex]="1000"
    styleClass="ib-dialog"
  >
  <ng-template pTemplate="body">
    <div style='position: relative; height: 100%;'>
      <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"
              
    ></ngx-extended-pdf-viewer>
      <img *ngIf="vFileExt !== 'pdf'"  [src]="vFileUrl" style="display: block;" height="800px" />
    </div>
  </ng-template>
  </p-dialog>

  <p-dialog
    [(visible)]="deleteDialog"
    [style]="{ width: '500px', height: '200px' }"
    [modal]="true"
    [baseZIndex]="1000"
    styleClass="ib-dialog"
    (onHide)="hideDialog()"
  >
    <p-header [ngClass]="fontService.headingClass">
     <b class="text-danger">Reject Dialog</b>
    </p-header>
    <p>Something went wrong with the voucher, please reject the voucher</p>
    <ng-template pTemplate="footer">
      <button pButton pRipple
        class="p-button-danger">
        <span
          class="button-label"
          [ngClass]="fontService.buttonClass"
          label="Reject"
          (click)="reject()"
          >Ok</span
        >
      </button>
    </ng-template>

  </p-dialog>