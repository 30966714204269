<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">{{headingText}}</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row py-10 pb-2">
        <p-calendar [(ngModel)]="month" view="month" [placeholder]="month ? '' : 'Month'" styleClass="datepicker" dateFormat="mm/yy"></p-calendar>
        <ng-select *ngIf="deductionTypeList" [placeholder]="deductionType ? '' : 'Deduction Type'" id="statutoryType" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [items]="deductionTypeList" [(ngModel)]="deductionType">
        </ng-select>
        <button pButton pRipple label="Search" (click)="loadPage(1)" class="p-button-sm"></button>
        <button pButton pRipple label="Clear" (click)="onFilterClear()" class="p-button-sm"></button>
    </div>
    <div class="row">
        <div [hidden]="totalCount === 0" class="col-12 px-4">
            <p-table [value]="deductionList" [resizableColumns]="true" [paginator]="true" (onLazyLoad)="loadFilterValues($event)" [rows]="itemsPerPage" [showCurrentPageReport]="true" [lazy]="true" [totalRecords]="totalCount" [lazyLoadOnInit]="true" responsiveLayout="scroll"
                [tableStyle]="{ width: 'max-content'}" [reorderableColumns]="true" selectionMode="single" [rowsPerPageOptions]="[1,10,20,30]" [currentPageReportTemplate]="totalCount > 0 ? 'Showing {first} - {last} of {totalRecords} items' : 'No Items Found'"
                [first]="first" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>

                        <th style="text-align: center;">S.No</th>
                        <th style="text-align: center;">Queue No</th>
                        <th style="text-align: center;">Voucher No</th>
                        <th style="text-align: center;">VendorName</th>
                        <th style="text-align: center;">GST Number</th>
                        <th style="text-align: center;">PAN Number</th>
                        <th style="text-align: center;">Deduction%</th>
                        <th style="text-align: center;">Deduction Amount</th>
                        <th style="text-align: center;">Status</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-index="rowIndex" let-list>
                    <tr>
                        <td style="text-align: center;">{{index+1}}</td>
                        <td style="text-align: center;">{{ list.queueNo }}</td>
                        <td style="text-align: center;">{{ list.voucherNo}}</td>
                        <td style="text-align: center;">{{ list.vendorName}}</td>
                        <td style="text-align: center;">{{ list.gstNo}}</td>
                        <td style="text-align: center;">{{list.panNo}}</td>
                        <td style="text-align: center;">{{list.deductionPercentage}}</td>
                        <td style="text-align: center;">{{list.deductionAmount}}</td>
                        <td style="text-align: center;">{{list.status}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="totalAmount">
                        <td style="text-align: right;" colspan="6">Total</td>
                        <td style="text-align: center;">{{totalAmount}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

</div>