<div class="row">
    <div class="col-md-12">
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <h5>
                    Account Balance
                </h5>
            </ng-template>
        </p-toolbar>
    </div>
</div>

<div class="row px-4 mt-2">
    <div *ngIf="sessionStorageService.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE'" class="col-2">
        <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">District</label>
        <ng-select id="scheme" [(ngModel)]="districtMaster" [items]="districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
        </ng-select>
    </div>
    <div *ngIf="sessionStorageService.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE' || sessionStorageService.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT'" class="col-2">
        <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Block</label>
        <ng-select id="scheme" [(ngModel)]="blockMaster" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
        </ng-select>
    </div>
    <div *ngIf="sessionStorageService.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE' && sessionStorageService.retrieve('roleCode') !== 'ZVM'" class="col-2">
        <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Village</label>
        <ng-select id="scheme" [items]="villageLevelList" bindLabel="name" bindValue="id" appearance="outline" (search)="onBlockLevelChange($event)" [(ngModel)]="levelMasId" (change)="onVillageChange()" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
        </ng-select>
    </div>
    <div *ngIf="sessionStorageService.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE' && sessionStorageService.retrieve('roleCode') === 'ZVM'" class="col-lg-2 col-md-4 col-sm-3">
        <label class="form-control-label req" for="Village" [ngClass]="fontService.labelClass">Village</label>
        <ng-select id="gender" bindLabel="levelMasterName" bindValue="levelMasterId" [items]="villageList" appearance="outline" [(ngModel)]="levelMasId" (change)="onVillageChange()" [closeOnSelect]="true" [clearable]="false" class="form-control">
        </ng-select>
    </div>
    <div *ngIf="sessionStorageService.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE'" class="col-1" style="display: flex;align-items: flex-end;">
        <button pButton pRipple class="p-button-success custom-button" (click)="search()" styleClass="button">
  <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.searchbutton">Search</span>
</button>
    </div>
    <div *ngIf="sessionStorageService.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE'" class="col-1" style="display: flex;align-items: flex-end;">
        <button pButton pRipple class="p-button-danger custom-button">
  <span class="button-label" [ngClass]="fontService.buttonClass" (click)="isZonal = false;districtMaster = null; blockMaster = null; levelMasId = null;" jhiTranslate="common.clearbutton">clear</span>
</button>
    </div>
</div>
<div *ngIf="isZonal" class="btn_class">

    <button pButton pRipple class="fetch_btn mx-2" (click)="getAccountBalance()">Fetch Account Balance</button>

</div>

<div *ngIf="isZonal" class="row">
    <div class="col-md-12 mt-4 mx-4">
        <p-table *ngIf="isTable" [value]="tabledata">
            <ng-template pTemplate="header">
                <tr>
                    <th>Village Name</th>
                    <th>LGD Code</th>
                    <th> Balance</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-index="rowIndex" let-list>
                <tr>
                    <td>{{list.villageName}}</td>
                    <td>{{list.lgdCode}}</td>

                    <td>{{list.accountBalance}}</td>

                </tr>
            </ng-template>
        </p-table>
    </div>

</div>