import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import {
  commercial,
  CommercialVendors,
  CommercialVendorsDto,
} from './commercial-vendors';

@Injectable()
export class CommercialVendorService {
  protected resourceFliterUrl =
    environment.SERVER_API_URL + 'api/getCommercialVendorByFilter';
  protected resourceSaveUrl =
    environment.SERVER_API_URL + 'api/saveCommercialVendor';
  protected resourceDeleteUrl =
    environment.SERVER_API_URL + 'api/deleteCommercialVendor';
  protected CommercialListUrl =
    environment.SERVER_API_URL + 'api/commercialList';
  protected commercialGetUrl =
    environment.SERVER_API_URL + 'api/getCommercialVendor';
  protected resourceValidFliterUrl =
    environment.SERVER_API_URL + 'api/getCommercialVendorValidByFilter';
  protected getBankUrl = environment.SERVER_API_URL + 'api/getBankList';
  protected getBankBranchUrl = environment.SERVER_API_URL + 'api/getBankBranchByFilter';
  protected getIfscByBankBranchUrl = environment.SERVER_API_URL + 'api/getIfscByBankBranch';
  protected accountValidationUrl = environment.SERVER_API_URL + 'api/accountValidation';
  protected bankBranchDetailsUrl = environment.SERVER_API_URL + 'api/bankBranchDetails';
  protected waitingForApprovalUrl = environment.SERVER_API_URL + 'api/commercialVendor/waitingForApproval';
  protected updateStatusUrl = environment.SERVER_API_URL + 'api/commercialVendor/updateStatus';

  constructor(protected http: HttpClient) { }
  filter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<any[]>(this.resourceFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  validFilter(req?: any, filter?: any): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<any[]>(this.resourceValidFliterUrl, filter, {
      params: options,
      observe: 'response',
    });
  }
  save(profile: CommercialVendorsDto, status: Boolean): Observable<any> {
    return this.http.post<CommercialVendorsDto>(`${this.resourceSaveUrl}?status=${status}`, profile, {
      observe: 'response',
    });
  }
  getcommercialVendor(id: number): Observable<HttpResponse<any>> {
    return this.http.get<CommercialVendorsDto>(
      `${this.commercialGetUrl}?id=${id}`,
      { observe: 'response' }
    );
  }
  getCommerical(): Observable<any> {
    return this.http.get<commercial[]>(this.CommercialListUrl, {
      observe: 'response',
    });
  }

  getBankList(): Observable<any> {
    return this.http.get<any[]>(this.getBankUrl, {
      observe: 'response',
    });
  }


  getBankBranchList(filter, req): Observable<any> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<any[]>(this.getBankBranchUrl, filter, {
      params: options,
      observe: 'response',
    });

  }

  getBankIfscCode(bank: string, branch?: string): Observable<any> {
    return this.http.get<any[]>(`${this.getIfscByBankBranchUrl}?bank=${bank}&branch=${branch}&page=0&size=10`, {
      observe: 'response',
    });
  }
  getaccountValidation(account: string, ifscCode?: string): Observable<any> {
    return this.http.get(`${this.accountValidationUrl}?accountNo=${account}&ifscode=${ifscCode}`, { observe: 'response' });
  }
  bankBranchDetails(ifscCode?: string): Observable<any> {
    return this.http.get(`${this.bankBranchDetailsUrl}?ifscCode=${ifscCode}`, { observe: 'response' });
  }

  delete(id: number): Observable<any> {
    return this.http.get(`${this.resourceDeleteUrl}?id=${id}`, {
      observe: 'response',
    });
  }
  // save(profile?:Profile): Observable<EntityArrayResponseType> {
  //     return this.http
  //     .post<Profile[]>(this.resourceSaveUrl,profile, {observe: 'response' })
  // }
  // delete(id: number): Observable<HttpResponse<{}>> {
  //   return this.http.delete(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
  // }

  waitingForApproval(): Observable<any> {
    return this.http
      .get<any>(this.waitingForApprovalUrl, { observe: 'response' })
  }

  updateStatus(id:number,status): Observable<any> {
    return this.http.get(`${this.updateStatusUrl}?id=${id}&approvalAction=${status}`, { observe: 'response',responseType:'text' });
  }
}
