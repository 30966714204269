<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.tneb"></h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <button pButton pRipple label="VP Wise TANGEDCO" (click)="downloadExport()" class="p-button-success p-button-sm export-button"></button>
        <button pButton pRipple label="Check Status" (click)="checkStatus()" class="p-button-success p-button-sm"></button>
        <button pButton pRipple label="Export to Excel" (click)="export()" class="p-button-success p-button-sm"></button>
    </div>
    <div class="row">
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
            <p-tabPanel header="District" *ngIf="levelType === 'STATE'">
                <div class="col-12 px-4" *ngIf="tnebsummary">
                    <p-table id="district-table" [value]="tnebsummary" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Block Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Village Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Consumer No Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Consumer No Active Connection Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Consumer No Inactive Connection Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Total Due Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Paid Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Balance Amount</th>

                            </tr>


                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadBlock(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.blockCount}}</td>
                                <td style="text-align: center;">{{list.villageCount}}</td>
                                <td style="text-align: center;">{{list.consumerNoCount}}</td>
                                <td style="text-align: center;">{{list.approvedCount}}</td>
                                <td style="text-align: center;">{{list.unapprovedCount}}</td>
                                <td style="text-align: center;">{{list.totalDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.paidAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.balanceAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                            </tr>
                        </ng-template>

                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Block" *ngIf="levelType === 'STATE' || levelType === 'DISTRICT'">
                <div *ngIf="!tnebsummaryBlock">Choose distict to get block report</div>
                <div *ngIf="tnebsummaryBlock" class="col-12 px-4">
                    <p-table id="block-table" [value]="tnebsummaryBlock" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Block Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Village Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Consumer No Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Consumer No Active Connection Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Consumer No Inactive Connection Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Total Due Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Paid Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Balance Amount</th>

                            </tr>


                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadVillage(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.villageCount}}</td>
                                <td style="text-align: center;">{{list.consumerNoCount}}</td>
                                <td style="text-align: center;">{{list.approvedCount}}</td>
                                <td style="text-align: center;">{{list.unapprovedCount}}</td>
                                <td style="text-align: center;">{{list.totalDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.paidAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.balanceAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Village">
                <div *ngIf="!tnebsummaryVillage">Choose block to get village report</div>
                <div *ngIf="tnebsummaryVillage" class="col-12 px-4">
                    <p-table id="village-table" [value]="tnebsummaryVillage" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Village Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Consumer No Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Consumer No Active Connection Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Consumer No Inactive Connection Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Total Due Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Paid Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Balance Amount</th>

                            </tr>


                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadPage(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.consumerNoCount}}</td>
                                <td style="text-align: center;">{{list.approvedCount}}</td>
                                <td style="text-align: center;">{{list.unapprovedCount}}</td>
                                <td style="text-align: center;">{{list.totalDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.paidAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.balanceAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Consumer Demand">
                <div *ngIf="searchResult.total === 0">Choose village to get consumer Demand report</div>
                <div *ngIf="searchResult.total !== 0" class="col-12 px-4">
                    <div #customerTable id="entities" class="col-12 px-4">
                        <custom-filter-table id="consumer-table" [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false" (showTable)="getSubTableDetails($event)"
                            [showDialogTable]="true"></custom-filter-table>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <!-- <p-header [ngClass]="fontService.headingClass">
  {{ "dashBoard.transactionlist" | translate }}
</p-header> -->
    <custom-filter-table [columnsValues]="vouchercols" [filterSearchResult]="tangedcosearchResult" [disableDeleteIcon]="true" [paginator]="false" [disableEditIcon]="true"></custom-filter-table>
</p-dialog>