import { Component } from '@angular/core';
import { ReportService } from '../report.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageService } from 'ngx-webstorage';
import { NotificationService } from 'src/app/alert/notification.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-twad-payment-report',
  templateUrl: './twad-payment-report.component.html',
  styleUrl: './twad-payment-report.component.scss'
})
export class TwadPaymentReportComponent {
  activeIndex = 0;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any = [];
  villageId: any;
  cols: any;
  enableStatusVoucher: boolean = false;
  cosumerDemandId: any;
  vouchercols: any;
  roleCode: any;
  levelMasterId: any;
  isDMRole: boolean;
  twadSummary: any;
  twadSummaryBlock: any;
  twadSummaryVillage: any;
  levelType: any;

  constructor(
    protected reportService: ReportService,
    public fontService: FontService,
    private spinner: NgxSpinnerService,
    private sessionStorageService: SessionStorageService,
    protected notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.roleCode = this.sessionStorageService.retrieve('roleCode');
    this.levelMasterId = this.sessionStorageService.retrieve('levelmasterId');
    this.levelType = this.sessionStorageService.retrieve('levelmaster')?.levelTypeDto?.name?.toUpperCase()
    switch (this.levelType) {
      case 'STATE':
        this.loadDistrict();
        break;
      case 'DISTRICT':
        this.loadBlock(this.levelMasterId);
        break;
      case 'BLOCK':
        this.loadVillage(this.levelMasterId);
        break;
    }

  }

  loadDistrict() {
    const payload: any = {
      levelType: "STATE",
      levelMasterId: 1
    };
    this.reportService.getTwadDemandReport(payload).subscribe(
      (res) => {
        console.log('res.body', res.body);
        this.twadSummary = res.body;
      },
      (err) => {

      }
    )




  }

  loadBlock(districtId) {
    const payload: any = {
      levelType: "DISTRICT",
      levelMasterId: districtId
    };
    this.reportService.getTwadDemandReport(payload).subscribe(
      (res) => {
        console.log('res.body', res.body);
        this.twadSummaryBlock = res.body;
        if (this.levelType === 'STATE') {
          this.activeIndex = 1;
        } else {
          this.activeIndex = 0;
        }

      },
      (err) => {

      }
    )
  }

  loadVillage(blockId) {
    const payload: any = {
      levelType: "BLOCK",
      levelMasterId: blockId
    };
    if(this.roleCode === 'ZVM') {
      payload['zonalName'] = this.sessionStorageService.retrieve('user')?.zonalName;
    }
    this.reportService.getTwadDemandReport(payload).subscribe(
      (res) => {
        console.log('res.body', res.body);
        this.twadSummaryVillage = res.body;
        if (this.levelType === 'STATE') {
          this.activeIndex = 2;
        } else if(this.levelType === 'DISTRICT') {
          this.activeIndex = 1;
        }
      },
      (err) => {

      }
    )
  }




  onTabChange($event) {
  }



  export() {
    switch (this.levelType) {
      case 'STATE':
        if (this.activeIndex === 0) {
          this.exportToExcel('district-table', 'DistrictWiseTWADbillReport.xlsx');
        } else if (this.activeIndex === 1) {
          this.exportToExcel('block-table', 'BlockWiseTWADbillReport.xlsx');
        } else if (this.activeIndex === 2) {
          this.exportToExcel('village-table', 'VillageWiseTWADbillReport.xlsx');
        }
        break;
      case 'DISTRICT':
        if (this.activeIndex === 0) {
          this.exportToExcel('block-table', 'BlockWiseTWADbillReport.xlsx');
        } else if (this.activeIndex === 1) {
          this.exportToExcel('village-table', 'VillageWiseTWADbillReport.xlsx');
        }

        break;
      case 'BLOCK':
        console.log('jddhhdhdhd',this.activeIndex);
        if (this.activeIndex === 0) {
          this.exportToExcel('village-table', 'VillageWiseTWADbillReport.xlsx');
        }
        break;
    }





  }

  exportToExcel(id, filename): void {
    let element = document.getElementById(id);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
  }


  checkStatus() {
    let filter = {
      reportName: 'Twad Payment Report',

    };
    this.spinner.show();
    this.reportService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body
        this.saveBlobToExcel(b.data, 'VP Wise TWAD');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.message, '');
        this.spinner.hide();
      }
    );
  }
  downloadExport() {
    let payLoad = {
      levelMasterId: this.levelMasterId,
      levelType: this.sessionStorageService.retrieve('levelmaster')?.levelTypeDto?.name?.toUpperCase(),
      reportName: 'Twad Payment Report',
    }
    if(this.roleCode === 'ZVM') {
      payLoad['zonalName'] = this.sessionStorageService.retrieve('user')?.zonalName;
    }
    this.spinner.show();
    this.reportService.reportAsyncDownload({ ...payLoad }).subscribe(
      (res) => {
        this.notificationService.alertSuccess(res.body, '');
        this.spinner.hide();
      },
      (onError) => {
        onError.error = JSON.parse(onError.error);
        this.notificationService.alertError(onError.error.message, '');
        this.spinner.hide();
      }
    );
  }


  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }
}
