import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ZonalService } from './zonal.service';
import { Zonal, ZonalDto } from './zonal';
import { SearchResult } from 'src/app/shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { LevelTypeService } from '../level-type/level-type.service';
import { DepartmentListService } from '../department-list/department-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { LevelType } from '../level-type/level-type';
import { LevelMasterService } from '../level-master/level-master.service';
import { LevelMasterDto } from '../level-master/level-master';
import { FontService } from 'src/app/shared/font-service/font.service';

@Component({
  selector: 'app-zonal',
  templateUrl: './zonal.component.html',
  styleUrls: ['./zonal.component.scss'],
})
export class ZonalComponent implements OnInit {
  zonalDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Zonal> = new SearchResult<Zonal>();

  zonal?: ZonalDto;

  selectedZonal: Zonal[] = [];

  submitted?: boolean;

  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  levelType: LevelType[];
  levelTypeDto:LevelType
  schemeDto:SchemeList
  departmentDto:DepartmentList
  stateList: LevelMasterDto[];
  stateListDto: any;
  districtLevelList: LevelMasterDto[];
  districtListDto: any;
  blockLevelList: LevelMasterDto[];
  BlockLevelDto:LevelMasterDto
  selectedZonalName:any
  villageList:any



  constructor(
    private zonalService: ZonalService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private departmentListService: DepartmentListService,
    private levelMasterService: LevelMasterService,
    public fontService:FontService
  ) {}

  zonalList:any=[{name:"Zonal-1"},{name:"Zonal-2"},{name:"Zonal-3"},{name:"Zonal-4"},{name:"Zonal-5"}]

  ngOnInit() {
    this.cols = [
      {
        field: 'blockLevelMaster',
        header: 'Block',
        jhiTranslate: 'tableHead.block.name',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'villageLevelMaster',
        header: 'Village',
        jhiTranslate: 'tableHead.village.name',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'name',
        header: 'Zone',
        jhiTranslate: 'tableHead.zone.name',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
      },
    ];
  }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {}
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {}
    );
    
  }
  onchangeSchema(){
    let filterQuery = {
      page: 0,
      size: 10,
    };
    this.levelTypeService
        .levelTypefilter({ ...filterQuery }, [
          {
            key: 'name',
            operation: 'contains',
            value: "VILLAGE",
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelType[]>) => {
            this.levelType = res.body;
          },
          () => {}
        );

  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? [];
    this.zonalService.filter({ ...filterQuery }, this.filterProps).subscribe(
      (res: HttpResponse<Zonal[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: Zonal[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    if (navigate) {
      this.router.navigate(['/zonal'], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,
        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  openNew() {
    this.zonal = new ZonalDto();
    this.submitted = false;
    this.zonalDialog = true;
    this.loadValue()
  }

 

 

 

  hideDialog() {
    this.zonalDialog = false;
    this.submitted = false;
  }

  saveData() {
    this.zonal.name = this.selectedZonalName.name
    this.zonal.blockLevelMaster = this.BlockLevelDto
    const selectedvillageList: string[] = [];
    this.villageList.forEach(element => {
      selectedvillageList.push(element.id.toString())
    });
    this.zonal.villageFilter = selectedvillageList
    this.zonalService
			.save(this.zonal)
			.subscribe(
				(res: HttpResponse<any[]>) => {
					this.zonalDialog = false;
					this.loadPage(this.page)
				},
				() => {
					this.onError();
				}
			);


    
  }

  onLevelType() {
    
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'id',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.stateList = res.body;
          },
          () => {}
        );
    
  }
  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.stateListDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.stateListDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }
 
  onDistrictLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtListDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtListDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onBlockLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }
}
