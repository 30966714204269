<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Reconciliation Report
                    </h5>
                </ng-template>

            </p-toolbar>
        </div>
    </div>
    <div class="row px-4">
        <div class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Financial Year</label>
            <ng-select id="scheme" [(ngModel)]="finYear" [items]="finYearList" bindLabel="name" appearance="outline" (change)="onchangeFinYear()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE'" class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">District</label>
            <ng-select id="scheme" [(ngModel)]="districtMaster" [items]="districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE' || sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT'" class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Block</label>
            <ng-select id="scheme" [(ngModel)]="blockMaster" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE' && sessionStorage.retrieve('roleCode') !== 'ZVM'" class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="scheme" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" [(ngModel)]="villagelevelMaster" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE' && sessionStorage.retrieve('roleCode') === 'ZVM'" class="col-lg-2 col-md-4 col-sm-3">
            <label class="form-control-label req" for="Village" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="gender" bindLabel="levelMasterName" bindValue="levelMasterId" [items]="villageList" appearance="outline" [(ngModel)]="levelMasId" [closeOnSelect]="true" [clearable]="false" class="form-control">
            </ng-select>
        </div>
        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-success custom-button" (click)="loadPage('YEAR')" styleClass="button">
  <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.searchbutton">Search</span>
</button>
        </div>
        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-danger custom-button">
  <span class="button-label" [ngClass]="fontService.buttonClass" (click)="this.search = false;districtMaster = null; blockMaster = null; villagelevelMaster = null; finYear = defaultFinyear; fromDate = null;toDate = null;month=null;monthfinancialData=null;financialData=null;" jhiTranslate="common.clearbutton">clear</span>
</button>
        </div>

        <div *ngIf="search" class="col-lg-2 col-md-4 col-sm-3">
            <label  class="form-control-label req" for="month" [ngClass]="fontService.labelClass">Month</label>
        <ng-select id="month" [items]="months" appearance="outline" [(ngModel)]="month" [closeOnSelect]="true" (change)="calculateDates()" [clearable]="false" class="form-control">
        </ng-select>
        </div>
        
       

    </div>
   
    <div *ngIf="search" class="row mt-2">
        <div class="col-12">
            <h3>Fin Year: {{finYear?.name}}</h3>
        <p-table [value]="financialData" class="p-treetable-gridlines">
            <ng-template pTemplate="caption">
                <div class="text-xl font-bold row">
                    <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE'" class="col-4">
                        <strong>District:</strong> {{ districtMaster?.name }}
                    </div>
                    <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE' || sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT'" class="col-4">
                        <strong>Block:</strong> {{ blockMaster?.name }} 
                    </div>
                    <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() !== 'VILLAGE'" class="col-4">
                        <strong>Village:</strong> {{ villagelevelMaster?.name }} 
                    </div>
                </div> 
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="2" style="text-align: center;">Receipt</th>
                    <th colspan="2" style="text-align: center;">Expenditure</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-entry>
                <tr>
                    <td>{{ entry.category }}</td>
                    <td>{{ entry.amount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                    <td>{{ entry.expenditure}}</td>
                    <td>{{ entry.expenditureAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                </tr>
            </ng-template>
        </p-table>
        </div>
        
    </div>
    <div *ngIf="search" class="row mt-2">
        <div class="col-12">
            <h3>Month: {{month}}</h3>
            
        <p-table *ngIf="monthfinancialData.length !== 0" [value]="monthfinancialData" class="p-treetable-gridlines">
            <ng-template pTemplate="caption">
                <div class="text-xl font-bold row">
                    <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE'" class="col-4">
                        <strong>District:</strong> {{ districtMaster?.name }}
                    </div>
                    <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE' || sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT'" class="col-4">
                        <strong>Block:</strong> {{ blockMaster?.name }} 
                    </div>
                    <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() !== 'VILLAGE'" class="col-4">
                        <strong>Village:</strong> {{ villagelevelMaster?.name }} 
                    </div>
                </div> 
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="2" style="text-align: center;">Receipt</th>
                    <th colspan="2" style="text-align: center;">Expenditure</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-entry>
                <tr>
                    <td>{{ entry.category }}</td>
                    <td>{{ entry.amount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                    <td>{{ entry.expenditure}}</td>
                    <td>{{ entry.expenditureAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                </tr>
            </ng-template>
        </p-table>
        </div>
        
    </div>

</div>