import { HttpResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { BeneficiaryService } from 'src/app/module/beneficiary-creation/beneficiary-service.service';
import { beneficiaryDto } from 'src/app/module/beneficiary-creation/beneficiary.model';
import { Vendor } from 'src/app/module/commercial-vendors/commercial-vendors';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import { ComponentType } from 'src/app/module/component-type/component-type';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { DepartmentList } from 'src/app/module/department-list/department-list';
import { DepartmentListService } from 'src/app/module/department-list/department-list.service';
import { Employee, EmployeeDTO } from 'src/app/module/employee/employee.model';
import { EmployeeService } from 'src/app/module/employee/employee.service';
import { LevelType } from 'src/app/module/level-type/level-type';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { PersonalVendorsDto } from 'src/app/module/personal-vendors/personal-vendors';
import { PersonalVendorService } from 'src/app/module/personal-vendors/personal-vendors.service';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { SchemeListService } from 'src/app/module/scheme-list/scheme-list.service';
import { UserService } from 'src/app/module/user/user.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { environment } from 'src/environments/environment';
import { paymentVoucherGen, VendorTransaction, vendorTransactionDto, PersonalDescDto, beneficiaryDescDto } from '../payment-voucher/payment-voucher.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-thooimai-kavalar-payment-voucher',
  templateUrl: './thooimai-kavalar-payment-voucher.component.html',
  styleUrl: './thooimai-kavalar-payment-voucher.component.scss'
})
export class ThooimaiKavalarPaymentVoucherComponent {
  @ViewChild('dialog') dialog: Dialog;

  enableStateGst: boolean;
  enableIGst: boolean;
  departmentList: DepartmentList[];
  totalDeduction: number;

  // validation fields properties
  public ifscError = false;

  //required fields property
  ngSelectInvalid: boolean = false;
  dropDownLabel: string;
  enableNhis: boolean;
  enableCps: boolean;
  enableOtherDeduction: boolean;
  roleCode: any;
  readonly: boolean;
  enableRevertButton: boolean;
  description: string;
  amount: number;
  quantity: number;
  signButtonName: string;
  downloadButton: boolean;
  param1: any;
  param2: any;
  param3: any;

  beneficiary
  enableSignButton: boolean;
  employeeList: Employee;
  enableEmployee: boolean;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  enableRePaymentButton: boolean;
  disablepaymentButton: boolean = true;
  buttonText: string;
  interactionId: any;
  checkstatusButton: boolean;
  checkAmount: boolean;
  zonalList: LevelType;
  selectedZonalUser: any
  enableZonalButton: boolean;
  enableApproved: boolean;
  checkForPaymentStatus: boolean;
  filterTimeout: any;
  typedValue: any;
  componentMasterTable: any;
  currentPage: number = 0;
  loadingMore: boolean = false;
  hasMoreItemsToLoad: boolean = true;
  vendorSearchOption: any = 'vendorSelectOption';
  accountNo: string;
  uniqueId: any;
  deleteDialog: boolean;
  enableView: boolean;
  bulkVoucherDialog: boolean;
  finYear: any;
  finYearList: FinYear[];
  beneficiaryList: any;
  constructor(
    protected activatedRoute: ActivatedRoute,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private commercialVendorService: CommercialVendorService,
    private paymentService: PaymentService,
    private personalVendorService: PersonalVendorService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private employeeService: EmployeeService,
    private userService: UserService,
    private sessionStorageService: SessionStorageService,
    private limitTransListService: LimitTransListService,
    private beneficiaryService: BeneficiaryService,
  ) {

    this.voucherGenerationDto = new paymentVoucherGen();
    this.voucherGenerationDto.vendorTransactionDto = new vendorTransactionDto();
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
      this.componentTypeService
        .find(
          this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto
            .leveltype
        )
        .subscribe(
          (res: HttpResponse<any>) => {
            if (this.roleCode.role.name.startsWith('Village')) {
              this.grantLevel = res.body.filter(item => item.name === 'Village Thooimai Kavalar');
            } else if (this.roleCode.role.name.startsWith('Block')) {
              this.grantLevel = res.body.filter(item => item.name === 'Block Minimum Grant' || item.name === 'Panchayat Union General Fund');
            } else if (this.roleCode.role.name.startsWith('District')) {
              this.grantLevel = res.body.filter(item => item.name === 'District Admin Grant' || item.name === 'District Population Grant');
            } else {
              this.grantLevel = res.body;
            }
          },
          () => { }
        );
      this.majaorComponent = this.componentMasterTable.filter(item => item.componentTypeDto.id === this.grantlevel.id && item.parentId === 0);
      this.minorComponent = this.componentMasterTable.filter(item => item.parentId === this.majaorComponentdto.id && !item.name.startsWith('Salary'));
      this.subComponent = this.componentMasterTable.filter(item => item.parentId === this.minorComponentDto.id);

    });
  }

  schemeList: SchemeList[];

  selectedVendor: PersonalVendorsDto;
  // selectedVendor:beneficiaryDto;
  levelType: LevelType[];
  grantLevel: any;
  grantlevel: any;
  levelMasterDto: LevelType;
  voucherGenerationDto: paymentVoucherGen;
  minorComponent: any;
  minorComponentDto: any;
  subComponent: any;
  subComponentDto: any;
  date: Date;
  voucherNo: string;
  majaorComponent: any;
  majaorComponentdto: any;
  vendorList: Vendor[];
  selectedscheme: any;
  profileDialog: boolean = false;
  // descriptionList: DescriptionDto[];
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  noRecord: boolean;
  selectedEmployee: EmployeeDTO;
  cols = [
    {
      field: 'voucherNo',
      header: 'Voucher No',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'permanentVoucherNumber',
      header: 'Permanent Voucher Number',
      isFilterable: true,
      isSelectcolumn: true,
      jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
    },
    {
      field: 'personalVendorName',
      header: 'Vendor Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'personalAccountNo',
      header: 'Vendor Account',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'transDate',
      header: 'Transaction Date',
      isSortable: true,
      isSelectcolumn: true,
      type: 'date',
    },
    {
      field: 'grossAmount',
      header: 'Gross Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
    },
    {
      field: 'transactionAmount',
      header: 'Total Transaction Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
    },
  ];



  async ngOnInit(): Promise<void> {
    // console.log(this.beneficiaryAccnoSearchOption,this.beneficiaryUnIdSearchOption,this.beneficiarySelectOption)
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    this.componentMasterTable = this.sessionStorageService.retrieve('componentMasterTable');
    this.grantLevel = this.sessionStorageService.retrieve('componentTypeTable');
    if (!this.componentMasterTable) {
      await this.loadComp();
    }
    this.roleCode = this.sessionStorageService.retrieve('user');
    this.levelMasterDto = this.sessionStorageService.retrieve('levelMaster').levelTypeDto;
    this.spinner.show()

    if (
      this.roleCode.role.code === 'DMAKER' ||
      this.roleCode.role.code === 'VMAKER' ||
      this.roleCode.role.code === 'BMAKER'
    ) {
      if (this.roleCode.role.code === 'BMAKER') {
        this.paymentService
          .getZonalCheckers(
            this.roleCode.levelMasterId
          )
          .subscribe(
            (res: HttpResponse<LevelType>) => {
              this.zonalList = res.body;
            },
            () => { }
          );
      }
      this.signButtonName = 'Perform e-Sign Secretary';
    } else if (
      this.roleCode.role.code === 'DC1' ||
      this.roleCode.role.code === 'BC1' ||
      this.roleCode.role.code === 'VC1' ||
      this.roleCode.role.code === 'ZC1'
    ) {
      this.enableApproved = true;
      if (this.roleCode.role.code === 'BC1' || this.roleCode.role.code === 'ZC1') {
        this.checkAmount = true
      }
      this.signButtonName = 'Perform e-Sign VP';
      this.enableRevertButton = true;
      this.readonly = true;
    } else if (
      this.roleCode.role.code === 'DC2' ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'VC2'
    ) {
      this.enableApproved = true;

      this.signButtonName = 'Perform e-Sign President';
      this.enableRevertButton = true;
      this.downloadButton = true;
      this.readonly = true;
    }
    if (
      this.roleCode.role.code === 'BMAKER' ||
      this.roleCode.role.code === 'BC1' ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'ZC1'
    ) {
      this.enableZonalButton = true;
    }

    if (this.activatedRoute.snapshot.queryParamMap.get('response')) {
      this.paymentSucessDialog = true;
      this.paymentSucessText = this.activatedRoute.snapshot.queryParamMap.get('response');
      this.disablepaymentButton = true;
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('error')) {
      this.notificationService.alertError('Error Occurred: ' + this.activatedRoute.snapshot.queryParamMap.get('error'), '');
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      if (this.activatedRoute.snapshot.queryParamMap.get('view')) {
        this.enableView = true;
        this.readonly = true;
      }
      this.spinner.show();
      this.paymentService
        .updatedVocher(
          Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
        )
        .subscribe(
          (res: HttpResponse<paymentVoucherGen>) => {
            this.voucherGenerationDto = res.body;
            this.finYear = this.voucherGenerationDto.vendorTransactionDto.finYearDto;
            this.spinner.hide();
            // if(this.voucherGenerationDto.vendorTransactionDto.parentVoucherNumber && !this.enableView){
            //   this.readonly = true;
            //   this.bulkVoucherDialog = true;
            // }
            if (this.checkAmount && res.body.vendorTransactionDto.transactionAmount <= 500000 && res.body.vendorTransactionDto.signatureVicePresident) {
              res.body.vendorTransactionDto.signaturePresident = "signed"
            }
            if (res.body?.vendorTransactionDto?.zonalChecker) {
              this.userService
                .getUser(res.body?.vendorTransactionDto?.zonalChecker)
                .subscribe(
                  (res: HttpResponse<any>) => {
                    this.selectedZonalUser = res.body;
                  },
                  () => { }
                );
            }
            if (this.voucherGenerationDto.vendorTransactionDto.signStatus && !this.enableView) {
              this.readonly = true;
              this.enableSignButton = true;
            }
            this.date = new Date(
              this.voucherGenerationDto.vendorTransactionDto.transDate
            );
            if (this.voucherGenerationDto.vendorTransactionDto.signaturePresident) {
              switch (this.voucherGenerationDto.vendorTransactionDto.transactionStatus) {
                case 'O.K.':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Success';
                  break;
                case 'failed':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Failed';
                  break;
                case 'processing':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Processing';
                  break;
                default:
                  break;
              }
            }
            this.totalDeduction =
              this.voucherGenerationDto.vendorTransactionDto.totlaInRupees;
            this.selectedVendor =
              this.voucherGenerationDto.vendorTransactionDto.personalVendorDto;
            this.grantlevel =
              this.voucherGenerationDto.vendorTransactionDto.componentTypeDto;
            this.levelMasterDto =
              this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.levelTypeDto;
            this.majaorComponentdto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.majorComponentId);
            this.minorComponentDto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.minorComponentId);
            this.subComponentDto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.subComponentId);
            this.spinner.hide();
            this.enableNhis = this.voucherGenerationDto.vendorTransactionDto
              .nhis
              ? true
              : false;
            this.enableCps = this.voucherGenerationDto.vendorTransactionDto.cps
              ? true
              : false;
            this.enableOtherDeduction = this.voucherGenerationDto
              .vendorTransactionDto.otherDeductionIt
              ? true
              : false;
          },
          () => { this.spinner.hide(); }
        );
      this.spinner.hide();
    }
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.finYearList = this.sessionStorageService.retrieve('finYear');
      let FinYear = ''
      if (new Date().getMonth() + 1 >= 4) {
        FinYear =
          new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
      } else {
        FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
      }
      this.finYear = this.finYearList.find((_) => _.name === FinYear);
      this.enableNhis = false;
      this.enableCps = false;
      this.enableOtherDeduction = false;
      this.voucherGenerationDto = new paymentVoucherGen();
      this.voucherGenerationDto.vendorTransactionDto =
        new vendorTransactionDto();
      this.voucherGenerationDto.vendorTransactionDto.nhisPercentage = 0;
      this.voucherGenerationDto.vendorTransactionDto.cpsPercentage = 0;
      this.voucherGenerationDto.vendorTransactionDto.otherDeductionItPercentage = 0;
      this.voucherGenerationDto.vendorTransactionDto.personalVendorDto =
        new PersonalVendorsDto();

      this.selectedVendor = new PersonalVendorsDto();
      this.voucherGenerationDto.vendorTransactionDto.otherDeductionIt = 0;

      this.voucherGenerationDto.vendorTransactionDto.voucherNo =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.date = new Date();
      this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto = this.sessionStorageService.retrieve('IA');
      this.onLevelType();
      this.personalVendorService.filter({ size: 10, page: 0 }, []).subscribe(
        (res: HttpResponse<any>) => {
          this.vendorList = res.body;
        },
        () => { }
      );
      this.spinner.hide();
      
    }

  }

  loadComp(): Promise<any> {
    return new Promise<any>((resolve) => {
      const componmentTypeObservable = this.componentTypeService
        .find(
          this.sessionStorageService.retrieve('levelMaster').levelTypeDto.id
        );
      const componmentMasterObservable = this.paymentService.componentMasterList();
      forkJoin([componmentTypeObservable, componmentMasterObservable]).subscribe(
        (responses) => {
          this.sessionStorageService.store('componentMasterTable', responses[1].body)
          this.componentMasterTable = responses[1].body;
          this.sessionStorageService.store('componentTypeTable', responses[0].body)
          this.grantLevel = responses[0].body;
          resolve(this.componentMasterTable)
        },
        (error) => {
          console.error('Error:', error);
          resolve(null);
        }
      )
    });
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
  }

  changeSearchOption(data) {

    this.selectedVendor = null;
    this.beneficiaryService.getbeneficiaryList().subscribe(res => {
      console.log(res)
      this.beneficiaryList = res.body;
    })
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => { }
    );
  }

  onLevelType() {

    if (this.roleCode.role.name.startsWith('Village')) {
      this.grantLevel = this.grantLevel.filter(item => item.name === 'Village Thooimai Kavalar');
    } else if (this.roleCode.role.name.startsWith('Block')) {
      this.grantLevel = this.grantLevel.filter(item => item.name === 'Block Minimum Grant' || item.name === 'Panchayat Union General Fund');
    } else if (this.roleCode.role.name.startsWith('District')) {
      this.grantLevel = this.grantLevel.filter(item => item.name === 'District Admin Grant' || item.name === 'District Population Grant');
    }

    this.grantlevel = this.grantLevel[0];
    this.majaorComponent = this.componentMasterTable.filter(item => item.componentTypeDto.id === this.grantlevel.id && item.parentId === 0);
    this.majaorComponentdto = this.majaorComponent[0];
    this.minorComponent = this.componentMasterTable.filter(item => item.parentId === this.majaorComponentdto.id && !item.name.startsWith('Salary'));
    this.minorComponentDto = this.minorComponent[0];
    this.subComponent = this.componentMasterTable.filter(item => item.parentId === this.minorComponentDto.id);
    this.subComponentDto = this.subComponent[0];
    this.paymentService
      .getLimit(
        this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.id,
        this.grantlevel.id,
        this.finYear.id
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto =
            res.body;
        },
        () => { }
      );

  }
  ongrandLevel(edit?: any) {
    if (!edit) {
      this.majaorComponentdto = null;
      this.minorComponentDto = null;
      this.subComponentDto = null;
    }

    this.majaorComponent = this.componentMasterTable.filter(item => item.componentTypeDto.id === this.grantlevel.id && item.parentId === 0);
    this.paymentService
      .getLimit(
        this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.id,
        this.grantlevel.id,
        this.finYear.id
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto =
            res.body;
        },
        () => { }
      );
  }
  onMajorComponent(edit?: any) {
    if (!edit) {
      this.minorComponentDto = null;
      this.subComponentDto = null;
    }
    this.minorComponent = this.componentMasterTable.filter(item => item.parentId === this.majaorComponentdto.id && !item.name.startsWith('Salary'));

  }

  calculateOtherDeduction() {
    this.voucherGenerationDto.vendorTransactionDto.otherDeductionItPercentage =
      Number(
        this.voucherGenerationDto.vendorTransactionDto
          .otherDeductionItPercentage
      );
    this.voucherGenerationDto.vendorTransactionDto.otherDeductionIt = Number(
      (
        (this.voucherGenerationDto.vendorTransactionDto
          .otherDeductionItPercentage /
          100) *
        this.voucherGenerationDto.vendorTransactionDto.netAmount
      ).toFixed(2)
    );
    this.calculateTotalAmount();
  }

  onUpload($event) {
    const selectedFile: File = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer);
    };
    const formData = new FormData();
    const fileExtension = $event.name.split('.').pop();
    const fileData = new Blob([$event], { type: $event.type });
    formData.append('file', fileData, `file.${fileExtension}`);
    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => { },
      () => { }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }
  onMinorComponent(edit?: any) {
    if (!edit) {

      this.subComponentDto = null;

    }
    this.subComponent = this.componentMasterTable.filter(item => item.parentId === this.minorComponentDto.id);

    if (this.minorComponentDto.name.startsWith('Salary')) {
      this.employeeService.filter({ size: 10, page: 0 }, []).subscribe(
        (res: HttpResponse<Employee>) => {
          this.employeeList = res.body;
          this.enableEmployee = true;
        },
        () => { }
      );
    } else {
      this.enableEmployee = false;
    }
  }

  vendorFilterByAccount() {
    let filter = [];
    if (this.accountNo) {
      filter = [{
        key: 'accountNumber',
        operation: 'equals',
        value: this.accountNo,
      }];
      this.personalVendorService
        .validFilter({ size: 1, page: 0 }, filter)
        .subscribe(
          (res: HttpResponse<any>) => {
            if (res.body.length > 0) {
              this.vendorList = res.body;
              this.selectedVendor = res.body[0];
              this.popupValue();
            }
            else {
              this.selectedVendor = null;
              this.notificationService.alertInfo('Vendor with given Account number not found', '');
            }
          },
          () => { }
        );
    }
  }
  vendorFilterByUniqueId() {
    let filter = [];
    if (this.uniqueId) {
      filter = [{
        key: 'uniqueId',
        operation: 'equals',
        value: this.uniqueId,
      }];
      this.personalVendorService
        .validFilter({ size: 1, page: 0 }, filter)
        .subscribe(
          (res: HttpResponse<any>) => {
            if (res.body.length > 0) {
              this.vendorList = res.body;
              this.selectedVendor = res.body[0];
              this.popupValue();
            }
            else {
              this.selectedVendor = null;
              this.notificationService.alertInfo('Vendor with given Account number not found', '');
            }
          },
          () => { }
        );
    }
  }

  search($event) {
    this.typedValue = $event.term;
    this.hasMoreItemsToLoad = true;
    this.currentPage = 0;
  }

  vendorFilter() {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    if (this.typedValue) {
      this.filterTimeout = setTimeout(() => {
        let filter = new filterQuery();
        filter.key = 'name';
        filter.operation = 'contains';
        filter.value = this.typedValue.toUpperCase();
        let filterquery = [];
        filterquery.push(filter);
        this.personalVendorService
          .filter({ size: 10, page: 0 }, filterquery)
          .subscribe(
            (res: HttpResponse<any>) => {
              this.vendorList = res.body;
            },
            () => { }
          );
      }, 2000);
    }
  }

  loadMoreItems(page: number) {
    if (this.hasMoreItemsToLoad) {
      let filter = [];
      if (this.typedValue) {
        filter = [{
          key: 'name',
          operation: 'contains',
          value: this.typedValue.toUpperCase(),
        }];
      }

      const paginationOptions = { size: 10, page };

      return this.personalVendorService.filter(paginationOptions, filter);
    }
    return
  }

  onScrollToEnd() {
    console.log('scroll to end triggered')
    if (!this.loadingMore && this.hasMoreItemsToLoad) {
      this.loadingMore = true;
      this.currentPage++;

      this.loadMoreItems(this.currentPage).subscribe(
        (res: HttpResponse<any>) => {
          const additionalItems = res.body;

          if (additionalItems && additionalItems.length > 0) {
            if (additionalItems.length < 10) {
              this.vendorList = this.vendorList.concat(additionalItems);
              this.hasMoreItemsToLoad = false;
            } else {
              this.vendorList = this.vendorList.concat(additionalItems);
            }
          } else {
            this.hasMoreItemsToLoad = false;
          }

          this.loadingMore = false;
        },
        () => {
          this.loadingMore = false;
        }
      );
    }
  }

  popupValue() {
    this.voucherGenerationDto.thooimaiKavalarDescDtoList = [];
    let beneficiaryDescrpitionDto = new beneficiaryDescDto();
    //  let personalDescDto = new PersonalDescDto();
    beneficiaryDescrpitionDto.amount = 0;
    beneficiaryDescrpitionDto.grossAmount = 0;
    this.amount = 0;
    this.description = null;

    this.voucherGenerationDto.thooimaiKavalarDescDtoList.push(beneficiaryDescrpitionDto);
    // this.paymentService.getDescrptionList().subscribe(
    //   (res: HttpResponse<DescriptionDto[]>) => {
    //     this.descriptionList = [];
    //     res.body.forEach((element) => {
    //       if (!element.name.startsWith('Salary')) {
    //         this.descriptionList.push(element);
    //       }
    //     });
    //   },
    //   () => {}
    // );
    //this.selectedVendor.gstNo = null
    this.voucherGenerationDto.vendorTransactionDto.personalVendorDto =
      new PersonalVendorsDto();
    this.voucherGenerationDto.vendorTransactionDto.personalVendorDto.id =
      this.selectedVendor.id;
    // this.voucherGenerationDto.commercialVendorDto.gstNo = this.selectedVendor.gstNo
    this.voucherGenerationDto.vendorTransactionDto.gstOnTds = 2;
    this.voucherGenerationDto.vendorTransactionDto.personalVendorDto.panNo =
      this.selectedVendor.panNo;
    this.calculateTotalAmount();
  }
  addRow() {
    this.voucherGenerationDto.thooimaiKavalarDescDtoList = this.voucherGenerationDto
      .thooimaiKavalarDescDtoList
      ? this.voucherGenerationDto.thooimaiKavalarDescDtoList
      : [];
    let beneficiaryDescrpitionDto = new beneficiaryDescDto();
    beneficiaryDescrpitionDto.amount = 0;
    beneficiaryDescrpitionDto.grossAmount = 0;
    this.voucherGenerationDto.thooimaiKavalarDescDtoList.push(beneficiaryDescrpitionDto);
    // if(!this.descriptionList)
    // {
    //   this.paymentService.getDescrptionList().subscribe(
    //     (res: HttpResponse<DescriptionDto[]>) => {
    //       this.descriptionList = [];
    //       res.body.forEach((element) => {
    //         if (!element.name.startsWith('Salary')) {
    //           this.descriptionList.push(element);
    //         }
    //       });
    //     },
    //     () => {}
    //   );
    // }
    this.calculateOtherDeduction();
  }
  calculatetotal(amt: beneficiaryDescDto) {
    if (amt.amount === null) {
      amt.amount = 0;
    }
    this.amount = amt.amount;
    amt.grossAmount = amt.amount;
    this.calculateTotalAmount();
    this.calculateOtherDeduction();
  }
  delete(index: number) {
    this.voucherGenerationDto.thooimaiKavalarDescDtoList.splice(index, 1);
    if (this.voucherGenerationDto.thooimaiKavalarDescDtoList.length === 0) {
      this.voucherGenerationDto.vendorTransactionDto.netAmount = null;
      this.totalDeduction = null;
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount = null;
      this.voucherGenerationDto.vendorTransactionDto.nhis = 0;
      this.voucherGenerationDto.vendorTransactionDto.cps = 0;
      this.voucherGenerationDto.vendorTransactionDto.otherDeductionIt = 0;
    } else {
      this.calculateTotalAmount();
    }
    this.calculateOtherDeduction();
  }

  calculateTds() {
    this.voucherGenerationDto.vendorTransactionDto.tdsAmount =
      this.voucherGenerationDto.vendorTransactionDto.netAmount /
      this.voucherGenerationDto.vendorTransactionDto.tdsPercentage;
    this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = Math.round(
      this.voucherGenerationDto.vendorTransactionDto.tdsAmount -
      (this.voucherGenerationDto.vendorTransactionDto.gstOnTds / 100) *
      this.voucherGenerationDto.vendorTransactionDto.tdsAmount
    );
  }

  validateIfsc() {
    this.ifscError = !this.validationService.isPanValid(
      this.selectedVendor.ifscCode
    );
    !this.selectedVendor.ifscCode ? (this.ifscError = false) : true;
    return !this.ifscError && !!this.selectedVendor.ifscCode;
  }
  addDescritption(list: string) {
    this.description = list;
  }

  genVocher() {
    this.ngSelectInvalid = false;
    const isFormValid =

      this.levelMasterDto &&
      this.grantlevel &&
      this.majaorComponentdto &&
      this.minorComponentDto &&
      this.subComponentDto &&
      this.voucherGenerationDto.vendorTransactionDto
        .panchayatResolutionNumber &&
      this.selectedVendor.name &&
      this.voucherGenerationDto.thooimaiKavalarDescDtoList &&
      this.voucherGenerationDto.vendorTransactionDto.remark &&
      this.voucherGenerationDto.vendorTransactionDto.grossAmount &&
      this.voucherGenerationDto.vendorTransactionDto &&
      this.voucherGenerationDto.vendorTransactionDto.netAmount;



    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Please fill all the mandotory fields marked with *", '');
      return;
    }
    if (this.roleCode?.role?.code === 'BMAKER' && !this.selectedZonalUser) {
      this.notificationService.alertError('Please Select the Zonal User', '')
      return;
    }
    this.voucherGenerationDto.thooimaiKavalarDescDtoList.forEach((element) => {
      const isVal = element.descriptionDto && element.amount;
      if (!isVal) {
        this.ngSelectInvalid = true;
      }
    });
    if (this.ngSelectInvalid) {
      return;
    }
    this.ngSelectInvalid = false;
    this.spinner.show();

    if (this.selectedZonalUser?.id) {
      this.voucherGenerationDto.vendorTransactionDto.zonalChecker = this.selectedZonalUser?.id
    }
    this.voucherGenerationDto.vendorTransactionDto.componentTypeDto =
      this.grantlevel;

    this.voucherGenerationDto.vendorTransactionDto.personalVendorDto =
      this.selectedVendor;

    this.voucherGenerationDto.vendorTransactionDto.majorComponentId =
      this.majaorComponentdto.id;
    this.voucherGenerationDto.vendorTransactionDto.majorComponentName =
      this.majaorComponentdto.name;
    this.voucherGenerationDto.vendorTransactionDto.minorComponentId =
      this.minorComponentDto.id;
    this.voucherGenerationDto.vendorTransactionDto.minorComponentName =
      this.minorComponentDto.name;
    this.voucherGenerationDto.vendorTransactionDto.subComponentId =
      this.subComponentDto.id;
    this.voucherGenerationDto.vendorTransactionDto.subComponentName =
      this.subComponentDto.name;
    this.voucherGenerationDto.vendorTransactionDto.levelTypeId =
      this.levelMasterDto.id;
    this.voucherGenerationDto.vendorTransactionDto.personalFlag = 'TK';


    console.log(this.voucherGenerationDto)

    this.paymentService.genVocher(this.voucherGenerationDto).subscribe(
      (response: any) => {
        // const blob = new Blob([response.body], { type: 'application/pdf' });
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = 'file.pdf';
        // document.body.appendChild(link);
        // window.open(url, '_blank');
        // link.click();
        // URL.revokeObjectURL(url);
        this.readonly = true;
        this.spinner.hide();
        this.enableSignButton = true;
        // this.router.navigate(['/thooimai-kavalar-payment-list']);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },

      (error) => {
        this.spinner.hide();
        if (error.status == 400) {
          this.notificationService.alertError('Due to insufficient limit, You are not eligible to generate Voucher', '');

        } else if (error.status == 500) {
          this.notificationService.alertError('An unexpected error occurred, please delete the voucher if generated', '');
          this.router.navigate(['/thooimai-kavalar-payment-list'], {});
        } else {
          this.notificationService.alertError('An unexpected error occurred', '');
        }
      }
    );
  }

  getfile() {
    this.paymentService
      .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          if (!this.voucherGenerationDto.vendorTransactionDto.id) {
            this.router.navigate(['/beneficiary-payment-voucher'], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
          // add more parameters as needed
        },
        (error) => {
          if (
            this.roleCode.role.code === 'DMAKER' ||
            this.roleCode.role.code === 'VMAKER' ||
            this.roleCode.role.code === 'BMAKER'
          ) {
            this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: ' + this.voucherGenerationDto.vendorTransactionDto.voucherNo, '');
            this.router.navigate(['/thooimai-kavalar-payment-list'], {});
          }
          else {
            this.deleteDialog = true;
          }
        }
      );
  }

  hideDialog() {
    this.deleteDialog = false;
    this.router.navigate(['/thooimai-kavalar-payment-list'], {});
  }

  getActionUrl() {
    this.sessionStorageService.store('refresh', 'Yes');
    return environment.CDAC_URL;
  }

  getPdf() {
    this.paymentService
      .getPdfFile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
          // this.spinner.hide();
          // this.router.navigate(['payment']);
          // this.getfile();
          // this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (err) => {
          // this.spinner.hide();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  revert() {
    this.spinner.show();
    this.paymentService
      .revertTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/thooimai-kavalar-payment-list'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  reject() {
    this.spinner.show();
    this.paymentService
      .deletedVocher(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/thooimai-kavalar-payment-list'], {});
        },
        (onError) => {
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.title, '');
          } else {
            this.notificationService.alertError('Error in Reject', '');
          }

          this.spinner.hide();
        }

      );
  }

  calculateTotalAmount() {
    if (this.voucherGenerationDto?.thooimaiKavalarDescDtoList.length !== 0) {
      let intitalTotal = 0;
      let intitalTotalWithTax = 0;
      this.voucherGenerationDto?.thooimaiKavalarDescDtoList.forEach((element) => {
        intitalTotal += Number(element.amount);
        intitalTotalWithTax += element.grossAmount;
      });
      this.voucherGenerationDto.vendorTransactionDto.netAmount =
        intitalTotalWithTax;
      this.voucherGenerationDto.vendorTransactionDto.grossAmount =
        intitalTotalWithTax;
      this.voucherGenerationDto.vendorTransactionDto.nhis = 0;
      this.voucherGenerationDto.vendorTransactionDto.cps = 0;
      if (this.enableNhis) {
        this.voucherGenerationDto.vendorTransactionDto.nhis = Number(
          (
            (this.voucherGenerationDto.vendorTransactionDto.nhisPercentage /
              100) *
            this.voucherGenerationDto.vendorTransactionDto.netAmount
          ).toFixed(2)
        );
      }
      if (this.enableCps) {
        this.voucherGenerationDto.vendorTransactionDto.cps = Number(
          (
            (this.voucherGenerationDto.vendorTransactionDto.cpsPercentage /
              100) *
            this.voucherGenerationDto.vendorTransactionDto.netAmount
          ).toFixed(2)
        );
      }

      this.totalDeduction =
        this.voucherGenerationDto.vendorTransactionDto.nhis +
        this.voucherGenerationDto.vendorTransactionDto.cps +
        this.voucherGenerationDto.vendorTransactionDto.otherDeductionIt;
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount =
        this.voucherGenerationDto.vendorTransactionDto.netAmount -
        this.totalDeduction;
    }
  }

  getvendorTrans() {
    if (this.selectedVendor) {
      this.paymentService
        .getListPersonalVendorTransction(this.selectedVendor.id)
        .subscribe(
          (res: any) => {
            this.noRecord = true;
            this.searchResult.items = res.body ?? [];
            if (this.searchResult.items.length > 0) {
              this.noRecord = false;
            }
            this.searchResult.total = 5;
            this.searchResult = { ...this.searchResult };
            this.profileDialog = true;
          },
          () => { }
        );
    }
  }

  redirect() {
    this.router.navigate(['/beneficiary-payment-voucher'], {
      queryParams: {
        id: String(this.voucherGenerationDto.vendorTransactionDto.id),
      },
    });
  }

  bulkVoucherRedriect() {
    this.router.navigate(['/blukpaymentTable'], {});
  }

  checkPaymentStatus() {
    this.spinner.show();
    this.checkForPaymentStatus = false;
    this.paymentService.paymentStatus(this.interactionId).subscribe(
      (res: any) => {
        if (res.body.transactionStatus.TRANSTATUS === "Failure") {
          this.notificationService.alertError("Transaction Failed for Reference No:" + res.body.transactionStatus.JRNL_NO, '')
          this.buttonText = 'Re-Initiate payment';
          this.checkstatusButton = false;
          // this.disablepaymentButton = false;
          this.spinner.hide();
        }
        else if (res.body.transactionStatus.TRANSTATUS === "Success") {
          this.checkstatusButton = false;
          this.paymentSucessDialog = true;
          this.paymentSucessText = "Transaction Success for Reference No:", res.body.transactionStatus.JRNL_NO;
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  checkTransactionStatus() {
    this.checkForPaymentStatus = true;
    this.paymentStatus();
  }

  paymentStatus() {
    this.paymentService.getPaymentStatus(this.voucherGenerationDto.vendorTransactionDto.id).subscribe(
      (res: any) => {
        if (res.body.transactionStatus === "O.K.") {
          this.disablepaymentButton = true;
        }
        else if (res.body.transactionStatus !== null) {
          this.buttonText = "Re-Initiate payment"
          this.checkstatusButton = true;
          this.interactionId = res.body.apiInteractionId;
          if (this.checkForPaymentStatus) {
            this.checkPaymentStatus();
          }
        }


      },
      (err) => {

      }
    );


  }

  submitForm() {
    if (this.roleCode.role.code !== 'VC2') {
      this.sessionStorageService.store('paymentType', this.voucherGenerationDto.vendorTransactionDto.personalFlag);
      this.sessionStorageService.store('voucherNo', this.voucherGenerationDto.vendorTransactionDto.voucherNo);
      this.sessionStorageService.store('userId', this.roleCode.id);
      this.sessionStorageService.store('otpAttempts', 3);
      this.router.navigateByUrl('/aadhar-verification-page');
    }
    else {
      this.spinner.show();
      this.paymentService
        .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.spinner.hide();
            this.param1 = res.body.paramOne;
            this.param2 = res.body.paramTwo;
            this.param3 = res.body.paramThree;
            if (!this.voucherGenerationDto.vendorTransactionDto.id) {
              this.router.navigate(['/beneficiary-payment-voucher'], {
                queryParams: {
                  id: res.body.paramFour
                },
              });
            }
            this.sessionStorageService.store('refresh', 'Yes');
            const formElement = document.createElement('form');
            formElement.method = 'POST';
            formElement.action = environment.CDAC_URL;
            let inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "eSignRequest";
            inputElement.value = res.body.paramOne;
            formElement.appendChild(inputElement);
            inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "aspTxnID";
            inputElement.value = res.body.paramTwo;
            formElement.appendChild(inputElement);
            inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "Content-Type";
            inputElement.value = res.body.paramThree;
            formElement.appendChild(inputElement);
            document.body.appendChild(formElement);
            formElement.submit();

          },
          (error) => {
            this.spinner.hide();
            if (
              this.roleCode.role.code === 'DMAKER' ||
              this.roleCode.role.code === 'VMAKER' ||
              this.roleCode.role.code === 'BMAKER'
            ) {
              this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: ' + this.voucherGenerationDto.vendorTransactionDto.voucherNo, '');
              this.router.navigate(['/thooimai-kavalar-payment-list'], {});
            }
            else {
              this.deleteDialog = true;
            }
          }
        );
    }
  }

  PaymentSucess() {
    this.spinner.show();
    this.paymentService.paymentSuccess(this.voucherGenerationDto).subscribe(
      (res: any) => {
        this.paymentSucessDialog = true;
        this.paymentSucessText = res.body;
        this.disablepaymentButton = true;
        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        if (String(err.status).startsWith('5')) {
          this.checkstatusButton = true;
          this.spinner.hide();
        }
        else if (err.status === 400) {
          this.notificationService.alertError(err.error, '');
        }
        this.spinner.hide();
      }
    );
  }
}
