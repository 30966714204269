// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  I18N_HASH: '',
  production: false,
  environment: 'development',
  // SERVER_API_URL: 'http://localhost:8081/',
  SERVER_API_URL: 'https://rdprsna.mslabs.in/',
  // CDAC_URL:'https://esignservice.cdac.in/esign2.1/2.1/form/signdoc',
  CDAC_URL:'https://esignservice.cdac.in/esign2.1/2.1/form/signdoc',
  // CDAC_URL:'https://es-staging.cdac.in/esignlevel2/2.1/form/signdoc',
  VERSION: 'Dev-4.1.0',
  publicKey:'-----BEGIN PUBLIC KEY-----'
  +'MIIBojANBgkqhkiG9w0BAQEFAAOCAY8AMIIBigKCAYEAskcJaZD+dsiXvEKsF8G4'
  +'Y2wsj80wi9+GNti7a1IAI3dAiTvpVJ+pNQ3k6cHFtlfbsHJw0YJiUsYeWxSypini'
  +'gg31yNK7J605uIt032/NL2RdcFK9JmjGtu4zfMb9S3a10zaVtKHhM+WY+Hy1DLCG'
  +'s98nZf1bVwEJ3M1PomqURNVgw2S2HoObhBFk2Y6gxoVHiAq6Tjd4EWYf5dh/bGKs'
  +'Yh+AeRGGe4pacwyBN4Y72mYQWrkHbDudY4vZI6yKVEoxo2of+5D0Yv0tX+XVXc1x'
  +'teDBwtxDfd+4VQ43isV1o2WXVBix9w5qxotz1I1zJzrIyvOsfSjD3139U5SDsd0/'
  +'Ewi09F36IwJ607WANTL8apdfiTOtdvJYaWc0tVszQl6rVRT4Uf+f/+GHa2xo4Uvy'
  +'y/1Bn7cJF9QAPDNItMPWxhnhjPfLgTDY9dNqEKGxaYOJwBQgJdUb5/pCtb030IlW'
  +'I++KIZikL+XvTEt4QcZZqzXiPXq/afjuV7Tt/+T0nyw1AgMBAAE='
  +'-----END PUBLIC KEY-----',
  encryptKey: "3DC1E1CFC314CF853A2E67995760D289",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
