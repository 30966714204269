import { ReportService } from 'src/app/report/report.service';
import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConsumerDemand, TangedcoBill } from 'src/app/tneb/tangedco-bill-details/tangedco-bill-details';
import { TangedcoBillService } from 'src/app/tneb/tangedco-bill-details/tangedco-bill-details.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { filterQuery } from 'src/app/shared/common.model';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-twad-report',
  templateUrl: './twad-report.component.html',
  styleUrl: './twad-report.component.scss'
})
export class TwadReportComponent {
  activeIndex=0;
  tnebsummary: any;
  tnebsummaryBlock: any;
  tnebsummaryVillage: any;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any=[];
  searchResult: SearchResult<any> =
  new SearchResult<any>();
  villageId: any;
  cols: any;
  enableStatusVoucher: boolean = false;
  cosumerDemandId: any;
  tangedcosearchResult: SearchResult<TangedcoBill> =
  new SearchResult<TangedcoBill>();
  vouchercols:any;
  maxDate = new Date();
  fromDate: string;
  startDate: string;
  toDate: string;
  endDate: string;
  dateError: boolean;
  districtId: any;
  blockId: any;
  roleCode: any;
  
  constructor(
    protected reportService:ReportService,
    public fontService:FontService,
    private tangedcoBillService: TangedcoBillService,
    private datePipe: DatePipe,
    private spinner:NgxSpinnerService,
    private loginService: SigninService,
    private sessionStorageService:SessionStorageService
  ) { }

  ngOnInit(): void {
    let roleCode = this.sessionStorageService.retrieve('roleCode');
    this.cols = [
      {
        field: 'districtName',
        header: 'District',
        //jhiTranslate: 'tableHead.voucher.no',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'blockName',
        header: 'Block',
        //jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true
        //type: 'date',
      },
      {
        field: 'villageName',
        header: 'village',
        //jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: true
        //type:'amount'
      },
      {
        field: 'villageLgdCode',
        header: 'Ldg Code',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'voucherNumber',
        header: 'Voucher Number',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
        type:"date"
      },
      {
        field: 'transactionAmount',
        header: 'Transaction Amount',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
        type:"amount"
      },
      {
        field: 'utrNo',
        header: 'UTR No',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
        
      },
      {
        field: 'officeId',
        header: 'Office Id',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      {
        field: 'officeName',
        header: 'Office Name',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
      },

      {
        field: 'beneficiaryCode',
        header: 'Beneficiary Code',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      {
        field: 'billSno',
        header: 'Bill Sno',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
      },

    ];
    if (roleCode === 'DA' || roleCode === 'DM' || roleCode === 'DAD' || roleCode === 'DS') {
      this.cols.splice(0, 1);
    } else if (roleCode === 'BM' || roleCode === 'ZVM' || roleCode === 'BAD') {
      this.cols.splice(0, 2);
    }
  }

  loadBlock(districtId) {
    this.districtId = districtId;
    if(!this.fromDate && !this.toDate)
      {
      this.startDate=this.datePipe.transform(new Date("04-01-2024"), 'yyyy-MM-dd')+' 00:00:00.000';
      this.endDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
      }
    const payload: any = {
      levelType: 'DISTRICT',
      levelMasterId: districtId,
      fromDate:this.startDate,
      toDate:this.endDate
    };
    this.reportService.getTwadReport(payload).subscribe(
      (res) => {
        console.log('res.body',res.body);
        this.tnebsummaryBlock = res.body;
        this.activeIndex = 1;
      },
      (err)=>{
       
      }
    )
  }

  loadVillage(blockId) {
    this.blockId= blockId;
    if(!this.fromDate && !this.toDate)
      {
      this.startDate=this.datePipe.transform(new Date("04-01-2024"), 'yyyy-MM-dd')+' 00:00:00.000';
      this.endDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
      }
    const payload: any = {
      levelType: 'BLOCK',
      levelMasterId: blockId,
      fromDate:this.startDate,
      toDate:this.endDate
    };
    if(this.roleCode === 'ZVM') {
      payload['zonalName'] = this.sessionStorageService.retrieve('user')?.zonalName;
    }
    this.reportService.getTwadReport(payload).subscribe(
      (res) => {
        console.log('res.body',res.body);
        this.tnebsummaryVillage = res.body;
        this.activeIndex = 2;
      },
      (err)=>{
       
      }
    )
  }

  async loadPage(page?: number, dontNavigate?: boolean): Promise<void> {
    // this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    
    if(!this.fromDate && !this.toDate)
      {
      this.startDate=this.datePipe.transform(new Date("04-01-2024"), 'dd-MM-yyyy');
      this.endDate=this.datePipe.transform(new Date(), 'dd-MM-yyyy');
      }
    if(!this.roleCode) {
      await this.getRole();
    }

    const payload: any = {
      
      fromDate:this.startDate,
      toDate:this.endDate,
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    payload['levelMasterId'] = this.roleCode.levelMasterId;
    if (this.roleCode.role.code === 'SA' || this.roleCode.role.code === 'TA' || this.roleCode.role.code === 'TM' || this.roleCode.role.code === 'SM') {
      payload['levelType'] = 'STATE';
      payload['levelMasterId'] = 1;
    } else if (this.roleCode.role.code === "DM" || this.roleCode.role.code === "DA" || this.roleCode.role.code === "DAD" || this.roleCode.role.code === "DS") {
      payload['levelType'] = 'DISTRICT';
    } else {
      payload['levelType'] = 'BLOCK';
      this.cols.splice(0, 2);
    }
    if(this.roleCode.role.code === 'ZVM') {
      payload['zonalName'] = this.sessionStorageService.retrieve('user')?.zonalName;
    }
    this.reportService.getTwadReport(payload).subscribe(
      (res) => {
        console.log('res.body',res.body);
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (err)=>{
       
      }
    )
   
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;
    this.activeIndex = 3;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}
  onLazyLoad(event) {
      this.filterProps = event.filterDefinitions;
      this.itemsPerPage = event.size;
      this.loadPage(event.page);
    
    
  }


  onTabChange($event){

  }

  

  onSearch(){
    this.activeIndex=0;
      if(this.fromDate && this.toDate)
      {
        this.startDate=this.datePipe.transform(this.fromDate, 'dd-MM-yyyy');
        this.endDate=this.datePipe.transform(this.toDate, 'dd-MM-yyyy');
      }
    if (this.startDate > this.endDate) {
      this.dateError = true;
      return
    }
      this.dateError = false;
   this.loadPage(1);
  }

  download(){
    this.spinner.show();
    const payload: any = {
      fromDate:this.startDate,
      toDate:this.endDate,
     
    };
    payload['levelMasterId'] = this.roleCode.levelMasterId;
    if (this.roleCode.role.code === 'SA' || this.roleCode.role.code === 'TA' || this.roleCode.role.code === 'TM' || this.roleCode.role.code === 'SM') {
      payload['levelType'] = 'STATE';
      payload['levelMasterId'] = 1;
    } else if (this.roleCode.role.code === "DM" || this.roleCode.role.code === "DA" || this.roleCode.role.code === "DAD" || this.roleCode.role.code === "DS") {
      payload['levelType'] = 'DISTRICT';
    } else {
      payload['levelType'] = 'BLOCK';
    }
    if(this.roleCode.role.code === 'ZVM') {
      payload['zonalName'] = this.sessionStorageService.retrieve('user')?.zonalName;
    }
    this.reportService.getTwadDownload(payload).subscribe(
      (response) => {
        this.spinner.hide();
        this.saveBlobToExcel(response.body,'Twad Success Report');
      },
      (err)=>{
        this.spinner.hide();
      }
    )
   
  }

  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
      }

      getRole(): Promise<any> {
        return new Promise<any>((resolve) => {
          this.loginService.getuserDto().subscribe(
            (res) => {
              this.roleCode = res.body;
              resolve(this.roleCode);
            },
            (onError) => {
              resolve(null);
            }
          );
        });
      }

 
}
