import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VendorTransaction } from '../payment-voucher/payment-voucher.model';
import { FlaggingDto, SubVoucherFlagging, VoucherFlagging } from './flagging.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { FlagingService } from './flagging.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-flagging-page',
  templateUrl: './flagging-page.component.html',
  styleUrl: './flagging-page.component.scss'
})
export class FlaggingPageComponent {
  @Input() vendorTransaction: any;
  @Input() personalFlag: string;
  @Output() saveEmit = new EventEmitter();
  flagging: FlaggingDto;
  issueType: string[];
  bulkPaySearchResult: SubVoucherFlagging[];


  constructor(
    protected paymentService: PaymentService,
    public fontService: FontService,
    protected flagingService: FlagingService,
    private sessionStorage: SessionStorageService,
    protected notificationService: NotificationService,
  ) {

  }

  message: string;

  ngOnInit(): void {
    this.flagging = new FlaggingDto();
    this.flagging.voucherFlagging = new VoucherFlagging();
    this.flagging.voucherFlagging.amount = this.vendorTransaction.transactionAmount;
    this.flagging.voucherFlagging.levelMasterId = this.sessionStorage.retrieve('levelmasterId');
    if (['IT', 'GST', 'LWF', 'FD', 'SD'].includes(this.personalFlag)) {
      this.flagging.voucherFlagging.paymentCategory = "DT";
      this.flagging.voucherFlagging.deductionTransactionId = this.vendorTransaction.id;
      this.flagging.voucherFlagging.deductionType = this.personalFlag;
      this.flagging.voucherFlagging.voucherNo = this.vendorTransaction.voucherNumber;
      this.loadIssueTypeByStatus(this.vendorTransaction.transactionStatus);
    }
    else {
      this.flagging.voucherFlagging.vendorTransactionId = this.vendorTransaction.id;
      this.flagging.voucherFlagging.voucherNo = this.vendorTransaction.voucherNo;
      this.flagging.voucherFlagging.paymentCategory = this.vendorTransaction.personalFlag;
      
      if (this.vendorTransaction.personalFlag.toUpperCase() === 'T' || this.vendorTransaction.personalFlag.toUpperCase() === 'BE' || this.vendorTransaction.personalFlag.toUpperCase() === 'BER') {
        this.bulkPaySearchResult = [];
        this.getBulkPaymentList();
      }
      else {
        this.loadIssueTypeByStatus(this.vendorTransaction.transactionStatus);
      }
    }
    
    
  }

  loadIssueTypeByStatus(status) {
    switch (status) {
      case 'Success':
        this.issueType = ['Voucher Success but Payment not credited to vendor'];
        break;
      case 'Failed':
        this.issueType = ['Voucher Failed but limit not returned'];
        break;
      case 'O.K.':
        this.issueType = ['Voucher Success but Payment not credited to vendor'];
        break;
      case 'failed':
        this.issueType = ['Voucher Failed but limit not returned'];
        break;
      default:
        this.issueType = [];
        break;
    }
    this.issueType.push('Others');
    return this.issueType
  }

  getBulkPaymentList() {
    this.paymentService
      .bulkPaymentList(this.vendorTransaction.id)
      .subscribe(
        (res: any) => {
          res.body.forEach(element => {
            let bulkList = new SubVoucherFlagging();
           
            if (this.vendorTransaction.personalFlag.toUpperCase() === 'T') {
              bulkList.consumerNo = element.consumerNo;
              bulkList.amount = element.billAmount;
            }
            else {
              bulkList.name = element.employeeName;
              bulkList.amount = element.transactionAmount;
            }
            bulkList.issueTypeList = this.loadIssueTypeByStatus(element.transactionStatus);
            this.bulkPaySearchResult.push(bulkList);
          });



        },
        (err) => { }
      );
  }

  save() {
    if (this.vendorTransaction.personalFlag?.toUpperCase() === 'T' || this.vendorTransaction.personalFlag?.toUpperCase() === 'BE' || this.vendorTransaction.personalFlag?.toUpperCase() === 'BER') {
      this.flagging.flaggingList = [];
      let isVal = true;
      this.bulkPaySearchResult.forEach(element => {
        if(element.isSelect) {
          if(element.issueType && element.description) {
            this.flagging.flaggingList.push(element);
          }
          else {
            isVal = false;
          }
        }
      });
      if(!isVal) {
        this.notificationService.alertError('Please fill Issue Type or Description','');
        return;
      }
    }
    else {
      if(!this.flagging.voucherFlagging.description || !this.flagging.voucherFlagging.issueType) {
        this.notificationService.alertError('Please fill Issue Type or Description','');
        return;
      }
    }
    if((this.vendorTransaction.personalFlag?.toUpperCase() === 'T' || this.vendorTransaction.personalFlag?.toUpperCase() === 'BE' || this.vendorTransaction.personalFlag?.toUpperCase() === 'BER')  && this.flagging.flaggingList.length === 0) {
      this.notificationService.alertError('Issue Sublist should not be Empty','');
      return;
    }
    this.flagingService
      .save(this.flagging)
      .subscribe(
        (res) => {
          console.log('res', res)
          this.saveEmit.emit(true);
          this.notificationService.alertSuccess('The Flag Raised Sucessfully','');
        },
        (err) => { }
      );
  }
}
