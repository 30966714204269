<div class="col-12">
    <div class="form-inner-panel">

        <div class="row">
            <div class="col-12">
                <h5 [ngClass]="fontService.headingClass">Flag Raise Issue</h5>
            </div>
            <div *ngIf="!bulkPaySearchResult && (this.vendorTransaction?.personalFlag?.toUpperCase() !== 'T' && this.vendorTransaction?.personalFlag?.toUpperCase() !== 'BE' && this.vendorTransaction?.personalFlag?.toUpperCase() !== 'BER')" class="row gy-2">
                <div class="col-sm-6 col-md-6">
                    <label class="form-control-label dropdown-width req" [ngClass]="fontService.labelClass" for=" name">Issue Type</label>
                    <ng-select id="scheme" [(ngModel)]="flagging.voucherFlagging.issueType" [items]="issueType" bindLabel="habitationName" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
                <div class="col-sm-6 col-md-6">
                    <label class="form-control-label req" [ngClass]="fontService.labelClass" for=" name">Description</label>
                    <textarea id="w3review" name="w3review" rows="3" [(ngModel)]="flagging.voucherFlagging.description" cols="100"></textarea>
                </div>
            </div>
            <div *ngIf="bulkPaySearchResult && (this.vendorTransaction?.personalFlag?.toUpperCase() === 'T' || this.vendorTransaction?.personalFlag?.toUpperCase() === 'BE' || this.vendorTransaction?.personalFlag?.toUpperCase() === 'BER')" class="col-md-12 mt-2">
                <p-table [value]="bulkPaySearchResult" [paginator]="false" [rows]="20" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>S.No</th>
                            <th></th>
                            <th *ngIf="vendorTransaction.personalFlag.toUpperCase() !== 'T'">Employee Name</th>
                            <th *ngIf="vendorTransaction.personalFlag.toUpperCase() === 'T'">Consumer No</th>
                            <th>Amount</th>
                            <th class="req">Issue Type</th>
                            <th class="req">Description</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-index="rowIndex" let-list>
                        <tr>
                            <td>{{index+1}}</td>
                            <td>
                                <p-checkbox (onChange)="list.issueType = null;list.description = null" [(ngModel)]="list.isSelect" binary="true"></p-checkbox>
                            </td>
                            <td *ngIf="vendorTransaction.personalFlag.toUpperCase() !== 'T'">{{ list.name}}</td>
                            <td *ngIf="vendorTransaction.personalFlag.toUpperCase() === 'T'">{{ list.consumerNo}}</td>
                            <td>{{list.amount}}</td>
                            <td>
                                <ng-select id="scheme" [(ngModel)]="list.issueType" [items]="list.issueTypeList" [disabled]="!list.isSelect" bindLabel="habitationName" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                                </ng-select>
                            </td>
                            <td>
                                <textarea id="w3review" name="w3review" rows="3" [(ngModel)]="list.description" [disabled]="!list.isSelect" cols="100"></textarea>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="row">
            <p-toolbar>
                <div class="p-toolbar-group-start"></div>
                <div class="p-toolbar-group-end">
                    <button pButton pRipple type="button" label="Submit" (click)="save()" class="p-button-success"></button>
                </div>
            </p-toolbar>
        </div>
    </div>
</div>