import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { ValidateService } from 'src/app/auth/forgot-password/validate.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';

@Component({
  selector: 'app-aadhar-verification-page',
  templateUrl: './aadhar-verification-page.component.html',
  styleUrl: './aadhar-verification-page.component.scss'
})
export class AadharVerificationPageComponent {
  aadharNumber: string = '';
  aadharOtp: string = '';
  hide: boolean = true;
  aadhaarError: boolean;
  txnNo: any;
  enableSubmitButton: boolean;
  resendButton: boolean;
  sendOtp: boolean;
  timer: any;
  otpTimer: number = 0;
  disableOtp: boolean;
  disableAadhaar: boolean;

  constructor(
    protected router: Router,
    protected paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    protected notificationService: NotificationService,
    public sessionStorage: SessionStorageService,
    public validationService: ValidationService
  ) {

  }

  getOtp(resend?) {

    if (resend) {
      this.sessionStorage.store('otpAttempts', this.sessionStorage.retrieve('otpAttempts') - 1);
      if (this.sessionStorage.retrieve('otpAttempts') === 0) {
        this.disableOtp = true;
      }
    }
    this.aadhaarError = !this.validationService.isAadhaarValid(
      this.aadharNumber
    );
    if (this.aadharNumber.length === 12 && !this.aadhaarError) {
      this.disableAadhaar = true;
      let payload = {
        "uid": this.aadharNumber,
        "adminUserId": this.sessionStorage.retrieve('userId'),
        "voucherNo": this.sessionStorage.retrieve('voucherNo')
      }
      this.spinner.show();
      this.paymentService.sendEsignOtp(payload).subscribe(
        (res) => {
          this.startTimer();
          this.txnNo = res.body;
          this.enableSubmitButton = true;
          this.resendButton = true;
          this.sendOtp = true;
          this.spinner.hide();
          setTimeout(() => {
            this.sendOtp = false;
          }, 30000);
        },
        (err) => {
          console.log('err', err);
          if (err.status === 400) {
            this.notificationService.alertError(err.error.message, '');
          }
          else {
            this.notificationService.alertError('Unexpected Error Occur', '');
          }
          this.resendButton = false;
          this.sendOtp = true;
          this.spinner.hide();
        }
      );
    } else {

    }
  }



  submit() {
    let payload = {
      "uid": this.aadharNumber,
      "adminUserId": this.sessionStorage.retrieve('userId'),
      "voucherNo": this.sessionStorage.retrieve('voucherNo'),
      "txn": this.txnNo,
      "otp": this.aadharOtp
    }
    this.spinner.show();
    this.paymentService.validateEsignOtp(payload).subscribe(
      (res) => {
        this.sessionStorage.store('response', res.body);

        this.enableSubmitButton = false;
        this.spinner.hide();
        this.redirect();
      },
      (err) => {
        if (err.status === 400) {
          this.notificationService.alertError(err.error.message, '');
        }
        else {
          this.notificationService.alertError('Unexpected Error Occur', '');
        }
        console.log('err', err);
        this.spinner.hide();
      }
    );
  }

  startTimer() {
    clearInterval(this.timer);
    this.otpTimer = 30;
    this.timer = setInterval(() => {
      this.otpTimer--;
      if (this.otpTimer === 0) {
        clearInterval(this.timer);
      }
    }, 1000);
  }
  redirect() {
    let redriectUrl = '';
    switch (this.sessionStorage.retrieve('paymentType')) {
      case 'No':
        redriectUrl = '/payment';
        break
      case 'Yes':
        redriectUrl = '/payment-personal';
        break
      case 'E':
        redriectUrl = "/employeePaymentTable";
        break
      case 'ER':
        redriectUrl = "/electedRepresenativePaymentTableComponent";
        break
      case 'BE':
        redriectUrl = "/employee-bulkPay-list";
        break
      case 'BER':
        redriectUrl = "/ER-bulkPay-list";
        break
      case 'T':
        redriectUrl = "/Tangedco-Payment-Table";
        break
      case 'TWAD':
        redriectUrl = "/Twad-List";
        break
      case 'BP':
        redriectUrl = "/beneficiary-payment-list";
        break
      case 'TK':
        redriectUrl = "/thooimai-kavalar-payment-list";
        break


        

    }
    this.router.navigate([redriectUrl], {});
  }



}
