import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { LimitSet } from './limit-set';
export type EntityArrayResponseType = HttpResponse<LimitSet[]>;

@Injectable()
export class LimitSetService {
    
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getIAComponentLimitByFilter');
    protected resoureValidFilterUrl = environment.SERVER_API_URL+('api/getIAComponentLimitValidByFilter')
    constructor(protected http: HttpClient) {}
    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<LimitSet[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }
    Validfilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<LimitSet[]>(this.resoureValidFilterUrl,filter, { params:options, observe: 'response' })
    }
   

  
}