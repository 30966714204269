import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { employeeCountDto } from '../report.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { VendorTransaction } from 'src/app/payments/payment-voucher/payment-voucher.model';
import { SearchResult } from 'src/app/shared/models/search-result';
import * as XLSX from 'xlsx';
import { Employee } from 'src/app/module/employee/employee.model';
import { EmployeeService } from 'src/app/module/employee/employee.service';
import { ElectedRepresentative } from 'src/app/module/elected-representative/elected-represenative.model';
import { electedrepresentativecomponentService } from 'src/app/module/elected-representative/elected-represenative.service';

@Component({
  selector: 'app-employee-onboard-report',
  templateUrl: './employee-onboard-report.component.html',
  styleUrls: ['./employee-onboard-report.component.scss']
})
export class EmployeeOnboardReportComponent implements OnInit {

  employeeCountDto:employeeCountDto;
  employeeCountDtoBlockDto:employeeCountDto;
  employeeCountDtoVillageDto:employeeCountDto;
  activeIndex=0;
  roleCode: any;
  levelMasterId: any;
  isDMRole: boolean;
  cols: any;
  ERcols:any;
  itemsPerPage: any = 10;
  filterProps: any;
  filterPropsER: any;
  itemsPerPageER: any = 10;
  searchResult: SearchResult<Employee> =
    new SearchResult<Employee>();
    ERsearchResult: SearchResult<ElectedRepresentative> =
    new SearchResult<ElectedRepresentative>();
  page: number;
  enableStatusVoucher: boolean;
  personalFlag: string;
  villageLevelMasterId: any;
  isBMRole: boolean;
  id: any;
  subEmployeeTypeId: any;
  enableEmployee: boolean;
  post: any;
  enableER: boolean;


  constructor(public reportService:ReportService,
      public fontService: FontService,
      private spinner: NgxSpinnerService,
      private loginService:SigninService,
      private employeeService:EmployeeService,
      private electedrepresentativecomponentService:electedrepresentativecomponentService
    ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.loginService.getuserDto().subscribe(
      (res) => {
       this.roleCode = res.body.role.code;
       this.levelMasterId=res.body.levelMasterId;
       this.loadDistrict();
      },
      (error)=>{});
    this.cols = [
      {
        field: 'name',
        header: 'Name',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.name',
      },
      {
        field: 'employeeType',
        header: 'Employee Type',
        isSortable: true,
        isFilterable: false,
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
        jhiTranslate: 'tableHead.employeeType',
      },
      {
        field: 'subEmployeeType',
        header: 'Sub Employee Type',
        isSortable: true,
        isFilterable: false,
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
        jhiTranslate: 'tableHead.subEmployeeType',
      },
      {
        field: 'escNet',
        header: 'Salary',
        isSortable: true,
        isSelectcolumn: true,
      }
    ];
    this.ERcols = [
      {
        field: 'name',
        header: 'Name',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.name',
      },
      {
        field: 'post',
        header: 'Post',
        isSortable: true,
        isFilterable: false,
        width: 25,
        isSelectcolumn: true,
      },
      {
        field: 'wardNo',
        header: 'Ward No',
        isSortable: true,
        isFilterable: false,
        width: 25,
        isSelectcolumn: true,
      }
    ];
  }

  onTabChange(event: any): void {
    if (event.index === 0 && (this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA')) {
      this.employeeCountDtoBlockDto=null;
      this.employeeCountDtoVillageDto=null;
    }
    else if(event.index === 0 && (this.roleCode=='DM'|| this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2'||this.roleCode=='DAD' || this.roleCode=='DA')) {
      this.employeeCountDtoVillageDto=null;
    }
  }

  loadDistrict(){
    this.spinner.show();
    const payload: any = {
      parentId: 1,
    };
     if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA')
     {
      this.reportService.getEmployeeCountByDistrict(payload).subscribe(
        (res: HttpResponse<employeeCountDto>) => {
          this.employeeCountDto=res.body;
          this.employeeCountDto.employeeCountDTOs.forEach((element)=>{
            for (const key in element) {
              if (element.hasOwnProperty(key) && element[key] === null) {
                element[key] = 0;
              }
          }
        })
          this.spinner.hide();
        },
        (err)=>{
          this.spinner.hide();
        })
      }
      else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD' || this.roleCode==='DA'){
        this.loadBlock(this.levelMasterId);
        this.isDMRole=true;
      } else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2' || this.roleCode=='BAD'){
        this.loadVillage(this.levelMasterId);
        this.isBMRole=true;
      }
  }

  loadBlock(id){
    this.spinner.show();
    const payload: any = {
      parentId: id,
    };
    this.reportService.getEmployeeCountByBlock(payload).subscribe(
      (res: HttpResponse<employeeCountDto>) => {
        this.employeeCountDtoBlockDto=res.body;
        this.employeeCountDtoBlockDto.employeeCountDTOs.forEach((element)=>{
          for (const key in element) {
            if (element.hasOwnProperty(key) && element[key] === null) {
              element[key] = 0;
            }
        }
      })
        if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
          this.activeIndex=1;
        } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode =='DAD' ||  this.roleCode =='DA'){
          this.activeIndex=0;
        }
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }
  loadVillage(id){
    this.spinner.show();
    const payload: any = {
      parentId: id,
    };
    this.reportService.getEmployeeCountByVillage(payload).subscribe(
      (res: HttpResponse<employeeCountDto>) => {
        res.body.employeeCountDTOs.forEach((element)=>{
          for (const key in element) {
            if (element.hasOwnProperty(key) && (element[key] === null || element[key] === 0)) {
              element[key] = "0";
            }
        }
      })
      this.employeeCountDtoVillageDto=res.body;


        if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
          this.activeIndex=2;
        } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode =='DAD' || this.roleCode == 'DA'){
          this.activeIndex=1;
        } else if(this.roleCode=='BM' || this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2' || this.roleCode =='DAD' || this.roleCode == 'DA' || this.roleCode=='BAD'){
          this.activeIndex=0;
        } 
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }

  protected onError(): void {
    this.spinner.hide();
  }

  hideDialog(){
    this.enableEmployee = false;
  }

  hideERDialog(){
    this.enableER=false;
  }

  export(){
    if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA' ) {
      if (this.activeIndex === 0) {
          this.exportToExcel('district-table', 'DistrictWiseEmployeeOnboardReport.xlsx');
      } else if (this.activeIndex === 1) {
          this.exportToExcel('block-table', 'BlockWiseEmployeeOnboardReport.xlsx');
      } else if (this.activeIndex === 2) {
          this.exportToExcel('village-table', 'VillageWiseEmployeeOnboardReport.xlsx');
      }
    }
    else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode =='DAD' || this.roleCode == 'DA'){ 
      if (this.activeIndex === 0) {
        this.exportToExcel('block-table', 'BlockWiseEmployeeOnboardReport.xlsx');
      } else if (this.activeIndex === 1) {
        this.exportToExcel('village-table', 'VillageWiseEmployeeOnboardReport.xlsx');
      }
    }
    else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2' || this.roleCode=='BAD'){ 
        this.exportToExcel('village-table', 'VillageWiseEmployeeOnboardReport.xlsx');
    }
  }

  exportToExcel(id,filename): void {
    let element = document.getElementById(id);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
  }
  loadEmployee(levelMasterId,subEmployeeTypeId){
    this.id=levelMasterId;
    this.subEmployeeTypeId=subEmployeeTypeId;
    this.loadPage(1);
    this.enableEmployee=true;
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = [];
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    this.filterProps.push({
      key: 'levelMasterId',
      operation: 'equals',
      value: this.id,
    });
    this.filterProps.push({
      key: 'subEmployeeType.id',
      operation: 'equals',
      value: this.subEmployeeTypeId,
    });
    this.employeeService.filter({ ...filterQuery }, this.filterProps).subscribe(
      (res: HttpResponse<Employee[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.spinner.hide();
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: Employee[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onEmployeeLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  loadER(levelMasterId,post){
    this.id=levelMasterId;
    this.post=post;
    this.loadERPage(1);
    this.enableER=true;
  }
  loadERPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPageER,
    };
    this.filterPropsER = [];
    this.filterPropsER.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    this.filterPropsER.push({
      key: 'levelMasterId',
      operation: 'equals',
      value: this.id,
    });
    this.filterPropsER.push({
      key: 'post',
      operation: 'equals',
      value: this.post.toUpperCase(),
    });
    this.electedrepresentativecomponentService.filter({ ...filterQuery }, this.filterPropsER).subscribe(
      (res: HttpResponse<ElectedRepresentative[]>) => {
        this.onERSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.spinner.hide();
        this.onError();
      }
    );
  }

  protected onERSuccess(
    data: ElectedRepresentative[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.ERsearchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.ERsearchResult.items = data ?? [];
    this.ERsearchResult = { ...this.ERsearchResult };
  }

  onERLazyLoad(event) {
    this.filterPropsER = event.filterDefinitions;
    this.itemsPerPageER = event.size;
    this.loadERPage(event.page);
  }

}
