<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        {{headingText}}
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col">
                        <div class="card p-3">
                            <div class="row voucherfont">
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.voucher.number.name" | translate }}:<br />{{ swapTransaction?.componentLimitSwap?.voucherNumber }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "date.name" | translate }}:<br />{{ swapTransaction?.componentLimitSwap?.transactionDate | date:'dd/MM/yyyy'}}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "implementing.agency.name" | translate }}:<br />{{ this.swapTransaction.componentLimitSwap?.implementingAgency?.name }}
                                    </p>
                                </div>


                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.department.name" | translate }}:<br />{{ departmentName }}
                                    </p>
                                </div>

                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.scheme.name" | translate }}:<br />{{ schemeName }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Fin Year :<br />{{this.swapTransaction.componentLimitSwap?.finYear?.name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div *ngIf="(roleCode.role.code === 'DS' || roleCode.role.code === 'DA') && transferType === 'CS'" class="col-lg-6">
                            <p [ngClass]="fontService.regularBoldClass">
                                Grant Level :<br />{{this.swapTransaction.componentLimitSwap?.fromComponentMaster?.name }}
                            </p>
                        </div>
                        <div *ngIf="roleCode.role.code !== 'DS' && roleCode.role.code !== 'DA' && transferType === 'CS'" class="col-lg-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Grand Level</label>
                            <ng-select id="scheme" [(ngModel)]="swapTransaction.componentLimitSwap.fromComponentMaster" [disabled]="isApproved || readonly" [items]="grantLevelList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="clearData()"
                                class="form-control dropdown-width-grand">
                            </ng-select>
                        </div>
                        <div *ngIf="(roleCode.role.code === 'DS' || roleCode.role.code === 'DA') && transferType === 'MS'" class="col-lg-5">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Grand Level</label>
                            <ng-select id="scheme" [(ngModel)]="swapTransaction.componentLimitSwap.fromComponentMaster" [disabled]="isApproved || readonly" [items]="districtGrandList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="clearData();getDistrcitComponentLimit()"
                                class="form-control dropdown-width-grand">
                            </ng-select>
                        </div>
                        <div *ngIf="limitAmount" class="col-lg-1">
                            <p [ngClass]="fontService.regularBoldClass">
                                Limit Amount :<br />{{limitAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}
                            </p>
                        </div>
                        <div class="col-lg-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">{{transferType =='CS' ? 'Upload Collector Proceeding' : ' AS Copy'}}</label>
                            <div>
                                <small class="mb-2" [ngClass]="fontService.regularClass">Note : Allowed file types only pdf and
                                    Maximum file size should be 1MB</small>
                            </div>
                            <p-fileUpload accept="application/pdf" (onUpload)="onUpload($event)" [customUpload]="true" [disabled]="isFileUploaded || isApproved" (uploadHandler)="onUpload($event)" auto="true" multiple="false" [showUploadButton]="false" [showCancelButton]="false"
                                chooseLabel="{{'common.upload'|translate}}">
                            </p-fileUpload>
                            <div class="row mt-2" *ngIf="isFileUploaded">
                                <div class="col">
                                    <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument()">{{uFilename}}</a>
                                    <i *ngIf="!readonly" class="pi pi-times add-margin" (click)="removeDocument()"></i>
                                    <i class="pi pi-download add-margin" (click)="downloadDocument()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-md-12 mt-2">
                            <p-table [value]="swapTransaction.componentLimitSwapDescriptions" [resizableColumns]="true" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.labelClass">
                                            {{ "payments.table.sno" | translate }}
                                        </th>
                                        <th *ngIf="this.roleCode.role.code === 'SMAKER' || this.roleCode.role.code === 'SC'" [ngClass]="fontService.labelClass">
                                            District Name
                                        </th>
                                        <th [ngClass]="fontService.labelClass">
                                            Block Name
                                        </th>
                                        <th [ngClass]="fontService.labelClass">
                                            Village Name
                                        </th>
                                        <th *ngIf="!swapTransaction.componentLimitSwap.id && transferType === 'CS'" [ngClass]="fontService.labelClass">
                                            Available Balance
                                        </th>
                                        <th [ngClass]="fontService.labelClass">
                                            To Component
                                        </th>
                                        <th *ngIf="!swapTransaction.componentLimitSwap.id" [ngClass]="fontService.labelClass">
                                            To Component Already Amount
                                        </th>
                                        <th [ngClass]="fontService.labelClass">
                                            Amount
                                        </th>
                                        <th *ngIf="!swapTransaction.componentLimitSwap.id" [ngClass]="fontService.labelClass">
                                            Total Availble To Component Available Amount
                                        </th>

                                        <th *ngIf="!swapTransaction.componentLimitSwap.id && swapTransaction.componentLimitSwapDescriptions.length < 10" style="text-align: right">
                                            <button pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" [disabled]="readonly" styleClass="button" (click)="addRow()" [disabled]="readonly">
                                    <span
                                      class="button-label"
                                      [ngClass]="fontService.buttonClass"
                                      jhiTranslate="common.row"
                                    ></span>
                                  </button>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list let-index="rowIndex">
                                    <tr>
                                        <td>{{ index + 1 }}</td>
                                        <td *ngIf="this.roleCode.role.code === 'SMAKER' || this.roleCode.role.code === 'SC'">
                                            {{list.districtLevelMasterName}}
                                        </td>
                                        <td>{{list.blockLevelMasterName}}</td>
                                        <td>{{list.villageLevelMasterName}}</td>
                                        <td *ngIf="!swapTransaction.componentLimitSwap.id && transferType === 'CS'">{{list.fromComponementAvailableAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                        <td>{{list.toComponentMasterName}}</td>
                                        <td *ngIf="!swapTransaction.componentLimitSwap.id">{{list.toComponementAvailableAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                        <td>{{list.transactionAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                        <td *ngIf="!swapTransaction.componentLimitSwap.id">{{(list.toComponementAvailableAmount + list.transactionAmount) | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                        <td *ngIf="!swapTransaction.componentLimitSwap.id" style="text-align: right">
                                            <button pButton pRipple type="button" [disabled]="readonly" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" (click)="delete(index)"></button>
                                        </td>
                                        <td></td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-6">
                            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label
                                ><br />
                                <textarea
                                  id="w3review"
                                  name="w3review"
                                  rows="3"
                                  [(ngModel)]="swapTransaction.componentLimitSwap.remarks"
                                  cols="100"
                                  [disabled]="enableSignButton || readonly || enableView"
                                  [ngClass]="{
                                    'is-invalid':
                                      ngSelectInvalid &&
                                      !swapTransaction.componentLimitSwap.remarks
                                  }"
                                >
                                </textarea>
                        </div>
                       
                        
                    </div>
                    <p-toolbar *ngIf="this.swapTransaction?.componentLimitSwapDescriptions?.length !== 0 && !enableRevertButton && !this.enableView">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="!enableSignButton" pButton pRipple class="p-button-success custom-button margin" (click)="onsave()" styleClass="button">
                                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.generatevoucher"></span>
                            </button>
                            <button *ngIf="enableSignButton" class="p-button-success custom-button margin" (click)="submitForm()">{{signButtonName}}</button>
                        </div>
                    </p-toolbar>
                    <p-toolbar *ngIf="enableRevertButton && !this.enableView">
                        <div class="p-toolbar-group-start"></div>
                        <div *ngIf="!enableSignButton && !this.swapTransaction.componentLimitSwap.signatureAd" class="p-toolbar-group-end">
                            <button *ngIf="enableApproved" pButton pRipple (click)="onsave()" class="p-button-success custom-button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.approvebutton"></span>
              </button>
                            <button pButton pRipple (click)="reject()" class="p-button-danger custom-button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.rejectbutton"></span>
              </button>
                        </div>
                        <button *ngIf="enableSignButton && !this.swapTransaction.componentLimitSwap.signatureAd" class="p-button-success custom-button margin" (click)="submitForm()">{{signButtonName}}</button>
                    </p-toolbar>
                    <p-toolbar *ngIf="swapTransaction.componentLimitSwap.signatureAd">
                      <div class="p-toolbar-group-start">
                         
                      </div>
                      <button *ngIf="swapTransaction.componentLimitSwap.signatureAd && roleCode.role.code !== 'DAD'" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
                  </p-toolbar>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="showTableFrom" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div class="row mt-3">
                <div *ngIf="this.roleCode.role.code !== 'SMAKER' && this.roleCode.role.code !== 'SC'" [ngClass]="fontService.labelClass" class="col-sm-6 col-md-3">
                    District:<br />{{roleCode?.userName?.split('_')[1]?.toLowerCase()?.charAt(0)?.toUpperCase() + roleCode?.userName?.split('_')[1]?.toLowerCase()?.slice(1)}}
                </div>
                <div *ngIf="this.roleCode.role.code === 'SMAKER' || this.roleCode.role.code === 'SC'" class="col-sm-6 col-md-3">
                    <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">District</label>
                            <ng-select id="scheme" [(ngModel)]="componentLimitSwapDescription.districtLevelMaster" [disabled]="isApproved" [items]="districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange(componentLimitSwapDescription.districtLevelMaster.id)"
                                (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Block</label>
                            <ng-select id="scheme" [(ngModel)]="componentLimitSwapDescription.blockLevelMaster" [disabled]="isApproved" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange(componentLimitSwapDescription.districtLevelMaster.id,$event)"
                                [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Village</label>
                            <ng-select id="scheme" [items]="villageLevelList" bindLabel="name" [disabled]="isApproved" appearance="outline" (search)="onBlockLevelChange($event)" (change)="OnVillageChange()" [(ngModel)]="componentLimitSwapDescription.villageLevelMaster" [closeOnSelect]="true"
                                [clearable]="true" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                        <div [ngClass]="fontService.labelClass" class="col-sm-6 col-md-3">
                            Implementing Agency :<br />{{this.componentLimitSwapDescription.implementingAgency?.name}}
                        </div>
                    </div>
                    <div *ngIf="this.componentLimitSwapDescription.implementingAgency?.name" class="row mt-3">
                        <div *ngIf="transferType === 'CS'" [ngClass]="fontService.labelClass" class="col-sm-6 col-md-3">
                            From Component :<br />{{this.swapTransaction.componentLimitSwap?.fromComponentMaster?.name}}
                        </div>
                        <div *ngIf="transferType === 'CS'" [ngClass]="fontService.labelClass" class="col-sm-6 col-md-3">
                            From Component Available Amount:<br />{{componentLimitSwapDescription.fromComponementAvailableAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}
                        </div>
                        <div *ngIf="roleCode.role.code === 'DS' || roleCode.role.code === 'DA'" [ngClass]="fontService.labelClass" class="col-sm-6 col-md-3">
                            To Component :<br />{{componentLimitSwapDescription.toComponentMaster?.name}}
                        </div>
                        <div *ngIf="roleCode.role.code !== 'DS' && roleCode.role.code !== 'DA'" [ngClass]="fontService.labelClass" class="col-sm-6 col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">To Component</label>
                            <ng-select id="scheme" [(ngModel)]="componentLimitSwapDescription.toComponentMaster" (change)="ongrandLevel('to', this.componentLimitSwapDescription.toComponentMaster.id)" [disabled]="isApproved" [items]="toGrandLevelList" bindLabel="name" appearance="outline"
                                [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                        <div [ngClass]="fontService.labelClass" class="col-sm-6 col-md-3">
                            To Component Available Amount:<br />{{componentLimitSwapDescription.toComponementAvailableAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}
                        </div>
                    </div>
                    <div *ngIf="this.componentLimitSwapDescription.implementingAgency?.name" class="row mt-3">
                        <div class="col-sm-6 col-md-3">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Transaction Amount</label><br />
                            <p-inputNumber id="field_price" inputId="locale-indian" mode="decimal" locale="en-IN" [disabled]="isApproved" [max]="transferType == 'CS'? componentLimitSwapDescription.fromComponementAvailableAmount:limitAmount" [(ngModel)]="componentLimitSwapDescription.transactionAmount"></p-inputNumber>
                        </div>
                        <div [ngClass]="fontService.labelClass" class="col-sm-6 col-md-3">
                            Total Availble To Component Available Amount:<br />{{componentLimitSwapDescription.toComponementAvailableAmount + componentLimitSwapDescription.transactionAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}
                        </div>
                    </div>
                    <div *ngIf="(componentLimitSwapDescription.fromComponementAvailableAmount || limitAmount) && componentLimitSwapDescription.transactionAmount" class="row">
                        <p-toolbar>
                            <div class="p-toolbar-group-start"></div>
                            <div class="p-toolbar-group-end">
                                <button *ngIf="!isApproved" pButton pRipple type="button" label="Apply" (click)="apply()" class="p-button-success"></button>
                            </div>
                        </p-toolbar>
                    </div>
                    </ng-template>
                    </p-dialog>
                    <p-confirmDialog #cd [style]="{width: '50vw'}">
                        <ng-template pTemplate="header">
                            <h3 [ngClass]="fontService.headingClass" jhiTranslate="common.confirmation">Confirmation</h3>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.yes"></span>
            </button>
                            <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cd.reject()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.no"></span>
            </button>
                        </ng-template>
                    </p-confirmDialog>
                </div>