import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';
import { VendorTransaction } from '../payment-voucher/payment-voucher.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-employee-bulk-pay-table',
  templateUrl: './employee-bulk-pay-table.component.html',
  styleUrls: ['./employee-bulk-pay-table.component.scss']
})
export class EmployeeBulkPayTableComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;


  itemsPerPage: any = 10;
  filterProps: any;
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  page: number;
  cols: any;
  roleCode: any;
  vouchercols: any;
  enableStatusVoucher: boolean;
  searchVoucherStatusResult: SearchResult<any> =
    new SearchResult<any>();
  disableEdit: boolean = false;
  disableDelete: boolean = true;
  defaultFinyear: any;
  finYearList: any;
  finYear: any;
  levelMasterId: any;
  villageLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  blockLevelList: LevelMasterDto[];
  levelType: string = 'Village';
  levelTypeList = ['Block', 'Village'];
  villageLevelDto: any;
  currentVendorTransaction: any;
  enableFlagVoucher: boolean;
  levelTypeName: any;
  lgdCode: any;
  constructor(
    private dashboardService: DashboardService,
    protected router: Router,
    protected paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    private loginService: SigninService,
    protected notificationService: NotificationService,
    private levelMasterService: LevelMasterService,
    private limitTransListService: LimitTransListService,
    private sessionStorageService: SessionStorageService,
    private sessionStorage: SessionStorageService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit(): void {
    this.finYearList = this.sessionStorageService.retrieve('finYear');
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
    this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
    this.roleCode = this.sessionStorageService.retrieve('roleCode');
    this.levelMasterId = this.sessionStorageService.retrieve('levelMasterId');
    this.sessionStorage.clear('paymentType');
    this.sessionStorage.clear('voucherNo');
    this.sessionStorage.clear('userId');
    this.sessionStorage.clear('otpAttempts');
    if (this.sessionStorage.retrieve('response')) {
      if (this.sessionStorage.retrieve('response').status === 'SUCCESS') {
        this.notificationService.alertSuccess(this.sessionStorage.retrieve('response').message, '');
      }
      else {
        this.notificationService.alertError(this.sessionStorage.retrieve('response').message, '');
      }
      this.sessionStorage.clear('response');
    }
    this.levelTypeName = this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase();
    switch (this.levelTypeName) {
      case "STATE":
        this.cols = [
          {
            field: 'districtName',
            header: 'Block Name',
            jhiTranslate: 'dashBoard.district',
            isFilterable: false,
            isSelectcolumn: true,
          },
          {
            field: 'blockName',
            header: 'Block Name',
            jhiTranslate: 'dashBoard.block',
            isFilterable: false,
            isSelectcolumn: true,
          },
          {
            field: 'villageName',
            header: 'Block Name',
            jhiTranslate: 'dashBoard.villagePan',
            isFilterable: false,
            isSelectcolumn: true,
          },
          {
            field: 'villageLgd',
            header: 'LGD Code',
            isFilterable: false,
            isSelectcolumn: true,
          },
          {
            field: 'voucherNo',
            header: 'Voucher No',
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.voucher.no',
          },
          {
            field: 'permanentVoucherNumber',
            header: 'Permanent Voucher Number',
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
          },
          {
            field: 'fundGrant',
            header: 'Grand Level',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'payments.grant.level.name',
          },
          {
            field: 'majorComponentName',
            header: 'Major Component Name',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.major.compName',
          },
          {
            field: 'minorComponentName',
            header: 'Minor Component Name',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.minor.compName',
          },
          {
            field: 'subComponentName',
            header: 'Sub Component Name',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.sub.compName',
          },
          {
            field: 'transDate',
            header: 'Transaction Date',
            jhiTranslate: 'tableHead.transaction.date',
            isSortable: true,
            isSelectcolumn: true,
            type: 'date',
          },

          {
            field: 'transactionAmount',
            header: 'Total Transction Amount',
            jhiTranslate: 'tableHead.transaction.amount',
            isSelectcolumn: true,
            isFilterable: true,
            type: 'amount',
          },
          {
            field: 'transactionStatus',
            header: 'Payment Status',
            jhiTranslate: 'tableHead.payment.status',
            isSelectcolumn: true,
            isSortable: true,
            isFilterable: false,
            type: 'color'
          },
        ];
        break;
      case "DISTRICT":
        this.cols = [

          {
            field: 'blockName',
            header: 'Block Name',
            jhiTranslate: 'dashBoard.block',
            isFilterable: false,
            isSelectcolumn: true,
          },
          {
            field: 'villageName',
            header: 'Block Name',
            jhiTranslate: 'dashBoard.villagePan',
            isFilterable: false,
            isSelectcolumn: true,
          },

          {
            field: 'voucherNo',
            header: 'Voucher No',
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.voucher.no',
          },
          {
            field: 'permanentVoucherNumber',
            header: 'Permanent Voucher Number',
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
          },
          {
            field: 'fundGrant',
            header: 'Grand Level',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'payments.grant.level.name',
          },
          {
            field: 'majorComponentName',
            header: 'Major Component Name',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.major.compName',
          },
          {
            field: 'minorComponentName',
            header: 'Minor Component Name',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.minor.compName',
          },
          {
            field: 'subComponentName',
            header: 'Sub Component Name',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.sub.compName',
          },
          {
            field: 'transDate',
            header: 'Transaction Date',
            jhiTranslate: 'tableHead.transaction.date',
            isSortable: true,
            isSelectcolumn: true,
            type: 'date',
          },

          {
            field: 'transactionAmount',
            header: 'Total Transction Amount',
            jhiTranslate: 'tableHead.transaction.amount',
            isSelectcolumn: true,
            isFilterable: true,
            type: 'amount',
          },
          {
            field: 'transactionStatus',
            header: 'Payment Status',
            jhiTranslate: 'tableHead.payment.status',
            isSelectcolumn: true,
            isSortable: true,
            isFilterable: false,
            type: 'color'
          },
        ];
        break;
      case "BLOCK":
        this.cols = [
          {
            field: 'villageName',
            header: 'Block Name',
            jhiTranslate: 'dashBoard.villagePan',
            isFilterable: false,
            isSelectcolumn: true,
          },

          {
            field: 'voucherNo',
            header: 'Voucher No',
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.voucher.no',
          },
          {
            field: 'permanentVoucherNumber',
            header: 'Permanent Voucher Number',
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
          },
          {
            field: 'fundGrant',
            header: 'Grand Level',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'payments.grant.level.name',
          },
          {
            field: 'majorComponentName',
            header: 'Major Component Name',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.major.compName',
          },
          {
            field: 'minorComponentName',
            header: 'Minor Component Name',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.minor.compName',
          },
          {
            field: 'subComponentName',
            header: 'Sub Component Name',
            isSortable: true,
            isFilterable: true,
            isSelectcolumn: true,
            jhiTranslate: 'tableHead.sub.compName',
          },
          {
            field: 'transDate',
            header: 'Transaction Date',
            jhiTranslate: 'tableHead.transaction.date',
            isSortable: true,
            isSelectcolumn: true,
            type: 'date',
          },

          {
            field: 'transactionAmount',
            header: 'Total Transction Amount',
            jhiTranslate: 'tableHead.transaction.amount',
            isSelectcolumn: true,
            isFilterable: true,
            type: 'amount',
          },
          {
            field: 'transactionStatus',
            header: 'Payment Status',
            jhiTranslate: 'tableHead.payment.status',
            isSelectcolumn: true,
            isSortable: true,
            isFilterable: false,
            type: 'color'
          },
        ];
        break;
        case "VILLAGE":
          this.cols = [
            {
              field: 'voucherNo',
              header: 'Voucher No',
              jhiTranslate: 'tableHead.voucher.no',
              isFilterable: true,
              isSelectcolumn: true,
            },
            {
              field: 'permanentVoucherNumber',
              header: 'Permanent Voucher Number',
              isFilterable: true,
              isSelectcolumn: true,
              jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
            },
            {
              field: 'transDate',
              header: 'Transaction Date',
              jhiTranslate: 'tableHead.transaction.date',
              isSortable: true,
              isSelectcolumn: true,
              type: 'date',
            },
            {
              field: 'transactionAmount',
              header: 'Total Transaction Amount',
              jhiTranslate: 'tableHead.transaction.amount',
              isSelectcolumn: true,
              isFilterable: true,
              type: 'amount'
            },
            {
              field: 'subComponentName',
              header: 'Sub Component Name',
              jhiTranslate: 'tableHead.sub.compName',
              isSortable: true,
              isFilterable: true,
              isSelectcolumn: true,
            },
            {
              field: 'transactionStatus',
              header: 'Payment Status',
              jhiTranslate: 'tableHead.payment.status',
              isSelectcolumn: true,
              isSortable: true,
              isFilterable: false,
              type: 'color'
            },
          ];
        break;
      default:
        break;
    }
    
    // this.loadPage(1);
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.paymentService.getVoucher().subscribe(
      (res: HttpResponse<any>) => {
        this.router.navigate(['/employeeBulkPayVoucher'], {
          queryParams: {
            VoucherNo: res.body,
          },
        });
      },
      () => { }
    );
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = new filterQuery();
    query.key = 'personalFlag';
    query.operation = 'equals';
    query.value = 'BE';
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push(query);
    query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterProps.push(query);
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    if (this.roleCode !== 'DAD' && (this.levelTypeName === 'VILLAGE' ||  this.levelTypeName === 'STATE')) {
      if (this.finYear) {
        query = new filterQuery();
        query.key = 'finyear.id';
        query.operation = 'equals';
        query.value = this.finYear.id;
        this.filterProps.push(query);
      }
      this.paymentService.filter({ ...filter }, this.filterProps).subscribe(
        (res: HttpResponse<VendorTransaction[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.spinner.hide();
          this.onError();
        }
      );
    }
    else if(this.roleCode !== 'DAD' && this.levelTypeName !== 'VILLAGE' && this.levelTypeName !== 'STATE') {
      filter['levelType'] = this.levelTypeName;
      filter['personalFlag'] = 'BE';
      filter['levelMasterId'] = this.levelMasterId;
      filter['finYearId'] = this.finYear.id
      if(this.lgdCode) {
        filter['lgdCode']= this.lgdCode;
      }
      if(this.roleCode === 'ZVM') {
        filter['zonalName'] =  this.sessionStorage.retrieve('user')?.zonalName;
      }
      
      this.paymentService.getPaymentByLevel({ ...filter }).subscribe(
        (res: HttpResponse<VendorTransaction[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.spinner.hide();
          this.onError();
        }
      );
    }

    else {
      filter['finYearId'] = this.finYear.id;
      filter['personalFlag'] = 'BE';
      if (this.levelType === 'Block' && this.BlockLevelDto) {
        filter['levelMasterId'] = this.BlockLevelDto.id;
      } else if (this.levelType === 'Village' && this.villageLevelDto) {
        filter['levelMasterId'] = this.villageLevelDto.id;
      }
      this.paymentService.getTransactionsByDistrict({ ...filter }).subscribe(
        (res: HttpResponse<VendorTransaction[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.spinner.hide();
          this.onError();
        }
      );
    }
  }

  onFilterClear() {
    this.levelType = 'Village';
    this.BlockLevelDto = null;
    this.villageLevelDto = null;
    this.finYear = this.defaultFinyear;
    this.filterProps = [];
    this.lgdCode = null;
    this.loadPage(1)
  }

  protected onSuccess(
    data: VendorTransaction[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    if (this.roleCode !== 'DAD') {
      data.forEach(element => {
        switch (element.transactionStatus) {
          case null:
            element.transactionStatus = 'Not Initiated';
            break;
          case 'O.K.':
            element.transactionStatus = 'Success';
            break;
          case 'failed':
            element.transactionStatus = 'Failed';
            break;
          case 'processing':
            element.transactionStatus = 'Processing';
            break;
          default:
            break;
        }
        if (element.count === 1 && element.signatureSecretary === null && (this.roleCode === 'DMAKER' || this.roleCode === 'VMAKER' || this.roleCode === 'BMAKER')) {
          element.edit = true;
          element.delete = true;
        }
        else if (element.count === 2 && element.signatureVicePresident === null && !element.parentVoucherNumber && element.signatureSecretary !== null && (this.roleCode === 'BC1' || this.roleCode === 'ZC1' || this.roleCode === 'DC1' || this.roleCode === 'VC1')) {
          element.edit = true;
        }
        else if (element.count === 3 && element.signaturePresident === null && !element.parentVoucherNumber && element.signatureVicePresident !== null && element.signatureSecretary !== null && (this.roleCode === 'DC2' || this.roleCode === 'VC2' || this.roleCode === 'BC2')) {
          element.edit = true;
        }
        else {
          element.edit = false;
        }
      });
    }

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  async onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onSelectRow($event) {
    if ($event.type === 'select') {
      this.router.navigate(['/employeeBulkPayVoucher'], {
        queryParams: {
          id: $event.value.id,
        },
      });
    }
    else if ($event.type === 'view') {
      if(this.levelTypeName !== 'VILLAGE' && this.levelTypeName !== 'STATE') {
        this.router.navigate(['/employeeBulkPayVoucher'], {
          queryParams: {
            id: $event.value.vendorTransactionId,
            view: true
          },
        });
      }
      else {
        this.router.navigate(['/employeeBulkPayVoucher'], {
          queryParams: {
            id: $event.value.id,
            view: true
          },
        });
      }
    }
    else if($event.type === 'flag') {
      this.currentVendorTransaction = $event.value;
      this.enableFlagVoucher = true;
    }    
    else {
      this.spinner.show();
      this.paymentService.deletedVocher($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.loadPage(this.page);
        },
        (onError) => {
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.title, '');
          } else {
            this.notificationService.alertError('Error in Reject', '');
          }
          this.spinner.hide();
        }
      );
    }
  }
  getSubTableDetails($event) {
    this.vouchercols = [
      {
        field: 'name',
        header: 'Name',
        isSelectcolumn: true,
      },
      {
        field: 'description',
        header: 'Description',
        isSelectcolumn: true
      },
      {
        field: 'accountNumber',
        header: 'Account No',
        isSelectcolumn: true,
      },
      {
        field: 'grossAmount',
        header: 'Gross Amount',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.grossTotal',
        type: 'amount'
      },
      {
        field: 'transactionStatus',
        header: 'Payment Status',
        jhiTranslate: 'tableHead.payment.status',
        isSelectcolumn: true,
        isSortable: true,
        isFilterable: false,
        type: 'color'
      },
      {
        field: 'createdOn',
        header: 'Created Date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
    this.dashboardService.getVoucherSubtableById('BE', $event.transactionId).subscribe(
      (res: HttpResponse<any>) => {
        this.enableStatusVoucher = true;
        this.searchVoucherStatusResult.total = Number(
          res.headers.get('X-Total-Count')
        );
        res.body.forEach(element => {
          if(element.isCancelled) {
            element.transactionStatus = 'Failed and Returned';
          }
        });
        this.searchVoucherStatusResult.items = res.body ?? [];
        this.searchVoucherStatusResult = { ...this.searchVoucherStatusResult };
      },
      () => {
        this.onError();
      }
    );
  }

  onDistrictLevelChange($event?) {

    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.levelMasterId,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.levelMasterId,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    }
  }

  onBlockLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    }
  }


}

