import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import {
  paymentVoucherGen,
  ImplementingAgencyDto,
  VendorTransaction,
  DescriptionDto,
  limitDto,
  ComponentMasterDto,
  MyFiles,
  EmuthraResponseDto,
  EmudhraRequest,
  SwapTransactionDto,
  SwappingTransactionDto,
  VendorTransactionBulkDto,
  VendorFailedTransaction,
} from './payment-voucher.model';
import { TangedcoBillTmp } from 'src/app/tneb/tangedco-bill-details/tangedco-bill-details';
import { DatePipe } from '@angular/common';
export type EntityResponseType = HttpResponse<paymentVoucherGen>;
export type EntityArrayResponseType = HttpResponse<VendorTransaction[]>;
export type EntityArraySwapResponseType = HttpResponse<SwapTransactionDto[]>;
export type DescrptionArrayResponseType = HttpResponse<DescriptionDto[]>;
export type TangedcoBillTmpResponseType = HttpResponse<TangedcoBillTmp[]>;

@Injectable({ providedIn: 'root' })
export class PaymentService {
  protected getVoucherNoUrl = environment.SERVER_API_URL + 'api/getVoucherNo';
  protected VoucherSaveUrl =
    environment.SERVER_API_URL + 'api/voucher/generatePdfQ';
  protected VoucherSwapSaveUrl =
    environment.SERVER_API_URL + 'api/voucher/swapGeneratePdf';
  protected PaymentIntergationUrl =
    environment.SERVER_API_URL + 'api/paymentIntegration';
  protected PaymentStatusUrl =
    environment.SERVER_API_URL + 'api/transactionStatus';
  protected emudhraUrl = environment.SERVER_API_URL + 'api/emudhraRedirect';
  protected getFindImplementingById =
    environment.SERVER_API_URL + 'api/getImplementingAgency';
  protected getListTransction =
    environment.SERVER_API_URL + 'api/getVendorTransactionByFilter';
  protected getSwapListTransction =
    environment.SERVER_API_URL + 'api/getSwapTransactionByFilter';
  protected getLastfiveTransction =
    environment.SERVER_API_URL + 'api/getCommercialLastFiveTransaction';
  protected getLastPersonalFiveTransction =
    environment.SERVER_API_URL + 'api/getPersonalLastFiveTransaction';
  protected uploadImageUrl = environment.SERVER_API_URL + 'api/uploadFile';
  protected downloadFileUrl = environment.SERVER_API_URL + 'api/downloadFile';
  protected viewFileUrl = environment.SERVER_API_URL + 'api/viewFile';
  protected deleteFileUrl = environment.SERVER_API_URL + 'api/deleteFile';
  protected descrptionUrl = environment.SERVER_API_URL + 'api/descriptionList';
  protected UpdatedVoucher =
    environment.SERVER_API_URL + 'api/getVendorTransaction';
  protected UpdatedSwapVoucher =
    environment.SERVER_API_URL + 'api/getSwapTransaction';
  protected getLimitAmount =
    environment.SERVER_API_URL +
    'api/getIAComponentLimitByIaIdAndCompnentTypeId';
  protected getComponentMasterUrl =
    environment.SERVER_API_URL + 'api/getComponentMaster';
  protected DeleteTransctionUrl =
    environment.SERVER_API_URL + 'api/deleteVendorTransaction';
  protected DeleteSwapTransctionUrl =
    environment.SERVER_API_URL + 'api/deleteSwapTransaction';
  protected revertVendorTransUrl =
    environment.SERVER_API_URL + 'api/revertVendorTransaction';
  protected revertSwapTransUrl =
    environment.SERVER_API_URL + 'api/revertSwapTransaction';
  protected reportTransUrl =
    environment.SERVER_API_URL + 'api/getVendorTransactionValidByFilter';
  protected getfileUrl = environment.SERVER_API_URL + 'api/cdacRequest';
  protected getPdfUrl =
    environment.SERVER_API_URL + 'api/emuthraSignedDownload';
  protected getSwapfileUrl = environment.SERVER_API_URL + 'api/cdacSwapRequest';
  protected getSwapPdfUrl =
    environment.SERVER_API_URL + 'api/swapEmuthraSignedDownload';
  protected getpaymentStatusUrl =
    environment.SERVER_API_URL + 'api/paymentStatus';
  protected bulkPaymentStatusUrl =
    environment.SERVER_API_URL + 'api/bulkPaymentStatus';
  protected getZonalCheckersUrl =
    environment.SERVER_API_URL + 'api/grtZonalCheckers';
  protected bulkPaymentListUrl =
    environment.SERVER_API_URL + 'api/bulkPaymentList';
  protected componentMasterListUrl =
    environment.SERVER_API_URL + 'api/componentMasterList';

  protected getFailedTransactionUrl =
    environment.SERVER_API_URL + 'api/getFailedTransaction';
  protected getFailedBEmpVoucherDescUrl =
    environment.SERVER_API_URL + 'api/getFailedBEmpVoucherDesc';
  protected getFailedBErepVoucherDescUrl =
    environment.SERVER_API_URL + 'api/getFailedBErepVoucherDesc';
  protected revertBulkPaymentAmountUrl =
    environment.SERVER_API_URL + 'api/revertBulkPaymentAmount';
  protected getAllSignTwoPendingVouchersUrl =
    environment.SERVER_API_URL + 'api/getAllSignTwoPendingVouchers';
  protected getAllSignOnePendingVouchers = 
    environment.SERVER_API_URL + 'api/getAllSignOnePendingVouchers';
  protected saveParentVoucherUrl =
    environment.SERVER_API_URL + 'api/saveParentVoucher';
  protected getAllParentVoucher =
    environment.SERVER_API_URL + 'api/getAllParentVoucher';
  protected getParentVoucherByIdurl =
    environment.SERVER_API_URL + 'api/getParentVoucherById';
  protected getTnebrequestUrl =
    environment.SERVER_API_URL + 'api/generateBillReq';
  protected getTransactionsByDistrictUrl = environment.SERVER_API_URL + 'api/getTransactionsByDistrict';
  protected getConsoildatedBills = environment.SERVER_API_URL+('api/fetchTangedcoBills');
  protected getTangedcoFailedDescurl = environment.SERVER_API_URL+('api/getTangedcoFailedDesc');
  protected getTangedcoFailedTrans = environment.SERVER_API_URL+('api/getTangedcoFailedTrans');
  protected paymentAsyncStatusUrl = environment.SERVER_API_URL+('api/paymentAsyncStatus');
  protected tangedcoPaymentVerificationUrl = environment.SERVER_API_URL+('api/tangedcoPaymentVerification');
  protected getDescByPostingIdUrl = environment.SERVER_API_URL+('api/getDescByPostingId');
  protected sendEsignOtpUrl = environment.SERVER_API_URL+('api/sendEsignOtp');
  protected validateEsignOtpUrl = environment.SERVER_API_URL+('api/validateEsignOtp');
  fromDate1: string;
  toDate1: string;

  constructor(protected http: HttpClient,private datePipe: DatePipe) {}

  componentMasterList(): Observable<any> {
    return this.http.get(this.componentMasterListUrl, { observe: 'response' });
  }

  getFailedTransaction(
    levelMasterId: number,
    personalFlag: string
  ): Observable<any> {
    return this.http.get(
      `${this.getFailedTransactionUrl}?levelMasterId=${levelMasterId}&personalFlag=${personalFlag}`,
      { observe: 'response' }
    );
  }

  getDescByPostingId(req?: any): Observable<any>{
    const options = createRequestOption(req);
    return this.http.get(this.getDescByPostingIdUrl,{
      params: options,
      observe: 'response',
    });
  }

  getTangedcoFailedTransaction(
    levelMasterId: number,
  ): Observable<any> {
    return this.http.get(
      `${this.getTangedcoFailedTrans}?levelMasterId=${levelMasterId}`,
      { observe: 'response' }
    );
  }

  getTangedcoFailSubList( id: number,
    failureType: string) : Observable<any> {
      return this.http.get(
        `${this.getTangedcoFailedDescurl}?vendorTransactionId=${id}&failureType=${failureType}`,
        { observe: 'response' }
      );
    }
  getFailedBEmpVoucherDesc(vendorTransactionId: number): Observable<any> {
    return this.http.get(
      `${this.getFailedBEmpVoucherDescUrl}?vendorTransactionId=${vendorTransactionId}`,
      { observe: 'response' }
    );
  }
  getFailedBErepVoucherDesc(vendorTransactionId: number): Observable<any> {
    return this.http.get(
      `${this.getFailedBErepVoucherDescUrl}?vendorTransactionId=${vendorTransactionId}`,
      { observe: 'response' }
    );
  }
  revertBulkPaymentAmount(id: number, personalFlag: string, descId: number) {
    return this.http.get(
      `${this.revertBulkPaymentAmountUrl}?vendorTransactionId=${id}&personalFlag=${personalFlag}&voucherDescId=${descId}`,
      { observe: 'response', responseType: 'text' }
    );
  }

  checkPaymentStatus(id: number, descId: number) {
    return this.http.get(
      `${this.tangedcoPaymentVerificationUrl}?vendorTransId=${id}&voucherDescId=${descId}`,
      { observe: 'response', responseType: 'text' }
    );
  }

  getVoucher(req?): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(this.getVoucherNoUrl, {
      observe: 'response',
      params:options,
      responseType: 'text',
    });
  }

  sendEsignOtp(req?): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(this.sendEsignOtpUrl, {
      observe: 'response',
      params:options,
    });
  }

  validateEsignOtp(req?): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(this.validateEsignOtpUrl, {
      observe: 'response',
      params:options,
    });
  }

  

  

  getfile(fileName: string): Observable<any> {
    return this.http.get(`${this.getfileUrl}?fileName=${fileName}`, {
      observe: 'response',
    });
  }
  getSwapfile(fileName: string): Observable<any> {
    return this.http.get(`${this.getSwapfileUrl}?fileName=${fileName}`, {
      observe: 'response',
    });
  }

  genVocher(vocher: paymentVoucherGen) {
    return this.http.post(this.VoucherSaveUrl, vocher, {
      observe: 'response',
      responseType: 'arraybuffer',
    });
  }
  genSwapVocher(vocher: SwappingTransactionDto) {
    return this.http.post(this.VoucherSwapSaveUrl, vocher, {
      observe: 'response',
      responseType: 'arraybuffer',
    });
  }
  paymentSuccess(vocher: paymentVoucherGen) {
    return this.http.post(this.PaymentIntergationUrl, vocher, {
      observe: 'response',
      responseType: 'text',
    });
  }

  getZonalCheckers(id: any) {
    return this.http.get(`${this.getZonalCheckersUrl}?levelMasterId=${id}`, {
      observe: 'response',
    });
  }

  getPaymentStatus(id: any) {
    return this.http.get(`${this.getpaymentStatusUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  bulkPaymentStatus(id: any) {
    return this.http.get(`${this.bulkPaymentStatusUrl}?vendorTransId=${id}`, {
      observe: 'response',
    });
  }

  paymentAsyncStatus(id: any) {
    return this.http.get(`${this.paymentAsyncStatusUrl}?vendorTransId=${id}`, {
      responseType: 'text',
    });
  }
  bulkPaymentList(id: any) {
    return this.http.get(`${this.bulkPaymentListUrl}?vendorTransId=${id}`, {
      observe: 'response',
    });
  }

  paymentStatus(id: any) {
    return this.http.get(`${this.PaymentStatusUrl}?interactionId=${id}`, {
      observe: 'response',
    });
  }
  failedTransactionStatus(
    id: number,
    paymentCategory: string,
    descId?: number
  ) {
    if (descId) {
      return this.http.get(
        `${this.PaymentStatusUrl}?vtId=${id}&paymentCategory=${paymentCategory}&descId=${descId}`,
        { observe: 'response' }
      );
    } else {
      return this.http.get(
        `${this.PaymentStatusUrl}?vtId=${id}&paymentCategory=${paymentCategory}`,
        { observe: 'response' }
      );
    }
  }

  emudhraRedriect(file: EmudhraRequest) {
    return this.http.post(this.emudhraUrl, file, { observe: 'response' });
  }

  getPdfFile(fileName: string) {
    return this.http.get(`${this.getPdfUrl}?filename=${fileName}`, {
      observe: 'response',
      responseType: 'arraybuffer',
    });
  }

  getSwapPdfFile(fileName: string) {
    return this.http.get(`${this.getSwapPdfUrl}?filename=${fileName}`, {
      observe: 'response',
      responseType: 'arraybuffer',
    });
  }

  updatedVocher(id: number): Observable<HttpResponse<any>> {
    return this.http.get<paymentVoucherGen>(`${this.UpdatedVoucher}?id=${id}`, {
      observe: 'response',
    });
  }
  updatedSwapVocher(id: number): Observable<HttpResponse<any>> {
    return this.http.get<SwappingTransactionDto>(
      `${this.UpdatedSwapVoucher}?id=${id}`,
      { observe: 'response' }
    );
  }

  deletedVocher(id: number): Observable<any> {
    return this.http.get(`${this.DeleteTransctionUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  deletedSwapVocher(id: number): Observable<any> {
    return this.http.get(`${this.DeleteSwapTransctionUrl}?id=${id}`, {
      observe: 'response',
    });
  }
  getComponentMaster(id: number): Observable<HttpResponse<any>> {
    return this.http.get<ComponentMasterDto>(
      `${this.getComponentMasterUrl}?id=${id}`,
      { observe: 'response' }
    );
  }

  getimage(fileName: string): any {
    return this.http.get(`${this.downloadFileUrl}/${fileName}`, {
      observe: 'response',
    });
  }
  viewimage(fileName: string): any {
    return this.http.get(`${this.viewFileUrl}/${fileName}`, {
      observe: 'response',
    });
  }
  deleteimage(fileName: string): any {
    return this.http.get(`${this.deleteFileUrl}/${fileName}`, {
      observe: 'response',
    });
  }

  getDescrptionList(): Observable<DescrptionArrayResponseType> {
    return this.http.get<DescriptionDto[]>(this.descrptionUrl, {
      observe: 'response',
    });
  }
  filter(req?: any, filter?: any): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<VendorTransaction[]>(this.getListTransction, filter, {
      params: options,
      observe: 'response',
    });
  }
  getTransactionsByDistrict(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<VendorTransaction[]>(this.getTransactionsByDistrictUrl,{
      params: options,
      observe: 'response',
    });
  }
  swapFilter(req?: any, filter?: any): Observable<EntityArraySwapResponseType> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<SwapTransactionDto[]>(
      this.getSwapListTransction,
      filter,
      { params: options, observe: 'response' }
    );
  }
  reportfilter(
    req?: any,
    filter?: any,
    fromDate?: any,
    toDate?: any
  ): Observable<EntityArrayResponseType> {
    if (filter === null) {
      filter = [];
    }
    if (fromDate && toDate) {
      const options = createRequestOption(req);
      return this.http.post<VendorTransaction[]>(
        `${this.reportTransUrl}?fromDate=${fromDate}&toDate=${toDate}`,
        filter,
        { params: options, observe: 'response' }
      );
    } else {
      const options = createRequestOption(req);
      return this.http.post<VendorTransaction[]>(this.reportTransUrl, filter, {
        params: options,
        observe: 'response',
      });
    }
  }

  getListVendorTransction(id) {
    const options = createRequestOption(id);
    return this.http.get<VendorTransaction>(this.getLastfiveTransction, {
      params: { id: id, page: 0, size: 5 },
      observe: 'response',
    });
  }

  getListPersonalVendorTransction(id) {
    const options = createRequestOption(id);
    return this.http.get<VendorTransaction>(
      this.getLastPersonalFiveTransction,
      { params: { id: id, page: 0, size: 5 }, observe: 'response' }
    );
  }

  getByImplemeting(id: number) {
    return this.http.get<ImplementingAgencyDto>(this.getFindImplementingById, {
      params: { id: id },
      observe: 'response',
    });
  }
  uploadImage(image) {
    return this.http.post<any>(this.uploadImageUrl, image, {
      observe: 'response',
    });
  }

  getLimit(id: number, compId: number,finyearId) {
    return this.http.get<limitDto>(this.getLimitAmount, {
      params: { iaId: id, componentTypeId: compId ,finyearId:finyearId},
      observe: 'response',
    });
  }



  revertTranaction(id: number) {
    return this.http.get<any>(`${this.revertVendorTransUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  revertSwapTranaction(id: number) {
    return this.http.get<any>(`${this.revertSwapTransUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  getSign2PendingVoucher(req) {
    const options = createRequestOption(req);
    return this.http.get(this.getAllSignOnePendingVouchers, {
      params: options,
      observe: 'response',
    });
  }

  genBulkVocher(vocher: VendorTransactionBulkDto) {
    return this.http.post(this.saveParentVoucherUrl, vocher, {
      observe: 'response',
      responseType: 'text',
    });
  }

  getparentVoucherlist(req) {
    const options = createRequestOption(req);
    return this.http.get(this.getAllParentVoucher, {
      params: options,
      observe: 'response',
    });
  }

  getParentVocherbyId(id: number): Observable<HttpResponse<any>> {
    return this.http.get<VendorTransactionBulkDto>(
      `${this.getParentVoucherByIdurl}?parentVoucherId=${id}`,
      { observe: 'response' }
    );
  }

  getTnebRequestDetails() {
    return this.http.get(this.getTnebrequestUrl, { observe: 'response',responseType: 'text', });
  }

  loadTangedcoBills(): Observable<TangedcoBillTmpResponseType>{
  
    return this.http.get<TangedcoBillTmp[]>(this.getConsoildatedBills, {observe: 'response' });
  }
}
