import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { DistrictInternalTransaction, DistrictInternalTransactionDto } from './district-interal-swap-model';

export type EntityResponseType = HttpResponse<DistrictInternalTransaction[]>;

@Injectable({ providedIn: 'root' })
export class DistrictInternalSwapService {
    protected resourceFliterUrl = environment.SERVER_API_URL + ('api/getInternalSwapTransByFilter');
    protected resourceSaveUrl = environment.SERVER_API_URL + ('api/saveDistrictInternalSwap');
    protected getInternalTransactionUrl = environment.SERVER_API_URL + ('api/getInternalTransaction');
    protected aprroveInternalSwapUrl = environment.SERVER_API_URL + ('api/aprroveInternalSwap');
    protected rejectInternalTransactionUrl = environment.SERVER_API_URL + ('api/rejectInternalTransaction');
    constructor(protected http: HttpClient) { }

    filter(req?: any, filter?: any): Observable<any> {
        if (filter === null) {
            filter = [];
        }
        const options = createRequestOption(req);
        return this.http.post<any[]>(this.resourceFliterUrl, filter, {
            params: options,
            observe: 'response',
        });
    }
    save(payload: DistrictInternalTransactionDto): any {
        return this.http.post(this.resourceSaveUrl, payload, { observe: 'response' });
    }

    getInternalTransaction(id: number): Observable<HttpResponse<DistrictInternalTransactionDto>> {
        return this.http.get<DistrictInternalTransactionDto>(
            `${this.getInternalTransactionUrl}?internalSwapId=${id}`,
            { observe: 'response' }
        );
    }

    approveSwap(id: number) {
        return this.http.get(
            `${this.aprroveInternalSwapUrl}?internalSwapId=${id}`,
            { responseType: 'text' }
        );
    }
    rejectSwap(id: number) {
        return this.http.get(
            `${this.rejectInternalTransactionUrl}?internalSwapId=${id}`,
            { responseType: 'text' }
        );
    }


}