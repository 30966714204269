<div class="container-fluid">

    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.accountwiseFund"></h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <p-dropdown placeholder="Select Fin Year" [options]="finYearList" [(ngModel)]="finYear" optionLabel="name" showClear="false"></p-dropdown>
        <button pButton pRipple label="Search" (click)="loadComponent()" class="p-button-sm"></button>
        <button pButton pRipple label="Village Limit Details Excel" (click)="downloadExport()" class="p-button-success p-button-sm export-button"></button>
        <button pButton pRipple label="Check Status" (click)="checkStatus()" class="p-button-success p-button-sm"></button>
    </div>
    <div class="col-12 px-4" *ngIf="componentFund">
        <p-table id="componentFund-table" [value]="componentFund" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="componentName" filterMatchMode="icon">Component Name
                        <p-columnFilter type="text" field="componentName" display="menu"></p-columnFilter>
                    </th>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="sanctionAmount">Allocated Amount</th>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="utilizedAmount">Amount Utilized</th>
                    <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="balanceAmount">Balance Amount</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-list let-index="rowIndex">
                <tr>
                    <td style="text-align: center;">{{index+1}}</td>
                    <td style="text-align: center;">
                        <p-button [label]="list.componentName" (click)="loadFundDialog(list.componentMasterId,list.componentName)" styleClass="p-button-link"></p-button>
                    </td>
                    <td style="text-align: center;">{{list.sanctionAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                    <td style="text-align: center;">{{list.utilizedAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                    <td style="text-align: center;">{{list.balanceAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-dialog #dialog [(visible)]="fundListDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog" [style]="{ width: '1300px' }">
        <p-header [ngClass]="fontService.headingClass">
            Fund Report for {{componentMasterName}} </p-header>
        <ng-template pTemplate="content">
            <div class="input-row">
                <!-- <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="fromDate"
             [showIcon]="true" styleClass="datepicker"
            [maxDate]="maxDate"></p-calendar>
            <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="toDate"
             [showIcon]="true" styleClass="datepicker"
            [maxDate]="maxDate"></p-calendar>
            <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button> -->
                <button pButton pRipple label="Export to Excel" (click)="export()" class="p-button-success p-button-sm export-button"></button>

            </div>
            <div class="row">
                <div *ngIf="dateError" class="text-danger mb-2">
                    <small [ngClass]="fontService.labelClass" jhiTranslate="error.invaliddate">Please
                    choose valid from and to
                    date</small>
                </div>
            </div>
            <div class="row">
                <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
                    <p-tabPanel header="District" *ngIf="!isDMRole && !isBMRole">
                        <div class="col-12 px-4" *ngIf="componentFund">
                            <p-table id="district-table" [value]="compFundDto" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                                            <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                        </th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass">Number of Block</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass">Number of village</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="sanctionAmount">Allocated Amount</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="utilizedAmount">Amount Utilized</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="balanceAmount">Balance Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list let-index="rowIndex">
                                    <tr>
                                        <td style="text-align: center;">{{index+1}}</td>
                                        <td style="text-align: center;">
                                            <p-button [label]="list.levelMasterName" (click)="loadBlock(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                        </td>
                                        <td style="text-align: center;">{{list.levelOneCount}}</td>
                                        <td style="text-align: center;">{{list.levelTwoCount}}</td>
                                        <td style="text-align: center;">{{list.sanctionAmount}}</td>
                                        <td style="text-align: center;">{{list.utilizedAmount}}</td>
                                        <td style="text-align: center;">{{list.balanceAmount}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Block" *ngIf="!isBMRole">
                        <div *ngIf="!compFundBlockDto">Choose distict to get block report</div>
                        <div *ngIf="compFundBlockDto" class="col-12 px-4">
                            <p-table id="block-table" [value]="compFundBlockDto" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Block Name
                                            <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                        </th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass">Number of village</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="sanctionAmount">Allocated Amount</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="utilizedAmount">Amount Utilized</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="balanceAmount">Balance Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list let-index="rowIndex">
                                    <tr>
                                        <td style="text-align: center;">{{index+1}}</td>
                                        <td style="text-align: center;">
                                            <p-button [label]="list.levelMasterName" (click)="loadVillage(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                        </td>
                                        <td style="text-align: center;">{{list.levelOneCount}}</td>
                                        <td style="text-align: center;">{{list.sanctionAmount}}</td>
                                        <td style="text-align: center;">{{list.utilizedAmount}}</td>
                                        <td style="text-align: center;">{{list.balanceAmount}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Village">
                        <div *ngIf="!compFundVillageDto">Choose block to get village report</div>
                        <div *ngIf="compFundVillageDto" class="col-12 px-4">
                            <p-table id="village-table" [value]="compFundVillageDto" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Village Name
                                            <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                        </th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="sanctionAmount">Allocated Amount</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="utilizedAmount">Amount Utilized</th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="balanceAmount">Balance Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list let-index="rowIndex">
                                    <tr>
                                        <td style="text-align: center;">{{index+1}}</td>
                                        <td style="text-align: center;">{{list.levelMasterName}}</td>
                                        <td style="text-align: center;">{{list.sanctionAmount}}</td>
                                        <td style="text-align: center;">{{list.utilizedAmount}}</td>
                                        <td style="text-align: center;">{{list.balanceAmount}}</td>
                                        <!-- <td style="text-align: center;"><p-button [label]="list.personalMakerPending" (click)="loadVoucher(list.levelMasterId,'YES','M')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.personalChecker1Pending" (click)="loadVoucher(list.levelMasterId,'YES','C1')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.personalChecker2Pending" (click)="loadVoucher(list.levelMasterId,'YES','C2')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.personalApproved" (click)="loadVoucher(list.levelMasterId,'YES','A')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.commercialMakerPending" (click)="loadVoucher(list.levelMasterId,'NO','M')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.commercialChecker1Pending" (click)="loadVoucher(list.levelMasterId,'NO','C1')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.commercialChecker2Pending" (click)="loadVoucher(list.levelMasterId,'NO','C2')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.commercialApproved" (click)="loadVoucher(list.levelMasterId,'NO','A')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.employeeMakerPending" (click)="loadVoucher(list.levelMasterId,'E','M')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.employeeChecker1Pending" (click)="loadVoucher(list.levelMasterId,'E','C1')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.employeeChecker2Pending" (click)="loadVoucher(list.levelMasterId,'E','C2')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.employeeApproved" (click)="loadVoucher(list.levelMasterId,'E','A')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.electedRepMakerPending" (click)="loadVoucher(list.levelMasterId,'ER','M')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.electedRepChecker1Pending" (click)="loadVoucher(list.levelMasterId,'ER','C1')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.electedRepChecker2Pending" (click)="loadVoucher(list.levelMasterId,'ER','C2')" styleClass="p-button-link"></p-button></td>
                            <td style="text-align: center;"><p-button [label]="list.electedRepApproved" (click)="loadVoucher(list.levelMasterId,'ER','A')" styleClass="p-button-link"></p-button></td> -->
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </ng-template>
    </p-dialog>
</div>