import { Component, OnInit } from '@angular/core';


import { SearchResult } from 'src/app/shared/models/search-result';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/alert/notification.service';
import { AccountStatementService } from '../account-statement-balances-village/account-statement.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { setThrowInvalidWriteToSignalError } from '@angular/core/primitives/signals';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { LevelMasterDto } from '../level-master/level-master';
import { LevelMasterService } from '../level-master/level-master.service';


@Component({
  selector: 'app-account-statement-village',
  templateUrl: './account-statement-village.component.html',
  styleUrl: './account-statement-village.component.scss'
})
export class AccountStatementVillageComponent implements OnInit {

  searchResult: SearchResult<any> =
    new SearchResult<any>();
  fromDate: any;
  toDate: any;
  downloadfromDate: any;
  downloadtoDate: any;
  maxDate = new Date();
  filterProps: any[];
  itemsPerPage: any = 10;
  page: number;
  cols: any = [];
  accountTableData: any;
  isTable: boolean = false;
  isDownlaodExcel: boolean = false;
  isDownlaodPdf: boolean = false;
  getTable: boolean = false;

  fetchbtnDisable: boolean = false;
  accountBalance: any;
  status: string;
  showStatus: boolean;
  roleCode: any;
  isZonal: boolean;
  villageList: any;
  levelMasId: number;
  villageLevelList: LevelMasterDto[];
  blockMaster: any;
  villagelevelMaster: null;
  districtLevelList: LevelMasterDto[];
  districtMaster: any;
  blockLevelList: LevelMasterDto[];

  constructor(private datePipe: DatePipe,
    private notificationService: NotificationService,
    private accountService: AccountStatementService,
    public localStorageService: LocalStorageService,
    private spinner: NgxSpinnerService,
    public sessionStorage: SessionStorageService,
    private dashboardService: DashboardService,
    public fontService: FontService,
    public levelMasterService: LevelMasterService,
    private router: Router) {

  }
  ngOnInit() {
    // 
    //this.getStatementTable()
    if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
      this.districtMaster = this.sessionStorage.retrieve('levelMaster');
      this.onDistrictLevelChange();
    } else if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
      this.blockMaster = this.sessionStorage.retrieve('levelMaster');
      if (this.sessionStorage.retrieve('roleCode') === 'ZVM') {
        this.dashboardService.getVillagePanchayatByZonal().subscribe(
          (res) => {
            console.log('resZonal', res);
            this.villageList = res;
            this.isZonal = false;
          },
          () => { }
        );
      }
      else {
        this.onBlockLevelChange();
      }

    }
    else if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'VILLAGE') {
      this.checkStatus();
      this.getAccountBalance();
      this.isZonal = true;
    }
    else {
      this.onStateLevelChange();
    }
    this.cols = [
      {
        field: 'postDate',
        header: 'PostDate',
        jhiTranslate: 'tableHead.statement.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'narration',
        header: 'Narration',
        jhiTranslate: 'tableHead.statement.narration',
        isSelectcolumn: true,

      },
      {
        field: 'transactionType',
        header: 'Transaction Type',
        jhiTranslate: 'tableHead.statement.transactionType',
        isSelectcolumn: true,
      },
      {
        field: 'amount',
        cfield: 'transactionType',
        header: 'Credit',
        jhiTranslate: 'tableHead.statement.credit',
        isSortable: true,
        isSelectcolumn: true,
        type: 'crfield'
      },
      {
        field: 'amount',
        cfield: 'transactionType',
        header: 'Debit',
        jhiTranslate: 'tableHead.statement.debit',
        isSortable: true,
        isSelectcolumn: true,
        type: 'dbfield'
      },

      {
        field: 'currentBalance',
        header: 'Current Balance',
        jhiTranslate: 'tableHead.statement.currentBalance',
        isSortable: true,
        isSelectcolumn: true,
        type: 'amount'
      }
    ]
  }

  fetchData() {

    if (!this.fromDate || !this.toDate) {
      this.notificationService.alertError('Please select the From Date and To Date to continue', '');
      return
    } else {
      let fromDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      let toDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
      let data = {
        'fromDate': fromDate,
        'toDate': toDate,
        'levelMasterId':this.levelMasId ? this.levelMasId : this.sessionStorage.retrieve('levelmasterId')
      }
      this.accountService.downloadaccountStatement(data).subscribe(res => {
        console.log(res)

      })

    }

  }
  downloadPdfData() {

    if (!this.downloadfromDate || !this.downloadtoDate) {
      this.notificationService.alertError('Please select the From Date and To Date to continue', '');
      return
    } else {
      let downloadfromDate = this.datePipe.transform(this.downloadfromDate, 'yyyy-MM-dd');
      let downloadtoDate = this.datePipe.transform(this.downloadtoDate, 'yyyy-MM-dd');
      let data = {
        'fromDate': downloadfromDate,
        'toDate': downloadtoDate,
        'levelMasterId':this.levelMasId ? this.levelMasId : this.sessionStorage.retrieve('levelmasterId')
      }
      this.accountService.downloadaccountStatement(data).subscribe(res => {
        console.log(res)

        const blob = new Blob([res.body], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'file.pdf';
        document.body.appendChild(link);
        window.open(url, '_blank');
        link.click();
        URL.revokeObjectURL(url);
        this.hideDialog()
      })

    }

  }

  excelData() {
    if (!this.downloadfromDate || !this.downloadtoDate) {
      this.notificationService.alertError('Please select the From Date and To Date to continue', '');
      return
    } else {
      let downloadfromDate = this.datePipe.transform(this.downloadfromDate, 'yyyy-MM-dd');
      let downloadtoDate = this.datePipe.transform(this.downloadtoDate, 'yyyy-MM-dd');
      let data = {
        'fromDate': downloadfromDate,
        'toDate': downloadtoDate,
        'levelMasterId':this.levelMasId ? this.levelMasId : this.sessionStorage.retrieve('levelmasterId')
      }
      this.accountService.downloadaccountStatementExcel(data).subscribe(res => {
        console.log(res)


        var downloadURL = window.URL.createObjectURL(res);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Statement.xlsx";
        link.click();
        this.hideDialog()

      })
    }
  }
  getStatementTable(event?) {
    this.getTable = true;
    this.loadPage(1);

  }
  clearTable() {
    this.fromDate = null;
    this.toDate = null;
    this.getStatementTable();
  }
  filterData() {
    if (!this.fromDate || !this.toDate) {
      this.notificationService.alertError('Please select the From Date and To Date to continue', '');
      return
    } else {
      let fromDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      let toDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
      this.loadPage(1, fromDate, toDate)
    }
  }

  getAccountStatementData() {
    this.spinner.show();
    this.accountService.getAccountStatement(this.levelMasId ? this.levelMasId : this.sessionStorage.retrieve('levelmasterId')).subscribe(res => {
      this.spinner.hide();
      this.notificationService.alertSuccess('Process to fetch latest statement intiated, Please wait for sometime', '')
      this.router.navigate(['/landing-page'], {});
    }, (err => {
      this.notificationService.alertError(err.err, '')
      this.spinner.hide()
    }))
    this.checkStatus()
  }

  checkStatus() {
    this.accountService.getStatus(this.levelMasId ? this.levelMasId : this.sessionStorage.retrieve('levelmasterId')).subscribe(res => {
      console.log(res)
      if (res.body == null) {
        // this.notificationService.alertSuccess('Process not initiated, Now you can fetch the latest statement','');

      } else {
        if (res.body['status'] == 'Initiated') {
          this.fetchbtnDisable = true;
          this.status = 'Process to fetch latest statement intiated, Please wait for sometime';
          this.showStatus = true

        } else if (res.body['status'] == 'Success') {
          this.notificationService.alertSuccess('Process completed successfully', '');

        } else if (res.body['status'] == 'Failed') {
          this.notificationService.alertError('Process failed, Please try after sometime to fetch lastest statement', '')

        }
      }

    }, (err => {
      if (err.status === 400) {
        this.notificationService.alertError(err.error.message, '');
      }
      console.log(err)
    }))
    this.getStatementTable();
  }
  opendownloadExcel() {
    this.isDownlaodExcel = true;
  }
  opendownloadPdf() {
    this.isDownlaodPdf = true;
  }
  hideDialog() {
    if (this.isDownlaodExcel) {
      this.isDownlaodExcel = false;
      this.downloadfromDate = null;
      this.downloadtoDate = null;
    } else {
      this.isDownlaodPdf = false;
      this.downloadfromDate = null;
      this.downloadtoDate = null;
    }

  }

  onLazyLoad(event) {

    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    if (this.getTable) {
      if (this.fromDate && this.toDate) {
        let fromDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
        let toDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
        this.loadPage(event.page, fromDate, toDate)
      } else {
        this.loadPage(event.page);
      }


    }

  }
  loadPage(page?: number, fromdate?: any, todate?: any): void {
    this.spinner.show()
    const pageToLoad: number = page ?? this.page ?? 1;

    let data = {
      'fromDate': fromdate ? fromdate : null,
      'toDate': todate ? todate : null,
      'size': this.itemsPerPage,
      'page': pageToLoad - 1,
      levelMasterId: this.levelMasId ? this.levelMasId : this.sessionStorage.retrieve('levelmasterId')
    }
    this.accountService.getAccountStatementTable(data).subscribe((res: HttpResponse<any>) => {
      console.log(res)
      this.spinner.hide()
      this.onSuccess(res.body, res.headers, pageToLoad);

      this.isTable = true;


    }, (error => {
      this.spinner.hide()
      this.notificationService.alertError(error.error, '')
    }))
  }


  protected onSuccess(
    data: any | null,
    headers: HttpHeaders,
    page: number,

  ): void {
    console.log(data, headers)
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }
  getAccountBalance() {
    this.spinner.show()
    this.accountService.getAccountBalance(this.levelMasId ? this.levelMasId : this.sessionStorage.retrieve('levelmasterId')).subscribe(res => {
      this.accountBalance = res['body']['AccountEnquiry_Response']['Body']['Payload']['Account_Balance'];
      this.spinner.hide()
    },
      (err => {
        this.spinner.hide()
        this.notificationService.alertError(err.error, '')
      }))
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    }
  }


  onDistrictLevelChange($event?) {
    this.blockMaster = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtMaster.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtMaster.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    }
  }



  onBlockLevelChange($event?) {
    this.levelMasId = null;
    this.villageLevelList = [];
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.blockMaster.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.blockMaster.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    }
  }

  search() {

    if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE') {
      if (this.districtMaster && this.blockMaster && this.levelMasId) {
        this.isZonal = true;
        this.checkStatus();
        this.getAccountBalance();
      }
      else {

        this.notificationService.alertError('Please select a district, block, and village to proceed.', '');
        return

      }
    }
    if (this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
      if (this.blockMaster && this.levelMasId) {
        this.isZonal = true;
        this.checkStatus();
        this.getAccountBalance();
      }
      else {
        this.notificationService.alertError('Please select a block, and village to proceed.', '');
        return

      }
    }
    if (this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
      if (this.levelMasId) {
        this.isZonal = true;
        this.checkStatus();
        this.getAccountBalance();
      }
      else {

        this.notificationService.alertError('Please select a village to proceed.', '');
        return

      }
    }
  }
}


