import { Component } from '@angular/core';
import { ReportService } from 'src/app/report/report.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConsumerDemand, TangedcoBill } from 'src/app/tneb/tangedco-bill-details/tangedco-bill-details';
import { TangedcoBillService } from 'src/app/tneb/tangedco-bill-details/tangedco-bill-details.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { filterQuery } from 'src/app/shared/common.model';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { SessionStorageService } from 'ngx-webstorage';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-tneb-transaction-report',
  templateUrl: './tneb-transaction-report.component.html',
  styleUrl: './tneb-transaction-report.component.scss'
})
export class TnebTransactionReportComponent {
  activeIndex = 0;
  tnebsummary: any;
  tnebsummaryBlock: any;
  tnebsummaryVillage: any;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any = [];
  searchResult: SearchResult<any> =
    new SearchResult<any>();
  villageId: any;
  cols: any;
  enableStatusVoucher: boolean = false;
  cosumerDemandId: any;
  tangedcosearchResult: SearchResult<TangedcoBill> =
    new SearchResult<TangedcoBill>();
  vouchercols: any;
  maxDate = new Date();
  fromDate: Date;
  startDate: string;
  toDate: Date;
  endDate: string;
  dateError: boolean;
  districtId: any;
  blockId: any;
  roleCode: any;
  finYearList: FinYear[];
  finYear: FinYear;
  defaultFinyear: FinYear;
  minDate: Date;

  constructor(
    protected reportService: ReportService,
    public fontService: FontService,
    private tangedcoBillService: TangedcoBillService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private loginService: SigninService,
    public sessionStorageService: SessionStorageService,
    public limitTransListService: LimitTransListService,
    protected notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.roleCode = this.sessionStorageService.retrieve('roleCode');
    this.finYearList = this.sessionStorageService.retrieve('finyear');
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
    this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
    this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
    this.maxDate = new Date();
    this.cols = [
      {
        field: 'districtName',
        header: 'District',
        //jhiTranslate: 'tableHead.voucher.no',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'blockName',
        header: 'Block',
        //jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true
        //type: 'date',
      },
      {
        field: 'villageName',
        header: 'village',
        //jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: true
        //type:'amount'
      },
      {
        field: 'lgdCode',
        header: 'Ldg Code',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'voucherNo',
        header: 'Voucher No',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'utrNo',
        header: 'UTR No',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,

      },
      {
        field: 'receiptNo',
        header: 'Receipt No',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,

      },
      {
        field: 'transactionAmount',
        header: 'Transaction Amount',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
        type: "amount"
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
        type: "date"
      },

    ];
    if (this.roleCode === 'DA' || this.roleCode === 'DM' || this.roleCode === 'DAD' || this.roleCode === 'DS') {
      this.cols.splice(0, 1);
    } else if (this.roleCode === 'BM' || this.roleCode === 'ZVM' || this.roleCode === 'BAD') {
      this.cols.splice(0, 2);
    }
  }



  async loadPage(page?: number, dontNavigate?: boolean): Promise<void> {
    // this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    const payload: any = {


      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    if (this.startDate && this.endDate) {
      payload['fromDate'] = this.startDate;
      payload['toDate'] = this.endDate;
    }



    payload['levelMasterId'] = this.sessionStorageService.retrieve('levelmasterId');
    if (this.roleCode === 'SA' || this.roleCode === 'TNA' || this.roleCode === 'SM') {
      payload['levelType'] = 'STATE';
      payload['levelMasterId'] = 1;
    } else if (this.roleCode === 'DA' || this.roleCode === 'DM' || this.roleCode === 'DAD' || this.roleCode === 'DS') {
      payload['levelType'] = 'DISTRICT';
    }
    else if (this.roleCode === 'ZVM') {
      payload['levelType'] = 'BLOCK';
      payload['zonalName'] = this.sessionStorageService.retrieve('user')?.zonalName;
    }
    else {
      payload['levelType'] = 'BLOCK';
    }

    payload['finYearId'] = this.finYear.id;
    this.reportService.getTangedcoTransaction(payload).subscribe(
      (res) => {
        console.log('res.body', res.body);
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      (err) => {

      }
    )

  }

  pad(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    console.log('searchResult', headers);
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }
  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }



  onSearch() {
    this.activeIndex = 0;
    if (this.fromDate && this.toDate) {
      const date = new Date(this.fromDate);
      this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
      const enddate = new Date(this.toDate);
      this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} ${this.pad(enddate.getHours())}:${this.pad(enddate.getMinutes())}:${this.pad(enddate.getSeconds())}`;
    }
    if (this.startDate > this.endDate) {
      this.dateError = true;
      return
    }
    this.dateError = false;
    this.loadPage(1);
  }



  checkStatus() {
    let filter = {
      reportName: 'Tangedco Transaction Report',

    };
    this.spinner.show();
    this.reportService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body
        this.saveBlobToExcel(b.data, 'Tangedco Transaction Report');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.message, '');
        this.spinner.hide();
      }
    );
  }
  downloadExport() {
    const payload: any = {
      reportName: 'Tangedco Transaction Report',
    };
    if (this.startDate && this.endDate) {
      payload['fromDate'] = this.startDate;
      payload['toDate'] = this.endDate;
    }
    payload['levelMasterId'] = this.sessionStorageService.retrieve('levelmasterId');
    if (this.roleCode === 'SA' || this.roleCode === 'TNA' || this.roleCode === 'SM') {
      payload['levelType'] = 'STATE';
      payload['levelMasterId'] = 1;
    } else if (this.roleCode === 'DA' || this.roleCode === 'DM' || this.roleCode === 'DAD' || this.roleCode === 'DS') {
      payload['levelType'] = 'DISTRICT';
    }
    else if (this.roleCode === 'ZVM') {
      payload['levelType'] = 'BLOCK';
      payload['zonalName'] = this.sessionStorageService.retrieve('user')?.zonalName;
    }
    else {
      payload['levelType'] = 'BLOCK';
    }
    payload['finYearId'] = this.finYear.id;
    this.spinner.show();
    this.reportService.reportAsyncDownload({ ...payload }).subscribe(
      (res) => {
        this.notificationService.alertSuccess(res.body, '');
        this.spinner.hide();
      },
      (onError) => {
        onError.error = JSON.parse(onError.error);
        this.notificationService.alertError(onError.error.message, '');
        this.spinner.hide();
      }
    );
  }



  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }

  getFinyear(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.limitTransListService.getFinYear().subscribe(
        (res: HttpResponse<FinYear[]>) => {

          resolve(this.roleCode)
        },
        () => { }
      )
    });
  }

  parseDate(dateString) {
    const [day, month, year] = dateString.split('/');
    return new Date(`${month}/${day}/${year}`);
  }

  onchangeFinYear() {
    if (this.finYear.name === this.defaultFinyear.name) {
      this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
      this.maxDate = new Date();
    }
    else {
      this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
      this.maxDate = this.parseDate('31/03/' + this.finYear.name.split('-')[1]);
    }
  }


}




