<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="breadcrumb.deduction.name">
                        Deduction
                    </h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="!(roleCode === 'DM' || roleCode === 'BM' || this.roleCode ==='SM' || this.roleCode ==='DA' || this.roleCode ==='SA') && currentPath !== '/deduction-accounts-approve'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button"
                        (click)="openNew()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
          </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <!-- <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
            <div class="row px-4">
                <div class="col">
                    <label class="form-control-label" for="name" [ngClass]="fontService.labelClass" jhiTranslate="payments.viewTable.deductionAccountName">Deduction Account Name</label>
                    <input type="text" class="form-control" name="name" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="vendorName" id="name" />
                </div>
                <div class="col">
                    <label class="form-control-label" for="name" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno">Account No</label>
                    <input type="text" class="form-control" name="name" maxlength="60" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="accountNo" id="name" />
                </div>
                <div *ngIf="roleCode === 'SA'" class="col">
                    <label class="form-control-label" for="name" [ngClass]="fontService.labelClass">Level Type</label>
                    <ng-select id="vendorType" [(ngModel)]="selectedLevelType" [items]="levelType" appearance="outline" [closeOnSelect]="true" (change)="districtDto=null;BlockLevelDto=null;villageLevelDto=null" [clearable]="true" class="form-control">
                    </ng-select>
                </div>
                <div *ngIf="selectedLevelType" class="col">
                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                    <ng-select id="scheme" [(ngModel)]="districtDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
                <div *ngIf="selectedLevelType !== 'District' && selectedLevelType" class="col">
                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.block">Block</label>
                    <ng-select id="scheme" [appendTo]="'body'" [(ngModel)]="BlockLevelDto" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
                <div *ngIf="selectedLevelType !== 'District' && selectedLevelType !== 'Block' && selectedLevelType" class="col">
                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.village">Village</label>
                    <ng-select id="scheme" [appendTo]="'body'" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" [(ngModel)]="villageLevelDto" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
            </div>
        </ng-template>
    </p-toolbar> -->
    <!-- <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
            <div class="row px-4">
                <div class="col" style="display: flex;align-items: flex-end;">
                    <button pButton pRipple class="p-button-success custom-button" (click)="loadPage()" styleClass="button">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.searchbutton">Search</span>
      </button>
                </div>
                <div class="col mt-3" style="display: flex;align-items: flex-end;">
                    <button pButton pRipple class="p-button-danger custom-button" (click)="vendorName = null;accountNo=null;villageLevelDto=null;BlockLevelDto=null;districtDto=null;selectedLevelType=null;this.filterProps=[];loadPage(1)">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.clearbutton">clear</span>
      </button>
                </div>
            </div>
        </ng-template>
    </p-toolbar> -->
    <div #customerTable id="entities" class="col-12 px-4 mt-3">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [enableApproved]="enableApprove" [enableView]="true" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" (emitRow)="onSelectRow($event)"
            [enableVendorApproved]="currentPath === '/deduction-accounts-approve'"></custom-filter-table>
    </div>
    <p-dialog #dialog [(visible)]="deductionAccountDialog" [style]="{ width: '850px' }" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog">
        <p-header *ngIf="!readonly" [ngClass]="fontService.headingClass">
            {{ "vendor.addDeduction" | translate }}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row">
                        <div class="row gy-2">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="vendor.personal.deductiondetails">
                                    Deduction Details
                                </h6>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.name" for=" name">Name</label>
                                <input type="text" id="name" name="name" autocomplete="off" [(ngModel)]="deduction.name" [ngClass]="{ 'is-invalid': ngSelectInvalid && !deduction.name }" class="form-control" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" style="text-transform:uppercase" [readonly]="edit" maxlength="30" uppercase />
                                <div *ngIf="showNameError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{showNameError}}</small>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" for=" name">Remark</label>
                                <textarea type="text" id="name" name="name" autocomplete="off" [(ngModel)]="deduction.remark" class="form-control" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                    style="text-transform:uppercase" [readonly]="edit" maxlength="30" uppercase></textarea>

                            </div>
                            <!-- <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass"
                                    jhiTranslate="vendor.companyName" for=" companyName">Company Name</label>
                                <input type="text" class="form-control" name="companyName"
                                    [(ngModel)]="deduction.companyName" id="companyName"
                                    [ngClass]="{'is-invalid': ngSelectInvalid}" />
                            </div> -->
                            <!-- <div class="col-sm-6 col-md-4">

                                <label for=" designation" [ngClass]="fontService.labelClass" jhiTranslate="vendor.designation" class="form-control-label req">Designation</label>
                                <input type="text" id="designation" autocomplete="off" name="companyName" [(ngModel)]="deduction.designation" [ngClass]="{ 'is-invalid': ngSelectInvalid && !deduction.designation }" class="form-control" [readonly]="isDelete" maxlength="50" />

                            </div> -->
                            <!-- <div class="col-sm-6 col-md-4">
                <label for=" staffId" [ngClass]="fontService.labelClass" jhiTranslate="vendor.staff.id"
                  class="form-control-label req">Staff Id</label>
                <input type="text" id="registrationNo" name="registrationNo" autocomplete="off" [(ngModel)]="deduction.staffId"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !deduction.staffId }" class="form-control"
                  maxlength="30" (ngModelChange)="checkstaffId()" />
                  <p *ngIf="showStaffError" style="font-size: 14px; color: #ff0000">
                    {{showStaffError }}
                  </p>
              </div> -->

                        </div>
                        <div class="row gy-2 mt-3">


                        </div>
                        <!-- <div class="row gy-2 mt-3">
           
            </div> -->
                        <div class="row gy-2 mt-3">







                        </div>
                        <div class="row gy-2 mt-3">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="agency.account.detail.name">
                                    Vendor Account Details
                                </h6>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat' || currentEnvironment==='production')">
                                <div class="col-sm-6 col-md-4">
                                    <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                                    <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" (change)="getBankBranchList()" [items]="bankList" [disabled]="readonly" [(ngModel)]="deduction.bankName"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !deduction.bankName }">
                                    </ng-select>

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat' || currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                                <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="bankBranch" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" (search)="getBankBranchList($event)" [items]="bankBranchList" [disabled]="readonly" [(ngModel)]="bankBranch"
                                    (change)="getBankIfsc()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !deduction.bankBranch}">
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat' || currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                                <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase
                                    [readonly]="readonly" [(ngModel)]="deduction.ifscCode" id="ifsc" [ngClass]="{ 'is-invalid': ngSelectInvalid && !deduction.ifscCode }" maxlength="11" (input)="validateIfsc()" (change)="accountDetailsChanged()" />
                                <div *ngIf="ifscError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                    IFSCode.</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                                <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase
                                    [readonly]="readonly" [(ngModel)]="deduction.ifscCode" id="ifsc" [ngClass]="{ 'is-invalid': ngSelectInvalid && !deduction.ifscCode }" maxlength="11" (input)="validateIfsc()" (change)="accountDetailsChanged()" />
                                <div *ngIf="ifscError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                    IFSCode.</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                                <p [ngClass]="fontService.regularClass" class="mb-0">{{deduction.bankName}}</p>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                                <p [ngClass]="fontService.regularClass" class="mb-0">{{deduction.bankBranch}}</p>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno" for=" accountNo">Account Number</label>
                                <input type="text" class="form-control" name="accountNo" autocomplete="off" maxlength="25" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [readonly]="readonly" [(ngModel)]="deduction.accountNumber" id="accountNo" (change)="accountChanged()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !deduction.accountNumber }" />
                                <div *ngIf="accountNoUniError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.accountNoUniError">{{accountNoUniError}}</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.account.name" for=" bankAccountName">Bank Account Name As per
                  Passbook</label>
                                <input type="text" class="form-control" name="bankAccountName" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [readonly]="true" [(ngModel)]="deduction.bankAccountName" id="bankAccountName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !deduction.bankAccountName }" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.micr" for=" micr">MICR</label>
                                <input type="text" class="form-control" name="micr" autocomplete="off" [readonly]="readonly" [(ngModel)]="deduction.micrCode" maxlength="9" id="micr" />
                            </div>
                            <div class="col-12 justify-content-center">
                                <button pButton pRipple class="p-button-success custom-button" (click)="accountValidation()" styleClass="button" [disabled]="readonly">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.verifyaccount">Verify Account</span>
                </button>
                                <div *ngIf="accountInvalidError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass">{{accountInvalidError}}</small>
                                </div>
                                <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please verify the account</small>
                                </div>
                            </div>

                            <!-- </div>
            <div class="row mt-3"> -->


                        </div>
                        <!-- </div>
            <div class="row mt-3"> -->

                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
      </button>
            <button *ngIf="isDelete" pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="rejectDeduction(deduction)">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.delete"></span>
      </button>
            <button *ngIf="deduction.id && enableApproved" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="approveDeduction(deduction)">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.approvebutton"></span>
    </button>

            <button *ngIf="!deduction.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="savePersonalVendor()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
      </button>
            <button *ngIf="isApprove" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="approveVendor(true)">
        <span class="button-label" [ngClass]="fontService.buttonClass">Approve </span> </button>
            <button *ngIf="deduction.id && !readonly && !isApprove" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="savePersonalVendor()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
      </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog #cd [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <h3 [ngClass]="fontService.headingClass" jhiTranslate="common.confirmation">Confirmation</h3>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.yes"></span>
        </button>
            <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cd.reject()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.no"></span>
        </button>
        </ng-template>
    </p-confirmDialog>
</div>