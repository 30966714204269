<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.payments.elist">Employee Payment List</h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="roleCode === 'DMAKER' || roleCode === 'VMAKER' || roleCode === 'BMAKER'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.generatevoucher"></span>                    
                    </button>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedProfiles()"
                        [disabled]="!selectedProfiles || !selectedProfiles.length"></button> -->
                </ng-template>
                <!-- <ng-template pTemplate="right">
                    <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button>
                </ng-template> -->
            </p-toolbar>
        </div>
    </div>
    <div class="input-row py-10 pb-2">
        <ng-select id="scheme" *ngIf="roleCode === 'DAD'" [(ngModel)]="levelType" [placeholder]="levelType ? '' : 'Level Type'" [items]="levelTypeList" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
        </ng-select>
        <ng-select id="scheme" *ngIf="roleCode === 'DAD'" [(ngModel)]="BlockLevelDto" [placeholder]="BlockLevelDto ? '' : 'Block'" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)"
            [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
        </ng-select>
        <ng-select *ngIf="levelType === 'Village' && roleCode === 'DAD'" id="scheme" [(ngModel)]="villageLevelDto" [placeholder]="villageLevelDto ? '' : 'Village'" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)"
            change="" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
        </ng-select>
        <ng-select id="scheme" [(ngModel)]="finYear" [placeholder]="finYear ? '' : 'Fin Year'" [items]="finYearList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
        </ng-select>
        <input *ngIf="levelTypeName !== 'VILLAGE' && levelTypeName !== 'STATE'" pInputText pKeyFilter="alphanum" placeholder="Enter LGD Code" [(ngModel)]="lgdCode" />
        <button pButton pRipple label="Search" (click)="filterProps = [];loadPage(1)" class="p-button-sm"></button>
        <button pButton pRipple label="Clear" (click)="onFilterClear()" class="p-button-sm"></button>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [enableView]="true" (emitRow)="onSelectRow($event)" [lazyLoadOnInit]="false" [showRedirectButton]="false"
                (showTable)="getSubTableDetails($event)" [showTableButton]="true" [flagButton]="roleCode == 'VMAKER'"></custom-filter-table>
        </div>
    </div>

</div>
<p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="enableStatusVoucher = false">
    <p-header [ngClass]="fontService.headingClass">
        {{ "dashBoard.transactionlist" | translate }}
    </p-header>
    <custom-filter-table [columnsValues]="vouchercols" [filterSearchResult]="searchVoucherStatusResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [paginator]="false" [disableEditIcon]="true"></custom-filter-table>
</p-dialog>
<p-dialog [(visible)]="enableFlagVoucher" [style]="{width: '900px',height:'400px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="enableStatusVoucher = false">
    <!-- <p-header [ngClass]="fontService.headingClass">
        {{ "dashBoard.transactionlist" | translate }}
    </p-header> -->
    <app-flagging-page *ngIf="enableFlagVoucher" [vendorTransaction]="currentVendorTransaction" [personalFlag]="currentVendorTransaction.personalFlag" (saveEmit)="enableFlagVoucher=false;loadPage(1)"></app-flagging-page>
</p-dialog>