import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { SearchResult } from 'src/app/shared/models/search-result';
import { InspectionReportDto } from '../report.model';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { ReportService } from '../report.service';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { Router } from '@angular/router';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';

@Component({
  selector: 'app-fund-recipt-report',
  templateUrl: './fund-recipt-report.component.html',
  styleUrl: './fund-recipt-report.component.scss'
})
export class FundReciptReportComponent {
  levelMasterDialog: boolean = false;
  cols: any[];
  filterProps: any;
  searchResult: SearchResult<InspectionReportDto> = new SearchResult<InspectionReportDto>();
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: number = 10;
  page: number;
  tamilText: any;

  //required fields property
  ngSelectInvalid: boolean = false;
  codeError: string;
  roleCode: any;
  villageLevelList: LevelMasterDto[];
  districtLevelList: LevelMasterDto[];
  blockLevelList: LevelMasterDto[];
  twadOfficedialog: boolean = false;
  blockMaster: any;
  districtMaster: any;
  villagelevelMaster: any;
  finYearList: FinYear[];
  finYear: FinYear;
  defaultFinyear: FinYear;
  minDate: Date;
  maxDate: Date;
  fromDate: Date;
  startDate: string;
  toDate: Date;
  endDate: string;
  dateError: boolean;
  search: any;
  componentType: any;
  compId: any;

  constructor(

    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    public reportService: ReportService,
    private levelMasterService: LevelMasterService,
    private limitTransListService: LimitTransListService,
    protected router: Router,
    public sessionStorage : SessionStorageService,
    public componentTypeService:ComponentTypeService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  async ngOnInit() {
    this.loadCompType();
    if(!this.sessionStorage.retrieve('levelMaster')) {
      await this.getLevelMaster();
    }
    this.finYearList = this.sessionStorage.retrieve('finYear');
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
    this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
    this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
    this.maxDate = new Date();
    if(this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE'){
      this.cols = [
        {
          field: 'districtName',
          header: 'District Name',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'blockName',
          header: 'Block Name',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'villageName',
          header: 'Village Name',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'lgdCode',
          header: 'Lgd Code',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'compName',
          header: 'Component Name',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'fundSource',
          header: 'Fund Source',
          isFilterable: false,
          isSelectcolumn: true,
        },
        
        {
          field: 'transactionDate',
          header: 'Transaction Date',
          jhiTranslate: 'tableHead.transaction.date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        },
       
        {
          field: 'amount',
          header: 'Transaction Amount',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        
       
       
      ];
    }
    else if(this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
      this.cols = [
        {
          field: 'blockName',
          header: 'Block Name',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'villageName',
          header: 'Village Name',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'lgdCode',
          header: 'Lgd Code',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'compName',
          header: 'Component Name',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'fundSource',
          header: 'Fund Source',
          isFilterable: false,
          isSelectcolumn: true,
        },
        
        {
          field: 'transactionDate',
          header: 'Transaction Date',
          jhiTranslate: 'tableHead.transaction.date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        },
       
        {
          field: 'amount',
          header: 'Transaction Amount',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        
       
       
      ];
    }
    else if(this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
      this.cols = [
        {
          field: 'villageName',
          header: 'Village Name',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'lgdCode',
          header: 'Lgd Code',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'compName',
          header: 'Component Name',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'fundSource',
          header: 'Fund Source',
          isFilterable: false,
          isSelectcolumn: true,
        },
        
        {
          field: 'transactionDate',
          header: 'Transaction Date',
          jhiTranslate: 'tableHead.transaction.date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        },
       
        {
          field: 'amount',
          header: 'Transaction Amount',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        
       
       
      ];
    }
    else  {
      this.cols = [
        {
          field: 'lgdCode',
          header: 'Lgd Code',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'compName',
          header: 'Component Name',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'fundSource',
          header: 'Fund Source',
          isFilterable: false,
          isSelectcolumn: true,
        },
        
        {
          field: 'transactionDate',
          header: 'Transaction Date',
          jhiTranslate: 'tableHead.transaction.date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        },
       
        {
          field: 'amount',
          header: 'Transaction Amount',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        
       
       
      ];
    }
    
  }


  ngAfterViewInit() {
    this.updateDialogFont();

  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }


  loadPage(page?: number, dontNavigate?: boolean): void {
    if(!this.compId) {
      this.notificationService.alertError('Please Select Component','');
      return;
    }
    this.search = true;
    const pageToLoad: number = page ?? this.page ?? 1;

    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      levelType: this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase(),
      finYearId: this.finYear.id
    };
    if (this.fromDate && this.toDate) {
      const date = new Date(this.fromDate);
      this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
      const enddate = new Date(this.toDate);
      this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} 23:59:59.999`;
      if (this.startDate > this.endDate) {
        this.dateError = true;
        return
      }
      this.dateError = false;
      filter['fromDate'] = this.startDate;
      filter['toDate'] = this.endDate;
    }
    if(this.compId) {
      filter['compId'] = this.compId;
    }
    if(this.sessionStorage.retrieve('roleCode') === 'ZVM') {
      filter['zonalName'] =  this.sessionStorage.retrieve('user')?.zonalName;
    }
      this.spinner.show();
      this.reportService
      .getFundReceiptReportView({ ...filter })
      .subscribe(
        (res: HttpResponse<any>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.spinner.hide();
          this.onError();
        }
      );
    
   
  }

  pad(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }

  protected onSuccess(
    data: InspectionReportDto[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    data.forEach(element => {
      switch (element.paymentStatus) {
        case null:
          element.paymentStatus = 'Not Initiated';
          break;
        case 'O.K.':
          element.paymentStatus = 'Success';
          break;
        case 'failed':
          element.paymentStatus = 'Failed';
          break;
        case 'processing':
          element.paymentStatus = 'Processing';
          break;
        default:
          break;
      }
      if(element.isCancelled) {
        element.transactionStatus = 'Failed and Returned';
      }
    
    });
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }





  onLazyLoad(event) {
    this.filterProps = [];
    
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  getFinyear(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.limitTransListService.getFinYear().subscribe(
        (res: HttpResponse<FinYear[]>) => {
         
          resolve(this.roleCode)
        },
        () => { }
      )
    });
  }

  parseDate(dateString) {
    const [day, month, year] = dateString.split('/');
    return new Date(`${month}/${day}/${year}`);
  }

  onchangeFinYear() {
    if (this.finYear.name === this.defaultFinyear.name) {
      this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
      this.maxDate = new Date();
    }
    else {
      this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
      this.maxDate = this.parseDate('31/03/' + this.finYear.name.split('-')[1]);
    }
  }

  getLevelMaster(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.levelMasterService.getLevelMaster(this.sessionStorage.retrieve('levelmasterId')).subscribe(
        (res) => {
          this.sessionStorage.store('levelMaster',res.body);
          resolve('');
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }
  checkStatus() {
    let filter = {
      reportName: 'Fund Receipt Report',
    };
    this.spinner.show();
    this.reportService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body
        this.saveBlobToExcel(b.data, 'Fund Receipt Report');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.message, '');
        this.spinner.hide();
      }
    );
  }
  downloadExport() {
    if(!this.compId) {
      this.notificationService.alertError('Please Select Component','');
      return;
    }
    let filter = {
      levelType: this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase(),
      finYearId: this.finYear.id,
      reportName: 'Fund Receipt Report',
    };

    if(this.compId) {
      filter['compId'] = this.compId;
    }

    if(this.sessionStorage.retrieve('roleCode') === 'ZVM') {
      filter['zonalName'] =  this.sessionStorage.retrieve('user')?.zonalName;
    }
   
    if (this.fromDate && this.toDate) {
      const date = new Date(this.fromDate);
      this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
      const enddate = new Date(this.toDate);
      this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} 23:59:59.999`;
      if (this.startDate > this.endDate) {
        this.dateError = true;
        return
      }
      this.dateError = false;
      filter['fromDate'] = this.startDate;
      filter['toDate'] = this.endDate;
    }
   
   
   
      this.spinner.show();
      this.reportService.reportAsyncDownload({ ...filter }).subscribe(
        (res) => {
          this.notificationService.alertSuccess(res.body, '');
          this.spinner.hide();
        },
        (onError) => {
          onError.error = JSON.parse(onError.error);
          this.notificationService.alertError(onError.error.message,'');
          this.spinner.hide();
        }
      );
    
   
  }

  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }



  loadCompType(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.componentTypeService.find(4).subscribe(
        (res: HttpResponse<any>) => {
         this.componentType = res.body.filter(item=>item.componentCode === 'A' || item.componentCode === 'B' || item.componentCode === 'C' || item.componentCode === 'D');
         resolve(this.componentType);
        },
        () => {resolve(null); }
      )
    });
  }

  
}
