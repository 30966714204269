<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Monthly Cash Book Report
                    </h5>
                </ng-template>

            </p-toolbar>
        </div>
    </div>
    <div class="row px-4">
        <div class="col-3">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Financial Year</label>
            <ng-select id="scheme" [(ngModel)]="finYear" [items]="finYearList" bindLabel="name" appearance="outline" (change)="onchangeFinYear()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE'" class="col-3">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">District</label>
            <ng-select id="scheme" [(ngModel)]="districtMaster" [items]="districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE' || sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT'" class="col-3">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Block</label>
            <ng-select id="scheme" [(ngModel)]="blockMaster" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE' && sessionStorage.retrieve('roleCode') !== 'ZVM'" class="col-3">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="scheme" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" [(ngModel)]="villagelevelMaster" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE' && sessionStorage.retrieve('roleCode') === 'ZVM'" class="col-lg-2 col-md-3 col-sm-3">
            <label class="form-control-label req" for="Village" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="gender" bindLabel="levelMasterName" bindValue="levelMasterId" [items]="villageList" appearance="outline" [(ngModel)]="levelMasId" [closeOnSelect]="true" [clearable]="false" class="form-control">
            </ng-select>
        </div>
    </div>
    <div class="row px-4 mt-2">
        <div class="col-3">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Select Component</label>
            <ng-select id="scheme" [(ngModel)]="compId" [items]="componentType" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-3">
            <label class="form-control-label req" for="month" [ngClass]="fontService.labelClass">Month</label>
            <ng-select id="month" [items]="months" appearance="outline" [(ngModel)]="month" [closeOnSelect]="true" (change)="calculateDates()" [clearable]="false" class="form-control">
            </ng-select>
        </div>
        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-success custom-button" (click)="loadPage('YEAR')" styleClass="button">
  <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.searchbutton">Search</span>
</button>
        </div>
        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-danger custom-button">
  <span class="button-label" [ngClass]="fontService.buttonClass" (click)="this.search = false;districtMaster = null; blockMaster = null; villagelevelMaster = null; finYear = defaultFinyear; fromDate = null;toDate = null;month=null;monthfinancialData=null;financialData=null;compId=null" jhiTranslate="common.clearbutton">clear</span>
</button>
        </div>
    </div>

    <div *ngIf="search" class="row mt-2">
        <div class="col-12">
            <p-table [value]="statement" class="p-treetable-gridlines">
                <ng-template pTemplate="header">
                    <tr>
                        <th colspan="12">
                            <div style="text-align:center" class="text-xl font-bold">
                                <h3>Monthly Cash Book Report</h3>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th colspan="12">
                            <div class="text-xl font-bold row">
                                <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() === 'STATE'" class="col-4">
                                    <strong>District:</strong> {{ districtMaster?.name }}
                                </div>
                                <div *ngIf="['STATE', 'DISTRICT'].includes(sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase())" class="col-4">
                                    <strong>Block:</strong> {{ blockMaster?.name }}
                                </div>
                                <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE'" class="col-4">
                                    <strong>Village:</strong> {{ villagelevelMaster?.name }}
                                </div>
                            </div>

                        </th>
                    </tr>
                    <tr>
                        <th colspan="12">
                            <div style="text-align:center" class="text-xl font-bold">
                                <h3>Component: {{compId?.name}}</h3>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th colspan="12">
                            <div class="text-xl font-bold row">
                                <div class="col-3">
                                    <strong>Financial Year:</strong> {{ finYear?.name }}
                                </div>
                                <div class="col-3">
                                    <strong>Month:</strong> {{ month }}
                                </div>
                                <div class="col-2">
                                    <strong>Opening Balance {{getPreviousMonthLastDate()}}</strong>
                                </div>
                                <div class="col-3">
                                    <strong>{{openingBalance | currency:'INR':'symbol':'1.2-2':'en-IN'}}</strong>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th colspan="4" style="text-align: center;">Receipt</th>
                        <th colspan="5" style="text-align: center;">Expenditure</th>
                    </tr>
                    <tr>
                        <th>Sl.No</th>
                        <th>Date</th>
                        <th>Fund Source</th>
                        <th>Amount</th>
                        <th>Voucher no</th>
                        <th>Sub Component</th>
                        <th>Description (Name of the Work)</th>
                        <th>Amount</th>
                        <th>Balance</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-recipt let-index="rowIndex">
                    <tr>
                        <td>{{ index + 1 }}</td>
                        <td>{{ recipt.date | date:'dd/MM/yyyy'}}</td>
                        <td>{{ recipt.foundSource}}</td>
                        <td>{{ recipt.sanctionAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                        <td>{{ recipt.voucherNumber}}</td>
                        <td>{{ recipt.subComponentName}}</td>
                        <td>{{ recipt.discription}}</td>
                        <td>{{ recipt.utilizedAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                        <td>{{recipt.balance | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="8" style="text-align: right;">Closing Balance</td>
                        <td>{{ closingBalance | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>

                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>