import { TwadMasterService } from './../twad-master-list/twad.service';
import { BillingDetails, twadOffice, TwadSchemeMaster } from './../twad-master-list/twad.model';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { SearchResult } from "src/app/shared/models/search-result";
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { Router } from '@angular/router';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { filterQuery } from 'src/app/shared/common.model';
import { ReportService } from 'src/app/report/report.service';
import { saveAs } from 'file-saver';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';

@Component({
  selector: 'app-twad-office-master',
  templateUrl: './twad-office-master.component.html',
  styleUrl: './twad-office-master.component.scss'
})
export class TwadOfficeMasterComponent {
  @ViewChild('dialog') dialog: Dialog;
  levelMasterDialog: boolean = false;
  cols: any[];
  filterProps: any;
  searchResult: SearchResult<TwadSchemeMaster> = new SearchResult<TwadSchemeMaster>();
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: number = 10;
  page: number;
  tamilText: any;

  //required fields property
  ngSelectInvalid: boolean = false;
  codeError: string;
  roleCode: any;
  villageLevelList: LevelMasterDto[];
  districtLevelList: LevelMasterDto[];
  blockLevelList: LevelMasterDto[];
  twadOfficeMaster:TwadSchemeMaster;
  twadList: twadOffice[];
  twadOfficedialog:boolean = false;

  constructor(
 
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private loginService: SigninService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    protected twadMasterService:TwadMasterService,
    public reportService:ReportService,
    private levelMasterService: LevelMasterService,
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.onStateLevelChange();
    this.cols = [
      {
        field: 'districtMaster',
        header: 'District',
        isFilterable: true,
        width: 5,
        isSelectcolumn: true,
        type:'dropDown'
      },
      {
        field: 'blockMaster',
        header: 'Block',
        isFilterable: true,
        width: 5,
        isSelectcolumn: true,
        type:'dropDown'
      },
      {
        field: 'levelMaster',
        header: 'Village',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type:'dropDown'
      },
      {
        field: 'lgdCode',
        header: 'Village Lgd Code',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      {
        field: 'officeId',
        header: 'Office Code',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      {
        field: 'beneficiaryCode',
        header: 'Beneficiary Code',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      
      {
        field: 'twadOffice',
        header: 'Office Name',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type:'dropDown_with_other_field',
        Dfield: 'twadOfficeName',
      },
      
     
     
      
     

      
    ];
    
  }


  ngAfterViewInit() {
    this.updateDialogFont();
    
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }


  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    let query = new filterQuery();
    if(this.roleCode.role.code!== 'SA' && this.roleCode.role.code !== 'TA' && this.roleCode.role.code !== 'TM') {
      query = new filterQuery();
      query.key = 'levelMaster.id';
      query.operation = 'equals';
      query.value = this.roleCode.levelMasterId;
      this.filterProps.push(query);
    }
    this.twadMasterService
      .getTwadSchemeMaster({ ...filter }, this.filterProps)
      .subscribe(
        (res: HttpResponse<TwadSchemeMaster[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: TwadSchemeMaster[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  

 

  async onLazyLoad(event) {
    this.filterProps = [];
    if(!this.roleCode) {
      await this.getRole();
    }
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode);
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }
  checkStatus(){
    let filter = {
      reportName:'Twad Office Master',
    };
    this.spinner.show();
    this.reportService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res',res);
        let b:any = res.body
        this.saveBlobToExcel(b.data,'Twad Office Master');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.message,'');
        this.spinner.hide();
      }
    );
  }
  downloadExport(){
    let filter = {
      reportName:'Twad Office Master',
    };
    this.spinner.show();
    this.reportService.reportAsyncDownload({ ...filter }).subscribe(
      (res) => {
        this.notificationService.alertSuccess(res.body,'');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.message,'');
        this.spinner.hide();
      }
    );
  }

  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
    }

    onStateLevelChange($event?) {
      if ($event) {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: 1,
            },
            {
              key: 'name',
              operation: 'contains',
              value: $event.term.toUpperCase(),
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.districtLevelList = res.body;
            },
            () => {}
          );
      } else {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: 1,
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.districtLevelList = res.body;
            },
            () => {}
          );
      }
    }
  
   
    onDistrictLevelChange($event?) {
      this.twadOfficeMaster.blockMaster = null;
      if ($event) {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.twadOfficeMaster.districtMaster.id,
            },
            {
              key: 'name',
              operation: 'contains',
              value: $event.term.toUpperCase(),
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.blockLevelList = res.body;
            },
            () => {}
          );
      } else {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.twadOfficeMaster.districtMaster.id,
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.blockLevelList = res.body;
            },
            () => {}
          );
      }
    }
  
  
  
    onBlockLevelChange($event?) {
     this.twadOfficeMaster.levelMaster = null;
     this.villageLevelList = [];
      if ($event) {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.twadOfficeMaster.blockMaster.id,
            },
            {
              key: 'name',
              operation: 'contains',
              value: $event.term.toUpperCase(),
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.villageLevelList = res.body;
            },
            () => {}
          );
      } else {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.twadOfficeMaster.blockMaster.id,
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.villageLevelList = res.body;
            },
            () => {}
          );
      }
    }
    twadOfficeList($event?) {
      this.twadOfficeMaster.lgdCode = this.twadOfficeMaster.levelMaster.code;
       if ($event) {
         let filterQuery = {
           page: 0,
           size: 50,
         };
         this.twadMasterService
           .getTwadOfficeFilter({ ...filterQuery }, [
           
             {
               key: 'twadOfficeName',
               operation: 'contains',
               value: $event.term.toUpperCase(),
             },
           ])
           .subscribe(
             (res: HttpResponse<twadOffice[]>) => {
               this.twadList = res.body;
             },
             () => {}
           );
       } else {
         let filterQuery = {
           page: 0,
           size: 50,
         };
         this.twadMasterService
           .getTwadOfficeFilter({ ...filterQuery }, [
            
           ])
           .subscribe(
            (res: HttpResponse<twadOffice[]>) => {
              this.twadList = res.body;
            },
            () => {}
           );
       }
     }

     hideDialog() {
      this.twadOfficedialog = false;
      this.ngSelectInvalid = false;
      this.twadOfficeMaster = new TwadSchemeMaster();
    }
  
    openNew(){
      this.twadOfficedialog = true;
      this.twadOfficeMaster = new TwadSchemeMaster();
      
    }
  
    saveTwadOffice(){
      this.spinner.show();
      const isFormValid =
        this.twadOfficeMaster.blockMaster &&
        this.twadOfficeMaster.districtMaster &&
        this.twadOfficeMaster.levelMaster &&
        this.twadOfficeMaster.twadOffice &&
        this.twadOfficeMaster.beneficiaryCode
      if (!isFormValid) {
        this.ngSelectInvalid = true;
        return;
      }
      this.ngSelectInvalid = false;
      this.twadMasterService.saveTwadSchemeMaster(this.twadOfficeMaster).subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.twadOfficedialog = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (err) => {
          this.spinner.hide();
          this.onError();
          if (err.error.status === 400) {
            this.notificationService.alertError('Error! ' + err.error.title, '');
          } else {
            this.notificationService.alertError('Unexpected Error!', '');
          }
        }
      );
    }
}
