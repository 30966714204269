<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Twad Payment Report</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <button pButton pRipple label="VP Wise TWAD" (click)="downloadExport()" class="p-button-success p-button-sm export-button"></button>
        <button pButton pRipple label="Check Status" (click)="checkStatus()" class="p-button-success p-button-sm"></button>
        <button pButton pRipple label="Export to Excel" (click)="export()" class="p-button-success p-button-sm"></button>
    </div>
    <div class="row">
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
            <p-tabPanel header="District" *ngIf="levelType === 'STATE'">
                <div class="col-12 px-4" *ngIf="twadSummary">
                    <p-table id="district-table" [value]="twadSummary" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Block Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Village Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Total Due Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Paid Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Balance Amount</th>

                            </tr>


                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadBlock(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.blockCount}}</td>
                                <td style="text-align: center;">{{list.villageCount}}</td>
                                <td style="text-align: center;">{{list.totalDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.amountPaid | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.balanceDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                            </tr>
                        </ng-template>

                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Block" *ngIf="levelType === 'STATE' || levelType === 'DISTRICT'">
                <div *ngIf="!twadSummaryBlock">Choose distict to get block report</div>
                <div *ngIf="twadSummaryBlock" class="col-12 px-4">
                    <p-table id="block-table" [value]="twadSummaryBlock" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Block Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Village Count</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Total Due Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Paid Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Balance Amount</th>

                            </tr>


                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadVillage(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.villageCount}}</td>
                                <td style="text-align: center;">{{list.totalDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.amountPaid | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.balanceDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Village">
                <div *ngIf="!twadSummaryVillage">Choose block to get village report</div>
                <div *ngIf="twadSummaryVillage" class="col-12 px-4">
                    <p-table id="village-table" [value]="twadSummaryVillage" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Village Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Total Due Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Paid Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Balance Amount</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">{{list.levelMasterName}}</td>
                                <td style="text-align: center;">{{list.totalDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.amountPaid | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.balanceDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>