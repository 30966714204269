<div *ngIf="!isMaintenance" class="row bg">
    <div class="col-sm-6 col-md-6 login-form ">
        <div class="font-login mb-2">TAMILNADU Panchayat simplified accounting system TNPASS</div>
        <div class="font-single mb-4">Login </div>
        <div class="login-content" *ngIf="!showOtp">
            <form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm">
                <div *ngIf="!showLogout" class="form-group mb-4">
                    <input id="username" type="text" id="orangeForm-email" maxlength="55" class="form-control full" formControlName="username" uppercase name="username" autocomplete="off" placeholder="USERNAME">
                </div>

                <div *ngIf="!showLogout" class="form-group mb-4 eye-container">
                    <input id="password" name="password" autocomplete="off" maxlength="16" formControlName="password" id="orangeForm-pass" class="form-control full" placeholder="PASSWORD" [type]="hide ? 'password' : 'text'">
                    <i class="pi pi-eye eye" *ngIf="hide" (click)="hide=false"></i>
                    <i class="pi pi-eye-slash eye" *ngIf="!hide" (click)="hide=true"></i>
                </div>
                <div *ngIf="inValidUser" class="mb-4">
                    <small class="text-danger">Please enter valid username and password</small>
                </div>
                <div *ngIf="inValidRequestError" class="mb-4">
                    <div style="font-size: 15px;color: red;">{{inValidRequestError}}</div>
                </div>
                <div *ngIf="!showLogout" class="row  form-group mb-4">
                    <div class="col-6 text-center" *ngIf="spinner"> <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></div>
                    <div class="col-6" *ngIf="!spinner"><img class="img" [src]='image' /></div>
                    <div class="col-1 refresh">
                        <i class="pi pi-refresh" style="font-size: 1.2rem" (click)="loadCaptcha()"></i>
                    </div>
                    <div class="col-5"><input id="captcha" type="text" id="captcha" maxlength="6" class="form-control full" formControlName="captcha" oninput="this.value = this.value.replace(/[^0-9A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                            placeholder="ENTER CAPTCHA">

                    </div>
                    <div *ngIf="inValidCaptcha" class="text-end mt-2">
                        <small class="text-danger">Incorrect Captcha.Please try again..</small>
                    </div>
                    <div *ngIf="captchaExpireError" class="text-end mt-2">
                        <small class="text-danger">{{captchaExpireError}}</small>
                    </div>
                </div>


                <div class="mb-4">
                    <div class="col-6 text-center" *ngIf="btnSpinner && !showLogout"> <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></div>
                    <button *ngIf="!btnSpinner && !showLogout" type="submit" class="btn btn-secondary">Sign In</button>
                    <button *ngIf="!btnSpinner && showLogout" (click)="forceLogout()" class="btn btn-secondary">Log Out</button>
                </div>
                <div class="footer-logo">
                    <button type="button" *ngIf="!showLogout" (click)="navToForgotPassword()" class="btn btn-link btn-sm">Forgot Password?</button>
                    <img class="ib-logo" src="/./assets/img/logo 1.svg" alt="Image">
                </div>
            </form>
        </div>
        <div class="login-content" *ngIf="showOtp">
            <form class="form" role="form" (ngSubmit)="loginOtp()" [formGroup]="otpForm">
                <div class="form-group mb-2">
                    <div class="mt-2 mb-2" style="background-color: lightgreen;padding: 1px;">
                        OTP has been sent to the Mobile Number: {{mobileNo}}
                    </div>
                    <input id="otp" name="otp" maxlength="6" autocomplete="off" formControlName="otp" type="text" class="form-control full" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" placeholder="ENTER OTP">
                    <div *ngIf="otpError" class="mt-2">
                        <small style="font-size: 30px;" class="text-danger">{{otpError}}</small>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <button type="submit" class="btn btn-secondary">Sign In</button>
                    </div>
                    <div class="col text-end">
                        <button type="button" [disabled]="!enableResendButton" (click)="getUserOtp('reSend')" class="btn btn-secondary">Resend OTP</button>
                    </div>
                </div>
                <div class="row">
                    <div class="footer-logo mt-2 col-6">
                        <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm">Back to Login</button>
                    </div>
                    <div class="mt-2 col text-end">
                        You can get a new OTP after {{ otpTimer }} Seconds
                    </div>
                </div>

            </form>
        </div>
    </div>
    <div class="col-sm-6 col-md-6 login-caption">
        <div class="row">
            <div class="col-6 g-0 image">
                <img class="image" src="/./assets/img/1 1.png" alt="Image">

            </div>
            <div class="col-6 gy-0 graphic-wrapper">
                <img class="graphic" src="/./assets/img/4343973 1.svg" alt="Image">
            </div>
        </div>
        <div class="row gx-0 align-items-center caption">
            <div class="col-2" style="display:table">
                <img class="tn-logo" src="/./assets/img/ib-rdp-tng-logo.svg" alt="Image">
            </div>
            <div class="col-10 ">
                <p class="tn-font">ஊரக வளர்ச்சி மற்றும் ஊராட்சித் துறை</p>
                <p>Department of Rural Development and Panchayat Raj</p>
            </div>
        </div>
    </div>
</div>

<p-confirmDialog #cd [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h3 [ngClass]="fontService.headingClass" jhiTranslate="common.confirmation">Confirmation</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
      <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.yes"></span>
  </button>
        <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cd.reject()" styleClass="button">
      <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.no"></span>
  </button>
    </ng-template>
</p-confirmDialog>

<!-- <div *ngIf="isMaintenance" class="row bg">
    <h1 style="color: red; font-size: 70px;">Application is currently under maintenance</h1>
</div> -->