import { CommonService } from './../../shared/common.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProfileService } from './profie.service';
import { AddProfile, Profile } from './profile';
import { SearchResult } from 'src/app/shared/models/search-result';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import {
  educationalQualification,
  gender,
  maritalStatus,
  categoryReservation,
  community,
  filterQuery,
} from 'src/app/shared/common.model';
import { NgxSpinnerService } from 'ngx-spinner';

import { Dialog } from 'primeng/dialog';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
// import { saveAs } from 'file-saver';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;

  profileDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Profile> = new SearchResult<Profile>();
  profiles?: Profile[];
  profile?: AddProfile;
  selectedProfiles: Profile[] = [];
  submitted?: boolean;
  filterProps: any;
  dateOfAssumingOffice: Date;
  profileDetails: any = {};
  page: number;
  itemsPerPage: number = 10;
  genderList: gender[];
  educationList: educationalQualification[];
  maritalList: maritalStatus[];
  categoryReservationList: categoryReservation[];
  community: community[];
  name: any;
  otp: number;
  Profiledetails: any;
  enableFeild: boolean;

  // validation fields properties
  resendotp = false;
  sendotp: boolean = false;
  otpOpen = false;
  public aadhaarError: boolean = false;
  public otpError: boolean = false;
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public showBMobile = false;
  today: Date;
  inCharge: boolean = false;
  readonly: boolean = true;
  //required fields property
  ngSelectInvalid: boolean = false;
  aadharNoNameError: string;
  panNoError: string;
  mobileNoError: string;
  emailIdError: string;
  txnNo: any;
  rolecode: any;
  otpButtonName: string = "Send OTP";
  enableAddNewButton: boolean = false;
  showInactive: boolean;
  disableEdit: boolean = true;
  levelType: string = 'Village';
  adminUserName: string;
  levelTypeId: number = 4;
  isSearch: boolean;
  lgdCode: string;

  constructor(
    private ProfileService: ProfileService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    protected router: Router,
    private CommonService: CommonService,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private sessionStorage: SessionStorageService,
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {

    this.rolecode = this.sessionStorage.retrieve('user')?.role?.code;
    if (this.sessionStorage.retrieve('user')?.profileStatus == 'No') {
      this.enableAddNewButton = true;
    }
    if (this.rolecode === 'SA' || this.rolecode === 'SUA') {
      this.showInactive = true;
      this.disableEdit = false;
    }
    if (this.rolecode === 'VMAKER' || this.rolecode === 'VC1' || this.rolecode === 'VC2' || this.rolecode === 'DA') {
      this.readonly = false;
    }
    if (this.rolecode === 'DA') {
      this.showInactive = true;
      this.disableEdit = false;
      this.cols = [
        {
          field: 'name',
          header: 'Name',
          isSortable: false,
          isFilterable: false,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.name',
        },
        {
          field: 'dateOfBirth',
          header: 'Date Of Birth',
          isSortable: false,
          isSelectcolumn: false,
          type: 'date',
          jhiTranslate: 'tableHead.dateOfBirth',
        },
        {
          field: 'panNo',
          header: 'Pan No',
          jhiTranslate: 'tableHead.panno',
          isSortable: false,
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'aadhaarNo',
          header: 'Aadhaar',
          jhiTranslate: 'tableHead.aadhaarno',
          isSortable: false,
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'termValidUpTo',
          header: 'Term Valid Upto',
          jhiTranslate: 'tableHead.termValidUpTo',
          isSortable: false,
          isSelectcolumn: true,
          type: 'date',
        },
        {
          field: 'adminUser',
          header: 'Admin User Name',
          jhiTranslate: 'tableHead.adminUserName',
          isSortable: false,
          isFilterable: false,
          isSelectcolumn: true,
          type: "dropDown_with_other_field",
          Dfield: "userName"
        }
      ];

    } else {
      this.cols = [
        {
          field: 'name',
          header: 'Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.name',
        },
        {
          field: 'dateOfBirth',
          header: 'Date Of Birth',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
          jhiTranslate: 'tableHead.dateOfBirth',
        },
        {
          field: 'panNo',
          header: 'Pan No',
          jhiTranslate: 'tableHead.panno',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'aadhaarNo',
          header: 'Aadhaar',
          jhiTranslate: 'tableHead.aadhaarno',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'termValidUpTo',
          header: 'Term Valid Upto',
          jhiTranslate: 'tableHead.termValidUpTo',
          isSortable: false,
          isSelectcolumn: true,
          type: 'date',
        },
        {
          field: 'adminUser',
          header: 'Admin User Name',
          jhiTranslate: 'tableHead.adminUserName',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          type: "dropDown_with_other_field",
          Dfield: "userName"
        }
      ];
    }
    this.today = new Date();
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  levelTypeChange() {
    if (this.levelType == 'Block') {
      this.levelTypeId = 3;
    } else if (this.levelType == 'Village') {
      this.levelTypeId = 4;
    }
    this.loadPage(1);
  }

  loadValue() {
    this.CommonService.getGenderList().subscribe(
      (res: HttpResponse<gender[]>) => {
        this.genderList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.geteducationList().subscribe(
      (res: HttpResponse<educationalQualification[]>) => {
        this.educationList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.getMartialStatusList().subscribe(
      (res: HttpResponse<maritalStatus[]>) => {
        this.maritalList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.getCategoryReservationList().subscribe(
      (res: HttpResponse<categoryReservation[]>) => {
        this.categoryReservationList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.getcommunityList().subscribe(
      (res: HttpResponse<community[]>) => {
        this.community = res.body;
      },
      () => {
        this.onError();
      }
    );
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterProps = this.filterProps ? this.filterProps : [];

    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);

    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      levelId: this.levelTypeId
    };
    if ((this.rolecode === 'VMAKER' || this.rolecode === 'VC1' || this.rolecode === 'VC2') && this.sessionStorage.retrieve('user')?.profileStatus == 'Yes') {
      this.ProfileService.getAllProfilesByVillage().subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => { this.onError(); });
    }
    else if (this.isSearch && this.adminUserName) {
      query['search'] = this.adminUserName;
      if (this.levelTypeId === 4 && this.lgdCode) {
        query['lgdCode'] = this.lgdCode;
      }
      this.ProfileService.getProfilesByLevel({ ...query }).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => { this.onError(); });
    } else if (this.rolecode === 'DA' && this.sessionStorage.retrieve('user')?.profileStatus == 'Yes') {
      this.ProfileService.getProfilesByLevel({ ...query }).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => { this.onError(); });
    }
    else if (this.rolecode === 'DA' && this.sessionStorage.retrieve('user')?.profileStatus == 'No') {
      this.ProfileService.filter({ ...query }, this.filterProps).subscribe(
        (res: HttpResponse<Profile[]>) => {
          if (res.body.length !== 0) {
            this.enableAddNewButton = false;
          }
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    }
    else if (this.rolecode !== 'SA' && this.rolecode !== 'SUA' && this.sessionStorage.retrieve('user')?.profileStatus == 'No') {
      this.ProfileService.filter({ ...query }, this.filterProps).subscribe(
        (res: HttpResponse<Profile[]>) => {
          if (res.body.length !== 0) {
            this.enableAddNewButton = false;
          }
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    } else {
      let filquery = new filterQuery();
      filquery.key = 'isApproved';
      filquery.operation = 'equals';
      filquery.value = true;
      this.filterProps.push(filquery);

      this.ProfileService.vaildFilter({ ...query }, this.filterProps).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    }

  }

  protected onSuccess(
    data: Profile[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
    this.notificationService.alertError("An unexpected error occured,please try again", '');
    this.spinner.hide();
    // this.enableFeild = false
  }

  async aadhaarNocheck() {
    // this.validateAadhaar()
    const filterData = await this.getfilterdata(
      'aadhaarNo',
      this.profile.aadhaarNo
    );
    if (filterData.length !== 0) {
      this.aadharNoNameError = 'Aadhaar No Already Exists';
    } else {
      this.aadharNoNameError = null;
    }
  }
  isIncharge() {
    if (this.inCharge) {
      this.aadharNoNameError = null;
    }
    else {
      if (this.profile.aadhaarNo) {
        this.aadhaarNocheck();
      }
    }
  }

  async panNoCheck() {
    const filterData = await this.getfilterdata('panNo', this.profile.panNo);
    if (filterData.length !== 0) {
      this.panNoError = 'PanNo Already Exists';
    } else {
      this.panNoError = null;
    }
  }

  async mobileNo() {
    const filterData = await this.getfilterdata(
      'mobileNo',
      this.profile.mobileNo
    );
    if (filterData.length !== 0) {
      this.mobileNoError = 'MobileNo Already Exists';
    } else {
      this.mobileNoError = null;
    }
  }

  async emailCheck() {
    const filterData = await this.getfilterdata(
      'emailId',
      this.profile.emailId
    );
    if (filterData.length !== 0) {
      this.emailIdError = 'Email Already Exists';
    } else {
      this.emailIdError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      this.spinner.show();
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.profile.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.profile.id,
        });
      }
      this.ProfileService.vaildFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.spinner.hide();
          resolve(res.body);
        },
        () => {
          this.spinner.hide();
          this.onError();
          resolve(null);
        }
      );
    });
  }

  openNew() {
    this.loadValue();
    this.submitted = false;
    this.profileDialog = true;
    this.enableFeild = false;
    this.profile = new AddProfile();
  }

  sendOtp() {
    this.sendotp = true;
    if (!this.aadharNoNameError) {
      setTimeout(() => {
        this.otpButtonName = "Resend OTP"
        this.sendotp = false;
      }, 30000);
      this.ProfileService.sendOtp(this.profile.aadhaarNo).subscribe(
        (res: HttpResponse<any>) => {
          this.txnNo = res.body;
        },
        () => {
          this.onError();
          this.hideDialog();
        }
      );
    }
  }

  async validateOtp(otp) {
    this.spinner.show()
    this.ProfileService.validateOtp(
      this.txnNo,
      this.profile.aadhaarNo,
      this.profile.otp
    ).subscribe(
      async (res: HttpResponse<any>) => {
        this.profile = res.body;
        this.enableFeild = true;
        this.otpOpen = true;
        // const dateParts = details.inforamtion['@attributes']['dob'].split('-');
        // const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
        // this.profile.dateOfBirthUi = new Date(formattedDate);
        this.profile.dateOfBirthUi = new Date(this.profile.dateOfBirth);
        this.profile.landmark = this.profile.area
        // this.profile.name = details.inforamtion['@attributes']['name'];
        // this.genderList.forEach((element) => {
        //   if (
        //     element.name[0] === details.inforamtion['@attributes']['gender']
        //   ) {
        //     this.profile.genderDto = element;
        //   }
        // });
        // this.profile.doorNo = details.address['@attributes']['house'];
        // this.profile.street = details.address['@attributes']['street'];
        // this.profile.landmark = details.address['@attributes']['lm'];
        // this.profile.district = details.address['@attributes']['dist'];
        // this.profile.city = details.address['@attributes']['vtc'];
        // this.profile.pinCode = details.address['@attributes']['pc'];
        // this.profile.state = details.address['@attributes']['state'];
        this.profile.fileNameOne = await this.getImage(
          this.profile.fileNameOne
        );
        this.spinner.hide()
      },
      () => {
        this.spinner.hide()
        this.onError();
        this.hideDialog();
      }
    );
  }

  getImage(image: string): Promise<any> {
    return new Promise<any>((resolve) => {
      const binaryString = atob(image);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'image/jpeg' });
      const formData = new FormData();
      formData.append('file', blob, 'profile');
      this.paymentService
        .uploadImage(formData)
        .subscribe((res: HttpResponse<any>) => {
          resolve(res.body.fileName);
        });
    });
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    if (this.rolecode) {
      this.loadPage(event.page);
    }
  }

  hideDialog() {
    this.otpOpen = false;
    this.enableFeild = false;
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.profile = new AddProfile();
    this.ngSelectInvalid = false;
    this.profileDialog = false;
    this.resendotp = false;
    this.sendotp = false;
    this.otpButtonName = "Send OTP";
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.profile.mobileNo
    );
    !this.profile.mobileNo ? (this.showMobileError = false) : true;
    if (this.profile.mobileNo.length == 10 && !this.inCharge) {
      this.mobileNo();
    }
    return !this.showMobileError && !!this.profile.mobileNo;
  }

  validateEmail() {
    if (this.profile.emailId) {
      this.emailError = !this.validationService.isEmailValid(
        this.profile.emailId
      );
      !this.profile.emailId ? (this.emailError = false) : true;
      return !this.emailError;
    }
    else {
      return true;
    }

  }

  validatePan() {
    if (this.profile.panNo) {
      this.panError = !this.validationService.isPanValid(this.profile.panNo);
      !this.profile.panNo ? (this.panError = false) : true;
      if (this.profile.panNo.length == 10) {
        this.panNoCheck();
      }
      return !this.panError
    }
    else {
      return true;
    }

  }

  validatePin() {
    this.pinError = !this.validationService.isPincodeValid(this.profile.pinCode);
    !this.profile.pinCode ? (this.pinError = false) : true;
    return !this.pinError && !!this.profile.pinCode;
  }

  validateAotp() {
    this.otpError = !this.validationService.isOtpValid(this.profile.otp);
    !this.profile.otp ? (this.otpError = false) : true;
    // return !this.showMobileError && !!this.profile.otp;
  }

  validateAadhaar() {
    this.aadharNoNameError = null;
    this.aadhaarError = !this.validationService.isAadhaarValid(
      this.profile.aadhaarNo
    );
    !this.profile.aadhaarNo ? (this.aadhaarError = false) : true;

    if (this.profile.aadhaarNo.length == 12 && !this.inCharge) {
      this.aadhaarNocheck();
    }
    // return !this.showMobileError && !!this.profile.aadhaarNo;
  }

  saveProfile() {
    const isFormValid =
      this.validateMobile() &&
      this.validateEmail() &&
      this.validatePan() &&
      this.profile.mobileNo &&
      this.profile.maritalStatusDto &&
      this.profile.dateOfAssumingOffice &&
      this.profile.termValidUpTo &&
      this.profile.educationalQualificationDto
    if ((!this.inCharge && this.panNoError) || this.mobileNoError) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    this.profile.incharge = this.inCharge;
    this.profile.adminUserDtoCreatedby = {
      id: 1,
    };
    if (this.rolecode != 'DC2' && this.rolecode != 'BC2'
      && this.rolecode != 'VC2' && this.rolecode != 'SA' && this.rolecode != 'SUA') {
      this.profile.categoryReservationDto = null;
      this.profile.communityDto = null;
    }
    if (this.profile.categoryReservationDto?.id === null) {
      this.profile.categoryReservationDto = null;
    }
    if (this.profile.communityDto?.id === null) {
      this.profile.communityDto = null;
    }

    if (!isFormValid) {
      this.ngSelectInvalid = true;

    }
    if (this.ngSelectInvalid) {
      return;
    }
    else {
      this.ProfileService.save(this.profile).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.profileDialog = false;
          // this.loadPage(this.page);
          window.location.reload();
          this.router.navigate(['/home']);
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
    }


  }
  onSelectRow($event) {
    if ($event.type === 'select') {
      this.ProfileService.getProfile($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.profile = res.body;
          this.inCharge = res.body.incharge;
          this.profileDialog = true;
          this.enableFeild = true;
          if (this.profile.dateOfBirth) {
            this.profile.dateOfBirthUi = new Date(this.profile.dateOfBirth);
          }
          if (this.profile.termValidUpTo) {
            this.profile.termValidUpTo = new Date(this.profile.termValidUpTo);
          }
          if (this.profile.dateOfAssumingOffice) {
            this.profile.dateOfAssumingOffice = new Date(
              this.profile.dateOfAssumingOffice
            );
          }
          this.loadValue();
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.ProfileService.delete($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }
  onSearch() {
    this.isSearch = true;
    this.loadPage(1);
  }

  onClear() {
    this.isSearch = false;
    this.adminUserName = null;
    this.lgdCode = null;
    this.loadPage(1);
  }
}
