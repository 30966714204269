<div class="container-fluid">
    <div class="row header">
        <div class="col-md-6 d-flex">
            <img src="./assets/img/ib-rdp-tng-logo.svg" alt="Tamil Nadu Government">
            <h1 [ngClass]="{'tamil-header-font': selectedLanguage === 'nav.ta', 'english-header-font': selectedLanguage === 'nav.en'}" jhiTranslate="nav.header">
                Department of Rural Development and Panchayat Raj
            </h1>
        </div>
        <div class="col-lg-6 top-buttons">

            <div class="full-screen">
                <button type="button" (click)="toggleFullScreen()" class="full-screen-button">
                    <i class="bi bi-fullscreen" *ngIf="!isFullScreen"></i>
                    <i class="bi bi-fullscreen-exit" *ngIf="isFullScreen"></i>
                </button>
            </div>

            <div class="dropdown user " style="margin-top:30px;">
                <p> Session Time : {{ sessionStorageService.retrieve('minutes') }} : {{ sessionStorageService.retrieve('seconds') | number: '2.0' }}</p>
            </div>

            <div class="dropdown user " style="margin-top:30px;">
                <p> Idle Time : {{ sessionStorageService.retrieve('idle_minutes') }} : {{ sessionStorageService.retrieve('idle_seconds') | number: '2.0' }}</p>
            </div>

            <div class="dropdown language pr-2">
                <button class="/btn dropdown-toggle big-screen" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ 'nav.' + selectedLanguage | translate }}
                </button>
                <button class="btn bi bi-translate small-scrren" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                </button>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item" (click)="setLanguage('en')" jhiTranslate="nav.en">English</a>
                    </li>
                    <li>
                        <a class="dropdown-item" (click)="setLanguage('ta')" jhiTranslate="nav.ta">Tamil</a>
                    </li>
                </ul>
            </div>

            <div class="dropdown user">
                <!-- <div class="circle">
          <i class="pi pi-user p-text-light p-text-bold" style="font-size: 16px;"></i>
        </div> -->
                <p style="margin-top:30px;">
                    {{userName}}
                </p>
                <!-- <ul class="dropdown-menu">
          <li>
            <a routerLink="" class="dropdown-item" routerLink="/">
              <i class="pi pi-user"></i>
              {{'nav.myAccount' | translate}}</a>
          </li>
          <li>
            <a routerLink="" class="dropdown-item" routerLink="/">
              <i class="pi pi-cog"></i>
              {{'nav.settings' | translate}}</a>
          </li>
        </ul> -->
            </div>

            <div class="logout dropdown pr-2 mt-3">
                <i class="pi pi-user" type="button" data-bs-toggle="dropdown" aria-expanded="false"></i>
                <ul class="dropdown-menu">
                    <li class="dropdown-item">
                        {{name}}
                    </li>
                    <li>
                        <a class="dropdown-item" (click)="logout()">Logout</a>
                    </li>
                </ul>
            </div>

            <!-- <div class="logout">
                <button  label="Log Out" pButton icon="pi pi-sign-out" (click)="logout()"></button>
                <button id="logout" pButton type="button" (click)="logout()">
          <i class="pi pi-sign-out"></i>
          <span [ngClass]="{'tamil-kavi': selectedLanguage === 'nav.ta', 'english-reqular': selectedLanguage === 'nav.en'}" class="label">{{ 'logout.name' | translate }}</span>
        </button>
            </div> -->
        </div>
    </div>
    <div *ngIf="!disableMenu" class="row nav-row">
        <nav class="navbar navbar-expand-lg navbar-dark bg-custom">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon">
                        <svg width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M1.5 4.5A.5.5 0 0 1 2 4h12a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-1zm0 4A.5.5 0 0 1 2 8h12a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-1zm0 4a.5.5 0 0 1 .5-.5H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-1z" />
                        </svg>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <!-- <li [routerLinkActive]="['active']" class="nav-item">
                            <i routerLink="navigation-search" class="pi pi-home pl-1 pr-1" style="font-size: 1.5rem;margin-top: 6px; color: white;cursor: pointer;"></i>
                        </li> -->
                        <li [routerLinkActive]="['active']" class="nav-item">
                            <a routerLink="landing-page" jhiTranslate="nav.home" class="nav-link">
                                Home</a>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.dashboardHead" [hidden]="hideMenu('DASHBOARD')" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Dashboard
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li *ngIf="roleCode !== 'TA' && roleCode !== 'TNA' && roleCode !== 'DS' && roleCode !== 'DA'">
                                    <a routerLink="home" class="dropdown-item" jhiTranslate="nav.dashboardSub"></a>
                                </li>
                                <li *ngIf="roleCode === 'DS' || roleCode === 'DA'">
                                    <a routerLink="district-dashboard" class="dropdown-item" jhiTranslate="nav.dashboardSub"></a>
                                </li>
                                <li *ngIf="roleCode === 'TNA'">
                                    <a routerLink="tangedco-dashboard" class="dropdown-item" jhiTranslate="nav.dashboardSub"></a>
                                </li>
                                <li *ngIf="!disableTwad && roleCode === 'VMAKER'">
                                    <a routerLink="Twad-Dashboard" class="dropdown-item" jhiTranslate="nav.Twadashboard"></a>
                                </li>
                                <li *ngIf="!disableTneb && roleCode === 'VMAKER'">
                                    <a routerLink="Tneb-Dashboard" class="dropdown-item" jhiTranslate="nav.TnebDashboard"></a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="profile" class="nav-link" routerLink="profile" [hidden]="hideMenu('PROFILE')" jhiTranslate="nav.profile">2.Profile</a>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.user.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                User
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="user" class="dropdown-item" [hidden]="hideMenu('USERLIST')" jhiTranslate="nav.user.list"></a>
                                </li>
                                <li>
                                    <a routerLink="zonal" [hidden]="hideMenu('ZONAL')" class="dropdown-item" jhiTranslate="nav.user.zonal"></a>
                                </li>
                                <li>
                                    <a routerLink="profile" class="dropdown-item" jhiTranslate="nav.user.profile"></a>
                                </li>
                            </ul>
                        </li>

                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.agency" id="navbarDropdown" [hidden]="hideMenu('AGENCY')" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="agency" class="dropdown-item" [hidden]="hideMenu('AGENCY')" jhiTranslate="nav.agencySub"></a>
                                </li>
                            </ul>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('VENDORREGISTRATION')" jhiTranslate="nav.vendor.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Vendor Registration
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="personal-vendors" [hidden]="hideMenu('PERSONALVENDOR')" class="dropdown-item" jhiTranslate="nav.vendor.personal"></a>
                                </li>
                                <li>
                                    <a routerLink="commercial-vendors" [hidden]="hideMenu('COMMERCIALVENDOR')" class="dropdown-item" jhiTranslate="nav.vendor.commercial"></a>
                                </li>
                                <li><a routerLink="employee" class="dropdown-item" [hidden]="hideMenu('EMPLOYEE')" jhiTranslate="nav.vendor.employee">Employee</a></li>
                                <li><a routerLink="electedRepresentativeComponent" [hidden]="hideMenu('ELECTEDREPRESENTATIVE')" class="dropdown-item" jhiTranslate="nav.vendor.electedRepresentative">Elected Representative</a></li>
                                <li><a routerLink="universal-Vendor" [hidden]="hideMenu('UNIVERSALVENDOR')" class="dropdown-item">45 Universal Vendor</a></li>
                                <li><a routerLink="beneficiary-creation" [hidden]="hideMenu('BENEFICIARY')" class="dropdown-item" jhiTranslate="nav.vendor.beneficiary">Beneficiary</a></li>
                                <li><a routerLink="deduction-accounts" [hidden]="hideMenu('DEDUCTIONACCOUNT')" class="dropdown-item" jhiTranslate="nav.vendor.deduction">Deduction Accounts</a>
                                </li>
                            </ul>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('ACCOUNT')" jhiTranslate="nav.account.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Account
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="account-sna" class="dropdown-item" [hidden]="hideMenu('ACCOUNT')" jhiTranslate="nav.account.sna"></a>
                                </li>
                            </ul>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('DEPARTMENT')" jhiTranslate="nav.department.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Department
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="department-list" class="dropdown-item" jhiTranslate="nav.department.departList"></a>
                                </li>
                                <li>
                                    <a routerLink="scheme-list" class="dropdown-item" jhiTranslate="nav.department.schemeList"></a>
                                </li>
                            </ul>
                        </li>
                        <li [routerLinkActive]="['active']" [hidden]="hideMenu('FUNDS')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" jhiTranslate="nav.funds.nameHead" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="funds" class="dropdown-item" jhiTranslate="nav.funds.nameSub"></a>
                                </li>
                                <li>
                                    <a routerLink="opening-balance" class="dropdown-item" jhiTranslate="nav.funds.openingBal"></a>
                                </li>
                                <li>
                                    <a routerLink="funds-transaction" class="dropdown-item" jhiTranslate="nav.funds.transaction"></a>
                                </li>
                            </ul>
                        </li>
                        <li *ngIf="!disableTneb" [routerLinkActive]="['active']" [hidden]="hideMenu('TANGEDCO')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Tangedco
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="Tangedco-Bill-Details" [hidden]="hideMenu('CONSUMERDEMAD')" class="dropdown-item">81 Consumer Demand</a>
                                </li>
                                <li>
                                    <a routerLink="Tangedco-Master-Details" [hidden]="hideMenu('TANGEDCOMASTER')" jhiTranslate="tangedco.consumerDemandAdd" class="dropdown-item">Tangedco
                                        Master</a>
                                </li>
                                <li>
                                    <a routerLink="Tangedco-Master-Details" [hidden]="hideMenu('APPROVELIST')" jhiTranslate="tangedco.approveList" class="dropdown-item">Approve List</a>
                                </li>
                                <li>
                                    <a routerLink="Tangedco-Master-Approved" [hidden]="hideMenu('APPROVEDLIST')" jhiTranslate="tangedco.approvedList" class="dropdown-item">Approved List</a>
                                </li>
                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="tneb-report" class="dropdown-item" [hidden]="hideMenu('TANGEDCOREPORT')" jhiTranslate="nav.report.tneb"></a>
                                </li>

                                <li>
                                    <a routerLink="tneb-menu-configuration" class="dropdown-item" [hidden]="hideMenu('TANGEDCOMENULIST')" jhiTranslate="tangedco.menuConfiguartion"></a>
                                </li>
                                <li>
                                    <a routerLink="menu-enable-list" class="dropdown-item" [hidden]="hideMenu('TANGEDCOENABLEDISABLE')" jhiTranslate="tangedco.menuEnable"></a>
                                </li>
                                <li *ngIf="roleCode === 'SA' || roleCode === 'SM' || roleCode === 'TNA' || roleCode === 'DM' || roleCode === 'DA' || roleCode === 'DS' || roleCode === 'DAD' || roleCode === 'BM'">
                                    <a routerLink="tangedco-transaction-report" class="dropdown-item">88 Transaction Report</a>
                                </li>

                            </ul>
                        </li>
                        <li [routerLinkActive]="['active']" [hidden]="hideMenu('PAYMENT')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.payments.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" routerLinkActive="active">
                                Payment
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li *ngIf="roleCode !== 'TA' && roleCode !== 'TM'"><a routerLink="payment-personal" class="dropdown-item" jhiTranslate="nav.payments.personal">Personal</a></li>
                                <li *ngIf="roleCode !== 'TA' && roleCode !== 'TM'"><a routerLink="payment" class="dropdown-item" jhiTranslate="nav.payments.commercial">Commercial</a></li>
                                <li *ngIf="roleCode !== 'TA' && roleCode !== 'TM'"><a routerLink="employeePaymentTable" jhiTranslate="nav.payments.employee" class="dropdown-item">Employee</a></li>
                                <li *ngIf="roleCode !== 'TA' && roleCode !== 'TM'"><a routerLink="electedRepresenativePaymentTableComponent" class="dropdown-item" jhiTranslate="nav.payments.electedRepresentative">Elected Representative</a>
                                </li>
                                <li *ngIf="roleCode !== 'TA' && roleCode !== 'TM'"><a routerLink="employee-bulkPay-list" class="dropdown-item" jhiTranslate="nav.payments.eBulkPay">Employee Bulk Payment</a></li>
                                <li *ngIf="roleCode !== 'TA' && roleCode !== 'TM'"><a routerLink="ER-bulkPay-list" class="dropdown-item" jhiTranslate="nav.payments.ERBulkPay">ER Bulk Payment</a></li>
                                <li *ngIf="!disableTwad"><a routerLink="Twad-List" class="dropdown-item" jhiTranslate="dashBoard.dropdown.twad">TWAD</a></li>
                                <li *ngIf="!disableTnebPayment && roleCode !== 'TA' && roleCode !== 'TM'"><a routerLink="Tangedco-Payment-Table" class="dropdown-item" jhiTranslate="nav.payments.tangedcoTable">Tangedco</a></li>
                                <li><a [hidden]=hidden routerLink="swapTable" class="dropdown-item" jhiTranslate="nav.payments.swapTable">Swap Table</a> </li>
                                <!-- <li><a routerLink="beneficiary-payment-list" class="dropdown-item" jhiTranslate="nav.vendor.beneficiary">Beneficiary</a></li> -->
                                <li *ngIf="roleCode !== 'TA' && roleCode !== 'TM'"><a routerLink="beneficiary-payment-list" class="dropdown-item" jhiTranslate="nav.payments.beneficiary">Beneficiary</a></li>
                                <li *ngIf="roleCode !== 'TA' && roleCode !== 'TM'"><a routerLink="thooimai-kavalar-payment-list" class="dropdown-item" jhiTranslate="nav.payments.vtKavalar">Village Thoomai Kavalar</a></li>
                                <li *ngIf="roleCode !== 'TA' && roleCode !== 'TM'"><a routerLink="other-payment-list" class="dropdown-item">913 Other Funds Payment</a></li>
                            </ul>
                        </li>

                        <li *ngIf="!disableTwad" [routerLinkActive]="['active']" [hidden]="hideMenu('TWAD')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" routerLinkActive="active">
                                TWAD
                            </a>

                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li *ngIf="!disableTwad" [routerLinkActive]="['active']" class="nav-item">
                                    <a routerLink="twad-master-list" class="dropdown-item">101 Bills</a>
                                </li>
                                <li *ngIf="!disableTwad && (this.roleCode === 'SA' || this.roleCode === 'TA' || this.roleCode === 'TM')" [routerLinkActive]="['active']" class="nav-item">
                                    <a routerLink="twad-office-master" class="dropdown-item">102 Office Master</a>
                                </li>
                                <li *ngIf="(this.roleCode === 'SA' || this.roleCode === 'TA' || this.roleCode === 'TM' || this.roleCode === 'DM' || this.roleCode === 'SM' || this.roleCode === 'DM' || this.roleCode === 'DA' || this.roleCode === 'DAD' || this.roleCode === 'BM')">
                                    <a routerLink="twad-report" class="dropdown-item">103 Report</a>
                                </li>
                                <li *ngIf="(this.roleCode === 'SA' || this.roleCode === 'TA' || this.roleCode === 'TM')">
                                    <a routerLink="twad-account-summary" class="dropdown-item">104 Account Summary</a>
                                </li>
                                <li *ngIf="(this.roleCode === 'SA' || this.roleCode === 'TA' || this.roleCode === 'TM')">
                                    <a routerLink="Office-Master" class="dropdown-item">105 Office</a>
                                </li>
                                <li *ngIf="(this.roleCode === 'SA' || this.roleCode === 'TA')">
                                    <a routerLink="twad-Upload-excel-list" class="dropdown-item">106 Excel Upload</a>
                                </li>





                            </ul>
                        </li>

                        <!-- <li [routerLinkActive]="['active']" class="nav-item">
                            <a routerLink="blukpaymentTable" jhiTranslate="nav.payments.bulkpayment" [hidden]="hideMenu('BULKVOUCHER')" class="nav-link"></a>
                        </li> -->
                        <li [routerLinkActive]="['active']" [hidden]="hideMenu('PAYMENTSTATUS')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" routerLinkActive="active">
                                Payment Status
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a routerLink="RejectFailedPayments" class="dropdown-item">111 Failed Payments</a>
                                </li>
                            </ul>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('LEVELTYPE')" jhiTranslate="nav.master.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Master
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="level-type" class="dropdown-item" jhiTranslate="nav.master.levelTypeMenu"></a>
                                </li>
                                <li>
                                    <a routerLink="level-master" class="dropdown-item" jhiTranslate="nav.master.levelMasterMenu"></a>
                                </li>
                                <li>
                                    <a routerLink="component-type" class="dropdown-item" jhiTranslate="nav.master.compoType"></a>
                                </li>
                            </ul>
                        </li>

                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('COMPONENTTYPE')" jhiTranslate="nav.iACompo.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                IA Component
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li *ngIf="this.roleCode !== 'SM'">
                                    <a routerLink="limit-set" class="dropdown-item" jhiTranslate="nav.iACompo.limitset"></a>
                                </li>
                                <li *ngIf="this.roleCode !== 'SM'">
                                    <a routerLink="limit-translist" class="dropdown-item" jhiTranslate="nav.iACompo.limitTrans"></a>
                                </li>
                                <li *ngIf="this.roleCode !== 'SM'">
                                    <a routerLink="limit-formulalist" class="dropdown-item" jhiTranslate="nav.iACompo.limitFormula"></a>
                                </li>
                                <li *ngIf="this.roleCode !== 'SM'">
                                    <a routerLink="excel-Upload-list" class="dropdown-item" jhiTranslate="nav.iACompo.limitExcelUploadMenu"></a>
                                </li>
                                <li>
                                    <a routerLink="auto-sweep-list" class="dropdown-item" jhiTranslate="nav.iACompo.autoSweep"></a>
                                </li>


                            </ul>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('COMPONENTMASTER')" jhiTranslate="nav.compoMaster.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Component Master
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="major-component" class="dropdown-item" jhiTranslate="nav.compoMaster.major"></a>
                                </li>
                                <li>
                                    <a routerLink="minor-component" class="dropdown-item" jhiTranslate="nav.compoMaster.minor"></a>
                                </li>
                                <li>
                                    <a routerLink="sub-component" class="dropdown-item" jhiTranslate="nav.compoMaster.sub"></a>
                                </li>
                            </ul>
                        </li>

                        <!-- <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" [hidden]="hideMenu('OSRFUND')" data-bs-toggle="dropdown" aria-expanded="false">
                                OwnSourceRevenue
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">

                                <li [routerLinkActive]="['active']" class="nav-item">
                                    <a routerLink="OwnSourceRevenue" jhiTranslate="nav.funds.OwnSourceRevenue" [hidden]="hideMenu('OSRFUND')" class="dropdown-item">OwnSourceRevenue</a>
                                </li>
                            </ul>
                        </li> -->
                        <!-- <li [routerLinkActive]="['active']" class="nav-item">
                          
                        </li> -->
                        <li [routerLinkActive]="['active']" [hidden]="hideMenu('OSRFUND')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.funds.nameHead" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Funds
              </a>

                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="OwnSourceRevenue" jhiTranslate="nav.funds.OwnSourceRevenue" class="dropdown-item" [hidden]="hideMenu('OSRFUND')">OwnSourceRevenue</a>
                                </li>
                                <li>
                                    <a routerLink="account-statement" jhiTranslate="nav.funds.accountStatement" class="dropdown-item" [hidden]="hideMenu('OSRFUND')">Statement</a>
                                </li>
                                <li>
                                    <a routerLink="account-balances-statement" jhiTranslate="nav.funds.accountBalances" class="dropdown-item" [hidden]="hideMenu('OSRFUND')">Balance</a>
                                </li>
                            </ul>
                        </li>

                        <!-- <li [routerLinkActive]="['active']" class="nav-item">
                            <a routerLink="report-list" jhiTranslate="nav.report.name" [hidden]="hideMenu('REPORT')" class="nav-link">Report</a>
                        </li> -->
                        <li [routerLinkActive]="['active']" [hidden]="hideMenu('REPORT')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.report.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Report
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li *ngIf="roleCode === 'TNA'">
                                    <a routerLink="tneb-report" class="dropdown-item">201 Tangedco  Report</a>
                                </li>
                                <li *ngIf="roleCode === 'TNA'">
                                    <a routerLink="tangedco-payment-report" class="dropdown-item">202 Tangedco Payment Report</a>
                                </li>
                                <li *ngIf="roleCode === 'TNA'">
                                    <a routerLink="tangedco-transaction-report" class="dropdown-item">203 Transaction Report</a>
                                </li>
                                <li *ngIf="roleCode === 'TNA'">
                                    <a routerLink="tangedco-mis-report" class="dropdown-item">204 MIS Report</a>
                                </li>


                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="Onboard-Report" class="dropdown-item" jhiTranslate="nav.report.onboard"></a>
                                </li>
                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="Employee-Onboard-Report" class="dropdown-item" jhiTranslate="nav.report.employeeOnboard"></a>
                                </li>
                                <!-- <li><a routerLink="Vendor-Report" class="dropdown-item" jhiTranslate="nav.report.vendor"></a></li> -->
                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="Payment-Report" class="dropdown-item" jhiTranslate="nav.report.payment"></a>
                                </li>
                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="Bulk-Payment-Report" class="dropdown-item" jhiTranslate="nav.report.bulkpayment"></a>
                                </li>
                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="Voucher-Status-Report" class="dropdown-item" jhiTranslate="nav.report.voucher"></a>
                                </li>
                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="AccountWise-Fund-Report" class="dropdown-item" jhiTranslate="nav.report.accountwiseFund"></a>
                                </li>
                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="Nil-Total-Balance-Report" class="dropdown-item" jhiTranslate="nav.report.nilbalancereport"></a>
                                </li>
                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="Transaction-Report" class="dropdown-item" jhiTranslate="nav.report.transcationreport"></a>
                                </li>
                                <li *ngIf="roleCode=='SA'">
                                    <a routerLink="Employee-Count-Report" class="dropdown-item" jhiTranslate="nav.report.employeeCountReport"></a>
                                </li>
                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="Payment-Not-Done-Report" class="dropdown-item" jhiTranslate="nav.report.paymentNotDoneReport"></a>
                                </li>
                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="deduction-report" class="dropdown-item" [hidden]="hideMenu('DEDUCTIONACCOUNTREPORT')" jhiTranslate="tangedco.deductionAccount"></a>
                                </li>
                                <li *ngIf="roleCode !== 'VMAKER'">
                                    <a routerLink="deduction-voucher-report" class="dropdown-item" [hidden]="hideMenu('DEDUCTIONACCOUNTREPORT')" jhiTranslate="nav.report.deductionReport"></a>
                                </li>
                                <li *ngIf="roleCode !== 'TNA'">
                                    <a routerLink="employee-transfer-Report" class="dropdown-item" jhiTranslate="nav.report.employeeTransferReport"></a>
                                </li>
                                <li *ngIf="roleCode !== 'VMAKER' && roleCode !== 'TNA'">
                                    <a routerLink="inspect-report" class="dropdown-item">1516 Inspection Report</a>
                                </li>

                                <li *ngIf="roleCode !== 'VMAKER' && roleCode !== 'TNA'">
                                    <a routerLink="Voucher-Delay-Report" class="dropdown-item" [hidden]="hideMenu('VOUCHERDELAYREPORT')" jhiTranslate="nav.report.voucherDelayReport"></a>
                                </li>

                            </ul>
                        </li>
                        <li *ngIf="roleCode === 'VMAKER'" [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" jhiTranslate="nav.report.name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Report
              </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="deduction-voucher-report" class="dropdown-item" jhiTranslate="nav.report.deductionReport"></a>
                                </li>
                                <li>
                                    <a routerLink="Voucher-Delay-Report" [hidden]="hideMenu('VOUCHERDELAYREPORT')" class="dropdown-item" jhiTranslate="nav.report.voucherDelayReport"></a>
                                </li>
                                <li>
                                    <a routerLink="inspect-report" class="dropdown-item">1516 Inspection Report</a>
                                </li>
                            </ul>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item">
                            <a routerLink="push-notification" jhiTranslate="nav.notification" [hidden]="hideMenu('PUSHNOTIFICATION')" class="nav-link">Notification</a>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('BASICREPORTS')" jhiTranslate="nav.report.basicReport" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a routerLink="Vendor-Transaction-Report" class="dropdown-item">171 Vendor
                                        Transaction
                                        Report</a></li>
                                <li><a routerLink="Payment-Status-Report" class="dropdown-item">172 Payment Status
                                        Report</a></li>
                                <li><a routerLink="Payment-Trans-Report" class="dropdown-item">173 Payment Transaction
                                        Report</a></li>
                                <li><a routerLink="Api-Request-Response-Report" class="dropdown-item">174 API Request
                                        Response Report</a></li>
                                <li><a routerLink="IA-Component-Report" class="dropdown-item">175 IA Component
                                        Report</a>
                                </li>
                                <li><a routerLink="IA-Component-Trans-Report" class="dropdown-item">176 IA Component
                                        Transaction Report</a></li>
                                <li><a routerLink="Account-Validation-Log" class="dropdown-item">177 Account Validation
                                        Log</a></li>
                                <li><a routerLink="DSC-Report" class="dropdown-item">178 DSC Report</a></li>
                            </ul>
                        </li>
                        <li [routerLinkActive]="['active']" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" [hidden]="hideMenu('EMPLOYEETRANSFER')" jhiTranslate="nav.user.employee" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a routerLink="employee-transfer" jhiTranslate="nav.user.employeeSub" class="dropdown-item">t</a></li>
                            </ul>
                        </li>
                        <li [hidden]="hideMenu('COMPONENTSWAP')" [routerLinkActive]="['active']" class="nav-item">
                            <a routerLink="component-swap-list" class="nav-link">Component Wise Swap</a>
                        </li>
                        <li [routerLinkActive]="['active']" [hidden]="hideMenu('MINESANDMINERALS')" class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Mines and Minerals
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a routerLink="district-interal-swap-table" class="dropdown-item">Allocation to F Component</a>
                                </li>
                                <li>
                                    <a routerLink="funds-transfer-village-list" class="dropdown-item">Fund Transfer to Village</a>
                                </li>
                            </ul>
                        </li>
                        <li *ngIf="roleCode === 'VMAKER' || roleCode === 'VC1' || roleCode === 'VC2' " [routerLinkActive]="['active']" class="nav-item">
                            <a routerLink="Other-Funds" class="nav-link">Other Funds</a>
                        </li>




                    </ul>
                </div>
            </div>
        </nav>
    </div>
    <!-- <div class="row">
        <div class="col-12">
            <div class="breadscrum">
                <p>Breadscrum Area</p>
            </div>
        </div>
    </div> -->
</div>