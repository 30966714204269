import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Component } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ReportService } from '../report.service';
import { HttpResponse } from '@angular/common/http';
import { OnBoardReportDto } from '../report.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { saveAs } from 'file-saver'

@Component({
  selector: 'app-onboard-report',
  templateUrl: './onboard-report.component.html',
  styleUrls: ['./onboard-report.component.scss']
})
export class OnboardReportComponent {
  cols: any[];
  onBoardReportDto:OnBoardReportDto[];
  enableData:boolean=false;
  itemsPerPage: any = 10;
  page: number;
  roleId:number;
  status:string;
  enableOnboard=false;
  enableVillage=false;
  blockList:any;
  districtList:any;
  searchResult: SearchResult<any> =
  new SearchResult<any>();
  blockwiseReport: boolean;
  districtwiseReport: boolean=true;
  VillageList: any;
  roleCode: any;
  DMRole: boolean;
  headerName: string;
  header2Name: string;
  levelMasterId: any;
  chosenBlockId: number;
  chosenDistrictId: number;

  constructor(public fontService: FontService,
    public reportService:ReportService,
    private spinner: NgxSpinnerService,
    private loginService:SigninService,
    private sessionStorage:SessionStorageService

    ) {
       }

  ngOnInit(): void {
    this.spinner.show();
    this.reportService.getOnboardReport().subscribe(
      (res: OnBoardReportDto) => {
        this.onBoardReportDto=[res];
      },
      (err)=>{

      }
    )
    this.loginService.getuserDto().subscribe(
      (res) => {
       this.roleCode = res.body.role.code;
       this.levelMasterId=res.body.levelMasterId;
       if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA')
       {
        this.headerName='District Name'
        this.reportService.onboardDistrictReport( {
          parentId: 1
        }).subscribe(
          (res: any) => {
            this.districtList = res.body
            this.spinner.hide();
          },
          (err)=>{
            this.spinner.hide();
          }
        )
       }
       else if(this.roleCode=='DM' || this.roleCode ==='DAD' || this.roleCode === 'DA'){
        this.headerName='Block Name'
        this.reportService.onboardBlockReport( {
          parentId: this.levelMasterId
        }).subscribe(
          (res: any) => {
            this.districtList = res.body
            this.spinner.hide();
          },
          (err)=>{
            this.spinner.hide();
          }
        )
       }
       else if(this.roleCode=='ZVM' || this.roleCode =='BM' || this.roleCode =='BAD'){
        this.headerName='Village Name'
        this.reportService.onboardVillageReport( {
          parentId: this.levelMasterId,
          zonalName:this.sessionStorage.retrieve('user')?.zonalName
        }).subscribe(
          (res: any) => {
            this.districtList = res.body
            this.spinner.hide();
          },
          (err)=>{
            this.spinner.hide();
          }
        )
       }
      },
      (onError) => {
    });
    
  this.cols = [
    { field: 'lgdCode', header: 'LGD Code' , isSortable: true,
    isSelectcolumn: true , jhiTranslate: 'reports.lgdCode',},
    { field: 'villageName', header: 'Village',  isSortable: true,
    isSelectcolumn: true, jhiTranslate: 'reports.village', },
    { field: 'blockName', header: 'Block',  isSortable: true,
    isSelectcolumn: true, jhiTranslate: 'reports.block', },
    { field: 'districtName', header: 'District',  isSortable: true,
    isSelectcolumn: true, jhiTranslate: 'reports.district',}
  ];
  }

  getBlockDetails(id: number) {
    this.spinner.show();
    if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA')
    {
    this.header2Name='Block Name';
    this.chosenDistrictId=id;
    this.reportService.onboardBlockReport( {
      parentId: id
    }).subscribe(
      (res: any) => {
        this.blockList = res.body
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }
  else if(this.roleCode=='DM' || this.roleCode=='DAD' || this.roleCode == 'DA'){
    this.DMRole=true;
    this.header2Name='Village Name';
    this.chosenBlockId=id;
    this.reportService.onboardVillageReport( {
      parentId: id
    }).subscribe(
      (res: any) => {
        this.blockList = res.body
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }
    
  }

  getVillageDetails(id: number) {
    this.spinner.show();
    this.chosenBlockId=id;
    this.reportService.onboardVillageReport( {
      parentId: id
    }).subscribe(
      (res: any) => {
        this.VillageList = res.body
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }
  loadOnboardVillage(){
    this.enableData=true;
    this.enableOnboard=true;
    this.loadPage(1);
  }
  loadMakerVillage(roleId,status){
    this.roleId=roleId;
    this.status=status;
    this.enableData=true;
    this.enableVillage=true;
    this.loadVillage(1);
  }
  hideDialog(){
    this.enableData=false;
    this.enableOnboard=false;
    this.enableVillage=false;
  }
  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }
  onVillageLazyLoad(event){
    this.itemsPerPage = event.size;
    this.loadVillage(event.page);
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
     
    };
    if(this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT' || this.roleCode === 'ZVM') {
      filterQuery["districtId"] = this.sessionStorage.retrieve('levelMaster').id;
    }
    if(this.roleCode === 'ZVM') {
      filterQuery["zonalName"] = this.sessionStorage.retrieve('user')?.zonalName;
    }
    this.reportService.onboardVillageList({ ...filterQuery }).subscribe(
      (res: HttpResponse<any[]>) => {
        this.searchResult.total = Number(
          res.headers.get('X-Total-Count')
        );
        this.page = 1;
        this.searchResult.items = res.body ?? [];
        this.searchResult = { ...this.searchResult };
        this.spinner.hide();
      },
      () => {
        this.onError();
      }
    );
  }
  loadVillage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
   
    let filter=
      {roleId:this.roleId,
      status:this.status}
      if(this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT' || this.roleCode === 'ZVM') {
        filter["districtId"] = this.sessionStorage.retrieve('levelMaster').id;
      }
      if(this.roleCode === 'ZVM') {
        query["zonalName"] = this.sessionStorage.retrieve('user')?.zonalName;
      }
    this.reportService.getVillageList({ ...query },filter).subscribe(
      (res: HttpResponse<any[]>) => {
        this.searchResult.total = Number(
          res.headers.get('X-Total-Count')
        );
        this.page = 1;
        this.searchResult.items = res.body ?? [];
        this.searchResult = { ...this.searchResult };
        this.spinner.hide();
      },
      () => {
        this.onError();
      }
    );
  }
  protected onError(): void {
    this.spinner.hide();
  }
  reportDownload(){
    if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
      this.reportService.onboardReportDownload( {
        parentId: 1
      }).subscribe(
        (res:any)=>{
          this.saveBlobToExcel(res.body,'OnBoardReport_Districtwise')
        },
        (err)=>{
          console.log('err')
        }
      )
    }
    else if(this.roleCode=='DM' || this.roleCode === 'DA'){
      this.reportService.onboardReportBlockDownload( {
        parentId: this.levelMasterId

      }).subscribe(
        (res:any)=>{
          this.saveBlobToExcel(res.body,'OnBoardReport_Blockwise')
        },
        ()=>{
          console.log('err')
        }
      )
    }
    else if(this.roleCode=='ZVM' || this.roleCode=='BM' || this.roleCode=='BAD'){
      this.reportService.onboardReportVillageDownload( {
        parentId: this.levelMasterId,
        zonalName:this.sessionStorage.retrieve('user')?.zonalName
      }).subscribe(
        (res:any)=>{
          this.saveBlobToExcel(res.body,'OnBoardReport_Villagewise')
        },
        ()=>{
          console.log('err')
        }
      )
    }
  }
  reportBlockDownload(){
    if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
      this.reportService.onboardReportBlockDownload( {
        parentId: this.chosenDistrictId
      }).subscribe(
        (res:any)=>{
          this.saveBlobToExcel(res.body,'OnBoardReport_Blockwise')
        },
        ()=>{
          console.log('err')
        }
      )
    }
    else if(this.roleCode=='DM' || this.roleCode == 'DA'){
      this.reportService.onboardReportVillageDownload( {
        parentId: this.chosenBlockId
      }).subscribe(
        (res:any)=>{
          this.saveBlobToExcel(res.body,'OnBoardReport_Villagewise')
        },
        ()=>{
          console.log('err')
        }
      )
    }
  }
  reportVillageDownload(){
    this.reportService.onboardReportVillageDownload( {
      parentId: this.chosenBlockId
    }).subscribe(
      (res:any)=>{
        this.saveBlobToExcel(res.body,'OnBoardReport_Villagewise')
      },
      ()=>{
        console.log('err')
      }
    )
  }
  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
  }
}
