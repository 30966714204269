<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="breadcrumb.beneficiary.name">
                        Individual Beneficiary
                    </h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="!(roleCode === 'DM' || roleCode === 'BM' || this.roleCode ==='SM') && currentPath !== '/beneficiary-creation-approve'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
          </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
            <div *ngIf="currentPath !== '/beneficiary-creation-approve'" class="row px-4">
                <div class="col">
                    <label class="form-control-label" for="name" [ngClass]="fontService.labelClass" jhiTranslate="payments.viewTable.beneficiaryName">Beneficiary Name</label>
                    <input type="text" class="form-control" name="name" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="vendorName" id="name" />
                </div>
                <div class="col">
                    <label class="form-control-label" for="name" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno">Account No</label>
                    <input type="text" class="form-control" name="name" maxlength="60" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="accountNo" id="name" />
                </div>
                <div class="col">
                    <label class="form-control-label" for="name" [ngClass]="fontService.labelClass" jhiTranslate="tableHead.uniqueId"></label>
                    <input type="text" class="form-control" name="name" maxlength="60" [(ngModel)]="uniqueId" id="name" />
                </div>
                <div *ngIf="roleCode === 'SA'" class="col">
                    <label class="form-control-label" for="name" [ngClass]="fontService.labelClass">Level Type</label>
                    <ng-select id="vendorType" [(ngModel)]="selectedLevelType" [items]="levelType" appearance="outline" [closeOnSelect]="true" (change)="districtDto=null;BlockLevelDto=null;villageLevelDto=null" [clearable]="true" class="form-control">
                    </ng-select>
                </div>
                <div *ngIf="selectedLevelType" class="col">
                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                    <ng-select id="scheme" [(ngModel)]="districtDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
                <div *ngIf="selectedLevelType !== 'District' && selectedLevelType" class="col">
                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.block">Block</label>
                    <ng-select id="scheme" [appendTo]="'body'" [(ngModel)]="BlockLevelDto" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
                <div *ngIf="selectedLevelType !== 'District' && selectedLevelType !== 'Block' && selectedLevelType" class="col">
                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.village">Village</label>
                    <ng-select id="scheme" [appendTo]="'body'" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" [(ngModel)]="villageLevelDto" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
            </div>
        </ng-template>
    </p-toolbar>
    <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
            <div *ngIf="currentPath !== '/beneficiary-creation-approve'" class="row px-4">
                <div class="col" style="display: flex;align-items: flex-end;">
                    <button pButton pRipple class="p-button-success custom-button" (click)="this.filterProps=[];loadPage()" styleClass="button">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.searchbutton">Search</span>
      </button>
                </div>
                <div class="col mt-3" style="display: flex;align-items: flex-end;">
                    <button pButton pRipple class="p-button-danger custom-button" (click)="vendorName = null;accountNo=null;villageLevelDto=null;BlockLevelDto=null;districtDto=null;selectedLevelType=null;uniqueId=null;this.filterProps=[];loadPage(1)">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.clearbutton">clear</span>
      </button>
                </div>
            </div>
        </ng-template>
    </p-toolbar>
    <div #customerTable id="entities" class="col-12 px-4 mt-3">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="disableDelete" [disableEditIcon]="disableEdit" [lazyLoadOnInit]="false" (emitRow)="onSelectRow($event)" [enableVendorApproved]="currentPath === '/beneficiary-creation-approve'"></custom-filter-table>
    </div>
    <p-dialog #dialog [(visible)]="beneficiaryCreationDialog" [style]="{ width: '850px' }" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            {{ "vendor.addBeneficiary" | translate }}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row">
                        <div class="row gy-2">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="vendor.personal.beneficiarydetails">
                                    Beneficiary Details
                                </h6>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.name" for=" name">Name</label>
                                <input type="text" id="name" name="name" autocomplete="off" [(ngModel)]="beneficiary.name" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.name }" class="form-control" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" style="text-transform:uppercase" [readonly]="edit" maxlength="30" uppercase />
                                <div *ngIf="showNameError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{showNameError}}</small>
                                </div>
                            </div>
                            <!-- <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass"
                                    jhiTranslate="vendor.companyName" for=" companyName">Company Name</label>
                                <input type="text" class="form-control" name="companyName"
                                    [(ngModel)]="beneficiary.companyName" id="companyName"
                                    [ngClass]="{'is-invalid': ngSelectInvalid}" />
                            </div> -->
                            <!-- <div class="col-sm-6 col-md-4">

                                <label for=" designation" [ngClass]="fontService.labelClass" jhiTranslate="vendor.designation" class="form-control-label req">Designation</label>
                                <input type="text" id="designation" autocomplete="off" name="companyName" [(ngModel)]="beneficiary.designation" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.designation }" class="form-control" [readonly]="isDelete" maxlength="50" />

                            </div> -->
                            <!-- <div class="col-sm-6 col-md-4">
                <label for=" staffId" [ngClass]="fontService.labelClass" jhiTranslate="vendor.staff.id"
                  class="form-control-label req">Staff Id</label>
                <input type="text" id="registrationNo" name="registrationNo" autocomplete="off" [(ngModel)]="beneficiary.staffId"
                  [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.staffId }" class="form-control"
                  maxlength="30" (ngModelChange)="checkstaffId()" />
                  <p *ngIf="showStaffError" style="font-size: 14px; color: #ff0000">
                    {{showStaffError }}
                  </p>
              </div> -->
                            <div class="col-sm-6 col-md-4">
                                <label for=" mobileNo" [ngClass]="fontService.labelClass" jhiTranslate="vendor.mobile" class="form-control-label req">Mobile No</label>
                                <input type="text" id="mobileNo" name="mobileNo" autocomplete="off" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" [(ngModel)]="beneficiary.mobile" [ngClass]="{ 'is-invalid': !beneficiary.mobile && ngSelectInvalid }"
                                    (input)="validateMobile()" class="form-control" [readonly]="isDelete" maxlength="10" />
                                <div *ngIf="showMobileError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter a valid Mobile
                    Number.</small>
                                </div>
                                <div *ngIf="showMobileUniError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.showMobileUniError">{{showMobileUniError}}</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label for=" email" [ngClass]="fontService.labelClass" jhiTranslate="vendor.email" class="form-control-label">E-mail</label>
                                <input type="text" id="email" name="email" autocomplete="off" [(ngModel)]="beneficiary.email" (input)="validateEmail()" class="form-control" maxlength="60" [readonly]="isDelete" onblur="this.value =this.value.trim();" />
                                <div *ngIf="emailError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a valid Email
                    Address.</small>
                                </div>
                                <div *ngIf="emailUniError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.showEmailUniError">{{emailUniError}}</small>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-4">
                                <label for=" aadhaar" [ngClass]="fontService.labelClass" jhiTranslate="vendor.aadhaarno" class="form-control-label req">Aadhaar Number</label>
                                <input type="text" name="aadhaar" autocomplete="off" id="aadhaar" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" onblur="this.value =this.value.trim();" [(ngModel)]="beneficiary.aadhaarNo" [readonly]="isDelete" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.aadhaarNo }"
                                    (input)="validateAadhaar()" class="form-control" maxlength="12" />
                                <div *ngIf="aadhaarError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validaadhar">Please enter a valid
                    Aadhaar Number.</small>
                                </div>
                                <!-- <div *ngIf="aadhaarError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.aadhaarNoUniError">{{aadhaarNoUniError}}</small>
                                </div> -->
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label for=" panNo" [ngClass]="fontService.labelClass" jhiTranslate="vendor.panno" class="form-control-label">PAN No</label>
                                <input type="text" id="panNo" uppercase name="panNo" autocomplete="off" [(ngModel)]="beneficiary.panNo" (input)="validatePan()" class="form-control" maxlength="10" [readonly]="isDelete" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" />
                                <div *ngIf="panError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpan">Please enter a valid PAN
                    Number.</small>
                                </div>
                                <!-- <div *ngIf="panNoUniError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.panNoUniError">{{panNoUniError}}</small>
                                </div> -->
                            </div>
                        </div>
                        <div class="row gy-2 mt-3">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="dashboard.parmenent">
                                    Permenent Address
                                </h6>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for=" doorNo" [ngClass]="fontService.labelClass" jhiTranslate="vendor.buildingno">Building No/Door No</label>
                                <input type="text" id="doorNo" name="doorNo" autocomplete="off" (change)="addresschange('check')" [(ngModel)]="beneficiary.doorNo" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                    style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.doorNo }" class="form-control" [readonly]="isDelete" maxlength="20" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for=" street" [ngClass]="fontService.labelClass" jhiTranslate="vendor.street">Street</label>
                                <input type="text" class="form-control" (change)="addresschange('check')" name="street" id="street" [(ngModel)]="beneficiary.street" autocomplete="off" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" style="text-transform:uppercase" [readonly]="isDelete" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.street }" maxlength="50" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" for=" landmark" [ngClass]="fontService.labelClass" jhiTranslate="vendor.landmark">Landmark</label>
                                <input type="text" class="form-control" autocomplete="off" (change)="addresschange('check')" name="landmark" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                    style="text-transform:uppercase" [(ngModel)]="beneficiary.area" id="landmark" [readonly]="isDelete" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for=" city" [ngClass]="fontService.labelClass" jhiTranslate="vendor.city">City</label>
                                <input type="text" class="form-control" autocomplete="off" name="city" (change)="addresschange('check')" id="city" [(ngModel)]="beneficiary.city" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.city }" maxlength="20" [readonly]="isDelete" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for=" district" [ngClass]="fontService.labelClass" jhiTranslate="vendor.district">District</label>
                                <input type="text" class="form-control" autocomplete="off" (change)="addresschange('check')" name="district" [(ngModel)]="beneficiary.district" id="district" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.district }" maxlength="20" [readonly]="isDelete" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for=" state" [ngClass]="fontService.labelClass" jhiTranslate="vendor.state">State</label>
                                <input type="text" class="form-control" autocomplete="off" (change)="addresschange('check')" name="state" id="state" [(ngModel)]="beneficiary.state" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.state }" maxlength="50" [readonly]="isDelete" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for=" pinCode" [ngClass]="fontService.labelClass" jhiTranslate="vendor.pincode">Pincode</label>
                                <input type="text" class="form-control" autocomplete="off" name="pinCode" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" (change)="addresschange('check')" id="pinCode" [(ngModel)]="beneficiary.pinCode" (input)="validatePin()"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.pinCode }" [readonly]="isDelete" maxlength="6" />
                                <div *ngIf="pinError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpin">Please enter a valid
                    Pincode.</small>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row gy-2 mt-3">
           
            </div> -->
                        <div class="row gy-2 mt-3">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="dashboard.commercial">
                                    Commercial Address
                                </h6>
                            </div>
                            <div class="col-12">
                                <span>
                  <p-checkbox (onChange)="addresschange()" [(ngModel)]="checked" [disabled]="isDelete" binary="true"></p-checkbox>
                  <label style="margin-left: 15px; font-size: 14px" [ngClass]="fontService.labelClass"
                    jhiTranslate="error.sameAs">Same as Permanent Address</label>
                </span>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for=" doorNo" [ngClass]="fontService.labelClass" jhiTranslate="vendor.buildingno">Building No/Door No</label>
                                <input type="text" class="form-control" name="doorNo" autocomplete="off" id="doorNo" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [(ngModel)]="beneficiary.companyDoorNo" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.companyDoorNo }" maxlength="50" [readonly]="isDelete" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.street" for=" street">Street</label>
                                <input type="text" class="form-control" autocomplete="off" name="street" id="street" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [(ngModel)]="beneficiary.companyStreet" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.companyStreet }" (input)="ngSelectInvalid = !beneficiary.companyStreet ? true : false" maxlength="50" [readonly]="isDelete"
                                />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.landmark" for=" landmark">Landmark</label>
                                <input type="text" class="form-control" name="landmark" autocomplete="off" id="landmark" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    maxlength="50" [(ngModel)]="beneficiary.companyArea" [readonly]="isDelete" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.city" for=" city">City</label>
                                <input type="text" class="form-control" name="city" autocomplete="off" id="city" [(ngModel)]="beneficiary.companyCity" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                    style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.companyCity }" maxlength="50" [readonly]="isDelete" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.district" for=" district">District</label>
                                <input type="text" class="form-control" name="district" autocomplete="off" id="district" [(ngModel)]="beneficiary.companyDistrict" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                    style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.companyDistrict }" maxlength="20" [readonly]="isDelete" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.state" for=" state">State</label>
                                <input type="text" class="form-control" name="state" autocomplete="off" [(ngModel)]="beneficiary.companyState" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                    style="text-transform:uppercase" id="state" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.companyState }" maxlength="20" [readonly]="isDelete" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.pincode" for=" pinCode">Pincode</label>
                                <input type="text" class="form-control" name="pinCode" autocomplete="off" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" [(ngModel)]="beneficiary.companyPinCode" id="pinCode" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.companyPinCode }"
                                    maxlength="6" (input)="validateCPin()" [readonly]="isDelete" />
                                <div *ngIf="cPinError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpin">Please enter a valid
                    Pincode.</small>
                                </div>
                            </div>
                        </div>
                        <div class="row gy-2 mt-3">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="agency.account.detail.name">
                                    Vendor Account Details
                                </h6>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat' || currentEnvironment==='production')">
                                <div class="col-sm-6 col-md-4">
                                    <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                                    <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" (change)="getBankBranchList()" [items]="bankList" [disabled]="readonly" [(ngModel)]="beneficiary.bankName"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.bankName }">
                                    </ng-select>

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat' || currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                                <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="bankBranch" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" (search)="getBankBranchList($event)" [items]="bankBranchList" [disabled]="readonly" [(ngModel)]="bankBranch"
                                    (change)="getBankIfsc()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.bankBranch}">
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat' || currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                                <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase
                                    [readonly]="readonly" [(ngModel)]="beneficiary.ifscCode" id="ifsc" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.ifscCode }" maxlength="11" (input)="validateIfsc()" (change)="accountDetailsChanged()" />
                                <div *ngIf="ifscError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                    IFSCode.</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                                <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase
                                    [readonly]="readonly" [(ngModel)]="beneficiary.ifscCode" id="ifsc" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.ifscCode }" maxlength="11" (input)="validateIfsc()" (change)="accountDetailsChanged()" />
                                <div *ngIf="ifscError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                    IFSCode.</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                                <p [ngClass]="fontService.regularClass" class="mb-0">{{beneficiary.bankName}}</p>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                                <p [ngClass]="fontService.regularClass" class="mb-0">{{beneficiary.bankBranch}}</p>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno" for=" accountNo">Account Number</label>
                                <input type="text" class="form-control" name="accountNo" autocomplete="off" maxlength="25" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [readonly]="readonly" [(ngModel)]="beneficiary.accountNumber" id="accountNo" (change)="accountChanged()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.accountNumber }" />
                                <div *ngIf="accountNoUniError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.accountNoUniError">{{accountNoUniError}}</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.account.name" for=" bankAccountName">Bank Account Name As per
                  Passbook</label>
                                <input type="text" class="form-control" name="bankAccountName" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [readonly]="true" [(ngModel)]="beneficiary.bankAccName" id="bankAccountName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !beneficiary.bankAccName }" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.micr" for=" micr">MICR</label>
                                <input type="text" class="form-control" name="micr" autocomplete="off" [readonly]="readonly" [(ngModel)]="beneficiary.micr" maxlength="9" id="micr" />
                            </div>
                            <div class="col-12 justify-content-center">
                                <button pButton pRipple class="p-button-success custom-button" (click)="accountValidation()" styleClass="button" [disabled]="readonly">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.verifyaccount">Verify Account</span>
                </button>
                                <div *ngIf="accountInvalidError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass">{{accountInvalidError}}</small>
                                </div>
                                <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please verify the account</small>
                                </div>
                            </div>

                            <!-- </div>
            <div class="row mt-3"> -->


                        </div>
                        <!-- </div>
            <div class="row mt-3"> -->

                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span>
      </button>
            <button *ngIf="isDelete" pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="deleteVendor()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.delete"></span>
      </button>
            <button *ngIf="!beneficiary.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveBeneficiary()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
      </button>
            <button *ngIf="isApprove" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="approveVendor(true)">
        <span class="button-label" [ngClass]="fontService.buttonClass">Approve </span> </button>
            <button *ngIf="beneficiary.id && !isDelete && !isApprove" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveBeneficiary()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
      </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog #cd [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <h3 [ngClass]="fontService.headingClass" jhiTranslate="common.confirmation">Confirmation</h3>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.yes"></span>
        </button>
            <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cd.reject()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.no"></span>
        </button>
        </ng-template>
    </p-confirmDialog>
</div>