import { LevelMaster, LevelMasterDto } from 'src/app/module/level-master/level-master';
import { LevelType } from 'src/app/module/level-type/level-type';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import {
  PersonalVendors,
  PersonalVendorsDto,
} from 'src/app/module/personal-vendors/personal-vendors';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { DepartmentList } from './../../module/department-list/department-list';
import { EmployeeDTO, EmployeeTypeDTO, SubEmployeeTypeDTO } from 'src/app/module/employee/employee.model';
import { ElectedRepresentativeDto } from 'src/app/module/elected-representative/elected-represenative.model';
import { AgencyDto } from 'src/app/module/agency/agency';
import { CommercialVendorsDto } from 'src/app/module/commercial-vendors/commercial-vendors';
import { ComponentDto } from 'src/app/module/component-type/component-type';
import { FinYearDto } from 'src/app/shared/common.model';
import { TangedcoDescDto } from 'src/app/tneb/tangedco-bill-details/tangedco-bill-details';
import { UniversalVendor } from 'src/app/module/universal-vendor/universal-vendor.model';
import { twadOffice } from 'src/app/twad/twad-master-list/twad.model';

export class paymentVoucherGen {
  voucherBillDescDtoList: VoucherBillDescDto[];
  personalDescDtoList: PersonalDescDto[];
  vendorTransactionDto: vendorTransactionDto;
  beneficiaryDescDtoList: beneficiaryDescDto[];
  thooimaiKavalarDescDtoList:beneficiaryDescDto[];

  voucherBillDescEmployeeList:VoucherBillDescEmployeeList[];
  voucherBillDescElectedRepList:VoucherBillDescelectedRepDto[];
  voucherBillDescEmployeeBulkList:VoucherBillDescEmployeeBulkList[];
  voucherBillDescelectedRepBulkList:VoucherBillDescelectedRepBulkDto[];
  voucherBillDescTangedcoList: TangedcoDescDto[];
  voucherBillDescTwadList: VoucherBillDescTwad[];
}
export class SwappingTransactionDto{
  swapSubTableDto:SwapSubTableDto[];
  swapTransactionDto:SwapTransactionDto;
}

export class EmuthraResponseDto {
  returnStatus: string;
  errorMessage: string;
  returnvalue: string;
  transactionnumber: string;
  fileType: string;
  referencenumber: string;
  isCompressed: boolean;
}

export class VoucherBillDescDto {
  id: number;
  description: string;
  vendorTransactionDto: number;
  hsnsac: string;
  quantity: number;
  gstPercentage: number;
  gstAmount: number;
  sgstPercentage: number;
  sgstAmount: number;
  cgstPercentage: number;
  cgstAmount: number;
  igstPercentage: number;
  igstAmount: number;
  amount: number;
  grossAmount: number;
  activeFlag: string;
  createdOn: string; // Or use the Date type if appropriate
}

export class ComponentMasterDto {
  id: number;
  name: string;
  code: string;
  parentId: number;
  componentTypeDto: ComponentTypeDto;
  createdOn: Date;
  updatedOn: Date;
  componentOne: number;
  componentType: number;
  levelTypeDto: LevelTypeDto;
  componentTwo: number;
  componentThree: number;
  componentFour: number;
  componentFive: number;
  adminUserDtoCreatedby: AdminUserDtoCreatedby;
  adminUserDtoUpdatedBy: AdminUserDtoCreatedby;
  componentCode: any;
}

export class VoucherGstList {
  id: number;
  title: string;
  hsnSac: string;
  amount: number;
  quantity: number;
  gstPercetage: number;
  sgstPercetage: number;
  cgstPercetage: number;
  gstAmount: number;
  sgstAmount: number;
  cgstAmount: number;
  igstAmount: number;
  igstPercetage: number;
  grossTotal: number;
}

export class VoucherDto {
  currentDateAsString: string;
  voucherNumber: string;
  gstApplicable: string;
  tdsApplicable: string;
  tds: number;
  tdsAmount: number = 0;
  quantity: number;
  gstOnTds: number;
  gstOnTdsAmount: number;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceImage: string;
  natureOfWork: string;
  total: number = 0;
  totalWithTax: number = 0;
  totalSgst: number = 0;
  totalCgst: number = 0;
  totalIgst: number;
}

export class beneficiaryDescDto {
  id: number;
  quantity: number;
  amount: number;
  grossAmount: number;
  activeFlag: string;
  createdOn: string;
  descriptionDto: string;
}
export class CommercialVendorDto {
  id: number;
  name: string;
  companyName: string;
  aadhaarNo: string;
  registrationNo: string;
  micr: any;
  panNo: string;
  gstNo: string;
  mobile: string;
  email: string;
  commercialDto: CommercialDto;
  tanNo: any;
  tinNo: any;
  state: string;
  district: string;
  city: string;
  doorNo: string;
  street: string;
  area: any;
  pinCode: string;
  companyState: string;
  companyDistrict: string;
  companyCity: string;
  companyDoorNo: string;
  companyStreet: string;
  companyArea: any;
  companyPinCode: string;
  bankAccName: any;
  bankId: string;
  bankName: string;
  bankBranch: string;
  accountNumber: string;
  activeFlag: string;
  ifscCode: string;
  createdOn: string;
  updatedOn: string;
  adminUserDtoCreatedby: AdminUserDtoCreatedby;
  adminUserDtoUpdatedBy: AdminUserDtoUpdatedBy;
  departmentDto: DepartmentDto;
  schemeDto: SchemeDto;
  maskedVendorName: any;
  maskedName: any;
  maskedCompanyName: any;
  maskedPanNo: any;
  maskedGstNo: any;
  maskedMobile: any;
  maskedEmail: any;
  maskedBankName: any;
  maskedAccountNumber: any;
  maskedAadhaarNo: any;
  check: any;
}

export class CommercialDto {
  id: number;
  name: string;
}

export class AdminUserDtoCreatedby {
  id: number;
  userName: string;
  password: string;
  mobile: any;
  email: any;
  activeFlag: string;
  userActiveFlag: any;
  resetPassword: string;
  loginAttempt: number;
  roleDto: RoleDto;
  resetDate: any;
  createdOn: string;
  updatedOn: string;
  createdBy: number;
  updatedBy: number;
  schemeName: any;
  departmentDto: any;
  schemeDto: any;
  implementingAgencyDto: any;
  profileStatus: string;
  
}

export class RoleDto {
  id: number;
  code: string;
  name: string;
  createdOn: string;
  updatedOn: string;
}

export class AdminUserDtoUpdatedBy {
  id: number;
  userName: string;
  password: string;
  mobile: any;
  email: any;
  maskedMobile: any;
  maskedEmail: any;
  maskedPassword: any;
  activeFlag: string;
  userActiveFlag: any;
  resetPassword: string;
  loginAttempt: number;
  roleDto: RoleDto2;
  resetDate: any;
  createdOn: string;
  updatedOn: string;
  createdBy: number;
  updatedBy: number;
  captchaText: any;
  schemeName: any;
  email1: any;
  fromPage: any;
  departmentDto: any;
  schemeDto: any;
  implementingAgencyDto: any;
  profileStatus: string;
  levelOne: any;
  levelTwo: any;
  levelThree: any;
  levelFour: any;
  levelFive: any;
}

export class RoleDto2 {
  id: number;
  code: string;
  name: string;
  createdOn: string;
  updatedOn: string;
}

export class DepartmentDto {
  id: number;
  name: string;
  shortName: string;
  code: string;
  branch: string;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
}

export class SchemeDto {
  id: number;
  name: string;
  shortName: string;
  code: string;
  address: string;
  schemeDesc: string;
  departmentDto: DepartmentDto2;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
}

export class DepartmentDto2 {
  id: number;
  name: string;
  shortName: string;
  code: string;
  branch: string;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
}

export class ImplementingAgencyDto {
  id: number;
  name: string;
  mobile: any;
  email: any;
  departmentDto: DepartmentDto3;
  schemeDto: SchemeDto2;
  zonalName: any;
  leveltype: number;
  gstNo: string;
  designation: any;
  levelMaster: number;
  mapping: string;
  createdOn: string;
  updatedOn: string;
  adminUserDtoCreatedby: AdminUserDtoCreatedby;
  adminUserDtoUpdatedBy: AdminUserDtoCreatedby;
  bankAccName: any;
  bankId: any;
  accountNumber: any;
  ifscCode: any;
  branchName: any;
  branchMobNo: any;
  branchEmail: any;
  address: any;
  activeFlag: string;
  state: any;
  district: any;
  block: any;
  editImplementingAgency: boolean;
}

export class DepartmentDto3 {
  id: number;
  name: string;
  shortName: string;
  code: string;
  branch: string;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
}

export class SchemeDto2 {
  id: number;
  name: string;
  shortName: string;
  code: string;
  address: string;
  schemeDesc: string;
  departmentDto: DepartmentDto4;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
}

export class DepartmentDto4 {
  id: number;
  name: string;
  shortName: string;
  code: string;
  branch: string;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
}



export interface RoleDto3 {
  id: number;
  code: string;
  name: string;
  createdOn: string;
  updatedOn: string;
}

export interface AdminUserDtoUpdatedBy2 {
  id: number;
  userName: string;
  password: string;
  mobile: any;
  email: any;
  maskedMobile: any;
  maskedEmail: any;
  maskedPassword: any;
  activeFlag: string;
  userActiveFlag: any;
  resetPassword: string;
  loginAttempt: number;
  roleDto: RoleDto4;
  resetDate: any;
  createdOn: string;
  updatedOn: string;
  createdBy: number;
  updatedBy: number;
  captchaText: any;
  schemeName: any;
  email1: any;
  fromPage: any;
  departmentDto: any;
  schemeDto: any;
  implementingAgencyDto: any;
  profileStatus: string;
  levelOne: any;
  levelTwo: any;
  levelThree: any;
  levelFour: any;
  levelFive: any;
}

export interface RoleDto4 {
  id: number;
  code: string;
  name: string;
  createdOn: string;
  updatedOn: string;
}

export class ComponentTypeDto {
  id: number;
  name: string;
  levelTypeDto: LevelTypeDto;
  parentId: number;
  createdOn: string;
  updatedOn: string;
  adminUserDtoCreatedby: AdminUserDtoCreatedby;
  adminUserDtoUpdatedBy: AdminUserDtoCreatedby;
}

export interface LevelTypeDto {
  id: number;
  name: string;
  departmentDto: DepartmentDto5;
  schemeDto: SchemeDto3;
  createdOn: string;
  updatedOn: string;
  adminUserDtoCreatedBy: number;
  adminUserDtoUpdatedBy: number;
}

export interface DepartmentDto5 {
  id: number;
  name: string;
  shortName: string;
  code: string;
  branch: string;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
}

export interface SchemeDto3 {
  id: number;
  name: string;
  shortName: string;
  code: string;
  address: string;
  schemeDesc: string;
  departmentDto: DepartmentDto6;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
}

export interface DepartmentDto6 {
  id: number;
  name: string;
  shortName: string;
  code: string;
  branch: string;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
}



export interface RoleDto5 {
  id: number;
  code: string;
  name: string;
  createdOn: string;
  updatedOn: string;
}

export interface AdminUserDtoUpdatedBy3 {
  id: number;
  userName: string;
  password: string;
  mobile: any;
  email: any;
  maskedMobile: any;
  maskedEmail: any;
  maskedPassword: any;
  activeFlag: string;
  userActiveFlag: any;
  resetPassword: string;
  loginAttempt: number;
  roleDto: RoleDto6;
  resetDate: any;
  createdOn: string;
  updatedOn: string;
  createdBy: number;
  updatedBy: number;
  captchaText: any;
  schemeName: any;
  email1: any;
  fromPage: any;
  departmentDto: any;
  schemeDto: any;
  implementingAgencyDto: any;
  profileStatus: string;
  levelOne: any;
  levelTwo: any;
  levelThree: any;
  levelFour: any;
  levelFive: any;
}

export class VoucherBillDescEmployeeList {
  voucherBillDescEmployeeId: number;
  employeeDto: EmployeeDTO;
  name: string;
  grade: string;
  gpfCpsNo: number;
  escNet: number;
  bankAccName: string;
  bankName: string;
  voucherNo: string;
  bankBranch: string;
  ifscCode: string;
  accountNumber: string;
  descriptionDto: DescriptionDto;
  quantity: number;
  amount: number;
  grossAmount: number;
}

export class VoucherBillDescEmployeeBulkList {
  voucherBillDescEmployeeBulkId: number;
  employeeId: number;
  name: string;
  bankAccName: string;
  bankName: string;
  voucherNo: string;
  bankBranch: string;
  ifscCode: string;
  accountNumber: string;
  description: string;
  month:string;
  quantity: number;
  amount: number;
  grossAmount: number;
  designation:string;
}

export class VoucherBillDescelectedRepBulkDto {
  voucherBillDescElectedRepBulkId: number;
  electedRepId:number;
  name: string;
  post: string;
  wardNo: number;
  allowance: number;
  bankAccName: string;
  bankName: string;
  voucherNo: string;
  bankBranch: string;
  ifscCode: string;
  accountNumber: string;
  descriptionDto: DescriptionDto;
  quantity: number;
  amount: number;
  month:string;
  finYearDto:FinYearDto[];
  fixedTravellingAllowance:number;
  honorariumAllowance:number;
  sittingChargesAllowance:number;
  others:number;
  grossAmount: number;
  finYear:string;
  activeFlag: string;
  createdOn: Date;
}

export class VoucherBillDescelectedRepDto {
  voucherBillDescEmployeeId: number;
  electedRepDto:ElectedRepresentativeDto;
  name: string;
  post: string;
  wardNo: number;
  allowance: number;
  bankAccName: string;
  bankName: string;
  voucherNo: string;
  bankBranch: string;
  ifscCode: string;
  accountNumber: string;
  descriptionDto: DescriptionDto;
  quantity: number;
  amount: number;
  month:string;
  finYearDto:FinYearDto;
  grossAmount: number;
  activeFlag: string;
  createdOn: Date;
}

export interface RoleDto6 {
  id: number;
  code: string;
  name: string;
  createdOn: string;
  updatedOn: string;
}

export interface AdminUserDtoCreatedby4 {
  id: number;
  userName: string;
  password: string;
  mobile: any;
  email: any;
  maskedMobile: any;
  maskedEmail: any;
  maskedPassword: any;
  activeFlag: string;
  userActiveFlag: any;
  resetPassword: string;
  loginAttempt: number;
  roleDto: RoleDto7;
  resetDate: any;
  createdOn: string;
  updatedOn: string;
  createdBy: number;
  updatedBy: number;
  captchaText: any;
  schemeName: any;
  email1: any;
  fromPage: any;
  departmentDto: any;
  schemeDto: any;
  implementingAgencyDto: any;
  profileStatus: string;
  levelOne: any;
  levelTwo: any;
  levelThree: any;
  levelFour: any;
  levelFive: any;
}

export interface RoleDto7 {
  id: number;
  code: string;
  name: string;
  createdOn: string;
  updatedOn: string;
}



export interface RoleDto8 {
  id: number;
  code: string;
  name: string;
  createdOn: string;
  updatedOn: string;
}

export class MediaFile {
  fileName?: string;
  filePath?: string;
  fileExtension?: string;
  serverPath?: string;
  inputStream?: string;
  size: number;
  fileType?: string;
  content?: string;
}

export class BulkPaymentResDto{
  voucherNo:string;
  employeeName:string;
  transactionAmount:number;
  transactionStatus:string;
  transactionDate:Date;
  journalNumber:string;
  utrNo:string;
}

export class VendorTransaction {
  id: number;
  department: DepartmentList;
  scheme: SchemeList;
  implementingAgency: AgencyDto;
  commercialVendor: any;
  levelMasterId: number;
  commercialVendorName: string;
  commercialCompanyName: string;
  commercialPanNo: string;
  commercialGst: string;
  commercialAccountNo: string;
  commercialDoorNo: string;
  commercialStreet: string;
  commercialPinCode: string;
  personalVendor: any;
  personalVendorName: any;
  personalDesignation: any;
  personalStaffId: any;
  personalPanNo: any;
  personalAccountNo: any;
  personalDoorNo: any;
  personalStreet: any;
  personalPincode: any;
  sna: Sna;
  snaAccountNo: string;
  componentType: ComponentTypeDto;
  levelType: LevelTypeDto;
  transDate: string;
  majorComponentName: string;
  minorComponentName: string;
  subComponentName: string;
  nameOfWork: string;
  workId: any;
  quality: any;
  rate: any;
  transactionId: any;
  signatureSecretary: any;
  signatureVicePresident: any;
  signaturePresident: any;
  qrCode: any;
  grossAmount: number;
  netAmount: number;
  transactionAmount: number;
  batchNo: string;
  status: string;
  count: number;
  remark: any;
  voucherNo: string;
  tdsPercentage: any;
  tdsAmount: any;
  gstOnTds: number;
  gstOnTdsAmount: any;
  labourCess: number;
  securityDeposit: number;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
  personalFlag:string;
  transactionStatus:string;
  utrNo:string;
  paymentDate:string;
  rejectionReason:string;
  edit: boolean;
  delete: boolean;
  isDefault: boolean;
  parentVoucherNumber:string;
  commercialIfscode:string;
  personalIfscode:string;
  employeeId: any;
  employeeName: any;
  employeeAccountNumber: any;
  employeeIFSCCode: any;
}
export class VendorFailedTransaction {
  id: number;
  department: DepartmentList;
  scheme: SchemeList;
  implementingAgency: AgencyDto;
  commercialVendor: any;
  levelMasterId: number;
  commercialVendorName: string;
  commercialCompanyName: string;
  commercialPanNo: string;
  commercialGst: string;
  commercialAccountNo: string;
  commercialDoorNo: string;
  commercialStreet: string;
  commercialPinCode: string;
  personalVendor: any;
  personalVendorName: any;
  personalDesignation: any;
  personalStaffId: any;
  personalPanNo: any;
  personalAccountNo: any;
  personalDoorNo: any;
  personalStreet: any;
  personalPincode: any;
  sna: Sna;
  snaAccountNo: string;
  componentType: ComponentTypeDto;
  levelType: LevelTypeDto;
  transDate: string;
  majorComponentName: string;
  minorComponentName: string;
  subComponentName: string;
  nameOfWork: string;
  workId: any;
  quality: any;
  rate: any;
  transactionId: any;
  signatureSecretary: any;
  signatureVicePresident: any;
  signaturePresident: any;
  qrCode: any;
  grossAmount: number;
  netAmount: number;
  transactionAmount: number;
  batchNo: string;
  status: string;
  count: number;
  remark: any;
  voucherNo: string;
  tdsPercentage: any;
  tdsAmount: any;
  gstOnTds: number;
  gstOnTdsAmount: any;
  labourCess: number;
  securityDeposit: number;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
  personalFlag:string;
  transactionStatus:string;
  utrNo:string;
  paymentDate:string;
  rejectionReason:string;
  checkTransactionStatus:boolean;
  showReject:boolean;
}

export class vendorTransactionDto {
  id: number;
  departmentDto: DepartmentDto;
  schemeDto: SchemeDto;
  implementingAgencyDto:AgencyDto;
  levelMasterId: number;
  snaAccountNo: string;
  commercialVendorDto: CommercialVendorsDto;
  commercialVendorName: string;
  commercialCompanyName: string;
  commercialPanNo: string;
  commercialGst: string;
  commercialAccountNo: string;
  commercialDoorNo: string;
  commercialStreet: string;
  commercialPinCode: string;
  personalVendorDto: PersonalVendorsDto;
  personalVendorName: string;
  personalDesignation: string;
  personalStaffId: string;
  personalPanNo: string;
  personalAccountNo: string;
  personalDoorNo: string;
  personalStreet: string;
  levelMasterName: string;
  commercialIfscode: string;
  personalIfscode: string;
  personalPincode: string;
  nhisPercentage?: number;
  cpsPercentage?: number;
  otherDeductionItPercentage?: number;
  panchayatResolutionNumber: string;
  levelTypeId: number;
  componentTypeDto: ComponentDto;
  majorComponentId: number;
  minorComponentId: number;
  subComponentId: number;
  commercialVendor: number;
  personalVendor: number;
  iaComponentLimitDto: limitDto;
  transDate: Date;
  nhis?: number;
  cps?: number;
  otherDeductionIt?: number;
  invoiceNo: string;
  invoiceDate: Date;
  invoiceImage: string;
  personalFlag: string;
  activeFlag: string;
  majorComponentName: string;
  minorComponentName: string;
  subComponentName: string;
  nameOfWork: string;
  workId: string;
  quality: number;
  signStatus:boolean
  description: string;
  rate: number;
  totlaInRupees?: number;
  transactionId: string;
  signatureSecretary: string;
  signatureVicePresident: string;
  signaturePresident: string;
  qrCode: string;
  grossAmount?: number;
  netAmount?: number;
  transactionAmount?: number;
  batchNo: string;
  status: string;
  count: number;
  remark: string;
  voucherNo: string;
  tdsPercentage?: number;
  tdsAmount?: number;
  gstOnTds?: number;
  gstOnTdsAmount?: number;
  labourCess?: number;
  labourCessPercentage?: number;
  securityDeposit?: number;
  securityDepositAmount?: number;
  createdOn: Date;
  updatedOn: Date;
  adminUserDtoCreatedBy: number;
  adminUserDtoUpdatedBy?: number;
  approvelevelOneVendorTransaction?: boolean;
  approvelevelTwoVendorTransaction?: boolean;
  rejectVendorTransaction?: boolean;
  fundGrant: string;
  bankName: string;
  invoiceAmount: number;
  totalSgst: number = 0;
  totalCgst: number = 0;
  totalIgst: number;
  tds: boolean;
  tdsApplicable: any;
  transactionStatus: string;
  utrNo: string;
  paymentDate: Date;
  rejectionReason: string;
  zonalChecker:number=0;
  revertStatus:boolean;
  finYearDto: any;
  universalVendor:UniversalVendor;
  twadOffice:twadOffice;
  flagDay:number=0;
  beneficiaryCode:string;
  asDocumentImage: string;
  bdoPassOrderImage: string;
  panchayatVoucherImage: string;
  // parentVoucherNumber:number;
}
export class SwapIntermediateSubTableDto{
  id:number;
  levelTypeDto:any;
  BlockLevelDto:any;
  villageLevelDto:any;
  grantLevelDto:any;
	limitAmount:number;
  levelType:any;
  blockLevelList:any;
  villageLevelList:any;
  grantLevelList:any;
}
export class SwapSubTableDto{
  id:number;
	levelMasterId:number;
	levelId:number;
	componentMaster:number;
	implementingAgency:number;
	voucherNumber:string;
	swapTransactionId:number;
	limitAmount:number;
	levelMasterName:string;
	levelName:string;
	componentMasterName:string;
	activeFlag:string;
	createdOn:Date;
}
export class SwapTransactionDto {
	id:number;
	department:number;
	scheme:number;
	schemeName:string;
	implementingAgency:number;
	implementingAgencyName:string;
	levelId:number;
	levelMasterId:number;
	levelMasterName:string;
	iaComponentLimitDto:limitDto;
	componentType:number;
	majorComponentId:number;
	transDate:Date;
	majorComponentName:string;
	description:string;
	rate:number;
	totalInRupees:number;
	transactionId:string;
	signatureSecretary:string;
	signatureVicePresident:string;
	signaturePresident:string;
	signatureVp:string;
	signatureP:string;
	qrCode:string;
	signStatus:boolean;
	revertStatus:boolean;
	transactionAmount:number;
	batchNo:string;
	status:string;
	countId:number;
	panchayatResolutionNumber:string;
	remark:string;
	voucherNumber:string;
	activeFlag:string;
	voucherNo:string;
	fundGrant:string;
	createdOn:Date;
	updatedOn:Date;
	adminUserDtoCreatedby:number;
	adminUserDtoUpdatedBy:number;
}
export interface Sna {
  id: number;
  name: string;
  shortName: string;
  code: string;
  accountNumber: string;
  ifscCode: string;
  branch: string;
  address: string;
  amount: any;
  snaDesc: any;
  managerMobNo: string;
  managerName: string;
  branchEmail: string;
  departmentBean: DepartmentBean;
  schemeBean: SchemeBean;
  createdOn: string;
  updatedOn: string;
  adminUserBeanCreatedBy: AdminUserDtoCreatedby;
  adminUserBeanUpdatedBy: AdminUserDtoUpdatedBy;
}

export interface DepartmentBean {
  id: number;
  name: string;
  shortName: string;
  code: string;
  branch: string;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
}

export interface SchemeBean {
  id: number;
  name: string;
  shortName: string;
  code: string;
  address: string;
  schemeDesc: string;
  department: DepartmentDto;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: number;
  adminUserUpdatedBy: number;
}

export class PersonalDescDto {
  id: number;
  quantity: number;
  amount: number;
  grossAmount: number;
  activeFlag: string;
  createdOn: string;
  descriptionDto: string;
}

export class DescriptionDto {
  id: number;
  name: string;
}

export class MyFiles {
  file1: string;
  file2: string;
  file3: string;
  file4: string;
}

export class limitDto {
  id: number;
  departmentDto: DepartmentList;
  schemeDto: SchemeList;
  levelTypeDto: LevelType;
  levelMasterDto: LevelMasterDto;
  implementingAgencyDto: AgencyDto;
  finYearDto: FinYear;
  componentTypeDto: ComponentTypeDto;
  sanctionAmount: number;
  realizedLimitAmount:number;
  utilizedAmount: number;
  createdOn: string;
  updatedOn: string;
  adminUserCreatedBy: AdminUserDtoCreatedby;
  adminUserUpdatedBy: any;
}

export class EmudhraRequest {
  param1: string;
  param2: string;
  param3: string;
}

export class EmployeeBulkDTO {
  id: number;
  employeeType: EmployeeTypeDTO;
  subEmployeeType: SubEmployeeTypeDTO;
  levelId: number;
  levelMasterId: number;
  name: string;
  grade: string;
  gpfCpsNo: number;
  dutyPay: number;
  dearnessAllowance: number;
  houseRentAllowance: number;
  medicalAllowance: number;
  generalProvidentFund: number;
  newHealthInsurence: number;
  familyBenefitFund: string;
  nhisCorpusFund: number;
  specialProvidentFund: number;
  cca:number;
  otherAllowances:number;
  otherDeductions:number;
  grossAmount: number;
  totalDeduction: number;
  netAmount: number;
  escNet: number;
  consolidatePay: number;
  adhocIncrease: number;
  additionalAllowance: number;
  powerPumpOperatorAllowance: number;
  createdOn: string;
  micr: any;
  bankAccName: any;
  bankId: any;
  bankName: string;
  bankBranch: string;
  accountNumber: string;
  ifscCode: string;
  updatedOn: string;
  activeFlag: string;
}

export class ParentVoucher {
  id: number;
  levelMaster: LevelMaster;
  implementingAgency: any;
  voucherNumber: string;
  transactionDate: Date;
  createdOn: Date;
  updatedOn: Date;
  createdBy: number;
  updatedBy: number;
  activeFlag: string;
  paymentMethod: string;
  signStatus: boolean;
  signMessage: string;
  signaturePresident: string;
  componentMasterId: number;
  finyear:any;
}

export class ParentVoucherBillDesc {
  id: number;
  parentVoucher: ParentVoucher;
  vendorTransactionId: number;
  netAmount: number;
  transactionAmount: number;
  childVoucherNumber: string;
  workId: string;
  workName: string;
  vendorName: string;
  vendorId: number;
  vendorAccountNumber: string;
  vendorIFSCCode: string;
  createdOn: Date;
  updatedOn: Date;
  createdBy: number;
  updatedBy: number;
  activeFlag: string;
  voucherType: string;
  personalFlag: string;
  componentMasterId: number;
}

export class VoucherBillDescTwad {
  id: number;
  vendorTransaction: VendorTransaction;
  title: string;
  dueAmount: number;
  billAmount: number;
  activeFlag: string;
  voucherNumber: string;
  createdOn: Date;
}

export class VendorTransactionBulkDto {
  parentVoucher: ParentVoucher;
  parentVoucherBillDescList: ParentVoucherBillDesc[];
  edit: boolean;
}
