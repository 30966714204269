<div class="row">
    <div class="col-md-12">
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <h5>
                    Account Statement
                </h5>
            </ng-template>
        </p-toolbar>
    </div>

    <div class="row px-4 mt-2">
        <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE'" class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">District</label>
            <ng-select id="scheme" [(ngModel)]="districtMaster" [items]="districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE' || sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT'" class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Block</label>
            <ng-select id="scheme" [(ngModel)]="blockMaster" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE' && sessionStorage.retrieve('roleCode') !== 'ZVM'" class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="scheme" [items]="villageLevelList" bindLabel="name" bindValue="id" appearance="outline" (search)="onBlockLevelChange($event)" [(ngModel)]="levelMasId" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE' && sessionStorage.retrieve('roleCode') === 'ZVM'" class="col-lg-2 col-md-4 col-sm-3">
            <label class="form-control-label req" for="Village" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="gender" bindLabel="levelMasterName" bindValue="levelMasterId" [items]="villageList" appearance="outline" [(ngModel)]="levelMasId" [closeOnSelect]="true" [clearable]="false" class="form-control">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE'" class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-success custom-button" (click)="search()" styleClass="button">
      <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.searchbutton">Search</span>
    </button>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE'" class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-danger custom-button">
      <span class="button-label" [ngClass]="fontService.buttonClass" (click)="isZonal = false;districtMaster = null; blockMaster = null; levelMasId = null;" jhiTranslate="common.clearbutton">clear</span>
    </button>
        </div>
    </div>
    <div *ngIf="isZonal" class="btn_class">

        <button pButton pRipple class="fetch_btn mx-2" [disabled]="fetchbtnDisable" (click)="getAccountStatementData()">Fetch Latest Statement</button>
        <button *ngIf="isTable" pButton pRipple class="download_btn mx-2" (click)="opendownloadPdf()">Download PDF</button>

        <button *ngIf="isTable" pButton pRipple class="download_btn mx-2" (click)="opendownloadExcel()">Download Excel</button>
    </div>
</div>
<div *ngIf="showStatus && isZonal" class="container-fluid pb-3">
    <div class="status_card">
        <div class="row">
            <h1>{{this.status}}</h1>
        </div>

    </div>
</div>

<div *ngIf="isTable && isZonal" class="flex_display mt-2">
    <div class="mx-2">
        <label class="req">From Date</label><br>
        <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="fromDate" [showIcon]="true" dateFormat="dd/mm/yy" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
    </div>
    <div class="mx-2 ">
        <label class="req">To Date</label><br>
        <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="toDate" [showIcon]="true" dateFormat="dd/mm/yy" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
    </div>

    <div class="btn_class">
        <button pButton pRipple class="search_btn mx-2" (click)="filterData()">Search</button>
        <button pButton pRipple class="clear_btn mx-2" (click)="clearTable()">Clear </button>
    </div>
</div>
<div *ngIf="isTable && isZonal" class="row mt-2">
    <div>
        <p *ngIf="accountBalance" class="balance_field">The available balance in this account : {{accountBalance}}</p>
    </div>
    <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false" [enableAction]="false"></custom-filter-table>
</div>
<p-dialog #dialog [(visible)]="isDownlaodPdf" [modal]="true" styleClass="ib-dialog" [style]="{ width: '650px' ,height:'600px' }" (onHide)="hideDialog()">
    <p class="heading_font">Select the From Date and ToDate to download the pdf</p>
    <div class="flex_display">
        <div class="mx-2">
            <label class="req">From Date</label><br>
            <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" dateFormat="dd/mm/yy" [(ngModel)]="downloadfromDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        </div>
        <div class="mx-4">
            <label class="req">To Date</label><br>
            <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" dateFormat="dd/mm/yy" [(ngModel)]="downloadtoDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="btn_part">
            <button pButton pRipple class="mx-2" (click)="hideDialog()">Cancel</button>
            <button pButton pRipple class="mx-2" (click)="downloadPdfData()">Proceed</button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog #dialog [(visible)]="isDownlaodExcel" [modal]="true" styleClass="ib-dialog" [style]="{ width: '650px' ,height:'600px' }" (onHide)="hideDialog()">
    <p class="heading_font">Select the From Date and ToDate to download the excel</p>
    <div class="flex_display">
        <div class="mx-2">
            <label class="req">From Date</label><br>
            <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="downloadfromDate" [showIcon]="true" styleClass="datepicker" dateFormat="dd/mm/yy" [maxDate]="maxDate"></p-calendar>
        </div>
        <div class="mx-4">
            <label class="req">To Date</label><br>
            <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="downloadtoDate" [showIcon]="true" styleClass="datepicker" dateFormat="dd/mm/yy" [maxDate]="maxDate"></p-calendar>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <div class="btn_part">
            <button pButton pRipple class="mx-2" (click)="hideDialog()">Cancel</button>
            <button pButton pRipple class="mx-2" (click)="excelData()">Proceed</button>
        </div>
    </ng-template>

</p-dialog>