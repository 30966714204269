export class VoucherFlagging {
    id?: number;
    vendorTransactionId?: number;
    voucherNo?: string;
    issueType?: string;
    description?: string;
    createdOn?: Date;
    createdBy?: number;
    updatedOn?: Date;
    updatedBy?: number;
    lgdCode?: string;
    districtName?: string;
    districtId?: number;
    blockName?: string;
    blockId?: number;
    villageName?: string;
    levelMasterId?: number;
    paymentCategory?: string;
    amount?: number;
    activeFlag?: string;
    paymentMethod?:string;
    status: string;
    deductionTransactionId:number;
    deductionType:string;
}

export class SubVoucherFlagging {
    id?: number;
    voucherFlaggingId?: number;
    issueType?: string;
    description?: string;
    name?: string;
    amount?: number;
    createdOn?: Date;
    updatedOn?: Date;
    activeFlag?: string;
    consumerNo?:string;
    issueTypeList: string[];
    isSelect:boolean
}

export class FlaggingDto {
    voucherFlagging?: VoucherFlagging;
    flaggingList?: SubVoucherFlagging[];
}