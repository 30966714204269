import { PersonalDescDto, VendorTransaction, paymentVoucherGen, vendorTransactionDto } from './../../payments/payment-voucher/payment-voucher.model';
import { ConsolidatedBills, TangedcoDescDto } from './../tangedco-bill-details/tangedco-bill-details';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Dialog } from 'primeng/dialog';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { DepartmentList } from 'src/app/module/department-list/department-list';
import { Employee, EmployeeDTO } from 'src/app/module/employee/employee.model';
import { LevelType } from 'src/app/module/level-type/level-type';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { SchemeListService } from 'src/app/module/scheme-list/scheme-list.service';
import { PersonalVendorService } from 'src/app/module/personal-vendors/personal-vendors.service';
import { DepartmentListService } from 'src/app/module/department-list/department-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { EmployeeService } from 'src/app/module/employee/employee.service';
import { UserService } from 'src/app/module/user/user.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ComponentType } from 'src/app/module/component-type/component-type';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { PersonalVendorsDto } from 'src/app/module/personal-vendors/personal-vendors';
import { Vendor } from 'src/app/module/commercial-vendors/commercial-vendors';
import { SearchResult } from 'src/app/shared/models/search-result';
import { filterQuery } from 'src/app/shared/common.model';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { UniversalVendorService } from 'src/app/module/universal-vendor/universal-vendor.service';
import { UniversalVendor } from 'src/app/module/universal-vendor/universal-vendor.model';
import { Table } from 'primeng/table';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-tangedco-payment-voucher',
  templateUrl: './tangedco-payment-voucher.component.html',
  styleUrls: ['./tangedco-payment-voucher.component.scss']
})
export class TangedcoPaymentVoucherComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;

  enableStateGst: boolean;
  enableIGst: boolean;
  departmentList: DepartmentList[];
  totalDeduction: number;

  // validation fields properties
  public ifscError = false;

  //required fields property
  ngSelectInvalid: boolean = false;
  dropDownLabel: string;
  enableNhis: boolean;
  enableCps: boolean;
  enableOtherDeduction: boolean;
  roleCode: any;
  readonly: boolean;
  enableRevertButton: boolean;
  description: string;
  amount: number;
  quantity: number;
  signButtonName: string;
  downloadButton: boolean;
  param1: any;
  param2: any;
  param3: any;
  enableSignButton: boolean;
  employeeList: Employee;
  enableEmployee: boolean;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  enableRePaymentButton: boolean;
  disablepaymentButton: boolean;
  buttonText: string;
  interactionId: any;
  checkstatusButton: boolean;
  checkAmount: boolean;
  zonalList: LevelType;
  selectedZonalUser: any
  enableZonalButton: boolean;
  enableApproved: boolean;
  checkForPaymentStatus: boolean;
  tangedcoBillReqCompId: number;
  finYearList: FinYear[];
  finYear: FinYear;
  tangedcoConsumerList: any;
  showConsumerDetails: boolean = false;
  activeIndex: number = 0;
  selectedConsumer: any;
  totalBillAmount: number;
  enableView: boolean;
  viewPayList: boolean;
  bulkPaymentListDialog: boolean;
  bulkPaySearchResult: SearchResult<any> =
    new SearchResult<any>();
  redirectPage: boolean = false;
  disableApprovedButton: boolean = false;
  isCheckActiveFlag: boolean;
  componentMasterTable: any;



  constructor(
    protected activatedRoute: ActivatedRoute,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private commercialVendorService: CommercialVendorService,
    private paymentService: PaymentService,
    private personalVendorService: PersonalVendorService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private employeeService: EmployeeService,
    private userService: UserService,
    private sessionStorageService: SessionStorageService,
    private limitTransListService: LimitTransListService,
    protected universalVendorService: UniversalVendorService
  ) {
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
      this.componentTypeService
        .find(
          this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto
            .leveltype
        )
        .subscribe(
          (res: HttpResponse<any>) => {
            if (this.roleCode.role.name.startsWith('Village')) {
              this.grantLevel = res.body.filter(item => item.name === 'Village Minimum Grant');
              this.tangedcoBillReqCompId = this.grantLevel.id;
            }
            else if (this.roleCode.role.name.startsWith('Block')) {
              this.grantLevel = res.body.filter(item => item.name === 'Block Minimum Grant' || item.name === 'Panchayat Union General Fund');
            } else if (this.roleCode.role.name.startsWith('District')) {
              this.grantLevel = res.body.filter(item => item.name === 'District Admin Grant' || item.name === 'District Population Grant');
            } else {
              this.grantLevel = res.body;
            }
          },
          () => { }
        );
      this.componentTypeService.findbyMasterId(this.grantlevel.id).subscribe(
        (res: HttpResponse<ComponentType>) => {
          this.majaorComponent = res.body;
        },
        () => { }
      );
      this.componentTypeService
        .findbyParentId(this.majaorComponentdto.id)
        .subscribe(
          (res: HttpResponse<ComponentType>) => {
            this.minorComponent = res.body;
          },
          () => { }
        );
      this.componentTypeService
        .findbyParentId(this.minorComponentDto.id)
        .subscribe(
          (res: HttpResponse<ComponentType>) => {
            this.subComponent = res.body;
          },
          () => { }
        );
    });
  }

  schemeList: SchemeList[];
  selectedVendor: UniversalVendor;
  levelType: LevelType[];
  grantLevel: any;
  grantlevel: any;
  levelMasterDto: LevelType;
  voucherGenerationDto: paymentVoucherGen;
  minorComponent: any;
  minorComponentDto: any;
  subComponent: any;
  subComponentDto: any;
  date: Date;
  voucherNo: string;
  majaorComponent: any;
  majaorComponentdto: any;
  vendorList: any;
  selectedscheme: any;
  profileDialog: boolean = false;
  // descriptionList: DescriptionDto[];
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  noRecord: boolean;
  selectedEmployee: EmployeeDTO;
  tangedcoDescList: ConsolidatedBills;
  tangedcoBills: TangedcoDescDto[];
  @ViewChild('dataTable') dataTable: any;

  cols = [
    {
      field: 'voucherNo',
      header: 'Voucher No',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'personalVendorName',
      header: 'Vendor Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'personalAccountNo',
      header: 'Vendor Account',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'transDate',
      header: 'Transaction Date',
      isSortable: true,
      isSelectcolumn: true,
      type: 'date',
    },
    {
      field: 'grossAmount',
      header: 'Gross Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
    },
    {
      field: 'transactionAmount',
      header: 'Total Transaction Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
    },
  ];
  async ngOnInit(): Promise<void> {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    this.roleCode = this.sessionStorageService.retrieve('user');
    this.levelMasterDto = this.sessionStorageService.retrieve('levelMaster').levelTypeDto;
    this.componentMasterTable = this.sessionStorageService.retrieve('componentMasterTable');
    this.grantLevel = this.sessionStorageService.retrieve('componentTypeTable');
    if (!this.componentMasterTable) {
      await this.loadComp();
    }
    if (
      this.roleCode.role.code === 'DMAKER' ||
      this.roleCode.role.code === 'VMAKER' ||
      this.roleCode.role.code === 'BMAKER'
    ) {
      if (this.roleCode.role.code === 'BMAKER') {
        this.paymentService
          .getZonalCheckers(
            this.roleCode.levelMasterId
          )
          .subscribe(
            (res: HttpResponse<LevelType>) => {
              this.zonalList = res.body;
            },
            () => { }
          );
      }
      this.signButtonName = 'Perform e-Sign Secretary';
    } else if (
      this.roleCode.role.code === 'DC1' ||
      this.roleCode.role.code === 'BC1' ||
      this.roleCode.role.code === 'VC1' ||
      this.roleCode.role.code === 'ZC1'
    ) {
      this.enableApproved = true;
      if (this.roleCode.role.code === 'BC1' || this.roleCode.role.code === 'ZC1') {
        this.checkAmount = true
      }
      this.signButtonName = 'Perform e-Sign VP';
      this.enableRevertButton = true;
      this.readonly = true;
    } else if (
      this.roleCode.role.code === 'DC2' ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'VC2'
    ) {
      this.enableApproved = true;

      this.signButtonName = 'Perform e-Sign President';
      this.enableRevertButton = true;
      this.downloadButton = true;
      this.readonly = true;
    }
    if (
      this.roleCode.role.code === 'BMAKER' ||
      this.roleCode.role.code === 'BC1' ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'ZC1'
    ) {
      this.enableZonalButton = true;
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('response')) {
      this.paymentSucessDialog = true;
      this.paymentSucessText = this.activatedRoute.snapshot.queryParamMap.get('response');
      this.disablepaymentButton = true;
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('error')) {
      this.notificationService.alertError('Error Occurred: ' + this.activatedRoute.snapshot.queryParamMap.get('error'), '');
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      if (this.activatedRoute.snapshot.queryParamMap.get('view')) {
        this.enableView = true;
        this.readonly = true;
      }
      this.spinner.show();
      this.paymentService
        .updatedVocher(
          Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
        )
        .subscribe(
          (res: HttpResponse<paymentVoucherGen>) => {
            this.voucherGenerationDto = { ...res.body };
            if (this.checkAmount && res.body.vendorTransactionDto.transactionAmount <= 500000 && res.body.vendorTransactionDto.signatureVicePresident) {
              res.body.vendorTransactionDto.signaturePresident = "signed"
            }
            if (res.body?.vendorTransactionDto?.zonalChecker) {
              this.userService
                .getUser(res.body?.vendorTransactionDto?.zonalChecker)
                .subscribe(
                  (res: HttpResponse<any>) => {
                    this.selectedZonalUser = res.body;
                  },
                  () => { }
                );
            }
            // this.paymentStatus();
            if (this.voucherGenerationDto.vendorTransactionDto.signStatus && !this.enableView) {
              this.readonly = true;
              this.enableSignButton = true;
            }
            if (this.voucherGenerationDto.vendorTransactionDto.signaturePresident) {
              switch (this.voucherGenerationDto.vendorTransactionDto.transactionStatus) {
                case 'O.K.':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Success';
                  break;
                case 'failed':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Failed';
                  break;
                case 'processing':
                  this.voucherGenerationDto.vendorTransactionDto.transactionStatus = 'Processing';
                  break;
                default:
                  break;
              }
            }
            this.LoadTangedcoBill('edit');
            if (this.voucherGenerationDto.vendorTransactionDto.signaturePresident) {
              this.bulkPaymentStatus();
            }
            if ((this.roleCode.role.code === 'DC2' ||
              this.roleCode.role.code === 'BC2' ||
              this.roleCode.role.code === 'VC2') && !this.voucherGenerationDto.vendorTransactionDto.signaturePresident) {
              this.paymentAsyncStatus();

            }
            this.tangedcoBills = this.voucherGenerationDto.voucherBillDescTangedcoList;
            this.date = new Date(
              this.voucherGenerationDto.vendorTransactionDto.transDate
            );
            // this.totalDeduction =
            //   this.voucherGenerationDto.vendorTransactionDto.totlaInRupees;
            this.selectedVendor =
              this.voucherGenerationDto.vendorTransactionDto.universalVendor;
            this.grantlevel =
              this.voucherGenerationDto.vendorTransactionDto.componentTypeDto;
            this.levelMasterDto =
              this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.levelTypeDto;
            this.tangedcoDescList = new ConsolidatedBills();
            //this.tangedcoDescList.tangedcoBills = [];
            this.majaorComponentdto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.majorComponentId);
            this.minorComponentDto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.minorComponentId);
            this.subComponentDto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.subComponentId);
            this.ongrandLevel('true');
            this.onMajorComponent('true');
            this.onMinorComponent('true');
            this.enableNhis = this.voucherGenerationDto.vendorTransactionDto
              .nhis
              ? true
              : false;
            this.enableCps = this.voucherGenerationDto.vendorTransactionDto.cps
              ? true
              : false;
            this.enableOtherDeduction = this.voucherGenerationDto
              .vendorTransactionDto.otherDeductionIt
              ? true
              : false;
          },
          () => { }
        );
    }
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.enableNhis = false;
      this.enableCps = false;
      this.enableOtherDeduction = false;
      this.voucherGenerationDto = new paymentVoucherGen();
      this.voucherGenerationDto.vendorTransactionDto =
        new vendorTransactionDto();
      this.voucherGenerationDto.vendorTransactionDto.nhisPercentage = 0;
      this.voucherGenerationDto.vendorTransactionDto.cpsPercentage = 0;
      this.voucherGenerationDto.vendorTransactionDto.otherDeductionItPercentage = 0;
      this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto = this.sessionStorageService.retrieve('IA');
      this.voucherGenerationDto.vendorTransactionDto.personalVendorDto =
        new PersonalVendorsDto();
      this.selectedVendor = new UniversalVendor();
      this.voucherGenerationDto.vendorTransactionDto.otherDeductionIt = 0;

      this.voucherGenerationDto.vendorTransactionDto.voucherNo =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.date = new Date();
      let filter1 = new filterQuery();
      filter1.key = 'name';
      filter1.operation = 'contains';
      filter1.value = "TANGEDCO";
      let filterquery1 = [];
      filterquery1.push(filter1);
      this.universalVendorService
        .filter({ size: 10, page: 0 })
        .subscribe(
          (res: HttpResponse<any>) => {
            this.vendorList = res.body.filter(item => item.vendorName === 'TANGEDCO');
            this.selectedVendor = this.vendorList[0];
          },
          () => { }
        );
    }
    this.finYearList = this.sessionStorageService.retrieve('finYear');
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    if (this.roleCode.role.name.startsWith('Village')) {
      this.grantLevel = this.grantLevel.filter(item => item.name === 'Village Minimum Grant' || item.name === 'Village General Funds');
    } else if (this.roleCode.role.name.startsWith('Block')) {
      this.grantLevel = this.grantLevel.filter(item => item.name === 'Block Minimum Grant' || item.name === 'Panchayat Union General Fund');
    } else if (this.roleCode.role.name.startsWith('District')) {
      this.grantLevel = this.grantLevel.filter(item => item.name === 'District Admin Grant' || item.name === 'District Population Grant');
    }
  }


  loadComp(): Promise<any> {
    return new Promise<any>((resolve) => {
      const componmentTypeObservable = this.componentTypeService
        .find(
          this.sessionStorageService.retrieve('levelMaster').levelTypeDto.id
        );
      const componmentMasterObservable = this.paymentService.componentMasterList();
      forkJoin([componmentTypeObservable, componmentMasterObservable]).subscribe(
        (responses) => {
          this.sessionStorageService.store('componentMasterTable', responses[1].body)
          this.componentMasterTable = responses[1].body;
          this.sessionStorageService.store('componentTypeTable', responses[0].body)
          this.grantLevel = responses[0].body;
          resolve(this.componentMasterTable)
        },
        (error) => {
          console.error('Error:', error);
          resolve(null);
        }
      )
    });
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => { }
    );
  }

  onLevelType() {
    this.componentTypeService
      .find(
        this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto
          .leveltype
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          // this.grantLevel = res.body;
          if (this.roleCode.role.name.startsWith('Village')) {
            this.grantLevel = res.body.filter(item => item.name === 'Village Minimum Grant' || item.name === 'Village General Funds');
          } else if (this.roleCode.role.name.startsWith('Block')) {
            this.grantLevel = res.body.filter(item => item.name === 'Block Minimum Grant' || item.name === 'Panchayat Union General Fund');
          } else if (this.roleCode.role.name.startsWith('District')) {
            this.grantLevel = res.body.filter(item => item.name === 'District Admin Grant' || item.name === 'District Population Grant');
          } else {
            this.grantLevel = res.body;
          }
        },
        () => { }
      );
  }
  ongrandLevel(edit?: any) {
    if (!edit && !this.voucherGenerationDto.vendorTransactionDto.id) {
      this.majaorComponentdto = null;
      this.minorComponentDto = null;
      this.subComponentDto = null;
    }
    this.majaorComponent = this.componentMasterTable.filter(item => item.componentTypeDto.id === this.grantlevel.id && item.parentId === 0);
    if (this.grantlevel.name === 'Village Minimum Grant' && !edit) {
      this.majaorComponentdto = this.majaorComponent[0];
      this.onMajorComponent();
    }
    else if (this.grantlevel.name === 'Village General Funds' && !edit) {
      this.majaorComponentdto = this.majaorComponent.filter(item => item.code === '301')[0];
      this.onMajorComponent();
    }
    this.paymentService
      .getLimit(
        this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.id,
        this.grantlevel.id,
        this.finYear.id
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto =
            res.body;
        },
        () => { }
      );
  }
  onMajorComponent(edit?: any) {
    if (!edit && !this.voucherGenerationDto.vendorTransactionDto.id) {
      this.minorComponentDto = null;
      this.subComponentDto = null;
    }
    this.componentTypeService
      .findbyParentId(this.majaorComponentdto.id)
      .subscribe(
        (res: HttpResponse<ComponentType[]>) => {
          this.minorComponent = [];
          this.minorComponent = this.componentMasterTable.filter(item => item.parentId === this.majaorComponentdto.id && item.code === '30101');
          this.minorComponentDto = res.body[0];
          this.onMinorComponent();
        },
        () => { }
      );
  }

  calculateOtherDeduction() {
    this.voucherGenerationDto.vendorTransactionDto.otherDeductionItPercentage =
      Number(
        this.voucherGenerationDto.vendorTransactionDto
          .otherDeductionItPercentage
      );
    this.voucherGenerationDto.vendorTransactionDto.otherDeductionIt = Number(
      (
        (this.voucherGenerationDto.vendorTransactionDto
          .otherDeductionItPercentage /
          100) *
        this.voucherGenerationDto.vendorTransactionDto.netAmount
      ).toFixed(2)
    );
    this.calculateTotalAmount();
  }

  onUpload($event) {
    const selectedFile: File = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer);
    };
    const formData = new FormData();
    const fileExtension = $event.name.split('.').pop();
    const fileData = new Blob([$event], { type: $event.type });
    formData.append('file', fileData, `file.${fileExtension}`);
    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => { },
      () => { }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }
  onMinorComponent(edit?: any) {
    if (!edit && !this.voucherGenerationDto.vendorTransactionDto.id) {
      this.subComponentDto = null;
    }
    this.subComponent = this.componentMasterTable.filter(item => item.parentId === this.minorComponentDto.id);
    this.subComponentDto = this.subComponent[0];
    this.LoadTangedcoBill();
    if (this.minorComponentDto.name.startsWith('Salary')) {
      this.employeeService.filter({ size: 10, page: 0 }, []).subscribe(
        (res: HttpResponse<Employee>) => {
          this.employeeList = res.body;
          this.enableEmployee = true;
        },
        () => { }
      );
    } else {
      this.enableEmployee = false;
    }
  }

  vendorFilter($event) {

    this.universalVendorService
      .filter({ size: 10, page: 0 })
      .subscribe(
        (res: HttpResponse<any>) => {
          this.vendorList = res.body.filter(item => item.department.name === 'TANGEDCO');
        },
        () => { }
      );
  }

  popupValue() {
    this.voucherGenerationDto.personalDescDtoList = [];
    let personalDescDto = new PersonalDescDto();
    // personalDescDto.quantity = 0;
    personalDescDto.amount = 0;
    personalDescDto.grossAmount = 0;
    this.amount = 0;
    // this.quantity = 0;
    this.description = null;

    this.voucherGenerationDto.personalDescDtoList.push(personalDescDto);
    // this.paymentService.getDescrptionList().subscribe(
    //   (res: HttpResponse<DescriptionDto[]>) => {
    //     this.descriptionList = [];
    //     res.body.forEach((element) => {
    //       if (!element.name.startsWith('Salary')) {
    //         this.descriptionList.push(element);
    //       }
    //     });
    //   },
    //   () => {}
    // );
    //this.selectedVendor.gstNo = null
    this.voucherGenerationDto.vendorTransactionDto.personalVendorDto =
      new PersonalVendorsDto();
    this.voucherGenerationDto.vendorTransactionDto.personalVendorDto.id =
      this.selectedVendor.id;
    // this.voucherGenerationDto.commercialVendorDto.gstNo = this.selectedVendor.gstNo
    this.voucherGenerationDto.vendorTransactionDto.gstOnTds = 2;

    this.calculateTotalAmount();
  }
  addRow() {
    this.voucherGenerationDto.personalDescDtoList = this.voucherGenerationDto
      .personalDescDtoList
      ? this.voucherGenerationDto.personalDescDtoList
      : [];
    let personalDescDto = new PersonalDescDto();
    personalDescDto.quantity = 0;
    personalDescDto.amount = 0;
    personalDescDto.grossAmount = 0;
    this.voucherGenerationDto.personalDescDtoList.push(personalDescDto);
    // if(!this.descriptionList)
    // {
    //   this.paymentService.getDescrptionList().subscribe(
    //     (res: HttpResponse<DescriptionDto[]>) => {
    //       this.descriptionList = [];
    //       res.body.forEach((element) => {
    //         if (!element.name.startsWith('Salary')) {
    //           this.descriptionList.push(element);
    //         }
    //       });
    //     },
    //     () => {}
    //   );
    // }
    this.calculateOtherDeduction();
  }
  calculatetotal(amt: PersonalDescDto) {
    if (amt.amount === null) {
      amt.amount = 0;
    }
    this.amount = amt.amount;
    amt.grossAmount = amt.amount;
    this.calculateTotalAmount();
    this.calculateOtherDeduction();
  }
  delete(index: number) {
    this.voucherGenerationDto.personalDescDtoList.splice(index, 1);
    if (this.voucherGenerationDto.personalDescDtoList.length === 0) {
      this.voucherGenerationDto.vendorTransactionDto.netAmount = null;
      this.totalDeduction = null;
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount = null;
      this.voucherGenerationDto.vendorTransactionDto.nhis = 0;
      this.voucherGenerationDto.vendorTransactionDto.cps = 0;
      this.voucherGenerationDto.vendorTransactionDto.otherDeductionIt = 0;
    } else {
      this.calculateTotalAmount();
    }
    this.calculateOtherDeduction();
  }

  calculateTds() {
    this.voucherGenerationDto.vendorTransactionDto.tdsAmount =
      this.voucherGenerationDto.vendorTransactionDto.netAmount /
      this.voucherGenerationDto.vendorTransactionDto.tdsPercentage;
    this.voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount = Math.round(
      this.voucherGenerationDto.vendorTransactionDto.tdsAmount -
      (this.voucherGenerationDto.vendorTransactionDto.gstOnTds / 100) *
      this.voucherGenerationDto.vendorTransactionDto.tdsAmount
    );
  }

  validateIfsc() {
    this.ifscError = !this.validationService.isPanValid(
      this.selectedVendor.ifscCode
    );
    !this.selectedVendor.ifscCode ? (this.ifscError = false) : true;
    return !this.ifscError && !!this.selectedVendor.ifscCode;
  }
  addDescritption(list: string) {
    this.description = list;
  }

  genVocher() {
    this.ngSelectInvalid = false;
    const isFormValid =
      this.selectedVendor.vendorName &&
      this.levelMasterDto &&
      this.grantlevel &&
      this.majaorComponentdto &&
      this.minorComponentDto &&
      this.subComponentDto &&
      this.voucherGenerationDto.vendorTransactionDto
        .panchayatResolutionNumber &&
      this.selectedVendor.vendorName &&
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount &&
      this.voucherGenerationDto.voucherBillDescTangedcoList &&
      this.voucherGenerationDto.vendorTransactionDto.remark &&
      this.voucherGenerationDto.vendorTransactionDto

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Please fill all the mandotory fields marked with *", '');
      return;
    }
    if (this.roleCode?.role?.code === 'BMAKER' && !this.selectedZonalUser) {
      this.notificationService.alertError('Please Select the Zonal User', '')
      return;
    }


    if (this.ngSelectInvalid) {
      return;
    }
    this.ngSelectInvalid = false;
    this.spinner.show();

    if (this.selectedZonalUser?.id) {
      this.voucherGenerationDto.vendorTransactionDto.zonalChecker = this.selectedZonalUser?.id
    }
    this.voucherGenerationDto.vendorTransactionDto.componentTypeDto =
      this.grantlevel;

    this.voucherGenerationDto.vendorTransactionDto.universalVendor =
      this.selectedVendor;

    this.voucherGenerationDto.vendorTransactionDto.majorComponentId =
      this.majaorComponentdto.id;
    this.voucherGenerationDto.vendorTransactionDto.majorComponentName =
      this.majaorComponentdto.name;
    this.voucherGenerationDto.vendorTransactionDto.minorComponentId =
      this.minorComponentDto.id;
    this.voucherGenerationDto.vendorTransactionDto.minorComponentName =
      this.minorComponentDto.name;
    this.voucherGenerationDto.vendorTransactionDto.subComponentId =
      this.subComponentDto.id;
    this.voucherGenerationDto.vendorTransactionDto.subComponentName =
      this.subComponentDto.name;
    this.voucherGenerationDto.vendorTransactionDto.levelTypeId =
      this.levelMasterDto.id;
    this.voucherGenerationDto.vendorTransactionDto.personalFlag = 'T';
    this.voucherGenerationDto.vendorTransactionDto.netAmount = 0;
    this.voucherGenerationDto.vendorTransactionDto.grossAmount = 0;
    this.paymentService.genVocher(this.voucherGenerationDto).subscribe(
      (response: any) => {
        // const blob = new Blob([response.body], { type: 'application/pdf' });
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = 'file.pdf';
        // document.body.appendChild(link);
        // window.open(url, '_blank');
        // link.click();
        // URL.revokeObjectURL(url);
        this.readonly = true;
        this.spinner.hide();
        this.enableSignButton = true;
        // this.router.navigate(['/payment-personal']);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },

      (error) => {
        this.spinner.hide();
        if (error.status == 400) {
          this.notificationService.alertError('Due to insufficient limit, You are not eligible to generate Voucher', '');

        } else {
          this.notificationService.alertError('An unexpected error occurred.', '');
        }
      }
    );
  }

  bulkPaymentStatus() {

    this.paymentService
      .bulkPaymentStatus(this.voucherGenerationDto.vendorTransactionDto.id)
      .subscribe(
        (res: any) => {
          this.paymentSucessDialog = true;
          this.paymentSucessText = res.body.response;
          if (res.body.response === 'Payment Successfully completed' || res.body.response === 'Payment successfull in TNPASS, But payment failed in tangedco' || res.body.response === 'Payment successfull in TNPASS, but partially done in Tangedco' || res.body.response === 'Payment Partially Completed') {
            this.viewPayList = true;
          }

          this.redirectPage = res.body.isRedirect;
        },
        (err) => { }
      );
  }

  paymentAsyncStatus() {
    this.spinner.show();
    this.paymentService
      .paymentAsyncStatus(this.voucherGenerationDto.vendorTransactionDto.id)
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res) {
            this.notificationService.alertError(res, '');
            if (res === 'This voucher contains one bill which is not in demand, So please reject this voucher') {
              this.isCheckActiveFlag = true;
            }
            this.voucherGenerationDto.voucherBillDescTangedcoList.forEach(element => {
              if (element.activeFlag === 'YES') {
                this.isCheckActiveFlag = false;
              }
            });
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  getBulkPaymentList() {
    this.paymentService
      .bulkPaymentList(this.voucherGenerationDto.vendorTransactionDto.id)
      .subscribe(
        (res: any) => {
          res.body.forEach(element => {
            if(element.isCancelled) {
              element.transactionStatus = 'Failed and Returned';
            }
          });
          this.bulkPaySearchResult.items = res.body ?? [];
          this.bulkPaySearchResult = { ...this.bulkPaySearchResult };
          this.bulkPaymentListDialog = true;
        },
        (err) => { }
      );
  }

  getfile() {
    this.paymentService
      .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          if (!this.voucherGenerationDto.vendorTransactionDto.id) {
            this.router.navigate(['/Tangedco-Voucher'], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
          // add more parameters as needed
        },
        () => { }
      );
  }

  getActionUrl() {
    this.sessionStorageService.store('refresh', 'Yes');
    return 'https://es-staging.cdac.in/esignlevel2/2.1/form/signdoc';
    //return 'https://esignservice.cdac.in/esign2.1/2.1/form/signdoc';
  }

  addBills(data, index, type) {
    this.spinner.show();
    let previousData;
    const isTangedcoBillAdded = this.tangedcoBills && this.tangedcoBills.length !== 0;

    if (type === 'cc') {
      previousData = this.selectedConsumer.ccChargesList[index - 1];
    } else if (type === 'ins') {
      previousData = this.selectedConsumer.installments[index - 1];
    } else if (type === 'mis') {
      previousData = this.selectedConsumer.miscellaneous[index - 1];
    }
    if (previousData) {
      if (this.tangedcoBills?.find(_ => _.tangedcoBillId === previousData.id) || previousData.voucherInitaied) {
        if (previousData.voucherInitaied && !previousData.paymentVerified) {
          this.notificationService.alertError('Please Complete The Payment For Oldest Bill Before Selected', '');
          this.spinner.hide();
          return
        }
        else {
          if (isTangedcoBillAdded && this.tangedcoBills.find(_ => _.tangedcoBillId === data.id)) {
            this.notificationService.alertError('This Bill Already Added', '');
          } else {
            data.tangedcoBillId = _.cloneDeep(data.id);
            this.tangedcoBills = [...this.tangedcoBills, data];
            data.selected = true;
            this.notificationService.alertSuccess('The Bill Added', '');
          }
        }

      } else {
        this.notificationService.alertError('Please Select Oldest Bill First', '');
      }
    } else {
      if (isTangedcoBillAdded && this.tangedcoBills.find(_ => _.tangedcoBillId === data.id)) {
        this.notificationService.alertError('This Bill Already Added', '');
      } else {
        data.tangedcoBillId = _.cloneDeep(data.id);
        this.tangedcoBills = [...this.tangedcoBills, data];
        data.selected = true;
        this.notificationService.alertSuccess('The Bill Added', '');
      }
    }

    console.log('tangedcoBills', this.tangedcoBills);
    this.totalBillAmount = this.tangedcoBills.reduce((total, bill) => total + bill.billAmount, 0);
    this.tangedcoBills = this.tangedcoBills.map(element => ({ ...element, id: null }));
    this.spinner.hide();
  }

  getPdf() {
    this.paymentService
      .getPdfFile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
          // this.spinner.hide();
          // this.router.navigate(['payment']);
          // this.getfile();
          // this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (err) => {
          // this.spinner.hide();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  revert() {
    this.spinner.show();
    this.paymentService
      .revertTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/Tangedco-Payment-Table'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  reject() {
    this.spinner.show();
    this.paymentService
      .deletedVocher(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/Tangedco-Payment-Table'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  calculateTotalAmount() {
    if (this.voucherGenerationDto?.personalDescDtoList.length !== 0) {
      let intitalTotal = 0;
      let intitalTotalWithTax = 0;
      this.voucherGenerationDto?.personalDescDtoList.forEach((element) => {
        intitalTotal += Number(element.amount);
        intitalTotalWithTax += element.grossAmount;
      });
      this.voucherGenerationDto.vendorTransactionDto.netAmount =
        intitalTotalWithTax;
      this.voucherGenerationDto.vendorTransactionDto.grossAmount =
        intitalTotalWithTax;
      this.voucherGenerationDto.vendorTransactionDto.nhis = 0;
      this.voucherGenerationDto.vendorTransactionDto.cps = 0;
      if (this.enableNhis) {
        this.voucherGenerationDto.vendorTransactionDto.nhis = Number(
          (
            (this.voucherGenerationDto.vendorTransactionDto.nhisPercentage /
              100) *
            this.voucherGenerationDto.vendorTransactionDto.netAmount
          ).toFixed(2)
        );
      }
      if (this.enableCps) {
        this.voucherGenerationDto.vendorTransactionDto.cps = Number(
          (
            (this.voucherGenerationDto.vendorTransactionDto.cpsPercentage /
              100) *
            this.voucherGenerationDto.vendorTransactionDto.netAmount
          ).toFixed(2)
        );
      }

      this.totalDeduction =
        this.voucherGenerationDto.vendorTransactionDto.nhis +
        this.voucherGenerationDto.vendorTransactionDto.cps +
        this.voucherGenerationDto.vendorTransactionDto.otherDeductionIt;
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount =
        this.voucherGenerationDto.vendorTransactionDto.netAmount -
        this.totalDeduction;
    }
  }

  getvendorTrans() {
    if (this.selectedVendor) {
      this.paymentService
        .getListPersonalVendorTransction(this.selectedVendor.id)
        .subscribe(
          (res: any) => {
            this.noRecord = true;
            this.searchResult.items = res.body ?? [];
            if (this.searchResult.items.length > 0) {
              this.noRecord = false;
            }
            this.searchResult.total = 5;
            this.searchResult = { ...this.searchResult };
            this.profileDialog = true;
          },
          () => { }
        );
    }
  }

  redirect() {
    let enableview = false;
    if (this.enableView) {
      enableview = true;
    }
    if (this.redirectPage) {
      this.router.navigate(['/Tangedco-Payment-Table'], {});
    }
    else {
      this.paymentSucessDialog = false;

    }

  }

  submitForm() {
    if(this.roleCode.role.code !== 'VC2') {
      this.sessionStorageService.store('paymentType', this.voucherGenerationDto.vendorTransactionDto.personalFlag);
      this.sessionStorageService.store('voucherNo',this.voucherGenerationDto.vendorTransactionDto.voucherNo);
      this.sessionStorageService.store('userId',this.roleCode.id);
      this.sessionStorageService.store('otpAttempts',3);
      this.router.navigateByUrl('/aadhar-verification-page');
    }
    else {
      this.spinner.show();
      this.paymentService
        .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
        .subscribe(
          (res: HttpResponse<any>) => {
            console.log('ress', res.body);
            this.spinner.hide();
            this.param1 = res.body.paramOne;
            this.param2 = res.body.paramTwo;
            this.param3 = res.body.paramThree;
            if (!this.voucherGenerationDto.vendorTransactionDto.id) {
              this.router.navigate(['/Tangedco-Voucher'], {
                queryParams: {
                  id: res.body.paramFour
                },
              });
            }
            this.sessionStorageService.store('refresh', 'Yes');
            const formElement = document.createElement('form');
            formElement.method = 'POST';
            formElement.action = environment.CDAC_URL;
            let inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "eSignRequest";
            inputElement.value = res.body.paramOne;
            formElement.appendChild(inputElement);
            inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "aspTxnID";
            inputElement.value = res.body.paramTwo;
            formElement.appendChild(inputElement);
            inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "Content-Type";
            inputElement.value = res.body.paramThree;
            formElement.appendChild(inputElement);
            document.body.appendChild(formElement);
            formElement.submit();
          },
          (error) => {
            this.spinner.hide();
            console.log('error', error);
            if (
              this.roleCode.role.code === 'DMAKER' ||
              this.roleCode.role.code === 'VMAKER' ||
              this.roleCode.role.code === 'BMAKER'
            ) {
              this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: ' + this.voucherGenerationDto.vendorTransactionDto.voucherNo, '');
              this.router.navigate(['/Tangedco-Payment-Table'], {});
            }
            else {
 
            }
          }
        );
}
  }

  checkPaymentStatus() {
    this.checkForPaymentStatus = false;
    this.paymentService.paymentStatus(this.interactionId).subscribe(
      (res: any) => {
        if (res.body.transactionStatus.TRANSTATUS === "Failure") {
          this.notificationService.alertError("Transaction Failed for Reference No:" + res.body.transactionStatus.JRNL_NO, '')
          this.buttonText = 'Re-Initiate payment';
          this.checkstatusButton = false;
          this.disablepaymentButton = false;
        }
        else if (res.body.transactionStatus.TRANSTATUS === "Success") {
          this.checkstatusButton = false;
          this.paymentSucessDialog = true;
          this.paymentSucessText = "Transaction Success for Reference No:", res.body.transactionStatus.JRNL_NO;
        }
      },
      (err) => {
      }
    );
  }
  checkTransactionStatus() {
    this.checkForPaymentStatus = true;
    this.paymentStatus();
  }

  paymentStatus() {
    this.paymentService.getPaymentStatus(this.voucherGenerationDto.vendorTransactionDto.id).subscribe(
      (res: any) => {
        if (res.body.transactionStatus === "O.K.") {
          this.disablepaymentButton = true;
        }
        else if (res.body.transactionStatus !== null) {
          this.buttonText = "Re-Initiate payment"
          this.checkstatusButton = true;
          this.interactionId = res.body.apiInteractionId;
          if (this.checkForPaymentStatus) {
            this.checkPaymentStatus();
          }
        }


      },
      (err) => {

      }
    );


  }

  PaymentSucess() {
    this.paymentService.paymentSuccess(this.voucherGenerationDto).subscribe(
      (res: any) => {
        this.paymentSucessDialog = true;
        this.paymentSucessText = res.body;
        this.disablepaymentButton = true;
      },
      (err) => {
        if (String(err.status).startsWith('5')) {
          this.checkstatusButton = true
        }
      }
    );
  }

  LoadTangedcoBill(edit?) {
    if (!edit && !this.voucherGenerationDto.vendorTransactionDto.id) {
      this.selectedConsumer = null;
      this.tangedcoBills = [];
      this.voucherGenerationDto.voucherBillDescTangedcoList = [];
      this.totalBillAmount = 0;
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount = 0;
    }
    if ((this.roleCode.role.code === 'DMAKER' ||
      this.roleCode.role.code === 'VMAKER' ||
      this.roleCode.role.code === 'BMAKER') && !this.voucherGenerationDto.vendorTransactionDto.signStatus)
      this.spinner.show();
    this.paymentService.loadTangedcoBills().subscribe(
      (res: any) => {
        console.log(res.body);
        this.spinner.hide();
        this.tangedcoConsumerList = res.body;
        console.log("hdqhjwgdhqiqq", this.tangedcoDescList);
      }
    )

  }

  deletedSelectedBills(index: number, data) {
    // data.voucherInitaied = false;
    // findIndex((_) => _.name === 'District')
    if (!this.selectedConsumer) {
      if (this.tangedcoConsumerList.findIndex((_) => _.consumerNo === data.consumerNo) >= 0) {
        this.selectedConsumer = this.tangedcoConsumerList[this.tangedcoConsumerList.findIndex((_) => _.consumerNo === data.consumerNo)];
      }
    }
    if (this.selectedConsumer.ccChargesList.findIndex((_) => _.id === data.tangedcoBillId) >= 0) {
      this.selectedConsumer.ccChargesList[this.selectedConsumer.ccChargesList.findIndex((_) => _.id === data.tangedcoBillId)].selected = false;
    }
    if (this.selectedConsumer.installments.findIndex((_) => _.id === data.tangedcoBillId) >= 0) {
      this.selectedConsumer.ccChargesList[this.selectedConsumer.installments.findIndex((_) => _.id === data.tangedcoBillId)].selected = false;
    }
    if (this.selectedConsumer.miscellaneous.findIndex((_) => _.id === data.tangedcoBillId) >= 0) {
      this.selectedConsumer.miscellaneous[this.selectedConsumer.miscellaneous.findIndex((_) => _.id === data.tangedcoBillId)].selected = false;
    }
    this.tangedcoBills.splice(index, 1);
    this.tangedcoBills = [...this.tangedcoBills];
    this.totalBillAmount = this.tangedcoBills.reduce((total, bill) => total + bill.billAmount, 0);
    this.notificationService.alertSuccess('the bill deleted Sucessfully', '');
  }

  deletedTangdeoBills(index: number, data) {
    if (!this.selectedConsumer) {
      if (this.tangedcoConsumerList.findIndex((_) => _.consumerNo === data.consumerNo) >= 0) {
        this.selectedConsumer = this.tangedcoConsumerList[this.tangedcoConsumerList.findIndex((_) => _.consumerNo === data.consumerNo)];
      }
    }
    if (this.selectedConsumer.ccChargesList.findIndex((_) => _.id === data.tangedcoBillId) >= 0) {
      this.selectedConsumer.ccChargesList[this.selectedConsumer.ccChargesList.findIndex((_) => _.id === data.tangedcoBillId)].selected = false;
    }
    if (this.selectedConsumer.installments.findIndex((_) => _.id === data.tangedcoBillId) >= 0) {
      this.selectedConsumer.ccChargesList[this.selectedConsumer.installments.findIndex((_) => _.id === data.tangedcoBillId)].selected = false;
    }
    if (this.selectedConsumer.miscellaneous.findIndex((_) => _.id === data.tangedcoBillId) >= 0) {
      this.selectedConsumer.miscellaneous[this.selectedConsumer.miscellaneous.findIndex((_) => _.id === data.tangedcoBillId)].selected = false;
    }
    this.voucherGenerationDto.voucherBillDescTangedcoList.splice(index, 1);
    this.voucherGenerationDto.voucherBillDescTangedcoList = [...this.voucherGenerationDto.voucherBillDescTangedcoList];
    this.tangedcoBills = [...this.voucherGenerationDto.voucherBillDescTangedcoList];
    this.totalBillAmount = this.tangedcoBills.reduce((total, bill) => total + bill.billAmount, 0);
    this.voucherGenerationDto.vendorTransactionDto.transactionAmount = this.totalBillAmount;
    this.notificationService.alertSuccess('the bill deleted Sucessfully', '');
  }

  apply() {
    this.voucherGenerationDto.voucherBillDescTangedcoList = _.cloneDeep(this.tangedcoBills);
    this.voucherGenerationDto?.voucherBillDescTangedcoList?.forEach(element => {
      element.voucherNo = this.voucherGenerationDto.vendorTransactionDto.voucherNo;
    });
    this.voucherGenerationDto.vendorTransactionDto.transactionAmount = this.totalBillAmount;
    this.selectedConsumer = null;
    this.showConsumerDetails = false;
  }

  exportToExcel(): void {
    let element = document.getElementById('BulkPaymentList');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'BulkPaymentListStatus.xlsx');
  }

  resetPaginator() {
    if (this.dataTable) {
      this.dataTable.first = 0; // Reset paginator to first page
    }
    this.activeIndex = 0;
  }

}
