import { Component } from '@angular/core';
import { AutoSweepLog } from './auto-sweep.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AutoSweepService } from './auto-sweep.service';
import { LazyLoadEvent } from 'primeng/api';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-auto-sweep-list',
  templateUrl: './auto-sweep-list.component.html',
  styleUrl: './auto-sweep-list.component.scss'
})
export class AutoSweepListComponent {
  autoSweep: AutoSweepLog[];
  totalCount: number;
  itemsPerPage: number = 10;
  page: number;
  first = 0;
  activeIndex = 0;

  constructor(
    public fontService: FontService,
    private spinner: NgxSpinnerService,
    private autoSweepService: AutoSweepService

  ) {

  }

  ngOnInit() {

  }

  LoadAutoSweepList(page) {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    if(this.activeIndex == 0){
      filter['fundSource']="OSR";
    }
    else {
      filter['fundSource']="MAM";
    }
    this.autoSweepService
      .getAllSweeps({ ...filter })
      .subscribe(
        (res: HttpResponse<AutoSweepLog[]>) => {
          this.autoSweep = res.body;
          this.totalCount = Number(res.headers.get('X-Total-Count'));
          this.spinner.hide();


        },
        () => {
          this.spinner.hide();
        }
      );
  }

  loadFilterValues(event: LazyLoadEvent) {
    let filterDefinitions = [];
    let filterValue = null
    this.first = event.first;
    event.first = (event.first + event.rows) / event.rows;
    this.page = event.first;
    this.itemsPerPage = event.rows;
    this.LoadAutoSweepList(this.page);

  }


  downloadFile(fileName: string) {
    let filter = {
      filename:fileName,
    };
    if(this.activeIndex == 0){
      filter['fundSource']="OSR";
    }
    else {
      filter['fundSource']="MAM";
    }
    this.spinner.show();
    this.autoSweepService.fetchOsrFile(filter)
      .subscribe((res) => {
        this.spinner.hide();
        const filename = res.body;
        this.saveBlobToTxt(filename,fileName);
      },
        (onError) => {

        })
  }

  saveBlobToTxt(data, fileName) {
    
    const blob = new Blob([data], { type: 'text/plain' });
    saveAs(blob, `${fileName}.txt`);
  }

  onTabChange(event) {

  }

}
