import { LimitTranslistComponent } from './module/limit-translist/limit-translist.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { SigninComponent } from './auth/signin/signin.component';
import { DashboardComponent } from './dashBoards/dashboard/dashboard.component';
import { AccountSnaComponent } from './module/account-sna/account-sna.component';
import { AgencyComponent } from './module/agency/agency.component';
import { CommercialVendorsComponent } from './module/commercial-vendors/commercial-vendors.component';
import { ComponentTypeComponent } from './module/component-type/component-type.component';
import { DepartmentListComponent } from './module/department-list/department-list.component';
import { FundsTransactionComponent } from './module/funds-transaction/funds-transaction.component';
import { FundsComponent } from './module/funds/funds.component';
import { LevelMasterComponent } from './module/level-master/level-master.component';
import { LevelTypeComponent } from './module/level-type/level-type.component';
import { LimitSetComponent } from './module/limit-set/limit-set.component';
import { MajorComponentComponent } from './module/major-component/major-component.component';
import { MinorComponentComponent } from './module/minor-component/minor-component.component';
import { OpeningBalanceComponent } from './module/opening-balance/opening-balance.component';
import { PersonalVendorsComponent } from './module/personal-vendors/personal-vendors.component';
import { ProfileComponent } from './module/profile/profile.component';
import { SchemeListComponent } from './module/scheme-list/scheme-list.component';
import { SubComponentComponent } from './module/sub-component/sub-component.component';
import { UserComponent } from './module/user/user.component';
import { ZonalComponent } from './module/zonal/zonal.component';
import { PaymentVoucherComponent } from './payments/payment-voucher/payment-voucher.component';
import { PaymentModuleComponent } from './payments/payment-module/payment-module.component'
import { LimitFormulaComponent } from './module/limit-formula/limit-formula.component';
import { PaymentPersonalComponent } from './payments/payment-personal/payment-personal.component';
import { PaymentPersonalVoucherComponent } from './payments/payment-personal-voucher/payment-personal-voucher.component';
import { UpdateBeneficiaryComponent } from './module/update-beneficiary/update-beneficiary.component';
import { BeneficiaryTransactionComponent } from './module/beneficiary-transaction/beneficiary-transaction.component';
import { UserRouteAccessService } from './auth/user-route-access.service';
import { ErrorComponent } from './shared/error/error.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { PaymentSuccessComponent } from './payments/payment-success/payment-success.component';
import { EmployeeComponent } from './module/employee/employee.component';
import { EmployeePaymentTableComponent } from './payments/employee-payment-table/employee-payment-table.component';
import { EmployeeVoucherComponent } from './payments/employee-voucher/employee-voucher.component';
import { ElectedRepresentativeComponent } from './module/elected-representative/elected-representative.component';
import { ElectedRepresenativePaymentTableComponent } from './payments/elected-represenative-payment-table/elected-represenative-payment-table.component';
import { ElectedRepresenativePaymentlComponent } from './payments/elected-represenative-paymentl/elected-represenative-paymentl.component';
import { PaymentSwapPageComponent } from './payments/payment-swap-page/payment-swap-page.component';
import { PaymentSwapTableComponent } from './payments/payment-swap-table/payment-swap-table.component';
import { OnboardReportComponent } from './report/onboard-report/onboard-report.component';
import { VendorReportComponent } from './report/vendor-report/vendor-report.component';
import { PaymentReportComponent } from './report/payment-report/payment-report.component';
import { EmployeeOnboardReportComponent } from './report/employee-onboard-report/employee-onboard-report.component';
import { VoucherStatusReportComponent } from './report/voucher-status-report/voucher-status-report.component';
import { AccountwiseFundReportComponent } from './report/accountwise-fund-report/accountwise-fund-report.component';
import { OsrFundComponent } from './module/osr-fund/osr-fund.component';
import { NilBalanceReportComponent } from './report/nil-balance-report/nil-balance-report.component';
import { TranscatonReportComponent } from './report/transcaton-report/transcaton-report.component';
import { HomeComponent } from './home/home.component';
import { EmployeeBulkPayTableComponent } from './payments/employee-bulk-pay-table/employee-bulk-pay-table.component';
import { EmployeeBulkPayVoucherComponent } from './payments/employee-bulk-pay-voucher/employee-bulk-pay-voucher.component';
import { ERBulkPayTableComponent } from './payments/er-bulk-pay-table/er-bulk-pay-table.component';
import { ERBulkPayVoucherComponent } from './payments/er-bulk-pay-voucher/er-bulk-pay-voucher.component';
import { RejectFailedPaymentsComponent } from './payments/reject-failed-payments/reject-failed-payments.component';
import { BulkPaymentReportComponent } from './report/payment-report/bulk-payment-report/bulk-payment-report.component';
import { EmployeeCountReportComponent } from './report/employee-count-report/employee-count-report.component';
import { PaymentNotDoneReportComponent } from './report/payment-not-done-report/payment-not-done-report.component';
import { VendorTransactionReportComponent } from './report/vendor-transaction-report/vendor-transaction-report.component';
import { PaymentStatusReportComponent } from './report/payment-status-report/payment-status-report.component';
import { PaymentTransReportComponent } from './report/payment-trans-report/payment-trans-report.component';
import { ApiReqResReportComponent } from './report/api-req-res-report/api-req-res-report.component';
import { IaComponentReportComponent } from './report/ia-component-report/ia-component-report.component';
import { IaComponentTransReportComponent } from './report/ia-component-trans-report/ia-component-trans-report.component';
import { AccountValidationLogComponent } from './report/account-validation-log/account-validation-log.component';
import { DscReportComponent } from './report/dsc-report/dsc-report.component';
import { FundsExcelUploadComponent } from './module/funds-excel-upload/funds-excel-upload.component';
import { BlukPaymentTableComponent } from './payments/bluk-payment-table/bluk-payment-table.component';
import { BlukPaymentVoucherComponent } from './payments/bluk-payment-voucher/bluk-payment-voucher.component';
import { EmployeeTransferComponent } from './module/employee-transfer/employee-transfer.component';
import { EmployeeTransferPageComponent } from './module/employee-transfer-page/employee-transfer-page.component';
import { EmployeeTransferReportComponent } from './report/employee-transfer-report/employee-transfer-report.component';
import { TwadMasterListComponent } from './twad/twad-master-list/twad-master-list.component';
import { TwadPaymentTableComponent } from './twad/twad-payment-table/twad-payment-table.component';
import { TangedcoBillDetailsComponent } from './tneb/tangedco-bill-details/tangedco-bill-details.component';
import { TangedcoPaymentTableComponent } from './tneb/tangedco-payment-table/tangedco-payment-table.component';
import { TangedcoPaymentVoucherComponent } from './tneb/tangedco-payment-voucher/tangedco-payment-voucher.component';
import { TangedcoMasterComponent } from './tneb/tangedco-master/tangedco-master.component';
import { UniversalVendorComponent } from './module/universal-vendor/universal-vendor.component';
import { TnebReportComponent } from './report/tneb-report/tneb-report.component';
import { TwadPaymentVoucherComponent } from './twad/twad-payment-voucher/twad-payment-voucher.component';
import { TwadDashboardComponent } from './twad/twad-dashboard/twad-dashboard.component';
import { MenuConfigurationComponent } from './tneb/menu-configuration/menu-configuration.component';
import { MenuEnableListComponent } from './tneb/menu-enable-list/menu-enable-list.component';
import { NavigationComponent } from './module/navigation/navigation.component';
import { ReportListComponent } from './report/report-list/report-list.component';
import { PushNotificationComponent } from './push_notifcation/push-notification/push-notification.component';
import { BeneficiaryCreationComponent } from './module/beneficiary-creation/beneficiary-creation.component';
import { DeductionAccountsComponent } from './module/deduction-accounts/deduction-accounts.component';
import { BeneficiaryPaymentListComponent } from './payments/beneficiary-payment-list/beneficiary-payment-list.component';
import { BeneficiaryPaymentVoucherComponent } from './payments/beneficiary-payment-voucher/beneficiary-payment-voucher.component';
import { DeductionAccountCountReportComponent } from './report/deduction-account-count-report/deduction-account-count-report.component';
import { TwadReportComponent } from './twad/twad-report/twad-report.component';
import { ThooimaiKavalarPaymentListComponent } from './payments/thooimai-kavalar-payment-list/thooimai-kavalar-payment-list.component';
import { ThooimaiKavalarPaymentVoucherComponent } from './payments/thooimai-kavalar-payment-voucher/thooimai-kavalar-payment-voucher.component';
import { AccountStatementVillageComponent } from './module/account-statement-village/account-statement-village.component';
import { AccountStatementBalancesVillageComponent } from './module/account-statement-balances-village/account-statement-balances-village.component';
import { DeductionReportComponent } from './report/deduction-report/deduction-report.component';
import { VoucherDelayReportComponent } from './report/voucher-delay-report/voucher-delay-report.component';
import { NewVoucherDelayReportComponent } from './report/new-voucher-delay-report/new-voucher-delay-report.component';
import { TwadOfficeMasterComponent } from './twad/twad-office-master/twad-office-master.component';
import { TwadAccountSummaryComponent } from './twad/twad-account-summary/twad-account-summary.component';
import { ExcelUploadListComponent } from './module/excel-upload-list/excel-upload-list.component';
import { OfficeMasterComponent } from './twad/office-master/office-master.component';
import { ComponentSwapTableComponent } from './payments/component-swap-table/component-swap-table.component';
import { ComponentSwapPageComponent } from './payments/component-swap-page/component-swap-page.component';
import { TnebDashboardComponent } from './dashBoards/tneb-dashboard/tneb-dashboard.component';
import { TnebPaymentReportComponent } from './report/tneb-payment-report/tneb-payment-report.component';
import { TnebTransactionReportComponent } from './report/tneb-transaction-report/tneb-transaction-report.component';
import { TnebMisReportComponent } from './report/tneb-mis-report/tneb-mis-report.component';
import { AutoSweepListComponent } from './module/auto-sweep-list/auto-sweep-list.component';
import { AadharVerificationPageComponent } from './payments/aadhar-verification-page/aadhar-verification-page.component';
import { OtherFundsComponent } from './dashBoards/other-funds/other-funds.component';
import { InspectionReportComponent } from './report/inspection-report/inspection-report.component';
import { DistrictInteralSwapTableComponent } from './payments/district-interal-swap-table/district-interal-swap-table.component';
import { DistrictInteralSwapPageComponent } from './payments/district-interal-swap-page/district-interal-swap-page.component';
import { DistrictDashboardComponent } from './dashBoards/district-dashboard/district-dashboard.component';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: "login",
          component: SigninComponent
        },
        {
          path: "",
          redirectTo: "login",
          pathMatch:"full"

        },
        {
          path: "ForgotPassword",
          component:ForgotPasswordComponent
        },
        {
          path: "ResetPassword",
          component:ResetPasswordComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "home",
          component:DashboardComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "landing-page",
          component:HomeComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "profile",
          component:ProfileComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "user",
          component:UserComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "zonal",
          component:ZonalComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "agency",
          component:AgencyComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "navigation-search",
          component:NavigationComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "personal-vendors",
          component:PersonalVendorsComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path:"beneficiary-creation",
          component:BeneficiaryCreationComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path:"deduction-accounts",
          component:DeductionAccountsComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "commercial-vendors",
          component:CommercialVendorsComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "employee",
          component:EmployeeComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "employeePaymentTable",
          component:EmployeePaymentTableComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "employeePaymentVoucher",
          component:EmployeeVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "electedRepresentativeComponent",
          component:ElectedRepresentativeComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "electedRepresenativePaymentTableComponent",
          component:ElectedRepresenativePaymentTableComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "electedRepresenativePaymentlComponent",
          component:ElectedRepresenativePaymentlComponent,
          canActivate: [UserRouteAccessService] 
        },
        
        {
          path: "electedRepresenativePaymentTableComponent",
          component:ElectedRepresenativePaymentTableComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "swapPage",
          component:PaymentSwapPageComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "swapTable",
          component:PaymentSwapTableComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "RejectFailedPayments",
          component:RejectFailedPaymentsComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "account-sna",
          component:AccountSnaComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "department-list",
          component:DepartmentListComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "scheme-list",
          component:SchemeListComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "funds",
          component:FundsComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "opening-balance",
          component:OpeningBalanceComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "funds-transaction",
          component:FundsTransactionComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "limit-set",
          component:LimitSetComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "limit-translist",
          component:LimitTranslistComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "limit-formulalist",
          component:LimitFormulaComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "level-type",
          component:LevelTypeComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "level-master",
          component:LevelMasterComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "component-type",
          component:ComponentTypeComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "major-component",
          component:MajorComponentComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "minor-component",
          component:MinorComponentComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "sub-component",
          component:SubComponentComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "payment",
          component:PaymentModuleComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "PaymentVoucher",
          component:PaymentVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "payment-personal",
          component:PaymentPersonalComponent,
          canActivate: [UserRouteAccessService] 
          
        },
        {
          path: "payment-personal-voucher",
          component:PaymentPersonalVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "beneficiary-payment-list",
          component:BeneficiaryPaymentListComponent,
          canActivate: [UserRouteAccessService] 
          
        },
        {
          path: "beneficiary-payment-voucher",
          component:BeneficiaryPaymentVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "thooimai-kavalar-payment-list",
          component:ThooimaiKavalarPaymentListComponent,
          canActivate: [UserRouteAccessService] 
          
        },
        {
          path: "thooimai-kavalar-payment-voucher",
          component:ThooimaiKavalarPaymentVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "employee-bulkPay-list",
          component:EmployeeBulkPayTableComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "employeeBulkPayVoucher",
          component:EmployeeBulkPayVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "ER-bulkPay-list",
          component:ERBulkPayTableComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "ERBulkPayVoucher",
          component:ERBulkPayVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "Onboard-Report",
          component:OnboardReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "Vendor-Report",
          component:VendorReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "Payment-Report",
          component:PaymentReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "Bulk-Payment-Report",
          component:BulkPaymentReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "Employee-Onboard-Report",
          component:EmployeeOnboardReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "Voucher-Status-Report",
          component:VoucherStatusReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "Voucher-Delay-Report",
          component:NewVoucherDelayReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        
        {
          path: "AccountWise-Fund-Report",
          component:AccountwiseFundReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "Nil-Total-Balance-Report",
          component:NilBalanceReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "Transaction-Report",
          component:TranscatonReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "Employee-Count-Report",
          component:EmployeeCountReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "Payment-Not-Done-Report",
          component:PaymentNotDoneReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "employee-transfer-Report",
          component:EmployeeTransferReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "Vendor-Transaction-Report",
          component:VendorTransactionReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "Payment-Status-Report",
          component:PaymentStatusReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "Payment-Trans-Report",
          component:PaymentTransReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "Api-Request-Response-Report",
          component:ApiReqResReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "IA-Component-Report",
          component:IaComponentReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "IA-Component-Trans-Report",
          component:IaComponentTransReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "Account-Validation-Log",
          component:AccountValidationLogComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "DSC-Report",
          component:DscReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "OwnSourceRevenue",
          component:OsrFundComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "UpdateBeneficiary",
          component:UpdateBeneficiaryComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "BeneficiaryTransaction",
          component:BeneficiaryTransactionComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "fundExcelUpload",
          component:FundsExcelUploadComponent,
          canActivate: [UserRouteAccessService] 
        }, 
        
        {
          path: "blukpaymentTable",
          component:BlukPaymentTableComponent,
          canActivate: [UserRouteAccessService] 
        },

        {
          path: "blukpaymentVoucher",
          component:BlukPaymentVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
       
        {
          path: "PaymentSucessPage",
          component:PaymentSuccessComponent,
          canActivate: [UserRouteAccessService] 											
        },
        {
          path: "employee-transfer",
          component:EmployeeTransferComponent	,
          canActivate: [UserRouteAccessService] 											
        },
        {
          path: "employee-transfer-page",
          component:EmployeeTransferPageComponent,
          canActivate: [UserRouteAccessService] 												
        },
        {
          path:"twad-master-list",
          component:TwadMasterListComponent,
          canActivate: [UserRouteAccessService] 
        },

        {
          path:"Twad-List",
          component:TwadPaymentTableComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path:"Twad-Voucher",
          component:TwadPaymentVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path:"Twad-Dashboard",
          component:TwadDashboardComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path:"Tneb-Dashboard",
          component:TwadDashboardComponent,
          canActivate: [UserRouteAccessService] 
        },



        
        
        //  { path: '**', redirectTo: '/error', pathMatch: 'full' },
        // {
        //   path: "error",
        //   component:ErrorComponent												
        // },
        {
          path: "Tangedco-Bill-Details",
          component:TangedcoBillDetailsComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "account-statement",
          component:AccountStatementVillageComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "account-balances-statement",
          component:AccountStatementBalancesVillageComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "Tangedco-Master-Details",
          component:TangedcoMasterComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "Tangedco-Master-Approved",
          component:TangedcoMasterComponent,
          canActivate: [UserRouteAccessService] 
        },
        
        {
          path: "Tangedco-Payment-Table",
          component:TangedcoPaymentTableComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "Tangedco-Voucher",
          component:TangedcoPaymentVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "universal-Vendor",
          component:UniversalVendorComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "tneb-report",
          component:TnebReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "deduction-report",
          component:DeductionAccountCountReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path:"tneb-menu-configuration",
          component:MenuConfigurationComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path:"menu-enable-list",
          component:MenuEnableListComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path:"report-list",
          component:ReportListComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"push-notification",
          component:PushNotificationComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"twad-report",
          component:TwadReportComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path:"deduction-voucher-report",
          component:DeductionReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"voucher-delay-report",
          component:VoucherDelayReportComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path:"twad-office-master",
          component:TwadOfficeMasterComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path:"twad-account-summary",
          component:TwadAccountSummaryComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path:"excel-Upload-list",
          component:ExcelUploadListComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path:"Office-Master",
          component:OfficeMasterComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"twad-Upload-excel-list",
          component:ExcelUploadListComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path:"tangedco-dashboard",
          component:TnebDashboardComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path:"tangedco-payment-report",
          component:TnebPaymentReportComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path:"tangedco-transaction-report",
          component:TnebTransactionReportComponent,
          canActivate: [UserRouteAccessService]

        },
        {
          path:"tangedco-mis-report",
          component:TnebMisReportComponent,
          canActivate: [UserRouteAccessService]
        },

        {
          path:"auto-sweep-list",
          component:AutoSweepListComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"aadhar-verification-page",
          component:AadharVerificationPageComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"inspect-report",
          component:InspectionReportComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"component-swap-list",
          component:ComponentSwapTableComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"component-swap-page",
          component:ComponentSwapPageComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"Other-Funds",
          component:OtherFundsComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"Other-payment-page",
          component:PaymentVoucherComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "other-payment-list",
          component:PaymentModuleComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "district-interal-swap-table",
          component:DistrictInteralSwapTableComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "district-interal-swap-page",
          component:DistrictInteralSwapPageComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path:"funds-transfer-village-list",
          component:ComponentSwapTableComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"funds-transfer-village-page",
          component:ComponentSwapPageComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path:"district-dashboard",
          component:DistrictDashboardComponent,
          canActivate: [UserRouteAccessService]
        },
        
      ],
      { enableTracing: false }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }