import { Component } from '@angular/core';
import { OpeningBalance } from './../../module/opening-balance/opening-balance';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { SearchResult } from 'src/app/shared/models/search-result';
import { InspectionReportDto } from '../report.model';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { ReportService } from '../report.service';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { Router } from '@angular/router';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';

@Component({
  selector: 'app-cash-book-report-annual',
  templateUrl: './cash-book-report-annual.component.html',
  styleUrl: './cash-book-report-annual.component.scss'
})
export class CashBookReportAnnualComponent {
  levelMasterDialog: boolean = false;
      cols: any[];
      filterProps: any;
      searchResult: SearchResult<InspectionReportDto> = new SearchResult<InspectionReportDto>();
      submitted?: boolean;
      selectedMapping: string;
      itemsPerPage: number = 10;
      page: number;
      tamilText: any;
    
      //required fields property
      ngSelectInvalid: boolean = false;
      codeError: string;
      roleCode: any;
      villageLevelList: LevelMasterDto[];
      districtLevelList: LevelMasterDto[];
      blockLevelList: LevelMasterDto[];
      twadOfficedialog: boolean = false;
      blockMaster: any;
      districtMaster: any;
      villagelevelMaster: any;
      finYearList: FinYear[];
      finYear: FinYear;
      defaultFinyear: FinYear;
      minDate: Date;
      maxDate: Date;
      fromDate: Date;
      startDate: string;
      toDate: Date;
      endDate: string;
      dateError: boolean;
      search: any;
      componentType: any;
      compId: any;
      villageList: any;
      levelMasId: any;
      months: string[] = [
        'April', 'May', 'June', 'July', 'August', 'September',
        'October', 'November', 'December', 'January', 'February', 'March'
      ];
      month = "";
      financialData = [];
      monthfinancialData = [];
    openingBalance: any;
    closingBalance: any;
    statement: any;
  totalReciptData: any;
    
      constructor(
        private messageService: MessageService,
        private spinner: NgxSpinnerService,
        private translateService: TranslateService,
        public fontService: FontService,
        public validationService: ValidationService,
        protected notificationService: NotificationService,
        public reportService: ReportService,
        private levelMasterService: LevelMasterService,
        private limitTransListService: LimitTransListService,
        protected router: Router,
        public sessionStorage: SessionStorageService,
        public componentTypeService: ComponentTypeService,
        public dashboardService:DashboardService
      ) {
        this.translateService.onLangChange.subscribe(() => {
          this.updateDialogFont();
        });
      }
    
      async ngOnInit() {
        await this.loadCompType();
        if(this.sessionStorage.retrieve('roleCode') === 'ZVM') {
          this.dashboardService.getVillagePanchayatByZonal().subscribe(
            (res) => {
              console.log('resZonal', res);
              this.villageList = res;
            },
            () => { }
          );
        }
        this.finYearList = this.sessionStorage.retrieve('finyear');
        let FinYear = ''
        if (new Date().getMonth() + 1 >= 4) {
          FinYear =
            new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
        } else {
          FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
        }
        this.finYear = this.finYearList.find((_) => _.name === FinYear);
        this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
        this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
        if (!this.sessionStorage.retrieve('levelMaster')) {
          await this.getLevelMaster();
        }
    
        if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
          this.districtMaster = this.sessionStorage.retrieve('levelMaster');
          this.onDistrictLevelChange();
        } else if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
          this.blockMaster = this.sessionStorage.retrieve('levelMaster');
          this.onBlockLevelChange();
        }
        else if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'VILLAGE') {
          
        }
        this.onStateLevelChange();
    
    
      }
    
    
      ngAfterViewInit() {
        this.updateDialogFont();
    
      }
    
      private updateDialogFont() {
        const lang = this.translateService.currentLang;
        const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    
        // Update label fonts
        const labels = document.querySelectorAll('.form-control-label');
        if (labels) {
          labels.forEach((label) => {
            if (lang === 'en') {
              label.classList.add('english-label');
              label.classList.remove('tamil-label');
            } else {
              label.classList.add('tamil-label');
              label.classList.remove('english-label');
            }
          });
        }
      }
    
    
      loadPage(type): void {
        if (!this.compId) {
          this.notificationService.alertError('please Select Component', '');
          return;
        }
        let filter = {
          finYearId: this.finYear.id,
          componentMasterId:this.compId.id
          
        };
        if ((this.districtMaster || this.blockMaster || this.villagelevelMaster) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE') {
          console.log('districtfilter')
          this.search = true;
        }
        else if ((this.blockMaster || this.villagelevelMaster) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
          this.search = true;
        }
        else if ((this.villagelevelMaster || this.levelMasId) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
          this.search = true;
         
        }
        else if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'VILLAGE') {
          this.search = true;
        }
        else {
          this.search = false;
        }
        if (this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE') {
          if (this.districtMaster && this.blockMaster && this.villagelevelMaster) {
            
            filter['levelMasterId'] = this.villagelevelMaster.id;
          }
          else {
    
            this.notificationService.alertError('Please select a district, block, and village to proceed.', '');
            this.spinner.hide();
            return
    
          }
        }
        if (this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
          if (this.blockMaster && this.villagelevelMaster) {
            filter['levelMasterId'] = this.villagelevelMaster.id;
          }
          else {
    
            this.notificationService.alertError('Please select a block, and village to proceed.', '');
            this.spinner.hide();
            return
    
          }
        }
        if (this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
         
          if (this.villagelevelMaster || this.levelMasId) {
            filter['levelMasterId'] = this.levelMasId ? this.levelMasId :this.villagelevelMaster.id;
          }
          else {
            this.search = false;
            this.notificationService.alertError('Please select a village to proceed.', '');
            this.spinner.hide();
            return
            
          }
        }
        if (this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'VILLAGE') {
          filter['levelMasterId'] = this.sessionStorage.retrieve('levelMaster').id;
         
        }
        console.log('levelmaster',filter)
        if (this.search) {
          this.spinner.show();
          this.reportService
            .getCashBookMonthWiseReport({ ...filter })
            .subscribe(
              (res: HttpResponse<any>) => {
                console.log('res',res)
                this.totalReciptData = res.body;
                this.statement = res.body.cashBookMonthWiseReportDtoList;
                this.spinner.hide();
              },
              () => {
                this.spinner.hide();
              }
            );
        }
    
      }
      pad(number: number): string {
        return number < 10 ? '0' + number : number.toString();
      }
      getFinyear(): Promise<any> {
        return new Promise<any>((resolve) => {
          this.limitTransListService.getFinYear().subscribe(
            (res: HttpResponse<FinYear[]>) => {
              this.finYearList = res.body;
              let FinYear = ''
              if (new Date().getMonth() + 1 >= 4) {
                FinYear =
                  new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
              } else {
                FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
              }
              this.finYear = this.finYearList.find((_) => _.name === FinYear);
              this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
              this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
              this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
              this.maxDate = new Date();
              resolve(this.roleCode)
            },
            () => { }
          )
        });
      }
    
      parseDate(dateString) {
        const [day, month, year] = dateString.split('/');
        return new Date(`${month}/${day}/${year}`);
      }
    
      onchangeFinYear() {
        if (this.finYear.name === this.defaultFinyear.name) {
          this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
          this.maxDate = new Date();
        }
        else {
          this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
          this.maxDate = this.parseDate('31/03/' + this.finYear.name.split('-')[1]);
        }
      }
    
      getLevelMaster(): Promise<any> {
        return new Promise<any>((resolve) => {
          this.levelMasterService.getLevelMaster(this.sessionStorage.retrieve('levelmasterId')).subscribe(
            (res) => {
              this.sessionStorage.store('levelMaster', res.body);
              resolve('');
            },
            (onError) => {
              resolve(null);
            }
          );
        });
      }
     
    
      onStateLevelChange($event?) {
        if ($event) {
          let filterQuery = {
            page: 0,
            size: 50,
          };
          this.levelMasterService
            .LevelMasterDtoFilter({ ...filterQuery }, [
              {
                key: 'parentId',
                operation: 'equals',
                value: 1,
              },
              {
                key: 'name',
                operation: 'contains',
                value: $event.term.toUpperCase(),
              },
            ])
            .subscribe(
              (res: HttpResponse<LevelMasterDto[]>) => {
                this.districtLevelList = res.body;
              },
              () => { }
            );
        } else {
          let filterQuery = {
            page: 0,
            size: 50,
          };
          this.levelMasterService
            .LevelMasterDtoFilter({ ...filterQuery }, [
              {
                key: 'parentId',
                operation: 'equals',
                value: 1,
              },
            ])
            .subscribe(
              (res: HttpResponse<LevelMasterDto[]>) => {
                this.districtLevelList = res.body;
              },
              () => { }
            );
        }
      }
    
    
      onDistrictLevelChange($event?) {
        this.blockMaster = null;
        if ($event) {
          let filterQuery = {
            page: 0,
            size: 50,
          };
          this.levelMasterService
            .LevelMasterDtoFilter({ ...filterQuery }, [
              {
                key: 'parentId',
                operation: 'equals',
                value: this.districtMaster.id,
              },
              {
                key: 'name',
                operation: 'contains',
                value: $event.term.toUpperCase(),
              },
            ])
            .subscribe(
              (res: HttpResponse<LevelMasterDto[]>) => {
                this.blockLevelList = res.body;
              },
              () => { }
            );
        } else {
          let filterQuery = {
            page: 0,
            size: 50,
          };
          this.levelMasterService
            .LevelMasterDtoFilter({ ...filterQuery }, [
              {
                key: 'parentId',
                operation: 'equals',
                value: this.districtMaster.id,
              },
            ])
            .subscribe(
              (res: HttpResponse<LevelMasterDto[]>) => {
                this.blockLevelList = res.body;
              },
              () => { }
            );
        }
      }
    
    
    
      onBlockLevelChange($event?) {
        this.villagelevelMaster = null;
        this.villageLevelList = [];
        if ($event) {
          let filterQuery = {
            page: 0,
            size: 50,
          };
          this.levelMasterService
            .LevelMasterDtoFilter({ ...filterQuery }, [
              {
                key: 'parentId',
                operation: 'equals',
                value: this.blockMaster.id,
              },
              {
                key: 'name',
                operation: 'contains',
                value: $event.term.toUpperCase(),
              },
            ])
            .subscribe(
              (res: HttpResponse<LevelMasterDto[]>) => {
                this.villageLevelList = res.body;
              },
              () => { }
            );
        } else {
          let filterQuery = {
            page: 0,
            size: 50,
          };
          this.levelMasterService
            .LevelMasterDtoFilter({ ...filterQuery }, [
              {
                key: 'parentId',
                operation: 'equals',
                value: this.blockMaster.id,
              },
            ])
            .subscribe(
              (res: HttpResponse<LevelMasterDto[]>) => {
                this.villageLevelList = res.body;
              },
              () => { }
            );
        }
      }
    
    
    
      loadCompType(): Promise<any> {
        return new Promise<any>((resolve) => {
          this.componentTypeService.find(4).subscribe(
            (res: HttpResponse<any>) => {
              this.componentType = res.body.filter(item => item.componentCode === 'C' || item.componentCode === 'D' || item.componentCode === 'B' || item.componentCode === 'A');
              resolve(this.componentType);
            },
            () => { resolve(null); }
          )
        });
      }
    
     
  
}
