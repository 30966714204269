import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { LevelType } from 'src/app/module/level-type/level-type';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { VendorTransaction } from 'src/app/payments/payment-voucher/payment-voucher.model';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { environment } from 'src/environments/environment';
import { DeductionBillDescription, DeductionReportList, DeductionTransaction, DeductionVoucherGenerationDto } from '../deduction.model';
import { DatePipe, Location } from '@angular/common';
import { DeductionService } from '../deduction-service';
import { deductionDto } from 'src/app/module/beneficiary-creation/beneficiary.model';
import { BeneficiaryService } from 'src/app/module/beneficiary-creation/beneficiary-service.service';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import { filterQuery } from 'src/app/shared/common.model';
import { CommercialVendors } from 'src/app/module/commercial-vendors/commercial-vendors';

@Component({
  selector: 'app-deduction-payment-page',
  templateUrl: './deduction-payment-page.component.html',
  styleUrl: './deduction-payment-page.component.scss'
})
export class DeductionPaymentPageComponent {
  @ViewChild('dialog') dialog: Dialog;
  @ViewChild('formData') formData;
  enableStateGst: boolean;
  enableIGst: boolean;
  pdfString: any;
  levelType: LevelType[];
  levelMasterDto: LevelType;
  voucherGenerationDto: DeductionVoucherGenerationDto;
  date: Date;
  voucherNo: string;
  vendorList: CommercialVendors[];
  selectedscheme: any;
  profileDialog: boolean = false;
  totalDeduction: number;
  dropDownLabel: any;
  maxDate = new Date();
  amount: number;
  description: string;
  hsn: string;
  quantity: number;
  signButtonName: any;
  noRecord: boolean;
  isFileUploaded: boolean;
  uFilename: string;
  viewDoc: boolean = false;
  vFileExt: string;
  vFileUrl: string;
  currentPage: number = 0;
  loadingMore: boolean = false;
  vendorSearchOption: string = 'selectOption';
  accountNo: string;
  cols = [
    {
      field: 'voucherNo',
      header: 'Voucher No',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialVendorName',
      header: 'Vendor Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'commercialAccountNo',
      header: 'Vendor Account',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'transDate',
      header: 'Transaction Date',
      isSortable: true,
      isSelectcolumn: true,
      type: 'date',
    },
    {
      field: 'grossAmount',
      header: 'Rate',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
    {
      field: 'transactionAmount',
      header: 'Total Transction Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
      isFilterable: false,
    },
  ];

  // validation fields properties
  public ifscError = false;
  public panError = false;
  public hsnError = false;

  //required fields property
  ngSelectInvalid: boolean = false;
  filterProps: any;
  securityDeposit: number;
  enableGstOnTds: boolean;
  uploadedFiles: any;
  enableLabourChess: boolean;
  enableSecurityDeposit: boolean;
  roleCode: any;
  readonly: boolean;
  enableRevertButton: boolean;
  param1: any;
  param2: any;
  param3: any;
  enableSignButton: boolean;
  downloadButton: boolean;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  enablesign: boolean = false;
  enableRePaymentButton: boolean;
  disablepaymentButton: boolean = true;
  buttonText: string;
  checkstatusButton: boolean;
  interactionId: any;
  checkAmount: boolean;
  zonalList: LevelType;
  selectedZonalUser: any;
  enableZonalButton: boolean;
  enableApproved: boolean;
  checkForPaymentStatus: boolean;
  componentMasterTable: any;
  typedValue: any;
  filterTimeout: any;
  hasMoreItemsToLoad: boolean = true;
  deleteDialog: boolean;
  departmentName: any;
  schemeName: any;
  disableDownloadButton: boolean = false;
  finYear: FinYear[];
  finYearDto: FinYear;
  enableView: boolean;
  month: Date;
  deductionList: any;
  maxSelectableDate: Date;
  startDate: string;
  endDate: string;
  itemsPerPage: number = 10;
  page: number;
  deductionVoucher: DeductionReportList[];
  isSelectAll: boolean;
  totalAmount: number;
  headingText: string;
  currentPath: string;
  listUrl: string;
  voucherUrl: string;
  selectedVendor: any;
  uniqueId: any;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private http: HttpClient,
    private sessionStorage: SessionStorageService,
    private levelMasterService: LevelMasterService,
    private limitTransListService: LimitTransListService,
    private datePipe: DatePipe,
    private deductionService: DeductionService,
    private beneficaryService: BeneficiaryService,
    private location: Location,
    private commercialVendorService: CommercialVendorService,
  ) {
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => { });
    }
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    });
  }

  async ngOnInit(): Promise<void> {
    this.currentPath = this.location.path();
    await this.getRole();
    const today = new Date();
    this.maxSelectableDate = new Date(today.getFullYear(), today.getMonth()+1, 0, 23, 59, 59, 999);
    if (
      this.roleCode.role.code === 'DC2' ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'VC2'
    ) {
      this.enableApproved = true;
      this.signButtonName = 'Perform e-Sign President';
      this.enableRevertButton = true;
      this.downloadButton = true;
      this.readonly = true;
    }
    else if (
      this.roleCode.role.code === 'DMAKER' ||
      this.roleCode.role.code === 'VMAKER' ||
      this.roleCode.role.code === 'BMAKER'
    ) {
      this.signButtonName = 'Perform e-Sign Secretary';
    }

    else if (this.roleCode.role.code === 'DC1' ||
      this.roleCode.role.code === 'BC1' ||
      this.roleCode.role.code === 'VC1') {
      this.signButtonName = 'Perform e-Sign  VP';
      this.enableRevertButton = true;
      this.enableApproved = true;
      this.readonly = true;
    }

    // if (this.activatedRoute.snapshot.queryParamMap.get('response')) {
    //   this.paymentSucessDialog = true;
    //   this.paymentSucessText =
    //     this.activatedRoute.snapshot.queryParamMap.get('response');
    //   this.disablepaymentButton = true;
    // }
    // if (this.activatedRoute.snapshot.queryParamMap.get('error')) {
    //   this.notificationService.alertError(
    //     'Error Occurred: ' +
    //       this.activatedRoute.snapshot.queryParamMap.get('error'),
    //     ''
    //   );
    // }
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      switch (true) {
        case this.currentPath.startsWith('/Statutory-deduction-page'):
          this.headingText = 'Statutory Payment';
          this.listUrl = '/Statutory-deduction-list';
          this.voucherUrl = '/Statutory-deduction-page';
          this.sessionStorage.store('paymentType','Statutory');

          break;

        case this.currentPath.startsWith('/Flag-deduction-page'):
          this.headingText = 'Flag Day Payment';
          this.listUrl = '/Flag-deduction-list';
          this.voucherUrl = '/Flag-deduction-page';
          this.sessionStorage.store('paymentType','Flag');
          break;
        case this.currentPath.startsWith('/Security-deposit-deduction-page'):
          this.headingText = 'Security Deposit Payment';
          this.listUrl = '/Security-deposit-deduction-list';
          this.voucherUrl = '/Security-deposit-deduction-page';
          this.sessionStorage.store('paymentType','Security');

          
          break;
      }
      if (this.activatedRoute.snapshot.queryParamMap.get('view')) {
        this.enableView = true;
        this.readonly = true;
      }
      this.spinner.show();
      this.deductionService
        .getVocher(
          Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
        )
        .subscribe(
          (res: HttpResponse<DeductionVoucherGenerationDto>) => {
            this.voucherGenerationDto = res.body;
            if (this.voucherGenerationDto.deductionTransaction.signStatus && !this.enableView) {
              this.enableSignButton = true;
            }
            console.log('res.body', this.voucherGenerationDto);

            // if (this.voucherGenerationDto. && !this.enableView) {

            //   this.readonly = true;
            //   this.getfile();
            // }
            if (this.voucherGenerationDto.deductionTransaction.signaturePresident) {
              switch (this.voucherGenerationDto.deductionTransaction.transactionStatus) {
                case 'O.K.':
                  this.voucherGenerationDto.deductionTransaction.transactionStatus = 'Success';
                  break;
                case 'failed':
                  this.voucherGenerationDto.deductionTransaction.transactionStatus = 'Failed';
                  break;
                case 'processing':
                  this.voucherGenerationDto.deductionTransaction.transactionStatus = 'Processing';
                  break;
                default:
                  break;
              }
            }
            if (this.voucherGenerationDto.deductionTransaction.signStatus && !this.enableView) {
              this.readonly = true;
              this.enableSignButton = true;
            }
            this.date = new Date(
              this.voucherGenerationDto.deductionTransaction.transactionDate
            );
            this.departmentName =
              this.voucherGenerationDto.deductionTransaction.implementingAgency.department.name;
            this.schemeName =
              this.voucherGenerationDto.deductionTransaction.implementingAgency.scheme.name;
            this.levelMasterDto =
              this.voucherGenerationDto.deductionTransaction.levelMaster.levelType;
            this.spinner.hide();
            const [monthString, yearString] = this.voucherGenerationDto.deductionTransaction.deductionMonth.split('/');
            const date = new Date(Number(yearString), Number(monthString) - 1, 1);
            this.month = date;
          },
          () => { }
        );
    }
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.voucherGenerationDto = new DeductionVoucherGenerationDto();
      this.voucherGenerationDto.deductionTransaction = new DeductionTransaction();
      switch (true) {
        case this.currentPath.startsWith('/Statutory-deduction-page'):
          this.headingText = 'Statutory Payment';
          this.listUrl = '/Statutory-deduction-list';
          this.deductionList = ['IT', 'GST', 'LWF'];
          this.voucherUrl = '/Statutory-deduction-page';
          this.sessionStorage.store('paymentType','Statutory');
          this.loadAccounts();
          break;

        case this.currentPath.startsWith('/Flag-deduction-page'):
          this.headingText = 'Flag Day Payment';
          this.voucherGenerationDto.deductionTransaction.deductionType = 'FD';
          this.listUrl = '/Flag-deduction-list';
          this.voucherUrl = '/Flag-deduction-page';
          this.sessionStorage.store('paymentType','Flag');
          this.loadAccounts();
          break;
        case this.currentPath.startsWith('/Security-deposit-deduction-page'):
          this.headingText = 'Security Deposit Payment';
          this.voucherGenerationDto.deductionTransaction.deductionType = 'SD';
          this.listUrl = '/Security-deposit-deduction-list';
          this.voucherUrl = '/Security-deposit-deduction-page';
          this.vendorFilter();
          this.sessionStorage.store('paymentType','Security');
          break;
      }

      
      this.limitTransListService.getFinYear().subscribe(
        (res: HttpResponse<FinYear[]>) => {
          this.finYear = res.body;
          let FinYear: string;
          if (new Date().getMonth() + 1 >= 4) {
            FinYear =
              new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
          } else {
            FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
          }
          this.voucherGenerationDto.deductionTransaction.finYear = this.finYear.find((_) => _.name === FinYear);
        },
        () => { }
      );
      this.paymentService
        .getByImplemeting(this.roleCode.implementingAgencyId)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.voucherGenerationDto.deductionTransaction.implementingAgency = res.body;
            this.departmentName = res.body.departmentDto.name;
            this.schemeName = res.body.schemeDto.name;

            this.levelMasterService
              .getLevelMaster(
                this.voucherGenerationDto.deductionTransaction.implementingAgency
                  .levelMaster
              )
              .subscribe(
                (res: HttpResponse<any>) => {
                  this.levelMasterDto = res.body.levelTypeDto;
                  this.voucherGenerationDto.deductionTransaction.levelMaster = res.body;
                },
                () => { }
              );

          },
          () => { }
        );

      this.voucherGenerationDto.deductionTransaction.voucherNumber =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.date = new Date();
    }
  }

  redirect() {
    // this.router.navigate(['/blukpaymentTable'], {
    //   queryParams: {
    //     id: String(this.voucherGenerationDto.parentVoucher.id),
    //   },
    // });
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode);
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }



  ngAfterViewInit() {
    this.updateDialogFont();
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => { });
    }
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  genVocher() {
    this.ngSelectInvalid = false;
    const isFormValid =

      this.levelMasterDto &&
      this.voucherGenerationDto.deductionTransaction.remarks &&
      this.voucherGenerationDto.deductionTransaction.transactionAmount &&
      this.voucherGenerationDto.deductionTransaction.deductionType &&
      this.month

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Please fill all the mandotory fields marked with *", '');
      return;
    }

    if(this.voucherGenerationDto.deductionTransaction.deductionType === 'SD' && this.voucherGenerationDto?.deductionTransaction?.commercialVendor == null) {
      this.notificationService.alertError("Please select the vendor", '');
      return;
    }

    if(this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD' && this.voucherGenerationDto?.deductionTransaction?.deductionAccount == null) {
      this.notificationService.alertError("Deduction Account Not Created", '');
      return;
    }

    let month = '';
    if ((this.month.getMonth() + 1).toString().length === 1) {
      month = '0' + (this.month.getMonth() + 1).toString();
    }
    else {
      month = (this.month.getMonth() + 1).toString();
    }
    this.voucherGenerationDto.deductionTransaction.deductionMonth = month + '/' + this.month.getFullYear().toString();
    this.spinner.show();
    this.deductionService.genVocher(this.voucherGenerationDto).subscribe(
      (res) => {
        this.enableSignButton = true;
        this.spinner.hide();
        // this.getfile();
      },
      (onError) => {
        this.spinner.hide();
        if (onError.status == 400) {
          this.notificationService.alertError(onError.error.title, '');
        } else {
          this.notificationService.alertError('An unexpected error occurred.', '');
        }
      }
    );
  }

  getPdf() {
    this.paymentService
      .getDeductionPdfFile(this.voucherGenerationDto.deductionTransaction.voucherNumber)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);

        },
        (err) => {
          // this.spinner.hide();
          // this.notificationService.alertError('Error!', '');
        }
      );
  }

  submitForm() {
    if(this.roleCode.role.code !== 'VC2') {
      this.sessionStorage.store('voucherNo',this.voucherGenerationDto.deductionTransaction.voucherNumber);
      this.sessionStorage.store('userId',this.roleCode.id);
      this.sessionStorage.store('otpAttempts',3);
      this.router.navigateByUrl('/aadhar-verification-page');
    }
    else {
      this.spinner.show();
      this.deductionService
        .getfile(this.voucherGenerationDto.deductionTransaction.voucherNumber)
        .subscribe(
          (res: HttpResponse<any>) => {
            console.log('ress', res.body);
            this.spinner.hide();
            this.param1 = res.body.paramOne;
            this.param2 = res.body.paramTwo;
            this.param3 = res.body.paramThree;
            if (!this.voucherGenerationDto.deductionTransaction.id) {
              this.router.navigate([this.voucherUrl], {
                queryParams: {
                  id: res.body.paramFour
                },
              });
            }
            this.sessionStorage.store('refresh', 'Yes');
            const formElement = document.createElement('form');
            formElement.method = 'POST';
            formElement.action = environment.CDAC_URL;
            let inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "eSignRequest";
            inputElement.value = res.body.paramOne;
            formElement.appendChild(inputElement);
            inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "aspTxnID";
            inputElement.value = res.body.paramTwo;
            formElement.appendChild(inputElement);
            inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "Content-Type";
            inputElement.value = res.body.paramThree;
            formElement.appendChild(inputElement);
            document.body.appendChild(formElement);
            formElement.submit();
          },
          (error) => {
            this.spinner.hide();
            console.log('error', error);
            if (
              this.roleCode.role.code === 'DMAKER' ||
              this.roleCode.role.code === 'VMAKER' ||
              this.roleCode.role.code === 'BMAKER'
            ) {
              this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: ' + this.voucherGenerationDto.deductionTransaction.voucherNumber, '');
              this.router.navigate([this.listUrl], {});
            }
            else {
              this.deleteDialog = true;
            }
          }
        );
    }
   

  }




  getActionUrl() {
    this.sessionStorage.store('refresh', 'Yes');
    return environment.CDAC_URL;
  }

  loadStatutoryBillDescription(page) {
    console.log('this.month', this.month);
    let month = this.month.getMonth();
    let year = this.month.getFullYear();
    console.log('this.month',new Date(year, month, 1));
    console.log('maxSelectableDate',this.maxSelectableDate);
    if (this.maxSelectableDate < new Date(year, month, 1)) {
      this.notificationService.alertError('This Month ' + this.month.toLocaleString('default', { month: 'long' }) + ' Statutory Payments Will  Not Allowed', '');
      this.month = new Date();
      this.month = null;
      if (this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD' && this.voucherGenerationDto.deductionTransaction.deductionType !== 'FD') {
        this.voucherGenerationDto.deductionTransaction.deductionType = null;
      }
      this.voucherGenerationDto.deductionBillDescriptionList = [];

      return
    }
    console.log('this.monthout', this.month);
    if (this.month && this.voucherGenerationDto.deductionTransaction.deductionType) {
      const lastDay = new Date(year, month + 1, 0).getDate();
      const date = new Date(this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd HH:mm:ss.SSS'))
      this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
      const enddate = new Date(this.datePipe.transform(new Date(year, month + 1, 0, 23, 59, 59, 999), 'yyyy-MM-dd HH:mm:ss.SSS'));
      this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} ${this.pad(enddate.getHours())}:${this.pad(enddate.getMinutes())}:${this.pad(enddate.getSeconds())}`;

      const pageToLoad: number = page ?? this.page ?? 1;
      let filter = {
        deductionCode: this.voucherGenerationDto.deductionTransaction.deductionType,
        levelMasterId: this.voucherGenerationDto.deductionTransaction.levelMaster.id,
        fromDate: this.startDate,
        toDate: this.endDate
      };
      if (this.voucherGenerationDto.deductionTransaction.deductionType === 'SD' && this.voucherGenerationDto.deductionTransaction.commercialVendor) {
        filter['commercialVendorId'] = this.voucherGenerationDto.deductionTransaction.commercialVendor.id;
      }
      this.spinner.show();
      this.deductionService.getDeductionPaymentList
        (filter).subscribe(
          (response) => {
            this.spinner.hide();
            console.log('res', response);
            this.deductionVoucher = response.body;
            this.totalAmount = this.deductionVoucher.reduce((total, deduction) => total + deduction.deductionAmount, 0);
            console.log('this,voucher', this.voucherGenerationDto)
          },
          (onError) => {
            this.spinner.hide();

            if (onError.status == 400) {
              this.notificationService.alertError(
                onError.error.title, '');
            } else {
              this.notificationService.alertError('Error!', '');
            }
          }
        )
    }
  }

  pad(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }


  revert() {
    this.spinner.show();
    this.deductionService
      .revertTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate([this.listUrl], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  reject() {
    this.spinner.show();
    this.deductionService
      .rejectTransaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate([this.listUrl], {});
        },
        (onError) => {
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.title, '');
          } else {
            this.notificationService.alertError('Error in Reject', '');
          }

          this.spinner.hide();
        }
      );
  }

  selectAll() {
    this.voucherGenerationDto.deductionBillDescriptionList = [];
    this.deductionVoucher.forEach(element => {
      let deductionBillDescription = new DeductionBillDescription();
      if (this.isSelectAll) {
        element.isSelect = true;
        deductionBillDescription.deductionType = this.voucherGenerationDto.deductionTransaction.deductionType;
        deductionBillDescription.deductionPercentage = element.deductionPercentage;
        deductionBillDescription.deductionAmount = element.deductionAmount;
        deductionBillDescription.voucherNumber = element.queueNo;
        deductionBillDescription.permanentVoucherNumber = element.voucherNo;
        deductionBillDescription.vendorName = element.vendorName;
        deductionBillDescription.vendorGstNumber = element.gstNo;
        deductionBillDescription.vendorPanNumber = element.panNo;
        this.voucherGenerationDto.deductionBillDescriptionList.push(deductionBillDescription);
        this.voucherGenerationDto.deductionTransaction.transactionAmount += element.deductionAmount;
      }
      else {
        element.isSelect = false;
        this.voucherGenerationDto.deductionBillDescriptionList = [];
        this.voucherGenerationDto.deductionTransaction.transactionAmount = 0;
      }
    });
  }

  selectDeduction(element: DeductionReportList) {
    this.voucherGenerationDto.deductionBillDescriptionList = this.voucherGenerationDto.deductionBillDescriptionList ? this.voucherGenerationDto.deductionBillDescriptionList : [];
    let deductionBillDescription = new DeductionBillDescription();
    if (element.isSelect) {
      deductionBillDescription.deductionType = this.voucherGenerationDto.deductionTransaction.deductionType;
      deductionBillDescription.deductionPercentage = element.deductionPercentage;
      deductionBillDescription.deductionAmount = element.deductionAmount;
      deductionBillDescription.voucherNumber = element.queueNo;
      deductionBillDescription.permanentVoucherNumber = element.voucherNo;
      deductionBillDescription.vendorName = element.vendorName;
      deductionBillDescription.vendorGstNumber = element.gstNo;
      deductionBillDescription.vendorPanNumber = element.panNo;
      deductionBillDescription.vendorAccountNumber = element.vendorAccountNumber;
      this.voucherGenerationDto.deductionBillDescriptionList.push(deductionBillDescription);
    }
    else {
      element.isSelect = false;
      let index = this.voucherGenerationDto.deductionBillDescriptionList.findIndex(_ => _.voucherNumber === element.queueNo);
      this.voucherGenerationDto.deductionBillDescriptionList.splice(index, 1);
    }
    this.voucherGenerationDto.deductionTransaction.transactionAmount = this.voucherGenerationDto.deductionBillDescriptionList.reduce((total, deduction) => total + deduction.deductionAmount, 0);

  }

  loadAccounts() {
    let filterQuery = { page: 0, size: 1 ,activeFlag:'Active',isApproved:true}
    this.beneficaryService
      .validFilterDeduction({ ...filterQuery })
      .subscribe(
        (res: HttpResponse<deductionDto[]>) => {
          if(res.body.length !== 0) {
            this.voucherGenerationDto.deductionTransaction.deductionAccount = res.body[0];
            console.log(' this.voucherGenerationDto.deductionTransaction.deductionAccount', this.voucherGenerationDto.deductionTransaction.deductionAccount);
          }
          else {
            this.notificationService.alertError('Please Add Deduction Account','');
          }

        },
        () => {

        }
      );
  }

  vendorFilterByAccount(type) {
    let filter = [];
    if (this.accountNo && type === 'AccountNo') {
      filter = [{
        key: 'accountNumber',
        operation: 'equals',
        value: this.accountNo,
      }];
    }
    else {
      if (this.uniqueId) {
        filter = [{
          key: 'uniqueId',
          operation: 'equals',
          value: this.uniqueId,
        }];
      }
    }
    this.commercialVendorService
      .filter({ size: 1, page: 0 }, filter)
      .subscribe(
        (res: HttpResponse<any>) => {
          console.log('res,body')
          if (res.body.length !== 0) {
            this.voucherGenerationDto.deductionTransaction.commercialVendor = res.body[0];
            this.loadStatutoryBillDescription(1);
          }
          else {
            this.selectedVendor = null;
            if (type === 'AccountNo') {
              this.notificationService.alertInfo('Vendor with given Account number not found', '');
            }
            else {
              this.notificationService.alertInfo('Vendor with given Unique number not found', '');
            }
          }
        },
        () => { }
      );

  }

 

  search($event) {
    this.typedValue = $event.term;
    this.hasMoreItemsToLoad = true;
    this.currentPage = 0;
  }

  vendorFilter() {
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }
    if (this.typedValue) {
      this.filterTimeout = setTimeout(() => {
        let filter = new filterQuery();
        filter.key = 'name';
        filter.operation = 'contains';
        filter.value = this.typedValue.toUpperCase();
        let filterquery = [];
        filterquery.push(filter);
        this.commercialVendorService
          .filter({ size: 10, page: 0 }, filterquery)
          .subscribe(
            (res: HttpResponse<any>) => {
              this.vendorList = res.body;
            },
            () => { }
          );
      }, 2000);
    } else {
      this.commercialVendorService.filter({ size: 10, page: 0 }, []).subscribe(
        (res: HttpResponse<any>) => {
          this.vendorList = res.body;
        },
        () => { }
      );
    }
  }

  loadMoreItems(page: number) {
    if (this.hasMoreItemsToLoad) {
      let filter = [];
      if (this.typedValue) {
        filter = [{
          key: 'name',
          operation: 'contains',
          value: this.typedValue.toUpperCase(),
        }];
      }

      const paginationOptions = { size: 10, page };

      return this.commercialVendorService.filter(paginationOptions, filter);
    }
    return
  }

  onScrollToEnd() {
    console.log('scroll to end triggered')
    if (!this.loadingMore && this.hasMoreItemsToLoad) {
      this.loadingMore = true;
      this.currentPage++;

      this.loadMoreItems(this.currentPage).subscribe(
        (res: HttpResponse<any>) => {
          const additionalItems = res.body;

          if (additionalItems && additionalItems.length > 0) {
            if (additionalItems.length < 10) {
              this.vendorList = this.vendorList.concat(additionalItems);
              this.hasMoreItemsToLoad = false;
            } else {
              this.vendorList = this.vendorList.concat(additionalItems);
            }
          } else {
            this.hasMoreItemsToLoad = false;
          }

          this.loadingMore = false;
        },
        () => {
          this.loadingMore = false;
        }
      );
    }
  }

}
