import { OpeningBalance } from './../../module/opening-balance/opening-balance';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { SearchResult } from 'src/app/shared/models/search-result';
import { InspectionReportDto } from '../report.model';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { ReportService } from '../report.service';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { Router } from '@angular/router';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';

@Component({
  selector: 'app-cash-book-report-montly',
  templateUrl: './cash-book-report-montly.component.html',
  styleUrl: './cash-book-report-montly.component.scss'
})
export class CashBookReportMontlyComponent {
  levelMasterDialog: boolean = false;
    cols: any[];
    filterProps: any;
    searchResult: SearchResult<InspectionReportDto> = new SearchResult<InspectionReportDto>();
    submitted?: boolean;
    selectedMapping: string;
    itemsPerPage: number = 10;
    page: number;
    tamilText: any;
  
    //required fields property
    ngSelectInvalid: boolean = false;
    codeError: string;
    roleCode: any;
    villageLevelList: LevelMasterDto[];
    districtLevelList: LevelMasterDto[];
    blockLevelList: LevelMasterDto[];
    twadOfficedialog: boolean = false;
    blockMaster: any;
    districtMaster: any;
    villagelevelMaster: any;
    finYearList: FinYear[];
    finYear: FinYear;
    defaultFinyear: FinYear;
    minDate: Date;
    maxDate: Date;
    fromDate: Date;
    startDate: string;
    toDate: Date;
    endDate: string;
    dateError: boolean;
    search: any;
    componentType: any;
    compId: any;
    villageList: any;
    levelMasId: any;
    months: string[] = [
      'April', 'May', 'June', 'July', 'August', 'September',
      'October', 'November', 'December', 'January', 'February', 'March'
    ];
    month = "";
    financialData = [];
    monthfinancialData = [];
  openingBalance: any;
  closingBalance: any;
  statement: any;
  
    constructor(
      private messageService: MessageService,
      private spinner: NgxSpinnerService,
      private translateService: TranslateService,
      public fontService: FontService,
      public validationService: ValidationService,
      protected notificationService: NotificationService,
      public reportService: ReportService,
      private levelMasterService: LevelMasterService,
      private limitTransListService: LimitTransListService,
      protected router: Router,
      public sessionStorage: SessionStorageService,
      public componentTypeService: ComponentTypeService,
      public dashboardService:DashboardService
    ) {
      this.translateService.onLangChange.subscribe(() => {
        this.updateDialogFont();
      });
    }
  
    async ngOnInit() {
      await this.loadCompType();
      if(this.sessionStorage.retrieve('roleCode') === 'ZVM') {
        this.dashboardService.getVillagePanchayatByZonal().subscribe(
          (res) => {
            console.log('resZonal', res);
            this.villageList = res;
          },
          () => { }
        );
      }
      this.finYearList = this.sessionStorage.retrieve('finyear');
      let FinYear = ''
      if (new Date().getMonth() + 1 >= 4) {
        FinYear =
          new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
      } else {
        FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
      }
      this.finYear = this.finYearList.find((_) => _.name === FinYear);
      this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
      this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
      if (!this.sessionStorage.retrieve('levelMaster')) {
        await this.getLevelMaster();
      }
  
      if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
        this.districtMaster = this.sessionStorage.retrieve('levelMaster');
        this.onDistrictLevelChange();
      } else if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
        this.blockMaster = this.sessionStorage.retrieve('levelMaster');
        this.onBlockLevelChange();
      }
      else if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'VILLAGE') {
        
      }
      this.onStateLevelChange();
  
  
    }
  
  
    ngAfterViewInit() {
      this.updateDialogFont();
  
    }
  
    private updateDialogFont() {
      const lang = this.translateService.currentLang;
      const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
  
      // Update label fonts
      const labels = document.querySelectorAll('.form-control-label');
      if (labels) {
        labels.forEach((label) => {
          if (lang === 'en') {
            label.classList.add('english-label');
            label.classList.remove('tamil-label');
          } else {
            label.classList.add('tamil-label');
            label.classList.remove('english-label');
          }
        });
      }
    }
  
  
    loadPage(type): void {
      if (!this.compId) {
        this.notificationService.alertError('please Select Component', '');
        return;
      }
      let filter = {
        finYearId: this.finYear.id,
        componentMasterId:this.compId.id
        
      };
      if ((this.districtMaster || this.blockMaster || this.villagelevelMaster) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE') {
        console.log('districtfilter')
        this.search = true;
      }
      else if ((this.blockMaster || this.villagelevelMaster) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
        this.search = true;
      }
      else if ((this.villagelevelMaster || this.levelMasId) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
        this.search = true;
       
      }
      else if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'VILLAGE') {
        this.search = true;
      }
      else {
        this.search = false;
      }
      if (this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE') {
        if (this.districtMaster && this.blockMaster && this.villagelevelMaster) {
          
          filter['levelMasterId'] = this.villagelevelMaster.id;
        }
        else {
  
          this.notificationService.alertError('Please select a district, block, and village to proceed.', '');
          this.spinner.hide();
          return
  
        }
      }
      if (this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
        if (this.blockMaster && this.villagelevelMaster) {
          filter['levelMasterId'] = this.villagelevelMaster.id;
        }
        else {
  
          this.notificationService.alertError('Please select a block, and village to proceed.', '');
          this.spinner.hide();
          return
  
        }
      }
      if (this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
       
        if (this.villagelevelMaster || this.levelMasId) {
          filter['levelMasterId'] = this.levelMasId ? this.levelMasId :this.villagelevelMaster.id;
        }
        else {
          this.search = false;
          this.notificationService.alertError('Please select a village to proceed.', '');
          this.spinner.hide();
          return
          
        }
      }
      if (this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'VILLAGE') {
        filter['levelMasterId'] = this.sessionStorage.retrieve('levelMaster').id;
       
      }
      console.log('levelmaster',filter)
      this.calculateDates();
      if (this.startDate && this.endDate) {
        console.log('filterddd',filter)
        if (this.startDate > this.endDate) {
          this.dateError = true;
          return
        }
        this.dateError = false;
        filter['fromDate'] = this.startDate;
        filter['toDate'] = this.endDate;
        console.log('filterdddcn',filter)
      }
      else {
        this.notificationService.alertError('please Select Month', '');
        this.search = false;
        return;
      }
      if (this.search) {
        this.spinner.show();
        this.reportService
          .getCashBookDayWiseReport({ ...filter })
          .subscribe(
            (res: HttpResponse<any>) => {
              console.log('res',res)
              this.openingBalance = res.body.openingBalance;
              this.closingBalance = res.body.closingBalance;
              this.statement = res.body.cashBookDayWiseReportDtoList;
              this.spinner.hide();
            },
            () => {
              this.spinner.hide();
            }
          );
      }
  
    }
    pad(number: number): string {
      return number < 10 ? '0' + number : number.toString();
    }
    getFinyear(): Promise<any> {
      return new Promise<any>((resolve) => {
        this.limitTransListService.getFinYear().subscribe(
          (res: HttpResponse<FinYear[]>) => {
            this.finYearList = res.body;
            let FinYear = ''
            if (new Date().getMonth() + 1 >= 4) {
              FinYear =
                new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
            } else {
              FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
            }
            this.finYear = this.finYearList.find((_) => _.name === FinYear);
            this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
            this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
            this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
            this.maxDate = new Date();
            resolve(this.roleCode)
          },
          () => { }
        )
      });
    }
  
    parseDate(dateString) {
      const [day, month, year] = dateString.split('/');
      return new Date(`${month}/${day}/${year}`);
    }
  
    onchangeFinYear() {
      if (this.finYear.name === this.defaultFinyear.name) {
        this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
        this.maxDate = new Date();
      }
      else {
        this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
        this.maxDate = this.parseDate('31/03/' + this.finYear.name.split('-')[1]);
      }
    }
  
    getLevelMaster(): Promise<any> {
      return new Promise<any>((resolve) => {
        this.levelMasterService.getLevelMaster(this.sessionStorage.retrieve('levelmasterId')).subscribe(
          (res) => {
            this.sessionStorage.store('levelMaster', res.body);
            resolve('');
          },
          (onError) => {
            resolve(null);
          }
        );
      });
    }
   
  
    onStateLevelChange($event?) {
      if ($event) {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: 1,
            },
            {
              key: 'name',
              operation: 'contains',
              value: $event.term.toUpperCase(),
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.districtLevelList = res.body;
            },
            () => { }
          );
      } else {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: 1,
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.districtLevelList = res.body;
            },
            () => { }
          );
      }
    }
  
  
    onDistrictLevelChange($event?) {
      this.blockMaster = null;
      if ($event) {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.districtMaster.id,
            },
            {
              key: 'name',
              operation: 'contains',
              value: $event.term.toUpperCase(),
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.blockLevelList = res.body;
            },
            () => { }
          );
      } else {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.districtMaster.id,
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.blockLevelList = res.body;
            },
            () => { }
          );
      }
    }
  
  
  
    onBlockLevelChange($event?) {
      this.villagelevelMaster = null;
      this.villageLevelList = [];
      if ($event) {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.blockMaster.id,
            },
            {
              key: 'name',
              operation: 'contains',
              value: $event.term.toUpperCase(),
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.villageLevelList = res.body;
            },
            () => { }
          );
      } else {
        let filterQuery = {
          page: 0,
          size: 50,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.blockMaster.id,
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.villageLevelList = res.body;
            },
            () => { }
          );
      }
    }
  
  
  
    loadCompType(): Promise<any> {
      return new Promise<any>((resolve) => {
        this.componentTypeService.find(4).subscribe(
          (res: HttpResponse<any>) => {
            this.componentType = res.body.filter(item => item.componentCode === 'C' || item.componentCode === 'D' || item.componentCode === 'B' || item.componentCode === 'A');
            resolve(this.componentType);
          },
          () => { resolve(null); }
        )
      });
    }
  
    calculateDates() {
      const monthMap: { [key: string]: number } = {
        'January': 0, 'February': 1, 'March': 2, 'April': 3, 'May': 4, 'June': 5,
        'July': 6, 'August': 7, 'September': 8, 'October': 9, 'November': 10, 'December': 11
      };
  
      if (!this.month || !this.finYear) return;
      const months = this.finYear.name.split('-');
      const startYear = parseInt(months[0]); // e.g., 2024
      const endYear = parseInt(months[1]);   // e.g., 2025
      const monthIndex = monthMap[this.month]; // Convert month string to number
      let year = startYear;
      // If the selected month is after March, it belongs to the starting financial year
      if (monthIndex >= 3) {
        year = startYear;
      } else {
        year = endYear;
      }
      const fromDate = new Date(year, monthIndex, 1);
      const toDate = new Date(year, monthIndex + 1, 0); // Last day of the month
     
      const date = new Date(fromDate);
      this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
      const enddate = new Date(toDate);
      this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} 23:59:59.999`;
      console.log('jdjdnjjdj')
    }

    getPreviousMonthLastDate(): string {
      const today = new Date(this.startDate);
      const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Last date of the previous month
      return lastMonth.toLocaleDateString('en-GB'); // Format: DD-MM-YYYY
  }


}
