import { Component, OnInit, ViewChild } from '@angular/core';
import {
  DescriptionDto,
  VendorTransaction,
  VoucherBillDescEmployeeBulkList,
  vendorTransactionDto,
  EmployeeBulkDTO,
  BulkPaymentResDto
} from './../payment-voucher/payment-voucher.model';
import { NavbarComponent } from './../../layout/navbar/navbar.component';
import { filterQuery } from './../../shared/common.model';
import { ComponentType } from './../../module/component-type/component-type';
import { LevelType } from './../../module/level-type/level-type';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { SchemeList } from 'src/app/module/scheme-list/scheme-list';
import { SchemeListService } from 'src/app/module/scheme-list/scheme-list.service';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import { Vendor } from 'src/app/module/commercial-vendors/commercial-vendors';
import { paymentVoucherGen } from '../payment-voucher/payment-voucher.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { PersonalVendorService } from 'src/app/module/personal-vendors/personal-vendors.service';
import { PersonalVendorsDto } from 'src/app/module/personal-vendors/personal-vendors';
import { DepartmentListService } from 'src/app/module/department-list/department-list.service';
import { DepartmentList } from 'src/app/module/department-list/department-list';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import * as XLSX from 'xlsx';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { EmployeeService } from 'src/app/module/employee/employee.service';
import {
  Employee,
  EmployeeDTO,
} from 'src/app/module/employee/employee.model';
import { UserService } from 'src/app/module/user/user.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { forkJoin } from 'rxjs';

interface Month {
  index: number;
  name: string;
}
@Component({
  selector: 'app-employee-bulk-pay-voucher',
  templateUrl: './employee-bulk-pay-voucher.component.html',
  styleUrls: ['./employee-bulk-pay-voucher.component.scss']
})
export class EmployeeBulkPayVoucherComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;

  enableStateGst: boolean;
  enableIGst: boolean;
  departmentList: DepartmentList[];
  totalDeduction: number;

  // validation fields properties
  public ifscError = false;

  //required fields property
  ngSelectInvalid: boolean = false;
  dropDownLabel: string;
  enableNhis: boolean;
  enableCps: boolean;
  enableOtherDeduction: boolean;
  roleCode: any;
  readonly: boolean;
  enableRevertButton: boolean;
  description: string;
  amount: number;
  quantity: number;
  signButtonName: string;
  downloadButton: boolean;
  param1: any;
  param2: any;
  param3: any;
  enableSignButton: boolean;
  employeeList: EmployeeBulkDTO[];
  enableEmployee: boolean;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  enableRePaymentButton: boolean;
  disablepaymentButton: boolean = true;
  buttonText: string;
  interactionId: any;
  checkstatusButton: boolean;
  checkAmount: boolean;
  checkForPaymentStatus: boolean;
  selectedMonth: Month;
  month: Month[] = [
    { index: 1, name: 'January' },
    { index: 2, name: 'February' },
    { index: 3, name: 'March' },
    { index: 4, name: 'April' },
    { index: 5, name: 'May' },
    { index: 6, name: 'June' },
    { index: 7, name: 'July' },
    { index: 8, name: 'August' },
    { index: 9, name: 'September' },
    { index: 10, name: 'October' },
    { index: 11, name: 'November' },
    { index: 12, name: 'December' }
  ];
  currentYear: number;
  viewPayList: boolean;
  bulkPaymentListDialog: boolean;
  componentMasterTable: any;
  deleteDialog: boolean;
  finYearList: FinYear[];
  finYear: FinYear;
  enableView: boolean;
  bulkVoucherDialog: boolean;
  finYearDto: any;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private paymentService: PaymentService,
    private personalVendorService: PersonalVendorService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private employeeService: EmployeeService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private limitTransListService: LimitTransListService
  ) {
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
      this.componentTypeService
        .find(
          this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto
            .leveltype
        )
        .subscribe(
          (res: HttpResponse<any>) => {
            if (this.roleCode.role.name.startsWith('Village')) {
              this.grantLevel = res.body.filter(item => item.name === 'Village Salary' || item.name === '');
            } else if (this.roleCode.role.name.startsWith('Block')) {
              this.grantLevel = res.body.filter(item => item.name === 'Block Minimum Grant' || item.name === 'Panchayat Union General Fund');
            } else if (this.roleCode.role.name.startsWith('District')) {
              this.grantLevel = res.body.filter(item => item.name === 'District Admin Grant' || item.name === 'District Population Grant');
            } else {
              this.grantLevel = res.body;
            }
          },
          () => { }
        );
      this.majaorComponent = this.componentMasterTable.filter(item => item.componentTypeDto.id === this.grantlevel.id && item.parentId === 0);
      this.minorComponent = this.componentMasterTable.filter(item => item.parentId === this.majaorComponentdto.id);
      this.subComponent = this.componentMasterTable.filter(item => item.parentId === this.minorComponentDto.id);
    });
  }

  schemeList: SchemeList[];
  selectedVendor: PersonalVendorsDto;
  levelType: LevelType[];
  grantLevel: any;
  grantlevel: any;
  levelMasterDto: LevelType;
  voucherGenerationDto: paymentVoucherGen;
  minorComponent: any;
  minorComponentDto: any;
  subComponent: any;
  subComponentDto: any;
  date: Date;
  voucherNo: string;
  majaorComponent: any;
  majaorComponentdto: any;
  vendorList: Vendor[];
  selectedscheme: any;
  profileDialog: boolean = false;
  descriptionList: DescriptionDto[];
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  bulkPaySearchResult: SearchResult<BulkPaymentResDto> =
    new SearchResult<BulkPaymentResDto>();
  selectedEmployee: EmployeeDTO;
  zonalList: LevelType;
  selectedZonalUser: any
  enableZonalButton: boolean;
  cols = [
    {
      field: 'voucherNo',
      header: 'Voucher No',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'personalVendorName',
      header: 'Vendor Name',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'personalAccountNo',
      header: 'Vendor Account',
      isFilterable: false,
      isSelectcolumn: true,
    },
    {
      field: 'transDate',
      header: 'Transaction Date',
      isSortable: true,
      isSelectcolumn: true,
      type: 'date',
    },
    {
      field: 'grossAmount',
      header: 'Gross Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
    },
    {
      field: 'transactionAmount',
      header: 'Total Transaction Amount',
      jhiTranslate: 'flowtechApp.customers.industryType',
      isSelectcolumn: true,
    },
  ];
  async ngOnInit(): Promise<void> {
    this.finYearList = this.sessionStorageService.retrieve('finYear');
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYearDto = this.finYearList.find((_) => _.name === FinYear);
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    this.componentMasterTable = this.sessionStorageService.retrieve('componentMasterTable');
    this.grantLevel = this.sessionStorageService.retrieve('componentTypeTable');
    if (!this.componentMasterTable) {
      await this.loadComp();
    }
    this.roleCode = this.sessionStorageService.retrieve('user');
    this.levelMasterDto = this.sessionStorageService.retrieve('levelMaster').levelTypeDto;
    this.spinner.show();

    if (
      this.roleCode.role.code === 'DMAKER' ||
      this.roleCode.role.code === 'VMAKER' ||
      this.roleCode.role.code === 'BMAKER'
    ) {
      if (this.roleCode.role.code === 'BMAKER') {
        this.paymentService
          .getZonalCheckers(
            this.roleCode.levelMasterId
          )
          .subscribe(
            (res: HttpResponse<LevelType>) => {
              this.zonalList = res.body;
            },
            () => { }
          );
      }
      this.signButtonName = 'Perform e-Sign Secretary';
    } else if (
      this.roleCode.role.code === 'DC1' ||
      this.roleCode.role.code === 'BC1' ||
      this.roleCode.role.code === 'VC1' ||
      this.roleCode.role.code === 'ZC1'
    ) {
      if (this.roleCode.role.code === 'BC1' || this.roleCode.role.code === 'ZC1') {
        this.checkAmount = true
      }
      this.signButtonName = 'Perform e-Sign VP';
      this.enableRevertButton = true;
      this.readonly = true;
    } else if (
      this.roleCode.role.code === 'DC2' ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'VC2'
    ) {
      this.signButtonName = 'Perform e-Sign President';
      this.enableRevertButton = true;
      this.downloadButton = true;
      this.readonly = true;
    }
    if (
      this.roleCode.role.code === 'BMAKER' ||
      this.roleCode.role.code === 'BC1' ||
      this.roleCode.role.code === 'BC2' ||
      this.roleCode.role.code === 'ZC1'
    ) {
      this.enableZonalButton = true;
    }

    if (this.activatedRoute.snapshot.queryParamMap.get('response')) {
      this.paymentSucessDialog = true;
      this.paymentSucessText = this.activatedRoute.snapshot.queryParamMap.get('response');
      this.disablepaymentButton = true;
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('error')) {
      this.notificationService.alertError('Error Occurred: ' + this.activatedRoute.snapshot.queryParamMap.get('error'), '');
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      if (this.activatedRoute.snapshot.queryParamMap.get('view')) {
        this.enableView = true;
        this.readonly = true;
      }
      this.spinner.show();
      this.employeeService.filter({ size: 50, page: 0 }, [ {
        key: 'isAccountVerified',
        operation: 'equals',
        value: true,
      }]).subscribe(
        (res: HttpResponse<EmployeeBulkDTO[]>) => {
          this.employeeList = res.body;
          this.enableEmployee = true;
        },
        () => { }
      );
      this.paymentService
        .updatedVocher(
          Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
        )
        .subscribe(
          (res: HttpResponse<paymentVoucherGen>) => {
            this.voucherGenerationDto = res.body;
            this.finYearDto = this.voucherGenerationDto.vendorTransactionDto.finYearDto;
            // if(this.voucherGenerationDto.vendorTransactionDto.parentVoucherNumber && !this.enableView){
            //   this.readonly = true;
            //   this.bulkVoucherDialog = true;
            // }
            if (this.checkAmount && res.body.vendorTransactionDto.transactionAmount <= 500000 && res.body.vendorTransactionDto.signatureVicePresident) {
              res.body.vendorTransactionDto.signaturePresident = "signed"
            }
            if (this.voucherGenerationDto.vendorTransactionDto.signStatus && !this.enableView) {
              this.readonly = true;
              this.enableSignButton = true;
            }
            if (this.voucherGenerationDto.voucherBillDescEmployeeBulkList) {
              this.selectedMonth = this.month.find(item => item.name == this.voucherGenerationDto.voucherBillDescEmployeeBulkList[0].month);
              const regex = /(\b\w+\s+\d{4}\b)/;
              const match = this.voucherGenerationDto.voucherBillDescEmployeeBulkList[0].description.match(regex);
              if (match) {
                const financialYear = this.getFinancialYear(match[0]);
                console.log('logss', financialYear);
                this.finYear = this.finYearList.find(item => item.name == financialYear)
              } else {
                // this.extractedMonth = "Month not found";
              }
            }
            if (res.body?.vendorTransactionDto?.zonalChecker) {
              this.userService
                .getUser(res.body?.vendorTransactionDto?.zonalChecker)
                .subscribe(
                  (res: HttpResponse<any>) => {
                    this.selectedZonalUser = res.body;
                  },
                  () => { }
                );
            }
            this.date = new Date(
              this.voucherGenerationDto.vendorTransactionDto.transDate
            );
            if (this.voucherGenerationDto.vendorTransactionDto.signaturePresident) {
              this.bulkPaymentStatus();
            }
            this.enableEmployee = true;
            this.grantlevel =
              this.voucherGenerationDto.vendorTransactionDto.componentTypeDto;
            this.levelMasterDto =
              this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto.levelTypeDto;
            this.majaorComponentdto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.majorComponentId);
            this.minorComponentDto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.minorComponentId);
            this.subComponentDto = this.componentMasterTable.find(item => item.id === this.voucherGenerationDto.vendorTransactionDto.subComponentId);
            this.ongrandLevel('true');
            this.onMajorComponent('true');
            this.onMinorComponent('true');
          },
          () => { }
        );
    }
    if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {

      this.voucherGenerationDto = new paymentVoucherGen();
      this.voucherGenerationDto.vendorTransactionDto =
        new vendorTransactionDto();
      this.voucherGenerationDto.voucherBillDescEmployeeBulkList = [];
      this.voucherGenerationDto.vendorTransactionDto.voucherNo =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.date = new Date();
      this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto = this.sessionStorageService.retrieve('IA');
      this.voucherGenerationDto.voucherBillDescEmployeeBulkList = [];
      let employeeDescDto = new VoucherBillDescEmployeeBulkList();
      employeeDescDto.voucherNo =
        this.voucherGenerationDto.vendorTransactionDto.voucherNo + '_' + 1;
      employeeDescDto.quantity = 0;
      employeeDescDto.amount = 0;
      employeeDescDto.grossAmount = 0;
      this.voucherGenerationDto.voucherBillDescEmployeeBulkList.push(
        employeeDescDto
      );
    }
    this.paymentService.getDescrptionList().subscribe(
      (res: HttpResponse<DescriptionDto[]>) => {
        this.descriptionList = [];
        res.body.forEach((element) => {
          if (element.name.startsWith('Salary')) {
            this.descriptionList.push(element);
          }
        });
      },
      () => { }
    );
    this.spinner.hide();
    if (this.roleCode.role.name.startsWith('Village')) {
      this.grantLevel = this.grantLevel.filter(item => item.name === 'Village Salary' ||  item.name === 'Village General Funds');
    } else if (this.roleCode.role.name.startsWith('Block')) {
      this.grantLevel = this.grantLevel.filter(item => item.name === 'Block Minimum Grant' || item.name === 'Panchayat Union General Fund');
    } else if (this.roleCode.role.name.startsWith('District')) {
      this.grantLevel = this.grantLevel.filter(item => item.name === 'District Admin Grant' || item.name === 'District Population Grant');
    }
  }



  loadComp(): Promise<any> {
    return new Promise<any>((resolve) => {
      const componmentTypeObservable = this.componentTypeService
        .find(
          this.sessionStorageService.retrieve('levelMaster').levelTypeDto.id
        );
      const componmentMasterObservable = this.paymentService.componentMasterList();
      forkJoin([componmentTypeObservable, componmentMasterObservable]).subscribe(
        (responses) => {
          this.sessionStorageService.store('componentMasterTable', responses[1].body)
          this.componentMasterTable = responses[1].body;
          this.sessionStorageService.store('componentTypeTable', responses[0].body)
          this.grantLevel = responses[0].body;
          resolve(this.componentMasterTable)
        },
        (error) => {
          console.error('Error:', error);
          resolve(null);
        }
      )
    });
  }

  bulkPaymentStatus() {
    this.paymentService
      .bulkPaymentStatus(this.voucherGenerationDto.vendorTransactionDto.id)
      .subscribe(
        (res: any) => {
          this.paymentSucessDialog = true;
          this.paymentSucessText = res.body.response;
          if (res.body.response !== 'Payment In Progress') {
            this.viewPayList = true;
          }
        },
        (err) => { }
      );
  }

  getFinancialYear(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth();

    // Check if the month falls before April, if so, the financial year is in the previous year
    const financialYear = month < 3 ? `${year - 1}-${year}` : `${year}-${year + 1}`;

    return financialYear;
  }

  getBulkPaymentList() {
    this.paymentService
      .bulkPaymentList(this.voucherGenerationDto.vendorTransactionDto.id)
      .subscribe(
        (res: any) => {
          res.body.forEach(element => {
            if(element.isCancelled) {
              element.transactionStatus = 'Failed and Returned';
            }
          });
          this.bulkPaySearchResult.items = res.body ?? [];
          this.bulkPaySearchResult = { ...this.bulkPaySearchResult };
          this.bulkPaymentListDialog = true;
        },
        (err) => { }
      );
  }

  exportToExcel(): void {
    let element = document.getElementById('BulkPaymentList');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'BulkPaymentListStatus.xlsx');
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => { }
    );
  }

  onLevelType() {
    this.componentTypeService
      .find(
        this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto
          .leveltype
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          if (this.roleCode.role.name.startsWith('Village')) {
            this.grantLevel = res.body.filter(item => item.name === 'Village Salary');
          } else if (this.roleCode.role.name.startsWith('Block')) {
            this.grantLevel = res.body.filter(item => item.name === 'Block Minimum Grant' || item.name === 'Panchayat Union General Fund');
          } else if (this.roleCode.role.name.startsWith('District')) {
            this.grantLevel = res.body.filter(item => item.name === 'District Admin Grant' || item.name === 'District Population Grant');
          } else {
            this.grantLevel = res.body;
          }
        },
        () => { }
      );
  }
  getActionUrl() {
    this.sessionStorageService.store('refresh', 'Yes');
    return environment.CDAC_URL;

  }
  ongrandLevel(edit?: any) {
    if (!edit) {
      this.majaorComponentdto = null;
      this.minorComponentDto = null;
      this.subComponentDto = null;

    }
    console.log('this.majaorComponent',this.grantlevel);
    if(this.grantlevel.componentCode === 'C') {
      this.majaorComponent = this.componentMasterTable.filter(item => item.componentTypeDto.id === this.grantlevel.id && item.parentId === 0);
    }
    else {
      this.majaorComponent = this.componentMasterTable.filter(item => item.componentTypeDto.id === this.grantlevel.id && item.code === '304');
    }
    

    this.paymentService
      .getLimit(
        this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.id,
        this.grantlevel.id,
        this.finYear.id
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.voucherGenerationDto.vendorTransactionDto.iaComponentLimitDto =
            res.body;
        },
        () => { }
      );
  }
  onMajorComponent(edit?: any) {
    if (!edit) {
      this.minorComponentDto = null;
      this.subComponentDto = null;
    }
    if(this.grantlevel.componentCode === 'C') {
      this.minorComponent = this.componentMasterTable.filter(item => item.parentId === this.majaorComponentdto.id && !item.name.startsWith('Salary'));
    }
    else {
      this.minorComponent = this.componentMasterTable.filter(item => item.parentId === this.majaorComponentdto.id && item.code === '30402');
    }
    

  }

  show() {
    this.spinner.show();
  }

  calculateOtherDeduction() {
    this.voucherGenerationDto.vendorTransactionDto.otherDeductionItPercentage =
      Number(
        this.voucherGenerationDto.vendorTransactionDto
          .otherDeductionItPercentage
      );
    this.voucherGenerationDto.vendorTransactionDto.otherDeductionIt = Number(
      (
        (this.voucherGenerationDto.vendorTransactionDto
          .otherDeductionItPercentage /
          100) *
        this.voucherGenerationDto.vendorTransactionDto.netAmount
      ).toFixed(2)
    );
    this.calculateTotalAmount();
  }

  onUpload($event) {
    const selectedFile: File = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer);
    };
    const formData = new FormData();
    const fileExtension = $event.name.split('.').pop();
    const fileData = new Blob([$event], { type: $event.type });
    formData.append('file', fileData, `file.${fileExtension}`);
    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => { },
      () => { }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }
  onMinorComponent(edit?: any) {
    if (!edit) {
      this.subComponentDto = null;
    }
    this.subComponent = this.componentMasterTable.filter(item => item.parentId === this.minorComponentDto.id);

    // if (this.minorComponentDto.name.startsWith('Salary')) {
    this.employeeService.filter({ size: 50, page: 0 }, [ {
      key: 'isAccountVerified',
      operation: 'equals',
      value: true,
    }]).subscribe(
      (res: HttpResponse<EmployeeBulkDTO[]>) => {
        this.employeeList = res.body;
      },
      () => { }
    );
    // pm 
  }

  vendorFilter($event) {
    let filter = new filterQuery();
    filter.key = 'name';
    filter.operation = 'contains';
    filter.value = $event.term.toUpperCase();
    let filterquery = [];
    filterquery.push(filter);
    this.personalVendorService
      .filter({ size: 10, page: 0 }, filterquery)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.vendorList = res.body;
        },
        () => { }
      );
  }

  popupValue() {
    // const currentDate = new Date();
    // this.currentYear= currentDate.getFullYear();
    // let month=this.selectedMonth.index;
    //   let year;
    //   if (month >= 4) {
    //     year =this.finYear.name.split('-')[0];
    //   } else {
    //     year = this.finYear.name.split('-')[1];
    //   }
    this.voucherGenerationDto.voucherBillDescEmployeeBulkList = [];
    this.selectedMonth = null;
    this.employeeList.forEach(element => {
      let employeeDescDto = new VoucherBillDescEmployeeBulkList();
      employeeDescDto.name = element.name;
      employeeDescDto.employeeId = element.id;
      employeeDescDto.bankAccName = element.bankAccName;
      employeeDescDto.accountNumber = element.accountNumber;
      employeeDescDto.ifscCode = element.ifscCode;
      employeeDescDto.bankBranch = element.bankBranch;
      employeeDescDto.bankName = element.bankName;
      employeeDescDto.voucherNo = this.voucherGenerationDto.vendorTransactionDto.voucherNo;
      employeeDescDto.quantity = 0;
      employeeDescDto.amount = element.escNet;
      employeeDescDto.grossAmount = element.escNet;
      employeeDescDto.designation = element.subEmployeeType.name;
      // employeeDescDto.description="Salary for the month of "+this.selectedMonth.name+' '+year;
      // employeeDescDto.month=this.selectedMonth.name;
      this.amount = 0;
      this.quantity = 0;
      this.description = null;

      this.voucherGenerationDto.voucherBillDescEmployeeBulkList.push(employeeDescDto);
    });
    this.calculateTotalAmount();
  }
  addRow() {
    this.voucherGenerationDto.voucherBillDescEmployeeBulkList = this
      .voucherGenerationDto.voucherBillDescEmployeeBulkList
      ? this.voucherGenerationDto.voucherBillDescEmployeeBulkList
      : [];
    let employeeDescDto = new VoucherBillDescEmployeeBulkList();
    if (this.voucherGenerationDto.voucherBillDescEmployeeBulkList.length !== 0) {
      employeeDescDto.voucherNo =
        this.voucherGenerationDto.vendorTransactionDto.voucherNo +
        '_' +
        this.voucherGenerationDto.voucherBillDescEmployeeBulkList.length +
        1;
    } else {
      employeeDescDto.voucherNo =
        this.voucherGenerationDto.vendorTransactionDto.voucherNo + '_' + 1;
    }
    employeeDescDto.quantity = 0;
    employeeDescDto.amount = 0;
    employeeDescDto.grossAmount = 0;
    this.voucherGenerationDto.voucherBillDescEmployeeBulkList.push(employeeDescDto);

    // this.calculateOtherDeduction();
  }
  calculatetotal(amt: VoucherBillDescEmployeeBulkList) {
    amt.name = amt.name;
    if (amt.quantity === null) {
      amt.quantity = 0;
    }
    if (amt.amount === null) {
      amt.amount = 0;
    }
    this.amount = amt.amount;
    this.quantity = amt.quantity;
    if (amt.quantity > amt.amount) {
      amt.quantity = null;
      this.notificationService.alertError('Leave Deduction should not be greater than Amount', '');
      amt.grossAmount = null;
    }
    else {
      amt.grossAmount = amt.amount - amt.quantity;
    }
    this.calculateTotalAmount();
  }
  delete(index: number) {
    this.voucherGenerationDto.voucherBillDescEmployeeBulkList.splice(index, 1);
    if (this.voucherGenerationDto.voucherBillDescEmployeeBulkList.length === 0) {
      this.voucherGenerationDto.vendorTransactionDto.netAmount = null;
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount = null;
    } else {
      this.calculateTotalAmount();
    }
  }

  validateIfsc() {
    this.ifscError = !this.validationService.isPanValid(
      this.selectedVendor.ifscCode
    );
    !this.selectedVendor.ifscCode ? (this.ifscError = false) : true;
    return !this.ifscError && !!this.selectedVendor.ifscCode;
  }
  addDescritption(list: string) {
    this.description = list;
  }

  onMonthChange() {
    if (this.finYear && this.selectedMonth) {
      let month = this.selectedMonth.index;
      let year;
      if (month >= 4) {
        year = this.finYear.name.split('-')[0];
      } else {
        year = this.finYear.name.split('-')[1];
      }
      this.voucherGenerationDto.voucherBillDescEmployeeBulkList.forEach((element) => {
        element.description = "Salary for the month of " + this.selectedMonth.name + ' ' + year;
        element.month = this.selectedMonth.name;
      });
    }
  }

  genVocher() {
    this.ngSelectInvalid = false;
    const isFormValid =
      this.levelMasterDto &&
      this.grantlevel &&
      this.majaorComponentdto &&
      this.minorComponentDto &&
      this.subComponentDto &&
      this.voucherGenerationDto.vendorTransactionDto
        .panchayatResolutionNumber &&
      this.voucherGenerationDto.vendorTransactionDto.remark &&
      this.voucherGenerationDto.vendorTransactionDto.grossAmount &&
      this.voucherGenerationDto.vendorTransactionDto &&
      this.selectedMonth &&
      this.finYear &&
      this.voucherGenerationDto.vendorTransactionDto.netAmount;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    if (this.roleCode?.role?.code === 'BMAKER' && !this.selectedZonalUser) {
      this.notificationService.alertError('Please Select the Zonal User', '')
      return;
    }

    this.voucherGenerationDto.voucherBillDescEmployeeBulkList.forEach((element) => {
      const isVal = element.name && element.description && element.amount && element.grossAmount;
      if (!isVal) {
        this.ngSelectInvalid = true;
      }
      if (element.quantity > element.amount) {
        this.notificationService.alertError('Leave Deduction should not be greater than Amount', '');
        element.quantity = null;
        element.grossAmount = null;
        this.ngSelectInvalid = true;
      }
    });
    if (this.ngSelectInvalid) {
      return;
    }
    this.ngSelectInvalid = false;
    this.spinner.show();
    if (this.selectedZonalUser?.id) {
      this.voucherGenerationDto.vendorTransactionDto.zonalChecker = this.selectedZonalUser?.id
    }
    this.voucherGenerationDto.vendorTransactionDto.componentTypeDto =
      this.grantlevel;

    this.voucherGenerationDto.vendorTransactionDto.personalVendorDto =
      this.selectedVendor;

    this.voucherGenerationDto.vendorTransactionDto.majorComponentId =
      this.majaorComponentdto.id;
    this.voucherGenerationDto.vendorTransactionDto.majorComponentName =
      this.majaorComponentdto.name;
    this.voucherGenerationDto.vendorTransactionDto.minorComponentId =
      this.minorComponentDto.id;
    this.voucherGenerationDto.vendorTransactionDto.minorComponentName =
      this.minorComponentDto.name;
    this.voucherGenerationDto.vendorTransactionDto.subComponentId =
      this.subComponentDto.id;
    this.voucherGenerationDto.vendorTransactionDto.subComponentName =
      this.subComponentDto.name;
    this.voucherGenerationDto.vendorTransactionDto.levelTypeId =
      this.levelMasterDto.id;
    this.voucherGenerationDto.vendorTransactionDto.personalFlag = 'BE';

    this.paymentService.genVocher(this.voucherGenerationDto).subscribe(
      (response: any) => {
        this.readonly = true;
        this.spinner.hide();
        this.enableSignButton = true;
        this.notificationService.alertSuccess('Saved Successfully', '');
      },

      (error) => {
        this.spinner.hide();
        if (error.status == 400) {
          this.notificationService.alertError('Due to insufficient limit, You are not eligible to generate Voucher', '');

        } else if (error.status == 500) {
          this.notificationService.alertError('An unexpected error occurred, please delete the voucher if generated', '');
          this.router.navigate(['/employee-bulkPay-list'], {});
        } else {
          this.notificationService.alertError('An unexpected error occurred', '');
        }
      }
    );
  }

  getfile() {
    this.paymentService
      .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          if (!this.voucherGenerationDto.vendorTransactionDto.id) {
            this.router.navigate(['/employeeBulkPayVoucher'], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
          // add more parameters as needed
        },
        (error) => {
          if (
            this.roleCode.role.code === 'DMAKER' ||
            this.roleCode.role.code === 'VMAKER' ||
            this.roleCode.role.code === 'BMAKER'
          ) {
            this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: ' + this.voucherGenerationDto.vendorTransactionDto.voucherNo, '');
            this.router.navigate(['/employee-bulkPay-list'], {});
          }
          else {
            this.deleteDialog = true;
          }
        }
      );
  }

  hideDialog() {
    this.deleteDialog = false;
    this.router.navigate(['/employee-bulkPay-list'], {});
  }

  getPdf() {
    this.paymentService
      .getPdfFile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
        },
        (err) => {
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  revert() {
    this.spinner.show();
    this.paymentService
      .revertTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/employee-bulkPay-list'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  reject() {
    this.spinner.show();
    this.paymentService
      .deletedVocher(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/employee-bulkPay-list'], {});
        },
        (onError) => {
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.title, '');
          } else {
            this.notificationService.alertError('Error in Reject', '');
          }
          this.spinner.hide();
        }
      );
  }

  calculateTotalAmount() {
    if (this.voucherGenerationDto?.voucherBillDescEmployeeBulkList.length !== 0) {
      let intitalTotal = 0;
      let intitalTotalWithTax = 0;
      this.voucherGenerationDto?.voucherBillDescEmployeeBulkList.forEach(
        (element) => {
          intitalTotal += Number(element.amount);
          intitalTotalWithTax += element.grossAmount;
        }
      );
      this.voucherGenerationDto.vendorTransactionDto.netAmount =
        intitalTotalWithTax;
      this.voucherGenerationDto.vendorTransactionDto.grossAmount =
        intitalTotalWithTax;
      this.voucherGenerationDto.vendorTransactionDto.transactionAmount =
        this.voucherGenerationDto.vendorTransactionDto.netAmount;
    }
  }

  getvendorTrans() {
    if (this.selectedVendor) {
      this.paymentService
        .getListPersonalVendorTransction(this.selectedVendor.id)
        .subscribe(
          (res: any) => {
            this.searchResult.items = res.body ?? [];
            this.searchResult.total = 5;
            this.searchResult = { ...this.searchResult };
            this.profileDialog = true;
          },
          () => { }
        );
    }
  }
  redirect() {
    let enableview = false;
    if (this.enableView) {
      enableview = true;
    }
    this.router.navigate(['/employeeBulkPayVoucher'], {
      queryParams: {
        id: String(this.voucherGenerationDto.vendorTransactionDto.id),
        view: enableview
      },
    });
  }

  paymentStatus() {
    this.paymentService
      .getPaymentStatus(this.voucherGenerationDto.vendorTransactionDto.id)
      .subscribe(
        (res: any) => {
          if (res.body.transactionStatus === 'O.K.') {
            this.disablepaymentButton = true;
          }
          else if (res.body.transactionStatus !== null) {
            this.buttonText = 'Re-Initiate payment';
            this.checkstatusButton = true;
            this.interactionId = res.body.apiInteractionId;
            if (this.checkForPaymentStatus) {
              this.checkPaymentStatus();
            }
          }
        },
        (err) => { }
      );
  }

  PaymentSucess() {
    this.spinner.show();
    this.paymentService.paymentSuccess(this.voucherGenerationDto).subscribe(
      (res: any) => {
        this.paymentSucessDialog = true;
        this.paymentSucessText = res.body;
        this.disablepaymentButton = true;
      },
      (err) => {
        console.log(err);
        if (String(err.status).startsWith('5')) {
          this.checkstatusButton = true;
          this.spinner.hide();
        }
        else if (err.status === 400) {
          this.notificationService.alertError(err.error, '');
        }
        this.spinner.hide();
      }
    );
  }
  checkTransactionStatus() {
    this.checkForPaymentStatus = true;
    this.paymentStatus();
  }

  checkPaymentStatus() {
    this.spinner.show();
    this.checkForPaymentStatus = false;
    this.paymentService.paymentStatus(this.interactionId).subscribe(
      (res: any) => {
        if (res.body.transactionStatus.TRANSTATUS === "Failure") {
          this.notificationService.alertError("Transaction Failed for Reference No:" + res.body.transactionStatus.JRNL_NO, '')
          this.buttonText = 'Re-Initiate payment';
          this.checkstatusButton = false;
          // this.disablepaymentButton = false; 
          this.spinner.hide();
        }
        else if (res.body.transactionStatus.TRANSTATUS === "Success") {
          this.checkstatusButton = false;
          this.paymentSucessDialog = true;
          this.paymentSucessText = "Transaction Success for Reference No:", res.body.transactionStatus.JRNL_NO;
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  bulkVoucherRedriect() {
    this.router.navigate(['/blukpaymentTable'], {});
  }

  submitForm() {
    if(this.roleCode.role.code !== 'VC2') {
      this.sessionStorageService.store('paymentType', this.voucherGenerationDto.vendorTransactionDto.personalFlag);
      this.sessionStorageService.store('voucherNo',this.voucherGenerationDto.vendorTransactionDto.voucherNo);
      this.sessionStorageService.store('userId',this.roleCode.id);
      this.sessionStorageService.store('otpAttempts',3);
      this.router.navigateByUrl('/aadhar-verification-page');
    }
    else {
      this.spinner.show();
      this.paymentService
        .getfile(this.voucherGenerationDto.vendorTransactionDto.voucherNo)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.spinner.hide();
            this.param1 = res.body.paramOne;
            this.param2 = res.body.paramTwo;
            this.param3 = res.body.paramThree;
            this.sessionStorageService.store('refresh', 'Yes');
            if (!this.voucherGenerationDto.vendorTransactionDto.id) {
              this.router.navigate(['/employeeBulkPayVoucher'], {
                queryParams: {
                  id: res.body.paramFour
                },
              });
            }
            const formElement = document.createElement('form');
            formElement.method = 'POST';
            formElement.action = environment.CDAC_URL;
            let inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "eSignRequest";
            inputElement.value = res.body.paramOne;
            formElement.appendChild(inputElement);
            inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "aspTxnID";
            inputElement.value = res.body.paramTwo;
            formElement.appendChild(inputElement);
            inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = "Content-Type";
            inputElement.value = res.body.paramThree;
            formElement.appendChild(inputElement);
            document.body.appendChild(formElement);
            formElement.submit();
  
          },
          (error) => {
            this.spinner.hide();
            if (
              this.roleCode.role.code === 'DMAKER' ||
              this.roleCode.role.code === 'VMAKER' ||
              this.roleCode.role.code === 'BMAKER'
            ) {
              this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: ' + this.voucherGenerationDto.vendorTransactionDto.voucherNo, '');
              this.router.navigate(['/employee-bulkPay-list'], {});
            }
            else {
              this.deleteDialog = true;
            }
          }
        );
}
  }
}
