import { SessionStorageService } from 'ngx-webstorage';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { filterQuery } from 'src/app/shared/common.model';

import { FontService } from 'src/app/shared/font-service/font.service';
import { LazyLoadEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { FundsExcelUploadService } from '../funds-excel-upload/funds-excel-upload.service';
import { LimitAllocationHistory } from '../funds-excel-upload/funds-excel-upload.model';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Location } from '@angular/common';

@Component({
  selector: 'app-excel-upload-list',
  templateUrl: './excel-upload-list.component.html',
  styleUrl: './excel-upload-list.component.scss'
})
export class ExcelUploadListComponent {
  roleCode: any;
  finYearList: any;
  finYear: any;
  defaultFinyear: any;
  fundDetails: LimitAllocationHistory[];
  totalCount: number;
  itemsPerPage:number = 10;
  page: number;
  first = 0;
  currentPath: string;
  headingText: string;

  constructor(
    private loginService: SigninService,
    public fontService: FontService,
    private spinner: NgxSpinnerService,
    private fundsExcelUploadService: FundsExcelUploadService,
    private paymentService: PaymentService,
    protected router: Router,
    private location: Location,
    private sessionStorage:SessionStorageService
  ) { 
    
  }

  ngOnInit() {
    this.roleCode = this.sessionStorage.retrieve('roleCode');
    this.currentPath = this.location.path();
    if(this.currentPath === '/twad-Upload-excel-list'){
      this.headingText = 'Twad Excel Upload';
    }
    else {
      this.headingText = 'Limit Excel Upload';
    }
    
  }

  LoadExcelUpload(page) {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let filter = {
      page:pageToLoad-1,
      size:this.itemsPerPage,
    };
    let filterProps = [];
    if(this.currentPath === '/twad-Upload-excel-list'){
      filterProps.push({
        key: 'fundSource',
        operation: 'equals',
        value: 'TWAD',
      });
    }
    else {
      filterProps.push({
        key: 'fundSource',
        operation: 'notEquals',
        value: 'TWAD',
      });
    }
    filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    this.fundsExcelUploadService
      .getLimitHistoryByFilter({ ...filter },filterProps)
      .subscribe(
        (res: HttpResponse<LimitAllocationHistory[]>) => {
         this.fundDetails = res.body;
         this.totalCount = Number(res.headers.get('X-Total-Count'));
         this.spinner.hide();
         
          
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  loadFilterValues(event: LazyLoadEvent) {
    let filterDefinitions = [];
    let filterValue = null
    this.first = event.first;
    event.first = (event.first + event.rows) / event.rows;
    this.page = event.first;
    this.itemsPerPage = event.rows;
    this.LoadExcelUpload(this.page);
    
  }
  openNew(){
    if(this.currentPath === '/twad-Upload-excel-list') {
      this.router.navigate(['/fundExcelUpload'], {
        queryParams: {
          isTwad: true
        },
      });
    }
    else {
      this.router.navigate(['/fundExcelUpload'], {
        queryParams: {
          
        },
      });
    }
    
  }
  downloadFile(fileName:string) {
    this.paymentService.getimage(fileName)
  .subscribe((res)=>
  {
    const filename = res.body.fileName; 
    const fileExtension = filename.split('.').pop();
    this.saveBlobToExcel(res.body.fileType, filename);
  },
  (onError)=>
  {

  })
  }

  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
    }

 
  
}
