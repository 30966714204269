<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.payment"></h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="fromDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="toDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        <p-dropdown placeholder="Select Component" [options]="compTypeList" [(ngModel)]="selectedcompType" optionLabel="name" showClear="true"></p-dropdown>
        <p-dropdown [options]="statusList" [(ngModel)]="selectedStatus" optionLabel="name" optionValue="value"></p-dropdown>
        <p-dropdown placeholder="Select FinYear" [options]="finYearList" [(ngModel)]="finYear" optionLabel="name" showClear="true"></p-dropdown>
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
        <button pButton pRipple label="Export to Excel" (click)="export()" class="p-button-success p-button-sm export-button"></button>
        <button pButton pRipple label="Export to Excel" (click)="export()" class="p-button-success p-button-sm export-button"></button>
    </div>
    <div class="row">
        <div *ngIf="dateError" class="text-danger mb-2">
            <small [ngClass]="fontService.labelClass" jhiTranslate="error.invaliddate">Please
                choose valid from and to
                date</small>
        </div>
    </div>
    <div class="row">
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
            <p-tabPanel header="District" *ngIf="!isDMRole && !isBMRole">
                <div class="col-12 px-4" *ngIf="paymentReportDto">
                    <p-table id="district-table" [value]="paymentReportDto.paymentDtoList" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Personal</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Commercial</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Employee</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Elected Representative</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Beneficiary</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Village Thooimai Kavalar</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">TWAD</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Other Fund Payment</th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                            </tr>

                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadBlock(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.personalCount}}</td>
                                <td style="text-align: center;">{{list.personalAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.commercialCount}}</td>
                                <td style="text-align: center;">{{list.commercialAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.employeeCount}}</td>
                                <td style="text-align: center;">{{list.employeeAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.electedRepCount}}</td>
                                <td style="text-align: center;">{{list.electedRepAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.beneficiaryCount}}</td>
                                <td style="text-align: center;">{{list.beneficiaryAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.thooimaikavalarCount}}</td>
                                <td style="text-align: center;">{{list.thooimaikavalarAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.twadCount}}</td>
                                <td style="text-align: center;">{{list.twadAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.otherFundPaymentCount}}</td>
                                <td style="text-align: center;">{{list.otherFundPaymentAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: center;">Total</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalPersonalCount }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalPersonalAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalCommercialCount }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalCommercialAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalEmployeeCount }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalEmployeeAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalElectedRepCount }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalElectedRepAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalBeneficiaryCount }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalBeneficiaryAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalThooimaikavalarCount }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalThooimaikavalarAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalTwadCount }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalTwadAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalOtherFundPaymentCount }}</td>
                                <td style="text-align: center;">{{ paymentReportDto.totalOtherFundPaymentAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Block" *ngIf="!isBMRole">
                <div *ngIf="!paymentBlockReportDto">Choose distict to get block report</div>
                <div *ngIf="paymentBlockReportDto" class="col-12 px-4">
                    <p-table id="block-table" [value]="paymentBlockReportDto.paymentDtoList" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Block Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Personal</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Commercial</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Employee</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Elected Representative</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Beneficiary</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Village Thooimai Kavalar</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">TWAD</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Other Fund Payment</th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadVillage(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.personalCount}}</td>
                                <td style="text-align: center;">{{list.personalAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.commercialCount}}</td>
                                <td style="text-align: center;">{{list.commercialAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.employeeCount}}</td>
                                <td style="text-align: center;">{{list.employeeAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.electedRepCount}}</td>
                                <td style="text-align: center;">{{list.electedRepAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.beneficiaryCount}}</td>
                                <td style="text-align: center;">{{list.beneficiaryAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.thooimaikavalarCount}}</td>
                                <td style="text-align: center;">{{list.thooimaikavalarAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.twadCount}}</td>
                                <td style="text-align: center;">{{list.twadAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">{{list.otherFundPaymentCount}}</td>
                                <td style="text-align: center;">{{list.otherFundPaymentAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: center;">Total</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalPersonalCount }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalPersonalAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalCommercialCount }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalCommercialAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalEmployeeCount }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalEmployeeAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalElectedRepCount }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalElectedRepAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalBeneficiaryCount }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalBeneficiaryAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalThooimaikavalarCount }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalThooimaikavalarAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalTwadCount }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalTwadAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalOtherFundPaymentCount }}</td>
                                <td style="text-align: center;">{{ paymentBlockReportDto.totalOtherFundPaymentAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Village">
                <div *ngIf="!paymentVillageReportDto">Choose block to get village report</div>
                <div *ngIf="paymentVillageReportDto" class="col-12 px-4">
                    <p-table id="village-table" [value]="paymentVillageReportDto.paymentDtoList" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Village Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Personal</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Commercial</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Employee</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Elected Representative</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Beneficiary</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Village Thooimai Kavalar</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">TWAD</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Other Fund Payment</th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepCount">No of voucher</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepAmount">Amount</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">{{list.levelMasterName}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.personalCount" (click)="loadVoucher(list.levelMasterId,'YES')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.personalAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.commercialCount" (click)="loadVoucher(list.levelMasterId,'NO')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.commercialAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.employeeCount" (click)="loadVoucher(list.levelMasterId,'E')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.employeeAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.electedRepCount" (click)="loadVoucher(list.levelMasterId,'ER')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.electedRepAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.beneficiaryCount" (click)="loadVoucher(list.levelMasterId,'BP')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.beneficiaryAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.thooimaikavalarCount" (click)="loadVoucher(list.levelMasterId,'TK')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.thooimaikavalarAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.twadCount" (click)="loadVoucher(list.levelMasterId,'TWAD')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.twadAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.otherFundPaymentCount" (click)="loadVoucher(list.levelMasterId,'OFP')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.otherFundPaymentAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: center;">Total</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalPersonalCount }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalPersonalAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalCommercialCount }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalCommercialAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalEmployeeCount }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalEmployeeAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalElectedRepCount }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalElectedRepAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalBeneficiaryCount }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalBeneficiaryAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalThooimaikavalarCount }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalThooimaikavalarAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalTwadCount }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalTwadAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalOtherFundPaymentCount }}</td>
                                <td style="text-align: center;">{{ paymentVillageReportDto.totalOtherFundPaymentAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
        {{ "dashBoard.transactionlist" | translate }}
    </p-header>
    <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false"></custom-filter-table>
</p-dialog>