import { DatePipe, registerLocaleData } from '@angular/common';
import enIN from '@angular/common/locales/en-IN';
import { Component, OnInit } from '@angular/core';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { ReportService } from '../report.service';
import { AmountDetailsReport } from '../report.model';
import * as XLSX from 'xlsx';
import { FontService } from 'src/app/shared/font-service/font.service';
import { HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComponentFund } from '../report.model';
import { TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'app-nil-balance-report',
  templateUrl: './nil-balance-report.component.html',
  styleUrls: ['./nil-balance-report.component.scss']
})
export class NilBalanceReportComponent implements OnInit {
  levelType: string='District';
  roleCode: any;
  levelMasterId: any;
  nilbalancereport: AmountDetailsReport;
  compFundDto:AmountDetailsReport;
  compFundBlockDto:AmountDetailsReport;
  compFundVillageDto:AmountDetailsReport;
  fundListDialog: boolean;
  activeIndex=0;
  compMasterId: any;
  componentMasterName: any;
  fromDate: string;
  isBMRole: boolean;
  toDate: string;
  startDate: string;
  endDate: string;
  isDMRole: boolean;
  dateError: boolean;
  
  

  constructor(private loginService:SigninService,
              private reportService:ReportService,
              public fontService: FontService,
              private datePipe: DatePipe,
              private spinner: NgxSpinnerService,) {   
     registerLocaleData(enIN);
   }

  ngOnInit(): void {
    this.levelTypeChange();
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.roleCode = res.body.role.code;
        this.levelMasterId=res.body.levelMasterId;
       },
         ()=>{});
   }

  levelTypeChange() {
   this.reportService.getTotalCountNilBalance(this.levelType).subscribe(
    (res) => {
      this.nilbalancereport=res.body;
    }
   )
  }
  exportToExcel(id,filename): void {
    let element = document.getElementById(id);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
  }
  loadFundDialog(id,name){
    this.fundListDialog=true;
    this.activeIndex=0;
    this.compFundDto=null;
    this.compFundBlockDto=null;
    this.compFundVillageDto=null;
    this.compMasterId=id;
    this.componentMasterName=name;
    this.loadDistrict();

  }
  hideDialog(){
    this.fundListDialog=false;
  }
  onTabChange(event: any): void {
    if (event.index === 0 && (this.roleCode=='SA' || this.roleCode=='SM'|| this.roleCode=='SUA' )) {
      this.compFundBlockDto=null;
      this.compFundVillageDto=null;
    }
    else if(event.index === 0 && (this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1' || this.roleCode=='DC2' || this.roleCode=='DAD' || this.roleCode=='DA')) {
      this.compFundVillageDto=null;
    }
  }
  loadDistrict(){   
    this.spinner.show();
    const payload: any = {
      componentId: this.compMasterId,
      parentId:1,
      levelTypeName: 'District'
      
    };
     if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA')
     {
      switch(this.levelType){
        case 'District':
          this.reportService.getLevelMasterCountHavingNilBalance(payload).subscribe(
            (res: HttpResponse<AmountDetailsReport>) => {
              this.compFundDto=res.body;
              this.spinner.hide();
            },
            (err)=>{
              this.spinner.hide();
            })
          break;
          case 'Block':
            this.reportService.getLevelMasterCountHavingNilBalance(payload).subscribe(
              (res: HttpResponse<AmountDetailsReport>) => {
                this.compFundDto=res.body;
                this.spinner.hide();
              },
              (err)=>{
                this.spinner.hide();
              })
            break;
        case 'Village':
          this.reportService.getLevelMasterCountHavingNilBalance(payload).subscribe(
            (res: HttpResponse<AmountDetailsReport>) => {
              this.compFundDto=res.body;
              this.spinner.hide();
            },
            (err)=>{
              this.spinner.hide();
            })
          break;
      }
      }
      else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD' || this.roleCode=='DA'){
        this.loadBlock(this.levelMasterId);
        this.isDMRole=true;
      } else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2' || this.roleCode == 'BAD'){
        this.loadVillage(this.levelMasterId);
        this.isBMRole=true;
      }
  }
  loadBlock(id){
    this.spinner.show();
    this.spinner.show();
    const payload: any = {
      componentId: this.compMasterId,
      parentId:id,
      levelTypeName: 'Block'

    };
    switch(this.levelType){
      case 'District':
        this.spinner.hide();
        this.compFundBlockDto=null;
        break;
        case 'Block':
        this.reportService.getLevelMasterCountHavingNilBalance(payload).subscribe(
          (res: HttpResponse<AmountDetailsReport>) => {
            this.compFundBlockDto=res.body;
            if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
              this.activeIndex=1;
            } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD' || this.roleCode=='DA'){
              this.activeIndex=0;
            }
            this.spinner.hide();
          },
          (err)=>{
            this.spinner.hide();
          }
        )
        break;
      case 'Village':
        this.reportService.getLevelMasterCountHavingNilBalance(payload).subscribe(
          (res: HttpResponse<AmountDetailsReport>) => {
            this.compFundBlockDto=res.body;
            if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
              this.activeIndex=1;
            } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD' || this.roleCode=='DA'){
              this.activeIndex=0;
            }
            this.spinner.hide();
          },
          (err)=>{
            this.spinner.hide();
          }
        )
        break;
    }
    
  }
  loadVillage(id){
    this.spinner.show();
    const payload: any = {
      componentId: this.compMasterId,
      parentId: id,
      levelTypeName: 'Village'
    };
    switch(this.levelType){
      case 'District':
      case 'Block':
        this.spinner.hide();
        this.compFundVillageDto=null;
        break;
      case 'Village':
    this.reportService.getLevelMasterCountHavingNilBalance(payload).subscribe(
      (res: HttpResponse<AmountDetailsReport>) => {
      this.compFundVillageDto=res.body;
        if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
          this.activeIndex=2;
        } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD' || this.roleCode=='DA'){
          this.activeIndex=1;
        } else if(this.roleCode=='BM' || this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2' || this.roleCode=='BAD'){
          this.activeIndex=0;
        } 
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
    break;
    }
  }
  export(){
    if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA') {
      if (this.activeIndex === 0) {
          this.exportToExcel('district-table', 'DistrictWiseNilBalanceReport.xlsx');
      } else if (this.activeIndex === 1) {
          this.exportToExcel('block-table', 'BlockWiseNilBalanceReport.xlsx');
      } else if (this.activeIndex === 2) {
          this.exportToExcel('village-table', 'VillageWiseNilBalanceReport.xlsx');
      }
    }
    else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD' || this.roleCode=='DA'){ 
      if (this.activeIndex === 0) {
        this.exportToExcel('block-table', 'BlockWiseNilBalanceReport.xlsx');
      } else if (this.activeIndex === 1) {
        this.exportToExcel('village-table', 'VillageWiseNilBalanceReport.xlsx');
      }
    }
    else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2' || this.roleCode == 'BAD'){ 
        this.exportToExcel('village-table', 'VillageWiseNilBalanceReport.xlsx');
    }
  }
 
  }

  
 

