<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Allocation to F Component List
                    </h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="roleCode?.role?.code === 'DS'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                        >Create New</span>                    
                    </button>
                </ng-template>

            </p-toolbar>
        </div>
    </div>
    <div #customerTable id="entities" class="col-12 px-4 mt-3">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [enableView]="true" [lazyLoadOnInit]="true" [enableAction]="true" (emitRow)="onSelectRow($event)"></custom-filter-table>
    </div>
</div>