<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="payments.electedRepresentative.payment.voucher.name">
                        Elected Representative Payment Voucher</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row voucherfont">
                        <h6 [ngClass]="fontService.headingClass" jhiTranslate="payments.add.voucher.generation.name">
                            Add Voucher Generation</h6>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">{{"payments.voucher.number.name"|translate}}:<br />{{ voucherGenerationDto?.vendorTransactionDto?.voucherNo }}
                            </p>
                        </div>
                        <div class="col-lg-1 col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">{{"date.name"|translate}}:<br />{{ date | date:'dd/MM/yyyy' }}</p>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"implementing.agency.name"|translate}}:<br />{{ this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.name }}
                            </p>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.villagegst.number.name"|translate}}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto.gstNo }}
                            </p>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.limit.amount.name"|translate}}:<br /> {{ this.voucherGenerationDto?.vendorTransactionDto .iaComponentLimitDto?.realizedLimitAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}
                            </p>
                        </div>
                        <div class="col-lg-1 col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.department.name"|translate}}:<br>{{this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.departmentDto.name}}
                        </div>
                        <div class="col-lg-1 col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.scheme.name"|translate}}:<br>{{this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.schemeDto.name}}
                        </div>
                        <div class="col-lg-1 col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.level.type.name"|translate}}:<br>{{levelMasterDto.name}}
                            </p>
                        </div>
                        <div class="col-lg-1 col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{ "funds.fin.year.name" | translate }}:<br />{{ finYearDTO.name }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
          <div class="row">
            <div class="col">
              <div class="card p-3">
                <div class="row">
                  <h6></h6>
                  <div class="col-md-4">
                    <p>Department:<br>{{voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.departmentDto.name}}</p>
                  </div>
                  <div class="col-md-4">
                    <p>Scheme:<br>{{ voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.schemeDto.name}}</p>
                  </div>
                  <div class="col-md-4">
                    <p>Level Type:<br>{{levelMasterDto.name}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.grant.level.name">Grant Level</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="grantlevel" [items]="grantLevel" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" [disabled]="readonly" (change)="ongrandLevel()" class="form-control dropdown-width"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !grantlevel }">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="breadcrumb.component.major.component">Major Component</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="majaorComponentdto" [disabled]="readonly" [items]="majaorComponent" [bindLabel]="dropDownLabel" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="onMajorComponent()" class="form-control dropdown-width"
                                [ngClass]="{'is-invalid': ngSelectInvalid && !majaorComponentdto}">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="breadcrumb.component.minor.component">Minor Component</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="minorComponentDto" [disabled]="readonly" [items]="minorComponent" [bindLabel]="dropDownLabel" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="onMinorComponent()" class="form-control dropdown-width"
                                [ngClass]="{'is-invalid': ngSelectInvalid && !minorComponentDto}">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="breadcrumb.component.sub.component">Sub Component</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="subComponentDto" (change)="onChangesubComponent($event)" [disabled]="readonly" [items]="subComponent" [bindLabel]="dropDownLabel" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width"
                                [ngClass]="{'is-invalid': ngSelectInvalid && !subComponentDto}">
                            </ng-select>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="subComponentDto" class="row">
            <div class="col-md-12 card_flex">
                <div class="col-md-6">


                    <div class="card p-3 pr_card">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.panchayat.resolution.number.name">Panchayat Resolution No</label>
                                <input type="text" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="15" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.panchayatResolutionNumber" name="name" autocomplete="off"
                                    id="levelType" [readonly]="readonly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !voucherGenerationDto.vendorTransactionDto.panchayatResolutionNumber }" />
                            </div>
                            <div *ngIf="enableZonalButton" class="col-md-4">
                                <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Zonal User</label
                          >
                          <ng-select
                            id="scheme"
                            [disabled]="readonly"
                            [(ngModel)]="selectedZonalUser"
                            [items]="zonalList"
                            bindLabel="userName"
                            appearance="outline"
                            [searchable]="true"
                            [closeOnSelect]="true"
                            [clearable]="false"
                            class="form-control dropdown-width"
                          >
                          </ng-select>
                        </div>

                        <!-- <div class="col-md-3">
                <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.staff.name">Name of the PRI Rep/ Staff</label>
                                <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="selectedVendor" [items]="vendorList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" (search)="vendorFilter($event)" [disabled]="readonly" (change)="popupValue()"
                                    class="form-control dropdown-width" [ngClass]="{ 'is-invalid': ngSelectInvalid && !selectedVendor.name }" maxlength="50">
                                </ng-select>
                            </div>
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-sm-3 mt-3">
                                        <p [ngClass]="fontService.fontClass">
                                            {{"payments.staff.accno.name"|translate}}:<br />{{ selectedVendor?.accountNumber }}
                                        </p>
                                    </div>
                                    <div class="col-sm-3 mt-3">
                                        <p [ngClass]="fontService.fontClass">
                                            {{"payments.staff.bank.name"|translate}}:<br />{{ selectedVendor?.bankName }}
                                        </p>
                                    </div>
                                    <div class="col-sm-3 mt-3">
                                        <p [ngClass]="fontService.fontClass">
                                            {{"payments.staff.id"|translate}}:<br />{{ selectedVendor?.staffId }}
                                        </p>
                                    </div>
                                    <div class="col-sm-3 mt-3">
                                        <p [ngClass]="fontService.fontClass">
                                            {{"payments.staff.ifsc.code.name"|translate}}:<br />{{ selectedVendor?.ifscCode }}
                                        </p>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <!-- <div class="row">
              <div *ngIf="selectedVendor.name" class="col transaction">
                <div class="mt-1 p-2" [ngClass]="fontService.labelClass"
                  jhiTranslate="payments.vendor.last.transaction.name">last 5 Transaction</div>
                <button pButton pRipple label="view" (click)="getvendorTrans()" class="p-button-success"
                  styleClass="button"></button>
              </div>
  
            </div> -->
                    </div>
                </div>
                <div class="col-md-6" *ngIf="isFileUpload">
                    <div class="card pr_card">
                        <div class="col-md-8  mx-2 mt-2">
                            <label class="form-control-label req pt-md-0 pt-sm-2 pt-xs-2" for="scheme" [ngClass]="fontService.labelClass">Meeting Attendance Copy</label
                        >
                        <div>
                          <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file types are jpg, jpeg, png, pdf and
                            Maximum file size should be 1MB</small>
                        </div>
                        <p-fileUpload *ngIf="!readonly"
                          accept="image/png,image/jpeg,image/jpg,application/pdf"
                          (onUpload)="onUpload($event)"
                          [customUpload]="true"
                          [disabled]="isFileUploaded"
                          (uploadHandler)="onUpload($event)"
                          auto="true"
                          multiple="false"
                          [showUploadButton]="false"
                          [showCancelButton]="false"
                          chooseLabel="{{'common.upload'|translate}}"
                          maxFileSize="1000000" 
                        >
                        </p-fileUpload>
                        <div class="row mt-2" *ngIf="isFileUploaded">
                          <div class="col">
                            <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument()">{{uFilename}}</a>
                            <i  class="pi pi-times add-margin" *ngIf="!readonly" (click)="removeDocument()"></i>
                            <i  class="pi pi-download add-margin" (click)="downloadDocument()"></i>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </div>
        </div>
        <div *ngIf="voucherGenerationDto?.vendorTransactionDto?.panchayatResolutionNumber" class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <!--  <div class="col-12">
                              <label class="form-control-label" for="schemeDescription">GST Applicable</label><br>
                            <p-radioButton name="option" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.gstApplicable" value="option1" label="Yes"></p-radioButton>
                            <p-radioButton name="option" value="option2" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.gstApplicable" label="No"></p-radioButton>
                        </div> -->
                        <div class="col-md-12 mt-2">
                            <p-table [value]="voucherGenerationDto.voucherBillDescElectedRepList" [resizableColumns]="true" responsiveLayout="scroll" style="overflow-y: visible">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.labelClass">{{'payments.table.sno'|translate}}</th>
                                        <th class="req" *ngIf="enableEmployee" [ngClass]="fontService.labelClass">{{'payments.table.name'|translate}}</th>
                                        <th class="req" [ngClass]="fontService.labelClass">{{'payments.table.description'|translate}}</th>
                                        <th class="req" [ngClass]="fontService.labelClass">{{'payments.table.month'|translate}}</th>
                                        <th class="req" [ngClass]="fontService.labelClass">{{'payments.table.finyear'|translate}}</th>
                                        <th class="req" [ngClass]="fontService.labelClass">{{'payments.table.noOfDaysAttended'|translate}}</th>
                                        <th class="req" [ngClass]="fontService.labelClass">{{'payments.table.amount'|translate}}</th>
                                        <th [ngClass]="fontService.labelClass">{{'payments.table.grossTotal'|translate}}</th>
                                        <!-- <th [ngClass]="fontService.labelClass">{{'payments.table.delete'|translate}}</th> -->
                                        <!-- <th style="text-align: right"> -->
                                        <!-- <button pButton pRipple icon="pi pi-plus"
                                                class="p-button-success custom-button" styleClass="button"
                                                (click)="addRow()" [disabled]="readonly">
                                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                                jhiTranslate="common.row"></span>
                                            </button> -->
                                        <!-- </th> -->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list let-index="rowIndex">
                                    <tr>
                                        <td>{{ index + 1 }}</td>
                                        <td *ngIf="enableEmployee">
                                            <ng-select [appendTo]="'body'" id="scheme" [items]="employeeList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" class="form-control" [(ngModel)]="list.electedRepDto" (change)="addDescritption(list)"
                                                appendTo="body" [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.electedRepDto }">
                                            </ng-select>
                                        </td>
                                        <td>
                                            <ng-select [appendTo]="'body'" id="scheme" style="min-width:210px" [(ngModel)]="list.descriptionDto" [items]="descriptionList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" class="form-control" [disabled]="readonly"
                                                appendTo="body" (change)="addDescritption(list)" [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.descriptionDto }">
                                            </ng-select>
                                        </td>
                                        <td>
                                            <p-dropdown [appendTo]="'body'" [disabled]="readonly" [options]="month" [(ngModel)]="selectedMonth"></p-dropdown>
                                        </td>
                                        <td>
                                            <ng-select [appendTo]="'body'" id="gender" bindLabel="name" [items]="finYear" appearance="outline" [(ngModel)]="list.finYearDto" [closeOnSelect]="true" [clearable]="true" [disabled]="readonly" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.finYearDto }">
                                            </ng-select>
                                        </td>
                                        <td>
                                            <p-inputNumber id="field_price" [(ngModel)]="list.quantity" (ngModelChange)="calculatetotal(list)" [readonly]="true" [min]="1" [max]="1" maxlength="15" [ngClass]="{'is-invalid': ngSelectInvalid && !list.quantity}"></p-inputNumber>
                                        </td>
                                        <td>
                                            <p-inputNumber id="field_price" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="list.amount" [disabled]="true" (ngModelChange)="calculatetotal(list)" [readonly]="readonly" maxlength="15" [ngClass]="{'is-invalid': ngSelectInvalid && !list.amount}"></p-inputNumber>

                                        </td>

                                        <td>
                                            <p-inputNumber id="field_price" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="list.grossAmount" mode="decimal" [maxFractionDigits]="2" [readonly]="true" [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.grossAmount }">
                                            </p-inputNumber>
                                        </td>
                                        <!-- <td>
                                            <button pButton pRipple type="button" icon="pi pi-trash"
                                                class="p-button-rounded p-button-text p-button-danger"
                                                (click)="delete(index)"></button>
                                        </td> -->
                                        <!-- <td></td> -->
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="row mt-3">

                        <div class="col-md-8">
                            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label><br>
                            <textarea id="w3review" name="w3review" rows="3" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.remark" [ngClass]="{ 'is-invalid': ngSelectInvalid && !voucherGenerationDto.vendorTransactionDto.remark }" cols="100" [disabled]="readonly"></textarea>
                        </div>

                        <div class="col-md-4" *ngIf="voucherGenerationDto.vendorTransactionDto.netAmount">
                            <table class="snippet generate">
                                <tr>
                                    <td [ngClass]="fontService.labelClass">
                                        {{"payments.amount.total.amount.name"|translate}}</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.netAmount | currency:'INR':'symbol':'1.2-2':'en-IN' || "Nill" }}
                                    </td>
                                </tr>
                                <tr *ngIf="voucherGenerationDto.vendorTransactionDto.cps">
                                    <td [ngClass]="fontService.labelClass">
                                        {{"payments.amount.cps.amount.name"|translate}}</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.cps | number : "1.2-2" || "Nill" }}
                                    </td>
                                </tr>
                                <tr *ngIf="voucherGenerationDto.vendorTransactionDto.nhis">
                                    <td [ngClass]="fontService.labelClass">
                                        {{"payments.amount.nhis.amount.name"|translate}}</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.nhis | number : "1.2-2" || "Nill" }}
                                    </td>
                                </tr>
                                <tr *ngIf="voucherGenerationDto.vendorTransactionDto.otherDeductionIt">
                                    <td [ngClass]="fontService.labelClass">
                                        {{"payments.amount.other.deduction.amount.name"|translate}}</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.otherDeductionIt | number : "1.2-2" || "Nill" }}
                                    </td>
                                </tr>
                                <tr *ngIf="totalDeduction">
                                    <td [ngClass]="fontService.labelClass">
                                        {{"payments.amount.total.deduction.amount.name"|translate}}</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ totalDeduction | number : "1.2-2" || "Nill" }}
                                    </td>
                                </tr>
                                <!-- <div class="row " *ngIf="!readonly">
                    <div class="col-sm-3 generate mt-3">
                      
                      <button pButton pRipple label="Cancel" class="p-button-danger"></button>
                    </div>
                  </div> -->
                                <!-- <div class="row" *ngIf="enableRevertButton">
                    <div class="col-6"></div>
                    <div class="col-2 generate mt-3">
                      <button pButton pRipple label="Revert" (click)="revert()" class="p-button-danger"></button><br>
                    </div>
                    <div class="col-2 generate mt-3">
                      <button pButton pRipple label="Approve" (click)="genVocher()" class="p-button-success"></button><br>
  
                    </div>
                    <div class="col-2 generate mt-3">
                      <button pButton pRipple label="Reject" (click)="reject()" class="p-button-danger"></button>
                    </div>
  
                  </div> -->
                            </table>

                        </div>
                    </div>
                    <p-toolbar *ngIf="!enableRevertButton && !enableView">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="!enableSignButton && !readonly" pButton pRipple class="p-button-success custom-button margin" (click)="genVocher()" styleClass="button">
                            <span class="button-label" [ngClass]="fontService.buttonClass"
                              jhiTranslate="common.generatevoucher"></span>
                          </button>
                            <button *ngIf="enableSignButton && !voucherGenerationDto.vendorTransactionDto.signaturePresident" class="p-button-success custom-button margin" (click)="submitForm()">{{signButtonName}}</button>
                        </div>
                    </p-toolbar>

                    <p-toolbar *ngIf="enableRevertButton && !enableView">
                        <div class="p-toolbar-group-start"></div>
                        <button *ngIf="enableSignButton && !voucherGenerationDto.vendorTransactionDto.signaturePresident" class="p-button-success custom-button margin" (click)="submitForm()">{{signButtonName}}</button>
                        <div *ngIf="
                              (!enableSignButton &&(
                              !voucherGenerationDto?.vendorTransactionDto?.signStatus || !voucherGenerationDto.vendorTransactionDto.signaturePresident) &&  !voucherGenerationDto.vendorTransactionDto.signaturePresident)
                            " class="p-toolbar-group-end">
                            <button pButton pRipple (click)="revert()" class="p-button-warning custom-button">
                              <span
                                class="button-label"
                                [ngClass]="fontService.buttonClass"
                                jhiTranslate="common.revertbutton"
                              ></span>
                            </button>
                            <button *ngIf="!voucherGenerationDto.vendorTransactionDto.signStatus && !voucherGenerationDto.vendorTransactionDto.revertStatus" pButton pRipple (click)="genVocher()" class="p-button-success custom-button">
                              <span
                                class="button-label"
                                [ngClass]="fontService.buttonClass"
                                jhiTranslate="common.approvebutton"
                              ></span>
                            </button>
                            <button pButton pRipple (click)="reject()" class="p-button-danger custom-button">
                              <span
                                class="button-label"
                                [ngClass]="fontService.buttonClass"
                                jhiTranslate="common.rejectbutton"
                              ></span>
                            </button>
                        </div>

                        <!-- <form *ngIf="enableSignButton || voucherGenerationDto.vendorTransactionDto.signStatus" ngNoForm
                                        action="https://demosignergateway.emsigner.com/eMsecure/V3_0/Index" method="POST">
                                        <input id="Parameter1" name="Parameter1" type="hidden" [value]='param1' />
                                        <input id="Parameter2" name="Parameter2" type="hidden" [value]='param2' />
                                        <input id="Parameter3" name="Parameter3" type="hidden" [value]='param3' />
            
                                        <div class="container text-center mt-10">
                                            <button class="p-button-success custom-button margin" type="submit"
                                                onclick="submit()">{{signButtonName}}</button>
                                        </div>
                                    </form> -->

                        <!-- <button *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident && !enableRePaymentButton && !disablepaymentButton && !checkstatusButton" pButton pRipple [label]="buttonText" (click)="PaymentSucess()" class="p-button-success"></button> -->
                        <p *ngIf="checkstatusButton" style="font-size: 18px;color:red;font-weight:700;">Payment Failed</p>
                        <!-- <button *ngIf="checkstatusButton" pButton pRipple label="Check Transaction Status" (click)="checkTransactionStatus()" class="p-button-success"></button> -->

                    </p-toolbar>
                    <p-toolbar *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident">
                        <div class="p-toolbar-group-start">
                            <p *ngIf="this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus === 'Failed'" style="font-size: 18px;color:red;font-weight:700;">Payment Has Been {{this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus}}</p>
                            <p *ngIf="this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus === 'Success'" style="font-size: 18px;color:green;font-weight:700;">Payment Has Been {{this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus}}</p>
                        </div>
                        <button *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident && roleCode.role.code !== 'DAD'  && voucherGenerationDto?.vendorTransactionDto?.transactionStatus !== 'Failed'" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
                    </p-toolbar>
                </div>
            </div>
        </div>
        <p-dialog [(visible)]="profileDialog" [style]="{ width: '1020px', height: '400px' }" header="Last Transaction" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
            <p-table [value]="searchResult.items" [resizableColumns]="true" styleClass="dyanamic-table" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Voucher No</th>
                        <th>Vendor Name</th>
                        <th>Vendor Account</th>
                        <th>Transaction Date</th>
                        <th>Rate</th>
                        <th>Total Transction Amount</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-index="rowIndex">
                    <tr>
                        <td>{{ data.voucherNo }}</td>
                        <td>{{ data.personalVendorName }}</td>
                        <td>{{ data.personalAccountNo }}</td>
                        <td>{{ data.transDate | date : "shortDate" }}</td>
                        <td>{{ data.grossAmount }}</td>
                        <td>{{ data.transactionAmount }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-dialog>
        <p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
            <p-header [ngClass]="fontService.headingClass">
                Payment Alert Box
            </p-header>
            <h1 style="text-align: center;font-size: 18px;">{{paymentSucessText}}</h1>
            <ng-template pTemplate="footer">
                <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false">
        <span
          class="button-label"
          [ngClass]="fontService.buttonClass"
          label="Ok"
          (click)="redirect()"
          >Ok</span
        >
      </button>
            </ng-template>
        </p-dialog>
    </div>
</div>
<p-dialog [(visible)]="deleteDialog" [style]="{ width: '500px', height: '200px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
        <b class="text-danger">Reject Dialog</b>
    </p-header>
    <p>Something went wrong with the voucher, please reject the voucher</p>
    <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button-danger">
        <span
          class="button-label"
          [ngClass]="fontService.buttonClass"
          label="Reject"
          (click)="reject()"
          >Reject</span
        >
      </button>
    </ng-template>

</p-dialog>

<!-- <p-dialog [(visible)]="bulkVoucherDialog" [style]="{ width: '500px', height: '200px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
        <b class="text-danger">Bulk Voucher Dialog</b>
    </p-header>
    <p>This Voucher Already in Bulk Voucher and Bulk Voucher Number is {{voucherGenerationDto.vendorTransactionDto.parentVoucherNumber}}</p>
    <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button-danger">
    <span
      class="button-label"
      [ngClass]="fontService.buttonClass"
      label="Ok"
      (click)="bulkVoucherRedriect()"
      >Reject</span
    >
  </button>
    </ng-template>

</p-dialog> -->
<p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <ng-template pTemplate="body">
        <div style='position: relative; height: 100%;'>
            <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
            <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
        </div>
    </ng-template>
</p-dialog>