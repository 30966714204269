<div class="container-fluid">
    <div class="row">
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <h5 [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.name">Dashboard</h5>
                <!-- <h5 [ngClass]="fontService.headingClass" > - {{levelMasterName}} Panchayat</h5> -->
            </ng-template>
        </p-toolbar>
    </div>
    <div class="row">
        <div class="col-lg-2 col-md-4 col-sm-3">
            <label class="form-control-label req" for="finYear" [ngClass]="fontService.labelClass">Fin Year</label>
            <ng-select id="gender" bindLabel="name" [items]="finYearList" appearance="outline" [(ngModel)]="finYear" [closeOnSelect]="true" [clearable]="false" (ngModelChange)="onFinYearChange()" class="form-control">
            </ng-select>
        </div>


    </div>
    <div class="row g-2 mb-5 mt-3">
        <div class="col-md">
            <p-card [style]="{                         
                backgroundColor: '#183883', 
                color: 'white'}" styleClass="fund-card">
                <!-- <p [ngClass]="fontService.regularBoldClass">{{'dashBoard.budgetAmount'|translate}}</p>
                <b >100%</b>
                <h5 class="justify-content-center">  {{panchayatSAmountINR }}</h5> -->
                <p class="size" [ngClass]="fontService.regularClass"><span class="big">{{SValue}}
                    </span>{{'dashBoard.budgetAmount'|translate}}</p>
                <h5>{{panchayatSanctionAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h5>
            </p-card>
        </div>
        <div class="col-md">
            <p-card [style]="{                         
                backgroundColor: '#97c95c', 
                color: 'white'}" styleClass="fund-card">
                <p class="size" [ngClass]="fontService.regularClass"><span class="big">{{UValue}}
                    </span>{{'dashBoard.utilizedAmount'|translate}}</p>
                <h5>{{panchayatUtilizedAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h5>
            </p-card>
        </div>
        <div class="col-md">
            <p-card [style]="{                         
                backgroundColor: '#FEB135', 
                color: 'white'}" styleClass="fund-card">
                <p class="size" [ngClass]="fontService.regularClass"><span class="big">{{BValue}}
                    </span>{{'dashBoard.balanceAmount'|translate}}</p>
                <h5>{{panchayatBalanceAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</h5>
            </p-card>
        </div>
        <div class="col-md-3">
            <p-card styleClass="fund-card">
                <dx-pie-chart type="doughnut" id="pie" [dataSource]="fundData">
                    <dxi-series argumentField="name" valueField="amount" name="name" type="doughnut" palette="Material">
                    </dxi-series>
                    <dxo-legend [margin]="0" horizontalAlignment="right" verticalAlignment="top"></dxo-legend>
                    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeChartTooltip">
                    </dxo-tooltip>
                </dx-pie-chart>
            </p-card>
        </div>
    </div>
    <div class="row g-2 mb-2">
        <div class="col-md-12">
            <div class="card border-left-info shadow p-2">
                <div class="row">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.grantFundReport">
                        Fund Report on Grant Level</h5>
                </div>
                <p-table [value]="fundDetails" [resizableColumns]="true" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [ngClass]="fontService.labelClass"> {{'dashBoard.grantLevel'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.openingBalance'|translate}}<br>01.04.{{finYear?.name?.slice(0,4)}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.budgetAmount'|translate}}<br>{{'dashBoard.sanction'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.grandTotal1'|translate}}<br>{{'dashBoard.grandTotal2'|translate}}<br>{{'dashBoard.grandTotal3'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> Allocated Amount</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">Balance Amount Available<br>To Allocate</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">{{'dashBoard.remainingBalance'|translate}}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">1</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">2</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">3 = 1+2</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">4</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">5 = 3-4</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list let-index="rowIndex">
                        <tr>
                            <td><b>District Minor Grant and Special Funds</b></td>
                            <td style="text-align: center;"><b>{{list.openingBalance}}</b></td>

                            <td style="text-align: center;"><b>{{list.allocatedAmount}}</b></td>
                            <td style="text-align: center;"><b>{{list.grandSanctioned}}</b></td>
                            <td style="text-align: center;">
                                <b>{{list.utilizedAmount}}</b>
                            </td>
                            <td style="text-align: center;"><b>{{list.balanceAmount}}</b></td>
                            <td>
                                <p-progressBar [value]="list.balancePercent" [style]="{'background-color': '#97c95ca8'}"></p-progressBar>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>