<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">{{headingText}}</h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="roleCode === 'VMAKER'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
                        <span
                        class="button-label"
                        [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.generatevoucher">
                    </span>
                    </button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div *ngIf="deductionTypeList" class="input-row py-10 pb-2">
        <ng-select [placeholder]="deductionType ? '' : 'Deduction Type'" id="statutoryType" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [items]="deductionTypeList" [(ngModel)]="deductionType">
        </ng-select>
        <ng-select id="scheme" [(ngModel)]="finYear" [placeholder]="finYear ? '' : 'Fin Year'" [items]="finYearList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
        </ng-select>
        <input *ngIf="roleCode === 'ZVM'" pInputText pKeyFilter="alphanum" placeholder="Enter LGD Code" [(ngModel)]="lgdCode" />
        <button pButton pRipple label="Search" (click)="filterProps=[];loadPage(1)" class="p-button-sm"></button>
        <button pButton pRipple label="Clear" (click)="onFilterClear()" class="p-button-sm"></button>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" (emitRow)="onSelectRow($event)" [enableView]="true" [showRedirectButton]="false" [flagButton]="roleCode == 'VMAKER'"></custom-filter-table>
        </div>
    </div>
    <p-dialog [(visible)]="enableFlagVoucher" [style]="{width: '900px',height:'400px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="enableStatusVoucher = false">
        <!-- <p-header [ngClass]="fontService.headingClass">
            {{ "dashBoard.transactionlist" | translate }}
        </p-header> -->
        <app-flagging-page *ngIf="enableFlagVoucher" [vendorTransaction]="currentDeductionTransaction" [personalFlag]="currentDeductionTransaction.deductionType" (saveEmit)="enableFlagVoucher=false;loadPage(1)"></app-flagging-page>
    </p-dialog>

</div>