import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { FontService } from 'src/app/shared/font-service/font.service';
import { LazyLoadEvent } from 'primeng/api';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-employee-transfer-report',
  templateUrl: './employee-transfer-report.component.html',
  styleUrls: ['./employee-transfer-report.component.scss']
})
export class EmployeeTransferReportComponent implements OnInit {

  constructor(public reportService: ReportService,
    private spinner: NgxSpinnerService,
    public fontService: FontService,
    private sessionStorage: SessionStorageService,
    private notificationService: NotificationService) { }
  employeeDetails: any;
  totalCount: number;
  itemsPerPage: number = 10;
  page: number;
  first = 0;
  minDate: Date;
  maxDate: Date;
  fromDate: Date;
  startDate: string;
  toDate: Date;
  endDate: string;
  dateError: boolean;
  ngOnInit(): void {
    this.maxDate = new Date();
  }

  getEmployeeReport(page) {
    this.spinner.show();
   
    const pageToLoad: number = page ?? this.page ?? 1;
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      levelMasterId: this.sessionStorage.retrieve('levelmasterId'),
      levelType: this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase()
    };
    if (this.fromDate && this.toDate) {
      const date = new Date(this.fromDate);
      this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
      const enddate = new Date(this.toDate);
      this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} ${this.pad(enddate.getHours())}:${this.pad(enddate.getMinutes())}:${this.pad(enddate.getSeconds())}`;
      if (this.startDate > this.endDate) {
        this.dateError = true;
        return
      }
      this.dateError = false;
      filter['fromDate'] = this.startDate;
      filter['toDate'] = this.endDate;
    }
    this.reportService.getEmployeeTransfer(filter).subscribe(
      (response) => {
        this.spinner.hide();
        this.totalCount = Number(response.headers.get('X-Total-Count'));
        this.employeeDetails = response.body;
      },
      () => {
        this.spinner.hide();
      }
    )
  }

  loadFilterValues(event: LazyLoadEvent) {
    this.first = event.first;
    event.first = (event.first + event.rows) / event.rows;
    this.page = event.first;
    this.itemsPerPage = event.rows;
    this.getEmployeeReport(this.page);

  }

  checkStatus() {
    let filter = {
      reportName: 'Employee Transfer Report',
    };
    this.spinner.show();
    this.reportService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body
        this.saveBlobToExcel(b.data, 'Employee Transfer Report');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.message, '');
        this.spinner.hide();
      }
    );
  }
  downloadExport() {
    let filter = {
      reportName: 'Employee Transfer Report',
      levelMasterId: this.sessionStorage.retrieve('levelmasterId'),
      levelType: this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase()
     
    };
    if (this.fromDate && this.toDate) {
      const date = new Date(this.fromDate);
      this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
      const enddate = new Date(this.toDate);
      this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} ${this.pad(enddate.getHours())}:${this.pad(enddate.getMinutes())}:${this.pad(enddate.getSeconds())}`;
      if (this.startDate > this.endDate) {
        this.dateError = true;
        return
      }
      this.dateError = false;
      filter['fromDate'] = this.startDate;
      filter['toDate'] = this.endDate;
    }
    this.spinner.show();
    this.reportService.reportAsyncDownload({ ...filter }).subscribe(
      (res) => {
        this.notificationService.alertSuccess(res.body, '');
        this.spinner.hide();
      },
      (onError) => {
        onError.error = JSON.parse(onError.error);
        this.notificationService.alertError(onError.error.message, '');
        this.spinner.hide();
      }
    );
  }

  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }

  pad(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }

  download(){
    this.spinner.show();
    this.reportService.employeeTransferReport().subscribe(
      (response)=>{
        this.spinner.hide();
        this.saveBlobToExcel(response.body,'Employee Transfer Report');
      },
      ()=>{
        this.spinner.hide();
      }
    )
  }

 


}
