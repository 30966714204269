import { Component } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { DeductionService } from '../deduction-service';
import { LocalStorageService, SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';
import { DeductionReportList } from '../deduction.model';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-deduction-report-list',
  templateUrl: './deduction-report-list.component.html',
  styleUrl: './deduction-report-list.component.scss'
})
export class DeductionReportListComponent {
  currentPath: string;
  headingText: string;
  deductionTypeList: string[];
  deductionType: string
  month: Date;
  itemsPerPage: number = 10;
  page: number;
  startDate: string;
  endDate: string;
  searchResult: SearchResult<DeductionReportList> =
    new SearchResult<DeductionReportList>();
  totalAmount: number;
  filterProps: any[];
  cols: any;
  first = 0;
  deductionList: DeductionReportList[];
  totalCount: number=0;

  constructor(
    private location: Location,
    public fontService: FontService,
    protected notificationService: NotificationService,
    private datePipe: DatePipe,
    private deductionService: DeductionService,
    private spinner: NgxSpinnerService,
    private sessionStorage: SessionStorageService,


  ) {

  }
  ngOnInit() {
    
    this.currentPath = this.location.path();
    switch (this.currentPath) {
      case "/Statutory-deduction-report":
        this.headingText = 'Statutory Deduction';
        this.deductionTypeList = ['IT', 'GST', 'LWF'];
        break;
      case "/Security-deposit-report":
        this.headingText = 'Security Deposit';
        this.deductionType = 'SD';
        break;
      case "/flag-day-report":
        this.headingText = 'Flag Day';
        this.deductionType = 'FD';
        break;
    }
  }

  loadPage(page) {
    if (this.month && this.deductionType) {
      let month = this.month.getMonth();
      let year = this.month.getFullYear();
      const date = new Date(this.datePipe.transform(new Date(year, month, 1), 'yyyy-MM-dd HH:mm:ss.SSS'))
      this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
      const enddate = new Date(this.datePipe.transform(new Date(year, month + 1, 0, 23, 59, 59, 999), 'yyyy-MM-dd HH:mm:ss.SSS'));
      this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} ${this.pad(enddate.getHours())}:${this.pad(enddate.getMinutes())}:${this.pad(enddate.getSeconds())}`;
      const pageToLoad: number = page ?? this.page ?? 1;
      let filter = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        deductionCode: this.deductionType,
        fromDate: this.startDate,
        toDate: this.endDate
      };
      if(this.sessionStorage.retrieve('rolecode') !== 'SA'){
        filter['levelMasterId'] =  this.sessionStorage.retrieve('levelmasterid');
      }
      this.spinner.show();
      this.deductionService.getDeductionList
        (filter).subscribe(
          (response) => {
            this.spinner.hide();
            this.searchResult.total = Number(response.headers.get('X-Total-Count'));
            this.page = page;
            this.deductionList = response.body;
            this.totalCount = Number(response.headers.get('X-Total-Count'));
            this.totalAmount = response.body.reduce((total, deduction) => total + deduction.deductionAmount, 0);
          },
          (onError) => {
            this.spinner.hide();

            if (onError.status == 400) {
              this.notificationService.alertError(
                onError.error.title, '');
            } else {
              this.notificationService.alertError('Error!', '');
            }
          }
        )
    }
    else {
      this.notificationService.alertError('Please Select Month And Deduction Type', '');
    }
  }

  loadFilterValues(event: LazyLoadEvent) {
   
    this.first = event.first;
    event.first = (event.first + event.rows) / event.rows;
    this.page = event.first;
    this.itemsPerPage = event.rows;
    if(this.totalCount !== 0) {
      this.loadPage(this.page);
    }
  }

  pad(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }

  onFilterClear() {
    this.month = null;
    this.startDate = null;
    if(this.currentPath === '/Statutory-deduction-report') {
      this.deductionType = null;
    }
    this.totalAmount = 0;
    this.deductionList = [];
    this.totalCount = 0;
  }

}
