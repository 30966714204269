<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Funds Receipt Report
                    </h5>
                </ng-template>

            </p-toolbar>
        </div>
    </div>
    <div class="row px-4">
        <div class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Fin Year</label>
            <ng-select id="scheme" [(ngModel)]="finYear" [items]="finYearList" bindLabel="name" appearance="outline" (change)="onchangeFinYear()" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">From Date</label><br>
            <p-calendar id="fromDate" dateFormat="dd/mm/yy" [(ngModel)]="fromDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate" [minDate]="minDate"></p-calendar>

        </div>
        <div class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">To Date</label><br>
            <p-calendar id="toDate" dateFormat="dd/mm/yy" [(ngModel)]="toDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate" [minDate]="minDate"></p-calendar>
        </div>
        <div class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Select Component</label>
            <ng-select id="scheme" [(ngModel)]="compId" [items]="componentType" bindValue="id" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>

    </div>
    <div class="row px-4 mt-2">

        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-success custom-button" (click)="loadPage(1)" styleClass="button">
  <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.searchbutton">Search</span>
</button>
        </div>
        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-danger custom-button">
  <span class="button-label" [ngClass]="fontService.buttonClass" (click)="finYear = defaultFinyear; fromDate = null;toDate = null;compId =null" jhiTranslate="common.clearbutton">clear</span>
</button>
        </div>
        <div class="col-2">

        </div>
        <div class="col-2">

        </div>
        <div class="col-2">

        </div>
        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple label="Download" class="p-button-success custom-button" (click)="downloadExport()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                ></span>
            </button>
        </div>
        <div class="col-2" style="display: flex;align-items: flex-end;">
            <button pButton pRipple label="Check Download Status" class="p-button-success custom-button" (click)="checkStatus()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                ></span>
            </button>
        </div>
    </div>
    <div *ngIf="search" #customerTable id="entities" class="col-12 px-4 mt-3">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [lazyLoadOnInit]="true" [enableAction]="true" [enableView]="false" [enableAction]="false"></custom-filter-table>
    </div>
</div>