import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { filterQuery } from 'src/app/shared/common.model';
import { TwadAccountSummary } from '../twad-master-list/twad.model';
import { TwadMasterService } from '../twad-master-list/twad.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { LazyLoadEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';
import { Location } from '@angular/common';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-twad-dashboard',
  templateUrl: './twad-dashboard.component.html',
  styleUrls: ['./twad-dashboard.component.scss']
})
export class TwadDashboardComponent implements OnInit {
  roleCode: any;
  finYearList: any;
  finYear: any;
  defaultFinyear: any;
  fundDetails: TwadAccountSummary[];
  totalCount: number;
  itemsPerPage:number = 10;
  page: number;
  first = 0;
  tableData = {
    openingBalance: 5000,
    sanctionedAmount: 10000,
    grandTotalSanctioned: 15000,
    dueAmountTANGEDCO: 10000,
    dueAmountTWAD: 12000,
    voucherInitiatedTANGEDCO: 3000,
    voucherInitiatedTWAD: 2500,
    voucherRejectedTANGEDCO: 0,
    voucherRejectedTWAD: 500,
    expenditureMadeTANGEDCO: 3000,
    expenditureMadeTWAD: 2000,
    balanceDueTANGEDCO: 7000,
    balanceDueTWAD: 10000,
    balanceSanctionLimit: 10000
  };
  currentPath: string;
  headingText: string;
  fundDetailsSummaryReport: any;
  tangedcoDashboardData: any;
  isZonal: boolean=true;
  villageList: any;
  levelMasId:number;

  constructor(
    private loginService: SigninService,
    private limitTransListService:LimitTransListService,
    protected twadMasterService:TwadMasterService,
    public fontService: FontService,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    private location: Location,
    private sessionStorage: SessionStorageService
  ) { 
    
  }

  async ngOnInit(): Promise<void> {
    this.currentPath = this.location.path();
    if(this.currentPath === '/Tneb-Dashboard') {
      this.headingText = "TANGEDCO Dashboard"
    }
    else {
      this.headingText = "TWAD Dashboard"

    }
    this.roleCode =this.sessionStorage.retrieve('roleCode');
    this.finYearList = this.sessionStorage.retrieve('finYear');
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
    this.defaultFinyear =  this.finYearList.find((_) => _.name === FinYear);
    if (this.sessionStorage.retrieve('roleCode') === 'ZVM') {
      this.isZonal = false;
      this.dashboardService.getVillagePanchayatByZonal().subscribe(
        (res) => {
          console.log('resZonal', res);
          this.villageList = res;
        },
        () => { }
      );
    }
    else {
      this.changeFinyear();
    }
  }

 

  

  LoadTangedcoBill(page) {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let filter = {
      page:pageToLoad-1,
      size:this.itemsPerPage,
      finYearId:this.finYear.id,
    };
    if(this.roleCode === 'VMAKER' || this.roleCode === 'VC1' || this.roleCode === 'VC2' || this.levelMasId) {
      filter['levelMasterId'] = this.levelMasId ? this.levelMasId:this.sessionStorage.retrieve('levelmasterId');
    }
    this.dashboardService
      .getTwadDashboardData({ ...filter })
      .subscribe(
        (res) => {
         this.fundDetails = res.body;
         this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  loadSummaryReport(page) {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let filter = {
      page:pageToLoad-1,
      size:this.itemsPerPage,
      finYearId:this.finYear.id,
    };
    if(this.roleCode === 'VMAKER' || this.roleCode === 'VC1' || this.roleCode === 'VC2' || this.levelMasId) {
      filter['levelMasterId'] = this.levelMasId ? this.levelMasId:this.sessionStorage.retrieve('levelmasterId');
    }
    this.dashboardService
      .getTwadTangedcoVoucherSummaryData({ ...filter })
      .subscribe(
        (res) => {
         this.fundDetailsSummaryReport = [];
         this.fundDetailsSummaryReport.push(res.body);
         this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getTangedcoDashboardData(page) {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let filter = {
      page:pageToLoad-1,
      size:this.itemsPerPage,
      finYearId:this.finYear.id,
    };
    if(this.roleCode === 'VMAKER' || this.roleCode === 'VC1' || this.roleCode === 'VC2' || this.levelMasId) {
      filter['levelMasterId'] = this.levelMasId ? this.levelMasId:this.sessionStorage.retrieve('levelmasterId');
    }
    this.dashboardService
      .getTangedcoDashboardData({ ...filter })
      .subscribe(
        (res) => {
         this.tangedcoDashboardData = res.body;
         this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  loadFilterValues(event: LazyLoadEvent) {
    let filterDefinitions = [];
    let filterValue = null
   
    this.first = event.first;
    event.first = (event.first + event.rows) / event.rows;
    this.page = event.first;
    this.itemsPerPage = event.rows;
    this.LoadTangedcoBill(this.page);
    
  }

  changeFinyear() {
    if(this.currentPath === '/Twad-Dashboard') {
      this.LoadTangedcoBill(1);
    }
    else {
      this.getTangedcoDashboardData(1);
    }
    this.loadSummaryReport(1);
  }

  onVillageChange() {
    this.isZonal = true;
    if(this.currentPath === '/Twad-Dashboard') {
      this.LoadTangedcoBill(1);
    }
    else {
      this.getTangedcoDashboardData(1);
    }
    this.loadSummaryReport(1);
  }

}
