import { ComponentDto } from './../../module/component-type/component-type';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import 'chart.js/auto';
import { ChartModule } from 'primeng/chart';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import enIN from '@angular/common/locales/en-IN';
import {
  LevelMaster,
  LevelMasterDto,
} from 'src/app/module/level-master/level-master';
import { saveAs } from 'file-saver';

import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { filterQuery } from 'src/app/shared/common.model';
import {
  AppConfig,
  Dashboard,
  DataItem,
  MonthWiseData,
  Months,
  TransactionData,
  VoucherStatusCount,
  fundDetails,
  voucherCount,
} from './dashboard';
import { DashboardService } from './dashboard.service';
import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import { SearchResult } from 'src/app/shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';
import Chart from 'chart.js/auto';
import { MenuItem } from 'primeng/api';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { DxChartModule } from 'devextreme-angular';
import { DxPieChartModule } from 'devextreme-angular';
import { LevelTypeService } from 'src/app/module/level-type/level-type.service';
import { LevelType } from 'src/app/module/level-type/level-type';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { ComponentType } from 'src/app/module/component-type/component-type';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { VendorTransaction } from 'src/app/payments/payment-voucher/payment-voucher.model';
import { SigninService } from 'src/app/auth/signin/signin.service';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import __drawTable from 'jspdf-autotable';
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';
import { CustomFilterTableComponent } from 'src/app/shared/custom-filter-table/custom-filter-table.component';
import { FontService } from 'src/app/shared/font-service/font.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/alert/notification.service';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [CurrencyPipe],
})
export class DashboardComponent {
  snaBudgetAmount: any = 0;
  snaUtilizedAmount: any = 0;
  snaBalanceAmount: any = 0;
  snaOSRAmount: any = 0;
  snaSFCAmount: any = 0;
  SValue: any;
  UValue: any;
  BValue: any;
  pendingVoucherDS: any;
  fundDS: any;
  isStateRole: boolean = false;
  roleName: any;
  voucherLegendName: string = "Vouchers Pending for S1";
  Sign = "signOneCount";
  demoDataSourceD1: any;
  isAdminComp: boolean = false;
  isPopComp: boolean = false;
  dataSources: any[] = [];
  districtLevelList: LevelMasterDto[];
  districtListDto: any;
  blockLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  levelId: number = 2;
  parentId: number;
  compTypeId: number;
  compType: string = "DISTRICT";
  removeBorder = false;
  majorSpentData: any[] = [];
  majorCompDS: any[] = [];
  panchayatSanctionAmount: any = 0;
  panchayatUtilizedAmount: any = 0;
  panchayatBalanceAmount: any = 0;
  panchayatOSRAmount: any = 0;
  panchayatSFCAmount: any = 0;
  fundDetails: fundDetails[];
  monthWiseData: MonthWiseData[];
  noMonthSpentData: boolean = false;
  sankeyData: DataItem[];
  noSpentData: boolean = false;
  levelMasterName: string;
  tabsDisabled: boolean;
  /*not state role */
  dataSource: TransactionData[];
  levelType: LevelType[];
  navigationSearch: any;
  levelTypeDto: any;
  grantLevel: ComponentType;
  grantlevel: any;
  grantLevelID: any = 1;
  roleLabel: string;
  leveLabel: any;
  budgetAmount: any = 0;
  utilizedAmount: any = 0;
  balanceAmount: any = 0;
  panchayatSAmountINR: any = 0;
  panchayatUAmountINR: any = 0;
  panchayatBAmountINR: any = 0;
  panchayatUtilizedPercent: any = 0;
  panchayatBalancePercent: any = 0;
  villageLevelList: LevelMasterDto[];
  villageLevelDto: any;
  levelMasterValue: any;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  filterPropsOne: any;
  filterVoucherProps: any;
  count: number;
  below5l: boolean;
  above5l: boolean;
  sign: string;
  cols: any;
  data: TransactionData[];
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  searchVoucherResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  searchAllResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  searchProcessingResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  searchSuccessResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  searchFailureResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  searchPartialResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  adminUserId: any;
  levelMasId: any;
  maxDate = new Date();
  fromDate: string = '';
  startDate: string = '';
  toDate: string;
  endDate: string;
  rangeDates: Date[];
  months: Months[];
  dateError: boolean = false;
  dropDownLabel: any;
  allTotal: number = 0;
  processingTotal: number = 0;
  successTotal: number = 0;
  failureTotal: number = 0;
  timelineDialog: boolean = false;
  enableAll: boolean = false;
  enableProcessing: boolean = false;
  enableSuccess: boolean = false;
  enableFailure: boolean = false;
  enablePartial: boolean = false;
  @ViewChild('calendar') calendar: any;
  vendorOptions: any
  value: any = { label: 'dashBoard.dropdown.all', value: 'ALL' };
  quickView: any[];
  selectedQuickView: any;
  isSelectiveDate: boolean = false;
  levelMasterFilter: boolean = false;
  personalFlagFilter: boolean = false;
  selectedMonth: any;
  events: any[];
  fundData: any[];
  isLazyLoading: boolean = false;
  timelineStatus: string;
  month: any[] = [
    { number: 1, label: 'dashBoard.months.january' },
    { number: 2, label: 'dashBoard.months.february' },
    { number: 3, label: 'dashBoard.months.march' },
    { number: 4, label: 'dashBoard.months.april' },
    { number: 5, label: 'dashBoard.months.may' },
    { number: 6, label: 'dashBoard.months.june' },
    { number: 7, label: 'dashBoard.months.july' },
    { number: 8, label: 'dashBoard.months.august' },
    { number: 9, label: 'dashBoard.months.september' },
    { number: 10, label: 'dashBoard.months.october' },
    { number: 11, label: 'dashBoard.months.november' },
    { number: 12, label: 'dashBoard.months.december' },
  ];



  @ViewChild(Table) dt: Table;
  voucherCount: voucherCount;
  voucherCount2: voucherCount;
  voucherCount3: voucherCount;
  userDetails: any;
  roleCode: any;
  enableVoucher: boolean;
  redriect: boolean;
  subTable: boolean;
  voucherstatusCount: VoucherStatusCount;
  searchVoucherStatusResult: SearchResult<any> =
    new SearchResult<any>();
  enableStatusVoucher: boolean;
  vouchercols: any;
  values: number[] = [];
  transid: number;

  //Dashboard demo data -- to be deleted later -- start
  dataSource1: any;
  demoDataSourceD2: any;
  isBlock: boolean = false;
  isVillage: boolean = false;
  districtName: string;

  demoDataD: any[] = [
    { name: 'Pay and Allowances', Value: 3 },
    { name: 'Capital Works', Value: 4 },
    { name: 'PU School Infra', Value: 5 },
  ]
  demoDataD3: any[] = [
    { category: 'Pay and Allowances', value: 3, compName: 'Major Comp' },
    { category: 'Capital Works', value: 4, compName: 'Major Comp' },
    { category: 'PU School Infra', value: 5, compName: 'Major Comp' },
  ]
  lang: string = 'en';
  flag: any;
  craAmount: any = 0;
  panchayatCraAmount: any = 0;
  crsaAmount: any = 0;
  panchayatCrsaAmount: any = 0;
  finYearList: FinYear[];
  finYear: FinYear;
  defaultFinyear: FinYear;
  voucherSummaryDetails: any;
  panchayatCrmaAmount: any;
  crmaAmount: any;
  fundSource: any[];
  fundName: any;
  enableFundsSource: boolean;
  paymentStatus: any;
  customizeTooltipdemo2(arg: any) {
    return {
      text: `${arg.argumentText}<br>${arg.seriesName} : ${arg.valueText}`,
    };
  }

  customizeTooltipdemo1(arg) {
    return { text: `${arg.argumentText}<br> ${arg.valueText}` };
  }
  customizeChartTooltip(arg: any) {
    return {
      text: `${arg.argumentText}- ${arg.percentText}`,
    };
  }
  customizeLabelText({ value }: { value: number }): string {
    if (value < 1e3) return value.toString();
    if (value >= 1e3 && value < 1e5) return +(value / 1e3).toFixed(2) + "K";
    if (value >= 1e5 && value < 1e7) return +(value / 1e5).toFixed(2) + "L";
    if (value >= 1e7) return +(value / 1e7).toFixed(2) + "C";
  }
  customizeLinkTooltip(info: any) {
    return {
      html: `<b>From:</b> ${info.source}<br/><b>To:</b> ${info.target}<br/><b>Amount:</b> ${info.weight}`,
    };
  }
  customizeTooltip(arg: any) {
    return {
      text: `Amount:₹${arg.valueText}`,
    };
  }
  constructor(
    private dashboardService: DashboardService,
    private levelMasterService: LevelMasterService,
    private spinner: NgxSpinnerService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private paymentService: PaymentService,
    private loginService: SigninService,
    private translateService: TranslateService,
    protected router: Router,
    public fontService: FontService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private limitTransListService: LimitTransListService,
    private notificationService: NotificationService,

  ) {
    registerLocaleData(enIN);
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.lang = lang === 'en' ? 'en' : 'ta';
    });
  }

  activeIndex: number = 0;
  async ngOnInit() {
    this.spinner.show();
    /* not state*/
    await this.loadFinYear();
    this.quickView = [
      { code: 'CM', name: 'dashBoard.dropdown.currentMonth' },
      { code: 'mon', name: 'dashBoard.dropdown.selectMonth' },
      { code: 'Q1', name: 'dashBoard.dropdown.quarter1' },
      { code: 'Q2', name: 'dashBoard.dropdown.quarter2' },
      { code: 'Q3', name: 'dashBoard.dropdown.quarter3' },
      { code: 'Q4', name: 'dashBoard.dropdown.quarter4' },
      { code: 'H1', name: 'dashBoard.dropdown.firstHalfYear' },
      { code: 'H2', name: 'dashBoard.dropdown.lastHalfYear' },
      { code: 'S', name: 'dashBoard.dropdown.selectDateRange' },
    ];
    // this.selectedQuickView = {
    //   code: 'CM',
    //   name: 'dashBoard.dropdown.currentMonth',
    // };
    // this.onQuickViewChange();
    if (this.value.value === 'YES') {
      this.cols = [
        {
          field: 'personalVendorName',
          header: 'Vendor Name',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.vendor.name',
        },
        {
          field: 'voucherNo',
          header: 'Voucher No',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.no',
        },
        {
          field: 'permanentVoucherNumber',
          header: 'Permanent Voucher Number',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
        },
        {
          field: 'transDate',
          header: 'Transaction Date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
          jhiTranslate: 'tableHead.transaction.date',
        },
        {
          field: 'majorComponentName',
          header: 'Major Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.major.compName',
        },
        {
          field: 'minorComponentName',
          header: 'Minor Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.minor.compName',
        },
        {
          field: 'subComponentName',
          header: 'Sub Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.sub.compName',
        },
        {
          field: 'transactionAmount',
          header: 'Total Transction Amount',
          jhiTranslate: 'tableHead.transaction.amount',
          isSelectcolumn: true,
          isFilterable: false,
          type: 'amount'
        },
        {
          field: 'transactionStatus',
          header: 'Payment Status',
          jhiTranslate: 'tableHead.payment.status',
          isSelectcolumn: true,
          isSortable: true,
          isFilterable: false,
          type:'color'
        },
      ];
    } else if (this.value.value === 'NO') {
      this.cols = [
        {
          field: 'commercialVendorName',
          header: 'Vendor Name',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.vendor.name',
        },
        {
          field: 'voucherNo',
          header: 'Voucher No',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.no',
        },
        {
          field: 'permanentVoucherNumber',
          header: 'Permanent Voucher Number',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
        },
        {
          field: 'transDate',
          header: 'Transaction Date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
          jhiTranslate: 'tableHead.transaction.date',
        },
        {
          field: 'majorComponentName',
          header: 'Major Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.major.compName',
        },
        {
          field: 'minorComponentName',
          header: 'Minor Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.minor.compName',
        },
        {
          field: 'subComponentName',
          header: 'Sub Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.sub.compName',
        },
        {
          field: 'transactionAmount',
          header: 'Total Transction Amount',
          jhiTranslate: 'tableHead.transaction.amount',
          isSelectcolumn: true,
          isFilterable: false,
          type: 'amount'
        },
        {
          field: 'transactionStatus',
          header: 'Payment Status',
          jhiTranslate: 'tableHead.payment.status',
          isSelectcolumn: true,
          isSortable: true,
          isFilterable: false,
          type:'color'
        },
      ];
    } else {
      this.cols = [
        {
          field: 'voucherNo',
          header: 'Voucher No',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.no',
        },
        {
          field: 'permanentVoucherNumber',
          header: 'Permanent Voucher Number',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
        },
        {
          field: 'transDate',
          header: 'Transaction Date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
          jhiTranslate: 'tableHead.transaction.date',
        },
        {
          field: 'majorComponentName',
          header: 'Major Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.major.compName',
        },
        {
          field: 'minorComponentName',
          header: 'Minor Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.minor.compName',
        },
        {
          field: 'subComponentName',
          header: 'Sub Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.sub.compName',
        },
        {
          field: 'transactionAmount',
          header: 'Total Transction Amount',
          jhiTranslate: 'tableHead.transaction.amount',
          isSelectcolumn: true,
          isFilterable: false,
          type: 'amount'
        },
        {
          field: 'transactionStatus',
          header: 'Payment Status',
          jhiTranslate: 'tableHead.payment.status',
          isSelectcolumn: true,
          isSortable: true,
          isFilterable: false,
          type:'color'
        },
      ];
    }
    /* end*/
    this.loginService.getuserDto().subscribe((res) => {
      this.roleName = res.body.role.name;
      this.roleCode = res.body.role.code;
      this.parentId = res.body.levelMasterId;

      this.vendorOptions = [
        { label: 'dashBoard.dropdown.all', value: 'ALL' },
        { label: 'dashBoard.dropdown.personal', value: 'YES' },
        { label: 'dashBoard.dropdown.commercial', value: 'NO' },
        { label: 'dashBoard.dropdown.employee', value: 'E' },
        { label: 'dashBoard.dropdown.electedRepresentative', value: 'ER' },
        { label: 'dashBoard.dropdown.bulkEmployee', value: 'BE' },
        { label: 'dashBoard.dropdown.bulkER', value: 'BER' },
      ];
      if (res.body.isTangedcoEnabled) {
        this.vendorOptions.push({ label: 'dashBoard.dropdown.tangedco', value: 'T' });
      }
      if (res.body.isTwadEnabled) {
        this.vendorOptions.push({ label: 'dashBoard.dropdown.twad', value: 'TWAD' });
      }
      if (this.roleCode == 'SA' || this.roleCode == 'SUA' || this.roleCode === 'DAD') {
        this.isStateRole = true;
      }
      if (this.roleName?.startsWith('Zonal')) {
        this.adminUserId = res.body.id;
      }

      this.levelMasId = res.body.levelMasterId;
      if (this.roleCode === 'DAD') {
        this.levelMasterService.getLevelMaster(this.levelMasId).subscribe(
          (res) => {
            this.levelMasterName = res.body.name;
            this.districtListDto = res.body;
            this.getPanchayatFundReport();
            this.loadSanKey(this.districtListDto);
            this.loadTimeline(this.levelMasId);
          },
          () => {
            this.onError();
          }
        );
      }
      this.levelTypeService.filter().subscribe(
        (res: HttpResponse<LevelType[]>) => {
          this.levelType = res.body;
          let index = 0;
          switch (true) {
            case this.roleName?.startsWith('State') || this.roleCode == 'SUA':
              this.isStateRole = true;
              this.getSnaDetails();
              this.getCompFundByLevel();
              this.getPendingVocuher();
              this.getFundByLevel();
              this.getMajorSpent();
              break;
            case this.roleName?.startsWith('District') && this.roleCode !== 'DAD':
              index = res.body.findIndex((_) => _.name === 'District');

              this.levelTypeDto = res.body[index];
              this.levelMasterService.getLevelMaster(this.levelMasId).subscribe(
                (res) => {
                  this.levelMasterName = res.body.name;
                  this.districtListDto = res.body;
                  this.loadGrantLevel();
                  this.getPanchayatFundReport();
                  this.loadSanKey(this.districtListDto);
                  this.loadTimeline(this.levelMasId);
                },
                () => {
                  this.onError();
                }
              );

              break;
            case this.roleName?.startsWith('Block'):
              index = res.body.findIndex((_) => _.name === 'Block');
              this.levelTypeDto = res.body[index];
              this.levelMasterService.getLevelMaster(this.levelMasId).subscribe(
                (res) => {
                  this.BlockLevelDto = res.body;
                  this.levelMasterName = res.body.name;
                  this.levelMasterService
                    .getLevelMaster(this.BlockLevelDto.parentId)
                    .subscribe(
                      (res) => {

                        this.districtListDto = res.body;
                        this.loadGrantLevel();
                        this.getPanchayatFundReport();
                        this.loadSanKey(this.BlockLevelDto);
                        this.loadTimeline(this.levelMasId);
                      },
                      () => {
                        this.onError();
                      }
                    );
                },
                () => { }
              );
              break;
            case this.roleName?.startsWith('Village'):
              index = res.body.findIndex((_) => _.name === 'Village');
              this.levelTypeDto = res.body[index];
              this.levelMasterService.getLevelMaster(this.levelMasId).subscribe(
                (res) => {
                  this.villageLevelDto = res.body;
                  this.levelMasterName = res.body.name;
                  this.levelMasterService
                    .getLevelMaster(this.villageLevelDto.parentId)
                    .subscribe(
                      (res) => {
                        this.BlockLevelDto = res.body;
                        this.levelMasterService
                          .getLevelMaster(this.BlockLevelDto.parentId)
                          .subscribe(
                            (res) => {

                              this.districtListDto = res.body;
                              this.loadGrantLevel();
                              this.getPanchayatFundReport();
                              this.loadSanKey(this.villageLevelDto);
                              this.loadTimeline(this.levelMasId);
                            },
                            () => {
                              this.onError();
                            }
                          );
                      },
                      () => {
                        this.onError();
                      }
                    );
                },
                () => {
                  this.onError();
                }
              );
              break;

            case this.roleName?.startsWith('Zonal'):
              index = res.body.findIndex((_) => _.name === 'Block');
              this.levelTypeDto = res.body[index];
              this.levelMasterService.getLevelMaster(this.levelMasId).subscribe(
                (res) => {
                  this.BlockLevelDto = res.body;
                  this.levelMasterService
                    .getLevelMaster(this.BlockLevelDto.parentId)
                    .subscribe(
                      (res) => {
                        this.levelMasterName = res.body.name;
                        this.districtListDto = res.body;
                        this.loadGrantLevel();
                        this.getPanchayatFundReport();
                        this.loadSanKey(this.BlockLevelDto);
                        this.loadTimeline(this.levelMasId);
                      },
                      () => {
                        this.onError();
                      }
                    );
                },
                () => { }
              );
              break;

            default:
          }
        },
        () => {
          this.onError();
        }
      );
      this.voucherMaker();
    },
      () => {
        this.onError();
      }
    );
  }
  voucherMaker() {
    if (this.value.value !== 'ALL') {
      if (this.roleName?.startsWith('Zonal')) {
        this.dashboardService
          .getVocherCount(this.finYear.id, 'YES', this.levelMasId, 1, this.value.value, this.adminUserId)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount = res.body;
          });
        this.dashboardService
          .getVocherCount(this.finYear.id, 'YES', this.levelMasId, 2, this.value.value, this.adminUserId)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount2 = res.body;
          });
        this.dashboardService
          .getVocherCount(this.finYear.id, 'YES', this.levelMasId, 3, this.value.value, this.adminUserId)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount3 = res.body;
          });
      }
      else {
        this.dashboardService
          .getVocherCount(this.finYear.id, 'YES', this.levelMasId, 1, this.value.value)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount = res.body;
          });
        this.dashboardService
          .getVocherCount(this.finYear.id, 'YES', this.levelMasId, 2, this.value.value)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount2 = res.body;
          });
        this.dashboardService
          .getVocherCount(this.finYear.id, 'YES', this.levelMasId, 3, this.value.value)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount3 = res.body;
          });
      }
      let countPayload = [
        {
          key: 'levelMasterId',
          operation: 'equals',
          value: this.levelMasId,

        },
        {
          key: 'count',
          operation: 'equals',
          value: 3,
        },
        {
          key: 'personalFlag',
          operation: 'equals',
          values: this.value.value
        }
      ]
      this.dashboardService
        .getVoucherStatusCount(countPayload)
        .subscribe((res: HttpResponse<VoucherStatusCount>) => {
          this.voucherstatusCount = res.body
        });
    } else {
      if (this.roleName?.startsWith('Zonal')) {
        this.dashboardService
          .getVocherCount(this.finYear.id, 'YES', this.levelMasId, 1, this.adminUserId)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount = res.body;
          });
        this.dashboardService
          .getVocherCount(this.finYear.id, this.levelMasId, 2, this.adminUserId)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount2 = res.body;
          });
        this.dashboardService
          .getVocherCount(this.finYear.id, this.levelMasId, 3, this.adminUserId)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount3 = res.body;
          });
      }
      else {
        this.dashboardService
          .getVocherCount(this.finYear.id, 'YES', this.levelMasId, 1)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount = res.body;
          });
        this.dashboardService
          .getVocherCount(this.finYear.id, 'YES', this.levelMasId, 2)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount2 = res.body;
          });
        this.dashboardService
          .getVocherCount(this.finYear.id, 'YES', this.levelMasId, 3)
          .subscribe((res: HttpResponse<voucherCount>) => {
            this.voucherCount3 = res.body;
          });
      }
      let countPayload = [
        {
          key: 'levelMasterId',
          operation: 'equals',
          value: this.levelMasId,

        },
        {
          key: 'count',
          operation: 'equals',
          value: 3,
        }
      ]
      this.dashboardService
        .getVoucherStatusCount(countPayload)
        .subscribe((res: HttpResponse<VoucherStatusCount>) => {
          this.voucherstatusCount = res.body
        });
    }
  }
  formatCash = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(2) + "L";
    if (n >= 1e7) return +(n / 1e7).toFixed(2) + "C";
  };
  getPendingVocuher() {
    this.dashboardService.getPendingVoucher(this.parentId, this.finYear.id)
      .subscribe((res: any) => {
        this.pendingVoucherDS = res.body;
      },
        (error) => {
        });

  }
  getFundByLevel() {
    this.dashboardService.getFundByLevel(this.parentId, this.finYear.id)
      .subscribe((res: any) => {
        this.fundDS = res.body;
      },
        (error) => {
        });

  }
  getSnaDetails() {
    this.dashboardService.getSnadetails()
      .subscribe((res: any) => {
        this.snaBudgetAmount = res.allocatedAmount;
        this.snaUtilizedAmount = res.utilizedAmount;
        this.snaBalanceAmount = res.balanceAmount;
        this.snaOSRAmount = res.osrAmount;
        this.snaSFCAmount = res.sfcAmount;
        this.craAmount = res.craAmount;
        this.crsaAmount = res.crsaAmount;
        this.crmaAmount = res.crmaAmount;
        this.SValue = this.formatCash(res.allocatedAmount);
        this.BValue = this.formatCash(res.balanceAmount);
        this.UValue = this.formatCash(res.utilizedAmount);
      },
        () => {
        });
  }
  getMajorSpent() {
    this.majorSpentData = [];
    this.dashboardService.getMajorReport(this.parentId, this.levelId, this.finYear.id)
      .subscribe((res) => {
        res.body.forEach((element, i) => {
          element.majorCompSpent.forEach((e) => {
            const data =
            {
              levelMasterName: element.levelMasterName,
              totalSpent: e.totalSpent,
              name: e.majorCompName + e.majorCompId
            }

            this.majorSpentData.push(data);
          })
        });

      },
        (error) => {
        });
  }
  getMajorCompSpentByLevel(compTypeId, i) {
    this.majorCompDS = [];
    this.dashboardService.getMajorCompSpentByLevel(this.parentId, this.levelId, compTypeId)
      .subscribe((res) => {
        this.dataSources[i] = res.body.map(item => ({
          category: item.majorCompName,
          value: item.totalSpent,
          id: item.majorCompId,
          compName: this.dataSources[i][0].compName + ":Major Component"
        }));
      },
        (error) => {
        });
  }
  getCompFundByLevel() {
    this.dataSources = [];
    this.componentTypeService.find(this.levelId).subscribe(
      (res: HttpResponse<any>) => {
        res.body.forEach(element => {
          this.compTypeId = element.id;
          this.dashboardService.getCompFundByLevel(this.levelId, this.parentId, this.compTypeId, this.finYear.id).subscribe((res) => {
            const data = [
              { category: 'Utilized Amount', value: res.body.utilizedAmount, compTypeId: res.body.compTypeId, compName: res.body.compName },
              { category: 'Balance Amount', value: res.body.balanceAmount, compTypeId: res.body.compTypeId, compName: res.body.compName }
            ];
            this.dataSources.push(data);
          },
            (error) => {
              this.spinner.hide();
            });
        });
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );


  }
  loadSign1() {
    this.voucherLegendName = "Vouchers Pending for S1"
    this.Sign = "signOneCount";
  }
  loadSign2() {
    this.removeBorder = true;
    this.voucherLegendName = "Vouchers Pending for S2"
    this.Sign = "signTwoCount";
  }
  loadSign3() {
    this.removeBorder = true;
    this.voucherLegendName = "Vouchers Pending for S3"
    this.Sign = "signThreeCount";
  }

  handleChange(e) {
    this.spinner.show();
    this.tabsDisabled = true; // Disable all tabs
    setTimeout(() => {
      this.tabsDisabled = false; // Enable all tabs after 2 seconds
    }, 2000);
    this.isAdminComp = false;
    if (e.index == 0) {
      this.compType = "DISTRICT";
      this.levelId = 2;
      this.parentId = 1;
      this.getCompFundByLevel();
      this.getFundByLevel();
      this.getPendingVocuher();
      this.getMajorSpent();
    } else if (e.index == 1) {
      this.compType = "BLOCK";
      this.levelId = 3;
      this.loadDistrict();
    } else {
      this.compType = "VILLAGE";
      this.levelId = 4;
      this.loadDistrict();
    }
  }
  loadDistrict($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 40,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            if (this.roleCode !== 'DAD') {
              this.districtListDto = res.body[0];
            }
            this.districtLevelList = res.body;
            this.onDistrictLevelChange();
          },
          () => { }
        );
    }
  }
  onPointClick(e, i) {
    this.getMajorCompSpentByLevel(+this.dataSources[i][0].compTypeId, i);
    this.isAdminComp = true;
  }



  onBackbtnClick() {
    this.isAdminComp = false;
    this.getCompFundByLevel();
  }


  onStateLevelChange($event?) {
    this.districtListDto = null;
    this.BlockLevelDto = null;
    this.villageLevelDto = null;
    this.grantlevel = null;
    this.componentTypeService.find(this.levelTypeDto.id).subscribe(
      (res: HttpResponse<ComponentType>) => {
        this.grantLevel = res.body;
      },
      () => { }
    );
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    }
  }


  onDistrictLevelChange($event?) {
    if (this.isStateRole) {
      if (this.levelId == 3) {
        this.parentId = this.districtListDto.id;
        this.getCompFundByLevel();
        this.getFundByLevel();
        this.getPendingVocuher();
        this.getMajorSpent();
      } else {
        this.BlockLevelDto = null;
        if ($event) {
          let filterQuery = {
            page: 0,
            size: 10,
          };
          this.levelMasterService
            .LevelMasterDtoFilter({ ...filterQuery }, [
              {
                key: 'parentId',
                operation: 'equals',
                value: this.districtListDto.id,
              },
              {
                key: 'name',
                operation: 'contains',
                value: $event.term.toUpperCase(),
              },
            ])
            .subscribe(
              (res: HttpResponse<LevelMasterDto[]>) => {
                this.BlockLevelDto = res.body[0];
                this.blockLevelList = res.body;
                this.onBlockLevelChange();
              },
              () => { }
            );
        } else {
          let filterQuery = {
            page: 0,
            size: 25,
          };
          this.levelMasterService
            .LevelMasterDtoFilter({ ...filterQuery }, [
              {
                key: 'parentId',
                operation: 'equals',
                value: this.districtListDto.id,
              },
            ])
            .subscribe(
              (res: HttpResponse<LevelMasterDto[]>) => {
                this.BlockLevelDto = res.body[0];
                this.blockLevelList = res.body;
                this.onBlockLevelChange();
              },
              () => { }
            );
        }
      }
    } else {
      this.levelMasterName = this.districtListDto.name;
      this.loadSanKey(this.districtListDto);
      this.levelMasterValue = this.districtListDto.id;
      this.getFundReport();
      this.getvoucherSummaryReport();
      this.loadPage(1);
      this.loadTimeline(this.levelMasterValue);
      this.getMonthWiseReport();
      this.onVendorChange()
      this.BlockLevelDto = null;
      this.villageLevelDto = null;
      if ($event) {
        let filterQuery = {
          page: 0,
          size: 10,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.districtListDto.id,
            },
            {
              key: 'name',
              operation: 'contains',
              value: $event.term.toUpperCase(),
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.blockLevelList = res.body;
            },
            () => { }
          );
      } else {
        let filterQuery = {
          page: 0,
          size: 10,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.districtListDto.id,
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.blockLevelList = res.body;
            },
            () => { }
          );
      }
    }

  }

  onBlockLevelChange($event?) {
    if (this.isStateRole) {
      this.parentId = this.BlockLevelDto.id;
      this.getCompFundByLevel();
      this.getFundByLevel();
      this.getPendingVocuher();
      this.getMajorSpent();
    } else {
      this.levelMasterName = this.BlockLevelDto.name;
      this.loadSanKey(this.BlockLevelDto);
      this.levelMasterValue = this.BlockLevelDto.id;
      this.getFundReport();
      this.getvoucherSummaryReport();
      this.loadPage(1);
      this.loadTimeline(this.levelMasterValue);
      this.getMonthWiseReport();
      this.villageLevelDto = null;
      if ($event) {
        let filterQuery = {
          page: 0,
          size: 10,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.BlockLevelDto.id,
            },
            {
              key: 'name',
              operation: 'contains',
              value: $event.term.toUpperCase(),
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.villageLevelList = res.body;
            },
            () => { }
          );
      } else {
        let filterQuery = {
          page: 0,
          size: 10,
        };
        this.levelMasterService
          .LevelMasterDtoFilter({ ...filterQuery }, [
            {
              key: 'parentId',
              operation: 'equals',
              value: this.BlockLevelDto.id,
            },
          ])
          .subscribe(
            (res: HttpResponse<LevelMasterDto[]>) => {
              this.villageLevelList = res.body;
            },
            () => { }
          );
      }
    }
  }
  onVillageLevelChange() {
    this.levelMasterName = this.villageLevelDto.name;
    this.loadSanKey(this.villageLevelDto);
    this.levelMasterValue = this.villageLevelDto.id;
    this.getFundReport();
    this.getvoucherSummaryReport();
    this.loadPage(1);
    this.loadTimeline(this.levelMasterValue);
    this.getMonthWiseReport();
  }
  /*no state*/
  getPanchayatFundReport() {
    this.dashboardService.getFunddetails(this.parentId, this.finYear.id)
      .subscribe((res: any) => {
        this.panchayatSanctionAmount = res.body.allocatedAmount ? res.body.allocatedAmount : 0
        this.panchayatUtilizedAmount = res.body.utilizedAmount ? res.body.utilizedAmount : 0
        this.panchayatBalanceAmount = res.body.balanceAmount ? res.body.balanceAmount : 0
        this.panchayatOSRAmount = res.body.osrAmount ? res.body.osrAmount : 0;
        this.panchayatSFCAmount = res.body.sfcAmount ? res.body.sfcAmount : 0;
        this.panchayatCraAmount = res.body.craAmount ? res.body.craAmount : 0;
        this.panchayatCrsaAmount = res.body.crsaAmount ? res.body.crsaAmount : 0
        this.panchayatCrmaAmount = res.body.crmaAmount ? res.body.crmaAmount :0;
        this.fundData = [
          { "name": "BalanceAmount", "amount": this.panchayatBalanceAmount },
          { "name": "UtilizedAmount", "amount": this.panchayatUtilizedAmount },
        ]
        this.SValue = this.formatCash(res.body.allocatedAmount);
        this.BValue = this.formatCash(res.body.balanceAmount);
        this.UValue = this.formatCash(res.body.utilizedAmount);
      },
        () => { });
  }
  getFundReport() {
    this.spinner.show();
    this.dashboardService
      .getFundReport(this.parentId, this.finYear.id)
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.fundDetails = [];
          res.body.forEach((element, i) => {
            switch (element.compName) {
              case 'Village General Funds':
                element.compName = 'Village General Funds - ' + 'A'
                element.compNameTn = 'கிராம பொது நிதி - ' + 'A'
                break;

              case 'Village Minimum Grant':
                element.compName = 'Village Minimum Grant - ' + 'B'
                element.compNameTn = 'கிராமத்தில் குறைந்தபட்ச மானியம் - ' + 'B'
                break;
              case 'Village Salary':
                element.compName = 'Village Salary - ' + 'C'
                element.compNameTn = 'கிராம சம்பளம் - ' + 'C'
                break;
              case 'Village Thooimai Kavalar':
                element.compName = 'Village Thooimai Kavalar - ' + 'D'
                element.compNameTn = 'கிராம தூய்மை காவலர் - ' + 'D'
                break;
              default:
                element.compName = null;
                break;
            }
            element.balancePercent = Math.floor((res.body[i].balanceAmount / res.body[i].grandSanctioned) * 100);
            element.allocatedAmount = this.currencyPipe.transform(res.body[i].allocatedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.utilizedAmount = this.currencyPipe.transform(res.body[i].utilizedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.balanceAmount = this.currencyPipe.transform(res.body[i].balanceAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.openingBalance = this.currencyPipe.transform(res.body[i].openingBalance, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.grandSanctioned = this.currencyPipe.transform(res.body[i].grandSanctioned, 'INR', 'symbol', '1.2-2', 'en-IN')
            if(element.compName) {
              this.fundDetails.push(element);
            }
          });
          this.fundDetails = this.sortItems('fund');
          console.log('loghgssssss', this.fundDetails);
        },
        (onError) => {
          this.spinner.hide();
        }
      );
  }

  getvoucherSummaryReport() {
    this.spinner.show();
    this.dashboardService
      .getVoucherSummary(this.parentId, this.finYear.id)
      .subscribe(
        (res: any) => {
          console.log('loggs', res.body)
          this.spinner.hide();
          this.voucherSummaryDetails = [];
          res.body.forEach((element, i) => {
            switch (element.compName) {
              case 'Village General Funds':
                element.compName = 'Village General Funds - ' + 'A'
                element.compNameTn = 'கிராம பொது நிதி - ' + 'A'
                break;

              case 'Village Minimum Grant':
                element.compName = 'Village Minimum Grant - ' + 'B'
                element.compNameTn = 'கிராமத்தில் குறைந்தபட்ச மானியம் - ' + 'B'
               
                break;
              case 'Village Salary':
                element.compName = 'Village Salary - ' + 'C'
                element.compNameTn = 'கிராம சம்பளம் - ' + 'C'
               
                break;
              case 'Village Thooimai Kavalar':
                element.compName = 'Village Thooimai Kavalar - ' + 'D'
                element.compNameTn = 'கிராம தூய்மை காவலர் - ' + 'D'
                break;

              default:
                element.compName = null;
                break;
            }
            element.balancePercent = Math.floor((res.body[i].balanceAmount / res.body[i].grandSanctioned) * 100);
            element.allocatedAmount = this.currencyPipe.transform(res.body[i].allocatedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.utilizedAmount = this.currencyPipe.transform(res.body[i].utilizedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.balanceAmount = this.currencyPipe.transform(res.body[i].balanceAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.openingBalance = this.currencyPipe.transform(res.body[i].openingBalance, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.grandSanctioned = this.currencyPipe.transform(res.body[i].grandSanctioned, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.approvedVouchersValue = this.currencyPipe.transform(res.body[i].approvedVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.pendingVouchersValue = this.currencyPipe.transform(res.body[i].pendingVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.rejectedVouchersValue = this.currencyPipe.transform(res.body[i].rejectedVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.totalVouchersValue = this.currencyPipe.transform(res.body[i].totalVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            if(element.compName) {
              this.voucherSummaryDetails.push(element);
            }


          });
          this.voucherSummaryDetails = this.sortItems('voucher');
        },
        (onError) => {
          this.spinner.hide();
        }
      );
  }

  sortItems(type): any[] {
    // Custom order for specific items
    const customOrder = {
      "Village General Funds - A": 1,
      "Village Minimum Grant - B": 2,
      "Village Salary - C": 3,
      "Village Thooimai Kavalar - D": 4,
    };

    // Sorting function based on custom order
    if (type === 'fund') {
      return this.fundDetails.sort((a, b) => {
        const orderA = customOrder[a.compName];
        const orderB = customOrder[b.compName];

        // If both items have custom orders, compare them
        if (orderA !== undefined && orderB !== undefined) {
          return orderA - orderB;
        }
        // If only one item has a custom order, prioritize it
        else if (orderA !== undefined) {
          return -1;
        } else if (orderB !== undefined) {
          return 1;
        }
        // If neither item has a custom order, sort alphabetically
        else {
          return a.compName.localeCompare(b.compName);
        }
      });
    }
    else {
      return this.voucherSummaryDetails.sort((a, b) => {
        const orderA = customOrder[a.compName];
        const orderB = customOrder[b.compName];

        // If both items have custom orders, compare them
        if (orderA !== undefined && orderB !== undefined) {
          return orderA - orderB;
        }
        // If only one item has a custom order, prioritize it
        else if (orderA !== undefined) {
          return -1;
        } else if (orderB !== undefined) {
          return 1;
        }
        // If neither item has a custom order, sort alphabetically
        else {
          return a.compName.localeCompare(b.compName);
        }
      });
    }

  }
  getMonthWiseReport() {
    let tot = 0;
    this.dashboardService
      .getMonthWiseReport([
        {
          key: 'levelMasterId',
          operation: 'equals',
          value: this.parentId,
        },
        {
          key: 'finyear.id',
          operation: 'equals',
          value: this.finYear.id,
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ])
      .subscribe(
        (res: any) => {
          this.monthWiseData = res.body;
          res.body.forEach((element, i) => {
            this.monthWiseData[i].amount = +element.amount.replace(/\,/g, '');
            tot = tot + this.monthWiseData[i].amount;
          });
          if (tot == 0) {
            this.noMonthSpentData = true;
          }
        },
        () => { }
      );
  }
  loadSanKey(levelType: any) {
    let total = 0;
    this.noSpentData = false;
    this.dashboardService.getSpendByComponentTYpe(levelType.id, this.finYear.id).subscribe(
      (res) => {
        this.sankeyData = res.body;
        res.body.forEach((element, i) => {
          this.sankeyData[i].levelTypeName = levelType.name;
          this.sankeyData[i].name = element.name;
          this.sankeyData[i].value = element.value;
          total = total + element.value;
        });
        if (total == 0) {
          this.noSpentData = true;
        }
      },
      () => {
        this.onError();
      }
    );
    this.spinner.hide();
  }
  errorHandler(e) {
  }
  loadGrantLevel() {
    this.componentTypeService.find(this.levelTypeDto.id).subscribe(
      (res: HttpResponse<ComponentType>) => {
        this.grantlevel = res.body[0];
        this.grantLevel = res.body;
        this.ongrandLevel();
      },
      () => {
        this.onError();
      }
    );
  }
  ongrandLevel() {
    if (this.levelTypeDto.name === 'State') {
      this.levelMasterValue = this.districtListDto?.id;
    } else if (this.levelTypeDto.name === 'District') {
      this.levelMasterValue = this.districtListDto?.id;
    } else if (this.levelTypeDto.name === 'Block') {
      this.levelMasterValue = this.BlockLevelDto?.id;
    } else if (this.levelTypeDto.name === 'Village') {
      this.levelMasterValue = this.villageLevelDto?.id;
    }
    this.loadPage(1);
    this.getFundReport();
    this.getvoucherSummaryReport();
    this.getMonthWiseReport();
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    this.levelMasterFilter = false;
    this.personalFlagFilter = false;
    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterProps = this.filterProps ? this.filterProps : [];
    console.log('fliterData', this.filterProps.some(year => year.key === 'finyear.id'));
    if (!this.filterProps.some(year => year.key === 'finyear.id')) {
      this.filterProps.push({
        key: 'finyear.id',
        operation: 'equals',
        value: this.finYear.id,
      })
    }
    if (this.filterProps) {
      this.filterProps.forEach((element, i) => {
        if (element.key == 'levelMasterId') {
          element.value = this.levelMasterValue;
          this.levelMasterFilter = true;
        }
        if (element.key == 'personalFlag') {
          element.value = this.value.value;
          this.personalFlagFilter = true;
        }
        if (element.key == 'finyear.id') {
          element.value = this.finYear.id;
        }
      });
    }
    const hasActiveFlag = this.filterProps.some(element => element.key === 'activeFlag');
    if (!hasActiveFlag) {
      let query = new filterQuery();
      query.key = 'activeFlag';
      query.operation = 'equals';
      query.value = 'YES';
      this.filterProps.push(query);
    }
    if (!this.levelMasterFilter) {
      let query = new filterQuery();
      query.key = 'levelMasterId';
      query.operation = 'equals';
      query.value = this.levelMasterValue;
      this.filterProps = this.filterProps ? this.filterProps : [];
      this.filterProps.push(query);
    }
    if (!this.personalFlagFilter) {
      let query = new filterQuery();
      query.key = 'personalFlag';
      query.operation = 'equals';
      query.value = this.value.value;
      this.filterProps = this.filterProps ? this.filterProps : [];
      this.filterProps.push(query);
    }

    if (this.value.value == "ALL") {
      const indexToRemove = this.filterProps.findIndex(item => item.key === 'personalFlag');
      if (indexToRemove !== -1) {
        this.filterProps.splice(indexToRemove, 1);
      }
    }
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.paymentService
      .reportfilter(
        { ...Query },
        this.filterProps,
        this.startDate,
        this.endDate
      )
      .subscribe(
        (res: HttpResponse<VendorTransaction[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }
  protected onSuccess(
    data: VendorTransaction[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult.items.forEach((element, i) => {
      if (element.transactionStatus == null) {
        element.transactionStatus = 'Not Initiated';
      }
      else if (element.transactionStatus == 'O.K.') {
        element.transactionStatus = 'Success';
      }
      else if (element.transactionStatus == 'failed') {
        element.transactionStatus = 'Failed';
      }
      else if (element.transactionStatus == 'processing') {
        element.transactionStatus = 'Processing';
      }
    });
    this.searchResult = { ...this.searchResult };
  }
  fileName = 'VendorTransactionData.xlsx';
  download() {
    let param
    if (this.selectedMonth && this.selectedQuickView.code === 'mon') {
      param = {
        levelMasterId: this.parentId,
        finYearId: this.finYear.id ?? '',
        month: this.selectedMonth.number
      }
      this.dashboardService
        .downloadMonthWiseData(param)
        .subscribe(
          (res) => {
            this.saveBlobToExcel(res.body, 'Vendor Transcation Report');
          }, (err) => {

          })
    } else {
      let param
      if (this.isSelectiveDate) {
        let startDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
        let endDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
        this.spinner.show();
        param = {
          levelMasterId: this.parentId,
          finYearId: this.finYear.id ?? '',
          fromDate: startDate ?? '',
          toDate: endDate ?? ''
        }
      } else {
        param = {
          levelMasterId: this.parentId,
          finYearId: this.finYear.id ?? '',
          fromDate: this.startDate ?? '',
          toDate: this.endDate ?? ''
        }
      }
      this.dashboardService.downloadDocuments(param).subscribe(
        (response) => {
          console.log(response);
          this.spinner.hide();
          this.saveBlobToExcel(response.body, 'Vendor Transcation Report');
        },
        () => {
          this.spinner.hide();
        }
      )
    }


  }

  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }
  exportToExcel(): void {
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }
  exportToPdf(): void {
    let columns = [
      { title: 'Vendor Name' },
      { title: 'Voucher No ' },
      { title: 'Transaction Date' },
      { title: 'Major Component Name' },
      { title: 'Minor Component Name' },
      { title: 'Sub Component Name' },
      { title: 'Transaction Amount' },
      { title: 'Transaction Status' },
    ];
    let data = [];
    for (var i = 0; i < this.searchResult.items.length; i++) {
      data.push([
        this.searchResult.items[i].personalVendorName
          ? this.searchResult.items[i].personalVendorName
          : this.searchResult.items[i].commercialVendorName,
        this.searchResult.items[i].voucherNo,
        this.searchResult.items[i].transDate.substring(0, 10),
        this.searchResult.items[i].majorComponentName,
        this.searchResult.items[i].minorComponentName,
        this.searchResult.items[i].subComponentName,
        this.searchResult.items[i].transactionAmount,
        this.searchResult.items[i].transactionStatus,
      ]);
    }
    const doc = new jsPDF('l', 'pt', 'a4');
    __drawTable(doc, { columns: columns, body: data });
    doc.save('VendorTransactionData.pdf');
  }
  onDateChange() {

    this.startDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
    if (this.startDate > this.endDate) {
      this.dateError = true;
    }
    if (this.startDate && this.endDate && this.startDate < this.endDate) {
      this.dateError = false;
      this.loadPage(1);
    }
  }
  onQuickViewChange() {
    if (this.selectedQuickView.code === 'mon') {
      this.selectedMonth = null;
    }
    this.isSelectiveDate = false;
    let FinYear: string;
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    if (this.selectedQuickView.code == 'CM') {
      this.startDate =
        new Date().getFullYear().toString() +
        '-' +
        (new Date().getMonth() + 1).toString() +
        '-01';
      this.endDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    } else if (this.selectedQuickView.code == 'Q1') {
      this.startDate = FinYear.slice(0, 4) + '-04-01';
      this.endDate = FinYear.slice(0, 4) + '-06-30';
    } else if (this.selectedQuickView.code == 'Q2') {
      this.startDate = FinYear.slice(0, 4) + '-07-01';
      this.endDate = FinYear.slice(0, 4) + '-09-30';
    } else if (this.selectedQuickView.code == 'Q3') {
      this.startDate = FinYear.slice(0, 4) + '-10-01';
      this.endDate = FinYear.slice(0, 4) + '-12-31';
    } else if (this.selectedQuickView.code == 'Q4') {
      this.startDate = FinYear.slice(5, 9) + '-01-01';
      this.endDate = FinYear.slice(5, 9) + '-03-31';
    } else if (this.selectedQuickView.code == 'H1') {
      this.startDate = FinYear.slice(0, 4) + '-04-01';
      this.endDate = FinYear.slice(0, 4) + '-09-30';
    } else if (this.selectedQuickView.code == 'H2') {
      this.startDate = FinYear.slice(0, 4) + '-10-01';
      this.endDate = FinYear.slice(5, 9) + '-03-31';
    } else if (this.selectedQuickView.code == 'S') {
      this.isSelectiveDate = true;
    }
    this.loadPage(1);
  }

  onVendorChange() {
    //this.table.reset();
    this.voucherMaker();

    this.filterProps = [];
    this.filterProps.push({
      key: 'finyear.id',
      operation: 'equals',
      value: this.finYear.id,
    })

    console.log('this.filter', this.filterProps);

    if (this.value.value === 'YES') {
      this.cols = [
        {
          field: 'personalVendorName',
          header: 'Vendor Name',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.vendor.name',
        },
        {
          field: 'voucherNo',
          header: 'Voucher No',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.no',
        },
        {
          field: 'permanentVoucherNumber',
          header: 'Permanent Voucher Number',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
        },
        {
          field: 'transDate',
          header: 'Transaction Date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
          jhiTranslate: 'tableHead.transaction.date',
        },
        {
          field: 'majorComponentName',
          header: 'Major Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.major.compName',
        },
        {
          field: 'minorComponentName',
          header: 'Minor Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.minor.compName',
        },
        {
          field: 'subComponentName',
          header: 'Sub Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.sub.compName',
        },
        {
          field: 'transactionAmount',
          header: 'Total Transction Amount',
          jhiTranslate: 'tableHead.transaction.amount',
          isSelectcolumn: true,
          isFilterable: false,
          type: 'amount'
        },
        {
          field: 'transactionStatus',
          header: 'Payment Status',
          jhiTranslate: 'tableHead.payment.status',
          isSelectcolumn: true,
          isSortable: true,
          isFilterable: false,
          type:'color'
        },
      ];
    } else if (this.value.value === 'NO') {
      this.cols = [
        {
          field: 'commercialVendorName',
          header: 'Vendor Name',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.vendor.name',
        },
        {
          field: 'voucherNo',
          header: 'Voucher No',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.no',
        },
        {
          field: 'permanentVoucherNumber',
          header: 'Permanent Voucher Number',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
        },
        {
          field: 'transDate',
          header: 'Transaction Date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
          jhiTranslate: 'tableHead.transaction.date',
        },
        {
          field: 'majorComponentName',
          header: 'Major Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.major.compName',
        },
        {
          field: 'minorComponentName',
          header: 'Minor Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.minor.compName',
        },
        {
          field: 'subComponentName',
          header: 'Sub Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.sub.compName',
        },
        {
          field: 'transactionAmount',
          header: 'Total Transction Amount',
          jhiTranslate: 'tableHead.transaction.amount',
          isSelectcolumn: true,
          isFilterable: false,
          type: 'amount'
        },
        {
          field: 'transactionStatus',
          header: 'Payment Status',
          jhiTranslate: 'tableHead.payment.status',
          isSelectcolumn: true,
          isSortable: true,
          isFilterable: false,
          type:'color'
        },
      ];
    } else {
      this.cols = [
        {
          field: 'voucherNo',
          header: 'Voucher No',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.no',
        },
        {
          field: 'permanentVoucherNumber',
          header: 'Permanent Voucher Number',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
        },
        {
          field: 'transDate',
          header: 'Transaction Date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
          jhiTranslate: 'tableHead.transaction.date',
        },
        {
          field: 'majorComponentName',
          header: 'Major Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.major.compName',
        },
        {
          field: 'minorComponentName',
          header: 'Minor Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.minor.compName',
        },
        {
          field: 'subComponentName',
          header: 'Sub Component Name',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.sub.compName',
        },
        {
          field: 'transactionAmount',
          header: 'Total Transction Amount',
          jhiTranslate: 'tableHead.transaction.amount',
          isSelectcolumn: true,
          isFilterable: false,
          type: 'amount'
        },
        {
          field: 'transactionStatus',
          header: 'Payment Status',
          jhiTranslate: 'tableHead.payment.status',
          isSelectcolumn: true,
          isSortable: true,
          isFilterable: false,
          type:'color'
        },
      ];
    }
    this.loadPage(1);
    this.loadTimeline(this.levelMasterValue);
    if (this.selectedMonth && this.selectedQuickView.code === 'mon') {
      this.onMonthChange();
    }
  }

  onMonthChange() {


    this.dashboardService
      .getMonthWiseData(this.filterProps, this.selectedMonth.number)
      .subscribe(
        (res) => {
          this.searchResult.total = Number(res.headers.get('X-Total-Count'));
          this.searchResult.items = res.body ?? [];
          this.searchResult.items.forEach((element, i) => {
            if (element.transactionStatus == null) {
              element.transactionStatus = 'Not Initiated';
            }
            else if (element.transactionStatus == 'O.K.') {
              element.transactionStatus = 'Success';
            }
            else if (element.transactionStatus == 'failed') {
              element.transactionStatus = 'Failed';
            }
            else if (element.transactionStatus == 'processing') {
              element.transactionStatus = 'Processing';
            }
          });
          this.searchResult = { ...this.searchResult };
        },
        () => {
          this.onError();
        }
      );
  }
  protected onError(): void {
    this.spinner.hide();
  }
  loadTimeline(levelMasterVal: any) {
    this.isLazyLoading = false;
    this.paymentTimeline(levelMasterVal);
    // this.loadActive(levelMasterVal, 1);
    // this.loadProcessing(levelMasterVal, 1);
    // this.loadSuccess(levelMasterVal, 1);
    // this.loadFailure(levelMasterVal, 1);
  }
  paymentTimeline(levelMasterVal: any) {
    let payload;
    if (this.value.value == "ALL") {
      payload = {
        levelMasterId: levelMasterVal
      }
    }

    else {
      let flag = this.value.value == "YES" ? 'Yes' : this.value.value == "NO" ? 'No' : this.value.value;
      payload = {
        levelMasterId: levelMasterVal,
        personalFlag: flag
      }
    }
    payload['finyearId'] = this.finYear.id;
    this.dashboardService.paymentTimeline(payload).subscribe(
      (res) => {
        this.events = [
          { status: 'dashBoard.all', count: res.body.total, color: '#3B82F6' },
          {
            status: 'dashBoard.processing',
            count: res.body.notInitiated,
            color: '#F59E0B',
          },
          {
            status: 'dashBoard.success',
            count: res.body.success,
            color: '#22C55E',
          },
          {
            status: 'dashBoard.failure',
            count: res.body.failed,
            color: '#EF4444',
          },
          {
            status: 'dashBoard.partiallyDone',
            count: res.body.partiallyDone,
            color: '#183883',
          }
        ];
      },
      () => {
        this.onError();
      }
    );;
  }
  loadActive(
    levelMasterVal?: any,
    page?: number,
    dontNavigate?: boolean
  ): void {
    this.levelMasterFilter = false;
    this.personalFlagFilter = false;
    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
    let query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterPropsOne.push(query);
    var i;
    if (this.filterPropsOne) {
      for (i = this.filterPropsOne.length - 1; i >= 0; i -= 1) {
        if (this.filterPropsOne[i].key === 'transactionStatus') {
          this.filterPropsOne.splice(i, 1);
        }
      }
      this.filterPropsOne?.forEach((element, i) => {
        if (element.key == 'levelMasterId') {
          element.value = levelMasterVal;
          this.levelMasterFilter = true;
        }
        if (element.key == 'personalFlag') {
          element.value = this.value.value;
          this.personalFlagFilter = true;
        }
        if (element.key == 'finyear.id') {
          element.value = this.finYear.id;
        }
      });
    }
    if (!this.levelMasterFilter) {
      let query = new filterQuery();
      query.key = 'levelMasterId';
      query.operation = 'equals';
      query.value = levelMasterVal;
      this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
      this.filterPropsOne.push(query);
    }
    if (!this.personalFlagFilter) {
      let query = new filterQuery();
      query.key = 'personalFlag';
      query.operation = 'equals';
      query.value = this.value.value;
      this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
      this.filterPropsOne.push(query);
    }
    if (!this.filterPropsOne.some(year => year.key === 'finyear.id')) {
      this.filterPropsOne.push({
        key: 'finyear.id',
        operation: 'equals',
        value: this.finYear.id,
      })
    }
    if (this.value.value == "ALL") {
      const indexToRemove = this.filterPropsOne.findIndex(item => item.key === 'personalFlag');
      if (indexToRemove !== -1) {
        this.filterPropsOne.splice(indexToRemove, 1);
      }
    }
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.paymentService
      .reportfilter({ ...Query }, this.filterPropsOne)
      .subscribe(
        (res: HttpResponse<VendorTransaction[]>) => {
          this.allTotal = Number(res.headers.get('X-Total-Count'));
          this.searchAllResult.total = Number(
            res.headers.get('X-Total-Count')
          );
          this.page = 1;
          this.searchAllResult.items = res.body ?? [];
          this.searchAllResult.items.forEach((element, i) => {
            if (element.transactionStatus == null) {
              element.transactionStatus = 'Not Initiated';
            }
            else if (element.transactionStatus == 'O.K.') {
              element.transactionStatus = 'Success';
            }
            else if (element.transactionStatus == 'failed') {
              element.transactionStatus = 'Failed';
            }
            else if (element.transactionStatus == 'processing') {
              element.transactionStatus = 'Processing';
            }
          });
          this.searchAllResult = { ...this.searchAllResult };
          // if (!this.isLazyLoading) this.loadTotal();
        },
        () => {
          this.onError();
        }
      );
  }
  loadProcessing(
    levelMasterVal?: any,
    page?: number,
    dontNavigate?: boolean
  ): void {
    this.levelMasterFilter = false;
    this.personalFlagFilter = false;
    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
    let query1 = new filterQuery();
    query1.key = 'activeFlag';
    query1.operation = 'equals';
    query1.value = 'YES';
    this.filterPropsOne.push(query1);
    var i;
    if (this.filterPropsOne)
      for (i = this.filterPropsOne.length - 1; i >= 0; i -= 1) {
        if (this.filterPropsOne[i].key === 'transactionStatus') {
          this.filterPropsOne.splice(i, 1);
        }
      }
    this.filterPropsOne?.forEach((element, i) => {
      if (element.key == 'levelMasterId') {
        element.value = levelMasterVal;
        this.levelMasterFilter = true;
      }
      if (element.key == 'personalFlag') {
        element.value = this.value.value;
        this.personalFlagFilter = true;
      }
    });
    if (!this.levelMasterFilter) {
      let query = new filterQuery();
      query.key = 'levelMasterId';
      query.operation = 'equals';
      query.value = levelMasterVal;
      this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
      this.filterPropsOne.push(query);
    }
    if (!this.personalFlagFilter) {
      let query = new filterQuery();
      query.key = 'personalFlag';
      query.operation = 'equals';
      query.value = this.value.value;
      this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
      this.filterPropsOne.push(query);
    }
    if (!this.filterPropsOne.some(year => year.key === 'finyear.id')) {
      this.filterPropsOne.push({
        key: 'finyear.id',
        operation: 'equals',
        value: this.finYear.id,
      })
    }

    let query = new filterQuery();
    query.key = 'transactionStatus';
    // query.operation = 'equals';
    // query.value = 'PROCESSING';
    query.operation = 'null';
    query.value = null;
    this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
    this.filterPropsOne.push(query);
    if (this.value.value == "ALL") {
      const indexToRemove = this.filterPropsOne.findIndex(item => item.key === 'personalFlag');
      if (indexToRemove !== -1) {
        this.filterPropsOne.splice(indexToRemove, 1);
      }
    }
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.paymentService
      .reportfilter({ ...Query }, this.filterPropsOne)
      .subscribe(
        (res: HttpResponse<VendorTransaction[]>) => {
          this.processingTotal = Number(res.headers.get('X-Total-Count'));
          this.searchProcessingResult.total = Number(
            res.headers.get('X-Total-Count')
          );
          this.page = 1;
          this.searchProcessingResult.items = res.body ?? [];
          this.searchProcessingResult.items.forEach((element, i) => {
            if (element.transactionStatus == null) {
              element.transactionStatus = 'Not Initiated';
            }
            else if (element.transactionStatus == 'O.K.') {
              element.transactionStatus = 'Success';
            }
            else if (element.transactionStatus == 'failed') {
              element.transactionStatus = 'Failed';
            }
            else if (element.transactionStatus == 'processing') {
              element.transactionStatus = 'Processing';
            }
          });
          this.searchProcessingResult = { ...this.searchProcessingResult };
          // if (!this.isLazyLoading) this.loadTotal();
        },
        () => {
          this.onError();
        }
      );
  }
  loadSuccess(
    levelMasterVal?: any,
    page?: number,
    dontNavigate?: boolean
  ): void {
    this.levelMasterFilter = false;
    this.personalFlagFilter = false;
    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
    let query1 = new filterQuery();
    query1.key = 'activeFlag';
    query1.operation = 'equals';
    query1.value = 'YES';
    this.filterPropsOne.push(query1);
    var i;
    if (this.filterPropsOne)
      for (i = this.filterPropsOne.length - 1; i >= 0; i -= 1) {
        if (this.filterPropsOne[i].key === 'transactionStatus') {
          this.filterPropsOne.splice(i, 1);
        }
      }
    this.filterPropsOne?.forEach((element, i) => {
      if (element.key == 'levelMasterId') {
        element.value = levelMasterVal;
        this.levelMasterFilter = true;
      }
      if (element.key == 'personalFlag') {
        element.value = this.value.value;
        this.personalFlagFilter = true;
      }
    });
    if (!this.levelMasterFilter) {
      let query = new filterQuery();
      query.key = 'levelMasterId';
      query.operation = 'equals';
      query.value = levelMasterVal;
      this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
      this.filterPropsOne.push(query);
    }
    if (!this.personalFlagFilter) {
      let query = new filterQuery();
      query.key = 'personalFlag';
      query.operation = 'equals';
      query.value = this.value.value;
      this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
      this.filterPropsOne.push(query);
    }
    if (!this.filterPropsOne.some(year => year.key === 'finyear.id')) {
      this.filterPropsOne.push({
        key: 'finyear.id',
        operation: 'equals',
        value: this.finYear.id,
      })
    }
    let query = new filterQuery();
    query.key = 'transactionStatus';
    query.operation = 'equals';
    query.value = 'O.K.';
    this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
    this.filterPropsOne.push(query);
    if (this.value.value == "ALL") {
      const indexToRemove = this.filterPropsOne.findIndex(item => item.key === 'personalFlag');
      if (indexToRemove !== -1) {
        this.filterPropsOne.splice(indexToRemove, 1);
      }
    }
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.paymentService
      .reportfilter({ ...Query }, this.filterPropsOne)
      .subscribe(
        (res: HttpResponse<VendorTransaction[]>) => {
          this.successTotal = Number(res.headers.get('X-Total-Count'));
          this.searchSuccessResult.total = Number(
            res.headers.get('X-Total-Count')
          );
          this.page = 1;
          this.searchSuccessResult.items = res.body ?? [];
          this.searchSuccessResult.items.forEach((element, i) => {
            if (element.transactionStatus == null) {
              element.transactionStatus = 'Not Initiated';
            }
            else if (element.transactionStatus == 'O.K.') {
              element.transactionStatus = 'Success';
            }
            else if (element.transactionStatus == 'failed') {
              element.transactionStatus = 'Failed';
            }
            else if (element.transactionStatus == 'processing') {
              element.transactionStatus = 'Processing';
            }
          });
          this.searchSuccessResult = { ...this.searchSuccessResult };
          // if (!this.isLazyLoading) this.loadTotal();
        },
        () => {
          this.onError();
        }
      );
  }
  loadFailure(
    levelMasterVal?: any,
    page?: number,
    dontNavigate?: boolean
  ): void {
    this.levelMasterFilter = false;
    this.personalFlagFilter = false;
    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
    let query1 = new filterQuery();
    query1.key = 'activeFlag';
    query1.operation = 'equals';
    query1.value = 'YES';
    this.filterPropsOne.push(query1);
    var i;
    if (this.filterPropsOne)
      for (i = this.filterPropsOne.length - 1; i >= 0; i -= 1) {
        if (this.filterPropsOne[i].key === 'transactionStatus') {
          this.filterPropsOne.splice(i, 1);
        }
      }
    this.filterPropsOne?.forEach((element, i) => {
      if (element.key == 'levelMasterId') {
        element.value = levelMasterVal;
        this.levelMasterFilter = true;
      }
      if (element.key == 'personalFlag') {
        element.value = this.value.value;
        this.personalFlagFilter = true;
      }
    });
    if (!this.levelMasterFilter) {
      let query = new filterQuery();
      query.key = 'levelMasterId';
      query.operation = 'equals';
      query.value = levelMasterVal;
      this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
      this.filterPropsOne.push(query);
    }
    if (!this.personalFlagFilter) {
      let query = new filterQuery();
      query.key = 'personalFlag';
      query.operation = 'equals';
      query.value = this.value.value;
      this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
      this.filterPropsOne.push(query);
    }
    let query = new filterQuery();
    query.key = 'transactionStatus';
    query.operation = 'contains';
    query.value = 'FAILED';
    this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
    this.filterPropsOne.push(query);
    if (this.value.value == "ALL") {
      const indexToRemove = this.filterPropsOne.findIndex(item => item.key === 'personalFlag');
      if (indexToRemove !== -1) {
        this.filterPropsOne.splice(indexToRemove, 1);
      }
    }
    if (!this.filterPropsOne.some(year => year.key === 'finyear.id')) {
      this.filterPropsOne.push({
        key: 'finyear.id',
        operation: 'equals',
        value: this.finYear.id,
      })
    }
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.paymentService
      .reportfilter({ ...Query }, this.filterPropsOne)
      .subscribe(
        (res: HttpResponse<VendorTransaction[]>) => {
          this.failureTotal = Number(res.headers.get('X-Total-Count'));
          this.searchFailureResult.total = Number(
            res.headers.get('X-Total-Count')
          );
          this.page = 1;
          this.searchFailureResult.items = res.body ?? [];
          this.searchFailureResult.items.forEach((element, i) => {
            if (element.transactionStatus == null) {
              element.transactionStatus = 'Not Initiated';
            }
            else if (element.transactionStatus == 'O.K.') {
              element.transactionStatus = 'Success';
            }
            else if (element.transactionStatus == 'failed') {
              element.transactionStatus = 'Failed';
            }
            else if (element.transactionStatus == 'processing') {
              element.transactionStatus = 'Processing';
            }
          });
          this.searchFailureResult = { ...this.searchFailureResult };
          // if (!this.isLazyLoading) this.loadTotal();
        },
        () => {
          this.onError();
        }
      );
  }
  loadPartial(
    levelMasterVal?: any,
    page?: number,
    dontNavigate?: boolean
  ): void {
    this.levelMasterFilter = false;
    this.personalFlagFilter = false;
    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
    let query1 = new filterQuery();
    query1.key = 'activeFlag';
    query1.operation = 'equals';
    query1.value = 'YES';
    this.filterPropsOne.push(query1);
    var i;
    if (this.filterPropsOne)
      for (i = this.filterPropsOne.length - 1; i >= 0; i -= 1) {
        if (this.filterPropsOne[i].key === 'transactionStatus') {
          this.filterPropsOne.splice(i, 1);
        }
      }
    this.filterPropsOne?.forEach((element, i) => {
      if (element.key == 'levelMasterId') {
        element.value = levelMasterVal;
        this.levelMasterFilter = true;
      }
      if (element.key == 'personalFlag') {
        element.value = this.value.value;
        this.personalFlagFilter = true;
      }
    });
    if (!this.levelMasterFilter) {
      let query = new filterQuery();
      query.key = 'levelMasterId';
      query.operation = 'equals';
      query.value = levelMasterVal;
      this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
      this.filterPropsOne.push(query);
    }
    if (!this.personalFlagFilter) {
      let query = new filterQuery();
      query.key = 'personalFlag';
      query.operation = 'equals';
      query.value = this.value.value;
      this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
      this.filterPropsOne.push(query);
    }
    if (!this.filterPropsOne.some(year => year.key === 'finyear.id')) {
      this.filterPropsOne.push({
        key: 'finyear.id',
        operation: 'equals',
        value: this.finYear.id,
      })
    }
    let query = new filterQuery();
    query.key = 'transactionStatus';
    query.operation = 'contains';
    query.value = 'PARTIALLY DONE';
    this.filterPropsOne = this.filterPropsOne ? this.filterPropsOne : [];
    this.filterPropsOne.push(query);
    if (this.value.value == "ALL") {
      const indexToRemove = this.filterPropsOne.findIndex(item => item.key === 'personalFlag');
      if (indexToRemove !== -1) {
        this.filterPropsOne.splice(indexToRemove, 1);
      }
    }
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.paymentService
      .reportfilter({ ...Query }, this.filterPropsOne)
      .subscribe(
        (res: HttpResponse<VendorTransaction[]>) => {
          this.failureTotal = Number(res.headers.get('X-Total-Count'));
          this.searchPartialResult.total = Number(
            res.headers.get('X-Total-Count')
          );
          this.page = 1;
          this.searchPartialResult.items = res.body ?? [];
          this.searchPartialResult.items.forEach((element, i) => {
            if (element.transactionStatus == null) {
              element.transactionStatus = 'Not Initiated';
            }
            else if (element.transactionStatus == 'O.K.') {
              element.transactionStatus = 'Success';
            }
            else if (element.transactionStatus == 'failed') {
              element.transactionStatus = 'Failed';
            }
            else if (element.transactionStatus == 'processing') {
              element.transactionStatus = 'Processing';
            }
            else if (element.transactionStatus == 'Partially Done') {
              element.transactionStatus = 'Partially Done';
            }
          });
          this.searchPartialResult = { ...this.searchPartialResult };
          // if (!this.isLazyLoading) this.loadTotal();
        },
        () => {
          this.onError();
        }
      );
  }

  loadDialog(status: string) {
    this.timelineStatus = status;
    this.isLazyLoading = false;
    if (status == 'dashBoard.all') {
      this.loadActive(this.levelMasterValue, 1);
      this.enableAll = true;
    }
    if (status == 'dashBoard.processing') {
      this.loadProcessing(this.levelMasterValue, 1);
      this.enableProcessing = true;
    }
    if (status == 'dashBoard.success') {
      this.loadSuccess(this.levelMasterValue, 1);
      this.enableSuccess = true;
    }
    if (status == 'dashBoard.failure') {
      this.loadFailure(this.levelMasterValue, 1);
      this.enableFailure = true;
    }
    if (status == 'dashBoard.partiallyDone') {
      this.loadPartial(this.levelMasterValue, 1);
      this.enablePartial = true;
    }
    this.timelineDialog = true;
  }
  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }
  onVoucherDetailsLazyLoad(event) {
    // this.filterVoucherProps = this.filterVoucherProps?this.filterVoucherProps:[];
    // if(event.filterDefinitions.length>0)
    // {
    //   event.filterDefinitions.forEach((element,i) => {
    //     this.filterVoucherProps .push(event.filterDefinitions[i]);
    //   });

    // }
    this.filterVoucherProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadVoucher(event.page,);
  }
  onSubTableLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadSubTable(event.page);
  }
  onLazyLoading(event) {
    this.isLazyLoading = true;
    this.filterPropsOne = event.filterDefinitions;
    this.itemsPerPage = event.size;
    if (this.timelineStatus == 'dashBoard.all') {
      this.loadActive(this.levelMasterValue, event.page);
    }
    if (this.timelineStatus == 'dashBoard.processing') {
      this.loadProcessing(this.levelMasterValue, event.page);
    }
    if (this.timelineStatus == 'dashBoard.success') {
      this.loadSuccess(this.levelMasterValue, event.page);
    }
    if (this.timelineStatus == 'dashBoard.failure') {
      this.loadFailure(this.levelMasterValue, event.page);
    }
    if (this.timelineStatus == 'dashBoard.partiallyDone') {
      this.loadFailure(this.levelMasterValue, event.page);
    }
  }
  hideDialog() {
    this.filterPropsOne = [];
    this.enableAll = false;
    this.enableFailure = false;
    this.enableProcessing = false;
    this.enableSuccess = false;
    this.enablePartial = false;
    //this.dt.reset();
    //this.table.reset();
  }
  hideVoucherDialog() {
    this.filterVoucherProps = [];
    this.enableVoucher = false;
  }

  getVoucherDetails(count: number, navigate, below5l?: boolean, above5l?: boolean, sign?: string) {
    this.filterVoucherProps = [];
    if (navigate) {
      this.redriect = true;
      this.subTable = false;
    } else {
      this.redriect = false;
      this.subTable = true;
    }
    this.count = count;
    this.below5l = below5l;
    this.above5l = above5l;
    this.sign = sign;

    this.enableVoucher = true;
    this.loadVoucher(1);
  }
  loadVoucher(page?: number) {
    this.filterVoucherProps = this.filterVoucherProps ? this.filterVoucherProps : [];
    if (!this.filterVoucherProps.some(year => year.key === 'finyear.id')) {
      this.filterVoucherProps.push({
        key: 'finyear.id',
        operation: 'equals',
        value: this.finYear.id,
      })
    }
    let query = new filterQuery();
    query.key = 'levelMasterId';
    query.operation = 'equals';
    query.value = this.levelMasterValue;
    this.filterVoucherProps.push(query);
    query = new filterQuery();
    query.key = 'personalFlag';
    query.operation = 'equals';
    query.value = this.value.value;
    this.filterVoucherProps.push(query);
    query = new filterQuery();
    query.key = 'count';
    query.operation = 'equals';
    query.value = this.count;
    this.filterVoucherProps.push(query);
    if (this.roleName?.startsWith('Zonal')) {
      query = new filterQuery();
      query.key = 'zonalChecker';
      query.operation = 'equals';
      query.value = this.adminUserId;
      this.filterVoucherProps.push(query);
    }
    if (this.below5l) {
      query = new filterQuery();
      query.key = 'transactionAmount';
      query.operation = 'lessThanEqual';
      query.value = 500000;
      this.filterVoucherProps.push(query);
    }
    if (this.above5l) {
      query = new filterQuery();
      query.key = 'transactionAmount';
      query.operation = 'greaterThan';
      query.value = 500000;
      this.filterVoucherProps.push(query);
    }
    if (this.sign === 'noSign') {
      query = new filterQuery();
      query.key = 'signaturePresident';
      // query.operation = 'equals';
      query.operation = 'null';
      query.value = null;
      this.filterVoucherProps.push(query);
    }
    if (this.sign === 'Sign') {
      query = new filterQuery();
      query.key = 'signaturePresident';
      query.operation = 'equals';
      query.value = 'PRESIDENT SIGNED';
      this.filterVoucherProps.push(query);
    }
    query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterVoucherProps.push(query);
    if (this.value.value == "ALL") {
      const indexToRemove = this.filterVoucherProps.findIndex(item => item.key === 'personalFlag');
      if (indexToRemove !== -1) {
        this.filterVoucherProps.splice(indexToRemove, 1);
      }
    }
    let Query = {
      page: page - 1,
      size: this.itemsPerPage,
    };
    this.paymentService.reportfilter({ ...Query }, this.filterVoucherProps).subscribe(
      (res: HttpResponse<VendorTransaction[]>) => {
        this.searchVoucherResult.total = Number(
          res.headers.get('X-Total-Count')
        );
        this.searchVoucherResult.items = res.body ?? [];
        this.searchVoucherResult.items.forEach((element, i) => {
          if (element.transactionStatus == null) {
            element.transactionStatus = 'Not Initiated';
          }
          else if (element.transactionStatus == 'O.K.') {
            element.transactionStatus = 'Success';
          }
          else if (element.transactionStatus == 'failed') {
            element.transactionStatus = 'Failed';
          }
          else if (element.transactionStatus == 'processing') {
            element.transactionStatus = 'Processing';
          }
        });
        this.searchVoucherResult = { ...this.searchVoucherResult };
      },
      () => {
        this.onError();
      }
    );
  }
  redirectToVoucher($event) {
    if ($event.personalFlag.toUpperCase() === 'YES') {
      this.router.navigate(['/payment-personal-voucher'], {
        queryParams: {
          id: $event.id,
        },
      });
    }
    else if ($event.personalFlag.toUpperCase() === 'NO') {
      this.router.navigate(['/PaymentVoucher'], {
        queryParams: {
          id: $event.id,
        },
      });
    }
    else if ($event.personalFlag.toUpperCase() === 'E') {
      this.router.navigate(['/employeePaymentVoucher'], {
        queryParams: {
          id: $event.id,
        },
      });
    }
    else if ($event.personalFlag.toUpperCase() === 'ER') {
      this.router.navigate(['/electedRepresenativePaymentlComponent'], {
        queryParams: {
          id: $event.id,
        },
      });
    }
    else if ($event.personalFlag.toUpperCase() === 'BE') {
      this.router.navigate(['/employeeBulkPayVoucher'], {
        queryParams: {
          id: $event.id,
        },
      });
    }
    else if ($event.personalFlag.toUpperCase() === 'BER') {
      this.router.navigate(['/ERBulkPayVoucher'], {
        queryParams: {
          id: $event.id,
        },
      });
    }

  }

  getSubTableDetails($event) {
    this.transid = $event.transactionId;
    this.enableStatusVoucher = true;
    this.searchVoucherStatusResult.items = [];
    this.searchVoucherStatusResult.total = 0;
    if ($event.personalFlag.toUpperCase() === 'YES') {
      this.vouchercols = [
        {
          field: 'descriptionDto',
          header: 'Description',
          isFilterable: false,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.description',

        },
        {
          field: 'quantity',
          header: 'Quantity',
          isFilterable: false,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.quantity',

        },
        {
          field: 'amount',
          header: 'Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.amount',
          type: 'amount'
        },
        {
          field: 'grossAmount',
          header: 'Gross Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.grossTotal',
          type: 'amount'
        },
      ];
    } else if ($event.personalFlag.toUpperCase() === 'NO') {
      this.vouchercols = [
        {
          field: 'description',
          header: 'Description',
          isFilterable: false,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.description',
        },
        {
          field: 'quantity',
          header: 'Quantity',
          isFilterable: false,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.quantity',

        },
        {
          field: 'amount',
          header: 'Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.amount',
          type: 'amount'

        },
        {
          field: 'gstAmount',
          header: 'Gst Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.gst',
        },
        {
          field: 'grossAmount',
          header: 'Gross Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.grossTotal',
          type: 'amount'
        },
      ];
    } else if ($event.personalFlag.toUpperCase() === 'E') {
      this.vouchercols = [
        {
          field: 'description',
          header: 'Description',
          isFilterable: false,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.description',
        },
        {
          field: 'quantity',
          header: 'Quantity',
          isFilterable: false,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.quantity',

        },
        {
          field: 'amount',
          header: 'Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.amount',
          type: 'amount'

        },
        {
          field: 'gstAmount',
          header: 'Gst Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.gst',
        },
        {
          field: 'grossAmount',
          header: 'Gross Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.grossTotal',
          type: 'amount'
        },
      ];
    } else if ($event.personalFlag.toUpperCase() === 'ER') {
      this.vouchercols = [
        {
          field: 'description',
          header: 'Description',
          isFilterable: false,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.description',
          type: 'dropDown'
        },
        {
          field: 'quantity',
          header: 'Quantity',
          isFilterable: false,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.quantity',

        },
        {
          field: 'amount',
          header: 'Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.amount',
          type: 'amount'

        },
        {
          field: 'grossAmount',
          header: 'Gross Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.grossTotal',
          type: 'amount'
        },
      ];
    }
    else if ($event.personalFlag.toUpperCase() === 'BE') {
      this.vouchercols = [
        {
          field: 'name',
          header: 'Name',
          isSelectcolumn: true,
        },
        {
          field: 'description',
          header: 'Description',
          isSelectcolumn: true,
        },
        {
          field: 'accountNumber',
          header: 'Account No',
          isSelectcolumn: true,
        },
        {
          field: 'grossAmount',
          header: 'Gross Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.grossTotal',
          type: 'amount'
        },
        {
          field: 'createdOn',
          header: 'Created Date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        }]
    }
    else if ($event.personalFlag.toUpperCase() === 'BER') {
      this.vouchercols = [
        {
          field: 'name',
          header: 'Name',
          isSelectcolumn: true,
        },
        {
          field: 'description',
          header: 'Description',
          isSelectcolumn: true,
          type: 'dropDown'
        },
        {
          field: 'accountNumber',
          header: 'Account No',
          isSelectcolumn: true,
        },
        {
          field: 'grossAmount',
          header: 'Gross Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.grossTotal',
          type: 'amount'
        },
        {
          field: 'createdOn',
          header: 'Created Date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        }]
    }
    else if ($event.personalFlag.toUpperCase() === 'T') {
      this.vouchercols = [
        {
          field: 'voucherNo',
          header: 'Voucher No',
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.no'
        },
        {
          field: 'consumerNo',
          header: 'Consumer No',
          isSelectcolumn: true,
        },
        {
          field: 'billType',
          header: 'Bill Type',
          isSelectcolumn: true,
        },
        {
          field: 'billMonth',
          header: 'Bill Month',
          isSelectcolumn: true,
        },
        {
          field: 'billYear',
          header: 'Bill Year',
          isSelectcolumn: true,
        },
        {
          field: 'billAmount',
          header: 'Gross Amount',
          isSortable: true,
          isSelectcolumn: true,
          jhiTranslate: 'payments.table.grossTotal',
          type: 'amount'
        },
        {
          field: 'createdOn',
          header: 'Created Date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        },
      ]
    }
    this.flag = $event.personalFlag;
    this.loadSubTable(1);
  }
  loadSubTable(page: number) {
    this.dashboardService.getVoucherSubtableById(this.flag, this.transid).subscribe(
      (res: HttpResponse<any>) => {
        this.searchVoucherStatusResult.total = Number(
          res.headers.get('X-Total-Count')
        );
        this.searchVoucherStatusResult.items = res.body ?? [];
        this.searchVoucherStatusResult = { ...this.searchVoucherStatusResult };
      },
      () => {
        this.onError();
      }
    );
  }



  loadFinYear(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.limitTransListService.getFinYear().subscribe(
        (res: HttpResponse<FinYear[]>) => {
          this.finYearList = res.body;
          let FinYear = ''
          if (new Date().getMonth() + 1 >= 4) {
            FinYear =
              new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
          } else {
            FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
          }
          this.finYear = this.finYearList.find((_) => _.name === FinYear);
          this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
          this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
          resolve(this.finYear);
        },
        () => { }
      )


    });
  }

  onFinYearChange() {
    console.log('this.finyear',this.finYear.name.slice(0,4));
    switch (true) {
      case this.roleName?.startsWith('State') || this.roleCode == 'SUA':
        this.isStateRole = true;
        this.getSnaDetails();
        this.getCompFundByLevel();
        this.getPendingVocuher();
        this.getFundByLevel();
        this.getMajorSpent();
        break;
      case this.roleName?.startsWith('District') && this.roleCode !== 'DAD':
        this.loadGrantLevel();
        this.getPanchayatFundReport();
        this.loadSanKey(this.districtListDto);
        this.loadTimeline(this.levelMasId);
        break;
      case this.roleName?.startsWith('Block'):
        this.loadGrantLevel();
        this.getPanchayatFundReport();
        this.loadSanKey(this.BlockLevelDto);
        this.loadTimeline(this.levelMasId);

        break;
      case this.roleName?.startsWith('Village'):

        this.loadGrantLevel();
        this.getPanchayatFundReport();
        this.loadSanKey(this.villageLevelDto);
        this.loadTimeline(this.levelMasId);


        break;

      case this.roleName?.startsWith('Zonal'):

        this.loadGrantLevel();
        this.getPanchayatFundReport();
        this.loadSanKey(this.BlockLevelDto);
        this.loadTimeline(this.levelMasId);
        break;
      default:
    }

  }

  loadSourceAmount (list,componentName,paymentType) {
    this.fundSource = [];
    this.fundName = componentName;
    this.paymentStatus = paymentType;
   
      if(list.compName === 'Village Minimum Grant - B' || list.compName === 'Village General Funds - A' || list.compName === 'Village Salary - C' ||  list.compName === 'Village Thooimai Kavalar - D') {
        switch(paymentType) {
          case 'Approved':
            this.fundSource.push({'cApprovedVouchersValue':list.cApprovedVouchersValue ? list.cApprovedVouchersValue:0,'vApprovedVouchersValue':list.vApprovedVouchersValue ? list.vApprovedVouchersValue:0});
            break;
          case 'Pending':
            this.fundSource.push({'cPendingVouchersValue':list.cPendingVouchersValue ? list.cPendingVouchersValue:0,'vPendingVouchersValue':list.vPendingVouchersValue ? list.vPendingVouchersValue:0});
            break;
          case 'Reject':
            this.fundSource.push({'cRjectedVouchersValue':list.cRejectedVouchersValue ? list.cRejectedVouchersValue:0,'vrjectedVouchersValue':list.vRejectedVouchersValue ? list.vRejectedVouchersValue:0});
            break;
          case 'Initialize':
            this.fundSource.push({'cTotalVouchersValue':list.cTotalVouchersValue ? list.cTotalVouchersValue:0,'vTotalVouchersValue':list.vTotalVouchersValue ? list.vTotalVouchersValue:0});
            break;
          case 'Allocated':
            this.fundSource.push({'cAllocatedAmount':list.cAllocatedAmount ? list.cAllocatedAmount:0,'vAllocatedAmount':list.vAllocatedAmount ? list.vAllocatedAmount:0});
            break;
         
        }
      }
    this.enableFundsSource = true;
  }



}


