import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { PushNotification } from './push-notification.model';

export type EntityResponseType = HttpResponse<PushNotification[]>;

@Injectable({ providedIn: 'root' })
export class PushNotificationService {
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getAllpushnotification');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/savepushnotify');
    protected getPushNotifyByDateAndTimeeUrl = environment.SERVER_API_URL+('api/getPushNotifyByDateAndTime');
    protected deletePushNotificationUrl = environment.SERVER_API_URL+('api/deletePushNotification');
    protected vpaxUrl =  "https://vptax.tnrd.tn.gov.in/project/webservices/open_services/sna_services.php";
    protected atomUrl = "https://pgreports.atomtech.in/SettlementReport/generateReport?merchantId=411938&settlementDate=2024-03-16&responseType=json";
   
   

    constructor(protected http: HttpClient) {}
    filter(req?: any): Observable<EntityResponseType> {
      
      const options = createRequestOption(req);
        return this.http
        .get<PushNotification[]>(this.resourceFliterUrl, { params:options, observe: 'response' })
    }

    atom(){
        return this.http
        .post(this.atomUrl, { observe: 'response' })
    }

    getPushNotify(){
        return this.http
        .get<PushNotification>(this.getPushNotifyByDateAndTimeeUrl, { observe: 'response' }) 
    }

    save(profile?:PushNotification) {
        return this.http
        .post<PushNotification>(this.resourceSaveUrl,profile, {observe: 'response' })
    }
    delete(id: number): Observable<HttpResponse<{}>> {
      return this.http.get(`${this.deletePushNotificationUrl}?id=${id}`, { observe: 'response',responseType:'text' });
    }
    vpax(): Observable<any> {
      const payload = "qtwCCJGXx20yW4UTOXcYkkJj9kfcUgOTIMNflp5mXyvlbumdQ8BF2qUiUyCN1rTyJ03xZBlKt/0GhAuv8hl9LQ==:MlNOMjJTa0pHRHlPQVhVVQ==";
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.http.request('GET', this.vpaxUrl, {
        headers: headers,
        body: payload,
        observe: 'response'
      });
    }
}
