import { ComponentSwapService } from '../component-swap-page/component-swap-service';
import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { SearchResult } from "src/app/shared/models/search-result";
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpResponse } from '@angular/common/http';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/alert/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { ReportService } from 'src/app/report/report.service';
import { LevelMaster, LevelMasterDto } from 'src/app/module/level-master/level-master';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { ComponentLimitData, ComponentLimitSwap, ComponentLimitSwapDescription, SwapTransaction } from '../component-swap-page/component-swap-model';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { LimitSetService } from 'src/app/module/limit-set/limit-set.service';
import { LevelType } from 'src/app/module/level-type/level-type';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from 'ngx-webstorage';
import { Location } from '@angular/common';

@Component({
  selector: 'app-component-swap-page',
  templateUrl: './component-swap-page.component.html',
  styleUrl: './component-swap-page.component.scss'
})
export class ComponentSwapPageComponent {
  levelMasterDialog: boolean = false;
  cols: any[];
  filterProps: any;
  searchResult: SearchResult<SwapTransaction> = new SearchResult<SwapTransaction>();
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: number = 10;
  page: number;
  tamilText: any;

  //required fields property
  ngSelectInvalid: boolean = false;
  codeError: string;
  roleCode: any;
  villageLevelList: LevelMasterDto[];
  districtLevelList: LevelMasterDto[];
  blockLevelList: LevelMasterDto[];
  swapTransaction: SwapTransaction;
  grantLevelList: any;
  grantlevel: any;
  finYearList: FinYear[];
  finYear: FinYear;
  vFileExt: any;
  vFileUrl: string;
  viewDoc: boolean;
  isFileUploaded: boolean;
  uFilename: string;
  userName: string;
  fromComponementAvailableAmount: any;
  toComponementAvailableAmount: any;
  isApproved: boolean;
  departmentName: any;
  schemeName: any;
  date: Date;
  readonly: boolean;
  componentLimitSwapDescription: ComponentLimitData;
  showTableFrom: boolean;
  toComponentMaster: any;
  enableApproved: boolean;
  signButtonName: string;
  downloadButton: boolean;
  enableRevertButton: boolean;
  enableSignButton: boolean;
  param1: any;
  param2: any;
  param3: any;
  enableView: boolean;
  grantList: any;
  toGrandLevelList: any;
  currentPath: string;
  districtGrandList: any;
  transferType: string;
  headingText: string;
  voucherUrl: string;
  listUrl: string;
  limitAmount: any;


  constructor(

    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private loginService: SigninService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    protected componentSwapService: ComponentSwapService,
    public reportService: ReportService,
    private levelMasterService: LevelMasterService,
    protected router: Router,
    private componentTypeService: ComponentTypeService,
    private paymentService: PaymentService,
    private limitTransListService: LimitTransListService,
    private limitSetService: LimitSetService,
    protected activatedRoute: ActivatedRoute,
    private sessionStorage: SessionStorageService,
    private location: Location,

  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  async ngOnInit() {
    this.currentPath = this.location.path();
    this.roleCode = this.sessionStorage.retrieve('user')
    this.transferType = this.currentPath.startsWith('/funds-transfer-village-page') ? 'MS' : 'CS';
    this.headingText = this.currentPath.startsWith('/funds-transfer-village-page') ? 'Fund Transfer to Village page' : 'Component Wise Account Swap';
    this.voucherUrl = this.currentPath.startsWith('/funds-transfer-village-page') ? '/funds-transfer-village-page' : '/component-swap-page';
    this.listUrl = this.currentPath.startsWith('/funds-transfer-village-page') ? '/funds-transfer-village-list' : '/component-swap-list';
    if (
      this.roleCode.role.code === 'DS'

    ) {
      this.signButtonName = 'Perform e-Sign District Superintendent';
    }

    else if (this.roleCode.role.code === 'DA') {
      this.signButtonName = 'Perform e-Sign  District AD';
      this.enableRevertButton = true;
      this.enableApproved = true;
      this.readonly = true;
    }
    else if (this.roleCode.role.code === 'SMAKER') {
      this.signButtonName = 'Perform e-Sign State Superintendent';
    }
    else if (this.roleCode.role.code === 'SC') {
      this.signButtonName = 'Perform e-Sign  State Checker';
      this.enableRevertButton = true;
      this.enableApproved = true;
      this.readonly = true;
    }
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      if (this.activatedRoute.snapshot.queryParamMap.get('view')) {
        this.enableView = true;
        this.readonly = true;
      }
      this.spinner.show();
      this.componentSwapService
        .componentSwapWithDescriptionById(
          Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
        )
        .subscribe(
          (res: HttpResponse<SwapTransaction>) => {
            this.swapTransaction = res.body;
            this.departmentName =
              this.swapTransaction.componentLimitSwap.implementingAgency.department.name;
            this.schemeName =
              this.swapTransaction.componentLimitSwap.implementingAgency.scheme.name;
            if (this.swapTransaction.componentLimitSwap.fileUpload) {
              this.uFilename = this.swapTransaction.componentLimitSwap.fileUpload;
              this.isFileUploaded = true;
            }
            this.swapTransaction.componentLimitSwap.transactionDate = new Date(
              this.swapTransaction.componentLimitSwap.transactionDate
            );
            if (this.swapTransaction.componentLimitSwap.signStatus) {
              this.readonly = true;
              this.enableSignButton = true;
            }
            this.spinner.hide();
          },
          () => { }
        );
    }
    else {
      this.swapTransaction = new SwapTransaction();
      this.swapTransaction.componentLimitSwap = new ComponentLimitSwap();
      this.swapTransaction.componentLimitSwapDescriptions = [];
      if (!this.finYearList) {
        await this.loadFinYear();
      }
      if (this.currentPath.startsWith('/funds-transfer-village-page')) {
        this.onLevelType(2);
      }
      this.onLevelType(4);
      if (this.roleCode.role.code === 'DS' || this.roleCode.role.code === 'DA') {
        this.componentLimitSwapDescription = new ComponentLimitData();
        this.onDistrictLevelChange(this.roleCode.levelMasterId);
      }
      else {
        this.onStateLevelChange();
      }

      this.swapTransaction.componentLimitSwap.levelMaster = new LevelMaster();
      this.swapTransaction.componentLimitSwap.levelMaster.id = this.roleCode.levelMasterId;
      this.swapTransaction.componentLimitSwap.levelType = new LevelType();
      this.swapTransaction.componentLimitSwap.levelType.id = 4;

      this.swapTransaction.componentLimitSwap.implementingAgency = this.sessionStorage.retrieve('IA');
      this.departmentName = this.swapTransaction.componentLimitSwap.implementingAgency.departmentDto.name;
      this.schemeName = this.swapTransaction.componentLimitSwap.implementingAgency.schemeDto.name;


      this.swapTransaction.componentLimitSwap.voucherNumber =
        this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.swapTransaction.componentLimitSwap.transactionDate = new Date();
    }


  }


  ngAfterViewInit() {
    this.updateDialogFont();

  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }



  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;

          resolve(this.roleCode);
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  onStateLevelChange($event?) {
    console.log('stateMaker')
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.roleCode.levelMasterId,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;

          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.roleCode.levelMasterId,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    }
  }

  onDistrictLevelChange(id, $event?) {
    this.componentLimitSwapDescription.fromComponementAvailableAmount = null;
    this.componentLimitSwapDescription.toComponementAvailableAmount = null;
    this.componentLimitSwapDescription.implementingAgency = null;
    this.componentLimitSwapDescription.villageLevelMaster = null;
    this.componentLimitSwapDescription.blockLevelMaster = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    }
  }

  onBlockLevelChange($event?) {
    this.componentLimitSwapDescription.fromComponementAvailableAmount = null;
    this.componentLimitSwapDescription.toComponementAvailableAmount = null;
    this.componentLimitSwapDescription.implementingAgency = null;
    this.componentLimitSwapDescription.villageLevelMaster = null;
    this.villageLevelList = [];
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.componentLimitSwapDescription.blockLevelMaster.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.componentLimitSwapDescription.blockLevelMaster.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    }
  }

  onLevelType(levelType) {
    this.componentTypeService
      .find(levelType)
      .subscribe(
        (res: HttpResponse<any>) => {
          if (levelType === 4) {
            this.grantList = res.body;
            if (this.currentPath.startsWith('/component-swap-page')) {
              this.grantLevelList = res.body.filter(item => item.componentCode === 'B' || item.componentCode === 'A' || item.componentCode === 'C' || item.componentCode === 'D');
              if (this.roleCode.role.code === 'DS' || this.roleCode.role.code === 'DA') {
                this.swapTransaction.componentLimitSwap.fromComponentMaster = res.body.filter(_ => _.name == 'Village Minimum Grant')[0];
                this.toComponentMaster = res.body.filter(_ => _.name.startsWith('Surplus Fund'))[0];
              }
            }
          }
          else {
            this.districtGrandList = res.body.filter(item => item.componentCode === 'F1' || item.componentCode === 'F4');
            console.log('thisdistrict', this.districtGrandList);
          }

        },
        () => { }

      );
  }

  loadFinYear(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.limitTransListService.getFinYear().subscribe(
        (res: HttpResponse<FinYear[]>) => {
          this.finYearList = res.body;
          let FinYear = ''
          if (new Date().getMonth() + 1 >= 4) {
            FinYear =
              new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
          } else {
            FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
          }
          this.swapTransaction.componentLimitSwap.finYear = this.finYearList.find((_) => _.name === FinYear);
          this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
          this.spinner.hide();
          resolve(this.finYear);
        },
        () => { }
      )


    });
  }

  getIaByLevelMaster(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.limitTransListService.getImplementingAgencyByLevelMaster(this.componentLimitSwapDescription.villageLevelMaster.id).subscribe(
        (res) => {
          this.componentLimitSwapDescription.implementingAgency = res.body[0];
          resolve(this.componentLimitSwapDescription.implementingAgency);
        },
        () => { }
      )


    });
  }

  async OnVillageChange() {
    this.componentLimitSwapDescription.fromComponementAvailableAmount = null;
    this.componentLimitSwapDescription.toComponementAvailableAmount = null;
    this.componentLimitSwapDescription.implementingAgency = null;
    if (this.swapTransaction.componentLimitSwapDescriptions.filter(_ => _.villageLevelMaster === this.componentLimitSwapDescription.villageLevelMaster.id).length !== 0) {
      this.notificationService.alertError(this.componentLimitSwapDescription.villageLevelMaster.name + ' This Village Already Added', '');
      this.componentLimitSwapDescription.villageLevelMaster = null;
      return
    }
    await this.getIaByLevelMaster();
    if(this.transferType === 'CS') {
      this.ongrandLevel('from', this.swapTransaction.componentLimitSwap.fromComponentMaster.id);
    }
    if (this.roleCode.role.code === 'DS' || this.roleCode.role.code === 'DA') {
      this.ongrandLevel('to', this.componentLimitSwapDescription.toComponentMaster.id);
    }
    else {
      this.toGrandLevelList = this.grantList.filter(item => (item.componentCode === 'B' || item.componentCode === 'A' || item.componentCode === 'C' || item.componentCode === 'D' || item.componentCode === 'F3') && item.componentCode !== this.swapTransaction.componentLimitSwap.fromComponentMaster.componentCode)
      console.log('this.grand', this.toGrandLevelList);
    }
  }


  ongrandLevel(type, id) {

    this.paymentService
      .getLimit(
        this.componentLimitSwapDescription.implementingAgency.id,
        id,
        this.swapTransaction.componentLimitSwap.finYear.id
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          if (type === 'from') {
            this.componentLimitSwapDescription.fromComponementAvailableAmount = res.body.realizedLimitAmount;
          }
          else {
            this.componentLimitSwapDescription.toComponementAvailableAmount = res.body.realizedLimitAmount;
          }

        },
        () => { }
      );

  }

  viewDocument() {
    this.paymentService.viewimage(this.swapTransaction.componentLimitSwap.fileUpload)
      .subscribe((res) => {
        const filename = res.body.fileName;
        this.vFileExt = filename.split('.').pop();
        this.vFileUrl = "data:image/" + this.vFileExt + ";base64," + res.body.fileType; // Replace with your Base64-encoded image string
        this.viewDoc = true;
      });
  }
  downloadImage(base64Data: string, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    link.click();
  }
  downloadDocument() {
    this.paymentService.getimage(this.swapTransaction.componentLimitSwap.fileUpload)
      .subscribe((res) => {
        const filename = res.body.fileName;
        const fileExtension = filename.split('.').pop();

        if (fileExtension == 'pdf') {
          const base64Data = "data:application/pdf;base64," + res.body.fileType; // Replace with your Base64-encoded image string
          this.downloadImage(base64Data, filename);
        }
        else {
          const base64Data = "data:image/" + fileExtension + ";base64," + res.body.fileType; // Replace with your Base64-encoded image string
          this.downloadImage(base64Data, filename);
        }
      },
        (onError) => {

        })
  }
  removeDocument() {
    this.paymentService.deleteimage(this.swapTransaction.componentLimitSwap.fileUpload)
      .subscribe((res) => {
        this.notificationService.alertSuccess("File Deleted", '');
        this.isFileUploaded = false;
        this.swapTransaction.componentLimitSwap.fileUpload = null;
      },
        (error) => {
          this.notificationService.alertError("Error occured, try again", '');
        });
  }

  onUpload($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.swapTransaction.componentLimitSwap.fileUpload =
          res.body.fileName;
        this.uFilename = this.swapTransaction.componentLimitSwap.fileUpload;
        this.isFileUploaded = true;
        this.notificationService.alertSuccess('Uploaded Successfully', '');
      },
      () => {
        this.notificationService.alertError('Error!', '');
      }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }

  onsave() {
    this.ngSelectInvalid = false;
    this.swapTransaction.componentLimitSwap.transferType = this.transferType;
    const isFormValid = this.swapTransaction.componentLimitSwap.remarks && this.swapTransaction.componentLimitSwap.fileUpload

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError("Please fill all the mandotory fields marked with *", '');
      return;
    }
    this.spinner.show();
    this.componentSwapService.save(this.swapTransaction).subscribe(
      (res) => {
        console.log('saveData', res)
        this.spinner.hide();
        this.notificationService.alertSuccess('Saved Successfully', '');
        this.enableSignButton = true;
        this. readonly = true;
      },
      (err) => {
        this.spinner.hide();
        console.log('err', err)
        if (err.error.status === 400) {
          this.notificationService.alertError('Error! ' + err.error.title, '');
        } else {
          this.notificationService.alertError('Unexpected Error!', '');
        }
      }
    );
  }

  reject() {
    this.spinner.show();
    this.componentSwapService
      .rejectTransaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate([this.listUrl], {});
        },
        (onError) => {
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.title, '');
          } else {
            this.notificationService.alertError('Error in Reject', '');
          }

          this.spinner.hide();
        }
      );
  }



  addRow() {
    this.showTableFrom = true;
    this.componentLimitSwapDescription = new ComponentLimitData();
    this.componentLimitSwapDescription.toComponentMaster = this.toComponentMaster;
  }

  delete(index: number) {
    if(this.transferType === 'MS') {
      this.limitAmount += this.swapTransaction.componentLimitSwapDescriptions[index].transactionAmount;
    }
    this.swapTransaction.componentLimitSwapDescriptions.splice(index, 1);
  }

  apply() {
    this.swapTransaction.componentLimitSwapDescriptions = this.swapTransaction.componentLimitSwapDescriptions ? this.swapTransaction.componentLimitSwapDescriptions : [];
    let componentLimitSwap = new ComponentLimitSwapDescription();
    componentLimitSwap.blockLevelMaster = this.componentLimitSwapDescription.blockLevelMaster.id;
    if (this.roleCode.role.code === 'SMAKER' || this.roleCode.role.code === 'SC') {
      componentLimitSwap.districtLevelMaster = this.componentLimitSwapDescription.districtLevelMaster.id;
      componentLimitSwap.districtLevelMasterName = this.componentLimitSwapDescription.districtLevelMaster.name;
    }
    componentLimitSwap.villageLevelMaster = this.componentLimitSwapDescription.villageLevelMaster.id;
    componentLimitSwap.villageLevelMasterName = this.componentLimitSwapDescription.villageLevelMaster.name;
    componentLimitSwap.blockLevelMasterName = this.componentLimitSwapDescription.blockLevelMaster.name;
    componentLimitSwap.toComponentMaster = this.componentLimitSwapDescription.toComponentMaster.id;
    componentLimitSwap.toComponentMasterName = this.componentLimitSwapDescription.toComponentMaster.name;
    componentLimitSwap.villageLgdCode = this.componentLimitSwapDescription.villageLevelMaster.code;
    componentLimitSwap.transactionAmount = this.componentLimitSwapDescription.transactionAmount;
    componentLimitSwap.fromComponementAvailableAmount = this.componentLimitSwapDescription.fromComponementAvailableAmount;
    componentLimitSwap.toComponementAvailableAmount = this.componentLimitSwapDescription.toComponementAvailableAmount;
    componentLimitSwap.finYearId = this.swapTransaction.componentLimitSwap.finYear.id;
    if(this.transferType === 'MS') {
      this.limitAmount -= componentLimitSwap.transactionAmount;
    }
    this.swapTransaction.componentLimitSwapDescriptions.push(componentLimitSwap);
    this.showTableFrom = false;
    this.villageLevelList = null;
  }

  submitForm() {
    this.spinner.show();
    this.componentSwapService
      .getfile(this.swapTransaction.componentLimitSwap.voucherNumber)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          if (!this.swapTransaction.componentLimitSwap.id) {
            this.router.navigate([this.voucherUrl], {
              queryParams: {
                id: res.body.paramFour
              },
            });
          }
          this.sessionStorage.store('refresh', 'Yes');
          const formElement = document.createElement('form');
          formElement.method = 'POST';
          formElement.action = environment.CDAC_URL;
          let inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "eSignRequest";
          inputElement.value = res.body.paramOne;
          formElement.appendChild(inputElement);
          inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "aspTxnID";
          inputElement.value = res.body.paramTwo;
          formElement.appendChild(inputElement);
          inputElement = document.createElement('input');
          inputElement.type = 'hidden';
          inputElement.name = "Content-Type";
          inputElement.value = res.body.paramThree;
          formElement.appendChild(inputElement);
          document.body.appendChild(formElement);
          formElement.submit();
        },
        (error) => {
          this.spinner.hide();
          console.log('error', error);
          if (
            this.roleCode.role.code === 'DS'

          ) {
            this.notificationService.alertError('Voucher Generation Failed, please delete this voucher and retry. voucher No: ' + this.swapTransaction.componentLimitSwap.voucherNumber, '');
            this.router.navigate([this.listUrl], {});
          }
          else {
          }
        }
      );

  }

  getPdf() {
    let param = {
      filename: this.swapTransaction.componentLimitSwap.voucherNumber,
      paymentType: 'ComponentSwap',
    };
    this.componentSwapService
      .getPdfFile(param)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
          // this.spinner.hide();
          // this.router.navigate(['payment']);
          // this.getfile();
          // this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (err) => {
          // this.spinner.hide();
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  clearData() {
    this.swapTransaction.componentLimitSwapDescriptions = [];
    this.componentLimitSwapDescription = new ComponentLimitData();
  }
  getDistrcitComponentLimit() {
    this.toComponentMaster = this.grantList.filter(_=>_.componentCode ===  this.swapTransaction.componentLimitSwap.fromComponentMaster.componentCode)[0];
    console.log('this.componentLimitSwapDescription.toComponentMaster',this.componentLimitSwapDescription);
    this.paymentService
      .getLimit(
        this.swapTransaction.componentLimitSwap.implementingAgency.id,
        this.swapTransaction.componentLimitSwap.fromComponentMaster.id,
        this.swapTransaction.componentLimitSwap.finYear.id
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.limitAmount = res.body.realizedLimitAmount;
        },
        () => { }
      );

  }



}
