import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  encryptData(data: string): string {
    const captchaLength = 16; // Specify the desired captcha length
    const saltCharacters =
      "abranNumcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ234567890";
    let captchaStr = "";

    while (captchaStr.length < captchaLength) {
      const index = Math.floor(Math.random() * saltCharacters.length);
      captchaStr += saltCharacters.charAt(index);
    }
    const captcha = captchaStr;
    const key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
    const iv = CryptoJS.enc.Utf8.parse(captcha);
    const paddedData = this.zeroPadData(data).toString();
    const encryptedData = CryptoJS.AES.encrypt(paddedData, key, {
      iv: iv,
    }).toString();
    const encryptedTextWithIV = captcha.toString() + encryptedData.toString();

    const base64CipherText1 = encryptedData.toString(CryptoJS.enc.Base64);


    const base64CipherText = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(encryptedTextWithIV)
    );
    return base64CipherText;
  }

  zeroPadData(data: string): string {
    const blockSize = 16;
    const paddingLength = blockSize - (data.length % blockSize);
    const paddedData = data + "\x00".repeat(paddingLength);
    return paddedData;
  }
}