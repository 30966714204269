import { Component, OnInit } from '@angular/core';
import { SearchResult } from 'src/app/shared/models/search-result';
import { AccountStatementService } from './account-statement.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/alert/notification.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { LevelMasterDto } from '../level-master/level-master';
import { HttpResponse } from '@angular/common/http';
import { LevelMasterService } from '../level-master/level-master.service';

@Component({
  selector: 'app-account-statement-balances-village',
  templateUrl: './account-statement-balances-village.component.html',
  styleUrl: './account-statement-balances-village.component.scss'
})
export class AccountStatementBalancesVillageComponent implements OnInit {
  cols: any;
  searchResult: SearchResult<any> =
    new SearchResult<any>();
  villageName: any;
  lgdCode: any;
  date: Date = new Date();
  accountBalance: string;
  isTable: boolean = false;
  tabledata: any = []
  today: string;
  isZonal: boolean;
  villageList: any;
  roleCode: any;
  levelMasId: number;
  blockMaster:any;
  districtMaster: any;
  villageLevelList: any;
  villagelevelMaster: null;
  districtLevelList: LevelMasterDto[];
  blockLevelList: LevelMasterDto[];
  constructor(private accountService: AccountStatementService,
    public localStorageService: LocalStorageService,
    public sessionStorageService: SessionStorageService,
    public datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private dashboardService: DashboardService,
    public fontService:FontService,
    public levelMasterService:LevelMasterService) {

  }

  ngOnInit() {

    if (this.sessionStorageService.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
      this.districtMaster = this.sessionStorageService.retrieve('levelMaster');
      this.onDistrictLevelChange();
    } else if (this.sessionStorageService.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
      this.blockMaster = this.sessionStorageService.retrieve('levelMaster');
      if (this.sessionStorageService.retrieve('roleCode') === 'ZVM') {
        this.dashboardService.getVillagePanchayatByZonal().subscribe(
          (res) => {
            console.log('resZonal', res);
            this.villageList = res;
            this.isZonal = false;
          },
          () => { }
        );
      }
      else {
        this.onBlockLevelChange();
      }

    }
    else if (this.sessionStorageService.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'VILLAGE') {
      this.isZonal = true;
    }
    else {
      this.onStateLevelChange();
    }
    this.roleCode = this.sessionStorageService.retrieve('roleCode');
    let levelMasterId = this.sessionStorageService.retrieve('levelMasterId');
    if (this.roleCode === 'VMAKER') {
      this.accountService.getLevelMasterById(levelMasterId).subscribe(res => {
        this.villageName = res.body.name;
        this.lgdCode = res.body.code
      }, (err => {
        
      }))
      this.isZonal = true;
     
    }
    else if (this.roleCode === 'ZVM') {
      this.isZonal = false;
      this.dashboardService.getVillagePanchayatByZonal().subscribe(
        (res) => {
          console.log('resZonal', res);
          this.villageList = res;
          this.isZonal = false;
          this.spinner.hide();
        },
        () => { }
      );
    }

   
    
    this.today = this.datePipe.transform(this.date, 'dd-MM-yyyy')
   
  }
  onLazyLoad(data) {

  }

 
  getAccountBalance() {
    this.spinner.show()
    this.accountService.getAccountBalance(this.levelMasId ? this.levelMasId : this.sessionStorageService.retrieve('levelMasterId')).subscribe(res => {
      this.accountBalance = res['body']['AccountEnquiry_Response']['Body']['Payload']['Account_Balance'];
      this.tabledata = [{
        'villageName': this.villageName,
        'lgdCode': this.lgdCode,
        'today': this.today,
        'accountBalance': this.accountBalance
      }]
      this.isTable = true;
      this.spinner.hide()
    }, (err => {
      this.notificationService.alertError(err.error, '')
      this.spinner.hide()
    }))
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    }
  }


  onDistrictLevelChange($event?) {
    this.blockMaster = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtMaster.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtMaster.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    }
  }



  onBlockLevelChange($event?) {
    this.levelMasId = null;
    this.villageLevelList = [];
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.blockMaster.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.blockMaster.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    }
  }

  search() {

    if (this.sessionStorageService.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE') {
      if (this.districtMaster && this.blockMaster && this.levelMasId) {
        this.isZonal = true;
        this.getAccountBalance();
      }
      else {

        this.notificationService.alertError('Please select a district, block, and village to proceed.', '');
        return

      }
    }
    if (this.search && this.sessionStorageService.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
      if (this.blockMaster && this.levelMasId) {
        this.isZonal = true;
        this.getAccountBalance();
      }
      else {
        this.notificationService.alertError('Please select a block, and village to proceed.', '');
        return

      }
    }
    if (this.search && this.sessionStorageService.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
      if (this.levelMasId) {
        this.isZonal = true;
        this.getAccountBalance();
      }
      else {

        this.notificationService.alertError('Please select a village to proceed.', '');
        return

      }
    }
  }

  onVillageChange() {
    this.accountService.getLevelMasterById(this.levelMasId).subscribe(res => {
      this.villageName = res.body.name;
      this.lgdCode = res.body.code
    }, (err => {
      
    }))
  }

  
}
