import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { FlaggingDto, VoucherFlagging } from './flagging.model';

export type EntityResponseType = HttpResponse<VoucherFlagging[]>;

@Injectable({ providedIn: 'root' })
export class FlagingService {
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getFlagByFilter');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveFlag');
    protected getFlagByIdUrl = environment.SERVER_API_URL+('api/getFlagById');
    protected deFlagUrl = environment.SERVER_API_URL+('api/deFlag');
   
    constructor(protected http: HttpClient) {}
    filter(filter:any,req?: any,): Observable<EntityResponseType> {
      
      const options = createRequestOption(req);
        return this.http
        .post<VoucherFlagging[]>(this.resourceFliterUrl,filter,{ params:options, observe: 'response' })
    }
    getFlagById(id:number){
        return this.http
        .get<FlaggingDto>(`${this.getFlagByIdUrl}?flagId=${id}`, { observe: 'response' }) 
    }

    save(flagging:FlaggingDto) {
        return this.http
        .post<FlaggingDto>(this.resourceSaveUrl,flagging, {observe: 'response' })
    }
    deFlag(id: number){
      return this.http.get(`${this.deFlagUrl}?flagId=${id}`, { observe: 'response',responseType:'text' });
    }
    
}