<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Flagging List
                    </h5>

                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row mt-3">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [lazyLoadOnInit]="false" (emitRow)="onSelectRow($event)" [disableDeleteIcon]="true" (searchParam)="onLazyLoad($event)" [disableEditIcon]="true" [deFlag]="roleCode === 'SA'"
                [enableView]="true"></custom-filter-table>
        </div>
    </div>
    <p-dialog [(visible)]="enableFlagVoucher" [style]="{width: '900px',height:'400px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="enableFlagVoucher = false">
        <div *ngIf="flagging" class="col-12">
            <div class="form-inner-panel">

                <div class="row">
                    <div class="col-12">
                        <h5 [ngClass]="fontService.headingClass">Flag Raise Issue</h5>
                    </div>
                    <div *ngIf="flagging.flaggingList.length === 0" class="row gy-2">
                        <div class="col-sm-6 col-md-6">
                            <label class="form-control-label dropdown-width req" [ngClass]="fontService.labelClass" for=" name">Issue Type</label>
                            <ng-select id="scheme" [(ngModel)]="flagging.voucherFlagging.issueType" [items]="issueType" bindLabel="habitationName" [disabled]="true" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-6">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" for=" name">Description</label>
                            <textarea id="w3review" name="w3review" rows="3" [(ngModel)]="flagging.voucherFlagging.description" [disabled]="true" cols="100"></textarea>
                        </div>
                    </div>
                    <div *ngIf="flagging.flaggingList.length !== 0" class="col-md-12 mt-2">
                        <p-table [value]="flagging.flaggingList" [paginator]="false" [rows]="20" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>S.No</th>
                                    <th *ngIf="flagging?.voucherFlagging?.paymentCategory?.toUpperCase() !== 'T'">Employee Name</th>
                                    <th *ngIf="flagging?.voucherFlagging?.paymentCategory?.toUpperCase() === 'T'">consumer No</th>
                                    <th>Amount</th>
                                    <th>Issue Type</th>
                                    <th>Description</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-index="rowIndex" let-list>
                                <tr>
                                    <td>{{index+1}}</td>
                                    <td *ngIf="flagging?.voucherFlagging?.paymentCategory?.toUpperCase() !== 'T'">{{ list.name}}</td>
                                    <td *ngIf="flagging?.voucherFlagging?.paymentCategory?.toUpperCase() === 'T'">{{ list.consumerNo}}</td>
                                    <td>{{list.amount}}</td>
                                    <td>
                                        <ng-select id="scheme" [(ngModel)]="list.issueType" [disabled]="true" [items]="list.issueTypeList" [disabled]="!list.isSelect" bindLabel="habitationName" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                                        </ng-select>
                                    </td>
                                    <td>
                                        <textarea id="w3review" name="w3review" rows="3" [disabled]="true" [(ngModel)]="list.description" [disabled]="!list.isSelect" cols="100"></textarea>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <div class="row">
                    <p-toolbar>
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="!disableFlagButton" pButton pRipple type="button" label="De Flagging" (click)="deFlag()" class="p-button-success"></button>
                        </div>
                    </p-toolbar>
                </div>
            </div>
        </div>

    </p-dialog>
</div>