<div class="container-fluid">
    <div class="row">
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <h5 [ngClass]="fontService.headingClass">Other Funds</h5>
                <!-- <h5 [ngClass]="fontService.headingClass" > - {{levelMasterName}} Panchayat</h5> -->
            </ng-template>
        </p-toolbar>
    </div>
    <div class="row">
        <div *ngIf="sessionStorage.retrieve('roleCode') === 'ZVM'" class="col-lg-2 col-md-4 col-sm-3">
            <label class="form-control-label req" for="Village" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="gender" bindLabel="levelMasterName" bindValue="levelMasterId" [items]="villageList" appearance="outline" [(ngModel)]="levelMasId" [closeOnSelect]="true" [clearable]="false" (ngModelChange)="onVillageChange()" class="form-control">
            </ng-select>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-3">
            <label class="form-control-label req" for="finYear" [ngClass]="fontService.labelClass">Fin Year</label>
            <ng-select id="gender" bindLabel="name" [items]="finYearList" appearance="outline" [(ngModel)]="finYear" [closeOnSelect]="true" [clearable]="false" (ngModelChange)="onFinYearChange()" class="form-control">
            </ng-select>
        </div>


    </div>
    <div *ngIf="isZonal" class="row g-2 mb-2 mt-2">
        <div class="col-md-12">
            <div class="card border-left-info shadow p-2">
                <div class="row">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.grantFundReport">
                        Fund Report on Grant Level</h5>
                </div>
                <p-table [value]="fundDetails" [resizableColumns]="true" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [ngClass]="fontService.labelClass"> {{'dashBoard.grantLevel'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.openingBalance'|translate}}<br>01.04.{{finYear?.name?.slice(0,4)}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.budgetAmount'|translate}}<br>{{'dashBoard.sanction'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.grandTotal1'|translate}}<br>{{'dashBoard.grandTotal2'|translate}}<br>{{'dashBoard.grandTotal3'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.expenditure'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.balanceAmount'|translate}}<br>{{'dashBoard.balance'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">{{'dashBoard.remainingBalance'|translate}}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">1</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">2</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">3 = 1+2</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">4</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">5 = 3-4</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list let-index="rowIndex">
                        <tr>
                            <td><b>{{list.compName}}</b></td>
                            <td style="text-align: center;"><b>{{list.openingBalance}}</b></td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B' && list.compName !== 'Village General Funds - A' && list.compName !== 'Village Salary - C' &&  list.compName !== 'Village Thooimai Kavalar - D'" style="text-align: center;"><b>{{list.allocatedAmount}}</b></td>
                            <td style="text-align: center;"><b>{{list.grandSanctioned}}</b></td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B' && list.compName !== 'Village General Funds - A' && list.compName !== 'Village Salary - C' &&  list.compName !== 'Village Thooimai Kavalar - D'" style="text-align: center;">
                                <b>{{list.utilizedAmount}}</b>
                            </td>
                            <td style="text-align: center;"><b>{{list.balanceAmount}}</b></td>
                            <td>
                                <p-progressBar [value]="list.balancePercent" [style]="{'background-color': '#97c95ca8'}"></p-progressBar>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

    </div>
    <div *ngIf="isZonal" class="row g-2 mb-2">
        <div class="col-md-12">
            <div class="card border-left-info shadow p-2">
                <div class="row">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass" jhiTranslate="dashBoard.voucherSummary">
                        Voucher Summary</h5>
                </div>
                <p-table [value]="voucherSummaryDetails" [resizableColumns]="true" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [ngClass]="fontService.labelClass"> {{'dashBoard.grantLevel'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.openingBalance'|translate}}<br>01.04.{{finYear?.name?.slice(0,4)}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.budgetAmount'|translate}}<br>{{'dashBoard.sanction'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.grandTotal1'|translate}}<br>{{'dashBoard.grandTotal2'|translate}}<br>{{'dashBoard.grandTotal3'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.totalVouchersGenerated1'|translate}}<br>{{'dashBoard.totalVouchersGenerated2'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.totalVouchersGenerated1'|translate}}<br>{{'dashBoard.approvedVouchersGenerated'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.totalVouchersGenerated4'|translate}}<br>{{'dashBoard.vouchers'|translate}}<br>{{'dashBoard.rejected'|translate}}<br>{{'dashBoard.Returned'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.totalVouchersGenerated1'|translate}}<br>{{'dashBoard.voucherPending'|translate}}<br>{{'dashBoard.forApproval'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"> {{'dashBoard.Balancesanction'|translate}}<br>{{'dashBoard.Available'|translate}}<br>{{'dashBoard.generation'|translate}}</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">{{'dashBoard.remainingBalance'|translate}}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">1</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">2</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">3 = 1+2</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">4 = 5+6+7</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">5</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">6</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">7</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">8=3-4+6</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list let-index="rowIndex">
                        <tr>
                            <td><b>{{list.compName}}</b></td>
                            <td style="text-align: center;"><b>{{list.openingBalance}}</b></td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B' && list.compName !== 'Village General Funds - A' && list.compName !== 'Village Salary - C' &&  list.compName !== 'Village Thooimai Kavalar - D'" style="text-align: center;"><b>{{list.allocatedAmount}}</b></td>
                            <td style="text-align: center;"><b>{{list.grandSanctioned}}</b></td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B' && list.compName !== 'Village General Funds - A' && list.compName !== 'Village Salary - C' &&  list.compName !== 'Village Thooimai Kavalar - D'" style="text-align: center;">
                                <b>{{list.totalVouchersValue}}</b>
                            </td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B' && list.compName !== 'Village General Funds - A' && list.compName !== 'Village Salary - C' &&  list.compName !== 'Village Thooimai Kavalar - D'" style="text-align: center;">
                                <b>{{list.approvedVouchersValue}}</b>
                            </td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B' && list.compName !== 'Village General Funds - A' && list.compName !== 'Village Salary - C' &&  list.compName !== 'Village Thooimai Kavalar - D'" style="text-align: center;">
                                <b>{{list.rejectedVouchersValue}}</b>
                            </td>
                            <td *ngIf="list.compName !== 'Village Minimum Grant - B' && list.compName !== 'Village General Funds - A' && list.compName !== 'Village Salary - C' &&  list.compName !== 'Village Thooimai Kavalar - D'" style="text-align: center;">
                                <b>{{list.pendingVouchersValue}}</b>
                            </td>
                            <td style="text-align: center;"><b>{{list.balanceAmount}}</b></td>
                            <td>
                                <p-progressBar [value]="list.balancePercent" [style]="{'background-color': '#97c95ca8'}"></p-progressBar>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

    </div>


</div>