import { NotificationService } from 'src/app/alert/notification.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SigninService } from './signin.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import * as forge from 'node-forge';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Captcha } from './login.model';
import { HttpResponse } from '@angular/common/http';
import { NavbarService } from 'src/app/layout/navbar/navbar.service';
import { filterQuery } from 'src/app/shared/common.model';
import { Profile } from 'src/app/module/profile/profile';
import { ProfileService } from 'src/app/module/profile/profie.service';
import { AccountStatementService } from 'src/app/module/account-statement-balances-village/account-statement.service';
import { ConfirmationService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { FontService } from 'src/app/shared/font-service/font.service';

@Component({
  selector: 'ib-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit, AfterViewInit {
  @ViewChild('username', { static: false })
  username!: ElementRef;
  filterProps: any;
  mobileNo: string;

  showOtp = false;
  inValidCaptcha = false;
  inValidUser: boolean = false;
  publicKey: string;
  loginForm = this.fb.group({
    username: [null, [Validators.required]],
    password: [null, [Validators.required]],
    captcha: [null, [Validators.required]],
    rememberMe: [false],
  });

  otpForm = this.fb.group({
    otp: [null, [Validators.required]],
  });
  image: any;
  captchaText: any;
  otpTimer: number;
  timer: any;
  enableResendButton: boolean;
  disableButton: boolean = false;
  otpError: string;
  passwordReset: string;
  profileStatus: string;
  uname: string;
  pwd: string;
  hide: boolean = true;
  captchaDetails: Captcha;
  captchaExpireError: string;
  spinner: boolean = false;
  btnSpinner: boolean = false;
  reSendCount: number = 1;
  roleCode: any;
  otpId: number;
  isMaintenance: boolean;
  inValidRequestError: any;
  showLogout: boolean;
  userName: any;

  constructor(
    private loginService: SigninService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private navbarService: NavbarService,
    protected notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private ProfileService: ProfileService,
    private accountService:AccountStatementService,
    private spinners: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    public fontService: FontService,
  ) { }

  ngOnInit(): void {
    this.spinners.hide();
    if(!this.sessionStorageService.retrieve('auth')) {
      this.localStorageService.clear();
    }
    // this.loadCaptcha();
    // if already authenticated then navigate to home page
  }
  loadCaptcha() {
    this.spinner = true;
    this.loginForm.controls['captcha'].setValue('');
    this.loginService.getCapthcaImage().subscribe(
      (res: any) => {
        this.spinner = false;
        this.captchaDetails = JSON.parse(res);
        let objectURL = 'data:image/jpeg;base64,' + this.captchaDetails.captchaImage;
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.isMaintenance = false;
      },
      (onError) => {
        this.spinner = false;
      }
    );
  }
  startTimer() {
    clearInterval(this.timer);
    this.otpTimer = 120;

    this.timer = setInterval(() => {
      this.otpTimer--;

      if (this.otpTimer === 0) {
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    if (this.reSendCount < 6) {
      this.enableResendButton = true;
      this.reSendCount = this.reSendCount + 1;
    }
    else {
      this.logout();
      this.showOtp = false;
      this.loginForm.reset();
      this.otpError = null;
      this.reSendCount = 1;
      this.otpForm.controls['otp'].setValue('');
    }
    clearInterval(this.timer);
  }

  ngAfterViewInit(): void {
    // this.username.nativeElement.focus();
  }
  navToForgotPassword() {
    if (this.loginForm.get('username').value) {
      this.router.navigate(['/ForgotPassword'], {
        queryParams: { username: btoa(this.loginForm.get('username')!.value) },
      });
    }
    else {
      this.router.navigate(['/ForgotPassword']);
    }
  }
  navToResetPassword() {
    if (this.loginForm.get('username').value) {
      this.router.navigate(['/ResetPassword'], {
        queryParams: { username: btoa(this.loginForm.get('username')!.value) },
      });
    }
    else {
      this.router.navigate(['/ResetPassword']);
    }
  }

  loginOtp(): void {
    if (!this.otpForm.get('otp')!.value) {
      this.otpError = 'Invalid Otp';
      return;
    }
    this.loginService.otpvalidate(this.otpId, this.otpForm.get('otp')!.value).subscribe(
      (res) => {
        console.log('res', res);
        if (res.body.isOtpVerified === true) {
          this.sessionStorageService.store('profileName', res.body.profileName);
          this.sessionStorageService.store('auth', true);
          if (this.passwordReset == "Yes") {
            this.navToResetPassword();
          }
          else {
            if (this.roleCode == 'DA') {
              this.router.navigate(['/profile']);
            } else {
              this.router.navigateByUrl('landing-page');
            
            }
          }

        } else {
          this.sessionStorageService.store('auth', false);
          this.otpError = 'Invalid Otp';
        }

      },
      (onError) => {
        this.otpForm.reset();
        this.sessionStorageService.store('auth', false);
      }
    );
  }
  login(): void {
    this.inValidCaptcha = false;
    this.inValidUser = false;
    this.captchaExpireError = null;
    this.inValidRequestError = null;
    if(this.showLogout) {
      return;
    }
    if (!this.loginForm.get('username')!.value || !this.loginForm.get('password')!.value) {
      this.inValidUser = true;
      return;
    }
    // if (!this.loginForm.get('captcha')!.value) {
    //   this.inValidCaptcha = true;
    //   return;
    // }
    // if (this.captchaDetails) {
      // this.btnSpinner = true;
      this.loginService.login({
        username: this.loginForm.get('username')!.value,
        password: this.loginForm.get('password')!.value,
        rememberMe: true,
      })
        .subscribe(
          (res) => {
            this.btnSpinner = false;
            this.passwordReset = res.resetPassword;
            this.profileStatus = res.profileActive;
            this.roleCode = res.userRoleCode;
            this.otpId = res.otpId;
            this.sessionStorageService.store('levelMaster',res.levelMaster);
            this.sessionStorageService.store('IA',res.implementingAgency);
            this.sessionStorageService.store('finYear',res.finYear);
            if (this.profileStatus == 'Yes' && this.roleCode != 'DM' && this.roleCode != 'BM' && this.roleCode != 'SM' && this.roleCode != 'TM') {
              this.mobileNo = '******' + res.userMobileNumber;
              this.sessionStorageService.store('auth', false);
              this.startTimer();
              this.showOtp = true;
            }
            else {
              this.sessionStorageService.store('auth', true);
              this.showOtp = false;
              if (this.passwordReset == "Yes") {
                this.navToResetPassword();
              }
              else {
                this.router.navigateByUrl('landing-page');
      
              }
            }
          },
          (onError) => {
            
            this.btnSpinner = false;
            if (onError.error.errorCode === "CAPTCHA_EXPIRED") {
              this.loginForm.reset();
              this.captchaExpireError = onError.error.message;
            }
            else if (onError.error.errorCode === "CAPTCHA_INVALID") {
              this.loginForm.reset();
              this.inValidCaptcha = true;

            }
            else if (onError.error.errorCode === "UNAUTHORIZED_USER") {
              this.loginForm.reset();
              this.inValidUser = true;
            }
            else if (onError.error.errorCode === "INVALID_REQUEST") {
              console.log('error',onError);
              this.inValidRequestError = onError.error.message;
              this.showLogout = true;
                this.userName = this.loginForm.get('username')!.value;
              // if(this.inValidRequestError === "This user has already logged in from another device. Kindly log out in that device to log in here.") {
              //   this.showLogout = true;
              //   this.userName = this.loginForm.get('username')!.value;
              // }
            }
            else if (onError.status === 400) {
              this.loginForm.reset();
              this.captchaExpireError = onError.error.message;
            }
          }
        );
    // }
  }

  getUserOtp(resend?) {
    this.loginService.reSendOtp().subscribe(
      (res) => {
        this.otpId = res;
        if (resend) {
          this.enableResendButton = false;
          this.startTimer();
        }
        else {
          this.showOtp = true;
        }
      },
      (onError) => { }
    );
  }

  logout(): void {
    this.loginService.stopRefreshTokenTimer();
    this.navbarService.logout().subscribe(
      (res: HttpResponse<any>) => {
        this.localStorageService.clear();
        this.sessionStorageService.clear();
      },
      (err) => { }
    );

  }
  navToLogin() {
    this.logout();
    this.showOtp = false;
    this.loginForm.reset();
  }

  //   async encrypt(value: string){
  //     // const publicKeyText = await this.loginService.loadPemFile("/assets/public_key.pem");
  //     // const publicKey = forge.pki.publicKeyFromPem(publicKeyText);
  //     // const encryptedValue = publicKey.encrypt("hello", 'RSA-OAEP');
  //     // const encryptedValueBase64 = forge.util.encode64(encryptedValue);

  //     const encryptRsa = new EncryptRsa();
  // const publicKeyText = await this.loginService.loadPemFile('/assets/public_key.pem');
  // const encryptedText = encryptRsa.encryptStringWithRsaPublicKey({
  //   text: 'hello world',
  //   publicKey,
  // });

  //   }

  // async loadPemFile() {
  //   const pemContent = await this.loginService.loadPemFile("/assets/mypublickey.der");
  //   const data = 'my secret data';
  //   const ciphertext = CryptoJS.AES.encrypt('my secret data', pemContent, {
  //     mode: CryptoJS.mode.ECB,
  //     padding: CryptoJS.pad.Pkcs7
  //   }).toString();
  //   // const encrypted = CryptoJS.AES.encrypt(data, pemContent);
  //   // const rsa = Forge.pki.publicKeyFromPem(pemContent);
  //   // const publicKey = window.btoa(rsa.encrypt('stateadmin1'));
  // }
  //   encryptData(data) {
  //     this.loadPemFile()

  //     // const plaintext = 'This is my plaintext data';
  //     // const paddedPlaintext = pad.pkcs7.encrypt(enc.Utf8.parse(plaintext), {
  //     //   blockSize: 16,
  //     // });
  //     // const encrypted = AES.encrypt(paddedPlaintext, environment.encryption_Key, {
  //     //   iv: iv,
  //     // });
  //     // const ciphertext = encrypted.ciphertext.toString(enc.Base64);
  //   }
  // encrypt(text: string){

  //   const message = 'Hello, world!';
  //   const key = 'secret key 123';
  //   const encryptedMessage = CryptoJS.AES.encrypt(message, environment.encryption_Key).toString();

  // }

  forceLogout() {
    this.confirmationService.confirm({
      message:
       'Are you sure you want to Log Out this User  ' +
      this.userName,
      accept: () => {
        this.spinners.show();
        this.loginService.forceLogout(this.userName).subscribe(
      (res) => {
        console.log('saveData',res)
        this.notificationService.alertSuccess('Log Out Successfully', '');
        this.showLogout = false;
        this.spinners.hide();
        this.loginForm.reset();
        
      },
      (err) => {
        this.showLogout = false
        this.loginForm.reset();
        this.spinners.hide();
      }
    );
        
      },
      reject: () => {
        this.showLogout = false
        this.loginForm.reset();
      },
    });
  }
}
