import { AdminUserDtoCreatedby2, AdminUserDtoUpdatedBy2, LevelTypeDto } from '../limit-set/limit-set';
import { LevelMaster } from './../level-master/level-master';
export class ComponentType {
    id:number
    name:string
    levelTypeDto:LevelMaster
    parentId:number
  code: string;
  componentCode: string;
 
   
}

export class ComponentDto {
    departmentId:number
    schemeId:number
    id: number
    name: string
    levelTypeDto: LevelTypeDto
    parentId: number
    createdOn: string
    updatedOn: string
    adminUserDtoCreatedBy: number
    adminUserDtoUpdatedBy: number
  }