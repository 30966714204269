<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        {{headingText}}
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col">
                        <div class="card p-3">
                            <div class="row voucherfont">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="payments.add.voucher.generation.name">
                                    Add Voucher Generation
                                </h6>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.voucher.number.name" | translate }}:<br />{{ voucherGenerationDto?.deductionTransaction?.voucherNumber}}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "date.name" | translate }}:<br />{{ date | date:'dd/MM/yyyy'}}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "implementing.agency.name" | translate }}:<br />{{ this.voucherGenerationDto.deductionTransaction?.implementingAgency?.name }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.gst.number.name" | translate }}:<br />{{ this.voucherGenerationDto.deductionTransaction?.implementingAgency?.gstNo }}
                                    </p>
                                </div>

                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.department.name" | translate }}:<br />{{ departmentName }}
                                    </p>
                                </div>

                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.scheme.name" | translate }}:<br />{{ schemeName }}
                                    </p>
                                </div>

                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.level.type.name" | translate }}:<br />{{ levelMasterDto.name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="month">Month</label><br/>
                            <p-calendar [(ngModel)]="month" view="month" styleClass="datepicker" dateFormat="mm/yy" (ngModelChange)="loadStatutoryBillDescription(1)" [disabled]="voucherGenerationDto.deductionTransaction.id" [ngClass]="{ 'is-invalid': ngSelectInvalid && !month}"></p-calendar>
                        </div>
                        <div *ngIf="month && voucherGenerationDto?.deductionTransaction?.deductionType !== 'FD' && voucherGenerationDto?.deductionTransaction?.deductionType !== 'SD'" class="col-md-3 col-sm-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for=" statutoryType">Deduction Type</label>
                            <ng-select id="statutoryType" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [items]="deductionList" [(ngModel)]="voucherGenerationDto.deductionTransaction.deductionType" (change)="loadStatutoryBillDescription(1)"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !voucherGenerationDto.deductionTransaction.deductionType}" [disabled]="voucherGenerationDto.deductionTransaction.id">
                            </ng-select>
                        </div>
                        <div *ngIf="month && voucherGenerationDto?.deductionTransaction?.deductionType === 'SD'" class="col-md-7">
                            <div class="row">
                                <div class="col">
                                    <p-radioButton name="vendorOption" [disabled]="readonly" value="selectOption" [(ngModel)]="vendorSearchOption" label="Search by Name"></p-radioButton>
                                </div>
                                <div class="col">
                                    <p-radioButton name="vendorOption" [disabled]="readonly" value="accnoSearchOption" [(ngModel)]="vendorSearchOption" label="Search by Account Number"></p-radioButton>
                                </div>
                                <div class="col">
                                    <p-radioButton name="vendorOption" [disabled]="readonly" value="unIdSearchOption" [(ngModel)]="vendorSearchOption" label="Search by Unique Id"></p-radioButton>
                                </div>

                                <div *ngIf="vendorSearchOption =='unIdSearchOption'">
                                    <span class="p-inputgroup">
                                <input type="text" class="form-control search_input mt-1"  maxlength="20" [(ngModel)]="uniqueId" name="uniqueId" autocomplete="off"
                                    id="uniqueId" [readonly]="readonly" />
                                <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-text" [disabled]="readonly" (click)="vendorFilterByAccount('uniqueNo')" ></button>
                                </span>
                                    <!-- [ngClass]="{ 'is-invalid': !accountNo }" -->
                                </div>
                                <div *ngIf="vendorSearchOption =='accnoSearchOption'">
                                    <span class="p-inputgroup">
                                <input type="text" class="form-control search_input mt-1" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="20" [(ngModel)]="accountNo" name="accountNo" autocomplete="off"
                                    id="accountNo" [readonly]="readonly"  />
                                <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-text" [disabled]="readonly" (click)="vendorFilterByAccount('AccountNo')" ></button>
                                </span>
                                    <!-- [ngClass]="{ 'is-invalid': !accountNo }" -->
                                </div>

                            </div>
                            <div class="row mt-3" *ngIf="vendorSearchOption == 'selectOption'">
                                <ng-select id="scheme" [disabled]="readonly || loadingMore|| !vendorSearchOption" [(ngModel)]="this.voucherGenerationDto?.deductionTransaction.commercialVendor" (change)="loadStatutoryBillDescription(1)" [items]="vendorList" bindLabel="name" appearance="outline"
                                    [searchable]="true" [closeOnSelect]="true" [clearable]="false" (search)="search($event)" class="form-control dropdown-width" [ngClass]="{
                                'is-invalid': ngSelectInvalid && !voucherGenerationDto?.deductionTransaction.commercialVendor
                              }" (scrollToEnd)="onScrollToEnd()">
                                    <ng-template ng-header-tmp>
                                        <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-text" [disabled]="readonly  || !vendorSearchOption" (click)="vendorFilter()"></button>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4" *ngIf="voucherGenerationDto.deductionTransaction.deductionType !== 'SD' && month">
                        <div class="col-md-2 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                Account Number:<br />{{ this.voucherGenerationDto?.deductionTransaction?.deductionAccount?.accountNumber}}
                            </p>
                        </div>
                        <div class="col-md-2 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                Account Name:<br />{{ this.voucherGenerationDto?.deductionTransaction?.deductionAccount?.bankAccountName}}
                            </p>
                        </div>
                        <div class="col-md-2 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                Bank Name:<br />{{ this.voucherGenerationDto?.deductionTransaction?.deductionAccount?.bankName }}
                            </p>
                        </div>
                        <div class="col-md-2 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                Bank Branch:<br />{{ this.voucherGenerationDto?.deductionTransaction?.deductionAccount?.bankBranch}}
                            </p>
                        </div>
                        <div class="col-md-2 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                Bank IFSC:<br />{{ this.voucherGenerationDto?.deductionTransaction?.deductionAccount?.ifscCode }}
                            </p>
                        </div>
                    </div>
                    <div class="row mt-4" *ngIf="voucherGenerationDto.deductionTransaction.deductionType === 'SD' && month">
                        <div class="col-md-2 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                Account Number:<br />{{ this.voucherGenerationDto?.deductionTransaction?.commercialVendor?.accountNumber}}
                            </p>
                        </div>
                        <div class="col-md-2 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                Account Name:<br />{{ this.voucherGenerationDto?.deductionTransaction?.commercialVendor?.bankAccName}}
                            </p>
                        </div>
                        <div class="col-md-2 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                Bank Name:<br />{{ this.voucherGenerationDto?.deductionTransaction?.commercialVendor?.bankName }}
                            </p>
                        </div>
                        <div class="col-md-2 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                Bank Branch:<br />{{ this.voucherGenerationDto?.deductionTransaction?.commercialVendor?.bankBranch}}
                            </p>
                        </div>
                        <div class="col-md-2 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                Bank IFSC:<br />{{ this.voucherGenerationDto?.deductionTransaction?.commercialVendor?.ifscCode }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="voucherGenerationDto.deductionTransaction.deductionType && month && (this.voucherGenerationDto.deductionTransaction.deductionAccount || voucherGenerationDto.deductionTransaction.commercialVendor)" class="row">
            <div class="col">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12 mt-2">
                            <p-table *ngIf="!voucherGenerationDto.deductionTransaction.id" [value]="deductionVoucher" [paginator]="false" [rows]="20" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [rowsPerPageOptions]="[10, 25, 50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th *ngIf="deductionVoucher.length !== 0">Check Box<br>
                                            <p-checkbox (onChange)="selectAll()" [(ngModel)]="isSelectAll" binary="true"></p-checkbox>
                                        </th>
                                        <th>S.No</th>
                                        <th style="text-align: center;">Queue No</th>
                                        <th>Voucher No</th>
                                        <th *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">Vendor Name</th>
                                        <th *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">Vendor Account Number</th>
                                        <th *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">GST Number</th>
                                        <th *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">PAN Number</th>
                                        <th>Deduction%</th>
                                        <th>Deduction Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-index="rowIndex" let-list>
                                    <tr>
                                        <td>
                                            <p-checkbox (onChange)="selectDeduction(list)" [(ngModel)]="list.isSelect" binary="true"></p-checkbox>
                                        </td>
                                        <td>{{index+1}}</td>
                                        <td>{{ list.queueNo }}</td>
                                        <td style="text-align: center;">{{ list.voucherNo}}</td>
                                        <td *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">{{ list.vendorName}}</td>
                                        <td *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">{{list.vendorAccountNumber}}</td>
                                        <td *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">{{ list.gstNo}}</td>
                                        <td *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">{{list.panNo}}</td>
                                        <td>{{list.deductionPercentage}}</td>
                                        <td>{{list.deductionAmount}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>

                            <p-table *ngIf="voucherGenerationDto.deductionTransaction.id" [value]="voucherGenerationDto.deductionBillDescriptionList" [paginator]="false" [rows]="20" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [rowsPerPageOptions]="[10, 25, 50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>S.No</th>
                                        <td>Queue No</td>
                                        <th>Voucher No</th>
                                        <th *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">Vendor Name</th>
                                        <th *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">Vendor Account Number</th>
                                        <th *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">GST Number</th>
                                        <th *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">PAN Number</th>
                                        <th>Deduction%</th>
                                        <th>Deduction Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-index="rowIndex" let-list>
                                    <tr>
                                        <td>{{index+1}}</td>
                                        <td>{{ list.voucherNumber }}</td>
                                        <td>{{list?.permanentVoucherNumber}}</td>
                                        <td *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">{{ list.vendorName}}</td>
                                        <td *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">{{list.vendorAccountNumber}}</td>
                                        <td *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">{{ list.vendorGstNumber}}</td>
                                        <td *ngIf="this.voucherGenerationDto.deductionTransaction.deductionType !== 'SD'">{{list.vendorPanNumber}}</td>
                                        <td>{{list.deductionPercentage}}</td>
                                        <td>{{list.deductionAmount}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>
                    </div>
                    <div *ngIf="voucherGenerationDto.deductionTransaction.deductionType && month" class="row mt-3">
                        <div class="col-lg-6">
                            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label
                                ><br />
                                <textarea
                                  id="w3review"
                                  name="w3review"
                                  rows="3"
                                  [(ngModel)]="voucherGenerationDto.deductionTransaction.remarks"
                                  cols="100"
                                  [disabled]="enableSignButton || readonly || enableView"
                                  [ngClass]="{
                                    'is-invalid':
                                      ngSelectInvalid &&
                                      !voucherGenerationDto.deductionTransaction.remarks
                                  }"
                                >
                                </textarea>
                        </div>
                        <div class="col-lg-4">
                           
                        </div>
                        <div class="col-lg-2 pt-lg-0 pt-xs-2 pt-sm-2">
                            <table class="snippet">
                              <tr
                                *ngIf="
                                  totalAmount !== 0 && !voucherGenerationDto.deductionTransaction.id
                                "
                              >
                                <td [ngClass]="fontService.labelClass">
                                  Total Amount :
                                </td>
                                <td style="width: 110px; text-align: right">
                                  {{
                                    totalAmount
                                    | currency:'INR':'symbol':'1.2-2':'en-IN' || "Nill"
                                  }}
                                </td>
                              </tr>
                              <tr
                                *ngIf="
                                voucherGenerationDto.deductionTransaction.transactionAmount !== 0
                                "
                              >
                                <td [ngClass]="fontService.labelClass">{{ "payments.amount.transaction.name" | translate }}:</td>
                                <td style="width: 110px; text-align: right">
                                  {{
                                    voucherGenerationDto.deductionTransaction.transactionAmount
                                    | currency:'INR':'symbol':'1.2-2':'en-IN'
                                  }}
                                </td>
                            </tr>
                            </table>
                        </div>
                    </div>
                    <p-toolbar *ngIf="month && !enableRevertButton && !this.enableView">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button  *ngIf="!enableSignButton" pButton pRipple class="p-button-success custom-button margin"
                                (click)="genVocher()" styleClass="button">
                                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.generatevoucher"></span>
                            </button>
                            <button  *ngIf="enableSignButton" class="p-button-success custom-button margin" 
                                (click)="submitForm()">{{signButtonName}}</button>
                        </div>
                    </p-toolbar>
                    <p-toolbar *ngIf="month && enableRevertButton && !this.enableView">
                        <div class="p-toolbar-group-start"></div>
                        <div *ngIf="!enableSignButton && !voucherGenerationDto?.deductionTransaction?.signaturePresident" class="p-toolbar-group-end">
                            <button *ngIf="enableApproved" pButton pRipple (click)="genVocher()" class="p-button-success custom-button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.approvebutton"></span>
              </button>
                            <button pButton pRipple (click)="reject()" class="p-button-danger custom-button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.rejectbutton"></span>
              </button>
                        </div>
                        <button *ngIf="enableSignButton && !voucherGenerationDto.deductionTransaction.signaturePresident" class="p-button-success custom-button margin" 
            (click)="submitForm()">{{signButtonName}}</button>
                    </p-toolbar>
                    <p-toolbar *ngIf="voucherGenerationDto.deductionTransaction.signaturePresident">
                      <div class="p-toolbar-group-start">
                          <p *ngIf="this.voucherGenerationDto?.deductionTransaction?.transactionStatus === 'Failed'" style="font-size: 18px;color:red;font-weight:700;">Payment Has Been {{this.voucherGenerationDto?.deductionTransaction?.transactionStatus}}</p>
                          <p *ngIf="this.voucherGenerationDto?.deductionTransaction?.transactionStatus === 'Success'" style="font-size: 18px;color:green;font-weight:700;">Payment Has Been {{this.voucherGenerationDto?.deductionTransaction?.transactionStatus}}</p>
                      </div>
                      <button *ngIf="voucherGenerationDto.deductionTransaction.signaturePresident && roleCode.role.code !== 'DAD'  && voucherGenerationDto?.deductionTransaction?.transactionStatus !== 'Failed'" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
                  </p-toolbar>
                </div>
            </div>

            

        </div>



    </div>

    <p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Payment Alert Box
        </p-header>
        <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
        <ng-template pTemplate="footer">
            <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false">
                        <span class="button-label" [ngClass]="fontService.buttonClass" label="Ok"
                            (click)="redirect()">Ok</span>
                    </button>
        </ng-template>
    </p-dialog>