<div class="container">
    <div class="header">
        <div class="row">
            <div class="col-11">
                <img class="udai-logo" src="/./assets/img/uidai.png" alt="Image">
            </div>
            <div class="col-1">
                <img class="aadhar-logo" src="/./assets/img/aadhaar.png" alt="Image">
            </div>
        </div>
        <div class="divder mt-3"></div>
    </div>
    <div class="tnpass-logo-section mt-2">
        <div class="row">
            <div class="col-3"></div>
            <div class="col-4">
                <div class="row">
                    <div class="col-7">
                        <img class="tnpass-logo" src="/./assets/img/ib-rdp-tng-logo.svg" alt="Image">
                    </div>
                    <div class="col-4 tnpass-text">
                        TNPASS
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mt-4 text-center" style="width: 750px;">
        <div class="card-header aadhar-verfication-text">
            <h4>Aadhaar Verification</h4>
        </div>
        <div class="card-body mt-3">
            <form #aadharForm="ngForm">
                <!-- Aadhar Number Field -->
                <div class="form-group input-text">
                    <label for="aadharNumber">Aadhaar Number</label>
                    <div class="input-group">
                        <span class="input-group-text">
                <i class="bi bi-person-badge"></i>
              </span>
                        <input [type]="hide ? 'password' : 'text'" class="form-control" id="aadharNumber" name="aadharNumber" required minlength="12" maxlength="12" [(ngModel)]="aadharNumber" [disabled]="disableAadhaar" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                            placeholder="Enter Your Aadhar Number" />
                        <span class="input-group-text">
                            <i class="pi pi-eye eye" *ngIf="hide" (click)="hide=false"></i>
                            <i class="pi pi-eye-slash eye" *ngIf="!hide" (click)="hide=true"></i>
                          </span>
                    </div>
                    <div *ngIf="aadhaarError" class="text-danger">
                        <small [ngClass]="fontService.smallClass" jhiTranslate="error.validaadhar">Please enter a valid Aadhaar
        Number</small>
                    </div>
                </div>

                <!-- Aadhar OTP Field -->
                <div class="form-group mt-3 input-text">
                    <label for="aadharOtp">Aadhaar OTP</label>
                    <div class="input-group">
                        <span class="input-group-text">
                <i class="bi bi-key"></i>
              </span>
                        <input type="text" class="form-control" id="aadharOtp" name="aadharOtp" [(ngModel)]="aadharOtp" placeholder="Enter Your Aadhar OTP" />
                    </div>
                </div>

            </form>
        </div>
        <div class="row mt-2">
            <div class="col-6">
                <p *ngIf="otpTimer !== 0" style="color: green;font-size:12px;margin-right: 35px;margin-left: 10px;">OTP has been sent to Aadhaar Registered mobile number</p>
            </div>
            <div class="col-6">
                <p style="color: blue;font-size:12px;text-align: end;margin-right: 10px;">You have {{sessionStorage.retrieve('otpAttempts')}} attempt(s) left to regenerate OTP</p>
            </div>
        </div>
        <!-- Buttons -->
        <div class="row mt-1">
            <div class="col-4">
                <button *ngIf="!enableSubmitButton && !resendButton && !disableOtp" type="button" class="btn btn-success" (click)="getOtp()" [disabled]="!aadharNumber">Get OTP</button>
                <button *ngIf="enableSubmitButton" type="button" class="btn btn-success" (click)="submit()" [disabled]="!aadharNumber">Submit</button>
                <button type="button" class="btn btn-secondary" (click)="redirect()">Cancel</button>
            </div>
            <div class="col-6">
                <p style="color: green;font-size:11px;padding-top:10px" *ngIf="otpTimer !== 0 && !disableOtp">Kindly click "Resend OTP" button after {{ otpTimer }} seconds....</p>
            </div>
            <div class="col-2">
                <button *ngIf="resendButton && !disableOtp" type="button" class="btn btn-success" (click)="getOtp(true)" [disabled]="sendOtp">Resend</button>
            </div>
        </div>
    </div>
</div>
<footer>
    <img style="width: 100%;" src="/./assets/img/footer.png" alt="Image">
</footer>