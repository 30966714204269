import { NotificationService } from './../../alert/notification.service';
import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { FlaggingDto, VoucherFlagging } from '../flagging-page/flagging.model';
import { FlagingService } from '../flagging-page/flagging.service';
import { HttpResponse } from '@angular/common/http';
import { LocalStorageService, SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { filterQuery } from 'src/app/shared/common.model';

@Component({
  selector: 'app-flagging-list',
  templateUrl: './flagging-list.component.html',
  styleUrl: './flagging-list.component.scss'
})
export class FlaggingListComponent {
  searchResult: SearchResult<VoucherFlagging> =
    new SearchResult<VoucherFlagging>();
  cols: any;
  filterProps: any = [];
  itemsPerPage: number = 10;
  page: number;
  enableFlagVoucher: boolean;
  flagging: FlaggingDto;
  issueType: any;
  roleCode: any;
  disableFlagButton: boolean;
  constructor(
    public fontService: FontService,
    private spinner: NgxSpinnerService,
    private flagingService: FlagingService,
    private sessionStorage: SessionStorageService,
    protected notificationService: NotificationService,
  ) { }
  ngOnInit(): void {
    this.loadPage(1);
    

  }

  loadPage(page) {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.roleCode = this.sessionStorage.retrieve('roleCode');
    if (this.sessionStorage.retrieve('roleCode') === 'VMAKER' || this.sessionStorage.retrieve('roleCode') === 'V1' || this.sessionStorage.retrieve('roleCode') === 'V2') {
      this.cols = [
        {
          field: 'villageName',
          header: 'Village Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'paymentMethod',
          header: 'Payment Category',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'voucherNo',
          header: 'Voucher No',
          jhiTranslate: 'tableHead.voucher.no',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'amount',
          header: 'Amount',
          isFilterable: true,
          isSelectcolumn: true,
          type: 'amount'
        },
        {
          field: 'issueType',
          header: 'Issue Type',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'description',
          header: 'Description',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'status',
          header: 'Status',
          isFilterable: false,
          isSelectcolumn: true,
        },
  
        {
          field: 'createdOn',
          header: 'Created On',
          isFilterable: true,
          isSelectcolumn: true,
          type: 'date',
        },
      ];
      let query = new filterQuery();
      query.key = 'levelMasterId';
      query.operation = 'equals';
      query.value = this.sessionStorage.retrieve('levelmasterId');
      this.filterProps.push(query);
    }
    else if (this.sessionStorage.retrieve('roleCode') === 'DA') {
      this.cols = [
      
        {
          field: 'blockName',
          header: 'Block Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'villageName',
          header: 'Village Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'paymentMethod',
          header: 'Payment Category',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'voucherNo',
          header: 'Voucher No',
          jhiTranslate: 'tableHead.voucher.no',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'amount',
          header: 'Amount',
          isFilterable: true,
          isSelectcolumn: true,
          type: 'amount'
        },
        {
          field: 'issueType',
          header: 'Issue Type',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'description',
          header: 'Description',
          isFilterable: true,
          isSelectcolumn: true,
        },
  
        {
          field: 'createdOn',
          header: 'Created On',
          isFilterable: true,
          isSelectcolumn: true,
          type: 'date',
        },
      ];
      let query = new filterQuery();
      query.key = 'districtId';
      query.operation = 'equals';
      query.value = this.sessionStorage.retrieve('levelmasterId');
      this.filterProps.push(query);
      query = new filterQuery();
      query.key = 'activeFlag';
      query.operation = 'equals';
      query.value = 'YES';
      this.filterProps.push(query);
    }
    else {
      this.cols = [
        {
          field: 'districtName',
          header: 'District Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'blockName',
          header: 'Block Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'villageName',
          header: 'Village Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'paymentMethod',
          header: 'Payment Category',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'voucherNo',
          header: 'Voucher No',
          jhiTranslate: 'tableHead.voucher.no',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'amount',
          header: 'Amount',
          isFilterable: true,
          isSelectcolumn: true,
          type: 'amount'
        },
        {
          field: 'issueType',
          header: 'Issue Type',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'description',
          header: 'Description',
          isFilterable: true,
          isSelectcolumn: true,
        },
  
        {
          field: 'createdOn',
          header: 'Created On',
          isFilterable: true,
          isSelectcolumn: true,
          type: 'date',
        },
      ];
      let query = new filterQuery();
      query.key = 'activeFlag';
      query.operation = 'equals';
      query.value = 'YES';
      this.filterProps.push(query);
    }
    this.flagingService.filter(this.filterProps, Query).subscribe(
      (res: HttpResponse<VoucherFlagging[]>) => {
        this.searchResult.total = Number(res.headers.get('X-Total-Count'));;
        this.spinner.hide();
        res.body.forEach(element => {
          if(element.activeFlag === 'No') {
            element.status = 'De Flagged'
          }
          switch (element.paymentCategory?.toUpperCase()) {
            case 'NO':
              element.paymentMethod = 'Commerical';
              break;
            case 'YES':
              element.paymentMethod = 'Personal';
              break;
            case 'E':
              element.paymentMethod = 'Employee';
              break;
            case 'ER':
              element.paymentMethod = 'Elected Represenative';
              break;
            case 'BER':
              element.paymentMethod = 'Bulk Elected Represenative';
              break;
            case 'BE':
              element.paymentMethod = 'Bulk Employee';
              break;
            case 'TWAD':
              element.paymentMethod = 'TWAD';
              break;
            case 'T':
              element.paymentMethod = 'TANGEDCO';
              break;
            case 'BP':
              element.paymentMethod = 'Beneficiary';
              break;
            case 'TK':
              element.paymentMethod = 'Thooimai Kavalar';
              break;
            case 'OFP':
              element.paymentMethod = 'Other Funds Payment';
              break;
            case 'DT':
              element.paymentMethod = 'Deduction (' + element?.deductionType?.toUpperCase() + ')';
              break;
          }
        });
        this.searchResult.items = res.body ?? [];
        this.searchResult = { ...this.searchResult };
        console.log('res', res);


      },
      (err) => {

      }
    );
  }

  async onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onSelectRow($event) {
    this.disableFlagButton = false;
    if ($event.type === 'view') { 
      this.disableFlagButton = true;
    }
    this.flagingService.getFlagById($event.value.id).subscribe(
      (res: HttpResponse<FlaggingDto>) => {
        this.flagging = res.body;
        this.enableFlagVoucher = true;
      },
      () => {

      }
    );

  }

  deFlag() {
    this.flagingService.deFlag(this.flagging.voucherFlagging.id).subscribe(
      (res) => {
        this.notificationService.alertSuccess(res.body,'');
        this.enableFlagVoucher = false;
        this.loadPage(1);
      },
      () => {

      }
    );
  }


}
