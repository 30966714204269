import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Component, OnInit } from '@angular/core';
import { PushNotificationService } from '../push_notifcation/push-notification/push-notification.service';
import { HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { FontService } from '../shared/font-service/font.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../alert/notification.service';
import { SigninService } from '../auth/signin/signin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  confirmationText: string;
  navigationSearch
  roleCode: any;

  constructor(
    private pushNotificationService:PushNotificationService,
    private datePipe: DatePipe,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private loginService: SigninService,
    private notificationService: NotificationService,
    private sessionStorage : SessionStorageService,
    private Router: Router,
   
  ) { }

  ngOnInit(): void {
    this.roleCode = this.sessionStorage.retrieve('user')?.role?.code;
    this.spinner.show();
    this.pushNotificationService.getPushNotify().subscribe(
      (res: HttpResponse<any>) => {
        this.spinner.hide();
       console.log('res',res)
       if(res.body) {
       this.confirmationText = 'The Application Maintainence on '+ this.datePipe.transform(res.body.startTime, 'dd/MMM/yy HH:mm:ss') +   ' to ' + this.datePipe.transform(res.body.endTime, 'dd/MMM/yy HH:mm:ss') +  ' due to ' + res.body.message;
       this.confirmationService.confirm({
        message: this.confirmationText,
        header: 'Notification',
        closeOnEscape:true,
        accept: () => {
          
        },
        reject: () => {}
    });

       }
       console.log('res',this.confirmationText)
      },
      (err) => {
        this.spinner.hide();
        // this.onError();
        
      }
    );
  }

  dynamicRoute = [
    {
      no: '11',
      url: 'home'
    },
    {
      no: '12',
      url: 'Twad-Dashboard'
    },
    {
      no: '13',
      url: 'Tneb-Dashboard'
    },
    {
      no: '21',
      url: 'user'
    },
    {
      no: '22',
      url: 'zonal'
    },
    {
      no: '23',
      url: 'profile'
    },
    {
      no: '31',
      url: 'agency'
    },
    {
      no: '41',
      url: 'personal-vendors'
    },
    {
      no: '42',
      url: 'commercial-vendors'
    },
    {
      no: '43',
      url: 'employee'
    },
    {
      no: '44',
      url: 'electedRepresentativeComponent'
    },
    {
      no: '45',
      url: 'universal-Vendor'
    },
    {
      no: '46',
      url: 'beneficiary-creation'
    }, {
      no: '47',
      url: 'deduction-accounts'
    },
    {
      no: '51',
      url: 'account-sna'
    },
    {
      no: '61',
      url: 'department-list'
    },
    {
      no: '62',
      url: 'scheme-list'
    },
    {
      no: '71',
      url: 'funds'
    },
    {
      no: '72',
      url: 'opening-balance'
    },
    {
      no: '73',
      url: 'opening-balance'
    },
    {
      no: '81',
      url: 'Tangedco-Bill-Details'
    },
    {
      no: '82',
      url: 'Tangedco-Master-Details'
    },
    {
      no: '83',
      url: 'Tangedco-Master-Details'
    },
    {
      no: '84',
      url: 'Tangedco-Master-Approved'
    },
    {
      no: '85',
      url: 'tneb-report'
    },
    {
      no: '86',
      url: 'tneb-menu-configuration'
    },
    {
      no: '87',
      url: 'menu-enable-list'
    },
    {
      no: '91',
      url: 'payment-personal'
    },
    {
      no: '92',
      url: 'payment'
    },
    {
      no: '93',
      url: 'employeePaymentTable'
    },
    {
      no: '94',
      url: 'electedRepresenativePaymentTableComponent'
    },
    {
      no: '95',
      url: 'employee-bulkPay-list'
    },
    {
      no: '96',
      url: 'ER-bulkPay-list'
    },
    {
      no: '97',
      url: 'Twad-List'
    },
    {
      no: '98',
      url: 'Tangedco-Payment-Table'
    },
    {
      no: '99',
      url: 'Tangedco-Payment-Table'
    },
    {
      no:'910',
      url:'swapTable'
    },
    {
      no: '911',
      url: 'beneficiary-payment-list'
    },
    {
      no:'912',
      url:'thooimai-kavalar-payment-list'
    },
    {
      no: '101',
      url: 'twad-master-list'
    },
    {
      no: '111',
      url: 'RejectFailedPayments'
    },
    {
      no: '121',
      url: 'level-type'
    },
    {
      no: '12.2',
      url: 'level-master'
    },
    {
      no: '123',
      url: 'component-type'
    },
    {
      no: '131',
      url: 'limit-set'
    },
    {
      no: '132',
      url: 'limit-translist'
    },
    {
      no: '133',
      url: 'limit-formulalist'
    },
    {
      no: '134',
      url: 'fundExcelUpload'
    },
    {
      no: '141',
      url: 'major-component'
    },
    {
      no: '142',
      url: 'minor-component'
    },
    {
      no: '143',
      url: 'sub-component'
    },
    {
      no: '151',
      url: 'Onboard-Report'
    },
    {
      no: '152',
      url: 'Employee-Onboard-Report'
    },
    {
      no: '153',
      url: 'Payment-Report'
    },
    {
      no: '154',
      url: 'Bulk-Payment-Report'
    },
    {
      no: '155',
      url: 'Voucher-Status-Report'
    },
    {
      no: '156',
      url: 'AccountWise-Fund-Report'
    },
    {
      no: '157',
      url: 'Nil-Total-Balance-Report'
    },
    {
      no: '158',
      url: 'Transaction-Report'
    },
    {
      no: '159',
      url: 'Employee-Count-Report'
    },
    {
      no: '1510',
      url: 'Payment-Not-Done-Report'
    },
    {
      no: '1511',
      url: 'deduction-report'
    },
    {
      no: '103',
      url: 'twad-report'
    },
    {
      no: '1513',
      url: 'employee-transfer-Report'
    },
    {
      no: '1514',
      url: 'voucher-delay-report'
    },
    {
      no: '161',
      url: 'OwnSourceRevenue'
    },
    {
      no: '162',
      url: 'account-statement'
    },
    {
      no: '163',
      url: 'account-balances-statement'
    },
  
    {
      no: '171',
      url: 'Vendor-Transaction-Report'
    },
    {
      no: '172',
      url: 'Payment-Status-Report'
    },
    {
      no: '173',
      url: 'Payment-Trans-Report'
    },
    {
      no: '174',
      url: "Api-Request-Response-Report"
    },
    {
      no: '175',
      url: "IA-Component-Report"
    },
    {
      no: '176',
      url: "IA-Component-Trans-Report"
    },
    {
      no: '177',
      url: 'Account-Validation-Log'
    },
    {
      no: '178',
      url: 'DSC-Report'
    },
    {
      no: '181',
      url: 'employee-transfer'
    },
    {
      no: '191',
      url: 'push-notification'
    },
    {
      no:'1515',
      url:'deduction-voucher-report'
    },
    {
      no: '104',
      url: 'twad-account-summary'
    },
    {
      no: '105',
      url: 'Office-Master'
    },

    
  ]

  

  keyPressNumbersDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      var charCode = event.which ? event.which : event.keyCode;
      // Only Numbers 0-9
      let text = event.target.value
      if (text.includes('.')) {
        let decimals = text.split('.');
        if (decimals[1].length >= 2) {
          event.preventDefault();
          return false;
        } else {
          return true;
        }
      }
    }
    return true;
  }
  submitSearch() {
    let availableMenu
    if (this.roleCode == 'SA') {
      availableMenu = ['11', '21', '22', '23', '31','41','42','43','44','45','46','47', '51', '61', '62', '71', '72', '73', '84', '85', '91', '92', '93', '94', '95', '96', '97', '98','911','912', '101', '121', '122', '123', '131', '132', '133','134', '141', '142', '143', '151', '152', '153', '154', '155', '156', '157', '158', '159', '1510', '1512','1513','1514','191','1515','103','105','104']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'VMAKER') {
      availableMenu = ['11','12','13', '23', '91', '92', '93', '94', '95', '96','911','912', '111', '161','162','163','81','82','101','41','42','43','44','46','47','1515']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'VC1' || this.roleCode == 'VC2') {
      availableMenu = ['11','12', '23', '91', '92', '93', '94', '95', '96','911','912', '161','162','163']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'DA') {
      availableMenu = ['11', '12', '21', '23', '83', '84', '101', '151', '152', '153', '154', '155', '156', '157', '158', '1510', '1512', '1514','181']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'SM') {
      availableMenu = ['11', '12', '23', '31', '41', '42', '43', '44', '46', '85', '91', '92', '93', '94', '95', '96', '97', '98','911','912','101', '151', '152', '153', '154', '155', '156', '157', '158', '159', '1510', '1512','1513','103']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'DMAKER') {
      availableMenu = ['11', '12', '23', '91', '92', '93', '94', '95', '96', '97', '98','911','912', '101', '111']
      this.whiteListMenu(availableMenu)
    } else if (this.roleCode == 'DC1' || this.roleCode == 'DC2') {
      availableMenu = ['11', '12', '23', '91', '92', '93', '94', '95', '96', '97', '98','911','912', '101', '111']
      this.whiteListMenu(availableMenu)
    }
   
    else if (this.roleCode == 'TA') {
      availableMenu = ['23','97','101','102','103','104','105']
      this.whiteListMenu(availableMenu)
    }
  }
  whiteListMenu(availableMenu) {
    let findMenu
    let result
    findMenu = availableMenu.filter(res => res == this.navigationSearch)
    if (findMenu.length != 0) {
      result = this.dynamicRoute.filter(res => res.no == findMenu)[0]
      console.log(result)
      this.Router.navigateByUrl(result.url)
    } else {
      this.notificationService.alertError('Page Not Found', '');
    }
  }

  vpax(){
    this.pushNotificationService.vpax().subscribe(
      (res) => {
       
   
      },
      (err) => {
        this.spinner.hide();
        // this.onError();
        
      }
    );
  }
  atom(){
    this.pushNotificationService.atom().subscribe(
      (res) => {
       
   
      },
      (err) => {
        this.spinner.hide();
        // this.onError();
        
      }
    );
  }

  submitForm(){
    this.spinner.show();
        const formElement = document.createElement('form');
        formElement.method = 'GET';
        formElement.action = ' https://vptax.tnrd.tn.gov.in/project/webservices/open_services/sna_services.php';
        let inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.value = "qtwCCJGXx20yW4UTOXcYkkJj9kfcUgOTIMNflp5mXyvlbumdQ8BF2qUiUyCN1rTyJ03xZBlKt/0GhAuv8hl9LQ==:MlNOMjJTa0pHRHlPQVhVVQ==";
        formElement.appendChild(inputElement);
        document.body.appendChild(formElement);
        formElement.submit();
  }
 
}
