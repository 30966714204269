<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="breadcrumb.commercial.name">
                        Commercial Vendors
                    </h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="!(roleCode === 'DM' || roleCode === 'BM' || this.roleCode ==='SM') && currentPath !== '/commercial-vendors-approve'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
          </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
            <div *ngIf="this.currentPath !== '/commercial-vendors-approve'" class="row px-4">
                <div class="col">
                    <label class="form-control-label" for="name" [ngClass]="fontService.labelClass" jhiTranslate="payments.viewTable.vendorName">Vendor Name</label>
                    <input type="text" class="form-control" name="name" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="vendorName" id="name" />
                </div>
                <div class="col">
                    <label class="form-control-label" for="name" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno">Account No</label>
                    <input type="text" class="form-control" name="name" maxlength="60" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="accountNo" id="name" />
                </div>
                <div class="col">
                    <label class="form-control-label" for="name" [ngClass]="fontService.labelClass" jhiTranslate="tableHead.uniqueId"></label>
                    <input type="text" class="form-control" name="name" maxlength="60" [(ngModel)]="uniqueId" id="name" />
                </div>
                <div *ngIf="roleCode === 'SA'" class="col">
                    <label class="form-control-label" for="name" [ngClass]="fontService.labelClass">Level Type</label>
                    <ng-select id="vendorType" [(ngModel)]="selectedLevelType" [items]="levelType" appearance="outline" [closeOnSelect]="true" (change)="districtDto=null;BlockLevelDto=null;villageLevelDto=null" [clearable]="true" class="form-control">
                    </ng-select>
                </div>
                <div *ngIf="selectedLevelType" class="col">
                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                    <ng-select id="scheme" [(ngModel)]="districtDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
                <div *ngIf="selectedLevelType !== 'District' && selectedLevelType" class="col">
                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.block">Block</label>
                    <ng-select id="scheme" [appendTo]="'body'" [(ngModel)]="BlockLevelDto" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
                <div *ngIf="selectedLevelType !== 'District' && selectedLevelType !== 'Block' && selectedLevelType" class="col">
                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.village">Village</label>
                    <ng-select id="scheme" [appendTo]="'body'" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" [(ngModel)]="villageLevelDto" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                    </ng-select>
                </div>
            </div>
        </ng-template>
    </p-toolbar>
    <p-toolbar styleClass="toolbar">
        <ng-template pTemplate="left">
            <div *ngIf="this.currentPath !== '/commercial-vendors-approve'" class="row px-4">
                <div class="col" style="display: flex;align-items: flex-end;">
                    <button pButton pRipple class="p-button-success custom-button" (click)="this.filterProps = [];loadPage(1)" styleClass="button">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.searchbutton">Search</span>
      </button>
                </div>
                <div class="col mt-3" style="display: flex;align-items: flex-end;">
                    <button pButton pRipple class="p-button-danger custom-button" (click)="vendorName = null;accountNo=null;villageLevelDto=null;BlockLevelDto=null;districtDto=null;selectedLevelType=null;uniqueId=null;this.filterProps=[];loadPage(1)">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.clearbutton">clear</span>
      </button>
                </div>
            </div>
        </ng-template>
    </p-toolbar>
    <div class="row mt-3">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [lazyLoadOnInit]="false" [disableDeleteIcon]="disableDelete" (emitRow)="onSelectRow($event)" [disableEditIcon]="disableEdit" [enableVendorApproved]="currentPath === '/commercial-vendors-approve'"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="commercialVendorsDialog" [style]="{ width: '850px' }" header="User Details" [modal]="true" styleClass="ib-dialog" (onHide)="hideDialog()">
        <p-header [ngClass]="fontService.headingClass">
            {{ "vendor.commercial.add" | translate }}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="vendor.commercial.details">
                                Commercial Vendor Details
                            </h6>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.name" for=" name">Name</label>
                            <input type="text" class="form-control" [(ngModel)]="commercialVendor.name" name="name" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                uppercase [readonly]="edit" style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.name }" id="name" />
                            <div *ngIf="showNameError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{ showNameError }}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.companyName" for=" companyName">Company Name</label>
                            <input type="text" class="form-control" [readonly]="isDelete" name="companyName" autocomplete="off" [(ngModel)]="commercialVendor.companyName" id="companyName" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyName }" />
                            <div *ngIf="showCompanyNameError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.showNameError">{{ showCompanyNameError }}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="vendorType" [ngClass]="fontService.labelClass" jhiTranslate="nav.vendor.vendortype">Vendor Type</label>
                                <ng-select [appendTo]="'.p-dialog'" id="vendorType" [(ngModel)]="commercialVendor.commercialDto" [items]="commercialList" bindLabel="name" appearance="outline" [disabled]="isDelete" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.commercialDto }">
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="registrationNo" [ngClass]="fontService.labelClass" jhiTranslate="nav.vendor.registrationNo">Registration No</label>
                            <input type="text" class="form-control" autocomplete="off" [(ngModel)]="commercialVendor.registrationNo" maxlength="15" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                style="text-transform:uppercase" name="registrationNo" [readonly]="isDelete" id="registrationNo" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.registrationNo }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="mobileNo" [ngClass]="fontService.labelClass" jhiTranslate="vendor.mobile">Mobile No</label>
                            <input class="form-control" [(ngModel)]="commercialVendor.mobile" (input)="validateMobile()" autocomplete="off" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.mobile }" [readonly]="isDelete" maxlength="10" name="mobileNo" id="mobileNo" />
                            <div *ngIf="showMobileError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validmobile">Please enter a valid Mobile
                    Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="email" [ngClass]="fontService.labelClass" jhiTranslate="vendor.email">E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="commercialVendor.email" name="email" [readonly]="isDelete" autocomplete="off" id="email" maxlength="60" onblur="this.value =this.value.trim();" (input)="validateEmail()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.email }"
                            />
                            <div *ngIf="showeEmailError">
                                <small [ngClass]="fontService.smallClass">{{ showeEmailError }}</small>
                            </div>
                            <div *ngIf="emailError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validemail">Please enter a valid Email
                    Address.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="aadhaar" [ngClass]="fontService.labelClass" jhiTranslate="vendor.aadhaarno">Aadhaar Number</label>
                            <input type="text" class="form-control" [(ngModel)]="commercialVendor.aadhaarNo" name="aadhaar" autocomplete="off" id="aadhaar" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" onblur="this.value =this.value.trim();"
                                (input)="validateAadhaar()" [readonly]="isDelete" maxlength="12" />
                            <div *ngIf="showAadhaarNoError">
                                <small [ngClass]="fontService.smallClass">
                    {{ showAadhaarNoError }}

                  </small>
                            </div>

                            <div *ngIf="aadhaarError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validaadhar">Please enter a valid
                    Aadhaar Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="panNo" [ngClass]="fontService.labelClass" jhiTranslate="vendor.panno">PAN No</label>
                            <input type="text" class="form-control" name="panNo" autocomplete="off" [(ngModel)]="commercialVendor.panNo" id="panNo" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                uppercase (input)="validatePan()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.panNo }" [readonly]="isDelete" maxlength="10" />
                            <div *ngIf="showPanError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.panNoUniError">
                    {{ showPanError }}
                  </small>
                            </div>
                            <div *ngIf="panError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpan">Please enter a valid PAN
                    Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for=" gstNo" [ngClass]="fontService.labelClass" jhiTranslate="user.gstNo">GST No</label>
                            <input type="text" class="form-control" name="gstNo" autocomplete="off" [(ngModel)]="commercialVendor.gstNo" id="gstNo" [readonly]="isDelete" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase (input)="validateGst()" maxlength="15" />
                            <div *ngIf="showGstError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.gstUniError">
                    {{ showGstError }}
                  </small>
                            </div>
                            <div *ngIf="gstError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validgst">Please enter a valid GST
                    Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="tanNo" [ngClass]="fontService.labelClass" jhiTranslate="user.tanNo">TAN No</label>
                            <input type="text" name="tanNo" autocomplete="off" class="form-control" [(ngModel)]="commercialVendor.tanNo" [readonly]="isDelete" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                uppercase id="tanNo" (input)="validateTan()" maxlength="10" />
                            <div *ngIf="tanError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validtan">Please enter a valid TAN
                    Number.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="tinNo" [ngClass]="fontService.labelClass" jhiTranslate="user.tinNo">TIN No</label>
                            <input type="text" class="form-control" name="tinNo" autocomplete="off" [(ngModel)]="commercialVendor.tinNo" [readonly]="isDelete" oninput="this.value = this.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                uppercase id="tinNo" (input)="validateTin()" maxlength="11" />
                            <div *ngIf="tinError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validtin">Please enter a valid TIN
                    Number.</small>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-2 mt-3">
                        <div class="col-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="dashboard.parmenent">
                                Permenent Address
                            </h6>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="doorNo" [ngClass]="fontService.labelClass" jhiTranslate="vendor.buildingno">Building No/Door No</label>
                            <input type="text" class="form-control" name="doorNo" autocomplete="off" id="doorNo" [(ngModel)]="commercialVendor.doorNo" (change)="addresschange('check')" maxlength="20" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" [readonly]="isDelete" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.doorNo }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="street" [ngClass]="fontService.labelClass" jhiTranslate="vendor.street">Street</label>
                            <input type="text" class="form-control" name="street" autocomplete="off" id="street" [(ngModel)]="commercialVendor.street" (change)="addresschange('check')" maxlength="50" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" [readonly]="isDelete" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.street }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="landmark" [ngClass]="fontService.labelClass" jhiTranslate="vendor.landmark">Landmark</label>
                            <input type="text" class="form-control" (change)="addresschange('check')" name="landmark" autocomplete="off" [readonly]="isDelete" maxlength="50" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" id="landmark" [(ngModel)]="commercialVendor.area" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="city" [ngClass]="fontService.labelClass" jhiTranslate="vendor.city">City</label>
                            <input type="text" class="form-control" name="city" autocomplete="off" [(ngModel)]="commercialVendor.city" id="city" maxlength="20" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.city }" [readonly]="isDelete" (change)="addresschange('check')" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="district" [ngClass]="fontService.labelClass" jhiTranslate="vendor.district">District</label>
                            <input type="text" class="form-control" name="district" autocomplete="off" [(ngModel)]="commercialVendor.district" id="district" maxlength="20" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" (change)="addresschange('check')" [readonly]="isDelete" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.district }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="state" [ngClass]="fontService.labelClass" jhiTranslate="vendor.state">State</label>
                            <input type="text" class="form-control" name="state" autocomplete="off" [(ngModel)]="commercialVendor.state" [readonly]="isDelete" (change)="addresschange('check')" id="state" maxlength="50" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.state }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="pinCode" [ngClass]="fontService.labelClass" jhiTranslate="vendor.pincode">Pincode</label>
                            <input type="text" class="form-control" name="pinCode" autocomplete="off" id="pinCode" [readonly]="isDelete" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" [(ngModel)]="commercialVendor.pinCode" (input)="validatePin()"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.pinCode }" (change)="addresschange('check')" maxlength="6" />
                            <div *ngIf="pinError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpin">Please enter a valid
                    Pincode.</small>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-2 mt-3">
                        <div class="col-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="dashboard.commercial">
                                Commercial Address
                            </h6>
                        </div>
                        <div class="col-12">
                            <span>
                  <p-checkbox (onChange)="addresschange()" [disabled]="isDelete" [(ngModel)]="checked" binary="true"></p-checkbox>
                  <label style="margin-left: 15px; font-size: 14px" [ngClass]="fontService.labelClass"
                    jhiTranslate="error.sameAs">Same as Permanent Address</label>
                </span>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for=" doorNo" [ngClass]="fontService.labelClass" jhiTranslate="vendor.buildingno">Building No/Door No</label>
                            <input type="text" class="form-control" name="doorNo" autocomplete="off" [(ngModel)]="commercialVendor.companyDoorNo" id="doorNo" maxlength="50" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" [readonly]="isDelete" style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyDoorNo }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.street" for=" street">Street</label>
                            <input type="text" class="form-control" name="street" autocomplete="off" id="street" maxlength="50" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                style="text-transform:uppercase" [readonly]="isDelete" [(ngModel)]="commercialVendor.companyStreet" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyStreet }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.landmark" for=" landmark">Landmark</label>
                            <input type="text" class="form-control" maxlength="50" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase" name="landmark"
                                autocomplete="off" id="landmark" [readonly]="isDelete" [(ngModel)]="commercialVendor.companyArea" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.city" for=" city">City</label>
                            <input type="text" class="form-control" name="city" autocomplete="off" maxlength="50" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                id="city" [(ngModel)]="commercialVendor.companyCity" [readonly]="isDelete" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyCity }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.district" for=" district">District</label>
                            <input type="text" class="form-control" name="district" autocomplete="off" id="district" maxlength="20" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                style="text-transform:uppercase" [(ngModel)]="commercialVendor.companyDistrict" [readonly]="isDelete" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyDistrict }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for=" state" [ngClass]="fontService.labelClass" jhiTranslate="vendor.state">State</label>
                            <input type="text" class="form-control" name="state" autocomplete="off" [(ngModel)]="commercialVendor.companyState" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                style="text-transform:uppercase" id="state" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyState }" [readonly]="isDelete" maxlength="20" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.pincode" for=" pinCode">Pincode</label>
                            <input type="text" class="form-control" name="pinCode" autocomplete="off" [readonly]="isDelete" [(ngModel)]="commercialVendor.companyPinCode" id="pinCode" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.companyPinCode }"
                                maxlength="6" (input)="validateCPin()" />
                            <div *ngIf="cPinError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validpin">Please enter a valid
                    Pincode.</small>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-2 mt-3">
                        <div class="col-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="agency.account.detail.name">
                                Vendor Account Details
                            </h6>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat'|| currentEnvironment==='production')">
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                                <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" (change)="getBankBranchList()" [items]="bankList" [disabled]="readonly" [(ngModel)]="commercialVendor.bankName"
                                    [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.bankName }">
                                </ng-select>

                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat'|| currentEnvironment==='production')">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                            <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="bankBranch" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" (search)="getBankBranchList($event)" [items]="bankBranchList" [disabled]="readonly" [(ngModel)]="bankBranch"
                                (change)="getBankIfsc()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.bankBranch}">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat'|| currentEnvironment==='production')">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                            <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase
                                [readonly]="readonly" [(ngModel)]="commercialVendor.ifscCode" id="ifsc" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.ifscCode }" maxlength="11" (input)="validateIfsc()" (change)="accountDetailsChanged()"
                            />
                            <div *ngIf="ifscError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                    IFSCode.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                            <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase
                                [readonly]="readonly" [(ngModel)]="commercialVendor.ifscCode" id="ifsc" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.ifscCode }" maxlength="11" (input)="validateIfsc()" (change)="accountDetailsChanged()"
                            />
                            <div *ngIf="ifscError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                    IFSCode.</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                            <p [ngClass]="fontService.regularClass" class="mb-0">{{commercialVendor.bankName}}</p>
                        </div>
                        <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat' || currentEnvironment==='production')">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                            <p [ngClass]="fontService.regularClass" class="mb-0">{{commercialVendor.bankBranch}}</p>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno" for=" accountNo">Account Number</label>
                            <input type="text" class="form-control" name="accountNo" autocomplete="off" maxlength="25" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                [readonly]="readonly" [(ngModel)]="commercialVendor.accountNumber" (change)="accountChanged()" id="accountNo" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.accountNumber }" />
                            <div *ngIf="showaccountNoError" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.accountNoUniError">{{ showaccountNoError }}</small>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.account.name" for=" bankAccountName">Bank Account Name As per
                  Passbook</label>
                            <input type="text" class="form-control" name="bankAccountName" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                [readonly]="true" [(ngModel)]="commercialVendor.bankAccName" id="bankAccountName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !commercialVendor.bankAccName }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.micr" for=" micr">MICR</label>
                            <input type="text" class="form-control" name="micr" autocomplete="off" [readonly]="readonly" [(ngModel)]="commercialVendor.micr" maxlength="9" id="micr" />
                        </div>
                        <div class="col-12 justify-content-center">
                            <button pButton pRipple class="p-button-success custom-button" (click)="accountValidation()" styleClass="button" [disabled]="readonly">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.verifyaccount">Verify Account</span>
                </button>
                            <div *ngIf="accountInvalidError" class="text-danger">
                                <small [ngClass]="fontService.smallClass">{{accountInvalidError}}</small>
                            </div>
                            <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                                <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please verify the account</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
        <span class="button-label" [ngClass]="fontService.buttonClass" custom-button
          jhiTranslate="common.cancelbutton"></span>
      </button>
            <button *ngIf="isDelete" pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="deleteVendor()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.delete"></span>
      </button>
            <button *ngIf="!commercialVendor.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
      </button>
            <button *ngIf="isApprove" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="approveVendor(true)">
        <span class="button-label" [ngClass]="fontService.buttonClass">Approve </span> </button>

            <button *ngIf="commercialVendor.id && !isDelete && !isApprove" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()">
        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
        
      </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog #cd [style]="{width: '50vw'}">
        <ng-template pTemplate="header">
            <h3 [ngClass]="fontService.headingClass" jhiTranslate="common.confirmation">Confirmation</h3>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.yes"></span>
        </button>
            <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cd.reject()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.no"></span>
        </button>
        </ng-template>
    </p-confirmDialog>
</div>