import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { ElectedRepresentativeDto } from './elected-represenative.model';


@Injectable({ providedIn: 'root' })
export class electedrepresentativecomponentService {
  protected employeeListUrl =
    environment.SERVER_API_URL + 'api/getElectedRepByFilter';
    protected resourceValidFliterUrl = environment.SERVER_API_URL+('api/getElectedRepValidByFilter');
    protected getemployeeUrl = environment.SERVER_API_URL + 'api/getElectedRep';
    protected employeeTypeListUrl = 
    environment.SERVER_API_URL + 'api/employeeTypeList';
  protected subEmployeeListUrl =
    environment.SERVER_API_URL + 'api/getSubEmployeeTypeByEmployeeId';
  protected deleteEmployeeUrl = environment.SERVER_API_URL+('api/deletElectedRep');
  protected employeeSaveUrl = environment.SERVER_API_URL+('api/saveElectedRep');
  protected waitingForApprovalUrl = environment.SERVER_API_URL+('api/electedRep/waitingForApproval');
  protected updateStatusUrl = environment.SERVER_API_URL + ('api/electedRep/updateStatus');
  // protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteCommercialVendor');
  // protected CommercialListUrl = environment.SERVER_API_URL+('api/commercialList');
  // protected commercialGetUrl = environment.SERVER_API_URL+('api/getCommercialVendor');
  // protected resourceValidFliterUrl = environment.SERVER_API_URL+('api/getCommercialVendorValidByFilter');
  constructor(protected http: HttpClient) {}
  filter(req?: any,filter?:any): Observable<any> {
    if(filter === null){
      filter = []
    }
    const options = createRequestOption(req);
      return this.http
      .post<any[]>(this.employeeListUrl,filter, { params:options, observe: 'response' })
  }

  getEmployeeList(): Observable<any> {
    return this.http.get<any[]>(this.employeeTypeListUrl, {
      observe: 'response',
    });
  }

  getemployee(id: number): Observable<HttpResponse<any>> {
    return this.http.get<ElectedRepresentativeDto>(`${this.getemployeeUrl}?id=${id}`, { observe: 'response' });
  }

  deletemployee(id: number): Observable<HttpResponse<any>> {
    return this.http.get<ElectedRepresentativeDto>(`${this.deleteEmployeeUrl}?id=${id}`, { observe: 'response' });
  }

  getSubEmployeeList(id: number): Observable<any> {
    return this.http.get<any[]>(`${this.subEmployeeListUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  save(employee: ElectedRepresentativeDto,status): Observable<any> {
    return this.http
    .post<ElectedRepresentativeDto>(`${this.employeeSaveUrl}?status=${status}`,employee, {observe: 'response' })
}

  validFilter(req?: any,filter?:any): Observable<any> {
    if(filter === null){
      filter = []
    }
    const options = createRequestOption(req);
      return this.http
      .post<any[]>(this.resourceValidFliterUrl,filter, { params:options, observe: 'response' })
  }

  waitingForApproval(): Observable<any> {
    return this.http
      .get<any>(this.waitingForApprovalUrl, { observe: 'response' })
  }

  updateStatus(id:number,status): Observable<any> {
    return this.http.get(`${this.updateStatusUrl}?id=${id}&approvalAction=${status}`, { observe: 'response',responseType:'text' });
  }
}
