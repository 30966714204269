import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { beneficiaryDto, beneficiaryList, deductionDto } from './beneficiary.model';
export type EntityArrayResponseType = HttpResponse<beneficiaryList[]>;

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {

  constructor(private http: HttpClient) { }

  protected resourceFliterUrl = environment.SERVER_API_URL+('api/getPersonalVendorByFilter');
    protected resourceValidFliterUrl = environment.SERVER_API_URL+('api/getBeneficiaryByFilter');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveBeneficiary');
    protected resourceEditUrl = environment.SERVER_API_URL+('api/getBeneficiaryById')
    protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deletePersonalVendor');
    protected resourcesaveDeductionUrl = environment.SERVER_API_URL+('api/saveDeductionAccount');
    protected resourceDeductionfilterUrl = environment.SERVER_API_URL+('api/getDeductionAccountsByFilter');
    protected getDeductionAccountsByFiltersUrl = environment.SERVER_API_URL+('api/getDeductionAccountsByFilters');
    protected resourceDeductionEditUrl = environment.SERVER_API_URL+('api/getDeductionAccById');
    protected beneficiaryfilterUrl = environment.SERVER_API_URL+('api/beneficiaryList');
    protected deductionApprovereRejectUrl = environment.SERVER_API_URL+('api/approvelDecutionAcc');
    protected waitingForApprovalUrl = environment.SERVER_API_URL+('api/beneficiary/waitingForApproval');
    protected updateStatusUrl = environment.SERVER_API_URL + ('api/beneficiary/updateStatus');
    protected waitingForDeductionApprovalUrl = environment.SERVER_API_URL+('api/deductionAccounts/waitingForApproval');
    protected updateDeductionStatusUrl = environment.SERVER_API_URL + ('api/deductionAccounts/updateStatus');
    

    
    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<beneficiaryList[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }

    validFilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<beneficiaryList[]>(this.resourceValidFliterUrl,filter, { params:options, observe: 'response' })
    }

    save(profile:beneficiaryDto,status): Observable<any> {
      return this.http
      .post<beneficiaryDto>(`${this.resourceSaveUrl}?status=${status}`,profile, {observe: 'response' })

    }

    saveDeduction(data:deductionDto,status): Observable<any> {
      return this.http
      .post<deductionDto>(`${this.resourcesaveDeductionUrl}?status=${status}`,data, {observe: 'response' })

    }

    delete(id:number): Observable<any> {
      
      return this.http.get(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
  }

    getbeneFiciary(id: number): Observable<HttpResponse<any>> {
      return this.http.get<beneficiaryDto>(`${this.resourceEditUrl}?id=${id}`, { observe: 'response' });
    }
    getDeductionAccount(id: number): Observable<HttpResponse<any>> {
      return this.http.get<deductionDto>(`${this.resourceDeductionEditUrl}?id=${id}`, { observe: 'response' });
    }
    validFilterDeduction(req?: any,filter?:any) {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .get(this.resourceDeductionfilterUrl, {params:options,  observe: 'response' })
    }
    getDeductionAccountsByFilters(req?: any,filter?:any) {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post(this.getDeductionAccountsByFiltersUrl,filter, {params:options,  observe: 'response' })
    }
    
    getbeneficiaryList() {
      return this.http.get<any>(`${this.beneficiaryfilterUrl}`, { observe: 'response' });
    }
    deductionApproval(req?: any): Observable<any> {
 let filter=[]
      const options = createRequestOption(req);
        return this.http
        .post<any>(this.deductionApprovereRejectUrl,filter,{params:options,  observe: 'response' })
    }

    waitingForApproval(): Observable<any> {
      return this.http
        .get<any>(this.waitingForApprovalUrl, { observe: 'response' })
    }
  
    updateStatus(id:number,status): Observable<any> {
      return this.http.get(`${this.updateStatusUrl}?id=${id}&approvalAction=${status}`, { observe: 'response',responseType:'text' });
    }
    deductionWaitingForApproval(): Observable<any> {
      return this.http
        .get<any>(this.waitingForDeductionApprovalUrl, { observe: 'response' })
    }
  
    deductionUpdateStatus(id:number,status): Observable<any> {
      return this.http.get(`${this.updateDeductionStatusUrl}?id=${id}&approvalAction=${status}`, { observe: 'response',responseType:'text' });
    }
}
