<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.voucherDelayReport"></h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <p-dropdown placeholder="Select Fin Year" [options]="finYearList" [(ngModel)]="finYear" optionLabel="name"></p-dropdown>
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
        <button pButton pRipple label="Download All" (click)="downloadAllReport()" class="p-button-success p-button-sm export-button"></button>
        <button pButton pRipple label="Download" (click)="voucherPendingExcel()" class="p-button-success p-button-sm export-button"></button>
    </div>
    <div class="row">
        <!-- <div *ngIf="dateError" class="text-danger mb-2">
            <small [ngClass]="fontService.labelClass" jhiTranslate="error.invaliddate">Please
                choose valid from and to
                date</small>
        </div> -->
    </div>
    <div class="row">
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
            <p-tabPanel header="District" *ngIf="!isDistrictTrue && !isZonalTrue">
                <div class="col-12 px-4" *ngIf="VoucherPendingDistrictDto">
                    <p-table id="district-table" [value]="VoucherPendingDistrictDto" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass"> Total No Pending Vouchers </th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Maker</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Checker 1</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Checker 2</th>

                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalMakerPending">15 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker1Pending">30 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker2Pending">90 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Above 90 Days</th>

                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialMakerPending">15 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker1Pending">30 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker2Pending">90 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Above 90 Days</th>

                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeMakerPending">15 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker1Pending">30 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker2Pending">90 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Above 90 Days</th>



                            </tr>

                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadBlock(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.totalPendingCount}}</td>
                                <td style="text-align: center;">{{list.makerDateCount0To15}}</td>
                                <td style="text-align: center;">{{list.makerDateCount16To30}}</td>
                                <td style="text-align: center;">{{list.makerDateCount31To90}}</td>
                                <td style="text-align: center;">{{list.makerDateCountAbove90}}</td>
                                <td style="text-align: center;">{{list.checker1DateCount0To15}}</td>
                                <td style="text-align: center;">{{list.checker1DateCount16To30}}</td>
                                <td style="text-align: center;">{{list.checker1DateCount31To90}}</td>
                                <td style="text-align: center;">{{list.checker1DateCountAbove90}}</td>
                                <td style="text-align: center;">{{list.checker2DateCount0To15}}</td>
                                <td style="text-align: center;">{{list.checker2DateCount16To30}}</td>
                                <td style="text-align: center;">{{list.checker2DateCount31To90}}</td>
                                <td style="text-align: center;">{{list.checker2DateCountAbove90}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel *ngIf="!isZonalTrue" header="Block">
                <div *ngIf="!VoucherPendingBlockDto">Choose distict to get block report</div>
                <div *ngIf="VoucherPendingBlockDto" class="col-12 px-4">
                    <p-table id="block-table" [value]="VoucherPendingBlockDto" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass"> Total No Pending Vouchers </th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Maker</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Checker 1</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Checker 2</th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalMakerPending">15 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker1Pending">30 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker2Pending">90 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Above 90 Days</th>

                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialMakerPending">15 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker1Pending">30 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker2Pending">90 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Above 90 Days</th>

                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeMakerPending">15 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker1Pending">30 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker2Pending">90 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Above 90 Days</th>


                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadVillage(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.totalPendingCount}}</td>
                                <td style="text-align: center;">{{list.makerDateCount0To15}}</td>
                                <td style="text-align: center;">{{list.makerDateCount16To30}}</td>
                                <td style="text-align: center;">{{list.makerDateCount31To90}}</td>
                                <td style="text-align: center;">{{list.makerDateCountAbove90}}</td>
                                <td style="text-align: center;">{{list.checker1DateCount0To15}}</td>
                                <td style="text-align: center;">{{list.checker1DateCount16To30}}</td>
                                <td style="text-align: center;">{{list.checker1DateCount31To90}}</td>
                                <td style="text-align: center;">{{list.checker1DateCountAbove90}}</td>
                                <td style="text-align: center;">{{list.checker2DateCount0To15}}</td>
                                <td style="text-align: center;">{{list.checker2DateCount16To30}}</td>
                                <td style="text-align: center;">{{list.checker2DateCount31To90}}</td>
                                <td style="text-align: center;">{{list.checker2DateCountAbove90}}</td>

                            </tr>
                        </ng-template>
                        <!-- <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: center;">Total</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.personalmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.personalChecker1Total}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.personalChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.personalApprovedTotal}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.commercialmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.commercialChecker1Total}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.commercialChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.commercialApprovedTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.employeemakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.employeeChecker1Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.employeeChecker2Total }}</td>
                              
                            </tr>
                        </ng-template> -->
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Village">
                <div *ngIf="!VoucherPendingVillageDto">Choose block to get village report</div>
                <div *ngIf="VoucherPendingVillageDto" class="col-12 px-4">
                    <p-table id="village-table" [value]="VoucherPendingVillageDto" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass"> Total No Pending Vouchers </th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Maker</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Checker 1</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Checker 2</th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalMakerPending">15 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker1Pending">30 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker2Pending">90 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Above 90 Days</th>

                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialMakerPending">15 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker1Pending">30 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker2Pending">90 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Above 90 Days</th>

                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeMakerPending">15 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker1Pending">30 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker2Pending">90 Days </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass">Above 90 Days</th>


                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">{{list.levelMasterName}}</td>
                                <td style="text-align: center;">{{list.totalPendingCount}}</td>
                                <td style="text-align: center;">{{list.makerDateCount0To15}}</td>
                                <td style="text-align: center;">{{list.makerDateCount16To30}}</td>
                                <td style="text-align: center;">{{list.makerDateCount31To90}}</td>
                                <td style="text-align: center;">{{list.makerDateCountAbove90}}</td>
                                <td style="text-align: center;">{{list.checker1DateCount0To15}}</td>
                                <td style="text-align: center;">{{list.checker1DateCount16To30}}</td>
                                <td style="text-align: center;">{{list.checker1DateCount31To90}}</td>
                                <td style="text-align: center;">{{list.checker1DateCountAbove90}}</td>
                                <td style="text-align: center;">{{list.checker2DateCount0To15}}</td>
                                <td style="text-align: center;">{{list.checker2DateCount16To30}}</td>
                                <td style="text-align: center;">{{list.checker2DateCount31To90}}</td>
                                <td style="text-align: center;">{{list.checker2DateCountAbove90}}</td>
                            </tr>
                        </ng-template>
                        <!-- <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: center;">Total</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.personalmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.personalChecker1Total}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.personalChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.personalApprovedTotal}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.commercialmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.commercialChecker1Total}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.commercialChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.commercialApprovedTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.employeemakerTotal }}</td>
                                
                            </tr>
                        </ng-template> -->
                    </p-table>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
        Transaction List
    </p-header>
    <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false"></custom-filter-table>
</p-dialog>