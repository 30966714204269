<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.employeeTransferReport"></h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Download" (click)="download()" class="p-button-sm"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-lg-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">From Date</label><br>
            <p-calendar id="fromDate" dateFormat="dd/mm/yy" [(ngModel)]="fromDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>

        </div>
        <div class="col-lg-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">To Date</label><br>
            <p-calendar id="toDate" dateFormat="dd/mm/yy" [(ngModel)]="toDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        </div>

        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-success custom-button" (click)="getEmployeeReport(1)" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.searchbutton">Search</span>
            </button>
        </div>
        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-danger custom-button">
                <span class="button-label" [ngClass]="fontService.buttonClass" (click)="fromDate = null;toDate = null;getEmployeeReport(1)" jhiTranslate="common.clearbutton">clear</span>
            </button>
        </div>
        <div class="col-lg-3">

        </div>

        <div class="col-lg-1">
            <button pButton pRipple label="Download" class="p-button-success custom-button" (click)="downloadExport()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                ></span>
            </button>
        </div>
        <div class="col-lg-2">
            <button pButton pRipple label="Check Download Status" class="p-button-success custom-button" (click)="checkStatus()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                ></span>
            </button>
        </div>
    </div> -->
    <!-- <div class="row g-2 mb-2">
        <div class="col-md-12">
            <div class="card border-left-info shadow p-2">
                <div class="row">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass">
                        Employee Transfer Report</h5>
                </div>
                <p-table [value]="employeeDetails" [resizableColumns]="true" [paginator]="true" (onLazyLoad)="loadFilterValues($event)" [rows]="itemsPerPage" [showCurrentPageReport]="true" [lazy]="true" [totalRecords]="totalCount" [lazyLoadOnInit]="true" responsiveLayout="scroll"
                    [tableStyle]="{ width: 'max-content'}" [reorderableColumns]="true" selectionMode="single" [rowsPerPageOptions]="[1,10,20,30]" [currentPageReportTemplate]="totalCount > 0 ? 'Showing {first} - {last} of {totalRecords} items' : 'No Items Found'"
                    [first]="first" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th rowspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">S.No</th>
                            <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Employee transferred from</th>
                            <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Employee Details</th>
                            <th colspan="5" style="text-align: center;" [ngClass]="fontService.labelClass">Employee transferred To</th>
                        </tr>
                        <tr>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">District Name</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">Block Name</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">Village Name</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">LGD Code</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">Name of the Employeee</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">Designation</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">District Name</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">Block Name</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">Village Name</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">Transferred Date</th>
                            <th style="text-align: center;" [ngClass]="fontService.labelClass">LGD Code</th>
                        </tr>

                    </ng-template>
                    <ng-template pTemplate="body" let-list let-index="rowIndex">
                        <tr>
                            <td style="text-align: center;">{{index+1}}</td>
                            <td style="text-align: center;"><b>{{list?.districtFromName}}</b></td>
                            <td style="text-align: center;"><b>{{list?.blockFromName}}</b></td>
                            <td style="text-align: center;"><b>{{list?.villageFromName}}</b></td>
                            <td style="text-align: center;"><b>{{list?.villageFromLgd}}</b></td>
                            <td style="text-align: center;"><b>{{list?.employeeName}}</b></td>
                            <td style="text-align: center;"><b>{{list?.designation}}</b></td>
                            <td style="text-align: center;"><b>{{list?.districtToName}}</b></td>
                            <td style="text-align: center;"><b>{{list?.blockToName}}</b></td>
                            <td style="text-align: center;"><b>{{list?.villageToName}}</b></td>
                            <td style="text-align: center;"><b>{{list?.transferredDate | date:'dd/MM/yyyy' }}</b></td>
                            <td style="text-align: center;"><b>{{list?.villageToLgd}}</b></td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>

    </div> -->
</div>