import { SessionStorageService } from 'ngx-webstorage';
import { AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PersonalVendorService } from './personal-vendors.service';
import {
  PersonalVendors,
  PersonalVendorsDto,
  ValidationResponse,
} from './personal-vendors';
import { SearchResult } from 'src/app/shared/models/search-result';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { CommercialVendorService } from '../commercial-vendors/commercial-vendors.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { environment } from 'src/environments/environment';
import { LevelMasterDto } from '../level-master/level-master';
import { LevelMasterService } from '../level-master/level-master.service';
import { DatePipe, Location } from '@angular/common';
@Component({
  selector: 'app-personal-vendors',
  templateUrl: './personal-vendors.component.html',
  styleUrls: ['./personal-vendors.component.scss'],
})
export class PersonalVendorsComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;

  personalVendorsDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<PersonalVendors> =
    new SearchResult<PersonalVendors>();
  personalVendor?: PersonalVendorsDto;
  selectedPersonalVendors: PersonalVendors[] = [];
  submitted?: boolean;
  selectedMapping: string;
  checked: boolean;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any=[];
  validationRes: ValidationResponse;
  readonly: boolean;
  vendorName: string;
  currentEnvironment: string;
  bankBranchDetailsRes: any;
  accountAttempt:number=0;

  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  public verifyClicked: boolean;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showStaffError: string;
  showMobileUniError: string;
  emailUniError: string;
  aadhaarNoUniError: string;
  panNoUniError: string;
  accountNoUniError: any;
  bankList: any;
  bankBranchList: any;
  bankBranch: any;
  accountInvalidError: any;
  roleCode: any;
  disableEdit: boolean = false;
  edit: boolean;
  villageLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  districtLevelList: LevelMasterDto[];
  districtDto: any;
  blockLevelList: LevelMasterDto[];
  villageLevelDto: any;
  accountNo: string;
  levelType = ['District', 'Block', 'Village'];
  selectedLevelType: string;
  disableDelete: boolean = true;
  isDelete: boolean;
  uniqueId:string;
  currentPath: string;
  isApprove: boolean;
  constructor(
    private personalVendorService: PersonalVendorService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private commercialVendorservice: CommercialVendorService,
    protected loginService: SigninService,
    private levelMasterService: LevelMasterService,
    private sessionStorage:SessionStorageService,
    private location: Location
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.currentPath = this.location.path();
    this.currentEnvironment = environment.environment;
   
        this.roleCode = this.sessionStorage.retrieve('user')?.role?.code;
        if (
          this.roleCode == 'DM' ||
          this.roleCode == 'BM' ||
          this.roleCode == 'SM' ||
          this.roleCode == 'SA' ||
          this.roleCode == 'SUA'
        )
          this.disableEdit = true;
        this.loadPage(1);
        if (this.roleCode === 'SA' && this.currentPath !== '/personal-vendors-approve') {
          this.disableDelete = false;
          this.onStateLevelChange();
        }
     

    this.commercialVendorservice.getBankList().subscribe(
      (res: HttpResponse<[]>) => {
        this.bankList = res.body;
      },
      () => {}
    );
    this.cols = [
      {
        field: 'uniqueId',
        header: 'Name',
        jhiTranslate: 'tableHead.uniqueId',
        isSortable: true,
        isFilterable: true,
        width: 10,
        isSelectcolumn: true,
      },
      
      {
        field: 'name',
        header: 'Name',
        jhiTranslate: 'tableHead.name',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
      },
     
      {
        field: 'designation',
        header: 'Designation',
        jhiTranslate: 'tableHead.designation',
        isSortable: true,
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      {
        field: 'panNo',
        header: 'Pan No',
        jhiTranslate: 'tableHead.panno',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'bankName',
        header: 'Bank Name',
        jhiTranslate: 'vendor.bank.name',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'ifscCode',
        header: 'Ifsc Code',
        jhiTranslate: 'vendor.ifscode',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'accountNumber',
        header: 'Account No',
        jhiTranslate: 'tableHead.account.number',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'aadhaarNo',
        header: 'Aadhaar No',
        jhiTranslate: 'tableHead.aadhaarno',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'createdOn',
        header: 'Created On',
        jhiTranslate: 'tableHead.createdon',
        isSortable: true,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
    this.updateDialogFont();
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.personalVendor = new PersonalVendorsDto();
    this.submitted = false;
    this.personalVendorsDialog = true;
    this.readonly = false;
    this.accountAttempt = 0;
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
      ];
      if (this.personalVendor.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.personalVendor.id,
        });
      }
      this.personalVendorService
        .validFilter({ ...filterQuery }, filter)
        .subscribe(
          (res: HttpResponse<PersonalVendors[]>) => {
            resolve(res.body);
          },
          () => {
            this.onError();
            resolve(null);
          }
        );
    });
  }

  addresschange(check?) {
    if (this.checked) {
      this.personalVendor.companyDoorNo = this.personalVendor.doorNo;
      this.personalVendor.companyStreet = this.personalVendor.street;
      this.personalVendor.companyArea = this.personalVendor.area;
      this.personalVendor.companyCity = this.personalVendor.city;
      this.personalVendor.companyDistrict = this.personalVendor.district;
      this.personalVendor.companyState = this.personalVendor.state;
      this.personalVendor.companyPinCode = this.personalVendor.pinCode;
    } else {
      if (!check) {
        this.personalVendor.companyDoorNo = null;
        this.personalVendor.companyStreet = null;
        this.personalVendor.companyArea = null;
        this.personalVendor.companyCity = null;
        this.personalVendor.companyDistrict = null;
        this.personalVendor.companyState = null;
        this.personalVendor.companyPinCode = null;
      }
    }
  }

  async checkName() {
    const filterData = await this.getfilterdata(
      'name',
      this.personalVendor.name
    );
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkstaffId() {
    const filterData = await this.getfilterdata(
      'staffId',
      this.personalVendor.staffId
    );
    if (filterData.length !== 0) {
      this.showStaffError = 'Staff Already Exists';
    } else {
      this.showStaffError = null;
    }
  }

  async checkmobile() {
    const filterData = await this.getfilterdata(
      'mobile',
      this.personalVendor.mobile
    );
    if (filterData.length !== 0) {
      this.showMobileUniError = 'Mobile No Already Exists';
    } else {
      this.showMobileUniError = null;
    }
  }

  async checkemail() {
    const filterData = await this.getfilterdata(
      'email',
      this.personalVendor.email
    );
    if (filterData.length !== 0) {
      this.emailUniError = 'Email Already Exists';
    } else {
      this.emailUniError = null;
    }
  }

  async checkaadharNo() {
    const filterData = await this.getfilterdata(
      'aadhaarNo',
      this.personalVendor.aadhaarNo
    );
    if (filterData.length !== 0) {
      this.aadhaarNoUniError = 'Aadhaar No Already Exists';
    } else {
      this.aadhaarNoUniError = null;
    }
  }

  async checkPanNo() {
    const filterData = await this.getfilterdata(
      'panNo',
      this.personalVendor.panNo
    );
    if (filterData.length !== 0) {
      this.panNoUniError = 'Pan No Already Exists';
    } else {
      this.panNoUniError = null;
    }
  }

  async checkAccountNo() {
    const filterData = await this.getfilterdata(
      'accountNumber',
      this.personalVendor.accountNumber
    );
    if (filterData.length !== 0) {
      this.accountNoUniError = 'account No Already Exists';
    } else {
      this.accountNoUniError = null;
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      sort: ['createdOn,asc'],
    };
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    var i;
    if (this.filterProps) {
      for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
        if (this.filterProps[i].key === 'name') {
          this.filterProps.splice(i, 1);
        }
      }
    }
    if (this.vendorName) {
      this.filterProps.push({
        key: 'name',
        operation: 'contains',
        value: this.vendorName.toUpperCase(),
      });
    }
    if (this.accountNo) {
      this.filterProps.push({
        key: 'accountNumber',
        operation: 'contains',
        value: this.accountNo,
      });
    }
    if(this.uniqueId) {
      this.filterProps.push({
        key: 'uniqueId',
        operation: 'contains',
        value: this.uniqueId,
      });
      
    }
    if (this.selectedLevelType === 'District' && this.districtDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.districtDto.id,
      });
    }
    if (this.selectedLevelType === 'Block' && this.BlockLevelDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.BlockLevelDto.id,
      });
    }
    if (this.selectedLevelType === 'Village' && this.villageLevelDto) {
      this.filterProps.push({
        key: 'levelMasterId',
        operation: 'contains',
        value: this.villageLevelDto.id,
      });
    }
    console.log('resss', this.roleCode);
    if(this.currentPath === '/personal-vendors-approve') {
      this.disableDelete = true;
      this.personalVendorService
        .waitingForApproval()
        .subscribe(
          (res: HttpResponse<PersonalVendors[]>) => {
            this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          },
          () => {
            this.onError();
          }
        );
    }
    else {
      if (this.roleCode === 'SA') {
        this.personalVendorService
          .validFilter({ ...filterQuery }, this.filterProps)
          .subscribe(
            (res: HttpResponse<PersonalVendors[]>) => {
              this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
            },
            () => {
              this.onError();
            }
          );
      } else {
        this.personalVendorService
          .filter({ ...filterQuery }, this.filterProps)
          .subscribe(
            (res: HttpResponse<PersonalVendors[]>) => {
              this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
            },
            () => {
              this.onError();
            }
          );
      }
    }
    
  }
  getBankBranchList(event?) {
    this.bankBranch = null;
    let filter = [];
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.personalVendor.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };
    if (event) {
      filter.push({
        key: 'bankBranch',
        operation: 'startswith',
        value: event.term.toUpperCase(),
      });
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    } else {
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    }
  }

  getBankBranchdetais() {
    let filter = [];
    filter.push({
      key: 'bankIfsc',
      operation: 'equals',
      value: this.personalVendor.ifscCode.toUpperCase(),
    });
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.personalVendor.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };

    this.commercialVendorservice
      .getBankBranchList(filter, filterQuery)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.bankBranch = res.body[0];
        },
        () => {}
      );
  }

  getBankIfsc() {
    this.personalVendor.bankBranch = this.bankBranch.bankBranch;
    this.commercialVendorservice
      .getBankIfscCode(
        this.personalVendor.bankName,
        this.personalVendor.bankBranch
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.personalVendor.ifscCode = res.body[0];
        },
        () => {}
      );
  }

  accountValidation() {
    if (
      !this.personalVendor.ifscCode ||
      !this.personalVendor.accountNumber ||
      !this.personalVendor.bankName ||
      !this.personalVendor.bankBranch
    ) {
      this.accountInvalidError =
        'Please input valid account number and bank details';
      return;
    }
    this.verifyClicked = true;
    this.accountInvalidError = null;
    this.spinner.show();
    this.commercialVendorservice
      .getaccountValidation(
        this.personalVendor.accountNumber,
        this.personalVendor.ifscCode
      )
      .subscribe(
        (res: any) => {
          // this.validationR this.accountAttempt = 0;es=JSON.parse(res.body);
          this.validationRes = res.body;
          if (this.validationRes.bankTxnStatus === true) {
            this.personalVendor. bankAccName= this.validationRes.accountName;
            this.accountInvalidError = null;
            this.notificationService.alertSuccess(
              'Account Verfied Sucessfully',
              ''
            );
            this.readonly = true;
            this.personalVendor.isAccountVerified = true;
          } else {
            this.accountAttempt += 1;
            if(this.accountAttempt === 2) {
              this.readonly =true;
              this.verifyClicked = true;
              this.personalVendor.isAccountVerified = false;
              this.accountInvalidError = null;
            }
            else {
              this.accountInvalidError =
              'Account Verification Failed due to ' +
              this.validationRes.bankResponse;
            this.notificationService.alertError(
              'Account Verification Failed due to ' +
                this.validationRes.bankResponse,
              ''
            );
            this.readonly = false;
            this.verifyClicked = false;
            }
          }
          this.spinner.hide();
        },
        (error) => {
          if (error.status === 400) {
            this.accountInvalidError =
              'Account Verification Failed. ' + error.error.message;
            this.notificationService.alertError(
              'Account Verification Failed. ' + error.error.message,
              ''
            );
          } else {
            this.accountInvalidError =
              'Please input valid account number and branch details';
            this.notificationService.alertError(
              'Account Verification Failed',
              ''
            );
          }
          this.accountAttempt += 1;
            if(this.accountAttempt === 2) {
              this.readonly =true;
              this.verifyClicked = true;
              this.personalVendor.isAccountVerified = false;
              this.accountInvalidError = null;
            }
            else {
              this.readonly = false;
              this.verifyClicked = false;
            }
            this.spinner.hide();
         
        }
      );
  }

  protected onSuccess(
    data: PersonalVendors[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    console.log(event)
    this.filterProps = [];
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.tanError = false;
    this.tinError = false;
    this.gstError = false;
    this.ifscError = false;
    this.personalVendor = new PersonalVendorsDto();
    this.ngSelectInvalid = false;
    this.accountInvalidError = false;
    this.verifyClicked = true;
    this.personalVendorsDialog = false;
    this.submitted = false;
    this.bankBranch = null;
    this.edit = false;
    this.isDelete = false;
    this.isApprove = false;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.personalVendor.mobile
    );
    !this.personalVendor.mobile ? (this.showMobileError = false) : true;

    return !this.showMobileError && !!this.personalVendor.mobile;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(
      this.personalVendor.email
    );
    !this.personalVendor.email ? (this.emailError = false) : true;

    return !this.emailError && !!this.personalVendor.email;
  }

  validatePan() {
    this.panError = !this.validationService.isPanValid(
      this.personalVendor.panNo
    );
    !this.personalVendor.panNo ? (this.panError = false) : true;

    return !this.panError && !!this.personalVendor.panNo;
  }

  validateTan() {
    this.tanError = !this.validationService.isPanValid(
      this.personalVendor.tanNo
    );
    !this.personalVendor.tanNo ? (this.tanError = false) : true;

    return !this.tanError && !!this.personalVendor.tanNo;
  }

  validateTin() {
    this.tinError = !this.validationService.isPanValid(
      this.personalVendor.tinNo
    );
    !this.personalVendor.tinNo ? (this.tinError = false) : true;

    return !this.tinError && !!this.personalVendor.tinNo;
  }

  validatePin() {
    this.pinError = !this.validationService.isPincodeValid(
      this.personalVendor.pinCode
    );
    !this.personalVendor.pinCode ? (this.pinError = false) : true;

    return !this.pinError && !!this.personalVendor.pinCode;
  }

  validateCPin() {
    this.cPinError = !this.validationService.isPincodeValid(
      this.personalVendor.companyPinCode
    );
    !this.personalVendor.companyPinCode ? (this.cPinError = false) : true;

    return !this.cPinError && !!this.personalVendor.companyPinCode;
  }

  validateAadhaar() {
    this.aadhaarError = !this.validationService.isAadhaarValid(
      this.personalVendor.aadhaarNo
    );
    !this.personalVendor.aadhaarNo ? (this.aadhaarError = false) : true;

    return !this.aadhaarError && !!this.personalVendor.aadhaarNo;
  }

  validateGst() {
    this.gstError = !this.validationService.isPanValid(
      this.personalVendor.gstNo
    );
    !this.personalVendor.gstNo ? (this.gstError = false) : true;

    return !this.gstError && !!this.personalVendor.gstNo;
  }

  validateIfsc() {
    this.ifscError = !this.validationService.isIfscValid(
      this.personalVendor.ifscCode
    );
    !this.personalVendor.ifscCode ? (this.ifscError = false) : true;

    return !this.ifscError && !!this.personalVendor.ifscCode;
  }
  accountDetailsChanged() {
    this.verifyClicked = false;
    if (
      this.currentEnvironment == 'uat' ||
      this.currentEnvironment === 'production'
    ) {
      if (!this.ifscError) {
        this.spinner.show();
        this.commercialVendorservice
          .bankBranchDetails(this.personalVendor.ifscCode)
          .subscribe(
            (res) => {
              this.spinner.hide();
              this.bankBranchDetailsRes = res.body;
              console.log(this.bankBranchDetailsRes, 'bankbranchdetails');
              this.personalVendor.bankBranch =
                this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Branch_Name;
              this.personalVendor.bankName =
                this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Bank_Name;
            },
            () => {
              this.spinner.hide();
              this.notificationService.alertError('Error Occured', '');
            }
          );
      }
    }
  }

  accountChanged() {
    this.verifyClicked = false;
  }

  savePersonalVendor() {
    const isFormValid =
      this.validateMobile() &&
      this.validatePin() &&
      this.validateAadhaar() &&
      this.validateIfsc() &&
      this.personalVendor.name &&
      this.personalVendor.designation &&
      // this.personalVendor.staffId &&
      this.personalVendor.doorNo &&
      this.personalVendor.street &&
      this.personalVendor.city &&
      this.personalVendor.district &&
      this.personalVendor.state &&
      this.personalVendor.companyDoorNo &&
      this.personalVendor.companyStreet &&
      this.personalVendor.companyDistrict &&
      this.personalVendor.companyCity &&
      this.personalVendor.companyState &&
      // this.personalVendor.bankId &&
      // this.personalVendor.bankBranch &&
      // this.personalVendor.bankName &&
      this.personalVendor.accountNumber &&
      this.personalVendor.ifscCode &&
      !this.accountInvalidError &&
      this.verifyClicked;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    this.personalVendorService.save(this.personalVendor,this.personalVendor.isAccountVerified).subscribe(
      (res: HttpResponse<any>) => {
        this.personalVendorsDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (err) => {
        this.onError();
        if (err.error.status === 400) {
          this.notificationService.alertError('Error! ' + err.error.title, '');
        } else {
          this.notificationService.alertError('Unexpected Error!', '');
        }
      }
    );
  }

  onSelectRow($event) {
    this.personalVendorService.getpersonalVendor($event.value.id).subscribe(
      (res: HttpResponse<any>) => {
        this.readonly = true;
        this.edit = true;
        this.personalVendor = res.body;
        this.personalVendorsDialog = true;
        this.verifyClicked = true;
        if (this.personalVendor.bankName) {
          this.getBankBranchList();
          this.getBankBranchdetais();
        }
      },
      () => {
        this.onError();
      }
    );
    if ($event.type === 'select') {
      this.readonly = true;
      this.edit = true;
    }
    else if($event.type === 'approve') {
      this.isApprove = true;
      this.readonly = true;
    }
     else {
      this.isDelete = true;
      this.readonly = true;
    }
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onDistrictLevelChange($event?) {
    this.villageLevelDto = null;
    this.BlockLevelDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onBlockLevelChange($event?) {
    this.villageLevelDto = null;
    this.villageLevelList = [];
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    }
  }

  deleteVendor() {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to delete this ' +
        this.personalVendor.name,
      icon: 'pi pi-unlock',
      accept: () => {
        this.spinner.show();
        this.personalVendorService.delete(this.personalVendor.id).subscribe(
          (res: HttpResponse<any>) => {
            this.filterProps = [];
            this.loadPage(this.page);
            this.hideDialog();
            this.spinner.hide();
            this.notificationService.alertSuccess('Vendor Deleted Sucessfully','');
          },
          (err) => {
            this.notificationService.alertError(err.error.title, '');
            this.spinner.hide();
          }
        );
      },
      reject: () => {},
    });
  }

  approveVendor(status) {
    let message = ''
    if(status) {
      message = 'Approve';
    }
    else {
      message = 'Reject';
    }
    this.confirmationService.confirm({
      message:
        'Are you sure you want to ' + message +  ' this ' +
        this.personalVendor.name,
      icon: 'pi pi-unlock',
      accept: () => {
        this.spinner.show();
        this.personalVendorService.updateStatus(this.personalVendor.id,status).subscribe(
          (res: HttpResponse<any>) => {
            this.filterProps = [];
            this.loadPage(this.page);
            this.hideDialog();
            this.spinner.hide();
            this.notificationService.alertSuccess(res.body,'');
          },
          (err) => {
            this.notificationService.alertError(err.error.title, '');
            this.spinner.hide();
          }
        );
      },
      reject: () => {},
    });
  }
}
