import { DatePipe } from '@angular/common';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { ComponentType } from 'src/app/module/component-type/component-type';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { VendorTransaction } from 'src/app/payments/payment-voucher/payment-voucher.model';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { VoucherPendingReportDto, VoucherStatusReportDto } from '../report.model';
import { ReportService } from '../report.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { LocalStorageService, SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { NotificationService } from 'src/app/alert/notification.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';

@Component({
  selector: 'app-new-voucher-delay-report',
  templateUrl: './new-voucher-delay-report.component.html',
  styleUrl: './new-voucher-delay-report.component.scss'
})
export class NewVoucherDelayReportComponent {

  VoucherPendingDistrictDto: VoucherPendingReportDto;
  VoucherPendingBlockDto: VoucherPendingReportDto;
  VoucherPendingVillageDto: VoucherPendingReportDto;
  activeIndex:number = 0;
  roleCode: any;
  levelMasterId: any;
  isDMRole: boolean;
  cols: any;
  ERcols: any;
  itemsPerPage: any = 10;
  filterProps: any;
  filterPropsER: any;
  itemsPerPageER: any = 10;
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  page: number;
  enableStatusVoucher: boolean;
  personalFlag: string;
  villageLevelMasterId: any;
  isBMRole: boolean;
  id: any;
  subEmployeeTypeId: any;
  post: any;
  role: string;
  maxDate = new Date();
  fromDate: string;
  startDate: string;
  toDate: string;
  endDate: string;
  dateError: boolean;
  compTypeList: ComponentType[];
  selectedcompType: any;
  compId: any = null;
  levelmasterId: any;
  isDistrictTrue: boolean;
  finYearList: FinYear[];
  finYear: FinYear;
  defaultFinyear: FinYear;
  isZonalTrue: boolean;


  constructor(public reportService: ReportService,
    public fontService: FontService,
    private spinner: NgxSpinnerService,
    private loginService: SigninService,
    private paymentService: PaymentService,
    private componentTypeService: ComponentTypeService,
    private datePipe: DatePipe,
    private sessionStorage: SessionStorageService,
    private notificationService: NotificationService,
    private limitTransListService:LimitTransListService,
    private sessionStorageService: SessionStorageService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.finYearList = this.sessionStorageService.retrieve('finyear');
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
    this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
    this.spinner.show();
    this.roleCode = this.sessionStorage.retrieve('roleCode');
    this.levelmasterId = this.sessionStorage.retrieve('levelmasterId');
    console.log('this.levelMaster', this.levelmasterId);
    console.log('this.roleCode', this.roleCode);
    if (this.roleCode == 'SA' ||  this.roleCode == 'SM') {
     this.loadDistrict();
    } 
    else if (this.roleCode == 'DA' || this.roleCode == 'DM') {
      this.spinner.show();
      this.isDistrictTrue = true;
      this.loadBlock(this.levelmasterId)
    }
    else if(this.roleCode == 'ZVM') {
      this.spinner.show();
      this.isZonalTrue = true;
      this.loadVillage(this.levelmasterId)
    }


    this.cols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        jhiTranslate: 'tableHead.voucher.no',
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'transactionAmount',
        header: 'Total Transaction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        type: 'amount'
      },
      {
        field: 'subComponentName',
        header: 'Sub Component Name',
        jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isSelectcolumn: true,
      }
    ];
  }

  onTabChange(event: any): void {
    if (event.index === 0 && (this.roleCode == 'SA')) {
      this.VoucherPendingBlockDto = null;
      this.VoucherPendingVillageDto = null;
    }
    else if (event.index === 0 && (this.roleCode == 'DM' || this.roleCode === 'DA')) {
      this.VoucherPendingVillageDto = null;
    }
  }

  loadDistrict() {
    this.spinner.show();
    const payload: any = {
      levelMasterId: this.levelmasterId,
      levelType: 'STATE',
      finyearId: this.finYear.id

    };
    this.reportService.getVoucherDelayCount(payload).subscribe((res: HttpResponse<VoucherPendingReportDto>) => {
      console.log(res)
      this.VoucherPendingDistrictDto = res.body;
      this.spinner.hide();
      this.activeIndex = 0;
    })
  }

  loadBlock(data) {
    this.spinner.show();
    this.levelmasterId = null;
    this.levelmasterId = data
    const payload: any = {
      levelMasterId: data,
      levelType: 'DISTRICT',
      finyearId: this.finYear.id

    };
    this.reportService.getVoucherDelayCount(payload).subscribe((res: HttpResponse<VoucherPendingReportDto>) => {
      console.log(res)
      this.VoucherPendingBlockDto = res.body;
      this.spinner.hide()
      if (this.roleCode == 'SA' ||   this.roleCode == 'SM') {
        this.activeIndex = 1;
      } else {
        this.activeIndex = 0
      }


    }, (err => {
      this.spinner.hide()
    }))

  }
  loadVillage(data) {
    this.levelmasterId = null;
    this.levelmasterId = data
    this.spinner.show();
    const payload: any = {
      levelMasterId: data,
      levelType: 'BLOCK',
      finyearId: this.finYear.id

    };
    if(this.roleCode === 'ZVM') {
      payload['zonalName'] = this.sessionStorage.retrieve('user')?.zonalName;
    }
    this.reportService.getVoucherDelayCount(payload).subscribe((res: HttpResponse<VoucherPendingReportDto>) => {
      console.log(res)
      this.VoucherPendingVillageDto = res.body;
      this.spinner.hide()
      if (this.roleCode == 'SA' ||  this.roleCode == 'SM') {
        this.activeIndex = 2;
      } else {
        this.activeIndex = 1
      }

      this.spinner.hide()
    }, (err => {
      this.spinner.hide()
    }))

  }

  loadVoucher(id, flag: string, role: string) {

    this.loadPage(1);
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    //this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

  }

  protected onSuccess(
    data: VendorTransaction[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
    this.spinner.hide();
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog() {
    this.enableStatusVoucher = false;
    this.filterProps = [];
    this.itemsPerPage = 10;
  }

  export() {
    if (this.roleCode == 'SA') {
      if (this.activeIndex === 0) {
        this.exportToExcel('district-table', 'DistrictWiseVoucherPendingReport.xlsx');
      } else if (this.activeIndex === 1) {
        this.exportToExcel('block-table', 'BlockWiseVoucherPendingReport.xlsx');
      } else if (this.activeIndex === 2) {
        this.exportToExcel('village-table', 'VillageWiseVoucherPendingReport.xlsx');
      }
    }
    else if (this.roleCode == 'DM' || this.roleCode == 'DA') {
      if (this.activeIndex === 0) {
        this.exportToExcel('block-table', 'BlockWiseVoucherPendingReport.xlsx');
      } else if (this.activeIndex === 1) {
        this.exportToExcel('village-table', 'VillageWiseVoucherPendingReport.xlsx');
      }
    }

  }

  exportToExcel(id, filename): void {
    let element = document.getElementById(id);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
  }

  onSearch() {
    this.levelmasterId = this.sessionStorage.retrieve('levelmasterId');
    if( this.roleCode == 'SM' ||  this.roleCode == 'SA') {
      this.loadDistrict();
    }
    else {
      this.isDistrictTrue = true;
      this.loadBlock(this.levelmasterId)
    }
  }

  voucherPendingExcel() {
    this.spinner.show();
    let payload;
    if (this.roleCode == 'SA' || this.roleCode == 'SM') {
      if (this.activeIndex == 0) {
        this.levelmasterId = null;
        this.levelmasterId = this.sessionStorage.retrieve('levelmasterId');
        console.log(this.activeIndex)
        payload = {
          levelMasterId: this.levelmasterId,
          levelType: 'STATE',

        };
      } else if (this.activeIndex == 1) {
        if (this.VoucherPendingBlockDto) {
          payload = {
            levelMasterId: this.levelmasterId,
            levelType: 'DISTRICT',

          };
        } else {
          this.spinner.hide();
          this.notificationService.alertError('Please choose the district to download the excel', '');
          return

        }

      }
      else if (this.activeIndex == 2) {
        if (this.VoucherPendingVillageDto) {
          payload = {
            levelMasterId: this.levelmasterId,
            levelType: 'BLOCK',

          };
        } else {
          this.spinner.hide();
          this.notificationService.alertError('Please choose the block to download the excel', '');
          return

        }

      }
    }
    else if(this.roleCode === 'ZVM') {
      payload = {
        levelMasterId: this.levelmasterId,
        levelType: 'BLOCK',
        zonalName: this.sessionStorage.retrieve('user')?.zonalName

      };
    }
     else {
      if (this.activeIndex == 0) {
        this.levelmasterId = null;
        this.levelmasterId = this.sessionStorage.retrieve('levelmasterId');
        console.log(this.activeIndex)
        payload = {
          levelMasterId: this.levelmasterId,
          levelType: 'DISTRICT',

        };
      } else if (this.activeIndex == 1) {
        if (this.VoucherPendingVillageDto) {
          payload = {
            levelMasterId: this.levelmasterId,
            levelType: 'BLOCK',

          };
        } else {
          this.spinner.hide();
          this.notificationService.alertError('Please choose the Block to download the excel', '');
          return

        }

      }
    }


    
    payload['finyearId'] = this.finYear.id;
    this.reportService.downloadVoucherDelayCount(payload).subscribe(
      (response) => {
        this.spinner.hide();
        var downloadURL = window.URL.createObjectURL(response);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Voucher Delay Report.xlsx";
        link.click();
      },
      () => {
        this.spinner.hide();
      }
    )
  }
  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }
  downloadAllReport() {
    let payload
    if (this.roleCode == 'SA' || this.roleCode == 'SM') {
      this.levelmasterId = this.sessionStorage.retrieve('levelmasterId');
      payload = {
        levelMasterId: this.levelmasterId,
        levelType: 'STATE',

      };
    }
    else if(this.roleCode === 'ZVM') {
      payload = {
        levelMasterId: this.levelmasterId,
        levelType: 'BLOCK',
        zonalName: this.sessionStorage.retrieve('user')?.zonalName

      };
    } 
    else {
      this.levelmasterId = this.sessionStorage.retrieve('levelmasterId');

      payload = {
        levelMasterId: this.levelmasterId,
        levelType: 'DISTRICT',

      };
    }
    this.reportService.downloadAllVoucherDelayCount(payload).subscribe(
      (response) => {
        this.spinner.hide();
        var downloadURL = window.URL.createObjectURL(response);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Voucher Delay Report.xlsx";
        link.click();
      },
      () => {
        this.spinner.hide();
      }
    )
  }


  getFinYear(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.limitTransListService.getFinYear().subscribe(
        (res: HttpResponse<FinYear[]>) => {
          this.finYearList = res.body;
          let FinYear = '';
          if (new Date().getMonth() + 1 >= 4) {
            FinYear =
              new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
          } else {
            FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
          }
          this.finYear = this.finYearList.find((_) => _.name === FinYear);
          this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
          this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
          console.log('actice',this.activeIndex);
          resolve(this.finYear);
        },
        () => { }
      );
    })

  }

}
