import { ComponentSwapService } from '../component-swap-page/component-swap-service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { SearchResult } from "src/app/shared/models/search-result";
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { Router } from '@angular/router';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { filterQuery } from 'src/app/shared/common.model';
import { ReportService } from 'src/app/report/report.service';
import { saveAs } from 'file-saver';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { ComponentLimitSwap, SwapTransaction } from '../component-swap-page/component-swap-model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { SessionStorageService } from 'ngx-webstorage';
import { Location } from '@angular/common';


@Component({
  selector: 'app-component-swap-table',
  templateUrl: './component-swap-table.component.html',
  styleUrl: './component-swap-table.component.scss'
})
export class ComponentSwapTableComponent {
  levelMasterDialog: boolean = false;
  cols: any[];
  filterProps: any;
  searchResult: SearchResult<ComponentLimitSwap> = new SearchResult<ComponentLimitSwap>();
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: number = 10;
  page: number;
  tamilText: any;

  //required fields property
  ngSelectInvalid: boolean = false;
  codeError: string;
  roleCode: any;
  villageLevelList: LevelMasterDto[];
  districtLevelList: LevelMasterDto[];
  blockLevelList: LevelMasterDto[];
  twadOfficedialog:boolean = false;
  currentPath: string;
  headingText: string;
  voucherUrl: any;
  transferType: string;


  constructor(
 
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private loginService: SigninService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    protected componentSwapService:ComponentSwapService,
    public reportService:ReportService,
    private levelMasterService: LevelMasterService,
    protected router: Router,
    protected paymentService:PaymentService,
    private sessionStorageService:SessionStorageService,
    private location: Location,
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.currentPath = this.location.path();
    if(this.currentPath === '/component-swap-list') {
      this.headingText =  'Component Wise Account Swap List';
      this.voucherUrl = '/component-swap-page';
      this.transferType = 'CS';
    }
    else {
      this.headingText =  'Fund Transfer to Village List';
      this.voucherUrl = '/funds-transfer-village-page';
      this.transferType = 'MS';
    }
    
    this.roleCode = this.sessionStorageService.retrieve('user');
    this.cols = [
      {
        field: 'voucherNumber',
        header: 'Voucher No',
        isFilterable: true,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'permanentVoucherNumber',
        header: 'Permanent Voucher Number',
        isFilterable: true,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
      },
      {
        field: 'levelMaster',
        header: 'Level Master',
        isFilterable: true,
        width: 5,
        isSelectcolumn: true,
        type:'dropDown'
      },
     
      {
        field: 'finYear',
        header: 'Fin Year',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type:'dropDown'
      },
      {
        field: 'fromComponentMaster',
        header: 'From Component Master',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type:'dropDown'
      },
     
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        isFilterable: false,
        width: 25,
        isSelectcolumn: true,
        type:'date'
      },

     
    ];
    
  }


  ngAfterViewInit() {
    this.updateDialogFont();
    
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }


  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    let query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterProps.push(query);
    query = new filterQuery();
    query.key = 'transferType';
    query.operation = 'equals';
    query.value = this.transferType;
    this.filterProps.push(query);
    query = new filterQuery();
    if(this.roleCode.role.code!== 'SA') {
      query = new filterQuery();
      query.key = 'levelMaster.id';
      query.operation = 'equals';
      query.value = this.roleCode.levelMasterId;
      this.filterProps.push(query);
    }
    this.componentSwapService
      .filter({ ...filter }, this.filterProps)
      .subscribe(
        (res: HttpResponse<ComponentLimitSwap[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: ComponentLimitSwap[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    data.forEach(element => {
      
      if(element.signCount ===1 && element.signatureSuperintendent === null && (this.roleCode?.role?.code === 'DS' ||  this.roleCode?.role?.code  === 'SMAKER')){
        element.edit = true;
        element.delete = true;
      }
      else if(element.signCount ===2 && element.signatureAd === null  && element.signatureSuperintendent !== null &&  (this.roleCode?.role?.code === 'DA' ||  this.roleCode?.role?.code  === 'SC')){
        element.edit = true;
      }
      
      else{
        element.edit = false;
      }
    });
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }
  async onLazyLoad(event) {
    this.filterProps = [];
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  
  openNew(){
    this.paymentService.getVoucher().subscribe(
      (res: HttpResponse<any>) => {
        this.router.navigate([this.voucherUrl], {
          queryParams: {
            VoucherNo: res.body,
          },
        });
      },
      () => {}
    );
  }

  onSelectRow($event) {
    if ($event.type === 'select') {
      this.router.navigate([this.voucherUrl], {
        queryParams: {
          id: $event.value.id,
        },
      });
    }
    else if ($event.type === 'view') {
      this.router.navigate([this.voucherUrl], {
        queryParams: {
          id: $event.value.id,
          view: true
        },
      });
    }
    else {
      this.spinner.show();
      this.componentSwapService.rejectTransaction($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.loadPage(this.page);
        },
        (onError) => {
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.title, '');
          } else {
            this.notificationService.alertError('Error in Reject', '');
          }

          this.spinner.hide();
        }
      );
    }   
  }
}
