import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { SearchResult } from 'src/app/shared/models/search-result';
import { InspectionReportDto } from '../report.model';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { ReportService } from '../report.service';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { Router } from '@angular/router';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';

@Component({
  selector: 'app-village-salary-report',
  templateUrl: './village-salary-report.component.html',
  styleUrl: './village-salary-report.component.scss'
})
export class VillageSalaryReportComponent {
  levelMasterDialog: boolean = false;
  cols: any[];
  filterProps: any;
  searchResult: SearchResult<any> = new SearchResult<any>();
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: number = 10;
  page: number;
  tamilText: any;

  //required fields property
  ngSelectInvalid: boolean = false;
  codeError: string;
  roleCode: any;
  villageLevelList: LevelMasterDto[];
  districtLevelList: LevelMasterDto[];
  blockLevelList: LevelMasterDto[];
  blockMaster: any;
  districtMaster: any;
  villagelevelMaster: any;
  finYearList: FinYear[];
  finYear: FinYear;
  defaultFinyear: FinYear;
  minDate: Date;
  maxDate: Date;
  fromDate: Date;
  startDate: string;
  toDate: Date;
  endDate: string;
  dateError: boolean;
  search: any;
  componentType: any;
  compId:number;
  toMaxDate:Date;
  villageList: any;
  levelMasId: any;

  constructor(

    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    public reportService: ReportService,
    private levelMasterService: LevelMasterService,
    private limitTransListService: LimitTransListService,
    protected router: Router,
    public sessionStorage : SessionStorageService,
    public componentTypeService: ComponentTypeService,
    public dashboardService:DashboardService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  async ngOnInit() {
    await this.loadCompType();
    this.toMaxDate = new Date();
    this.toMaxDate.setDate(new Date().getDate() - 1);
    this.finYearList = this.sessionStorage.retrieve('finyear');
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
    this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
    this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
    this.maxDate = new Date();
    if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
      this.districtMaster = this.sessionStorage.retrieve('levelMaster');
      this.cols = [
        {
          field: 'blockName',
          header: 'block Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'villageName',
          header: 'Village Panchayat Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'lgdCode',
          header: 'LGD Code',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'transactionDate',
          header: 'Transaction Date',
          jhiTranslate: 'tableHead.transaction.date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        },
        {
          field: 'openingBalance',
          header: 'Opening Balance',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'salaryAllocation',
          header: 'Salary Allocation current Month',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'salaryPaid',
          header: 'Salary Paid',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'balanceAmount',
          header: 'Balance Available',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
      ];
      this.onDistrictLevelChange();
    } else if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
      this.blockMaster = this.sessionStorage.retrieve('levelMaster');
      if(this.sessionStorage.retrieve('roleCode') === 'ZVM') {
        this.dashboardService.getVillagePanchayatByZonal().subscribe(
          (res) => {
            console.log('resZonal', res);
            this.villageList = res;
          },
          () => { }
        );
      }
      else {
        this.onBlockLevelChange();
      }
      this.cols = [
        {
          field: 'villageName',
          header: 'Village Panchayat Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'lgdCode',
          header: 'LGD Code',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'transactionDate',
          header: 'Transaction Date',
          jhiTranslate: 'tableHead.transaction.date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        },
        {
          field: 'openingBalance',
          header: 'Opening Balance',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'salaryAllocation',
          header: 'Salary Allocation current Month',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'salaryPaid',
          header: 'Salary Paid',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'balanceAmount',
          header: 'Balance Available',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
      ];
      
    }
    else if (this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'VILLAGE') {
      this.search = true;
      this.cols = [
        {
          field: 'villageName',
          header: 'Village Panchayat Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'lgdCode',
          header: 'LGD Code',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'transactionDate',
          header: 'Transaction Date',
          jhiTranslate: 'tableHead.transaction.date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        },
        {
          field: 'openingBalance',
          header: 'Opening Balance',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'salaryAllocation',
          header: 'Salary Allocation current Month',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'salaryPaid',
          header: 'Salary Paid',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'balanceAmount',
          header: 'Balance Available',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
      ];

    }
    else {
      this.onStateLevelChange();
      this.cols = [
        {
          field: 'districtname',
          header: 'District Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'blockName',
          header: 'block Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'villageName',
          header: 'Village Panchayat Name',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'lgdCode',
          header: 'LGD Code',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'transactionDate',
          header: 'Transaction Date',
          jhiTranslate: 'tableHead.transaction.date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        },
        {
          field: 'openingBalance',
          header: 'Opening Balance',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'salaryAllocation',
          header: 'Salary Allocation current Month',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'salaryPaid',
          header: 'Salary Paid',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
        {
          field: 'balanceAmount',
          header: 'Balance Available',
          type: 'amount',
          isFilterable: false,
          width: 25,
          isSelectcolumn: true,
        },
      ];
    }
    
   

  }


  ngAfterViewInit() {
    this.updateDialogFont();

  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }


  loadPage(page?: number, dontNavigate?: boolean): void {
    const pageToLoad: number = page ?? this.page ?? 1;
    if(!this.compId) {
      this.notificationService.alertError('please Select Component','');
      return;
    }
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      levelType: this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase(),
      finYearId: this.finYear.id,
      compId:this.compId
    };
   
    if((this.districtMaster || this.blockMaster || this.villagelevelMaster) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE') {
      console.log('districtfilter')
      this.search = true;
    }
    else if((this.blockMaster || this.villagelevelMaster) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
      this.search = true;
    }
    else if( (this.villagelevelMaster || this.levelMasId) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
      this.search = true;
    }
    else if(this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'VILLAGE') {
      this.search = true;
    }
    else {
      this.search = false;
    }
    if(this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE') {
      if (this.districtMaster && this.blockMaster && this.villagelevelMaster ) {
        filter['districtId'] = this.districtMaster.id;
        filter['blockId'] = this.blockMaster.id;
        filter['villageId'] = this.villagelevelMaster.id;
      }
      else {
       
        this.notificationService.alertError('Please select a district, block, and village to proceed.', '');
        this.spinner.hide();
        return
        
      }
    }
    if(this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
      if (this.blockMaster && this.villagelevelMaster) {
        filter['blockId'] = this.blockMaster.id;
        filter['villageId'] = this.villagelevelMaster.id;
      }
      else {
        
        this.notificationService.alertError('Please select a block, and village to proceed.', '');
        this.spinner.hide();
        return
        
      } 
    }
    if(this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
      if (this.villagelevelMaster || this.levelMasId) {
        filter['villageId'] = this.levelMasId ? this.levelMasId :this.villagelevelMaster.id;
      }
      else {
       
        this.notificationService.alertError('Please select a village to proceed.', '');
        this.spinner.hide();
        return
        
      }
    } 
    
    if (this.fromDate && this.toDate) {
      const date = new Date(this.fromDate);
      this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
      const enddate = new Date(this.toDate);
      this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} ${this.pad(enddate.getHours())}:${this.pad(enddate.getMinutes())}:${this.pad(enddate.getSeconds())}`;
      if (this.startDate > this.endDate) {
        this.dateError = true;
        return
      }
      this.dateError = false;
      filter['fromDate'] = this.startDate;
      filter['toDate'] = this.endDate;
    }
   
    if(this.search) {
      this.spinner.show();
      this.reportService
      .getVillageSalaryReport({ ...filter })
      .subscribe(
        (res: HttpResponse<InspectionReportDto[]>) => {

          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.spinner.hide();
          this.onError();
        }
      );
    }
   
  }

  pad(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }

  protected onSuccess(
    data: InspectionReportDto[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    data.forEach(element => {
      switch (element.paymentStatus) {
        case null:
          element.paymentStatus = 'Not Initiated';
          break;
        case 'O.K.':
          element.paymentStatus = 'Success';
          break;
        case 'failed':
          element.paymentStatus = 'Failed';
          break;
        case 'processing':
          element.paymentStatus = 'Processing';
          break;
        default:
          break;
      }
    
    });
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }





  onLazyLoad(event) {
    this.filterProps = [];
    
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

 

  loadCompType(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.componentTypeService.find(4).subscribe(
        (res: HttpResponse<any>) => {
         this.componentType = res.body.filter(item=>item.componentCode === 'C' || item.componentCode === 'D');
         resolve(this.componentType);
        },
        () => {resolve(null); }
      )
    });
  }

 

  parseDate(dateString) {
    const [day, month, year] = dateString.split('/');
    return new Date(`${month}/${day}/${year}`);
  }

  onchangeFinYear() {
    if (this.finYear.name === this.defaultFinyear.name) {
      this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
      this.maxDate = new Date();
    }
    else {
      this.minDate = this.parseDate('01/04/' + this.finYear.name.split('-')[0]);
      this.maxDate = this.parseDate('31/03/' + this.finYear.name.split('-')[1]);
    }
  }

  getLevelMaster(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.levelMasterService.getLevelMaster(this.sessionStorage.retrieve('levelmasterId')).subscribe(
        (res) => {
          this.sessionStorage.store('levelMaster',res.body);
          resolve('');
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }
  checkStatus() {
    let filter = {
      reportName: 'VillageSalaryReport',
    };
    this.spinner.show();
    this.reportService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body
        this.saveBlobToExcel(b.data, 'Village Salary Report');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.message, '');
        this.spinner.hide();
      }
    );
  }
  downloadExport() {
    if(!this.compId) {
      this.notificationService.alertError('please Select Component','');
      return;
    }
    let filter = {
      levelType: this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase(),
      finYearId: this.finYear.id,
      reportName: 'VillageSalaryReport',
      compId:this.compId
    };
    if((this.districtMaster || this.blockMaster || this.villagelevelMaster) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE') {
      console.log('districtfilter')
      this.search = true;
    }
    else if((this.blockMaster || this.villagelevelMaster) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
      this.search = true;
    }
    else if((this.villagelevelMaster || this.levelMasId) && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
      this.search = true;
    }
    else if(this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'VILLAGE') {
      this.search = true;
    }
    else {
      this.search = false;
    }
    if(this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE') {
      if (this.districtMaster && this.blockMaster && this.villagelevelMaster ) {
        filter['districtId'] = this.districtMaster.id;
        filter['blockId'] = this.blockMaster.id;
        filter['villageId'] = this.villagelevelMaster.id;
      }
      else {
        this.search = false;
        this.notificationService.alertError('Please select a district, block, and village to proceed.', '');
        this.spinner.hide();
        return
        
      }
    }
    if(this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT') {
      if (this.blockMaster && this.villagelevelMaster) {
        filter['blockId'] = this.blockMaster.id;
        filter['villageId'] = this.villagelevelMaster.id;
      }
      else {
        this.search = false;
        this.notificationService.alertError('Please select a block, and village to proceed.', '');
        this.spinner.hide();
        return
        
      }
    }
    if(this.search && this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'BLOCK') {
      if (this.villagelevelMaster || this.levelMasId) {
        filter['villageId'] = this.levelMasId ? this.levelMasId :this.villagelevelMaster.id;
      }
      else {
        this.search = false;
        this.notificationService.alertError('Please select a village to proceed.', '');
        this.spinner.hide();
        return
        
      }
    } 
    if (this.fromDate && this.toDate) {
      const date = new Date(this.fromDate);
      this.startDate = `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
      const enddate = new Date(this.toDate);
      this.endDate = `${enddate.getFullYear()}-${this.pad(enddate.getMonth() + 1)}-${this.pad(enddate.getDate())} ${this.pad(enddate.getHours())}:${this.pad(enddate.getMinutes())}:${this.pad(enddate.getSeconds())}`;
      if (this.startDate > this.endDate) {
        this.dateError = true;
        return
      }
      this.dateError = false;
      filter['fromDate'] = this.startDate;
      filter['toDate'] = this.endDate;
    }
   
   
    if(this.search) {
      this.spinner.show();
      this.reportService.reportAsyncDownload({ ...filter }).subscribe(
        (res) => {
          this.notificationService.alertSuccess(res.body, '');
          this.spinner.hide();
        },
        (onError) => {
          onError.error = JSON.parse(onError.error);
          this.notificationService.alertError(onError.error.message,'');
          this.spinner.hide();
        }
      );
    }
   
  }

  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => { }
        );
    }
  }


  onDistrictLevelChange($event?) {
    this.blockMaster = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtMaster.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtMaster.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    }
  }



  onBlockLevelChange($event?) {
    this.villagelevelMaster = null;
    this.villageLevelList = [];
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.blockMaster.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.blockMaster.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    }
  }

  
}
