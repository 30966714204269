import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Component } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-other-funds',
  templateUrl: './other-funds.component.html',
  styleUrl: './other-funds.component.scss',
  providers: [CurrencyPipe],
})
export class OtherFundsComponent {
  fundDetails: any;
  voucherSummaryDetails: any;
  lang: string;
  finYear: any;
  finYearList: any;
  defaultFinyear: any;

  constructor(
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    protected router: Router,
    public fontService: FontService,
    private currencyPipe: CurrencyPipe,
    private sessionStorage: SessionStorageService

  ) {

  }
  ngOnInit() {
    this.finYearList = this.sessionStorage.retrieve('finYear');
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
    this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
    this.onFinYearChange();
  }



  getFundReport() {
    this.spinner.show();
    this.dashboardService
      .getFundReport(this.sessionStorage.retrieve('levelmasterId'), this.finYear.id)
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.fundDetails = [];
          res.body.forEach((element, i) => {
            switch (element.compName) {
              
              case 'Surplus Fund':
                element.compName = 'Surplus Fund - ' + 'F3'
                break;
              default:
                element.compName = null;
                break;
            }
            element.balancePercent = Math.floor((res.body[i].balanceAmount / res.body[i].grandSanctioned) * 100);
            element.allocatedAmount = this.currencyPipe.transform(res.body[i].allocatedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.utilizedAmount = this.currencyPipe.transform(res.body[i].utilizedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.balanceAmount = this.currencyPipe.transform(res.body[i].balanceAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.openingBalance = this.currencyPipe.transform(res.body[i].openingBalance, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.grandSanctioned = this.currencyPipe.transform(res.body[i].grandSanctioned, 'INR', 'symbol', '1.2-2', 'en-IN')
            if (element.compName) {
              this.fundDetails.push(element);
            }
          });
          console.log('loghgssssss', this.fundDetails);
        },
        (onError) => {
          this.spinner.hide();
        }
      );
  }

  getvoucherSummaryReport() {
    this.spinner.show();
    this.dashboardService
      .getVoucherSummary(this.sessionStorage.retrieve('levelmasterId'), this.finYear.id)
      .subscribe(
        (res: any) => {
          console.log('loggs', res.body)
          this.spinner.hide();
          this.voucherSummaryDetails = [];
          res.body.forEach((element, i) => {
            switch (element.compName) {
              case 'Surplus Fund':
                element.compName = 'Surplus Fund - ' + 'F3'
                break;

              default:
                element.compName = null;
                break;
            }
            element.balancePercent = Math.floor((res.body[i].balanceAmount / res.body[i].grandSanctioned) * 100);
            element.allocatedAmount = this.currencyPipe.transform(res.body[i].allocatedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.utilizedAmount = this.currencyPipe.transform(res.body[i].utilizedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.balanceAmount = this.currencyPipe.transform(res.body[i].balanceAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.openingBalance = this.currencyPipe.transform(res.body[i].openingBalance, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.grandSanctioned = this.currencyPipe.transform(res.body[i].grandSanctioned, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.approvedVouchersValue = this.currencyPipe.transform(res.body[i].approvedVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.pendingVouchersValue = this.currencyPipe.transform(res.body[i].pendingVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.rejectedVouchersValue = this.currencyPipe.transform(res.body[i].rejectedVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.totalVouchersValue = this.currencyPipe.transform(res.body[i].totalVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            if (element.compName) {
              this.voucherSummaryDetails.push(element);
            }


          });
        },
        (onError) => {
          this.spinner.hide();
        }
      );
  }

  onFinYearChange () {
    this.getFundReport();
    this.getvoucherSummaryReport();
  }

}
