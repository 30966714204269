import { LevelMaster } from "src/app/module/level-master/level-master";
import { ComponentMasterDto } from "../payment-voucher/payment-voucher.model";
import { AgencyDto } from "src/app/module/agency/agency";
import { FinYearDto } from "src/app/shared/common.model";
import { LevelType } from "src/app/module/level-type/level-type";

export class SwapTransaction {
    componentLimitSwap: ComponentLimitSwap;
    componentLimitSwapDescriptions: ComponentLimitSwapDescription[];
}

export class ComponentLimitSwap {
    id: number;
    levelMaster: LevelMaster;
    implementingAgency: any;
    fromComponentMaster: ComponentMasterDto;
    finYear: FinYearDto;
    activeFlag: string;
    transactionDate: Date;
    fileUpload: string;
    signatureSuperintendent: string;
    signatureAd: string;
    signCount: number;
    signStatus: boolean;
    levelType: LevelType;
    voucherNumber: string;
    permanentVoucherNumber: string;
    createdOn: Date;
    updatedOn: Date;
    createdBy: number;
    updatedBy: number;
    edit: boolean;
    delete: boolean;
    remarks: string;
}

export class ComponentLimitSwapDescription {
    id: number;
    componentLimitSwap: number;
    districtLevelMaster: number;
    districtLevelMasterName: string;
    blockLevelMaster: number;
    blockLevelMasterName: string;
    villageLevelMaster: number;
    villageLevelMasterName: string;
    villageLgdCode: string;
    toComponentMaster: number;
    toComponentMasterName: string;
    transactionAmount: number;
    activeFlag: string;
    createdOn: Date;
    fromComponementAvailableAmount: number;
    toComponementAvailableAmount: number;
    implementingAgency: any;
}

export class ComponentLimitData {
    id: number;
    componentLimitSwap: ComponentLimitSwap;
    districtLevelMaster:LevelMaster;
    blockLevelMaster: LevelMaster;
    villageLevelMaster: LevelMaster;
    villageLgdCode: string;
    implementingAgency: AgencyDto;
    toComponentMaster: ComponentMasterDto;
    transactionAmount: number;
    activeFlag: string;
    createdOn: Date;  // Use Date for Timestamp
    createdBy: number;
    fromComponementAvailableAmount: number;
    toComponementAvailableAmount: number;
}



