<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Village Panchayat Salary Report
                    </h5>
                </ng-template>

            </p-toolbar>
        </div>
    </div>
    <div class="row px-4">
        <div class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Fin Year</label>
            <ng-select id="scheme" [(ngModel)]="finYear" [items]="finYearList" bindLabel="name" appearance="outline" (change)="onchangeFinYear()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">From Date</label><br>
            <p-calendar id="fromDate" dateFormat="dd/mm/yy" [(ngModel)]="fromDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate" [minDate]="minDate"></p-calendar>

        </div>
        <div class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">To Date</label><br>
            <p-calendar id="toDate" dateFormat="dd/mm/yy" [(ngModel)]="toDate" [showIcon]="true" styleClass="datepicker" [maxDate]="toMaxDate" [minDate]="minDate"></p-calendar>
        </div>
        <div class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Select Component</label>
            <ng-select id="scheme" [(ngModel)]="compId" [items]="componentType" bindValue="id" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>

    </div>
    <div class="row px-4 mt-2">
        <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE'" class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">District</label>
            <ng-select id="scheme" [(ngModel)]="districtMaster" [items]="districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'STATE' || sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase() === 'DISTRICT'" class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Block</label>
            <ng-select id="scheme" [(ngModel)]="blockMaster" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE' && sessionStorage.retrieve('roleCode') !== 'ZVM'" class="col-2">
            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="scheme" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" [(ngModel)]="villagelevelMaster" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
            </ng-select>
        </div>
        <div *ngIf="sessionStorage.retrieve('levelMaster')?.levelTypeDto?.name?.toUpperCase() !== 'VILLAGE' && sessionStorage.retrieve('roleCode') === 'ZVM'" class="col-lg-2 col-md-4 col-sm-3">
            <label class="form-control-label req" for="Village" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="gender" bindLabel="levelMasterName" bindValue="levelMasterId" [items]="villageList" appearance="outline" [(ngModel)]="levelMasId" [closeOnSelect]="true" [clearable]="false" class="form-control">
            </ng-select>
        </div>
        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-success custom-button" (click)="loadPage(1)" styleClass="button">
  <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.searchbutton">Search</span>
</button>
        </div>
        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple class="p-button-danger custom-button">
  <span class="button-label" [ngClass]="fontService.buttonClass" (click)="this.search = false;districtMaster = null; blockMaster = null; villagelevelMaster = null; finYear = defaultFinyear; fromDate = null;toDate = null;" jhiTranslate="common.clearbutton">clear</span>
</button>
        </div>
        <div class="col-1" style="display: flex;align-items: flex-end;">
            <button pButton pRipple label="Download" class="p-button-success custom-button" (click)="downloadExport()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                ></span>
            </button>
        </div>
        <div class="col-2" style="display: flex;align-items: flex-end;">
            <button pButton pRipple label="Check Download Status" class="p-button-success custom-button" (click)="checkStatus()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                ></span>
            </button>
        </div>
    </div>
    <div #customerTable id="entities" *ngIf="search" class="col-12 px-4 mt-3">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [lazyLoadOnInit]="true" [enableAction]="true" [enableView]="false"></custom-filter-table>
    </div>
</div>