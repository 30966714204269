import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UniversalVendor } from './universal-vendor.model';
import { environment } from 'src/environments/environment';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { CommercialVendorService } from '../commercial-vendors/commercial-vendors.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PersonalVendorService } from '../personal-vendors/personal-vendors.service';
import { UniversalVendorService } from './universal-vendor.service';
import { ValidationResponse } from '../personal-vendors/personal-vendors';
import { SearchResult } from 'src/app/shared/models/search-result';
import { Dialog } from 'primeng/dialog';

@Component({
  selector: 'app-universal-vendor',
  templateUrl: './universal-vendor.component.html',
  styleUrls: ['./universal-vendor.component.scss']
})
export class UniversalVendorComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;

  personalVendorsDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<UniversalVendor> =
    new SearchResult<UniversalVendor>();
 
  submitted?: boolean;
  selectedMapping: string;
  checked: boolean;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  validationRes: ValidationResponse;
  readonly: boolean;
  vendorName: string;
  currentEnvironment: string;
  bankBranchDetailsRes: any;

  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  public verifyClicked: boolean;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showStaffError: string;
  showMobileUniError: string;
  emailUniError: string;
  aadhaarNoUniError: string;
  panNoUniError: string;
  accountNoUniError: any;
  bankList: any;
  bankBranchList: any;
  bankBranch: any;
  accountInvalidError: any;
  roleCode: any;
  disableEdit: boolean = false;
  edit: boolean;
  BlockLevelDto: any;
  districtDto: any;
  villageLevelDto: any;
  accountNo: string;
  levelType = ['District', 'Block', 'Village'];
  selectedLevelType: string;
  disableDelete: boolean = true;
  isDelete: boolean;
  universalVendor: UniversalVendor;
  department: any;
  constructor(
    private personalVendorService: PersonalVendorService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private commercialVendorservice: CommercialVendorService,
    protected loginService: SigninService,
    protected universalVendorService:UniversalVendorService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.loadPage();
    this.currentEnvironment = environment.environment;
    
    this.commercialVendorservice.getBankList().subscribe(
      (res: HttpResponse<[]>) => {
        this.bankList = res.body;
      },
      () => {}
    );
    this.universalVendorService.departmentFilter().subscribe(
      (res: HttpResponse<[]>) => {
        this.department = res.body;
      },
      () => {}
    );
    this.cols = [
      {
        field: 'uniqueId',
        header: 'Name',
        jhiTranslate: 'tableHead.uniqueId',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field: 'vendorName',
        header: 'Name',
        jhiTranslate: 'tableHead.name',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field: 'emailId',
        header: 'Email',
        jhiTranslate: 'tableHead.email',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'bankName',
        header: 'Bank Name',
        jhiTranslate: 'vendor.bank.name',
        isSortable: false,
        isFilterable: false,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'ifscCode',
        header: 'Ifsc Code',
        jhiTranslate: 'vendor.ifscode',
        isSortable: false,
        isFilterable: false,
        width: 13,
        isSelectcolumn: true,
      },

      {
        field: 'accountNumber',
        header: 'Account No',
        jhiTranslate: 'tableHead.account.number',
        isSortable: false,
        isFilterable: false,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'createdOn',
        header: 'Created On',
        jhiTranslate: 'tableHead.createdon',
        isSortable: true,
        isFilterable: false,
        width: 13,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
    this.updateDialogFont();
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.universalVendor = new UniversalVendor();
    this.submitted = false;
    this.personalVendorsDialog = true;
    this.readonly = false;
  }

  
 


  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
   
    
      this.universalVendorService
        .filter({ ...filterQuery })
        .subscribe(
          (res: HttpResponse<UniversalVendor[]>) => {
            this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          },
          () => {
            this.onError();
          }
        );
    
  }
  getBankBranchList(event?) {
    this.bankBranch = null;
    let filter = [];
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.universalVendor.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };
    if (event) {
      filter.push({
        key: 'bankBranch',
        operation: 'startswith',
        value: event.term.toUpperCase(),
      });
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    } else {
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    }
  }

  getBankBranchdetais() {
    let filter = [];
    filter.push({
      key: 'bankIfsc',
      operation: 'equals',
      value: this.universalVendor.ifscCode.toUpperCase(),
    });
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.universalVendor.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };

    this.commercialVendorservice
      .getBankBranchList(filter, filterQuery)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.bankBranch = res.body[0];
        },
        () => {}
      );
  }

  getBankIfsc() {
    this.universalVendor.bankBranch = this.bankBranch.bankBranch;
    this.commercialVendorservice
      .getBankIfscCode(
        this.universalVendor.bankName,
        this.universalVendor.bankBranch
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.universalVendor.ifscCode = res.body[0];
        },
        () => {}
      );
  }

  accountValidation() {
    if (
      !this.universalVendor.ifscCode ||
      !this.universalVendor.accountNumber ||
      !this.universalVendor.bankAccName ||
      !this.universalVendor.bankName ||
      !this.universalVendor.bankBranch
    ) {
      this.accountInvalidError =
        'Please input valid account number and bank details';
      return;
    }
    this.verifyClicked = true;
    this.accountInvalidError = null;
    this.spinner.show();
    this.commercialVendorservice
      .getaccountValidation(
        this.universalVendor.accountNumber,
        this.universalVendor.ifscCode
      )
      .subscribe(
        (res: any) => {
          // this.validationRes=JSON.parse(res.body);
          this.validationRes = res.body;
          if (this.validationRes.bankTxnStatus === true) {
            this.accountInvalidError = null;
            this.notificationService.alertSuccess(
              'Account Verfied Sucessfully',
              ''
            );
            this.readonly = true;
          } else {
            this.accountInvalidError =
              'Account Verification Failed due to ' +
              this.validationRes.bankResponse;
            this.notificationService.alertError(
              'Account Verification Failed due to ' +
                this.validationRes.bankResponse,
              ''
            );
            this.readonly = false;
            this.verifyClicked = false;
          }
          this.spinner.hide();
        },
        (error) => {
          if (error.status === 400) {
            this.accountInvalidError =
              'Account Verification Failed. ' + error.error.message;
            this.notificationService.alertError(
              'Account Verification Failed. ' + error.error.message,
              ''
            );
          } else {
            this.accountInvalidError =
              'Please input valid account number and branch details';
            this.notificationService.alertError(
              'Account Verification Failed',
              ''
            );
          }
          this.readonly = false;
          this.verifyClicked = false;
          this.spinner.hide();
        }
      );
  }

  protected onSuccess(
    data: UniversalVendor[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
   
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.tanError = false;
    this.tinError = false;
    this.gstError = false;
    this.ifscError = false;
    this.universalVendor = new UniversalVendor();
    this.ngSelectInvalid = false;
    this.accountInvalidError = false;
    this.verifyClicked = true;
    this.personalVendorsDialog = false;
    this.submitted = false;
    this.bankBranch = null;
    this.edit = false;
    this.isDelete = false;
  }

 

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(
      this.universalVendor.emailId
    );
    !this.universalVendor.emailId ? (this.emailError = false) : true;

    return !this.emailError && !!this.universalVendor.emailId;
  }



  validateTan() {
    this.tanError = !this.validationService.isPanValid(
      this.universalVendor.tanNo
    );
    !this.universalVendor.tanNo ? (this.tanError = false) : true;

    return !this.tanError && !!this.universalVendor.tanNo;
  }

  validateTin() {
    this.tinError = !this.validationService.isPanValid(
      this.universalVendor.tinNo
    );
    !this.universalVendor.tinNo ? (this.tinError = false) : true;

    return !this.tinError && !!this.universalVendor.tinNo;
  }

  

  validateGst() {
    this.gstError = !this.validationService.isPanValid(
      this.universalVendor.gstNo
    );
    !this.universalVendor.gstNo ? (this.gstError = false) : true;

    return !this.gstError && !!this.universalVendor.gstNo;
  }

  validateIfsc() {
    this.ifscError = !this.validationService.isIfscValid(
      this.universalVendor.ifscCode
    );
    !this.universalVendor.ifscCode ? (this.ifscError = false) : true;

    return !this.ifscError && !!this.universalVendor.ifscCode;
  }
  accountDetailsChanged() {
    this.verifyClicked = false;
    if (
      this.currentEnvironment == 'uat' ||
      this.currentEnvironment === 'production'
    ) {
      if (!this.ifscError) {
        this.spinner.show();
        this.commercialVendorservice
          .bankBranchDetails(this.universalVendor.ifscCode)
          .subscribe(
            (res) => {
              this.spinner.hide();
              this.bankBranchDetailsRes = res.body;
              console.log(this.bankBranchDetailsRes, 'bankbranchdetails');
              this.universalVendor.bankBranch =
                this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Branch_Name;
              this.universalVendor.bankName =
                this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Bank_Name;
            },
            () => {
              this.spinner.hide();
              this.notificationService.alertError('Error Occured', '');
            }
          );
      }
    }
  }

  accountChanged() {
    this.verifyClicked = false;
  }

  saveUniversalVendor() {
    const isFormValid =
     this.validateEmail();
      this.universalVendor.vendorName &&
      this.universalVendor.accountNumber &&
      this.universalVendor.emailId &&
      this.universalVendor.bankAccName &&
      this.universalVendor.accountNumber &&
      this.universalVendor.ifscCode &&
      !this.accountInvalidError &&
      this.verifyClicked;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    this.universalVendor.levelMasterId = 1;
    this.universalVendorService.save(this.universalVendor).subscribe(
      (res: HttpResponse<any>) => {
        this.personalVendorsDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (err) => {
        this.onError();
        if (err.error.status === 400) {
          this.notificationService.alertError('Error! ' + err.error.title, '');
        } else {
          this.notificationService.alertError('Unexpected Error!', '');
        }
      }
    );
  }

  onSelectRow($event) {
    this.universalVendorService.getVendor($event.value.id).subscribe(
      (res: HttpResponse<any>) => {
        this.readonly = true;
        this.edit = true;
        this.universalVendor = res.body;
        this.personalVendorsDialog = true;
        if (this.universalVendor.bankName) {
          this.getBankBranchList();
          this.getBankBranchdetais();
        }
      },
      () => {
        this.onError();
      }
    );
    if ($event.type === 'select') {
      this.readonly = true;
      this.edit = true;
    } else {
     
    }
  }

  

 

  
  

}
