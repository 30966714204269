<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.vendor.electedRepresentative">
                        Elected Representative
                    </h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                          (click)="deleteSelectedAgencys()"
                          [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="!(roleCode === 'DM' || roleCode === 'BM' || this.roleCode ==='SM') && currentPath !== '/electedRepresentativeComponent-approve'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
                        <span class="button-label" jhiTranslate="common.newbutton"></span>
                    </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                          chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                      <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <div *ngIf="this.currentPath !== '/electedRepresentativeComponent-approve'" class="row px-4">
                    <div class="col">
                        <label class="form-control-label" for="name" [ngClass]="fontService.labelClass" jhiTranslate="payments.viewTable.vendorName">Vendor Name</label>
                        <input type="text" class="form-control" name="name" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="vendorName" id="name" />
                    </div>
                    <div class="col">
                        <label class="form-control-label" for="name" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno">Account No</label>
                        <input type="text" class="form-control" name="name" maxlength="60" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="accountNo" id="name" />
                    </div>
                    <div class="col">
                        <label class="form-control-label" for="name" [ngClass]="fontService.labelClass" jhiTranslate="tableHead.uniqueId"></label>
                        <input type="text" class="form-control" name="name" maxlength="60" [(ngModel)]="uniqueId" id="name" />
                    </div>
                    <div *ngIf="roleCode === 'SA'" class="col">
                        <label class="form-control-label" for="name" [ngClass]="fontService.labelClass">Level Type</label>
                        <ng-select id="vendorType" [(ngModel)]="selectedLevelType" [items]="levelType" appearance="outline" [closeOnSelect]="true" (change)="districtDto=null;BlockLevelDto=null;villageLevelDto=null" [clearable]="true" class="form-control">
                        </ng-select>
                    </div>
                    <div *ngIf="selectedLevelType" class="col">
                        <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.district">District</label>
                        <ng-select id="scheme" [(ngModel)]="districtDto" [items]="this.districtLevelList" bindLabel="name" appearance="outline" (change)="onDistrictLevelChange()" (search)="onStateLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                        </ng-select>
                    </div>
                    <div *ngIf="selectedLevelType !== 'District' && selectedLevelType" class="col">
                        <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.block">Block</label>
                        <ng-select id="scheme" [appendTo]="'body'" [(ngModel)]="BlockLevelDto" [items]="blockLevelList" bindLabel="name" appearance="outline" (change)="onBlockLevelChange()" (search)="onDistrictLevelChange($event)" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                        </ng-select>
                    </div>
                    <div *ngIf="selectedLevelType !== 'District' && selectedLevelType !== 'Block' && selectedLevelType" class="col">
                        <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.village">Village</label>
                        <ng-select id="scheme" [appendTo]="'body'" [items]="villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockLevelChange($event)" [(ngModel)]="villageLevelDto" [closeOnSelect]="true" [clearable]="true" class="form-control dropdown-width">
                        </ng-select>
                    </div>
                </div>
            </ng-template>
        </p-toolbar>
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <div *ngIf="this.currentPath !== '/electedRepresentativeComponent-approve'" class="row px-4">
                    <div class="col" style="display: flex;align-items: flex-end;">
                        <button pButton pRipple class="p-button-success custom-button" (click)="this.filterProps=[];loadPage()" styleClass="button">
              <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.searchbutton">Search</span>
          </button>
                    </div>
                    <div class="col mt-3" style="display: flex;align-items: flex-end;">
                        <button pButton pRipple class="p-button-danger custom-button" (click)="vendorName = null;accountNo=null;villageLevelDto=null;BlockLevelDto=null;districtDto=null;selectedLevelType=null;uniqueId=null;this.filterProps=[];loadPage(1)">
              <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.clearbutton">clear</span>
          </button>
                    </div>
                </div>
            </ng-template>
        </p-toolbar>
        <div #customerTable id="entities" class="col-12 px-4 mt-2">
            <div class="row px-4">
                <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [lazyLoadOnInit]="false" (searchParam)="onLazyLoad($event)" (emitRow)="onSelectRow($event)" [disableDeleteIcon]="disableDelete" [disableEditIcon]="disableEdit" [enableVendorApproved]="currentPath === '/electedRepresentativeComponent-approve'"></custom-filter-table>
            </div>
        </div>
        <p-dialog #dialog [(visible)]="employeeDialog" [style]="{ width: '850px' }" [modal]="true" styleClass="ib-dialog" (onHide)="hideDialog()">
            <p-header [ngClass]="fontService.headingClass">
                {{ "vendor.electedRepresentative.add" | translate }}
            </p-header>
            <ng-template pTemplate="content">
                <div class="col-12">
                    <div class="form-inner-panel">
                        <div class="row gy-2">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="vendor.electedRepresentative.erDetails">
                                    Elected Representative Details
                                </h6>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="department" [ngClass]="fontService.labelClass" jhiTranslate="vendor.name">Name</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.name" name="name" autocomplete="off" maxlength="60" uppercase style="text-transform:uppercase" id="name" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();" [readonly]="edit" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.name }" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="department" [ngClass]="fontService.labelClass" jhiTranslate="vendor.electedRepresentative.postName">Post Name</label>
                                <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [disabled]="isDelete" [closeOnSelect]="true" [clearable]="true" class="form-control" [items]="postList" [(ngModel)]="postName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !postName }">
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="name" [ngClass]="fontService.labelClass" jhiTranslate="vendor.electedRepresentative.wardNo">Ward No</label>
                                <input type="text" class="form-control" [(ngModel)]="employee.wardNo" name="name" [readonly]="isDelete" maxlength="15" autocomplete="off" id="name" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.wardNo }" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                />
                            </div>
                        </div>
                        <div class="row gy-2 mt-3">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="vendor.electedRepresentative.eligibleAmount">
                                    Eligible Amount
                                </h6>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="name" [ngClass]="fontService.labelClass" jhiTranslate="vendor.electedRepresentative.fixedTravellingAllowance">Fixed Travelling Allowance</label>
                                <input type="text" class="form-control" [readonly]="isDelete" [(ngModel)]="employee.fixedTravellingAllowance" name="name" autocomplete="off" maxlength="25" style="text-transform:uppercase" id="name" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.fixedTravellingAllowance }"
                                    oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="name" [ngClass]="fontService.labelClass" jhiTranslate="vendor.electedRepresentative.sittingChargesAllowance">sittingChargesAllowance</label>
                                <input type="text" class="form-control" [readonly]="isDelete" [(ngModel)]="employee.sittingChargesAllowance" name="name" autocomplete="off" id="name" maxlength="25" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.sittingChargesAllowance }" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="name" [ngClass]="fontService.labelClass" jhiTranslate="vendor.electedRepresentative.honorariumAllowance">Honorarium Allowance</label>
                                <input type="text" class="form-control" [readonly]="isDelete" [(ngModel)]="employee.honorariumAllowance" name="name" autocomplete="off" maxlength="25" id="name" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.honorariumAllowance }" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="name" [ngClass]="fontService.labelClass" jhiTranslate="vendor.electedRepresentative.others">Others</label>
                                <input type="text" class="form-control" [readonly]="isDelete" [(ngModel)]="employee.others" name="name" autocomplete="off" maxlength="25" id="name" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.others }" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                                />
                            </div>
                        </div>
                        <div class="row gy-2 mt-3">
                            <div class="col-12">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="agency.account.detail.name">
                                    Vendor Account Details
                                </h6>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <div class="col-sm-6 col-md-4">
                                    <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                                    <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" (change)="getBankBranchList()" [items]="bankList" [disabled]="readonly" [(ngModel)]="employee.bankName"
                                        [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.bankName }">
                                    </ng-select>

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                                <ng-select [appendTo]="'.p-dialog'" id="department" bindLabel="bankBranch" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" (search)="getBankBranchList($event)" [items]="bankBranchList" [disabled]="readonly" [(ngModel)]="bankBranch"
                                    (change)="getBankIfsc()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.bankBranch}">
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="!(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                                <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase
                                    [readonly]="readonly" [(ngModel)]="employee.ifscCode" id="ifsc" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.ifscCode }" maxlength="11" (input)="validateIfsc()" (change)="accountDetailsChanged()" />
                                <div *ngIf="ifscError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                                  IFSCode.</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.ifscode" for=" ifsc">IFSC Code</label>
                                <input type="text" class="form-control" name="ifsc" autocomplete="off" maxlength="11" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" uppercase
                                    [readonly]="readonly" [(ngModel)]="employee.ifscCode" id="ifsc" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.ifscCode }" maxlength="11" (input)="validateIfsc()" (change)="accountDetailsChanged()" />
                                <div *ngIf="ifscError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.validifsc">Please enter a valid
                                    IFSCode.</small>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.name" for=" bankName">Bank Name</label>
                                <p [ngClass]="fontService.regularClass" class="mb-0">{{employee.bankName}}</p>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="(currentEnvironment==='uat'|| currentEnvironment==='production')">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="agency.branch.name" for=" branchName">Branch Name</label>
                                <p [ngClass]="fontService.regularClass" class="mb-0">{{employee.bankBranch}}</p>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.accountno" for=" accountNo">Account Number</label>
                                <input type="text" class="form-control" name="accountNo" autocomplete="off" maxlength="25" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [readonly]="readonly" [(ngModel)]="employee.accountNumber" (change)="accountChanged()" id="accountNo" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.accountNumber }" />

                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" jhiTranslate="vendor.bank.account.name" for=" bankAccountName">Bank Account Name As per
                                Passbook</label>
                                <input type="text" class="form-control" name="bankAccountName" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                    [readonly]="true" [(ngModel)]="employee.bankAccName" id="bankAccountName" [ngClass]="{ 'is-invalid': ngSelectInvalid && !employee.bankAccName }" />
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label" [ngClass]="fontService.labelClass" jhiTranslate="vendor.micr" for=" micr">MICR</label>
                                <input type="text" class="form-control" name="micr" autocomplete="off" [(ngModel)]="employee.micr" maxlength="9" id="micr" [readonly]="readonly" />
                            </div>
                            <div class="col-12 justify-content-center">
                                <button pButton pRipple class="p-button-success custom-button" (click)="accountValidation()" styleClass="button" [disabled]="readonly">
                                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.verifyaccount" >Verify Account</span>
                                </button>
                                <div *ngIf="accountInvalidError" class="text-danger">
                                    <small [ngClass]="fontService.smallClass">{{accountInvalidError}}</small>
                                </div>
                                <div *ngIf="ngSelectInvalid && !verifyClicked" class="text-danger">
                                    <small [ngClass]="fontService.smallClass" jhiTranslate="error.verifyaccount"> Please verify the account</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
                <span class="button-label" custom-button jhiTranslate="common.cancelbutton"></span>
            </button>
                <button *ngIf="isDelete" pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="deleteVendor()">
              <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.delete"></span>
            </button>
                <button *ngIf="!employee.id" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
              </button>
                <button *ngIf="isApprove" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="approveVendor(true)">
                <span class="button-label" [ngClass]="fontService.buttonClass">Approve </span> </button>

                <button *ngIf="!isDelete && employee.id && !isApprove" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()">
                <span class="button-label" jhiTranslate="common.savebutton"></span>
            </button>
            </ng-template>
        </p-dialog>
        <p-confirmDialog #cd [style]="{width: '50vw'}">
            <ng-template pTemplate="header">
                <h3 [ngClass]="fontService.headingClass" jhiTranslate="common.confirmation">Confirmation</h3>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="cd.accept()" styleClass="button">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.yes"></span>
      </button>
                <button pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="cd.reject()" styleClass="button">
          <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.no"></span>
      </button>
            </ng-template>
        </p-confirmDialog>
    </div>