import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConsumerDemand, TangedcoBill } from 'src/app/tneb/tangedco-bill-details/tangedco-bill-details';
import { TangedcoBillService } from 'src/app/tneb/tangedco-bill-details/tangedco-bill-details.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { filterQuery } from 'src/app/shared/common.model';
import * as XLSX from 'xlsx';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageService } from 'ngx-webstorage';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-tneb-report',
  templateUrl: './tneb-report.component.html',
  styleUrls: ['./tneb-report.component.scss']
})
export class TnebReportComponent implements OnInit {
  activeIndex = 0;
  tnebsummary: any;
  tnebsummaryBlock: any;
  tnebsummaryVillage: any;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any = [];
  searchResult: SearchResult<ConsumerDemand> =
    new SearchResult<ConsumerDemand>();
  villageId: any;
  cols: any;
  enableStatusVoucher: boolean = false;
  cosumerDemandId: any;
  tangedcosearchResult: SearchResult<TangedcoBill> =
    new SearchResult<TangedcoBill>();
  vouchercols: any;
  roleCode: any;
  levelMasterId: any;
  isDMRole: boolean;
  levelType: any;

  constructor(
    protected reportService: ReportService,
    public fontService: FontService,
    private tangedcoBillService: TangedcoBillService,
    private loginService: SigninService,
    private spinner: NgxSpinnerService,
    private sessionStorageService: SessionStorageService,
    protected notificationService: NotificationService,
  ) { }

  ngOnInit(): void {

    this.cols = [
      {
        field: 'consumerNo',
        header: 'Consumer Number',
        //jhiTranslate: 'tableHead.voucher.no',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'consumerName',
        header: 'Consumer Name',
        //jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true
        //type: 'date',
      },
      {
        field: 'billCycle',
        header: 'Bill Cycle',
        //jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: true
        //type:'amount'
      },
      {
        field: 'billAmount',
        header: 'Bill Amount',
        //jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isFilterable: true,
        isSelectcolumn: true,
        type: 'amount'
      }
    ];
    this.roleCode = this.sessionStorageService.retrieve('roleCode');
    this.levelMasterId = this.sessionStorageService.retrieve('levelmasterId');
    this.levelType = this.sessionStorageService.retrieve('levelmaster')?.levelTypeDto?.name?.toUpperCase();
    switch (this.levelType) {
      case 'STATE':
        this.loadDistrict();
        break;
      case 'DISTRICT':
        this.loadBlock(this.levelMasterId);
        break;
      case 'BLOCK':
        this.loadVillage(this.levelMasterId);
        break;
    }

  }

  loadDistrict() {
    const payload: any = {
      levelType: "STATE",
      levelMasterId: 1
    };
    this.reportService.getTangedcoDemandReport(payload).subscribe(
      (res) => {
        console.log('res.body', res.body);
        this.tnebsummary = res.body;
      },
      (err) => {

      }
    )



  }

  loadBlock(districtId) {
    const payload: any = {
      levelType: "DISTRICT",
      levelMasterId: districtId
    };
    this.reportService.getTangedcoDemandReport(payload).subscribe(
      (res) => {
        console.log('res.body', res.body);
        this.tnebsummaryBlock = res.body;
        if (this.levelType === 'STATE') {
          this.activeIndex = 1;
        } else {
          this.activeIndex = 0;
        }

      },
      (err) => {

      }
    )
  }

  loadVillage(blockId) {
    const payload: any = {
      levelType: "BLOCK",
      levelMasterId: blockId
    };
    if(this.roleCode === 'ZVM') {
      payload['zonalName'] = this.sessionStorageService.retrieve('user')?.zonalName;
    }
    this.reportService.getTangedcoDemandReport(payload).subscribe(
      (res) => {
        console.log('res.body', res.body);
        this.tnebsummaryVillage = res.body;
        if (this.levelType === 'STATE') {
          this.activeIndex = 2;
        } else if (this.levelType === 'DISTRICT') {
          this.activeIndex = 1;
        }
        else {
          console.log('0')
          this.activeIndex = 0;
        }
      },
      (err) => {

      }
    )
  }

  loadPage(villageid, page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    this.villageId = villageid;



    let query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterProps.push(query);
    query = new filterQuery();
    query.key = 'levelMasterId';
    query.operation = 'equals';
    query.value = this.villageId;
    this.filterProps.push(query);
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.tangedcoBillService.validConsumerfilter({ ...filter }, this.filterProps).subscribe(
      (res: HttpResponse<ConsumerDemand[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: ConsumerDemand[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;
    if (this.levelType === 'STATE') {
      this.activeIndex = 3;
    } else if (this.levelType === 'DISTRICT') {
      this.activeIndex = 2;
    }
    else {
      this.activeIndex = 1;
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }
  onLazyLoad(event) {
    if ((this.activeIndex === 3 || this.activeIndex === 2) && this.tnebsummaryVillage) {
      this.filterProps = [];
      this.filterProps = event.filterDefinitions;
      this.itemsPerPage = event.size;
      this.loadPage(this.villageId, event.page);
    }

  }


  onTabChange($event) {
    this.filterProps = [];
  }

  getSubTableDetails($event) {
    this.cosumerDemandId = $event;
    this.vouchercols = [
      {
        field: 'consumerNo',
        header: 'Consumer Number',
        isFilterable: false,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.description',
        //type:'dropDown'

      },
      {
        field: 'billMonth',
        header: 'Bill Month',
        isFilterable: false,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.quantity',

      },
      {
        field: 'billYear',
        header: 'Bill Year',
        isSortable: true,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.amount',
        //type:'amount'
      },
      {
        field: 'billAmount',
        header: 'Bill Amount',
        isSortable: true,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.grossTotal',
        type: 'amount'
      },
      {
        field: 'dueDate',
        header: 'Due Date',
        isSortable: true,
        isSelectcolumn: true,
        //jhiTranslate: 'payments.table.grossTotal',
        type: 'date'
      },

    ];
    console.log('nwejkbfewjkfwn', $event);
    this.loadTangedcoPage(1);
  }

  loadTangedcoPage(page?: number, dontNavigate?: boolean): void {
    // this.spinner.show();
    this.enableStatusVoucher = true;
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = new filterQuery();
    query.key = 'consumerDemandId';
    query.operation = 'equals';
    query.value = this.cosumerDemandId;
    let filterProps = [];
    filterProps.push(query);
    // query = new filterQuery();
    // query.key = 'activeFlag';
    //   query.operation = 'equals';
    //   query.value = 'YES';
    // this.filterProps.push(query);
    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.tangedcoBillService.vaildFilter({ ...filter }, filterProps).subscribe(
      (res: HttpResponse<TangedcoBill[]>) => {
        this.onTangedcoSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );

  }

  protected onTangedcoSuccess(
    data: TangedcoBill[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.tangedcosearchResult.items = data ?? [];
    this.tangedcosearchResult = { ...this.tangedcosearchResult };
  }

  hideDialog() {
    this.filterProps = [];
    this.enableStatusVoucher = false;
  }

  export() {

    switch (this.levelType) {
      case 'STATE':
        if (this.activeIndex === 0) {
          this.exportToExcel('district-table', 'DistrictWiseTangedcobillReport.xlsx');
        } else if (this.activeIndex === 1) {
          this.exportToExcel('block-table', 'BlockWiseTangedcobillReport.xlsx');
        } else if (this.activeIndex === 2) {
          this.exportToExcel('village-table', 'VillageWiseTangedcobillReport.xlsx');
        }
        else if (this.activeIndex === 3) {
          this.exportToExcel('consumer-table', 'consumerDemandReport.xlsx')
        }
        break;
      case 'DISTRICT':
        if (this.activeIndex === 0) {
          this.exportToExcel('block-table', 'BlockWiseTangedcobillReport.xlsx');
        } else if (this.activeIndex === 1) {
          this.exportToExcel('village-table', 'VillageWiseTangedcobillReport.xlsx');
        }
        else if (this.activeIndex === 2) {
          this.exportToExcel('consumer-table', 'consumerDemandReport.xlsx')
        }
        break;
      case 'BLOCK':
        if (this.activeIndex === 0) {
          this.exportToExcel('village-table', 'VillageWiseTangedcobillReport.xlsx');
        }
        else if (this.activeIndex === 1) {
          this.exportToExcel('consumer-table', 'consumerDemandReport.xlsx')
        }
        break;
    }
  }

  exportToExcel(id, filename): void {
    let element = document.getElementById(id);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
  }


  checkStatus() {
    let filter = {
      reportName: 'Tangedco Payment Report',

    };
    this.spinner.show();
    this.reportService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res', res);
        let b: any = res.body
        this.saveBlobToExcel(b.data, 'VP Wise TANGEDCO');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.message, '');
        this.spinner.hide();
      }
    );
  }
  downloadExport() {
    let payLoad = {
      levelMasterId: this.levelMasterId,
      levelType: this.sessionStorageService.retrieve('levelmaster')?.levelTypeDto?.name?.toUpperCase(),
      reportName: 'Tangedco Payment Report',
    }

    if(this.roleCode === 'ZVM') {
      payLoad['zonalName'] = this.sessionStorageService.retrieve('user')?.zonalName;
    }
    
    this.spinner.show();
    this.reportService.reportAsyncDownload({ ...payLoad }).subscribe(
      (res) => {
        this.notificationService.alertSuccess(res.body, '');
        this.spinner.hide();
      },
      (onError) => {
        onError.error = JSON.parse(onError.error);
        this.notificationService.alertError(onError.error.message, '');
        this.spinner.hide();
      }
    );
  }


  saveBlobToExcel(data, filename) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, filename);
  }




}
