import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Profile } from 'src/app/module/profile/profile';
import { filterQuery } from 'src/app/shared/common.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { SearchResult } from 'src/app/shared/models/search-result';
import { VendorFailedTransaction } from '../payment-voucher/payment-voucher.model';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { Dropdown } from 'primeng/dropdown';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-reject-failed-payments',
  templateUrl: './reject-failed-payments.component.html',
  styleUrls: ['./reject-failed-payments.component.scss']
})
export class RejectFailedPaymentsComponent implements OnInit {

  activeIndex = 0;
  searchResult: SearchResult<VendorFailedTransaction> =
    new SearchResult<VendorFailedTransaction>();
  searchBulkResult: SearchResult<any> =
    new SearchResult<any>();
  searchTnebResult: SearchResult<any> =
    new SearchResult<any>();
  searchtangdecoResult: SearchResult<any> =
    new SearchResult<any>();

  personalCols: any;
  beneficiaryCols: any;
  commercialCols: any;
  employeeCols: any;
  ERCols: any;
  bulkListCols: any;

  personalFlag: string = 'Yes';
  levelMasterId: number;
  updatedOn: Date;
  tangedcoCols: any;
  failureType: string;
  tangedcoSubCols: any;
  disableTneb: boolean;
  disableTwad: boolean;
  tangedcoBulkSubCols: any;
  enableStatusVoucher: boolean;

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    protected paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    protected notificationService: NotificationService,
    public fontService: FontService,
    private loginService: SigninService,
    private confirmationService: ConfirmationService,
  ) {
  }

  ngOnInit(): void {
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.levelMasterId = res.body.levelMasterId;
        if (!res.body.isTangedcoEnabled && (res.body.role.code === 'VMAKER' || res.body.role.code === 'VC1' || res.body.role.code === 'VC2')) {
          this.disableTneb = true;
        }
        if (!res.body.isTwadEnabled && (res.body.role.code === 'VMAKER' || res.body.role.code === 'VC1' || res.body.role.code === 'VC2')) {
          this.disableTwad = true;
        }
        this.loadFailedTransactions();
      },
      () => { }
    );

    this.personalCols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no'
      },
      {
        field: 'personalVendorName',
        header: 'Vendor Name',
        jhiTranslate: 'tableHead.vendor.name',
        isSelectcolumn: true,
      },
      {
        field: 'personalAccountNo',
        header: 'Vendor Account',
        jhiTranslate: 'tableHead.vendor.account',
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'grossAmount',
        header: 'Rate',
        jhiTranslate: 'tableHead.rate',
        isSelectcolumn: true,
        type: 'amount'
      },
      {
        field: 'transactionAmount',
        header: 'Total Transction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        type: 'amount'
      },
    ];
    this.beneficiaryCols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        jhiTranslate: 'tableHead.voucher.no',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'permanentVoucherNumber',
        header: 'Permanent Voucher Number',
        isFilterable: true,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
      },
      {
        field: 'personalVendorName',
        header: 'Vendor Name',
        jhiTranslate: 'tableHead.beneficiary.name',
        isFilterable: true,
        isSelectcolumn: true,
      },


      {
        field: 'personalAccountNo',
        header: 'Vendor Account',
        jhiTranslate: 'tableHead.beneficiary.account',
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'grossAmount',
        header: 'Gross Amount',
        jhiTranslate: 'tableHead.gross.amount',
        isSelectcolumn: true,
        isFilterable: true,
        type: 'amount'
      },
      {
        field: 'transactionAmount',
        header: 'Total Transaction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        isFilterable: true,
        type: 'amount'
      },
      {
        field: 'transactionStatus',
        header: 'Payment Status',
        jhiTranslate: 'tableHead.payment.status',
        isSelectcolumn: true,
        isSortable: true,
        isFilterable: false,
      },
    ];
    this.commercialCols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no'
      },
      {
        field: 'commercialVendorName',
        header: 'Vendor Name',
        jhiTranslate: 'tableHead.vendor.name',
        isSelectcolumn: true,
      },
      {
        field: 'commercialAccountNo',
        header: 'Vendor Account',
        jhiTranslate: 'tableHead.vendor.account',
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'grossAmount',
        header: 'Rate',
        jhiTranslate: 'tableHead.rate',
        isSelectcolumn: true,
        type: 'amount'
      },
      {
        field: 'transactionAmount',
        header: 'Total Transction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        type: 'amount'
      },
    ];
    this.employeeCols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no'
      },
      {
        field: 'subComponentName',
        header: 'Sub Component Name',
        jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'grossAmount',
        header: 'Rate',
        jhiTranslate: 'tableHead.rate',
        isSelectcolumn: true,
        type: 'amount'
      },
      {
        field: 'transactionAmount',
        header: 'Total Transction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        type: 'amount'
      }
    ];
    this.ERCols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no'
      },
      {
        field: 'description',
        header: 'Description',
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.description',
        type: 'dropDown'
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'grossAmount',
        header: 'Gross Amount',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.grossTotal',
        type: 'amount'
      },
      {
        field: 'transactionAmount',
        header: 'Total Transction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        type: 'amount'
      }
    ];

    this.bulkListCols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no'
      },
      {
        field: 'name',
        header: 'Name',
        isSelectcolumn: true,
      },
      {
        field: 'accountNumber',
        header: 'Account No',
        isSelectcolumn: true,
      },
      {
        field: 'grossAmount',
        header: 'Gross Amount',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.grossTotal',
        type: 'amount'
      },
      {
        field: 'createdOn',
        header: 'Created Date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
    ];

    this.tangedcoCols =
      [
        {
          field: 'voucherNo',
          header: 'Voucher No',
          jhiTranslate: 'tableHead.voucher.no',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'universalVendor',
          header: 'Vendor Name',
          jhiTranslate: 'tableHead.vendor.name',
          isFilterable: true,
          isSelectcolumn: true,
          type: 'dropDown_with_other_field',
          Dfield: 'vendorName',
        },

        {
          field: 'universalVendor',
          header: 'Vendor Account',
          jhiTranslate: 'tableHead.vendor.account',
          isFilterable: true,
          isSelectcolumn: true,
          type: 'dropDown_with_other_field',
          Dfield: 'accountNumber',
        },
        {
          field: 'transDate',
          header: 'Transaction Date',
          jhiTranslate: 'tableHead.transaction.date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        },
        {
          field: 'grossAmount',
          header: 'Gross Amount',
          jhiTranslate: 'tableHead.gross.amount',
          isSelectcolumn: true,
          isFilterable: true,
          type: 'amount'
        },
        {
          field: 'transactionAmount',
          header: 'Total Transaction Amount',
          jhiTranslate: 'tableHead.transaction.amount',
          isSelectcolumn: true,
          isFilterable: true,
          type: 'amount'
        },
        {
          field: 'transactionStatus',
          header: 'Payment Status',
          jhiTranslate: 'tableHead.payment.status',
          isSelectcolumn: true,
          isSortable: true,
          isFilterable: false,
        },
      ]

    this.tangedcoSubCols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no'
      },
      {
        field: 'consumerNo',
        header: 'Consumer No',
        isSelectcolumn: true,
      },
      {
        field: 'billType',
        header: 'Bill Type',
        isSelectcolumn: true,
      },
      {
        field: 'billMonth',
        header: 'Bill Month',
        isSelectcolumn: true,
      },
      {
        field: 'billYear',
        header: 'Bill Year',
        isSelectcolumn: true,
      },
      {
        field: 'billAmount',
        header: 'Gross Amount',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.grossTotal',
        type: 'amount'
      },
      {
        field: 'createdOn',
        header: 'Created Date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
    ];

    this.tangedcoBulkSubCols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no'
      },
      {
        field: 'consumerNumber',
        header: 'Consumer No',
        isSelectcolumn: true,
      },
      {
        field: 'billAmount',
        header: 'Gross Amount',
        isSortable: true,
        isSelectcolumn: true,
        jhiTranslate: 'payments.table.grossTotal',
        type: 'amount'
      },
      {
        field: 'createdOn',
        header: 'Created Date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
    ];


  }

  loadFailedTransactions() {
    this.spinner.show();
    this.paymentService.getFailedTransaction(this.levelMasterId, this.personalFlag).subscribe(
      (res) => {
        this.spinner.hide();
        this.searchResult.items = res.body ?? [];
        if (this.personalFlag != 'BE' && this.personalFlag != 'BER') {
          this.searchResult.items.forEach((element) => {
            const date = new Date(element.updatedOn);
            const currentDate = new Date();
            const yesterday = new Date(currentDate);
            yesterday.setDate(currentDate.getDate() - 1);
            if (date < yesterday) {
              element.checkTransactionStatus = true;
            }
          })
        }
        this.searchResult = { ...this.searchResult };
      },
      () => {
        this.spinner.hide();
      }
    )
  }

  loadEmployeeBulkSubTransactions(id) {
    this.searchBulkResult = new SearchResult<VendorFailedTransaction>();
    this.spinner.show();
    this.paymentService.getFailedBEmpVoucherDesc(id).subscribe(
      (res) => {
        this.spinner.hide();
        this.activeIndex = 6;
        this.searchBulkResult.items = res.body ?? [];
        this.searchBulkResult.items.forEach((element) => {
          const date = new Date(this.updatedOn);
          const currentDate = new Date();
          const yesterday = new Date(currentDate);
          yesterday.setDate(currentDate.getDate() - 1);
          if (date < yesterday) {
            element.checkTransactionStatus = true;
          }
        })
        this.searchBulkResult = { ...this.searchBulkResult };
      },
      () => {
        this.spinner.hide();
      }
    )
  }

  loadERBulkSubTransactions(id) {
    this.searchBulkResult = new SearchResult<VendorFailedTransaction>()
    this.spinner.show();
    this.paymentService.getFailedBErepVoucherDesc(id).subscribe(
      (res) => {
        this.spinner.hide();

        this.activeIndex = 6;

        this.searchBulkResult.items = res.body ?? [];
        this.searchBulkResult.items.forEach((element) => {
          const date = new Date(this.updatedOn);
          const currentDate = new Date();
          const yesterday = new Date(currentDate);
          yesterday.setDate(currentDate.getDate() - 1);
          if (date < yesterday) {
            element.checkTransactionStatus = true;
          }
        })
        this.searchBulkResult = { ...this.searchBulkResult };
      },
      () => {
        this.spinner.hide();
      }
    )
  }

  loadTnebSubTransactions(id, type, QueueNo) {
    this.spinner.show();
    this.paymentService.getTangedcoFailSubList(id, type).subscribe(
      (res) => {
        this.spinner.hide();
        res.body.forEach(element => {
          element.voucherNo = QueueNo;
        });
        this.activeIndex = 12;
        this.searchTnebResult.items = res.body ?? [];
        this.searchTnebResult = { ...this.searchTnebResult };
      },
      () => {
        this.spinner.hide();
      }
    )
  }


  protected onError(): void { }


  onSelectRow($event) {
    console.log(this.personalFlag)
    if ($event.type === 'loadBulk') {
      this.updatedOn = $event.value.updatedOn;
      if (this.personalFlag == 'BE') {
        this.loadEmployeeBulkSubTransactions($event.value.id);
      } else if (this.personalFlag == 'BER') {
        this.loadERBulkSubTransactions($event.value.id);
      }
      else if (this.personalFlag == 'T') {
        this.loadTnebSubTransactions($event.value.id, this.failureType, $event.value.voucherNo);
      }
    }
    else if ($event.type === 'status') {
      this.spinner.show();
      let id, descId;
      if (this.personalFlag == 'T') {
        id = $event.value.vendorTransactionId;
        descId = $event.value.id;
      }


      this.paymentService.checkPaymentStatus(id, descId)
        .subscribe(
          (res: any) => {
            console.log('res', res.body);
            if (res.body === "Success") {
              this.notificationService.alertSuccess("Payment has Successfully Verified", '');
              this.loadTnebSubTransactions($event.value.id, this.failureType, $event.value.voucherNo);
            }
            else {
              this.notificationService.alertError("Payment has Failed", '')
            }
            this.spinner.hide();

          },
          () => {
            this.spinner.hide();

          }
        );
    }
    else if ($event.type === 'delete') {
      this.spinner.show();
      if (this.personalFlag != 'BE' && this.personalFlag != 'BER' && this.personalFlag != 'T') {

        this.paymentService.deletedVocher($event.value.id).subscribe(
          (res: HttpResponse<any>) => {
            this.loadFailedTransactions();
            this.spinner.hide();
          },
          (onError) => {
            if (onError.status == 400) {
              this.notificationService.alertError(onError.error.title, '');
            } else {
              this.notificationService.alertError('Error in Reject', '');
            }

            this.spinner.hide();
          }
        );
      } else {
        let id, descId;
        if (this.personalFlag == 'BER') {
          id = $event.value.vendorTransactionId;
          descId = $event.value.voucherBillDescElectedRepBulkId;
          this.paymentService.revertBulkPaymentAmount(id, this.personalFlag, descId).subscribe(
            (res: HttpResponse<any>) => {
              this.loadERBulkSubTransactions(id);
              this.spinner.hide();
            },
            (onError) => {
              if (onError.status == 400) {
                this.notificationService.alertError(onError.error.title, '');
              } else {
                this.notificationService.alertError('Error in Reject', '');
              }

              this.spinner.hide();
            });

        }
        else if (this.personalFlag == 'BE') {
          id = $event.value.vendorTransactionId;
          descId = $event.value.voucherBillDescEmployeeBulkId;
          this.paymentService.revertBulkPaymentAmount(id, this.personalFlag, descId).subscribe(
            (res: HttpResponse<any>) => {
              this.loadEmployeeBulkSubTransactions(id);
              this.spinner.hide();
            },
            () => {
              this.onError();
              this.notificationService.alertError('Error in Revert', '');
              this.spinner.hide();
            });
        }
        else if (this.personalFlag === 'T') {
          id = $event.value.vendorTransactionId;
          descId = $event.value.id;
          this.paymentService.revertBulkPaymentAmount(id, this.personalFlag, descId).subscribe(
            (res: HttpResponse<any>) => {
              this.loadTnebSubTransactions(id, this.failureType, $event.value.voucherNo);
              this.spinner.hide();
            },
            () => {
              this.onError();
              this.notificationService.alertError('Error in Revert', '');
              this.spinner.hide();
            });
        }
      }
    }
  }
  onTabChange(event: any): void {
    console.log('logddddd', event, event.index)
    switch (event.index) {
      case 0:
        this.personalFlag = 'Yes';
        this.loadFailedTransactions();
        break;
      case 1:
        this.personalFlag = 'No';
        this.loadFailedTransactions();
        break;
      case 2:
        this.personalFlag = 'E';
        this.loadFailedTransactions();
        break;
      case 3:
        this.personalFlag = 'ER';
        this.loadFailedTransactions();
        break;
      case 4:
        this.personalFlag = 'BE';
        this.loadFailedTransactions();
        break;
      case 5:
        this.personalFlag = 'BER';
        this.loadFailedTransactions();
        break;
      case 7:
        this.personalFlag = 'BP';
        this.loadFailedTransactions();
        break;
      case 8:
        this.personalFlag = 'TK';
        this.loadFailedTransactions();
        break
      case 9:
        this.personalFlag = 'OFP';
        this.loadFailedTransactions();
        break

      case 10:
        this.personalFlag = 'T';
        this.failureType = 'TNPASS';
        this.loadFailedTransactions();
        break;


      case 11:
        this.personalFlag = 'T';
        this.failureType = 'TANGEDCO'
        this.loadTangedcoFailedTransactions();
        break;


      case 13:
        this.personalFlag = 'TWAD';
        this.loadFailedTransactions();
        break;

    }
  }
  loadTangedcoFailedTransactions() {
    this.spinner.show();
    this.paymentService.getTangedcoFailedTransaction(this.levelMasterId).subscribe(
      (res) => {
        this.spinner.hide();
        this.searchResult.items = res.body ?? [];

        this.searchResult = { ...this.searchResult };
      },
      () => {
        this.spinner.hide();
      }
    )
  }

  getSubTableDetails(event) {
    console.log('event', event)
    this.spinner.show();
    this.paymentService.getDescByPostingId({
      vendorTransId: event.vendorTransactionId,
      postingId: event.id,
    }).subscribe(
      (res) => {
        this.spinner.hide();
        this.searchtangdecoResult.items = res.body ?? [];
        this.searchtangdecoResult = { ...this.searchtangdecoResult };
        this.enableStatusVoucher = true;
      },
      () => {
        this.spinner.hide();
      }
    )

  }

}

