<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="payments.tneb.payment.voucher.name">
                        Tangedco Payment Voucher</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row voucherfont">
                        <h6 [ngClass]="fontService.headingClass" jhiTranslate="payments.add.voucher.generation.name">
                            Add Voucher Generation</h6>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">{{"payments.voucher.number.name"|translate}}:<br />{{ voucherGenerationDto?.vendorTransactionDto?.voucherNo }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">{{"date.name"|translate}}:<br />{{ date | date:'dd/MM/yyyy' }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"implementing.agency.name"|translate}}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto.name }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.gst.number.name"|translate}}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto.gstNo }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.limit.amount.name"|translate}}:
                                <br />{{ this.voucherGenerationDto?.vendorTransactionDto .iaComponentLimitDto?.realizedLimitAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}
                            </p>
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.department.name"|translate}}:<br>{{this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.departmentDto.name}}
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.scheme.name"|translate}}:<br>{{this.voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.schemeDto.name}}
                        </div>
                        <div class="col-lg col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{"payments.level.type.name"|translate}}:<br>{{levelMasterDto.name}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
      <div class="row">
        <div class="col">
          <div class="card p-3">
            <div class="row">
              <h6></h6>
              <div class="col-md-4">
                <p>Department:<br>{{voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.departmentDto.name}}</p>
              </div>
              <div class="col-md-4">
                <p>Scheme:<br>{{ voucherGenerationDto.vendorTransactionDto.implementingAgencyDto.schemeDto.name}}</p>
              </div>
              <div class="col-md-4">
                <p>Level Type:<br>{{levelMasterDto.name}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.grant.level.name">Grant Level</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="grantlevel" [items]="grantLevel" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" [disabled]="readonly" (change)="ongrandLevel()" class="form-control dropdown-width"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !grantlevel }">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="breadcrumb.component.major.component">Major
              Component</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="majaorComponentdto" [disabled]="readonly" [items]="majaorComponent" [bindLabel]="dropDownLabel" appearance="outline" [closeOnSelect]="true" [clearable]="false" (change)="onMajorComponent()" class="form-control dropdown-width"
                                [ngClass]="{'is-invalid': ngSelectInvalid && !majaorComponentdto}">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="breadcrumb.component.minor.component">Minor
              Component</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="minorComponentDto" [disabled]="readonly" [items]="minorComponent" [bindLabel]="dropDownLabel" appearance="outline" [closeOnSelect]="true" [clearable]="false" (change)="onMinorComponent()" class="form-control dropdown-width"
                                [ngClass]="{'is-invalid': ngSelectInvalid && !minorComponentDto}">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="breadcrumb.component.sub.component">Sub
              Component</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="subComponentDto" [disabled]="readonly" [items]="subComponent" [bindLabel]="dropDownLabel" appearance="outline" [closeOnSelect]="true" [clearable]="false" class="form-control dropdown-width" [ngClass]="{'is-invalid': ngSelectInvalid && !subComponentDto}"
                                (change)="LoadTangedcoBill()">
                            </ng-select>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="subComponentDto" class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.panchayat.resolution.number.name">Panchayat Resolution No</label>
                            <input type="text" class="form-control full" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="15" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.panchayatResolutionNumber" name="name" autocomplete="off"
                                id="levelType" [readonly]="readonly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !voucherGenerationDto.vendorTransactionDto.panchayatResolutionNumber }" />
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.vendor.name">Vendor</label>
                            <ng-select [appendTo]="'body'" id="scheme" [(ngModel)]="selectedVendor" [items]="vendorList" bindLabel="vendorName" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" (search)="vendorFilter($event)" [disabled]="readonly"
                                (change)="popupValue()" class="form-control dropdown-width" [ngClass]="{ 'is-invalid': ngSelectInvalid && !selectedVendor.vendorName }" maxlength="50">
                            </ng-select>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="row">
                                <div class="col-md-3 col-sm-6">
                                    <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" [ngClass]="fontService.labelClass">{{"payments.staff.accno.name"|translate}}:</label>
                                    <p [ngClass]="fontService.regularClass" class="ps-2 mb-0">{{selectedVendor?.accountNumber}}</p>
                                </div>
                                <div class="col-md-3 col-sm-6">
                                    <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" [ngClass]="fontService.labelClass">{{"payments.staff.bank.name"|translate}}:</label>
                                    <p [ngClass]="fontService.regularClass" class="ps-2 mb-0">{{selectedVendor?.bankName}}</p>
                                </div>
                                <!-- <div class="col-md-3 col-sm-6">
                <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2 wordbreak" [ngClass]="fontService.labelClass">{{"payments.staff.id"|translate}}:</label>
                <p [ngClass]="fontService.regularClass" class="ps-2 mb-0">{{selectedVendor?.staffId}}</p>
              </div> -->
                                <div class="col-md-3 col-sm-6">
                                    <label class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" [ngClass]="fontService.labelClass">{{"payments.staff.ifsc.code.name"|translate}}:</label>
                                    <p [ngClass]="fontService.regularClass" class="ps-2 mb-0">{{selectedVendor?.ifscCode}}</p>
                                </div>

                                <div *ngIf="!readonly && !this.voucherGenerationDto.vendorTransactionDto.id" class="col-md-3 col-sm-6">
                                    <button pButton pRipple label="Add Consumer Bills" (click)="showConsumerDetails = true;this.selectedConsumer=null;" class="p-button-success"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                      <div *ngIf="enableZonalButton" class="col-md-4">
                          <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Zonal User</label>
                          <ng-select id="scheme" [disabled]="readonly" [(ngModel)]="selectedZonalUser" [items]="zonalList" bindLabel="userName" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" class="form-control dropdown-width">
                          </ng-select>
                      </div>
                      <div *ngIf="selectedVendor.name" class="col-md-6">
                          <div class="mt-1 p-2" [ngClass]="fontService.labelClass" jhiTranslate="payments.vendor.last.transaction.name">last 5 Transaction</div>
                          <button pButton pRipple (click)="getvendorTrans()" class="p-button-success custom-button" styleClass="button">
              <span class="button-label" [ngClass]="fontService.buttonClass"
                jhiTranslate="common.view"></span></button>
                      </div>


                  </div> -->
                </div>
            </div>
        </div>
        <div *ngIf="subComponentDto && selectedVendor.vendorName" class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <!--  <div class="col-12">
                          <label class="form-control-label" for="schemeDescription">GST Applicable</label><br>
                          <p-radioButton name="option" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.gstApplicable" value="option1" label="Yes"></p-radioButton>
                          <p-radioButton name="option" value="option2" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.gstApplicable" label="No"></p-radioButton>
                      </div> -->
                        <div class="col-md-12 mt-2" *ngIf="selectedVendor.vendorName">
                            <p-table [value]="voucherGenerationDto?.voucherBillDescTangedcoList" [resizableColumns]="true" responsiveLayout="scroll" style="overflow-y: visible" [paginator]="true" [rows]="5" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [rowsPerPageOptions]="[5,10, 25, 50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.labelClass">{{'payments.table.sno'|translate}}</th>
                                        <th [ngClass]="fontService.labelClass">
                                            Consumer No</th>
                                        <th class="req" [ngClass]="fontService.labelClass">Bill Type</th>
                                        <!-- <th class="req" [ngClass]="fontService.labelClass">{{'payments.table.noOfDaysAttended'|translate}}
                                      </th> -->
                                        <th class="req" [ngClass]="fontService.labelClass">Bill Month</th>
                                        <th [ngClass]="fontService.labelClass">Bill Year</th>
                                        <th [ngClass]="fontService.labelClass">Bill Amount</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <!-- <th style="text-align: right">
                                          <button pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" styleClass="button" (click)="addRow()" [disabled]="readonly">
                      <span class="button-label" [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.row"></span></button>
                                      </th> -->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list let-index="rowIndex">
                                    <tr>
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            {{list.consumerNo}}
                                        </td>
                                        <td>
                                            {{list.billType}}
                                        </td>
                                        <!-- <td>
                                          <ng-select [appendTo]="'body'" id="scheme" style="min-width:210px" [(ngModel)]="list.descriptionDto" [items]="descriptionList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" class="form-control" [disabled]="readonly"
                                              appendTo="body" (change)="addDescritption(list.descriptionDto)" [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.descriptionDto }">
                                          </ng-select>
                                      </td> -->
                                        <!-- <td>
                                          <p-inputNumber id="field_price" [(ngModel)]="list.quantity" (ngModelChange)="calculatetotal(list)" [readonly]="readonly" maxlength="15" [ngClass]="{'is-invalid': ngSelectInvalid && !list.quantity}"></p-inputNumber>
                                      </td> -->
                                        <td>
                                            {{list.billMonth}}
                                        </td>
                                        <td>
                                            {{list.billYear}}

                                        </td>

                                        <td>
                                            {{list.billAmount}}

                                        </td>
                                        <td *ngIf="list.activeFlag === 'No'">{{list.message}}</td>
                                        <td><button *ngIf="!list.isCancelled" pButton pRipple type="button" [disabled]="readonly || voucherGenerationDto.vendorTransactionDto.id" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" (click)="deletedTangdeoBills(index,list)"></button></td>
                                        <td *ngIf="list.isCancelled">
                                            <div style="color:#EF4444;">Failed and Returned</div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>

                    <div *ngIf="selectedVendor.vendorName" class="row mt-3 tds">
                        <div class="col-lg-4 pt-lg-0 pt-xs-2 pt-sm-2">
                            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label
                        ><br />
                        <textarea
                          id="w3review"
                          name="w3review"
                          rows="3"
                          [(ngModel)]="voucherGenerationDto.vendorTransactionDto.remark"
                          [readonly]="
                            enableSignButton ||
                            voucherGenerationDto.vendorTransactionDto.signStatus ||
                            this.enableView
                          "
                          cols="100"
                          [ngClass]="{
                            'is-invalid':
                              ngSelectInvalid &&
                              !voucherGenerationDto.vendorTransactionDto.remark
                          }"
                        >
                        </textarea>
                      </div>

                      <div class="col-lg-3 pt-lg-0 pt-xs-2 pt-sm-2" style="
                      margin-left: auto;">
                          <table class="snippet">

                              <tr >
                                  <td [ngClass]="fontService.labelClass">
                                      Total Amount</td>
                                  <td style="width: 110px; text-align: right">
                                      {{ voucherGenerationDto.vendorTransactionDto.transactionAmount | currency:'INR':'symbol':'1.2-2':'en-IN'|| "Nill" }}
                                  </td>
                              </tr>
                              <!-- <div class="row " *ngIf="!readonly">
                <div class="col-sm-3 generate mt-3">

                  <button pButton pRipple label="Cancel" class="p-button-danger"></button>
                </div>
              </div> -->
                              <!-- <div class="row" *ngIf="enableRevertButton">
                <div class="col-6"></div>
                <div class="col-2 generate mt-3">
                  <button pButton pRipple label="Revert" (click)="revert()" class="p-button-danger"></button><br>
                </div>
                <div class="col-2 generate mt-3">
                  <button pButton pRipple label="Approve" (click)="genVocher()" class="p-button-success"></button><br>

                </div>
                <div class="col-2 generate mt-3">
                  <button pButton pRipple label="Reject" (click)="reject()" class="p-button-danger"></button>
                </div>

              </div> -->
                          </table>

                      </div>
                  </div>
                  <p-toolbar *ngIf="selectedVendor.vendorName && !enableRevertButton  && !this.enableView">
                      <div class="p-toolbar-group-start"></div>
                      <div class="p-toolbar-group-end">
                          <button *ngIf="!enableSignButton && !voucherGenerationDto.vendorTransactionDto.signaturePresident && !this.enableView" pButton pRipple class="p-button-success custom-button margin" (click)="genVocher()" styleClass="button">
              <span class="button-label" [ngClass]="fontService.buttonClass"
                jhiTranslate="common.generatevoucher"></span>
            </button>
            <button *ngIf="enableSignButton && !voucherGenerationDto.vendorTransactionDto.signaturePresident" class="p-button-success custom-button margin" 
            (click)="submitForm()">{{signButtonName}}</button>
                      </div>
                  </p-toolbar>
                  <p-toolbar *ngIf="selectedVendor.vendorName && enableRevertButton && !this.enableView">
                      <div class="p-toolbar-group-start"></div>
                      <div *ngIf="!enableSignButton && !voucherGenerationDto?.vendorTransactionDto?.signaturePresident" class="p-toolbar-group-end">
                          <button *ngIf="!isCheckActiveFlag" pButton pRipple (click)="revert()" class="p-button-warning custom-button">
              <span class="button-label" [ngClass]="fontService.buttonClass"
                jhiTranslate="common.revertbutton"></span>
            </button>
                          <button *ngIf="!voucherGenerationDto?.vendorTransactionDto?.revertStatus && enableApproved && !isCheckActiveFlag" pButton pRipple (click)="genVocher()" class="p-button-success custom-button">
              <span class="button-label" [ngClass]="fontService.buttonClass"
                jhiTranslate="common.approvebutton"></span>
            </button>
                          <button pButton pRipple (click)="reject()" class="p-button-danger custom-button">
              <span class="button-label" [ngClass]="fontService.buttonClass"
                jhiTranslate="common.rejectbutton"></span>
            </button>
                      </div>
                      <button *ngIf="enableSignButton && !voucherGenerationDto.vendorTransactionDto.signaturePresident" class="p-button-success custom-button margin" 
          (click)="submitForm()">{{signButtonName}}</button>
                      <button *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident && !enableRePaymentButton && !disablepaymentButton && !checkstatusButton" pButton pRipple [label]="buttonText" (click)="PaymentSucess()" class="p-button-success"></button>
                      <p *ngIf="checkstatusButton" style="font-size: 18px;color:red;font-weight:700;">Please Check Transaction Status Before Re-Initiate Payment
                      </p>
                      <button *ngIf="checkstatusButton" pButton pRipple label="Check Transaction Status" (click)="checkTransactionStatus()" class="p-button-success"></button>
                  </p-toolbar>
                  <p-toolbar *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident">
                    <div class="p-toolbar-group-start">
                        <p style="font-size: 18px;color:red;font-weight:700;">Payment Has Been {{this.voucherGenerationDto?.vendorTransactionDto?.transactionStatus}}</p>
                    </div>
                    <button *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident && roleCode.role.code !== 'DAD'  && voucherGenerationDto?.vendorTransactionDto?.transactionStatus !== 'Failed'" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
                    <button *ngIf="viewPayList && voucherGenerationDto.vendorTransactionDto.signaturePresident" pButton pRipple label="View Payment List" (click)="getBulkPaymentList()" class="p-button-success"></button>
                </p-toolbar>
                  <!-- <button *ngIf="voucherGenerationDto.vendorTransactionDto.id" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button> -->
              </div>
          </div>
      </div>
      <p-dialog [(visible)]="profileDialog" [style]="{ width: '1020px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
          <p-header [ngClass]="fontService.headingClass">
              {{ "payments.lastTransaction" | translate }}
          </p-header>
          <p-table *ngIf="!noRecord" [value]="searchResult.items" [resizableColumns]="true" styleClass="dyanamic-table p-datatable-gridlines" responsiveLayout="scroll">
              <ng-template pTemplate="header">
                  <tr>
                      <th [ngClass]="fontService.labelClass">{{'payments.viewTable.voucherNo'|translate}}</th>
                      <th [ngClass]="fontService.labelClass">{{'payments.viewTable.vendorName'|translate}}</th>
                      <th [ngClass]="fontService.labelClass">{{'payments.viewTable.vendorAccount'|translate}}</th>
                      <th [ngClass]="fontService.labelClass">{{'payments.viewTable.transactionDate'|translate}}</th>
                      <th [ngClass]="fontService.labelClass">{{'payments.viewTable.rate'|translate}}</th>
                      <th [ngClass]="fontService.labelClass">{{'payments.viewTable.totTransAmount'|translate}}</th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-data let-index="rowIndex">
                  <tr>
                      <td>{{ data.vendorTransaction.voucherNo }}</td>
                      <td>{{ data.vendorTransaction.personalVendorName }}</td>
                      <td>{{ data.vendorTransaction.personalAccountNo }}</td>
                      <td>{{ data.vendorTransaction.transDate | date : "shortDate" }}</td>
                      <td>{{ data.vendorTransaction.grossAmount }}</td>
                      <td>{{ data.vendorTransaction.transactionAmount }}</td>
                  </tr>
              </ng-template>
          </p-table>
          <div *ngIf="noRecord">
              <h5 class="text-center" [ngClass]="fontService.regularClass" jhiTranslate="error.norecord">No transactions found
              </h5>
          </div>
      </p-dialog>
      <p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
          <p-header [ngClass]="fontService.headingClass">
              Payment Alert Box
          </p-header>
          <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
          <ng-template pTemplate="footer">
              <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false">
        <span class="button-label" [ngClass]="fontService.buttonClass" label="Ok" (click)="redirect()">Ok</span>
      </button>
          </ng-template>
      </p-dialog>
      <p-dialog [(visible)]="showConsumerDetails" [style]="{ width: '1500px', height: '1500px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <p-header [ngClass]="fontService.headingClass">
            Consumer Details
        </p-header>
        <p-table  [value]="tangedcoConsumerList" [paginator]="true" [rows]="5"  [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [rowsPerPageOptions]="[5,10, 25, 50]">
            <ng-template pTemplate="header">
                <tr>
                    <th [ngClass]="fontService.labelClass">Consumer No</th>
                    <th [ngClass]="fontService.labelClass">Consumer Name</th>
                    <th [ngClass]="fontService.labelClass">Total Demand Amount</th>
                    <th></th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-index="rowIndex">
                <tr>
                    <td>{{ data.consumerNo}}</td>
                    <td>{{ data.consumerName }}</td>
                    <td>{{ data.billAmount}}</td>
  
    <td><button pButton pRipple label="Select" (click)="selectedConsumer=data;resetPaginator()" class="p-button-success"></button></td>
                    
                </tr>
            </ng-template>
        </p-table>
        <p-tabView *ngIf="selectedConsumer" [(activeIndex)]="activeIndex">
            <p-tabPanel header="CC Charges List">
                <div class="row">
                    <p-table #dataTable [value]="selectedConsumer.ccChargesList" [paginator]="true" [rows]="5"  [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [rowsPerPageOptions]="[5,10, 25, 50]">
            <ng-template pTemplate="header">
                <tr>
                    <th [ngClass]="fontService.labelClass">Consumer No</th>
                    <th [ngClass]="fontService.labelClass">Bill Type</th>
                    <th [ngClass]="fontService.labelClass">Bill Month</th>
                    <th [ngClass]="fontService.labelClass">Bill Year</th>
                    <th [ngClass]="fontService.labelClass">Bill Amount</th>
                    <th></th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-index="rowIndex">
                <tr>
                    <td>{{ data.consumerNo}}</td>
                    <td>{{ data.billType}}</td>
                    <td>{{ data.billMonth}}</td>
                    <td>{{ data.billYear}}</td>
                    <td>{{ data.billAmount}}</td>
                    <td *ngIf="!data.voucherInitaied && !data.selected"><button pButton pRipple label="Add" (click)="addBills(data,index,'cc')" class="p-button-success"></button></td>
                    <td *ngIf="data.voucherInitaied" style="color: red;">This Bill Already Initaied</td>
                    <td *ngIf="data.selected" style="color: red;">This Bill Already Added</td>
                    
                </tr>
            </ng-template>
        </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel  header="Installments List">
                <div class="row">
                    <p-table  [value]="selectedConsumer.installments" [paginator]="true" [rows]="5"  [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [rowsPerPageOptions]="[5,10, 25, 50]">
            <ng-template pTemplate="header">
                <tr>
                    <th [ngClass]="fontService.labelClass">Consumer No</th>
                    <th [ngClass]="fontService.labelClass">Install Pos</th>
                    <th [ngClass]="fontService.labelClass">Bill Type</th>
                    <th [ngClass]="fontService.labelClass">Bill Month</th>
                    <th [ngClass]="fontService.labelClass">Bill Year</th>
                    <th [ngClass]="fontService.labelClass">Bill Amount</th>
                    <th></th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-index="rowIndex">
                <tr>
                    <td>{{ data.consumerNo}}</td>
                    <td>{{data.installPos}}</td>
                    <td>{{ data.billType}}</td>
                    <td>{{ data.billMonth}}</td>
                    <td>{{ data.billYear}}</td>
                    <td>{{ data.billAmount}}</td>
                    <td *ngIf="!data.voucherInitaied && !data.selected"><button pButton pRipple label="Add" (click)="addBills(data,index,'ins')" class="p-button-success"></button></td>
                    <td *ngIf="data.voucherInitaied && voucherGenerationDto?.vendorTransactionDto?.id" style="color: red;">This Bill Already Initaied</td>
                    <td *ngIf="data.selected" style="color: red;">This Bill Already Added</td>
                   
                    
                </tr>
            </ng-template>
        </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Miscellaneous List">
                <div class="row">
                    <p-table  [value]="selectedConsumer.miscellaneous" [paginator]="true" [rows]="5"  [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [rowsPerPageOptions]="[5,10, 25, 50]">
<ng-template pTemplate="header">
    <tr>
        <th [ngClass]="fontService.labelClass">Consumer No</th>
        <th [ngClass]="fontService.labelClass">Bill Type</th>
        <th [ngClass]="fontService.labelClass">Bill Month</th>
        <th [ngClass]="fontService.labelClass">Bill Year</th>
        <th [ngClass]="fontService.labelClass">Bill Amount</th>
        <th></th>
        
    </tr>
</ng-template>
<ng-template pTemplate="body" let-data let-index="rowIndex">
    <tr>
        <td>{{ data.consumerNo}}</td>
        <td>{{ data.billType}}</td>
        <td>{{ data.billMonth}}</td>
        <td>{{ data.billYear}}</td>
        <td>{{ data.billAmount}}</td>
        <td *ngIf="!data.voucherInitaied && !data.selected"><button pButton pRipple label="Add" (click)="addBills(data,index,'mis')" class="p-button-success"></button></td>
         <td *ngIf="data.voucherInitaied && voucherGenerationDto.vendorTransactionDto.id" style="color: red;">This Bill Already Initaied</td>
        <td *ngIf="data.selected" style="color: red;">This Bill Already Added</td>
        
    </tr>
</ng-template>
</p-table>
                </div>
            </p-tabPanel>
            
        </p-tabView>
        <div *ngIf="tangedcoBills" class="row">
            <h1>Selected Bills</h1>
        <p-table #dataTable  [value]="tangedcoBills" [paginator]="true" [rows]="5"  [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[5,10, 25, 50]">
<ng-template pTemplate="header">
<tr>
<th [ngClass]="fontService.labelClass">Consumer No</th>
<th [ngClass]="fontService.labelClass">Bill Type</th>
<th [ngClass]="fontService.labelClass">Bill Month</th>
<th [ngClass]="fontService.labelClass">Bill Year</th>
<th [ngClass]="fontService.labelClass">Bill Amount</th>
<th></th>


</tr>
</ng-template>
<ng-template pTemplate="body" let-data let-index="rowIndex">
<tr>
<td>{{ data.consumerNo}}</td>
<td>{{ data.billType}}</td>
<td>{{ data.billMonth}}</td>
<td>{{ data.billYear}}</td>
<td>{{ data.billAmount}}</td>
<td>
    <button
      pButton
      pRipple
      type="button"
      [disabled]="readonly"
      icon="pi pi-trash"
      class="p-button-rounded p-button-text p-button-danger"
      (click)="deletedSelectedBills(index,data)"
    ></button>
  </td>
</tr>
</ng-template>
</p-table>
        </div>
        <ng-template pTemplate="footer">
           Total Bill Amount : {{ totalBillAmount | currency:'INR':'symbol':'1.2-2':'en-IN'|| "Nill" }}<br>
           <button pButton pRipple label="Apply" (click)="apply()" class="p-button-success"></button>
        </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="bulkPaymentListDialog" [style]="{ width: '1500px', height: '800px' }" header="Last Transaction" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <button pButton pRipple label="Export to Excel" (click)="exportToExcel()" class="p-button-success p-button-sm export-button"></button>
        <p-table id="BulkPaymentList" [value]="bulkPaySearchResult?.items" [resizableColumns]="true" styleClass="dyanamic-table" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th [ngClass]="fontService.labelClass">Voucher No</th>
                    <th [ngClass]="fontService.labelClass">Consumer No</th>
                    <th [ngClass]="fontService.labelClass">Bill Type</th>
                    <th [ngClass]="fontService.labelClass">Bill Month</th>
                    <th [ngClass]="fontService.labelClass">Bill Year</th>
                    <th [ngClass]="fontService.labelClass">Bill Amount</th>
                    <th [ngClass]="fontService.labelClass">Transaction Date</th>
                    <th [ngClass]="fontService.labelClass">TNPASS Transaction Status</th>
                    <th [ngClass]="fontService.labelClass">TANGEDCO Tranasaction Status</th>
                    <th [ngClass]="fontService.labelClass">Journal Number</th>
                    <th [ngClass]="fontService.labelClass">Receipt Number</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-index="rowIndex">
                <tr>
                    <td>{{ data.voucherNo }}</td>
                    <td>{{ data.consumerNo}}</td>
                    <td>{{ data.billType}}</td>
                    <td>{{ data.billMonth}}</td>
                    <td>{{ data.billYear}}</td>
                    <td>{{ data.billAmount}}</td>
                    <td>{{ data.transactionDate | date : "shortDate" }}</td>
                    <td>{{ data.transactionStatus }}</td>
                    <td>{{data.tangedcoPaymentStatus}}</td>
                    <td>{{ data.journalNumber }}</td>
                    <td>{{ data.receiptNumber }}</td>
                </tr>
            </ng-template>
        </p-table>
    </p-dialog>
  </div>
</div>