import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report.service';
import { VoucherStatusReportDto, employeeCountDto } from '../report.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { VendorTransaction } from 'src/app/payments/payment-voucher/payment-voucher.model';
import { SearchResult } from 'src/app/shared/models/search-result';
import * as XLSX from 'xlsx';
import { filterQuery } from 'src/app/shared/common.model';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { ComponentType } from 'src/app/module/component-type/component-type';
import { DatePipe } from '@angular/common';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-voucher-status-report',
  templateUrl: './voucher-status-report.component.html',
  styleUrls: ['./voucher-status-report.component.scss']
})
export class VoucherStatusReportComponent implements OnInit {

  voucherStatusReportDto:VoucherStatusReportDto;
  voucherStatusReportBlockDto:VoucherStatusReportDto;
  voucherStatusReportVillageDto:VoucherStatusReportDto;
  activeIndex=0;
  roleCode: any;
  levelMasterId: any;
  isDMRole: boolean;
  cols: any;
  ERcols:any;
  itemsPerPage: any = 10;
  filterProps: any;
  filterPropsER: any;
  itemsPerPageER: any = 10;
  searchResult: SearchResult<VendorTransaction> =
    new SearchResult<VendorTransaction>();
  page: number;
  enableStatusVoucher: boolean;
  personalFlag: string;
  villageLevelMasterId: any;
  isBMRole: boolean;
  id: any;
  subEmployeeTypeId: any;
  post: any;
  role: string;
  maxDate = new Date();
  fromDate: string;
  startDate: string;
  toDate: string;
  endDate: string;
  dateError: boolean;
  compTypeList:ComponentType[];
  selectedcompType:any;
  compId: any=null;


  constructor(public reportService:ReportService,
      public fontService: FontService,
      private spinner: NgxSpinnerService,
      private loginService:SigninService,
      private paymentService:PaymentService,
      private componentTypeService:ComponentTypeService,
      private datePipe: DatePipe,
    ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.loginService.getuserDto().subscribe(
      (res) => {
       this.roleCode = res.body.role.code;
       this.levelMasterId=res.body.levelMasterId;
       this.componentTypeService.filter().subscribe(
        (res: HttpResponse<ComponentType[]>) => {
          this.compTypeList=res.body.filter(item => item.name.startsWith('Village'));
          this.selectedcompType=this.compTypeList[2];  
       this.loadDistrict();
      },
        ()=>{});
      },
      (error)=>{});
      
      this.cols = [
        {
          field: 'voucherNo',
          header: 'Voucher No',
          jhiTranslate: 'tableHead.voucher.no',
          isSelectcolumn: true,
        },
        {
          field: 'transDate',
          header: 'Transaction Date',
          jhiTranslate: 'tableHead.transaction.date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
        },
        {
          field: 'transactionAmount',
          header: 'Total Transaction Amount',
          jhiTranslate: 'tableHead.transaction.amount',
          isSelectcolumn: true,
          type:'amount'
        },
        {
          field: 'subComponentName',
          header: 'Sub Component Name',
          jhiTranslate: 'tableHead.sub.compName',
          isSortable: true,
          isSelectcolumn: true,
        }
      ];
  }

  onTabChange(event: any): void {
    if (event.index === 0 && (this.roleCode=='SA' || this.roleCode=='SM'|| this.roleCode=='SUA' )) {
      this.voucherStatusReportBlockDto=null;
      this.voucherStatusReportVillageDto=null;
    }
    else if(event.index === 0 && (this.roleCode=='DM'|| this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD' || this.roleCode ==='DA')) {
      this.voucherStatusReportVillageDto=null;
    }
  }

  loadDistrict(){ 
    this.spinner.show();
    if(!this.fromDate && !this.toDate)
    {
    this.startDate=this.datePipe.transform(new Date("04-01-2024"), 'yyyy-MM-dd')+' 00:00:00.000';
    this.endDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
    }
    const payload: any = {
      parentId: 1,
      fromDate: this.startDate,
      toDate: this.endDate
    };
    if (this.selectedcompType) {
      payload.id = this.selectedcompType.id;
    } 
     if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA')
     {
      this.reportService.getVoucherStatusDistrict(payload).subscribe(
        (res: HttpResponse<VoucherStatusReportDto>) => {
          this.voucherStatusReportDto=res.body;
          this.voucherStatusReportDto.voucherStatusReports.forEach((element)=>{
            for (const key in element) {
              if (element.hasOwnProperty(key) && element[key] === null) {
                element[key] = 0;
              }
          }
        })
          this.spinner.hide();
        },
        (err)=>{
          this.spinner.hide();
        })
      }
      else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD' || this.roleCode==='DA'){
        this.loadBlock(this.levelMasterId);
        this.isDMRole=true;
      } else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2' || this.roleCode == 'BAD'){
        this.loadVillage(this.levelMasterId);
        this.isBMRole=true;
      }
  }

  loadBlock(id){
    this.spinner.show();
    if(!this.fromDate && !this.toDate)
    {
      this.startDate=this.datePipe.transform(new Date("04-01-2024"), 'yyyy-MM-dd')+' 00:00:00.000';
      this.endDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
    }
    const payload: any = {
      parentId: id,
      fromDate: this.startDate,
      toDate: this.endDate
    };
    if (this.selectedcompType) {
      payload.id = this.selectedcompType.id;
    }  
    this.reportService.getVoucherStatusBlock(payload).subscribe(
      (res: HttpResponse<VoucherStatusReportDto>) => {
        this.voucherStatusReportBlockDto=res.body;
        this.voucherStatusReportBlockDto.voucherStatusReports.forEach((element)=>{
          for (const key in element) {
            if (element.hasOwnProperty(key) && element[key] === null) {
              element[key] = 0;
            }
        }
      })
        if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
          this.activeIndex=1;
        } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD'){
          this.activeIndex=0;
        }
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }
  loadVillage(id){
    this.spinner.show();const payload: any = {
      parentId: id,
      fromDate: this.startDate,
      toDate: this.endDate
    };
    if (this.selectedcompType) {
      payload.id = this.selectedcompType.id;
    } 
    this.reportService.getVoucherStatusVillage(payload).subscribe(
      (res: HttpResponse<VoucherStatusReportDto>) => {
        res.body.voucherStatusReports.forEach((element)=>{
          for (const key in element) {
            if (element.hasOwnProperty(key) && (element[key] === null || element[key] === 0)) {
              element[key] = "0";
            }
        }
      })
      this.voucherStatusReportVillageDto=res.body;
        if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
          this.activeIndex=2;
        } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD'){
          this.activeIndex=1;
        } else if(this.roleCode=='BM' || this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){
          this.activeIndex=0;
        } 
        this.spinner.hide();
      },
      (err)=>{
        this.spinner.hide();
      }
    )
  }

  loadVoucher(id,flag:string,role:string){
    this.personalFlag=flag;
    this.villageLevelMasterId=id;
    this.enableStatusVoucher=true;
    this.role=role;
    this.loadPage(1);
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    var i;
    if (this.filterProps){
        for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
          if (this.filterProps[i].key === 'levelMasterId') {
            this.filterProps.splice(i, 1);
          }
        }
      for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
        if (this.filterProps[i].key === 'personalFlag') {
          this.filterProps.splice(i, 1);
        }
      }
      for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
        if (this.filterProps[i].key === 'activeFlag') {
          this.filterProps.splice(i, 1);
        }
    }    
  }
    let query = new filterQuery();
    query.key = 'levelMasterId';
    query.operation = 'equals';
    query.value = this.villageLevelMasterId;
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push(query);
    query = new filterQuery();
    query.key = 'personalFlag';
    query.operation = 'equals';
    query.value = this.personalFlag;
    this.filterProps.push(query);
    query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterProps.push(query);
    switch (true) {
      case this.role=='M':
        query = new filterQuery();
        query.key = 'signatureSecretary';
        query.operation = 'null';
        query.value = null;
        this.filterProps.push(query); 
        query = new filterQuery();
        query.key = 'signatureVicePresident';
        query.operation = 'null';
        query.value = null;
        this.filterProps.push(query); 
        query = new filterQuery();
        query.key = 'signaturePresident';
        query.operation = 'null';
        query.value = null;
        this.filterProps.push(query);
        break;
      case this.role=='C1':
        query = new filterQuery();
        query.key = 'signatureSecretary';
        query.operation = 'equals';
        query.value = 'SECERATORY SIGNED';
        this.filterProps.push(query); 
        query = new filterQuery();
        query.key = 'signatureVicePresident';
        query.operation = 'null';
        query.value = null;
        this.filterProps.push(query); 
        query = new filterQuery();
        query.key = 'signaturePresident';
        query.operation = 'null';
        query.value = null;
        this.filterProps.push(query);
        break;
      case this.role=='C2': query = new filterQuery();
      query.key = 'signatureSecretary';
      query.operation = 'equals';
      query.value = 'SECERATORY SIGNED';
      this.filterProps.push(query); 
      query = new filterQuery();
      query.key = 'signatureVicePresident';
      query.operation = 'equals';
      query.value = 'VICE PRESIDENT SIGNED';
      this.filterProps.push(query); 
      query = new filterQuery();
      query.key = 'signaturePresident';
      query.operation = 'null';
      query.value = null;
      this.filterProps.push(query);
      break;
      case this.role=='A': query = new filterQuery();
      query.key = 'signatureSecretary';
      query.operation = 'equals';
      query.value = 'SECERATORY SIGNED';
      this.filterProps.push(query); 
      query = new filterQuery();
      query.key = 'signatureVicePresident';
      query.operation = 'equals';
      query.value = 'VICE PRESIDENT SIGNED';
      this.filterProps.push(query); 
      query = new filterQuery();
      query.key = 'signaturePresident';
      query.operation = 'equals';
      query.value = 'PRESIDENT SIGNED';
      this.filterProps.push(query);
      break;
      default:
    }   
    if(!this.fromDate && !this.toDate)
      {
        this.startDate=this.datePipe.transform(new Date("04-01-2024"), 'yyyy-MM-dd')+' 00:00:00.000';
        this.endDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
      }
      let filter = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        fromDate: this.startDate,
        toDate: this.endDate
      };
    this.reportService.VendorTransactionDays({ ...filter }, this.filterProps).subscribe(
      (res: HttpResponse<VendorTransaction[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        this.spinner.hide();
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: VendorTransaction[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
    this.spinner.hide();
  }


  onLazyLoad(event) {

    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog(){
    this.enableStatusVoucher = false;
    this.filterProps=[];
    this.itemsPerPage=10;
  }

  export(){
    if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA' ) {
      if (this.activeIndex === 0) {
          this.exportToExcel('district-table', 'DistrictWiseVoucherStatusReport.xlsx');
      } else if (this.activeIndex === 1) {
          this.exportToExcel('block-table', 'BlockWiseVoucherStatusReport.xlsx');
      } else if (this.activeIndex === 2) {
          this.exportToExcel('village-table', 'VillageWiseVoucherStatusReport.xlsx');
      }
    }
    else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD'){ 
      if (this.activeIndex === 0) {
        this.exportToExcel('block-table', 'BlockWiseVoucherStatusReport.xlsx');
      } else if (this.activeIndex === 1) {
        this.exportToExcel('village-table', 'VillageWiseVoucherStatusReport.xlsx');
      }
    }
    else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){ 
        this.exportToExcel('village-table', 'VillageWiseVoucherStatusReport.xlsx');
    }
  }

  exportToExcel(id,filename): void {
    let element = document.getElementById(id);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
  }

  onSearch(){
    this.activeIndex=0;
      if(this.fromDate && this.toDate)
      {
    this.startDate=this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd')+' 00:00:00.000';
    this.endDate=this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd')+' 23:59:59.999';
      }
    if (this.startDate > this.endDate) {
      this.dateError = true;
      return
    }
      this.dateError = false;
     if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='SUA'){
      this.voucherStatusReportBlockDto=null;
      this.voucherStatusReportVillageDto=null;
      this.loadDistrict();
     } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' || this.roleCode=='DAD'){
      this.voucherStatusReportVillageDto=null;
      this.loadBlock(this.levelMasterId);
     }  else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){
      this.loadVillage(this.levelMasterId);
     }
  }

  voucherPendingExcel(){
    this.spinner.show();
    this.reportService.voucherPendingExcel().subscribe(
      (response)=>{
        this.spinner.hide();
        this.saveBlobToExcel(response.body,'Vendor Transaction Days');
      },
      ()=>{
        this.spinner.hide();
      }
    )
  }
  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
      }

}

