<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.employeeOnboard"></h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Export to Excel" (click)="export()" class="p-button-success p-button-sm export-button"></button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
            <p-tabPanel header="District" *ngIf="!isDMRole && !isBMRole">
                <div class="col-12 px-4" *ngIf="employeeCountDto">
                    <p-table id="district-table" [value]="employeeCountDto.employeeCountDTOs" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="districtName" filterMatchMode="icon">District Name
                                    <p-columnFilter type="text" field="districtName" display="menu"></p-columnFilter>
                                </th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Time Scale Employee</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Non Time Scale Employee</th>
                                <th colspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">Elected Representative</th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="villagePanchayatSecretaryCount">Village Panchayat Secretary</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="thoomaiPaniyalargalCount">Thoomai Paniyalargal</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="ohtOperatorsCount">OHT Operator</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="thoomaiPaniyalargalOthersCount">Thoomai Paniyalargal (Others) </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="villagePanchayatPresidentCount"> Panchayat President</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="wardMemberCount">Ward Member</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="vicePresident">Vice President</th>
                            </tr>

                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.districtName" (click)="loadBlock(list.districtId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.villagePanchayatSecretaryCount}}</td>
                                <td style="text-align: center;">{{list.thoomaiPaniyalargalCount}}</td>
                                <td style="text-align: center;">{{list.ohtOperatorsCount}}</td>
                                <td style="text-align: center;">{{list.thoomaiPaniyalargalOthersCount}}</td>
                                <td style="text-align: center;">{{list.villagePanchayatPresidentCount}}</td>
                                <td style="text-align: center;">{{list.wardMemberCount}}</td>
                                <td style="text-align: center;">{{list.vicePresident}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: center;">Total</td>
                                <td style="text-align: center;">{{ employeeCountDto.villagePanchayatSecretaryCountTotal }}</td>
                                <td style="text-align: center;">{{ employeeCountDto.thoomaiPaniyalargalCountTotal}}</td>
                                <td style="text-align: center;">{{ employeeCountDto.ohtOperatorsCountTotal }}</td>
                                <td style="text-align: center;">{{ employeeCountDto.thoomaiPaniyalargalOthersCountTotal}}</td>
                                <td style="text-align: center;">{{ employeeCountDto.villagePanchayatPresidentCountTotal }}</td>
                                <td style="text-align: center;">{{ employeeCountDto.wardMemberCountTotal}}</td>
                                <td style="text-align: center;">{{ employeeCountDto.vicePresidentTotal }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Block" *ngIf="!isBMRole">
                <div *ngIf="!employeeCountDtoBlockDto">Choose distict to get block report</div>
                <div *ngIf="employeeCountDtoBlockDto" class="col-12 px-4">
                    <p-table id="block-table" [value]="employeeCountDtoBlockDto.employeeCountDTOs" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="districtName" filterMatchMode="icon">Block Name
                                    <p-columnFilter type="text" field="districtName" display="menu"></p-columnFilter>
                                </th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Time Scale Employee</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Non Time Scale Employee</th>
                                <th colspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">Elected Representative</th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="villagePanchayatSecretaryCount">Village Panchayat Secretary</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="thoomaiPaniyalargalCount">Thoomai Paniyalargal</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="ohtOperatorsCount">OHT Operator</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="thoomaiPaniyalargalOthersCount">Thoomai Paniyalargal (Others) </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="villagePanchayatPresidentCount"> Panchayat President</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="wardMemberCount">Ward Member</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="vicePresident">Vice President</th>
                            </tr>

                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.districtName" (click)="loadVillage(list.districtId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.villagePanchayatSecretaryCount}}</td>
                                <td style="text-align: center;">{{list.thoomaiPaniyalargalCount}}</td>
                                <td style="text-align: center;">{{list.ohtOperatorsCount}}</td>
                                <td style="text-align: center;">{{list.thoomaiPaniyalargalOthersCount}}</td>
                                <td style="text-align: center;">{{list.villagePanchayatPresidentCount}}</td>
                                <td style="text-align: center;">{{list.wardMemberCount}}</td>
                                <td style="text-align: center;">{{list.vicePresident}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: center;">Total</td>
                                <td style="text-align: center;">{{ employeeCountDtoBlockDto.villagePanchayatSecretaryCountTotal }}</td>
                                <td style="text-align: center;">{{ employeeCountDtoBlockDto.thoomaiPaniyalargalCountTotal}}</td>
                                <td style="text-align: center;">{{ employeeCountDtoBlockDto.ohtOperatorsCountTotal }}</td>
                                <td style="text-align: center;">{{ employeeCountDtoBlockDto.thoomaiPaniyalargalOthersCountTotal}}</td>
                                <td style="text-align: center;">{{ employeeCountDtoBlockDto.villagePanchayatPresidentCountTotal }}</td>
                                <td style="text-align: center;">{{ employeeCountDtoBlockDto.wardMemberCountTotal}}</td>
                                <td style="text-align: center;">{{ employeeCountDtoBlockDto.vicePresidentTotal }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Village">
                <div *ngIf="!employeeCountDtoVillageDto">Choose block to get village report</div>
                <div *ngIf="employeeCountDtoVillageDto" class="col-12 px-4">
                    <p-table id="village-table" [value]="employeeCountDtoVillageDto.employeeCountDTOs" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="districtName" filterMatchMode="icon">Village Name
                                    <p-columnFilter type="text" field="districtName" display="menu"></p-columnFilter>
                                </th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Time Scale Employee</th>
                                <th colspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">Non Time Scale Employee</th>
                                <th colspan="3" style="text-align: center;" [ngClass]="fontService.labelClass">Elected Representative</th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="villagePanchayatSecretaryCount">Village Panchayat Secretary</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="thoomaiPaniyalargalCount">Thoomai Paniyalargal</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="ohtOperatorsCount">OHT Operator</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="thoomaiPaniyalargalOthersCount">Thoomai Paniyalargal (Others) </th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="villagePanchayatPresidentCount"> Panchayat President</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="wardMemberCount">Ward Member</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="vicePresident">Vice President</th>
                            </tr>

                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">{{list.districtName}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.villagePanchayatSecretaryCount" (click)="loadEmployee(list.districtId,1)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.thoomaiPaniyalargalCount" (click)="loadEmployee(list.districtId,2)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.ohtOperatorsCount" (click)="loadEmployee(list.districtId,3)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.thoomaiPaniyalargalOthersCount" (click)="loadEmployee(list.districtId,4)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.villagePanchayatPresidentCount" (click)="loadER(list.districtId,'Village Panchayat President')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.wardMemberCount" (click)="loadER(list.districtId,'Ward Member')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.vicePresident" (click)="loadER(list.districtId,'Vice President')" styleClass="p-button-link"></p-button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: center;">Total</td>
                                <td style="text-align: center;">{{ employeeCountDtoVillageDto.villagePanchayatSecretaryCountTotal }}</td>
                                <td style="text-align: center;">{{ employeeCountDtoVillageDto.thoomaiPaniyalargalCountTotal}}</td>
                                <td style="text-align: center;">{{ employeeCountDtoVillageDto.ohtOperatorsCountTotal }}</td>
                                <td style="text-align: center;">{{ employeeCountDtoVillageDto.thoomaiPaniyalargalOthersCountTotal}}</td>
                                <td style="text-align: center;">{{ employeeCountDtoVillageDto.villagePanchayatPresidentCountTotal }}</td>
                                <td style="text-align: center;">{{ employeeCountDtoVillageDto.wardMemberCountTotal}}</td>
                                <td style="text-align: center;">{{ employeeCountDtoVillageDto.vicePresidentTotal }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<p-dialog [(visible)]="enableEmployee" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
        Employee List
    </p-header>
    <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onEmployeeLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false"></custom-filter-table>
</p-dialog>
<p-dialog [(visible)]="enableER" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideERDialog()">
    <p-header [ngClass]="fontService.headingClass">
        ER List
    </p-header>
    <custom-filter-table [columnsValues]="ERcols" [filterSearchResult]="ERsearchResult" (searchParam)="onERLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false"></custom-filter-table>
</p-dialog>