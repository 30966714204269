import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { SwapTransaction } from './component-swap-model';

export type EntityResponseType = HttpResponse<SwapTransaction[]>;

@Injectable({ providedIn: 'root' })
export class ComponentSwapService {
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getAllComponentLimitSwapByFilter');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveComponentLimitSwap');
    protected getComponentLimitSwapByIdUrl = environment.SERVER_API_URL+('api/getComponentLimitSwapById');
    protected approveComponentLimitSwapUrl = environment.SERVER_API_URL+('api/approveComponentLimitSwap');
    protected deleteComponentLimitSwapUrl = environment.SERVER_API_URL+('api/deleteComponentLimitSwap');
    protected componentSwapWithDescriptionByIdUrl = environment.SERVER_API_URL+('api/componentSwapWithDescriptionById');
    protected componentSwapCdacRequestUrl = environment.SERVER_API_URL+('api/componentSwapCdacRequest');
    protected rejectComponentLimitSwapUrl = environment.SERVER_API_URL+('api/rejectComponentLimitSwap');
    protected getPdfUrl = environment.SERVER_API_URL + 'api/emuthraSignedDownload';
    constructor(protected http: HttpClient) {}
    
    filter(req?: any, filter?: any): Observable<any> {
        if (filter === null) {
          filter = [];
        }
        const options = createRequestOption(req);
        return this.http.post<any[]>(this.resourceFliterUrl, filter, {
          params: options,
          observe: 'response',
        });
    }

    save(payload:SwapTransaction): any {
      return this.http.post(this.resourceSaveUrl,payload, { observe: 'response' });
    }

    getComponentLimitSwapById(id: number): Observable<HttpResponse<any>> {
      return this.http.get<SwapTransaction>(
        `${this.getComponentLimitSwapByIdUrl}?id=${id}`,
        { observe: 'response' }
      );
    }

    componentSwapWithDescriptionById(id: number): Observable<HttpResponse<any>> {
      return this.http.get<SwapTransaction>(
        `${this.componentSwapWithDescriptionByIdUrl}?id=${id}`,
        { observe: 'response' }
      );
    }

    

    approveComponentLimitSwap(id: number){
      return this.http.post(
        `${this.approveComponentLimitSwapUrl}?id=${id}`,
        { responseType: 'text' }
      );
    }
    deleteComponentLimitSwap(id: number) {
      return this.http.post(
        `${this.deleteComponentLimitSwapUrl}?id=${id}`,
        { responseType: 'text' }
      );
    }

    getfile(fileName: string): Observable<any> {
      return this.http.get(`${this.componentSwapCdacRequestUrl}?fileName=${fileName}`, {
        observe: 'response',
      });
    }

    rejectTransaction(id:number) {
      return this.http.get<any>(`${this.rejectComponentLimitSwapUrl}?id=${id}`, {
        observe: 'response',
      });
    }

    getPdfFile(req) {
      const options = createRequestOption(req);
      return this.http.get(`${this.getPdfUrl}`, {
        params: options,
        observe: 'response',
        responseType: 'arraybuffer',
      });
    }

    





   

   
}