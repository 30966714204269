<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="tableHead.iACompo.limitTrans.list">Limit Transaction List</h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="roleCode === 'SA'" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button" (click)="openNew()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton">
            </span>
            </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="limitSetDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog" [style]="{ width: '850px' }">
        <p-header [ngClass]="fontService.headingClass">
            {{"iACompo.limitTrans.add.limitTrans"| translate}}
        </p-header>
        <ng-template pTemplate="content">
            <p-tabView>
                <p-tabPanel [header]="'iACompo.limitTrans.limitTransGf'|translate">
                    <div class="col-12">
                        <div class="form-inner-panel">
                            <div class="row gy-2">
                                <div *ngIf="enableForm" class="col-sm-6 col-md-4">
                                    <label class="form-control-label" for="department" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.department.name">Department</label>
                                    <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="departmentDto" [items]="departmentList" bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control" [clearable]="true">
                                    </ng-select>
                                </div>
                                <div *ngIf="enableForm" class="col-sm-6 col-md-4">
                                    <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.scheme.name">Scheme</label>
                                    <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="schemeDto" [items]="schemeList" bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control" [clearable]="true">
                                    </ng-select>
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <label class="form-control-label" for="gender" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.levelType">Level Type</label>
                                    <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" appearance="outline" [items]="levelTypeList" [closeOnSelect]="true" [(ngModel)]="limitTranslist.levelTypeDto" [disabled]="enableForm" [clearable]="true" (change)="onLevelTypeChange()" class="form-control">
                                    </ng-select>
                                </div>
                                <div *ngIf="enableForm" class="col-sm-6 col-md-4">
                                    <label class="form-control-label" for="gender" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.compoType">Component Type</label>
                                    <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" [items]="ComponentType" [(ngModel)]="limitTranslist.componentTypeDto" class="form-control">
                                    </ng-select>
                                </div>
                                <div *ngIf="enableForm" class="col-sm-6 col-md-4">
                                    <label class="form-control-label" for="gender" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.fin.year.name">Fin Year</label>
                                    <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" [items]="finYear" appearance="outline" [(ngModel)]="limitTranslist.finYearDto" [closeOnSelect]="true" [clearable]="true" class="form-control">
                                    </ng-select>
                                </div>
                                <div *ngIf="enableForm" class="col-sm-6 col-md-4">
                                    <label class="form-control-label" for="amount1" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.amount">Amount</label>
                                    <input type="text" class="form-control" name="amount1" autocomplete="off" id="amount1" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="25" [(ngModel)]="limitTranslist.amount" autofocus />

                                </div>
                                <div *ngIf="enableForm" class="text-end mt-3">
                                    <button pButton pRipple (click)="calcAmount()" icon="pi pi-check" class="p-button-text custom-button">
                    <span
                  class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton">
                    </span>
                  </button>
                                </div>
                            </div>
                            <div *ngIf="enableTable" class="col-sm-6 col-md-4">
                                <label class="form-control-label" for="gender" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.compoType">Component Type</label>
                                <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" [items]="ComponentType" appearance="outline" [(ngModel)]="limitTranslist.componentTypeDto" [closeOnSelect]="true" [clearable]="true" class="form-control" [disabled]="true">
                                </ng-select>
                            </div>
                            <div *ngIf="enableTable" class="col-sm-6 col-md-4">
                                <label class="form-control-label" for="gender" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.fin.year.name">Fin Year</label>
                                <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" [items]="finYear" appearance="outline" [(ngModel)]="limitTranslist.finYearDto" [closeOnSelect]="true" [clearable]="true" class="form-control" [disabled]="true">
                                </ng-select>
                            </div>
                            <div *ngIf="enableTable" class="col-sm-6 col-md-4">
                                <label class="form-control-label" for="aadhaar" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.amount">Amount</label>
                                <input type="number" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="25" name="aadhaar" autocomplete="off" id="aadhaar" [(ngModel)]="limitTranslist.amount" required autofocus readonly
                                />
                            </div>
                        </div>

                        <custom-filter-table *ngIf="enableTable" [columnsValues]="formulaCol" [filterSearchResult]="searchFormulaResult" (searchParam)="onFormulaLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true"></custom-filter-table>
                    </div>

                    <div class="col-12 mt-3" *ngIf="showText">
                        <h1>Already a request in progress</h1>
                    </div>
                    <div class="col-12 mt-3" *ngIf="this.limitTranslist.status">
                        <h1>{{ this.limitTranslist.status }}</h1>
                    </div>
                    <div class="text-end mt-3">
                        <button *ngIf="enableTable && !this.limitTranslist.status" pButton pRipple (click)="schedule()" icon="pi pi-check" class="p-button-text custom-button">
              <span
            class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton">
              </span>
            </button>
                    </div>
                </p-tabPanel>
                <p-tabPanel [header]="'iACompo.limitTrans.limitTrans'|translate">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="department" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.department.name">Department</label>
                            <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="limitTransDto.departmentDto" [items]="departmentList" bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control" [clearable]="true" [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.departmentDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.scheme.name">Scheme</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="limitTransDto.schemeDto" [items]="schemeList" bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control" [clearable]="true" [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.schemeDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="gender" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.levelType">Level Type</label>
                            <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" appearance="outline" [items]="levelTypeList" [closeOnSelect]="true" [(ngModel)]="limitTransDto.levelTypeDto" [clearable]="true" (change)="onLevelTypeChangeTrans()" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.levelTypeDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.levelMaster">Level Master</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="limitTransDto.levelMasterDto" [items]="levelMasterList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="true" (search)="onLevelTypeChangeTrans($event)"
                                (change)="onLevelMasterChange()" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.levelMasterDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.iA.name">Implementing Agency</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="limitTransDto.implementingAgencyDto" [items]="iaCompList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="true" (search)="onLevelTypeChangeTrans($event)"
                                (change)="onLevelMasterChange()" (ngModelChange)="checkIa()" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.implementingAgencyDto }">
                            </ng-select>
                            <!-- <p *ngIf="iaError" style="font-size: 14px; color: #ff0000">
                {{iaError}}
              </p> -->
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="gender" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.compoType">Component Type</label>
                            <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" [items]="ComponentType" appearance="outline" [(ngModel)]="limitTransDto.componentTypeDto" [closeOnSelect]="true" [clearable]="true" [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.componentTypeDto }"
                                class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="gender" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.fin.year.name">Fin Year</label>
                            <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" [items]="finYear" appearance="outline" [(ngModel)]="limitTransDto.finYearDto" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.finYearDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="amount" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.amount">Amount</label>
                            <input type="text" id="amount" name="amount" autocomplete="off" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" class="form-control" [(ngModel)]="limitTransDto.amount" [ngClass]="{ 'is-invalid': ngSelectInvalid && !limitTransDto.amount }"
                                autofocus />
                        </div>
                    </div>
                    <div class="text-end">
                        <button pButton pRipple (click)="saveData()" icon="pi pi-check" class="p-button-text custom-button">
            <span
          class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton">
            </span>
          </button>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </ng-template>
        <!-- <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"></button>
        </ng-template> -->
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>