<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.report.voucher"></h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" [(ngModel)]="fromDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" [(ngModel)]="toDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate"></p-calendar>
        <p-dropdown placeholder="Select Component" [options]="compTypeList" [(ngModel)]="selectedcompType" optionLabel="name"></p-dropdown>
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
        <button pButton pRipple label="Download" (click)="voucherPendingExcel()" class="p-button-success p-button-sm export-button"></button>
    </div>
    <div class="row">
        <div *ngIf="dateError" class="text-danger mb-2">
            <small [ngClass]="fontService.labelClass" jhiTranslate="error.invaliddate">Please
                choose valid from and to
                date</small>
        </div>
    </div>
    <div class="row">
        <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
            <p-tabPanel header="District" *ngIf="!isDMRole && !isBMRole">
                <div class="col-12 px-4" *ngIf="voucherStatusReportDto">
                    <p-table id="district-table" [value]="voucherStatusReportDto.voucherStatusReports" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Personal</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Commercial</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Employee</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Elected Representative</th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalApproved">Approved</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialApproved">Approved</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeApproved">Approved</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepApproved">Approved</th>
                            </tr>

                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadBlock(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.personalMakerPending}}</td>
                                <td style="text-align: center;">{{list.personalChecker1Pending}}</td>
                                <td style="text-align: center;">{{list.personalChecker2Pending}}</td>
                                <td style="text-align: center;">{{list.personalApproved}}</td>
                                <td style="text-align: center;">{{list.commercialMakerPending}}</td>
                                <td style="text-align: center;">{{list.commercialChecker1Pending}}</td>
                                <td style="text-align: center;">{{list.commercialChecker2Pending}}</td>
                                <td style="text-align: center;">{{list.commercialApproved}}</td>
                                <td style="text-align: center;">{{list.employeeMakerPending}}</td>
                                <td style="text-align: center;">{{list.employeeChecker1Pending}}</td>
                                <td style="text-align: center;">{{list.employeeChecker2Pending}}</td>
                                <td style="text-align: center;">{{list.employeeApproved}}</td>
                                <td style="text-align: center;">{{list.electedRepMakerPending}}</td>
                                <td style="text-align: center;">{{list.electedRepChecker1Pending}}</td>
                                <td style="text-align: center;">{{list.electedRepChecker2Pending}}</td>
                                <td style="text-align: center;">{{list.electedRepApproved}}</td>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: center;">Total</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.personalmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.personalChecker1Total}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.personalChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.personalApprovedTotal}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.commercialmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.commercialChecker1Total}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.commercialChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.commercialApprovedTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.employeemakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.employeeChecker1Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.employeeChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.employeeApprovedTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.electedRepmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.electedRepChecker1Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.electedRepChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportDto.electedRepApprovedTotal }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel *ngIf="!isBMRole" header="Block">
                <div *ngIf="!voucherStatusReportBlockDto">Choose distict to get block report</div>
                <div *ngIf="voucherStatusReportBlockDto" class="col-12 px-4">
                    <p-table id="block-table" [value]="voucherStatusReportBlockDto.voucherStatusReports" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">District Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Personal</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Commercial</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Employee</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Elected Representative</th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalApproved">Approved</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialApproved">Approved</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeApproved">Approved</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepApproved">Approved</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.levelMasterName" (click)="loadVillage(list.levelMasterId)" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">{{list.personalMakerPending}}</td>
                                <td style="text-align: center;">{{list.personalChecker1Pending}}</td>
                                <td style="text-align: center;">{{list.personalChecker2Pending}}</td>
                                <td style="text-align: center;">{{list.personalApproved}}</td>
                                <td style="text-align: center;">{{list.commercialMakerPending}}</td>
                                <td style="text-align: center;">{{list.commercialChecker1Pending}}</td>
                                <td style="text-align: center;">{{list.commercialChecker2Pending}}</td>
                                <td style="text-align: center;">{{list.commercialApproved}}</td>
                                <td style="text-align: center;">{{list.employeeMakerPending}}</td>
                                <td style="text-align: center;">{{list.employeeChecker1Pending}}</td>
                                <td style="text-align: center;">{{list.employeeChecker2Pending}}</td>
                                <td style="text-align: center;">{{list.employeeApproved}}</td>
                                <td style="text-align: center;">{{list.electedRepMakerPending}}</td>
                                <td style="text-align: center;">{{list.electedRepChecker1Pending}}</td>
                                <td style="text-align: center;">{{list.electedRepChecker2Pending}}</td>
                                <td style="text-align: center;">{{list.electedRepApproved}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: center;">Total</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.personalmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.personalChecker1Total}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.personalChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.personalApprovedTotal}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.commercialmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.commercialChecker1Total}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.commercialChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.commercialApprovedTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.employeemakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.employeeChecker1Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.employeeChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.employeeApprovedTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.electedRepmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.electedRepChecker1Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.electedRepChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportBlockDto.electedRepApprovedTotal }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Village">
                <div *ngIf="!voucherStatusReportVillageDto">Choose block to get village report</div>
                <div *ngIf="voucherStatusReportVillageDto" class="col-12 px-4">
                    <p-table id="village-table" [value]="voucherStatusReportVillageDto.voucherStatusReports" [resizableColumns]="true" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass">S.NO</th>
                                <th rowspan="2" style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="levelMasterName" filterMatchMode="icon">Village Name
                                    <p-columnFilter type="text" field="levelMasterName" display="menu"></p-columnFilter>
                                </th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Personal</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Commercial</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Employee</th>
                                <th colspan="4" style="text-align: center;" [ngClass]="fontService.labelClass">Elected Representative</th>
                            </tr>
                            <tr>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="personalApproved">Approved</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="commercialApproved">Approved</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="employeeApproved">Approved</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepMakerPending">Maker</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepChecker1Pending">Checker1</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepChecker2Pending">Checker2</th>
                                <th style="text-align: center;" [ngClass]="fontService.labelClass" pSortableColumn="electedRepApproved">Approved</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-list let-index="rowIndex">
                            <tr>
                                <td style="text-align: center;">{{index+1}}</td>
                                <td style="text-align: center;">{{list.levelMasterName}}</td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.personalMakerPending" (click)="loadVoucher(list.levelMasterId,'YES','M')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.personalChecker1Pending" (click)="loadVoucher(list.levelMasterId,'YES','C1')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.personalChecker2Pending" (click)="loadVoucher(list.levelMasterId,'YES','C2')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.personalApproved" (click)="loadVoucher(list.levelMasterId,'YES','A')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.commercialMakerPending" (click)="loadVoucher(list.levelMasterId,'NO','M')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.commercialChecker1Pending" (click)="loadVoucher(list.levelMasterId,'NO','C1')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.commercialChecker2Pending" (click)="loadVoucher(list.levelMasterId,'NO','C2')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.commercialApproved" (click)="loadVoucher(list.levelMasterId,'NO','A')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.employeeMakerPending" (click)="loadVoucher(list.levelMasterId,'E','M')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.employeeChecker1Pending" (click)="loadVoucher(list.levelMasterId,'E','C1')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.employeeChecker2Pending" (click)="loadVoucher(list.levelMasterId,'E','C2')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.employeeApproved" (click)="loadVoucher(list.levelMasterId,'E','A')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.electedRepMakerPending" (click)="loadVoucher(list.levelMasterId,'ER','M')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.electedRepChecker1Pending" (click)="loadVoucher(list.levelMasterId,'ER','C1')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.electedRepChecker2Pending" (click)="loadVoucher(list.levelMasterId,'ER','C2')" styleClass="p-button-link"></p-button>
                                </td>
                                <td style="text-align: center;">
                                    <p-button [label]="list.electedRepApproved" (click)="loadVoucher(list.levelMasterId,'ER','A')" styleClass="p-button-link"></p-button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <tr>
                                <td colspan="2" style="text-align: center;">Total</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.personalmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.personalChecker1Total}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.personalChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.personalApprovedTotal}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.commercialmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.commercialChecker1Total}}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.commercialChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.commercialApprovedTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.employeemakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.employeeChecker1Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.employeeChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.employeeApprovedTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.electedRepmakerTotal }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.electedRepChecker1Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.electedRepChecker2Total }}</td>
                                <td style="text-align: center;">{{ voucherStatusReportVillageDto.electedRepApprovedTotal }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="hideDialog()">
    <p-header [ngClass]="fontService.headingClass">
        Transaction List
    </p-header>
    <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true" [showRedirectButton]="false"></custom-filter-table>
</p-dialog>