import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { filterQuery } from 'src/app/shared/common.model';
import { TwadAccountSummary } from '../twad-master-list/twad.model';
import { TwadMasterService } from '../twad-master-list/twad.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { FilterMatchMode, LazyLoadEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportService } from 'src/app/report/report.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-twad-account-summary',
  templateUrl: './twad-account-summary.component.html',
  styleUrl: './twad-account-summary.component.scss'
})
export class TwadAccountSummaryComponent {
  roleCode: any;
  finYearList: any;
  finYear: any;
  defaultFinyear: any;
  fundDetails: TwadAccountSummary[];
  totalCount: number;
  itemsPerPage:number = 10;
  matchModeOptions = [
    { label: "Contains", value: FilterMatchMode.CONTAINS },
    { label: "Equals", value: FilterMatchMode.EQUALS  },
  
  ];
  page: number;
  first = 0;
  tableData = {
    openingBalance: 5000,
    sanctionedAmount: 10000,
    grandTotalSanctioned: 15000,
    dueAmountTANGEDCO: 10000,
    dueAmountTWAD: 12000,
    voucherInitiatedTANGEDCO: 3000,
    voucherInitiatedTWAD: 2500,
    voucherRejectedTANGEDCO: 0,
    voucherRejectedTWAD: 500,
    expenditureMadeTANGEDCO: 3000,
    expenditureMadeTWAD: 2000,
    balanceDueTANGEDCO: 7000,
    balanceDueTWAD: 10000,
    balanceSanctionLimit: 10000
  };
  filterProps: any[]=[];

  constructor(
    private loginService: SigninService,
    private limitTransListService:LimitTransListService,
    protected twadMasterService:TwadMasterService,
    public fontService: FontService,
    private spinner: NgxSpinnerService,
    public reportService:ReportService,
    protected notificationService: NotificationService,
  ) { 
    
  }

  async ngOnInit(): Promise<void> {
    await this.getRole();
    await this.loadFinYear();
    this.LoadTangedcoBill(1);
    
  }

  loadFinYear(): Promise<any> {
    return new Promise<any>((resolve) => {
        this.limitTransListService.getFinYear().subscribe(
          (res: HttpResponse<FinYear[]>) => {
            this.finYearList = res.body;
            let FinYear = ''
            if (new Date().getMonth() + 1 >= 4) {
              FinYear =
                new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
            } else {
              FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
            }
            this.finYear = this.finYearList.find((_) => _.name === FinYear);
            this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
            this.defaultFinyear =  this.finYearList.find((_) => _.name === FinYear);
            resolve(this.finYear);
          },
          () => { }
        )
         
        
    });
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode);
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  LoadTangedcoBill(page) {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let filter = {
      page:pageToLoad-1,
      size:this.itemsPerPage,
      finYearId:this.finYear.id,
    };
    let query = new filterQuery();
    query.key = 'finYear.id';
    query.operation = 'equals';
    query.value = this.finYear.id;
    this.filterProps.push(query);
    if(this.roleCode.role.code === 'VMAKER' || this.roleCode.role.code === 'VC1' || this.roleCode.role.code === 'VC2') {
      query = new filterQuery();
      query.key = 'levelMaster.id';
      query.operation = 'equals';
      query.value = this.roleCode.levelMasterId;
      this.filterProps.push(query);
    }
   
    this.twadMasterService
      .twadAccountSummaryByFilter({ ...filter },this.filterProps)
      .subscribe(
        (res) => {
         this.fundDetails = res.body;
         this.totalCount = Number(res.headers.get('X-Total-Count'));
         this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  loadFilterValues(event: LazyLoadEvent) {
    this.filterProps = [];
    let filterValue = null
    if (Object.keys(event.filters).length !== 0) {
        if (event.filters['beneficiaryCode'] && event.filters['beneficiaryCode'][0].value) {
          let query = new filterQuery()
          query.key = 'beneficiaryCode';
          query.operation = event.filters['beneficiaryCode'][0].matchMode
          query.value = event.filters['beneficiaryCode'][0].value.toUpperCase().trim();
          this.filterProps.push(query)
        }
        if (event.filters['levelMaster'] && event.filters['levelMaster'][0].value) {
          let query = new filterQuery()
          query.key = 'levelMaster.name';
          query.operation = event.filters['levelMaster'][0].matchMode
          query.value = event.filters['levelMaster'][0].value.toUpperCase().trim();
          this.filterProps.push(query)
        }
        if (event.filters['OfficeId'] && event.filters['OfficeId'][0].value) {
          let query = new filterQuery()
          query.key = 'twadOffice.twadOfficeName';
          query.operation = event.filters['OfficeId'][0].matchMode
          query.value = event.filters['OfficeId'][0].value.toUpperCase().trim();
          this.filterProps.push(query)
        }
    }

    this.first = event.first;
    event.first = (event.first + event.rows) / event.rows;
    this.page = event.first;
    this.itemsPerPage = event.rows;
    this.LoadTangedcoBill(this.page);
    
  }
  checkStatus(){
    let filter = {
      reportName:'Twad Account Summary',
    };
    this.spinner.show();
    this.reportService.reportAsyncStatusCheck({ ...filter }).subscribe(
      (res) => {
        console.log('res',res);
        let b:any = res.body
        this.saveBlobToExcel(b.data,'Twad Account Summary');
        this.spinner.hide();
      },
      (onError) => {
        this.notificationService.alertError(onError.error.message,'');
        this.spinner.hide();
      }
    );
  }
  downloadExport(){
    let filter = {
      reportName:'Twad Account Summary',
      finYearId:this.finYear.id,
    };
    this.spinner.show();
    this.reportService.reportAsyncDownload({ ...filter }).subscribe(
      (res) => {
        this.notificationService.alertSuccess(res.body,'');
        this.spinner.hide();
      },
      (onError) => {
        onError.error = JSON.parse(onError.error);
        this.notificationService.alertError(onError.error.message,'');
        this.spinner.hide();
      }
    );
  }

  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
    }
}
