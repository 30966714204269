<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Allocation to F Component Page
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col">
                        <div class="card p-3">
                            <div class="row voucherfont">
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.voucher.number.name" | translate }}:<br />{{ districtInteralDto.districtInternalTransaction?.voucherNumber }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "date.name" | translate }}:<br />{{ districtInteralDto.districtInternalTransaction?.transactionDate | date:'dd/MM/yyyy'}}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "implementing.agency.name" | translate }}:<br />{{ districtInteralDto.districtInternalTransaction?.iaName }}
                                    </p>
                                </div>
                                <div *ngIf="iaComponentLimitDto" class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Unallocated Limit Amount:<br />{{ iaComponentLimitDto?.realizedLimitAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.department.name" | translate }}:<br />{{ districtInteralDto?.districtInternalTransaction?.department?.name}}
                                    </p>
                                </div>

                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.scheme.name" | translate }}:<br />{{ districtInteralDto?.districtInternalTransaction?.scheme?.name }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.level.type.name" | translate }}:<br />{{ districtInteralDto?.districtInternalTransaction?.levelType?.name }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Fin Year :<br />{{districtInteralDto?.districtInternalTransaction?.finYear?.name}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-md-6 mt-2">
                            <p-table *ngIf="!districtInteralDto?.districtInternalTransaction?.id" [value]="districtInternalBillDesc" [resizableColumns]="true" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;"></th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass">
                                            Grant Level
                                        </th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass">
                                            Transaction Amount
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list let-index="rowIndex">
                                    <tr>
                                        <td style="text-align: center;">
                                            <p-checkbox [(ngModel)]="list.isEnable" (onChange)="onChangeAmount(list)" binary="true"></p-checkbox>
                                        </td>
                                        <td style="text-align: center;">
                                            {{list.name}}
                                        </td>
                                        <td style="text-align: center;">
                                            <p-inputNumber id="field_price" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="list.amount" (ngModelChange)="onChangeAmount(list)"></p-inputNumber>
                                        </td>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td colspan="2" style="text-align: center;">Total</td>
                                        <td style="text-align: center;">{{districtInteralDto.districtInternalTransaction.transactionAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <p-table *ngIf="districtInteralDto?.districtInternalTransaction?.id" [value]="districtInteralDto.districtInternalBillDescs" [resizableColumns]="true" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass">
                                            Grant Level
                                        </th>
                                        <th style="text-align: center;" [ngClass]="fontService.labelClass">
                                            Transaction Amount
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list let-index="rowIndex">
                                    <tr>
                                        <td style="text-align: center;">
                                            {{list.componentMasterName}}
                                        </td>
                                        <td style="text-align: center;">
                                            {{list.amount | currency:'INR':'symbol':'1.2-2':'en-IN' }}
                                        </td>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr>
                                        <td style="text-align: center;">Total</td>
                                        <td style="text-align: center;">{{districtInteralDto.districtInternalTransaction.transactionAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <p-toolbar *ngIf="districtInteralDto.districtInternalTransaction.transactionAmount  && !districtInteralDto?.districtInternalTransaction?.id">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button pButton pRipple class="p-button-success custom-button margin" (click)="save()" styleClass="button">
                                <span class="button-label" [ngClass]="fontService.buttonClass">Submit</span>
                            </button>
                        </div>
                    </p-toolbar>
                    <p-toolbar *ngIf="isApproved && !this.enableView">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button pButton pRipple (click)="approve()" class="p-button-success custom-button">
                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                jhiTranslate="common.approvebutton"></span>
                            </button>
                            <button pButton pRipple (click)="reject()" class="p-button-danger custom-button">
                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                jhiTranslate="common.rejectbutton"></span>
                            </button>
                        </div>
                    </p-toolbar>
                </div>
            </div>
        </div>
    </div>
</div>