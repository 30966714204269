import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { DeductionReportList, DeductionTransaction, DeductionVoucherGenerationDto } from './deduction.model';
export type EntityArrayResponseType = HttpResponse<DeductionReportList[]>;

@Injectable({
  providedIn: 'root'
})
export class DeductionService {

  constructor(private http: HttpClient) { }

  protected getDeductionListUrl = environment.SERVER_API_URL + 'api/getDeductionList';
  protected generateDeductionPaymentUrl = environment.SERVER_API_URL + 'api/voucher/generateDeductionPayment';
  protected deductionCdacRequestUrl = environment.SERVER_API_URL + 'api/deductionCdacRequest';
  protected getAllDeductionPaymentsUrl = environment.SERVER_API_URL + 'api/getAllDeductionPayments';
  protected getDeductionPaymentListUrl = environment.SERVER_API_URL + 'api/getDeductionPaymentList';
  protected getDeductionTransactionByIdUrl = environment.SERVER_API_URL + 'api/getDeductionPaymentWithDeductionDescById';
  protected revertStatutoryTransactionUrl = environment.SERVER_API_URL + 'api/revertStatutoryTransaction';
  protected rejectStatutoryTransactionUrl = environment.SERVER_API_URL +  'api/rejectStatutoryTransaction';
  protected getAllDeductionPaymentsByTypeUrl = environment.SERVER_API_URL + 'api/getAllDeductionPaymentsByType';

  genVocher(vocher: DeductionVoucherGenerationDto) {
    return this.http.post(this.generateDeductionPaymentUrl, vocher, {
      observe: 'response',
    });
  }

  getfile(fileName: string): Observable<any> {
    return this.http.get(`${this.deductionCdacRequestUrl}?fileName=${fileName}`, {
      observe: 'response',
    });
  }

  getDeductionList(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
      return this.http
      .get<DeductionReportList[]>(this.getDeductionListUrl, { params:options, observe: 'response' })
  }

  getDeductionPaymentList(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
      return this.http
      .get<DeductionReportList[]>(this.getDeductionPaymentListUrl, { params:options, observe: 'response' })
  }

  getDeductionPaymentByType(req?: any): Observable<any> {
    const options = createRequestOption(req);
      return this.http
      .get(this.getAllDeductionPaymentsByTypeUrl, { params:options, observe: 'response' })
  }

  filter(req?: any, filter?: any): Observable<HttpResponse<DeductionTransaction[]>> {
    if (filter === null) {
      filter = [];
    }
    const options = createRequestOption(req);
    return this.http.post<any[]>(this.getAllDeductionPaymentsUrl, filter, {
      params: options,
      observe: 'response',
    });
  }

  getVocher(id: number): Observable<HttpResponse<any>> {
    return this.http.get<DeductionVoucherGenerationDto>(`${this.getDeductionTransactionByIdUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  revertTranaction(id: number) {
    return this.http.get<any>(`${this.revertStatutoryTransactionUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  rejectTransaction(id:number) {
    return this.http.get<any>(`${this.rejectStatutoryTransactionUrl}?id=${id}`, {
      observe: 'response',
    });
  }

  

}
