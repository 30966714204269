import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Component } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-other-funds',
  templateUrl: './other-funds.component.html',
  styleUrl: './other-funds.component.scss',
  providers: [CurrencyPipe],
})
export class OtherFundsComponent {
  fundDetails: any;
  voucherSummaryDetails: any;
  lang: string;
  finYear: any;
  finYearList: any;
  defaultFinyear: any;
  villageList: any;
  isZonal: boolean = true;
  levelMasId: number;

  constructor(
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    protected router: Router,
    public fontService: FontService,
    private currencyPipe: CurrencyPipe,
    public sessionStorage: SessionStorageService

  ) {

  }
  ngOnInit() {
    this.finYearList = this.sessionStorage.retrieve('finYear');
   
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
    this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
    if (this.sessionStorage.retrieve('roleCode') === 'ZVM') {
      this.isZonal = false;
      this.dashboardService.getVillagePanchayatByZonal().subscribe(
        (res) => {
          console.log('resZonal', res);
          this.villageList = res;
        },
        () => { }
      );
    }
    else {
      this.onFinYearChange();
    }
    
  }



  getFundReport() {
    this.spinner.show();
    this.dashboardService
      .getFundReport(this.levelMasId ? this.levelMasId : this.sessionStorage.retrieve('levelmasterId'), this.finYear.id)
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.fundDetails = [];
          res.body.forEach((element, i) => {
            switch (element.compName) {

              case 'Surplus Fund':
                element.compName = 'Surplus Fund - ' + 'F3'
                break;
              case 'Mines and Minerals Grant':
                element.compName = 'Mines and Minerals Grant - F1';
                break;
              case 'Pooled Assigned Revenue (PAR)':
                element.compName = 'Pooled Assigned Revenue (PAR) - F2';
                break;
              case 'Special Fund':
                element.compName = 'Special Fund - F4';
                break;
              default:
                element.compName = null;
                break;
            }
            element.balancePercent = Math.floor((res.body[i].balanceAmount / res.body[i].grandSanctioned) * 100);
            element.allocatedAmount = this.currencyPipe.transform(res.body[i].allocatedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.utilizedAmount = this.currencyPipe.transform(res.body[i].utilizedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.balanceAmount = this.currencyPipe.transform(res.body[i].balanceAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.openingBalance = this.currencyPipe.transform(res.body[i].openingBalance, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.grandSanctioned = this.currencyPipe.transform(res.body[i].grandSanctioned, 'INR', 'symbol', '1.2-2', 'en-IN')
            if (element.compName) {
              this.fundDetails.push(element);
            }
          });
          this.sortItems('fund');
          console.log('loghgssssss', this.fundDetails);
        },
        (onError) => {
          this.spinner.hide();
        }
      );
  }

  getvoucherSummaryReport() {
    this.spinner.show();
    this.dashboardService
      .getVoucherSummary(this.levelMasId ? this.levelMasId : this.sessionStorage.retrieve('levelmasterId'), this.finYear.id)
      .subscribe(
        (res: any) => {
          console.log('loggs', res.body)
          this.spinner.hide();
          this.voucherSummaryDetails = [];
          res.body.forEach((element, i) => {
            switch (element.compName) {
              case 'Surplus Fund':
                element.compName = 'Surplus Fund - F3';
                break;
              case 'Mines and Minerals Grant':
                element.compName = 'Mines and Minerals Grant - F1';
                break;
              case 'Pooled Assigned Revenue (PAR)':
                element.compName = 'Pooled Assigned Revenue (PAR) - F2';
                break;
              case 'Special Fund':
                element.compName = 'Special Fund - F4';
                break;

              default:
                element.compName = null;
                break;
            }
            element.balancePercent = Math.floor((res.body[i].balanceAmount / res.body[i].grandSanctioned) * 100);
            element.allocatedAmount = this.currencyPipe.transform(res.body[i].allocatedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.utilizedAmount = this.currencyPipe.transform(res.body[i].utilizedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.balanceAmount = this.currencyPipe.transform(res.body[i].balanceAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.openingBalance = this.currencyPipe.transform(res.body[i].openingBalance, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.grandSanctioned = this.currencyPipe.transform(res.body[i].grandSanctioned, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.approvedVouchersValue = this.currencyPipe.transform(res.body[i].approvedVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.pendingVouchersValue = this.currencyPipe.transform(res.body[i].pendingVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.rejectedVouchersValue = this.currencyPipe.transform(res.body[i].rejectedVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.totalVouchersValue = this.currencyPipe.transform(res.body[i].totalVouchersValue, 'INR', 'symbol', '1.2-2', 'en-IN');
            if (element.compName) {
              this.voucherSummaryDetails.push(element);
            }


          });
          this.sortItems('voucher');
        },
        (onError) => {
          this.spinner.hide();
        }
      );
  }

  onFinYearChange() {
    this.getFundReport();
    this.getvoucherSummaryReport();
  }

  sortItems(type): any[] {
    // Custom order for specific items
    const customOrder = {
      "Mines and Minerals Grant - F1": 1,
      "Pooled Assigned Revenue (PAR) - F2": 2,
      "Surplus Fund - F3": 3,
      "Special Fund - F4": 4,
    };

    // Sorting function based on custom order
    if (type === 'fund') {
      return this.fundDetails.sort((a, b) => {
        const orderA = customOrder[a.compName];
        const orderB = customOrder[b.compName];

        // If both items have custom orders, compare them
        if (orderA !== undefined && orderB !== undefined) {
          return orderA - orderB;
        }
        // If only one item has a custom order, prioritize it
        else if (orderA !== undefined) {
          return -1;
        } else if (orderB !== undefined) {
          return 1;
        }
        // If neither item has a custom order, sort alphabetically
        else {
          return a.compName.localeCompare(b.compName);
        }
      });
    }
    else {
      return this.voucherSummaryDetails.sort((a, b) => {
        const orderA = customOrder[a.compName];
        const orderB = customOrder[b.compName];

        // If both items have custom orders, compare them
        if (orderA !== undefined && orderB !== undefined) {
          return orderA - orderB;
        }
        // If only one item has a custom order, prioritize it
        else if (orderA !== undefined) {
          return -1;
        } else if (orderB !== undefined) {
          return 1;
        }
        // If neither item has a custom order, sort alphabetically
        else {
          return a.compName.localeCompare(b.compName);
        }
      });
    }

  }

  onVillageChange() {
    this.getFundReport();
    this.getvoucherSummaryReport();
    this.isZonal = true;
  }

}
