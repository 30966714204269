import { Agency, AgencyDto } from "../module/agency/agency";
import { deductionDto } from "../module/beneficiary-creation/beneficiary.model";
import { CommercialVendors } from "../module/commercial-vendors/commercial-vendors";
import { LevelMaster } from "../module/level-master/level-master";
import { FinYear } from "../module/limit-translist/limit-translist.module";

export class DeductionReportList {
    voucherNo: string;
    queueNo: string;
    vendorName: string;
    designation: string;
    panNo: string;
    gstNo: string;
    transactionDate: Date;
    billAmount: number;
    deductionPercentage: number;
    grossAmount: number;
    deductionAmount: number;
    status: string;
    isSelect: boolean;
    vendorAccountNumber:string
}

export class DeductionTransaction {
    id: number;
    transactionAmount: number = 0;
    deductionMonth: string;
    deductionType: string;
    deductionAccount: deductionDto;
    implementingAgency: any;
    levelMaster: LevelMaster;
    signatureSecretary: string;
    signatureVicePresident: string;
    signaturePresident: string;
    transactionStatus: string;
    transactionDate: Date;
    voucherNumber: string;
    signCount: number;
    signStatus: boolean;
    rejectReason: string;
    utrNo: string;
    journalNumber: string;
    remarks: string;
    paymentDate: Date;
    activeFlag: string;
    createdBy: number;
    updatedBy: number;
    createdOn: Date;
    updatedOn: Date;
    finYear: FinYear;
    edit: boolean;
    delete: boolean;
    permanentVoucherNumber: string;
    commercialVendor:CommercialVendors;
}

export class DeductionBillDescription {
    id: number;
    deductionTransaction: DeductionTransaction;
    deductionAmount: number;
    deductionPercentage: number;
    voucherNumber: string;
    permanentVoucherNumber: string;
    vendorName: string;
    vendorGstNumber: string;
    vendorPanNumber: string;
    designation: string;
    deductionType: string;
    activeFlag: string;
    createdBy: number;
    updatedBy: number;
    createdOn: Date;
    updatedOn: Date;
    vendorAccountNumber:string;


}

export class DeductionVoucherGenerationDto {
    deductionTransaction: DeductionTransaction;
    deductionBillDescriptionList: DeductionBillDescription[];
}