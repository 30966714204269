import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { environment } from 'src/environments/environment';
import { BeneficiaryService } from '../beneficiary-creation/beneficiary-service.service';
import { beneficiaryList, beneficiaryDto, deductionDto } from '../beneficiary-creation/beneficiary.model';
import { CommercialVendorService } from '../commercial-vendors/commercial-vendors.service';
import { LevelMasterDto } from '../level-master/level-master';
import { LevelMasterService } from '../level-master/level-master.service';
import { ValidationResponse, PersonalVendorsDto } from '../personal-vendors/personal-vendors';
import { error } from 'console';
import { DatePipe, Location } from '@angular/common';


@Component({
  selector: 'app-deduction-accounts',
  templateUrl: './deduction-accounts.component.html',
  styleUrl: './deduction-accounts.component.scss'
})
export class DeductionAccountsComponent {
  @ViewChild('dialog') dialog: Dialog;

  personalVendorsDialog: boolean = false;
  beneficiaryCreationDialog:boolean =false;
  deductionAccountDialog:boolean=false;
  cols: any[];
  searchResult: SearchResult<beneficiaryList> =
    new SearchResult<beneficiaryList>();
  deduction?: deductionDto;
  selectedPersonalVendors: beneficiaryList[] = [];
  submitted?: boolean;
  selectedMapping: string;
  checked: boolean;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any=[];
  validationRes: ValidationResponse;
  readonly: boolean;
  vendorName: string;
  currentEnvironment: string;
  bankBranchDetailsRes: any;
  enableApproved:boolean=false;

  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  public verifyClicked: boolean;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showStaffError: string;
  showMobileUniError: string;
  emailUniError: string;
  aadhaarNoUniError: string;
  panNoUniError: string;
  accountNoUniError: any;
  bankList: any;
  bankBranchList: any;
  bankBranch: any;
  accountInvalidError: any;
  roleCode: any;
  disableEdit: boolean = false;
  edit: boolean;
  villageLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  districtLevelList: LevelMasterDto[];
  districtDto: any;
  blockLevelList: LevelMasterDto[];
  villageLevelDto: any;
  accountNo: string;
  levelType = ['District', 'Block', 'Village'];
  selectedLevelType: string;
  disableDelete: boolean = true;
  isDelete: boolean;
  enableApprove:boolean =false;
  accountAttempt: number;
  currentPath: string;
  isApprove: boolean;
  constructor(
  
    private beneficaryService:BeneficiaryService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private commercialVendorservice: CommercialVendorService,
    protected loginService: SigninService,
    private levelMasterService: LevelMasterService,
    private location: Location
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.currentEnvironment = environment.environment;
    this.currentPath = this.location.path();
    this.loginService.getuserDto().subscribe(
      (res) => {
        this.roleCode = res.body.role.code;
        console.log(this.roleCode)
        if (
          this.roleCode == 'DM' ||
          this.roleCode == 'BM' ||
          this.roleCode == 'SM' ||
          this.roleCode == 'SA' ||
          this.roleCode == 'SUA'
        )
          this.disableEdit = true;
        this.loadPage(1);
        if (this.roleCode === 'SA' && this.currentPath !== '/deduction-accounts-approve') {
          this.disableDelete = false;
          this.onStateLevelChange();
        }
        if(this.roleCode=='DA'){
          this.cols = [
            {
              field: 'blockName',
              header: 'BLOCK',
              isSortable: true,
              isFilterable: false,
              width: 10,
              isSelectcolumn: true,
            },
            {
              field: 'villageName',
              header: 'village Name',
              isSortable: true,
              isFilterable: false,
              width: 10,
              isSelectcolumn: true,
            },
            {
              field: 'villageLgd',
              header: 'Lgd Code',
              isSortable: true,
              isFilterable: false,
              width: 10,
              isSelectcolumn: true,
            },
            {
              field: 'name',
              header: 'Name',
              jhiTranslate: 'tableHead.name',
              isSortable: true,
              isFilterable: false,
              width: 10,
              isSelectcolumn: true,
            },
            {
              field: 'ifscCode',
              header: 'Ifsc Code',
              jhiTranslate: 'tableHead.ifscCode',
              isSortable: false,
              isFilterable: false,
              width: 13,
              isSelectcolumn: true,
            },
            {
              field: 'accountNumber',
              header: 'Account No',
              jhiTranslate: 'tableHead.account.number',
              isSortable: false,
              isFilterable: false,
              width: 13,
              isSelectcolumn: true,
            },
       
            {
              field: 'createdOn',
              header: 'Created On',
              jhiTranslate: 'tableHead.createdon',
              isSortable: true,
              isFilterable: false,
              width: 13,
              isSelectcolumn: true,
              type: 'date',
            },
            {
              field: 'activeFlag',
              header: 'Account Status',
              jhiTranslate: 'tableHead.accountStatus',
              isSortable: true,
              isFilterable: false,
              width: 13,
              isSelectcolumn: true,
            
            },
          ];
          this.enableApprove=true;
        }else{
          this.cols = [
            {
              field: 'name',
              header: 'Name',
              jhiTranslate: 'tableHead.name',
              isSortable: true,
              isFilterable: false,
              width: 10,
              isSelectcolumn: true,
            },
            {
              field: 'ifscCode',
              header: 'Ifsc Code',
              jhiTranslate: 'tableHead.ifscCode',
              isSortable: false,
              isFilterable: false,
              width: 13,
              isSelectcolumn: true,
            },
            {
              field: 'accountNumber',
              header: 'Account No',
              jhiTranslate: 'tableHead.account.number',
              isSortable: false,
              isFilterable: false,
              width: 13,
              isSelectcolumn: true,
            },
       
            {
              field: 'createdOn',
              header: 'Created On',
              jhiTranslate: 'tableHead.createdon',
              isSortable: true,
              isFilterable: false,
              width: 13,
              isSelectcolumn: true,
              type: 'date',
            },
            {
              field: 'activeFlag',
              header: 'Account Status',
              jhiTranslate: 'tableHead.accountStatus',
              isSortable: true,
              isFilterable: false,
              width: 13,
              isSelectcolumn: true,
            
            },
          ];
          this.enableApprove=false;
        }
      },
      (onError) => {}
    );

    this.commercialVendorservice.getBankList().subscribe(
      (res: HttpResponse<[]>) => {
        this.bankList = res.body;
      },
      () => {}
    );
   
    this.updateDialogFont();
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  openNew() {
    this.deduction = new deductionDto();
    this.submitted = false;
    this.deductionAccountDialog = true;
    this.readonly = false;
    this.accountAttempt = 0;
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
      ];
      if (this.deduction.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.deduction.id,
        });
      }
      this.beneficaryService
        .validFilter({ ...filterQuery }, filter)
        .subscribe(
          (res: HttpResponse<beneficiaryList[]>) => {
            resolve(res.body);
          },
          () => {
            this.onError();
            resolve(null);
          }
        );
    });
  }



  async checkName() {
    const filterData = await this.getfilterdata(
      'name',
      this.deduction.name
    );
    if (filterData.length !== 0) {
      this.showNameError = 'Name Already Exists';
    } else {
      this.showNameError = null;
    }
  }

  async checkstaffId() {
    // const filterData = await this.getfilterdata(
    //   'staffId',
    //   this.beneficiary.staffId
    // );
    // if (filterData.length !== 0) {
    //   this.showStaffError = 'Staff Already Exists';
    // } else {
    //   this.showStaffError = null;
    // }
  }

  async checkmobile() {
    // const filterData = await this.getfilterdata(
    //   'mobile',
    //   this.beneficiary.mobile
    // );
    // if (filterData.length !== 0) {
    //   this.showMobileUniError = 'Mobile No Already Exists';
    // } else {
    //   this.showMobileUniError = null;
    // }
  }

  async checkemail() {
    // const filterData = await this.getfilterdata(
    //   'email',
    //   this.beneficiary.email
    // );
    // if (filterData.length !== 0) {
    //   this.emailUniError = 'Email Already Exists';
    // } else {
    //   this.emailUniError = null;
    // }
  }

  async checkaadharNo() {
    // const filterData = await this.getfilterdata(
    //   'aadhaarNo',
    //   this.beneficiary.aadhaarNo
    // );
    // if (filterData.length !== 0) {
    //   this.aadhaarNoUniError = 'Aadhaar No Already Exists';
    // } else {
    //   this.aadhaarNoUniError = null;
    // }
  }

  async checkPanNo() {
    // const filterData = await this.getfilterdata(
    //   'panNo',
    //   this.beneficiary.panNo
    // );
    // if (filterData.length !== 0) {
    //   this.panNoUniError = 'Pan No Already Exists';
    // } else {
    //   this.panNoUniError = null;
    // }
  }

  async checkAccountNo() {
    const filterData = await this.getfilterdata(
      'accountNumber',
      this.deduction.accountNumber
    );
    if (filterData.length !== 0) {
      this.accountNoUniError = 'account No Already Exists';
    } else {
      this.accountNoUniError = null;
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    console.log(page)
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage
    };
    this.filterProps = this.filterProps ? this.filterProps : [];
    // this.filterProps.push({
    //   key: 'activeFlag',
    //   operation: 'equals',
    //   value: 'YES',
    // });
    var i;
    // if (this.filterProps) {
    //   for (i = this.filterProps.length - 1; i >= 0; i -= 1) {
    //     if (this.filterProps[i].key === 'name') {
    //       this.filterProps.splice(i, 1);
    //     }
    //   }
    // }
    // if (this.vendorName) {
    //   this.filterProps.push({
    //     key: 'name',
    //     operation: 'contains',
    //     value: this.vendorName.toUpperCase(),
    //   });
    // }
    // if (this.accountNo) {
    //   this.filterProps.push({
    //     key: 'accountNumber',
    //     operation: 'contains',
    //     value: this.accountNo,
    //   });
    // }
    // if (this.selectedLevelType === 'District' && this.districtDto) {
    //   this.filterProps.push({
    //     key: 'levelMasterId',
    //     operation: 'contains',
    //     value: this.districtDto.id,
    //   });
    // }
    // if (this.selectedLevelType === 'Block' && this.BlockLevelDto) {
    //   this.filterProps.push({
    //     key: 'levelMasterId',
    //     operation: 'contains',
    //     value: this.BlockLevelDto.id,
    //   });
    // }
    // if (this.selectedLevelType === 'Village' && this.villageLevelDto) {
    //   this.filterProps.push({
    //     key: 'levelMasterId',
    //     operation: 'contains',
    //     value: this.villageLevelDto.id,
    //   });
    // }
    console.log('resss', this.roleCode);
    if(this.currentPath === '/deduction-accounts-approve') {
      this.disableDelete = true;
      this.beneficaryService
        .deductionWaitingForApproval()
        .subscribe(
          (res) => {
            this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
          },
          () => {
            this.onError();
          }
        );
    }
    else {
      if (this.roleCode === 'SA') {
        this.beneficaryService
          .validFilterDeduction({ ...filterQuery }, this.filterProps)
          .subscribe(
            (res: HttpResponse<beneficiaryList[]>) => {
              this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
            },
            () => {
              this.onError();
            }
          );
      } else {
        this.beneficaryService
          .validFilterDeduction({ ...filterQuery }, this.filterProps)
          .subscribe(
            (res: HttpResponse<beneficiaryList[]>) => {
              this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
            },
            () => {
              this.onError();
            }
          );
      }
    }
   
  }
  getBankBranchList(event?) {
    this.bankBranch = null;
    let filter = [];
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.deduction.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };
    if (event) {
      filter.push({
        key: 'bankBranch',
        operation: 'startswith',
        value: event.term.toUpperCase(),
      });
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    } else {
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    }
  }
  approveDeduction(event){
    console.log(event)
    let data={
      id:event.id,
      status:'Active'
    }
    this.beneficaryService.deductionApproval(data).subscribe(res=>{
      console.log(res)
      this.deductionAccountDialog = false;
      this.loadPage(0)
    })
   

  }
  rejectDeduction(event){
    console.log(event)
    let data={
      id:event.id,
      status:'Rejected'
    }
    this.beneficaryService.deductionApproval(data).subscribe(res=>{
      console.log(res)
      this.deductionAccountDialog = false;
      this.loadPage(0)
    })
  }

  getBankBranchdetais() {
    let filter = [];
    filter.push({
      key: 'bankIfsc',
      operation: 'equals',
      value: this.deduction.ifscCode.toUpperCase(),
    });
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.deduction.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };

    this.commercialVendorservice
      .getBankBranchList(filter, filterQuery)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.bankBranch = res.body[0];
        },
        () => {}
      );
  }

  getBankIfsc() {
    this.deduction.bankBranch = this.bankBranch.bankBranch;
    this.commercialVendorservice
      .getBankIfscCode(
        this.deduction.bankName,
        this.deduction.bankBranch
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.deduction.ifscCode = res.body[0];
        },
        () => {}
      );
  }

  accountValidation() {
    if (
      !this.deduction.ifscCode ||
      !this.deduction.accountNumber ||
      !this.deduction.bankName ||
      !this.deduction.bankBranch
    ) {
      this.accountInvalidError =
        'Please input valid account number and bank details';
      return;
    }
    this.verifyClicked = true;
    this.accountInvalidError = null;
    this.spinner.show();
    this.commercialVendorservice
      .getaccountValidation(
        this.deduction.accountNumber,
        this.deduction.ifscCode
      )
      .subscribe(
        (res: any) => {
          // this.validationRes=JSON.parse(res.body);
          this.validationRes = res.body;
          if (this.validationRes.bankTxnStatus === true) {
            this.deduction.bankAccountName = this.validationRes.accountName;
            this.accountInvalidError = null;
            this.notificationService.alertSuccess(
              'Account Verfied Sucessfully',
              ''
            );
            this.readonly = true;
          } else {
            this.accountAttempt += 1;
            if(this.accountAttempt === 2) {
              this.readonly =true;
              this.verifyClicked = true;
              this.deduction.isAccountVerified = false;
              this.accountInvalidError = null;
            }
            else {
              this.accountInvalidError =
              'Account Verification Failed due to ' +
              this.validationRes.bankResponse;
            this.notificationService.alertError(
              'Account Verification Failed due to ' +
                this.validationRes.bankResponse,
              ''
            );
            this.readonly = false;
            this.verifyClicked = false;
            }
           
          }
          this.spinner.hide();
        },
        (error) => {
          if (error.status === 400) {
            this.accountInvalidError =
              'Account Verification Failed. ' + error.error.message;
            this.notificationService.alertError(
              'Account Verification Failed. ' + error.error.message,
              ''
            );
          } else {
            this.accountInvalidError =
              'Please input valid account number and branch details';
            this.notificationService.alertError(
              'Account Verification Failed',
              ''
            );
          }
          this.accountAttempt += 1;
          if(this.accountAttempt === 2) {
            this.readonly =true;
            this.verifyClicked = true;
            this.deduction.isAccountVerified = false;
            this.accountInvalidError = null;
          }
          else {
            this.readonly = false;
            this.verifyClicked = false;
          }
         
          this.spinner.hide();
        }
      );
  }

  protected onSuccess(
    data: beneficiaryList[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    console.log(event)
    this.filterProps = [];
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    console.log(this.itemsPerPage)
    this.loadPage(event.page);
  }

  hideDialog() {
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.tanError = false;
    this.tinError = false;
    this.gstError = false;
    this.ifscError = false;
    this.deduction = new deductionDto();
    this.ngSelectInvalid = false;
    this.accountInvalidError = false;
    this.verifyClicked = true;
    this.deductionAccountDialog = false;
    this.submitted = false;
    this.bankBranch = null;
    this.edit = false;
    this.isDelete = false;
    this.isApprove = false;
  }

  validateMobile() {
    // this.showMobileError = !this.validationService.isMobileValid(
    //   this.beneficiary.mobile
    // );
    // !this.beneficiary.mobile ? (this.showMobileError = false) : true;

    // return !this.showMobileError && !!this.beneficiary.mobile;
  }

  validateEmail() {
    // this.emailError = !this.validationService.isEmailValid(
    //   this.beneficiary.email
    // );
    // !this.beneficiary.email ? (this.emailError = false) : true;

    // return !this.emailError && !!this.beneficiary.email;
  }

  validatePan() {
    // this.panError = !this.validationService.isPanValid(
    //   this.beneficiary.panNo
    // );
    // !this.beneficiary.panNo ? (this.panError = false) : true;

    // return !this.panError && !!this.beneficiary.panNo;
  }

  validateTan() {
    // this.tanError = !this.validationService.isPanValid(
    //   this.beneficiary.tanNo
    // );
    // !this.beneficiary.tanNo ? (this.tanError = false) : true;

    // return !this.tanError && !!this.beneficiary.tanNo;
  }

  validateTin() {
    // this.tinError = !this.validationService.isPanValid(
    //   this.beneficiary.tinNo
    // );
    // !this.beneficiary.tinNo ? (this.tinError = false) : true;

    // return !this.tinError && !!this.beneficiary.tinNo;
  }

  validatePin() {
    // this.pinError = !this.validationService.isPincodeValid(
    //   this.beneficiary.pinCode
    // );
    // !this.beneficiary.pinCode ? (this.pinError = false) : true;

    // return !this.pinError && !!this.beneficiary.pinCode;
  }

  validateCPin() {
    // this.cPinError = !this.validationService.isPincodeValid(
    //   this.beneficiary.companyPinCode
    // );
    // !this.beneficiary.companyPinCode ? (this.cPinError = false) : true;

    // return !this.cPinError && !!this.beneficiary.companyPinCode;
  }

  validateAadhaar() {
    // this.aadhaarError = !this.validationService.isAadhaarValid(
    //   this.beneficiary.aadhaarNo
    // );
    // !this.beneficiary.aadhaarNo ? (this.aadhaarError = false) : true;

    // return !this.aadhaarError && !!this.beneficiary.aadhaarNo;
  }

  validateGst() {
    // this.gstError = !this.validationService.isPanValid(
    //   this.beneficiary.gstNo
    // );
    // !this.beneficiary.gstNo ? (this.gstError = false) : true;

    // return !this.gstError && !!this.beneficiary.gstNo;
  }

  validateIfsc() {
    // this.ifscError = !this.validationService.isIfscValid(
    //   this.beneficiary.ifscCode
    // );
    // !this.beneficiary.ifscCode ? (this.ifscError = false) : true;

    // return !this.ifscError && !!this.beneficiary.ifscCode;
  }
  accountDetailsChanged() {
    this.verifyClicked = false;
    if (
      this.currentEnvironment == 'uat' ||
      this.currentEnvironment === 'production'
    ) {
      if (!this.ifscError) {
        this.spinner.show();
        this.commercialVendorservice
          .bankBranchDetails(this.deduction.ifscCode)
          .subscribe(
            (res) => {
              this.spinner.hide();
              this.bankBranchDetailsRes = res.body;
              console.log(this.bankBranchDetailsRes, 'bankbranchdetails');
              this.deduction.bankBranch =
                this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Branch_Name;
              this.deduction.bankName =
                this.bankBranchDetailsRes.FetchBankDetails_Response.Body.Payload.Bank_Name;
            },
            () => {
              this.spinner.hide();
              this.notificationService.alertError('Error Occured', '');
            }
          );
      }
    }
  }

  accountChanged() {
    this.verifyClicked = false;
  }

  savePersonalVendor() {
    const isFormValid =
     // this.validateMobile() &&
     // this.validatePin() &&
     // this.validateAadhaar() &&
    //  this.validateIfsc() &&
      this.deduction.name &&
      this.deduction.accountNumber &&
      this.deduction.ifscCode
     !this.accountInvalidError &&
     this.verifyClicked;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    this.beneficaryService.saveDeduction(this.deduction,this.deduction.isAccountVerified).subscribe(
      (res: HttpResponse<any>) => {
        this.deductionAccountDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      (err) => {
        console.log(err)
        this.onError();
        if (err.error.status === 400) {
          this.notificationService.alertError('Error! ' + err.error.detail, '');
        } else if(err.error.status === 500) {
          this.notificationService.alertError('Error! ' + err.error.detail, '');
        }
      }
    );
  }

  onSelectRow($event) {
    console.log($event)
    this.beneficaryService.getDeductionAccount($event.value.id).subscribe(
      (res: HttpResponse<any>) => {
        this.readonly = true;
        this.edit = true;
        this.deduction = res.body;
        this.deductionAccountDialog = true;
        if (this.deduction.bankName) {
          this.getBankBranchList();
          this.getBankBranchdetais();
        }
      },
      () => {
        this.onError();
      }
    );
    if ($event.type === 'view') {
      this.readonly = true;
      this.enableApproved=false;
      this.isDelete=false;
     // this.edit = true;
    }
    else if($event.type === 'approve' && this.currentPath === '/deduction-accounts-approve') {
      this.isApprove = true;
      this.readonly = true;
    } 
    else if($event.type === 'approve') {
      this.isDelete = true;
      this.readonly = true;
      this.enableApproved=true;
    }

  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onDistrictLevelChange($event?) {
    this.villageLevelDto = null;
    this.BlockLevelDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onBlockLevelChange($event?) {
    this.villageLevelDto = null;
    this.villageLevelList = [];
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    }
  }

  deleteVendor() {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to delete this ' +
        this.deduction.name,
      icon: 'pi pi-unlock',
      accept: () => {
        this.spinner.show();
        this.beneficaryService.delete(this.deduction.id).subscribe(
          (res: HttpResponse<any>) => {
            this.filterProps = [];
            this.loadPage(this.page);
            this.hideDialog();
            this.spinner.hide();
            this.notificationService.alertSuccess('Vendor Deleted Sucessfully','');
          },
          (err) => {
            this.notificationService.alertError(err.error.title, '');
            this.spinner.hide();
          }
        );
      },
      reject: () => {},
    });
  }

  approveVendor(status) {
    let message = ''
    if(status) {
      message = 'Approve';
    }
    else {
      message = 'Reject';
    }
    this.confirmationService.confirm({
      message:
        'Are you sure you want to ' + message +  ' this ' +
        this.deduction.name,
      icon: 'pi pi-unlock',
      accept: () => {
        this.spinner.show();
        this.beneficaryService.deductionUpdateStatus(this.deduction.id,status).subscribe(
          (res: HttpResponse<any>) => {
            this.filterProps = [];
            this.loadPage(this.page);
            this.hideDialog();
            this.spinner.hide();
            this.notificationService.alertSuccess(res.body,'');
          },
          (err) => {
            this.notificationService.alertError(err.error.title, '');
            this.spinner.hide();
          }
        );
      },
      reject: () => {},
    });
  }
}
