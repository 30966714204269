<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Tangedco Transaction Report</h5>
                </ng-template>
            </p-toolbar>
        </div>
        <div class="input-row">
            <p-dropdown  placeholder="Select Fin Year" [options]="finYearList" [(ngModel)]="finYear" 
            optionLabel="name" showClear="false" (onChange)="onchangeFinYear()"></p-dropdown>
            <p-calendar id="fromDate" placeholder="{{'dashBoard.dropdown.fromDate'|translate}}" dateFormat="dd/mm/yy" [(ngModel)]="fromDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate" [minDate]="minDate"></p-calendar>
            <p-calendar id="toDate" placeholder="{{'dashBoard.dropdown.toDate'|translate}}" dateFormat="dd/mm/yy" [(ngModel)]="toDate" [showIcon]="true" styleClass="datepicker" [maxDate]="maxDate" [minDate]="minDate"></p-calendar>
            <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
            <button pButton pRipple label="Export to Excel" (click)="downloadExport()" class="p-button-success p-button-sm export-button"></button>
            <button pButton pRipple label="Check Status" (click)="checkStatus()" class="p-button-success p-button-sm"></button>
        </div>
    </div>

    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [enableAction]="false"></custom-filter-table>
        </div>
    </div>

</div>
