import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { SearchResult } from "src/app/shared/models/search-result";
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { Router } from '@angular/router';
import { BillingDetails } from './twad.model';
import { TwadMasterService } from './twad.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { filterQuery } from 'src/app/shared/common.model';

@Component({
  selector: 'app-twad-master-list',
  templateUrl: './twad-master-list.component.html',
  styleUrls: ['./twad-master-list.component.scss']
})
export class TwadMasterListComponent implements OnInit {

  @ViewChild('dialog') dialog: Dialog;
  levelMasterDialog: boolean = false;
  cols: any[];
  filterProps: any;
  searchResult: SearchResult<BillingDetails> = new SearchResult<BillingDetails>();
  submitted?: boolean;
  selectedMapping: string;
  itemsPerPage: number = 10;
  page: number;
  tamilText: any;

  //required fields property
  ngSelectInvalid: boolean = false;
  codeError: string;
  roleCode: any;

  constructor(
 
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private loginService: SigninService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    protected twadMasterService:TwadMasterService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
   
    this.cols = [
      {
        field: 'officeId',
        header: 'office Id',
        isFilterable: true,
        width: 5,
        isSelectcolumn: true,
      },
      {
        field: 'officeName',
        header: 'Office Name',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      {
        field: 'beneficiaryCode',
        header: 'beneficiary Code',
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'levelMaster',
        header: 'Village Name',
        isFilterable: false,
        width: 25,
        isSelectcolumn: true,
        type:'dropDown'
      },
      {
        field: 'lgdCode',
        header: 'Lgd Code',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      
      {
        field: 'billSno',
        header: 'Bill Sno',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
      },
      {
        field: 'billDate',
        header: 'Bill Date',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type:"date"
      },
      {
        field: 'waterChargesDue',
        header: 'Water Charges Due',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type:"amount"
      },
      {
        field: 'maintenaceChargesDue',
        header: 'maintenance Charges Due',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type:"amount"
      },
      
      {
        field: 'interestDue',
        header: 'Interest Due',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type:"amount"
      },
      {
        field: 'totalDue',
        header: 'Total Due',
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type:"amount"
      },

      
    ];
    
  }


  ngAfterViewInit() {
    this.updateDialogFont();
    
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }


  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filter = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    let query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterProps.push(query);
    if(this.roleCode.role.code!== 'SA' && this.roleCode.role.code !== 'TA' && this.roleCode.role.code !== 'TM' && this.roleCode.role.code !== 'SM') {
      query = new filterQuery();
      query.key = 'levelMaster.id';
      query.operation = 'equals';
      query.value = this.roleCode.levelMasterId;
      this.filterProps.push(query);
    }
    this.twadMasterService
      .filter({ ...filter }, this.filterProps)
      .subscribe(
        (res: HttpResponse<BillingDetails[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: BillingDetails[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  

 

  async onLazyLoad(event) {
    this.filterProps = [];
    if(!this.roleCode) {
      await this.getRole();
    }
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode);
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  





  
  

 


}
