import { FinYear } from "src/app/module/limit-translist/limit-translist.module";
import { DepartmentDto, SchemeDto } from "../payment-voucher/payment-voucher.model";
import { LevelType } from "src/app/module/level-type/level-type";

export class DistrictInternalTransaction {
    id?: number;
    voucherNumber?: string;
    transactionDate?: Date;
    levelMasterId?: number;
    levelMasterName?: string;
    iaId?: number;
    iaName?: string;
    componentMasterId?: number;
    componentMasterName?: string;
    department?: DepartmentDto; 
    scheme?: SchemeDto;         
    finYear?: FinYear;       
    levelType?: LevelType;   
    countId?: number;
    activeFlag?: string;
    transactionAmount?: number;
    isLimitUpdated?: boolean;
    rejectionReason?: string;
    status?: string;
    createdOn?: Date;
    updatedOn?: Date;
    createdBy?: number;
    updatedBy?: number;
  edit: boolean;
}

export class DistrictInternalBillDesc {
    id?: number;
    districtInternalTransactionId?: number;
    componentMasterId?: number;
    componentMasterName?: string;
    amount?: number;
    activeFlag?: string;
    createdOn?: Date;
    updatedOn?: Date;
    createdBy?: number;
    updatedBy?: number;
}

export class DistrictInternalTransactionDto {
    districtInternalTransaction?: DistrictInternalTransaction;
    districtInternalBillDescs?: DistrictInternalBillDesc[];
}