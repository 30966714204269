import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageService } from 'ngx-webstorage';
import { FontService } from 'src/app/shared/font-service/font.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-district-dashboard',
  templateUrl: './district-dashboard.component.html',
  styleUrl: './district-dashboard.component.scss',
  providers: [CurrencyPipe],
})
export class DistrictDashboardComponent {
  finYearList: any;
  finYear: any;
  defaultFinyear: any;
  panchayatSanctionAmount: any;
  panchayatUtilizedAmount: any;
  panchayatBalanceAmount: any;
  fundData: { name: string; amount: any; }[];
  SValue: any;
  BValue: any;
  UValue: any;
  fundDetails: any[];

  constructor(
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private dashboardService: DashboardService,
    public fontService: FontService,
    private currencyPipe: CurrencyPipe,

  ) {

  }

  customizeChartTooltip(arg: any) {
    return {
      text: `${arg.argumentText}- ${arg.percentText}`,
    };
  }
  ngOnInit() {
    this.finYearList = this.sessionStorageService.retrieve('finYear');
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
    this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
    this.getPanchayatFundReport();
    this.getFundReport();
  }

  getPanchayatFundReport() {
    this.dashboardService.districtOverallFund(this.sessionStorageService.retrieve('levelmasterId'), this.finYear.id)
      .subscribe((res: any) => {
        this.panchayatSanctionAmount = res.body.allocatedAmount ? res.body.allocatedAmount : 0
        this.panchayatUtilizedAmount = res.body.utilizedAmount ? res.body.utilizedAmount : 0
        this.panchayatBalanceAmount = res.body.balanceAmount ? res.body.balanceAmount : 0
        this.fundData = [
          { "name": "BalanceAmount", "amount": this.panchayatBalanceAmount },
          { "name": "UtilizedAmount", "amount": this.panchayatUtilizedAmount },
        ]
        this.SValue = this.formatCash(res.body.allocatedAmount);
        this.BValue = this.formatCash(res.body.balanceAmount);
        this.UValue = this.formatCash(res.body.utilizedAmount);
      },
        () => { });
  }

  getFundReport() {
    this.spinner.show();
    this.dashboardService
      .getDistrictFundReport(this.sessionStorageService.retrieve('levelmasterId'), this.finYear.id)
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          this.fundDetails = [];
          res.body.forEach((element, i) => {
            switch (element.compName) {
              case 'District Minor Grant and Special Fund':
                element.compName = 'District Minor Grant and Special Funds - F';
                break;
              case 'Mines and Minerals Grant':
                element.compName = 'Mines and Minerals Grant - F1';
                break;
              case 'Pooled Assigned Revenue (PAR)':
                element.compName = 'Pooled Assigned Revenue (PAR) - F2';
                break;
              case 'Special Fund':
                element.compName = 'Special Fund - F4';
                break;

              default:
                element.compName = null;
                break;
            }
            element.balancePercent = Math.floor((res.body[i].balanceAmount / res.body[i].grandSanctioned) * 100);
            element.allocatedAmount = this.currencyPipe.transform(res.body[i].allocatedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.utilizedAmount = this.currencyPipe.transform(res.body[i].utilizedAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.balanceAmount = this.currencyPipe.transform(res.body[i].balanceAmount, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.openingBalance = this.currencyPipe.transform(res.body[i].openingBalance, 'INR', 'symbol', '1.2-2', 'en-IN');
            element.grandSanctioned = this.currencyPipe.transform(res.body[i].grandSanctioned, 'INR', 'symbol', '1.2-2', 'en-IN');
            if(element.compName) {
              this.fundDetails.push(element);
            }
          });
          this.sortItems();
        },
        (onError) => {
          this.spinner.hide();
        }
      );
  }

  formatCash = n => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(2) + "L";
    if (n >= 1e7) return +(n / 1e7).toFixed(2) + "C";
  };

  onFinYearChange() {
    this.getPanchayatFundReport();
    this.getFundReport();
  }

  sortItems(): any[] {
    // Custom order for specific items
    const customOrder = {
      "District Minor Grant and Special Funds - F": 1,
      "Mines and Minerals Grant - F1": 2,
      "Pooled Assigned Revenue (PAR) - F2": 3,
      "Special Fund - F4": 4,
    };

    // Sorting function based on custom order

    return this.fundDetails.sort((a, b) => {
      const orderA = customOrder[a.compName];
      const orderB = customOrder[b.compName];

      // If both items have custom orders, compare them
      if (orderA !== undefined && orderB !== undefined) {
        return orderA - orderB;
      }
      // If only one item has a custom order, prioritize it
      else if (orderA !== undefined) {
        return -1;
      } else if (orderB !== undefined) {
        return 1;
      }
      // If neither item has a custom order, sort alphabetically
      else {
        return a.compName.localeCompare(b.compName);
      }
    });
  }






}
