import { Component, OnInit } from '@angular/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import * as XLSX from 'xlsx';
import { ReportService } from '../report.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { DatePipe } from '@angular/common';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComponentType } from 'src/app/module/component-type/component-type';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AmountDetailsReport } from '../report.model';
import { saveAs } from 'file-saver'

@Component({
  selector: 'app-transcaton-report',
  templateUrl: './transcaton-report.component.html',
  styleUrls: ['./transcaton-report.component.scss']
})
export class TranscatonReportComponent implements OnInit {
  roleCode: any;
  activeIndex=0;
  fromDate: string;
  transcationreport: AmountDetailsReport;
  compFundDto:AmountDetailsReport;
  compFundBlockDto:AmountDetailsReport;
  compFundVillageDto:AmountDetailsReport;
  compTypeList:ComponentType[];
  toDate: string;
  endDate: string;
  startDate: string;
  dateError: boolean;
  levelMasterId: any;
  cols: any;
  selectedcompType:any;
  maxDate = new Date();
  isDMRole: boolean;
  isBMRole: boolean;
  
    

  constructor( public fontService: FontService,
              public reportService:ReportService,
              private loginService:SigninService,
              private datePipe: DatePipe,
              private componentTypeService:ComponentTypeService,
              private spinner: NgxSpinnerService,

    ) { }

  ngOnInit(): void {
    // this.spinner.show();
    this.loginService.getuserDto().subscribe(
      (res) => {
       this.roleCode = res.body.role.code;
       this.levelMasterId=res.body.levelMasterId;
      //  this.loadDistrict();
      },
      (error)=>{});

      this.componentTypeService.filter().subscribe(
        (res: HttpResponse<ComponentType[]>) => {
          this.compTypeList=res.body.filter(item => item.name.startsWith('Village'));
          this.selectedcompType=this.compTypeList[0]
        },
        ()=>{});
      
    this.cols = [
      {
        field: 'voucherNo',
        header: 'Voucher No',
        jhiTranslate: 'tableHead.voucher.no',
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'transactionAmount',
        header: 'Total Transaction Amount',
        jhiTranslate: 'tableHead.transaction.amount',
        isSelectcolumn: true,
        type:'amount'
      },
      {
        field: 'subComponentName',
        header: 'Sub Component Name',
        jhiTranslate: 'tableHead.sub.compName',
        isSortable: true,
        isSelectcolumn: true,
      }
    ];
  }
 
  loadDistrict(){
    this.spinner.show();
    if(!this.fromDate && !this.toDate)
    {
    this.startDate=this.datePipe.transform(new Date("04-01-2023"), 'yyyy-MM-dd')+' 00:00:00.000';
    this.endDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
    }
    const payload: any = {
  
      componentType:this.selectedcompType?.id,
      fromDate: this.startDate,
      toDate: this.endDate,
    };     
       
     if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='DM' || this.roleCode=='SUA' ||  this.roleCode=='DAD' || this.roleCode == 'ZVM' || this.roleCode === 'DA')
     {
      this.reportService.getVendorTransactionReport(payload).subscribe(

        (res:any) => {
          this.spinner.hide();
          this.saveBlobToExcel(res.body,'TransactionReport_Villagewise')
        },
        (err)=>{
          this.spinner.hide();
        })
      }
      else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2' ||  this.roleCode=='DAD'){
        // this.loadBlock(this.levelMasterId);
        this.isDMRole=true;
      } else if(this.roleCode=='BM' ||this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2' || this.roleCode== 'BAD'){
        // this.loadVillage(this.levelMasterId);
        this.isBMRole=true;
      }
  }
  saveBlobToExcel(data,filename){
    const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
      }
  // loadBlock(id){
  //   this.spinner.show();
  //   if(!this.fromDate && !this.toDate)
  //   {
  //     this.startDate=this.datePipe.transform(new Date("04-01-2023"), 'yyyy-MM-dd')+' 00:00:00.000';
  //     this.endDate=this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
  //   }
  //   const payload: any = {
  //     componentType:this.selectedcompType?.id,
  //     fromDate: this.startDate,
  //     toDate: this.endDate,
  //   };
  //   this.reportService.getVendorTransactionReport(payload).subscribe(
  //     (res: HttpResponse<AmountDetailsReport>) => {
  //       this.compFundBlockDto=res.body;
  //       if(this.roleCode=='SA' || this.roleCode=='SM'){
  //         this.activeIndex=1;
  //       } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2'){
  //         this.activeIndex=0;
  //       }
  //       this.spinner.hide();
  //     },
  //     (err)=>{
  //       this.spinner.hide();
  //     }
  //   )
  // }
  // loadVillage(id){
  //   this.spinner.show();
  //   const payload: any = {
  //     componentType:this.selectedcompType?.id,
  //     fromDate: this.startDate,
  //     toDate: this.endDate,
  //   };
  //   this.reportService.getVendorTransactionReport(payload).subscribe(
  //     (res: HttpResponse<AmountDetailsReport>) => {
  //     this.compFundVillageDto=res.body;

  //       if(this.roleCode=='SA' || this.roleCode=='SM'){
  //         this.activeIndex=2;
  //       } else if(this.roleCode=='DM' || this.roleCode=='DMAKER' || this.roleCode=='DC1'||this.roleCode=='DC2'){
  //         this.activeIndex=1;
  //       } else if(this.roleCode=='BM' || this.roleCode=='BMAKER' || this.roleCode=='BC1'||this.roleCode=='BC2'){
  //         this.activeIndex=0;
  //       } 
  //       this.spinner.hide();
  //     },
  //     (err)=>{
  //       this.spinner.hide();
  //     }
  //   )
  // }
  
  onSearch(){
    this.activeIndex=0;
      if(this.fromDate && this.toDate)
      {
    this.startDate=this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd')+' 00:00:00.000';
    this.endDate=this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd')+' 23:59:59.999';
      }
    if (this.startDate > this.endDate) {
      this.dateError = true;
      return
    }
      this.dateError = false;
     if(this.roleCode=='SA' || this.roleCode=='SM' || this.roleCode=='DM' || this.roleCode=='SUA' || this.roleCode=='DAD' || this.roleCode==='ZVM' || this.roleCode=='DA'){
      this.compFundBlockDto=null;
      this.compFundVillageDto=null;
      this.loadDistrict();
     } 
  }

  
}
