<div class="container-fluid">
    <div class="row">
        <p-toolbar styleClass="toolbar">
            <ng-template pTemplate="left">
                <h5 [ngClass]="fontService.headingClass">{{headingText}}</h5>

            </ng-template>
        </p-toolbar>
    </div>
    <div class="row">
        <div *ngIf="this.roleCode === 'ZVM'" class="col-lg-2 col-md-4 col-sm-3">
            <label class="form-control-label req" for="Village" [ngClass]="fontService.labelClass">Village</label>
            <ng-select id="gender" bindLabel="levelMasterName" bindValue="levelMasterId" [items]="villageList" appearance="outline" [(ngModel)]="levelMasId" [closeOnSelect]="true" [clearable]="false" (ngModelChange)="onVillageChange()" class="form-control">
            </ng-select>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-3">
            <label class="form-control-label req" for="finYear" [ngClass]="fontService.labelClass">Fin Year</label>
            <ng-select id="gender" bindLabel="name" [items]="finYearList" appearance="outline" [(ngModel)]="finYear" [closeOnSelect]="true" [clearable]="false" (ngModelChange)="changeFinyear()" class="form-control">
            </ng-select>
        </div>
    </div>

    <div *ngIf="isZonal" class="row g-2 mb-2">
        <div *ngIf="this.currentPath === '/Twad-Dashboard'" class="col-md-12">
            <div class="card border-left-info shadow p-2">
                <div class="row">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass">
                        TWAD Dashboard</h5>
                </div>
                <p-table [value]="fundDetails" responsiveLayout="scroll" [tableStyle]="{ width: 'max-content'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="text-align: center;">Office Name</th>
                            <th style="text-align: center;">Beneficiary Code</th>
                            <th style="text-align: center;">Total Generated Bill Amount</th>
                            <th style="text-align: center;">Amount Paid</th>
                            <th style="text-align: center;">Outstanding Due Amount</th>

                        </tr>

                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>

                        <tr>
                            <td style="text-align: center;">{{rowData?.officeName}}</td>
                            <td style="text-align: center;">{{rowData?.beneficiaryCode}}</td>
                            <td style="text-align: center;">{{rowData?.billAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.paidAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.dueAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                        </tr>

                    </ng-template>
                </p-table>
            </div>
        </div>

    </div>
    <br *ngIf="this.currentPath === '/Twad-Dashboard'">
    <br *ngIf="this.currentPath === '/Twad-Dashboard'">
    <br *ngIf="this.currentPath === '/Twad-Dashboard'">
    <br *ngIf="this.currentPath === '/Twad-Dashboard'">
    <div *ngIf="isZonal" class="row g-2 mb-4">
        <div *ngIf="this.currentPath === '/Twad-Dashboard'" class="col-md-12">
            <div class="card border-left-info shadow p-2">
                <div class="row">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass">
                        TWAD Voucher/Limit Summary</h5>
                </div>
                <p-table [value]="fundDetailsSummaryReport" responsiveLayout="scroll" [tableStyle]="{ width: 'max-content'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th colspan="6" style="text-align: center;">TWAD</th>
                        </tr>
                        <tr>
                            <th style="text-align: center;">Total Generated Bill Amount</th>
                            <th style="text-align: center;">Total value of voucher initiated</th>
                            <th style="text-align: center;">Expenditure Made</th>
                            <th style="text-align: center;">Total value of voucher <br> Rejected/Returned</th>
                            <th style="text-align: center;">Total Value of <br> Voucher Pending for <br> Approval</th>
                            <th style="text-align: center;">Outstanding Due <br>Amount</th>

                        </tr>
                        <tr>
                            <th style="text-align: center;">1</th>
                            <th style="text-align: center;">2 = 3+4+5</th>
                            <th style="text-align: center;">3</th>
                            <th style="text-align: center;">4</th>
                            <th style="text-align: center;">5</th>
                            <th style="text-align: center;">6 = 1-3</th>

                        </tr>

                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>

                        <tr>
                            <td style="text-align: center;">{{rowData?.twadDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                            <td style="text-align: center;">{{rowData?.twadTotalvoucherInitiateAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.twadExpenditureMadeAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.twadTotalVoucherRejectAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.twadPendingDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.twadBalanceDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                        </tr>

                    </ng-template>
                </p-table>
            </div>
        </div>

    </div>
    <div *ngIf="isZonal" class="row g-2 mb-2">

        <div *ngIf="this.currentPath !== '/Twad-Dashboard'" class="col-md-12">
            <div class="card border-left-info shadow p-2">
                <div class="row">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass">
                        TANGEDCO Dashboard</h5>
                </div>
                <p-table [value]="tangedcoDashboardData" responsiveLayout="scroll" [tableStyle]="{ width: 'max-content'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="text-align: center;">Total No. of Service Connections </th>
                            <th style="text-align: center;">Total Generated Bill Amount</th>
                            <th style="text-align: center;">Amount Paid</th>
                            <th style="text-align: center;">Outstanding Due Amount</th>

                        </tr>

                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>

                        <tr>
                            <td style="text-align: center;">{{rowData?.connectionCount}}</td>
                            <td style="text-align: center;">{{rowData?.billAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.paidAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.dueAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                        </tr>

                    </ng-template>
                </p-table>
            </div>
        </div>

    </div>
    <br *ngIf="this.currentPath !== '/Twad-Dashboard'">
    <br *ngIf="this.currentPath !== '/Twad-Dashboard'">
    <br *ngIf="this.currentPath !== '/Twad-Dashboard'">
    <br *ngIf="this.currentPath !== '/Twad-Dashboard'">
    <div *ngIf="isZonal" class="row g-2 mb-4">
        <div *ngIf="this.currentPath !== '/Twad-Dashboard'" class="col-md-12">
            <div class="card border-left-info shadow p-2">
                <div class="row">
                    <h5 class="headingFont" [ngClass]="fontService.headingClass">
                        TANGEDCO Voucher/Limit Summary</h5>
                </div>
                <p-table [value]="fundDetailsSummaryReport" responsiveLayout="scroll" [tableStyle]="{ width: 'max-content'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th colspan="6" style="text-align: center;">TANGEDCO</th>
                        </tr>
                        <tr>
                            <th style="text-align: center;">Total Generated Bill Amount</th>
                            <th style="text-align: center;">Total value of voucher initiated</th>
                            <th style="text-align: center;">Expenditure Made</th>
                            <th style="text-align: center;">Total value of voucher <br> Rejected/Returned</th>
                            <th style="text-align: center;">Total Value of <br> Voucher Pending for <br> Approval</th>
                            <th style="text-align: center;">Outstanding Due <br>Amount</th>

                        </tr>
                        <tr>
                            <th style="text-align: center;">1</th>
                            <th style="text-align: center;">2 = 3+4+5</th>
                            <th style="text-align: center;">3</th>
                            <th style="text-align: center;">4</th>
                            <th style="text-align: center;">5</th>
                            <th style="text-align: center;">6 = 1-3</th>

                        </tr>

                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>

                        <tr>
                            <td style="text-align: center;">{{rowData?.tangedcoDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN'}}</td>
                            <td style="text-align: center;">{{rowData?.tangedcoTotalvoucherInitiateAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.tangedcoExpenditureMadeAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.tangedcoTotalVoucherRejectAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.tangedcoPendingDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                            <td style="text-align: center;">{{rowData?.tangedcoBalanceDueAmount | currency:'INR':'symbol':'1.2-2':'en-IN' }}</td>
                        </tr>

                    </ng-template>
                </p-table>
            </div>
        </div>

    </div>
</div>