import { Subscription } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageService } from 'ngx-webstorage';
import { NotificationService } from 'src/app/alert/notification.service';
import { ComponentTypeService } from 'src/app/module/component-type/component-type.service';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { DistrictInternalBillDesc, DistrictInternalTransaction, DistrictInternalTransactionDto } from './district-interal-swap-model';
import { DistrictInternalSwapService } from './district-inreral-swap-service';

@Component({
  selector: 'app-district-interal-swap-page',
  templateUrl: './district-interal-swap-page.component.html',
  styleUrl: './district-interal-swap-page.component.scss'
})
export class DistrictInteralSwapPageComponent {
  grantLevel: any;
  formData: any;
  dropDownLabel: string;
  roleCode: any;
  levelMasterDto: any;
  finYearList: any;
  districtInteralDto: DistrictInternalTransactionDto;
  iaComponentLimitDto: any;
  districtInternalBillDesc:any[];
  enableView: boolean;
  readonly: boolean;
  isApproved: boolean;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private componentTypeService: ComponentTypeService,
    private paymentService: PaymentService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private districtInternalSwapService: DistrictInternalSwapService,
  ) {
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => { });
    }
    this.translateService.onLangChange.subscribe((event) => {
      const lang = this.translateService.currentLang;
      this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    });
  }

  async ngOnInit(): Promise<void> {
    const lang = this.translateService.currentLang;
    this.dropDownLabel = lang === 'en' ? 'name' : 'nameTn';
    this.grantLevel = this.sessionStorageService.retrieve('componentTypeTable');
    if (!this.grantLevel) {
      await this.loadComp();
    }
    this.roleCode = this.sessionStorageService.retrieve('user');
    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      if (this.activatedRoute.snapshot.queryParamMap.get('view')) {
        this.enableView = true;
        this.readonly = true;
      }
      this.spinner.show();
      this.districtInternalSwapService
        .getInternalTransaction(
          Number((this.activatedRoute.snapshot.queryParamMap.get('id')))
        )
        .subscribe(
          (res: HttpResponse<DistrictInternalTransactionDto>) => {
            this.districtInteralDto = res.body;
           
            this.districtInteralDto.districtInternalTransaction.transactionDate = new Date(
              this.districtInteralDto.districtInternalTransaction.transactionDate
            );
            if (this.districtInteralDto.districtInternalTransaction?.status === "Initiated" && this.roleCode.role.code === 'DA') {
              this.readonly = true;
              this.isApproved = true;
            }
            this.spinner.hide();
          },
          () => { }
        );
      
    }
    else {
      this.districtInteralDto = new DistrictInternalTransactionDto();
      this.districtInteralDto.districtInternalTransaction = new DistrictInternalTransaction();
      this.finYearList = this.sessionStorageService.retrieve('finYear');
      let FinYear = ''
      if (new Date().getMonth() + 1 >= 4) {
        FinYear =
          new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
      } else {
        FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
      }
      this.districtInteralDto.districtInternalTransaction.levelType = this.sessionStorageService.retrieve('levelMaster').levelTypeDto;
      this.districtInteralDto.districtInternalTransaction.voucherNumber = this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
      this.districtInteralDto.districtInternalTransaction.finYear = this.finYearList.find((_) => _.name === FinYear);
      this.districtInteralDto.districtInternalTransaction.iaId = this.sessionStorageService.retrieve('IA')?.id;
      this.districtInteralDto.districtInternalTransaction.iaName = this.sessionStorageService.retrieve('IA')?.name;
      this.districtInteralDto.districtInternalTransaction.componentMasterId = this.grantLevel.find((_) => _.componentCode === 'F').id;
      this.districtInteralDto.districtInternalTransaction.componentMasterName = this.grantLevel.find((_) => _.componentCode === 'F').name;
      this.districtInteralDto.districtInternalTransaction.department = this.sessionStorageService.retrieve('IA')?.departmentDto;
      this.districtInteralDto.districtInternalTransaction.scheme = this.sessionStorageService.retrieve('IA')?.schemeDto;
      this.districtInteralDto.districtInternalTransaction.transactionDate = new Date();
      this.districtInteralDto.districtInternalTransaction.levelMasterId = this.sessionStorageService.retrieve('levelMaster').id;
      this.districtInteralDto.districtInternalTransaction.levelMasterName = this.sessionStorageService.retrieve('levelMaster').name;
      this.paymentService
      .getLimit(
        this.districtInteralDto.districtInternalTransaction.iaId,
        this.districtInteralDto.districtInternalTransaction.componentMasterId,
        this.districtInteralDto.districtInternalTransaction.finYear.id
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.iaComponentLimitDto =
            res.body;
        },
        () => { }
      );
      this.districtInternalBillDesc = [];
      const componentCodes = ['F1', 'F2', 'F4'];
      componentCodes.forEach(code => {
        const component = this.grantLevel.find(_ => _.componentCode === code);
        if (component) {
          this.districtInternalBillDesc.push({
            name: `${component.componentCode} - ${component.name}`,
            Amount: 0,
            isEnable: false,
            id:component.id
          });
        }
      });
    }
  }

  loadComp(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.componentTypeService
        .find(
          this.sessionStorageService.retrieve('levelMaster').levelTypeDto.id
        ).subscribe(
        (responses) => {
          this.grantLevel = responses.body;
          resolve(this.grantLevel)
        },
        (error) => {
          console.error('Error:', error);
          resolve(null);
        }
      )
    });
  }

  onChangeAmount(list) {
    const index = this.districtInteralDto.districtInternalBillDescs?.findIndex(
      (_) => _.componentMasterId === list.id
    );
    if (!list.isEnable) {
      if (index > -1) {
        this.districtInteralDto.districtInternalBillDescs.splice(index,1);
      }
    }
    else {
      this.districtInteralDto.districtInternalBillDescs = this.districtInteralDto.districtInternalBillDescs || [];
      if (index > -1) {
        this.districtInteralDto.districtInternalBillDescs[index].amount = list.amount;
      } else {
        const districtInternalBillDesc = {
          componentMasterId: list.id,
          componentMasterName: list.name,
          amount: list.amount,
        };
        this.districtInteralDto.districtInternalBillDescs.push(districtInternalBillDesc);
      }
    }
    this.districtInteralDto.districtInternalTransaction.transactionAmount = 
      this.districtInteralDto.districtInternalBillDescs.reduce(
        (total, list) => total + (list?.amount || 0), 0
      );
  }

  save() {
    this.spinner.show();
    this.districtInternalSwapService.save(this.districtInteralDto).subscribe(
      (res) => {
        console.log('saveData', res)
        this.spinner.hide();
        this.notificationService.alertSuccess('Saved Successfully', '');
        this.router.navigate(['/district-interal-swap-table'], {})
      },
      (err) => {
        this.spinner.hide();
        console.log('err', err)
        if (err.error.status === 400) {
          this.notificationService.alertError('Error! ' + err.error.title, '');
        } else {
          this.notificationService.alertError('Unexpected Error!', '');
        }
      }
    );
  }

  approve() {
    this.spinner.show();
    this.districtInternalSwapService
      .approveSwap(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.router.navigate(['/district-interal-swap-table'], {});
        },
        (onError) => {
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.title, '');
          } else {
            this.notificationService.alertError('Error in Approved', '');
          }

          this.spinner.hide();
        }
      );
  }

  reject() {
    this.spinner.show();
    this.districtInternalSwapService
      .rejectSwap(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.router.navigate(['/district-interal-swap-table'], {});
        },
        (onError) => {
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.title, '');
          } else {
            this.notificationService.alertError('Error in Reject', '');
          }

          this.spinner.hide();
        }
      );
  }

}
