import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { DeductionTransaction } from '../deduction.model';
import { filterQuery } from 'src/app/shared/common.model';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { FinYear } from 'src/app/module/limit-translist/limit-translist.module';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { DeductionService } from '../deduction-service';
import { DatePipe, Location } from '@angular/common';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-deduction-payment-list',
  templateUrl: './deduction-payment-list.component.html',
  styleUrl: './deduction-payment-list.component.scss'
})
export class DeductionPaymentListComponent {

  itemsPerPage: any = 10;
  filterProps: any;
  searchResult: SearchResult<DeductionTransaction> =
    new SearchResult<DeductionTransaction>();
  page: number;
  cols: any;
  roleCode: string;
  vouchercols: any;
  enableStatusVoucher: boolean;
  disableEdit: boolean = false;
  disableDelete: boolean = true;
  villageLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  blockLevelList: LevelMasterDto[];
  levelMasterId: any;
  villageLevelDto: any;
  levelTypeList = ['Block', 'Village'];
  levelType: any = 'Village';
  finYearList: FinYear[];
  finYear: FinYear;
  defaultFinyear: FinYear;
  currentPath: string;
  headingText: string;
  deductionTypeList: string[];
  deductionType: string;
  voucherUrl: string;
  enableFlagVoucher: boolean;
  currentDeductionTransaction: any;
  lgdCode:string;
  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    protected router: Router,
    protected paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    private loginService: SigninService,
    public sessionStorage: SessionStorageService,
    private levelMasterService: LevelMasterService,
    private limitTransListService: LimitTransListService,
    private deductionService: DeductionService,
    private location: Location,
    protected notificationService: NotificationService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  message: string;

  ngOnInit(): void {
    this.sessionStorage.clear('paymentType');
    this.sessionStorage.clear('voucherNo');
    this.sessionStorage.clear('userId');
    this.sessionStorage.clear('otpAttempts');
    if (this.sessionStorage.retrieve('response')) {
      if (this.sessionStorage.retrieve('response').status === 'SUCCESS') {
        this.notificationService.alertSuccess(this.sessionStorage.retrieve('response').message, '');
      }
      else {
        this.notificationService.alertError(this.sessionStorage.retrieve('response').message, '');
      }
      this.sessionStorage.clear('response');
    }
    if (this.route.snapshot.queryParamMap.get('error')) {
      this.notificationService.alertError('Error Occurred: ' + this.route.snapshot.queryParamMap.get('error'), '');
    }
    this.roleCode = this.roleCode = this.sessionStorage.retrieve('roleCode');
    this.levelMasterId = this.sessionStorage.retrieve('levelMasterId');
    this.finYearList = this.sessionStorage.retrieve('finYear');
    let FinYear = ''
    if (new Date().getMonth() + 1 >= 4) {
      FinYear =
        new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      FinYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    this.finYear = this.finYearList.find((_) => _.name === FinYear);
    this.finYearList = this.finYearList.filter(year => year.name <= FinYear);
    this.defaultFinyear = this.finYearList.find((_) => _.name === FinYear);
    if (
      this.roleCode == 'DM' ||
      this.roleCode == 'BM' ||
      this.roleCode == 'SM'
    )
      this.disableEdit = true;
    if (
      this.roleCode === 'DMAKER' ||
      this.roleCode === 'VMAKER' ||
      this.roleCode === 'BMAKER'
    )
      this.disableDelete = false;
    if (this.roleCode == 'DAD') {
      this.onDistrictLevelChange();
    }
    this.currentPath = this.location.path();
    switch (true) {
      case this.currentPath.startsWith('/Statutory-deduction-list'):
        this.headingText = 'Statutory Payment List';
        this.voucherUrl = '/Statutory-deduction-page';
        this.deductionTypeList = ['IT', 'GST', 'LWF'];
        break;

      case this.currentPath.startsWith('/Flag-deduction-list'):
        this.headingText = 'Flag Day Payment List';
        this.deductionType = 'FD';
        this.voucherUrl = '/Flag-deduction-page';
        break;
      case this.currentPath.startsWith('/Security-deposit-deduction-list'):
        this.headingText = 'Security Deposit Payment List';
        this.deductionType = 'SD';
        this.voucherUrl = '/Security-deposit-deduction-page';
        break;
    }

    this.roleCode = this.sessionStorage.retrieve('roleCode');
    if(this.roleCode === 'SA' || this.roleCode === 'SM') {
      this.cols = [
        {
          field: 'districtName',
          header: 'Block Name',
          jhiTranslate: 'dashBoard.district',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'blockName',
          header: 'Block Name',
          jhiTranslate: 'dashBoard.block',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'villageName',
          header: 'Block Name',
          jhiTranslate: 'dashBoard.villagePan',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'villageLgd',
          header: 'LGD Code',
          isFilterable: false,
          isSelectcolumn: true,
        },
        {
          field: 'voucherNumber',
          header: 'Voucher No',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.no',
        },
        {
          field: 'permanentVoucherNumber',
          header: 'Permanent Voucher Number',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
        },
        {
          field: 'deductionMonth',
          header: 'Deduction Month',
          isFilterable: true,
          isSelectcolumn: true,
        },
  
        {
          field: 'transactionDate',
          header: 'Voucher Date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
          jhiTranslate: 'tableHead.transaction.date'
        },
        {
          field: 'deductionType',
          header: 'Deduction Type',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'transactionAmount',
          header: 'Total Transction Amount',
          jhiTranslate: 'tableHead.transaction.amount',
          isSelectcolumn: true,
          isFilterable: true,
          type: 'amount',
        },
        {
          field: 'transactionStatus',
          header: 'Payment Status',
          jhiTranslate: 'tableHead.payment.status',
          isSelectcolumn: true,
          isSortable: true,
          isFilterable: false,
          type: 'color'
        },
  
      ];
    }
    else {
      this.cols = [
        {
          field: 'voucherNumber',
          header: 'Voucher No',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.no',
        },
        {
          field: 'permanentVoucherNumber',
          header: 'Permanent Voucher Number',
          isFilterable: true,
          isSelectcolumn: true,
          jhiTranslate: 'tableHead.voucher.permanentVoucherNumber',
        },
        {
          field: 'deductionMonth',
          header: 'Deduction Month',
          isFilterable: true,
          isSelectcolumn: true,
        },
  
        {
          field: 'transactionDate',
          header: 'Voucher Date',
          isSortable: true,
          isSelectcolumn: true,
          type: 'date',
          jhiTranslate: 'tableHead.transaction.date'
        },
        {
          field: 'deductionType',
          header: 'Deduction Type',
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'transactionAmount',
          header: 'Total Transction Amount',
          jhiTranslate: 'tableHead.transaction.amount',
          isSelectcolumn: true,
          isFilterable: true,
          type: 'amount',
        },
        {
          field: 'transactionStatus',
          header: 'Payment Status',
          jhiTranslate: 'tableHead.payment.status',
          isSelectcolumn: true,
          isSortable: true,
          isFilterable: false,
          type: 'color'
        },
  
      ];
    }
    
    // this.loadPage(1);
  }
  captureWSMessage(data: any) {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  openNew() {
    this.paymentService.getVoucher().subscribe(
      (res: HttpResponse<any>) => {
        this.router.navigate([this.voucherUrl], {
          relativeTo: this.route,
          queryParams: {
            VoucherNo: res.body,
          },
        });
      },
      () => { }
    );
  }

  private updateDialogFont() {

  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let query = new filterQuery();
    this.filterProps = this.filterProps ? this.filterProps : [];
    if (this.currentPath.startsWith('/Statutory-deduction-list')) {
      query.key = 'deductionType';
      query.operation = 'notEquals';
      query.value = 'FD';
      this.filterProps.push(query);
      query = new filterQuery();
      query.key = 'deductionType';
      query.operation = 'notEquals';
      query.value = 'SD';
      this.filterProps.push(query);
    }
    if (this.deductionType) {
      query = new filterQuery();
      query.key = 'deductionType';
      query.operation = 'equals';
      query.value = this.deductionType;
      this.filterProps.push(query);
    }
    query = new filterQuery();
    query.key = 'activeFlag';
    query.operation = 'equals';
    query.value = 'YES';
    this.filterProps.push(query);
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    if (this.finYear) {
      query = new filterQuery();
      query.key = 'finYear.id';
      query.operation = 'equals';
      query.value = this.finYear.id;
      this.filterProps.push(query);
    }
    if(this.roleCode === 'ZVM') {
      let payload = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        finYearId:this.finYear.id,
        levelMasterId:this.levelMasterId,
        zonalName:this.sessionStorage.retrieve('user')?.zonalName,
        levelType:this.sessionStorage.retrieve('levelMaster').levelTypeDto.name.toUpperCase()
      }
      payload['deductionType']=this.deductionType ? this.deductionType :null;
      if(this.lgdCode) {
        payload['lgdCode']=this.lgdCode;
      }
      this.deductionService.getDeductionPaymentByType({ ...payload }).subscribe(
        (res) => {
          this.spinner.hide();
          this.onSuccess(res.body.deductionTransactionDto, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.spinner.hide();
          this.onError();
        }
      );
    }
    else {
      this.deductionService.filter({ ...Query }, this.filterProps).subscribe(
        (res: HttpResponse<DeductionTransaction[]>) => {
          this.spinner.hide();
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.spinner.hide();
          this.onError();
        }
      );
    }
   


  }

  protected onSuccess(
    data: DeductionTransaction[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;
    data.forEach(element => {
      switch (element.transactionStatus) {
        case null:
          element.transactionStatus = 'Not Initiated';
          break;
        case 'O.K.':
          element.transactionStatus = 'Success';
          break;
        case 'failed':
          element.transactionStatus = 'Failed';
          break;
        case 'processing':
          element.transactionStatus = 'Processing';
          break;
        default:
          break;
      }
      if (element.signCount === 1 && element.signatureSecretary === null && (this.roleCode === 'DMAKER' || this.roleCode === 'VMAKER' || this.roleCode === 'BMAKER')) {
        element.edit = true;
        element.delete = true;
      }
      else if (element.signCount === 2 && element.signatureVicePresident === null && !element.permanentVoucherNumber && element.signatureSecretary !== null && (this.roleCode === 'BC1' || this.roleCode === 'ZC1' || this.roleCode === 'DC1' || this.roleCode === 'VC1')) {
        element.edit = true;
      }
      else if (element.signCount === 3 && element.signaturePresident === null && element.signatureVicePresident !== null && !element.permanentVoucherNumber && element.signatureSecretary !== null && (this.roleCode === 'DC2' || this.roleCode === 'VC2' || this.roleCode === 'BC2')) {
        element.edit = true;
      }
      else {
        element.edit = false;
      }
    });
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  async onLazyLoad(event) {
    this.filterProps = []
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onSelectRow($event) {
    if ($event.type === 'select') {
      this.router.navigate([this.voucherUrl], {
        queryParams: {
          id: $event.value.id,
        },
      });
    }
    else if ($event.type === 'view') {
      this.router.navigate([this.voucherUrl], {
        queryParams: {
          id: $event.value.id,
          view: true
        },
      });
    }
    else if ($event.type === 'flag') {
      this.currentDeductionTransaction = $event.value;
      this.enableFlagVoucher = true;
    }
    else {
      this.deductionService
        .rejectTransaction($event.value.id)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.loadPage(this.page)
          },
          () => {
            this.onError();
          }
        );
    }
  }

  onDistrictLevelChange($event?) {

    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.levelMasterId,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.levelMasterId,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => { }
        );
    }
  }

  onBlockLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => { }
        );
    }
  }
  getFinyear() {

  }


  onFilterClear() {
    if (this.voucherUrl === '/Statutory-deduction-page') {
      this.deductionType = null;
    }
    this.finYear = this.defaultFinyear;
    this.lgdCode = null;
    this.filterProps = [];

    this.loadPage(1);

  }



}
